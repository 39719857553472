import axios from '@/clientAPI.ts'
import {GetNotificationsHistoryPropsType} from '../queries/useGetNotificationsHistory'
import {NotificationsResponse} from '../types'

export type GetNotificationUnreadCountType = {
    count?: number
    last_read?: Date | string
}

export const NOTIFICATIONS_API = {
    getNotificationsHistory: async ({
        page,
        limit
    }: GetNotificationsHistoryPropsType): Promise<NotificationsResponse> => {
        const url = 'notifications'
        const {data} = await axios.get(url, {params: {limit, page}})
        return data
    },
    getNotificationUnreadCount: async (): Promise<GetNotificationUnreadCountType> => {
        const url = '/notifications/last-read'
        const {data} = await axios.get(url)
        return data
    },
    sendSeenNotifications: async () => {
        const {data} = await axios.post(`/notifications/seen`)
        return data
    }
}
