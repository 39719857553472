import axios from 'axios'

import {envVars} from '@/envVars.ts'
import i18n, {FALLBACK_LANGUAGE} from '@/translations/i18n.ts'
import {LocalStorageManager} from './utilities/localStorage'
import Cookies from 'js-cookie'
import {KNOWN_COOKIES} from './types'

// create axios custom instance with custom config
const clientAPI = axios.create({
    baseURL: envVars.VITE_API_ENDPOINT,
    withCredentials: true, // needed for http-only cookie
    headers: {
        common: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'X-Requested-With': 'XMLHttpRequest'
        },
        post: {
            'Access-Control-Allow-Origin': '*'
        }
    }
})

clientAPI.interceptors.request.use(config => {
    config.headers['X-localization'] = i18n.language || FALLBACK_LANGUAGE
    return config
})

//const endpointToIgnore = [commonRoutes.LOGIN_GUEST.path, commonRoutes.LOGIN_HOST.path]

clientAPI.interceptors.response.use(
    response => {
        // pass response without change
        return response
    },
    error => {
        // get error info
        const statusCode = error?.response?.status
        //const originalRequest = error.config
        console.log('error', error)
        switch (statusCode) {
            case 401: {
                LocalStorageManager.loginUser.remove()
                Cookies.remove(KNOWN_COOKIES.authCookie, {
                    domain: KNOWN_COOKIES.mainDomain
                })
                return Promise.reject(error)
            }
            default:
                return Promise.reject(error)
        }
    }
)

export default clientAPI
