import {css} from 'styled-components'
import mediaQueries from '@/theme/mediaQueries'
import '@fontsource/inter/400.css'
import '@fontsource/inter/500.css'
import '@fontsource/inter/600.css'
import '@fontsource/inter/700.css'
const defaultFontFamily = 'Inter'

export const typography = {
    display2xl: css`
        font-family: ${defaultFontFamily};
        font-size: 72px;
        letter-spacing: -0.02em;
    `,
    displayXXl: css`
        font-family: ${defaultFontFamily};
        font-size: 64px;
        letter-spacing: -3.2px;
    `,
    displayXl: css`
        font-family: ${defaultFontFamily};
        font-size: 60px;
        letter-spacing: -0.02em;
    `,
    displayLg: css`
        font-family: ${defaultFontFamily};
        font-size: 48px;
        letter-spacing: -0.02em;
    `,
    displayMd: css`
        font-family: ${defaultFontFamily};
        font-size: 36px;
        letter-spacing: -0.02em;
    `,
    displaySm: css`
        font-family: ${defaultFontFamily};
        font-size: 30px;

        ${mediaQueries.m} {
            font-size: 24px;
        }
    `,
    displayXs: css`
        font-family: ${defaultFontFamily};
        font-size: 24px;

        ${mediaQueries.m} {
            font-size: 20px;
        }
    `,
    text4Xl: css`
        font-family: ${defaultFontFamily};
        font-size: 30px;
    `,
    text3Xl: css`
        font-family: ${defaultFontFamily};
        font-size: 28px;
    `,
    text2Xl: css`
        font-family: ${defaultFontFamily};
        font-size: 24px;
    `,
    textXXl: css`
        font-family: ${defaultFontFamily};
        font-size: 22px;
    `,
    textXl: css`
        font-family: ${defaultFontFamily};
        font-size: 20px;
    `,
    textLg: css`
        font-family: ${defaultFontFamily};
        font-size: 18px;
    `,
    textMd: css`
        font-family: ${defaultFontFamily};
        font-size: 16px;
    `,
    textSm: css`
        font-family: ${defaultFontFamily};
        font-size: 14px;
    `,
    textXs: css`
        font-family: ${defaultFontFamily};
        font-size: 12px;
    `,
    textXxs: css`
        font-family: ${defaultFontFamily};
        font-size: 10px;
    `,
    text3Xs: css`
        font-family: ${defaultFontFamily};
        font-size: 8px;
    `,
    text4Xs: css`
        font-family: ${defaultFontFamily};
        font-size: 6px;
    `
}
