import {useQuery} from '@tanstack/react-query'
import {QUERY_KEYS} from './keys'
import {COUNTRY_API} from '../services/country.http'

export const useGetFilterCountries = ({appLang = ''}) => {
    return useQuery({
        queryKey: [QUERY_KEYS.filter_countries, appLang],
        queryFn: () => COUNTRY_API.getFilterCountries()
    })
}
