import {FC} from 'react'
import {StyledHostsName, StyledPlayButton, StyledVideoPreview, StyledVideoPreviewWrapper} from './style'
import {PlayButton} from '@/assets/icons/icons'
import {useTranslation} from 'react-i18next'
import {HostPartialType} from '@/features/authentication/types'

type HostProfileVideoProps = {
    video: string | null
    hostName: string
    setIsWatchedVideo?: () => void
    isBlockedVideos?: boolean
    currentBlockedVideo?: boolean
    host?: HostPartialType
}

export const HostProfileVideo: FC<HostProfileVideoProps> = ({
    video,
    hostName,
    isBlockedVideos = false,
    currentBlockedVideo = false
}) => {
    const {t} = useTranslation()

    const truncateName = (str: string, maxLength: number): string => {
        if (str.length > maxLength) {
            return str.substring(0, maxLength) + `...`
        }
        return str
    }
    const truncatedName = truncateName(hostName, 20)

    const poster = isBlockedVideos && currentBlockedVideo && video ? video : undefined
    return (
        <>
            <StyledVideoPreviewWrapper>
                {!!video && (
                    <>
                        <StyledVideoPreview>
                            <video preload="metadata" poster={poster}>
                                <source src={`${video}#t=0.1`} />
                            </video>
                            {isBlockedVideos ? (
                                <StyledHostsName>{t('rookie_my_profile:pay_wall:support_to_see_more')}</StyledHostsName>
                            ) : (
                                <>
                                    <StyledHostsName>
                                        {t('rookie_profile_preview:rookie_video', {
                                            USER: truncatedName
                                        })}
                                    </StyledHostsName>
                                    <StyledPlayButton>
                                        <PlayButton />
                                    </StyledPlayButton>
                                </>
                            )}
                        </StyledVideoPreview>
                    </>
                )}
            </StyledVideoPreviewWrapper>
        </>
    )
}

HostProfileVideo.displayName = 'HostProfileVideo'
