export const enum PublicRoute {
    COOKIE_POLICY = '/cookie-policy',
    TOS = '/tos',
    PRIVACY_POLICY = '/privacy-policy',
    COMMUNITY_GUILDINES = '/community-guidlines',
    FAQ = '/faq',
    WHAT_IS_MORGI = '/what-is-morgi',
    OUR_MISSION = '/our-mission',
    A_WORD_FROM_PRESIDENT = '/a-word-from-president',
    WHO_WE_ARE = '/who-we-are',
    SHARE_ROOKIE_PROFILE = '/',
    REFER_A_LEADER = '/join-as-guest',
    WAIVER_OF_RIGHTS = '/competition-waivers'
}
