import styled, {css} from 'styled-components'
import {Splide, SplideSlide} from '@splidejs/react-splide'

export const StyledSplide = styled(Splide)`
    ${({theme: {mediaQueries, palette}}) => css`
        position: relative;
        width: 100%;
        max-width: 340px;
        height: 100%;

        ${mediaQueries.s} {
            max-width: 390px;
        }

        ${mediaQueries.m} {
            max-width: 400px;
        }
        .splide__pagination__page {
            height: 6px;
            width: 33px;
            border-radius: 4px;
            background-color: ${palette.neutral['75']};
        }
        .splide__pagination__page.is-active {
            height: 6px;
            width: 33px;
            border-radius: 4px;
            background-color: ${palette.primary['900']};
            transform: none;
        }
    `}
`

export const StyledSplideSlide = styled(SplideSlide)(
    ({theme: {spacing}}) => css`
        background-color: transparent;
        display: flex;
        align-items: center;
        flex-direction: column;
        text-align: center;
        margin-bottom: ${spacing * 1.25}px;
        padding: ${spacing * 2.5}px;
    `
)

export const StyledButtonBox = styled.div`
    ${({theme: {spacing}}) => css`
        display: flex;
        gap: ${spacing * 1.25}px;
    `}
`

export const StyledModalContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
`

export const StyledModalFooter = styled.div`
    ${({theme: {spacing, typography, palette}}) => css`
        display: flex;
        flex-direction: column;
        gap: ${spacing * 2}px;
        padding: ${spacing * 2}px ${spacing * 2.5}px ${spacing * 2.5}px;
        text-align: center;
        & span {
            ${typography.textXs}
        }
        & p {
            ${typography.textXs}
            color: ${palette.primary['700']};

            & a {
                text-decoration: underline;
                cursor: pointer;
                line-height: 16.8px;
                white-space: pre-wrap;
            }
        }
    `}
`

export const StyledIconContainer = styled.div`
    ${({theme: {spacing}}) => css`
        margin: ${spacing * 3}px 0 ${spacing * 5}px 0;
    `}
`

export const StyledStrong = styled.strong`
    display: block;
`
