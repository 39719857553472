import {
    AlertIcon,
    ConnectionIcon,
    CouponIcon,
    DollarIcon,
    GiftIcon,
    InfoIcon,
    LuckyMatchIcon,
    MorgiAppreciateIcon,
    PauseGiftIcon,
    TelegramIcon,
    TickIcon,
    TopFanCongratulationIcon
} from '@/assets/icons/icons'
import styled, {DefaultTheme, css} from 'styled-components'

export const StyledInfoIcon = styled(InfoIcon)`
    ${({old, color, theme: {palette}}: {old?: boolean; color?: string; theme: DefaultTheme}) => css`
        fill: ${old ? palette.neutral['900'] : color};
        path {
            fill: ${old ? palette.primary['700'] : color};
        }
    `}
`
export const StyledAlertIcon = styled(AlertIcon)`
    ${({old, color, theme: {palette}}: {old?: boolean; color?: string; theme: DefaultTheme}) => css`
        fill: ${old ? color : palette.neutral['900']};
        path {
            fill: ${old ? palette.primary['700'] : color};
            scale: 1.2;
            transform: translate(-2px, -2px);
        }
    `}
`
export const StyledTickIcon = styled(TickIcon)`
    ${({old, color, theme: {palette}}: {old?: boolean; color?: string; theme: DefaultTheme}) => css`
        fill: ${old ? color : palette.neutral['900']};
        path {
            fill: ${old ? palette.primary['700'] : color};
            scale: 1.2;
            transform: translate(-2px, -2px);
        }
    `}
`
export const StyledGiftIcon = styled(GiftIcon)`
    ${({old, color, theme: {palette}}: {old?: boolean; color?: string; theme: DefaultTheme}) => css`
        fill: ${old ? color : palette.neutral['900']};
        path {
            fill: ${old ? palette.primary['700'] : color};
        }
    `}
`
export const StyledMorgiAppreciateIcon = styled(MorgiAppreciateIcon)`
    ${({old, color, theme: {palette}}: {old?: boolean; color?: string; theme: DefaultTheme}) => css`
        fill: ${old ? color : palette.neutral['900']};
        path {
            fill: ${old ? palette.primary['700'] : color};
        }
    `}
`
export const StyledDollarIcon = styled(DollarIcon)`
    ${({old, color, theme: {palette}}: {old?: boolean; color?: string; theme: DefaultTheme}) => css`
        fill: ${old ? color : palette.neutral['900']};
        path {
            fill: ${old ? palette.primary['700'] : color};
            scale: 1.2;
            transform: translate(-2px, -2px);
        }
    `}
`
export const StyledCouponIcon = styled(CouponIcon)`
    ${({old, color, theme: {palette}}: {old?: boolean; color?: string; theme: DefaultTheme}) => css`
        fill: ${old ? color : palette.neutral['900']};
        path {
            fill: ${old ? palette.primary['700'] : color};
        }
    `}
`
export const StyledTelegramIcon = styled(TelegramIcon)`
    ${({old, color, theme: {palette}}: {old?: boolean; color?: string; theme: DefaultTheme}) => css`
        fill: ${old ? color : palette.neutral['900']};
        path {
            fill: ${old ? palette.primary['700'] : color};
        }
    `}
`

export const StyledPauseGiftIcon = styled(PauseGiftIcon)`
    ${({old, color, theme: {palette}}: {old?: boolean; color?: string; theme: DefaultTheme}) => css`
        stroke: ${!old ? color : palette.neutral['900']};
        fill: ${!old ? color : 'none'};
        path {
            fill: ${old ? palette.primary['700'] : color};
        }
    `}
`
export const StyledConnectionIcon = styled(ConnectionIcon)`
    ${({old, color, theme: {palette}}: {old?: boolean; color?: string; theme: DefaultTheme}) => css`
        fill: ${old ? color : palette.neutral['900']};
        path {
            fill: ${old ? palette.primary['700'] : color};
        }
    `}
`
export const StyledLuckyMatchIcon = styled(LuckyMatchIcon)`
    ${({old, color, theme: {palette}}: {old?: boolean; color?: string; theme: DefaultTheme}) => css`
        stroke: ${!old ? color : palette.neutral['900']};
        fill: ${!old ? color : palette.neutral['900']};
        path {
            fill: ${!old ? palette.primary['700'] : 'none'};
        }
    `}
`
export const StyledTopFanCongratulationIcon = styled(TopFanCongratulationIcon)`
    ${({old, color, theme: {palette}}: {old?: boolean; color?: string; theme: DefaultTheme}) => css`
        fill: ${!old ? color : palette.neutral['900']};
        path {
            fill: ${old ? palette.primary['800'] : color};
        }
    `}
`
