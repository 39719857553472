import {VerifiedHostIcon} from '@/assets/icons/icons'
import {StyledPopover, StyledRoot, StyledToolTipContent} from './style'
import {useNavigate} from 'react-router-dom'
import {ROUTE_FAQ_GUEST} from '@/utilities/constants/routeNames'
import {Trans, useTranslation} from 'react-i18next'
import {useMemo} from 'react'
import {GenderKeyNameE} from '@/features/constants/constants'

type HostProfileVerificationIcon = {
    hostGenderId?: number
    hostName?: string
}

const HostProfileVerificationIcon = ({hostName, hostGenderId}: HostProfileVerificationIcon) => {
    const navigate = useNavigate()
    const {t} = useTranslation()

    const hostGender = useMemo(() => {
        switch (hostGenderId) {
            case 1:
                return GenderKeyNameE.MALE
            case 2:
                return GenderKeyNameE.FEMALE
            default:
                return GenderKeyNameE.OTHER
        }
    }, [hostGenderId])

    const handleClickVerify = () => {
        navigate(ROUTE_FAQ_GUEST)
    }

    return (
        <StyledRoot>
            <StyledPopover trigger={<VerifiedHostIcon />} isModal>
                <StyledToolTipContent>
                    <Trans
                        t={t}
                        i18nKey="interactive_feed_rookie_card:verify_tooltip"
                        components={{
                            span: <span onClick={() => handleClickVerify()} />
                        }}
                        values={{
                            USER_NAME: hostName,
                            context: hostGender
                        }}
                    />
                </StyledToolTipContent>
            </StyledPopover>
        </StyledRoot>
    )
}

export default HostProfileVerificationIcon
