import {Button} from '@/components/ui/button/Button'
import styled, {css} from 'styled-components'
import TopConfettiBackground from '@assets/images/TopConfettiBackground.png'

export const StyledSendMegaGiftModalContainer = styled.div`
    ${({theme: {spacing, mediaQueries}}) => css`
        padding: ${spacing}px;
        ${mediaQueries.m} {
            padding: 0px ${spacing * 1.25}px ${spacing * 2.5}px;
        }
    `}
`
export const StyledSendMegaGiftModalWrapper = styled.div`
    ${({theme: {spacing}}) => css`
        width: 100%;
        height: 100%;
        display: flex;
        position: relative;
        background: #e6e1ff;
        align-items: center;
        border-radius: ${spacing * 2}px;
        flex-direction: column;
        justify-content: center;
        gap: ${spacing * 1.5}px;
        background-image: url(${TopConfettiBackground});
        background-repeat: no-repeat;
        position: relative;
        overflow: hidden;
        &:after {
            content: ' ';
            background-image: url(/src/assets/images/TopConfettiBackground.png);
            background-repeat: no-repeat;
            position: absolute;
            bottom: 0;
            height: 50%;
            width: 100%;
            transform: rotate(180deg);
            margin-bottom: -25px;
            pointer-events: none;
            z-index: 0;
        }
    `}
`

export const StyledSendMegaGiftModalImage = styled.img`
    ${() => css`
        width: 300px;
        height: 300px;
        margin-top: -45px;
    `}
`

export const StyledSendMegaGiftModalDescription = styled.div`
    ${({theme: {typography, spacing}}) => css`
        ${typography.textSm}
        display: flex;
        font-weight: 600;
        line-height: normal;
        flex-direction: column;
        letter-spacing: -0.7px;
        justify-content: center;
        z-index: 1;
        margin-top: -50px;
        align-items: center;
        gap: ${spacing / 2}px;
    `}
`
export const StyledSendMegaGiftModalDescriptionCost = styled.div`
    ${() => css`
        display: flex;
        align-items: center;
    `}
`
export const StyledSendMegaGiftModalSendButton = styled(Button)`
    ${({theme: {spacing, typography, flexUtils}}) => css`
        ${typography.textMd}
        ${flexUtils.centered}
        z-index: 1;
        width: 315px;
        padding: ${spacing * 2}px ${spacing * 3}px;
        position: relative;
        transition: all 300ms ease-in-out 0s;
        line-height: 1.4;
        border-radius: 100px;
    `}
`
