import {ReactNode} from 'react'
import {StyledProgressBarBorder, StyledProgressBarIndicator, StyledProgressBarText, StyledRoot} from './style'
import {DefaultNamespace} from 'i18next'

interface LinearProgressProps {
    text?: DefaultNamespace | ReactNode | string
    percentage: number
    mainColor: string
    secondaryColor: string
    className?: string
}

export const LinearProgress = ({text, percentage, mainColor, secondaryColor, className}: LinearProgressProps) => {
    return (
        <StyledRoot className={className}>
            <StyledProgressBarIndicator percentage={percentage} mainColor={mainColor} />
            <StyledProgressBarBorder secondaryColor={secondaryColor} />
            {text && (
                <StyledProgressBarText percentage={percentage} mainColor={mainColor}>
                    {text}
                </StyledProgressBarText>
            )}
        </StyledRoot>
    )
}

LinearProgress.displayName = 'LinearProgress'
