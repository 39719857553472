import {useTranslation} from 'react-i18next'
import {StyledActionButton} from './style'
import {useState} from 'react'
import {BlockHostModal} from './block-host-modal/BlockHostModal'

type BlockHostButtonProps = {
    hostName: string
    hostId: number
}

export const BlockHostButton = ({hostName, hostId}: BlockHostButtonProps) => {
    const {t} = useTranslation()
    const [isOpenBlockHostModal, setIsOpenBlockHostModal] = useState(false)
    return (
        <>
            <StyledActionButton onClick={() => setIsOpenBlockHostModal(true)}>
                {t('common:block')} {hostName}
            </StyledActionButton>

            {isOpenBlockHostModal && (
                <BlockHostModal onClose={() => setIsOpenBlockHostModal(false)} hostName={hostName} hostId={hostId} />
            )}
        </>
    )
}

export default BlockHostButton
