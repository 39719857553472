import {MicromorgiPackage} from '@/features/gift/types'
import {Variants} from '../../ReachedLimitModal'
import {FailureStep} from '../failureStep/FailureStep'
import {RenderPurchaseStep} from '../renderPurchaseStep/RenderPurchaseStep'
import {SuccessStep} from '../successStep/SuccessStep'

export type ReachedLimitContentProps = {
    variant: Variants
    selectedOption?: MicromorgiPackage
    findSelectedOption: (pack: MicromorgiPackage) => boolean
    selectPackClick: (pack: MicromorgiPackage) => void
    handleBuyMicromorgiPackage: (packageId: string) => void
    backToBuyMicromorgiPackage: () => void
}

export const ReachedLimitContent = ({
    variant,
    backToBuyMicromorgiPackage,
    handleBuyMicromorgiPackage,
    selectedOption,
    findSelectedOption,
    selectPackClick
}: ReachedLimitContentProps) => {
    switch (variant) {
        case Variants.SUCCESS:
            return <SuccessStep selectedOption={selectedOption} />
        case Variants.FAILURE:
            return <FailureStep backToBuyMicromorgiPackage={backToBuyMicromorgiPackage} />
        case Variants.PURCHASE:
            return (
                <RenderPurchaseStep
                    handleBuyMicromorgiPackage={handleBuyMicromorgiPackage}
                    selectedOption={selectedOption}
                    findSelectedOption={findSelectedOption}
                    selectPackClick={selectPackClick}
                />
            )
        default:
            return null
    }
}
