import Checkbox from '@/components/commons/checkbox/CheckBox'
import {OptionProps} from '../../types/types'
import SelectForOption from '../selectForOption/SelectForOption'
import {
    StyledOptionBox,
    StyledOptionContainer,
    StyledOptionItemBox,
    StyledOptionSelectBox,
    StyledOptionSpan
} from './style'

export const Option = ({
    showCheckbox,
    subSelectPlaceholder,
    optionData,
    isMulti,
    subSelect,
    onSelectSubOption,
    selectedOptions
}: OptionProps) => {
    const selectedOption = selectedOptions?.find(({id}) => id === optionData.data.id)
    return (
        <StyledOptionContainer
            disabled={optionData.data.disabled}
            key={optionData.data.label}
            onClick={() => {
                !optionData.data.disabled && optionData.selectOption(optionData.data)
            }}
        >
            <StyledOptionBox>
                <StyledOptionItemBox>
                    {isMulti && (
                        <Checkbox
                            id={`${optionData.data.id}`}
                            readOnly
                            name={'readOnly'}
                            checked={!!selectedOption?.id}
                        />
                    )}
                    {showCheckbox && (
                        <Checkbox
                            id={`${optionData.data.id}`}
                            readOnly
                            name={'readOnly'}
                            checked={!!selectedOption?.id}
                        />
                    )}
                </StyledOptionItemBox>
                <div>
                    <StyledOptionSpan disabled={optionData.data.disabled}>{optionData.data.label}</StyledOptionSpan>
                </div>
            </StyledOptionBox>
            {subSelect && (
                <StyledOptionSelectBox>
                    {subSelect && optionData.isSelected && (
                        <SelectForOption
                            options={subSelect}
                            defaultValue={selectedOption?.subOption}
                            placeholder={subSelectPlaceholder}
                            onChange={selectedSubOption => {
                                onSelectSubOption?.(optionData.data, selectedSubOption)
                            }}
                        />
                    )}
                </StyledOptionSelectBox>
            )}
        </StyledOptionContainer>
    )
}
