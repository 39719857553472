import {ChatFill} from '@/assets/icons/icons'
import {Button} from '@/components/ui/button/Button'
import {TextareaWithBannedElementsTrap} from '@/features/generated-content-security/components/text-area-with-word-trap/TextareaWithBannedElementsTrap'
import styled, {css} from 'styled-components'

export const StyledSendFirstMessageToGuestModalContainer = styled.div`
    ${({theme: {spacing}}) => css`
        display: flex;
        flex-direction: column;
        padding: 0 ${spacing * 3}px;
        gap: ${spacing * 3}px;
    `}
`

export const StyledSendFirstMessageToGuestModalDescription = styled.div`
    ${({theme: {typography}}) => css`
        ${typography.textMd}
        text-align: center;
    `}
`

export const StyledSendFirstMessageToGuest = styled.div`
    ${({theme: {spacing, palette}}) => css`
        display: flex;
        background: ${palette.primary[25]};
        align-items: center;
        flex-direction: column;
        padding: ${spacing * 2}px 0 ${spacing}px 0;
    `}
`

export const StyledSendFirstMessageToGuestAvatarContainer = styled.div`
    ${({theme: {spacing}}) => css`
        position: relative;
        margin-bottom: ${spacing * 3.5}px;
    `}
`

export const StyledChatFill = styled(ChatFill)`
    ${({theme: {spacing, mediaQueries}}) => css`
        position: absolute;
        bottom: -${spacing * 1.5}px;
        left: 37px;
        ${mediaQueries.m} {
            left: 65px;
        }
    `}
`

export const StyledTextArea = styled(TextareaWithBannedElementsTrap)`
    ${({theme: {typography, palette}}) => css`
        & > div {
            ${typography.textMd}
            min-width: 200px;
            min-height: 108px;
            max-height: 108px;
            background-color: ${palette.neutral.white};
        }
    `}
`

export const StyledTextAreaWrapper = styled.div`
    ${({theme: {spacing}}) => css`
        padding: 0 ${spacing}px;
        width: 100%;
    `}
`

export const StyledTextGuest = styled.div`
    ${({theme: {typography, spacing}}) => css`
        ${typography.textXs}
        font-weight: 400;
        line-height: 17px;
        margin-bottom: ${spacing}px;
    `}
`

export const StyledButton = styled(Button)`
    ${({theme: {spacing, mediaQueries}}) => css`
        margin: ${spacing}px auto ${spacing * 1}px;
        ${mediaQueries.m} {
            margin: ${spacing * 9}px auto ${spacing * 3}px;
            padding: ${spacing * 2}px ${spacing * 5}px;
        }
    `}
`
