import {MicromorgiIcon} from '@/assets/icons/icons'
import {Modal} from '@/components/ui/modal/Modal'
import {Spinner} from '@/components/ui/spinner/Spinner'
import {MegaGiftSection} from '@/features/mega-gift/components/mega-gift-section/MegaGiftSection'
import {useRootStore} from '@/store'
import {FC, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {css} from 'styled-components'
import {useGetPaidGifs} from '../queries/useGetPaidGifs'
import {Animation} from '../types'
import {
    StyledGif,
    StyledGifAmountContainer,
    StyledGifItem,
    StyledGifTitle,
    StyledGifsList,
    StyledOpenConfirmModalButton
} from './style'
import {MessageTypeE} from '@/utilities/constants/chat'
import {useParams} from 'react-router-dom'
import {useMe} from '@/features/authentication/queries/useMe'
import {useGetChannelDetails} from '@/features/chat/queries/useGetChannelDetails'
import {ChannelID} from '@/features/chat/types'
import {selectBuyMorgisModal, selectConfirmSendGiftModal, selectPaidGifsModal} from '@/store/selectors'
import useSendChatMessage from '@/features/chat/hooks/useSendChatMessage'
import {USER_ROLE_GUEST} from '@/utilities/constants/user'
import {GuestType} from '@/features/authentication/types'

export const OneTimeGifsModal: FC = () => {
    const {t} = useTranslation()
    const {closeModal, hostID, channelID, leaderID, minDonation} = useRootStore(selectPaidGifsModal)
    const {openModal: openConfirmSendGiftModal} = useRootStore(selectConfirmSendGiftModal)
    const {data: gifs, isLoading} = useGetPaidGifs({})

    const {channel} = useParams()
    const {data: user} = useMe()
    const isGuest = user?.type === USER_ROLE_GUEST
    const {data: channelDetails} = useGetChannelDetails(channel as ChannelID)
    const {sendChatMessage, isPending} = useSendChatMessage(channel as ChannelID)
    const [isLoadingSendChatMessage, setIsLoadingSendChatMessage] = useState(false)
    const {openModal: openBuyMorgisModal} = useRootStore(selectBuyMorgisModal)
    const guestMicroMorgiBalance = (user: GuestType) => user?.type_attributes?.micro_morgi_balance

    const onSelectGif = async (gif: Animation) => {
        if (isLoadingSendChatMessage) return null
        if (gif.amount && hostID) {
            openConfirmSendGiftModal({
                hostID,
                amount: gif.amount,
                animationID: gif.id
            })
        } else if (channelID && leaderID) {
            if (user && channelDetails && !isLoadingSendChatMessage) {
                setIsLoadingSendChatMessage(true)
                await sendChatMessage({
                    type: MessageTypeE.GIFT_TRANSACTION,
                    userId: leaderID,
                    animationId: gif.id,
                    micromorgiAmount: 0
                })
                    .then()
                    .finally(() => {
                        setIsLoadingSendChatMessage(false)
                    })

                closeModal()
            }
        }
    }

    return (
        <Modal
            modalStyles={() => css`
                padding-bottom: 20px;
                max-width: 400px;
            `}
            title={t('common:send_gift')}
            minHeight="580px"
            onClose={closeModal}
        >
            <StyledGifsList $isLoaded={!isLoading}>
                {!isLoading ? (
                    <>
                        {gifs?.map(gif => {
                            const disabled = minDonation ? gif.amount < minDonation : false
                            return (
                                !disabled && (
                                    <StyledGifItem key={gif.id}>
                                        <StyledGif src={gif.url} />
                                        <StyledGifTitle>{gif.name}</StyledGifTitle>
                                        {gif.amount ? (
                                            <StyledGifAmountContainer>
                                                <StyledGifAmountContainer>
                                                    <MicromorgiIcon height={14} width={14} />
                                                    {gif.amount}
                                                </StyledGifAmountContainer>
                                            </StyledGifAmountContainer>
                                        ) : (
                                            <br />
                                        )}
                                        <StyledOpenConfirmModalButton
                                            variant="primaryOutlined"
                                            disabled={isLoadingSendChatMessage || isPending}
                                            onClick={() => {
                                                if (
                                                    !isGuest ||
                                                    (isGuest && guestMicroMorgiBalance(user as GuestType) >= gif.amount)
                                                ) {
                                                    onSelectGif(gif)
                                                } else {
                                                    openBuyMorgisModal({})
                                                }
                                            }}
                                        >
                                            {t('common:send_now')}
                                        </StyledOpenConfirmModalButton>
                                    </StyledGifItem>
                                )
                            )
                        })}
                    </>
                ) : (
                    <Spinner />
                )}
            </StyledGifsList>
            {isGuest && <MegaGiftSection hostID={String(hostID)} />}
        </Modal>
    )
}
