import {MicromorgiIcon} from '@/assets/icons/icons'
import {MEGA_GIFT_ID} from '@/features/recurring-gift/gift-modal/GiftModal'
import {FC} from 'react'
import {GiftItem} from '../gift-item/GiftItem'
import {MegaGiftItem} from '../mega-gift-item/MegaGiftItem'
import {StyledAmountItemContainer} from './styles'
import {DonationType} from '@/features/recurring-gift/types'

export type AmountItemProps = {
    amount: DonationType
    isSelected: boolean
    onSelect: () => void
    amountIcon?: JSX.Element
    modalContainer?: HTMLDivElement | null
    disabledByMinAmount?: boolean
    activeSubscriptionAmount?: number
}

export const AmountItem: FC<AmountItemProps> = ({
    amount,
    isSelected,
    onSelect,
    amountIcon = <MicromorgiIcon />,
    modalContainer,
    disabledByMinAmount,
    activeSubscriptionAmount
}) => {
    const disabled = amount.is_locked || disabledByMinAmount

    return (
        <StyledAmountItemContainer
            onClick={!disabled ? onSelect : undefined}
            $isActive={isSelected}
            disabled={disabled}
        >
            {amount.id !== MEGA_GIFT_ID ? (
                <GiftItem
                    modalContainer={modalContainer}
                    amount={amount}
                    amountIcon={amountIcon}
                    activeSubscriptionAmount={activeSubscriptionAmount}
                />
            ) : (
                <MegaGiftItem amount={amount} />
            )}
        </StyledAmountItemContainer>
    )
}
