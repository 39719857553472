import styled, {css} from 'styled-components'

export const StyledTitle = styled.div`
    ${({theme: {spacing}}) => css`
        display: flex;
        align-items: center;
        font-weight: 700;
        gap: 4px;
        margin-bottom: ${spacing * 2}px;
    `}
`
export const StyledGoalIcon = styled.img`
    ${css`
        width: 27px;
        height: 18px;
    `}
`
export const StyledPastGoalsBtn = styled.div`
    ${({theme: {spacing}}) => css`
        cursor: pointer;
        margin-top: ${spacing * 3}px;
        text-align: center;
        font-weight: 700;
        text-decoration: underline;
    `}
`
export const StyledNoGoalsText = styled.span`
    opacity: 0.5;
`
