import {Button} from '@/components/ui/button/Button'
import {Modal} from '@/components/ui/modal/Modal'
import {FC} from 'react'
import {useTranslation} from 'react-i18next'
import {
    InspirationsStarredMessageModalContainer,
    StyledInspirationsStarredMessage,
    StyledInspirationsStarredMessageList
} from './style'

type InspirationsStarredMessageModalProps = {
    onClose: () => void
    leaderName: string
}
const InspirationsStarredMessageModal: FC<InspirationsStarredMessageModalProps> = ({onClose, leaderName}) => {
    const {t} = useTranslation()
    const correctDataToRender = Array.from({length: 15}, (_, index) =>
        t(`starred_message_modal:inspiration_texts:text_${index + 1}`, {LEADER_NAME: leaderName})
    )

    return (
        <Modal title={t('starred_message_modal:inspiration_title')} onClose={onClose}>
            <InspirationsStarredMessageModalContainer>
                <StyledInspirationsStarredMessageList>
                    {correctDataToRender?.map((item, index) => (
                        <StyledInspirationsStarredMessage key={index}>{t(item)}</StyledInspirationsStarredMessage>
                    ))}
                </StyledInspirationsStarredMessageList>
                <Button onClick={onClose}>{t('common:ok_got_it')}</Button>
            </InspirationsStarredMessageModalContainer>
        </Modal>
    )
}
export default InspirationsStarredMessageModal
