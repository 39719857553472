import styled, {css} from 'styled-components'
import SingleSelect from '../../singleSelect/SingleSelect'
import {DESKTOP_TABLE_SCROLLBAR_WIDTH} from '../../table/desktop-table/style'

export const StyledSelectAppsLanguageWrapper = styled.div``

export const StyledSingleSelect = styled(SingleSelect)`
    ${({theme: {palette, typography, spacing}}) => css`
        display: flex;
        flex-direction: row-reverse;
        & .select__control {
            width: 67px;
            height: 33px;
            padding: 0 1.5px;
            min-height: 0;
        }
        & .select__menu {
            width: 67px;
        }
        & .select__single-value {
            ${typography.textXs}
            color: ${palette.neutral[900]};
            font-weight: 600;
            line-height: 15.6px;
            margin-left: ${spacing * 1.5}px;
        }
        & .select__menu-list {
            &::-webkit-scrollbar {
                width: ${DESKTOP_TABLE_SCROLLBAR_WIDTH};
                background-color: ${palette.neutral[400]};
            }
            &::-webkit-scrollbar-thumb {
                background-color: ${palette.neutral[50]};
            }
        }
    `}
`
