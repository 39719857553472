import useMixPanel from '@/integrations/mixpanel/hooks/useMixpanel'
import {StyledArrowIconWrapper, StyledRoot, StyledTelegramIconWrapper} from './style'
import {RightArrowActionIcon, TelegramIcon} from '@/assets/icons/icons'
import {ROUTE_TELEGRAM_BOT} from '@/utilities/constants/routeNames'
import {useTranslation} from 'react-i18next'
import {useNavigate} from 'react-router-dom'
import {MixpanelEventNameE} from '@/integrations/mixpanel/types'

export const TelegramCardHost = ({
    onClickHandler,
    joinedTelegramBot
}: {
    onClickHandler?: () => void
    joinedTelegramBot: boolean
}) => {
    const {t} = useTranslation()
    const navigate = useNavigate()
    const mixpanel = useMixPanel()

    if (joinedTelegramBot) return null

    const handleClickTelegramBotCard = () => {
        navigate(ROUTE_TELEGRAM_BOT)
        onClickHandler?.()
        mixpanel?.trackEvent(MixpanelEventNameE.ConnectTelegramClick, {
            clicked_on: 'Menu'
        })
    }

    return (
        <StyledRoot onClick={handleClickTelegramBotCard}>
            <StyledTelegramIconWrapper>
                <TelegramIcon fill="url(#paint0_linear_6086_2968)" width={34} height={57} />
            </StyledTelegramIconWrapper>

            <p>{t('menu:telegram_notification_bot')}</p>

            <StyledArrowIconWrapper>
                <RightArrowActionIcon width={16} height={16} />
            </StyledArrowIconWrapper>
        </StyledRoot>
    )
}
