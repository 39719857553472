import styled, {DefaultTheme, css} from 'styled-components'

export const StyledFeedBarRoot = styled.div`
    ${({theme: {mediaQueries, spacing}}) => css`
        height: fit-content;
        margin: ${spacing}px 0px;
        display: flex;
        align-items: end;
        justify-content: space-between;
        padding: 0px ${spacing * 1.5}px;
        ${mediaQueries.m} {
            padding: 0;
            width: 100%;
            max-width: 410px;
        }
    `}
`

export const StyledTab = styled.div`
    ${({theme: {spacing}}) => css`
        gap: ${spacing}px;
        cursor: pointer;
        display: flex;
        min-width: 77px;
        align-items: center;
        flex-direction: column;
        justify-content: center;
    `}
`

export const StyledIcon = styled.div`
    ${({theme: {palette}, isActive}: {theme: DefaultTheme; isActive: boolean}) => css`
        width: 30px;
        height: 30px;
        ${!isActive &&
        css`
            & svg {
                & > path {
                    fill: ${palette.primary['900']};
                }
                & > g > path {
                    fill: ${palette.primary['900']};
                }
            }
        `}
    `}
`

export const StyledActiveFilters = styled.div`
    ${({theme: {spacing}}) => css`
        display: flex;
        gap: ${spacing}px;
        align-items: center;
        align-self: end;
        max-width: 340px;
    `}
`

export const StyledSelectedFilters = styled.div`
    ${({theme: {mediaQueries}}) => css`
        width: 100vw;

        ${mediaQueries.m} {
            padding: 0;
            width: auto;
        }
    `}
`
