import {CSSProperties, forwardRef, HTMLInputTypeAttribute, InputHTMLAttributes, ReactNode, useState} from 'react'
import {StyledCount, StyledInput, StyledInputWrapper} from '@components/commons/input-text/style.ts'
import {Flexbox} from '@components/ui/flexbox/FlexBox.tsx'
import {DefaultNamespace} from 'i18next'
import {Label} from '@components/ui/label/Label.tsx'
import {InputStatusIcon} from '@components/ui/input-status-icon/InputStatusIcon.tsx'
import {InputHelpText} from '@components/ui/input-help-text/InputHelpText.tsx'
import {DefaultTheme, FlattenSimpleInterpolation} from 'styled-components'

export interface InputTextProps extends InputHTMLAttributes<HTMLInputElement> {
    label?: DefaultNamespace | ReactNode | string
    name: string
    type?: HTMLInputTypeAttribute
    icon?: ReactNode
    /**
     * how to use errorMessage with translations
     *  errorMessage={t(errors.text?.message || '')} this avoid undefined value problems
     */
    errorMessage?: string | DefaultNamespace | boolean
    helpText?: string | DefaultNamespace
    placeholder?: string
    width?: CSSProperties['width']
    visibilityToggle?: boolean
    touched?: boolean
    showErrorIcon?: boolean
    inputStyles?: (theme: DefaultTheme) => FlattenSimpleInterpolation
    countable?: boolean
    valueLength?: number
}

export const InputText = forwardRef<HTMLInputElement, InputTextProps>(
    (
        {
            label,
            name,
            type = 'text',
            icon,
            errorMessage,
            helpText,
            disabled,
            placeholder,
            width = '100%',
            visibilityToggle = false,
            showErrorIcon = true,
            touched = false,
            inputStyles,
            countable = false,
            maxLength,
            valueLength,
            ...rest
        },
        ref
    ) => {
        const [eyeVisible, setEyeVisible] = useState(false)

        return (
            <Flexbox width={width} direction="column" gap={0}>
                {label && <Label htmlFor={`${name}`} text={label} />}

                <StyledInputWrapper
                    touched={touched}
                    hasError={!!errorMessage}
                    visibilityToggle={visibilityToggle}
                    inputStyles={inputStyles}
                >
                    <StyledInput
                        id={`${name}`}
                        ref={ref}
                        name={name}
                        type={eyeVisible ? 'text' : type}
                        placeholder={placeholder}
                        disabled={disabled}
                        maxLength={maxLength}
                        onKeyDown={e => {
                            if (type === 'number') {
                                const target = e.target as HTMLInputElement // Type assertion
                                if (
                                    e.key === '-' ||
                                    e.key === 'e' ||
                                    e.key === '+' ||
                                    (e.key === '0' && target.value.length < 1) ||
                                    e.key === 'ArrowUp' ||
                                    e.key === 'ArrowDown'
                                ) {
                                    e.preventDefault()
                                }
                            }
                        }}
                        {...rest}
                    />
                    {countable ? (
                        <StyledCount hasError={!!errorMessage}>
                            {valueLength} / {maxLength}
                        </StyledCount>
                    ) : (
                        <InputStatusIcon
                            touched={touched}
                            hasError={!!errorMessage}
                            visibilityToggle={visibilityToggle}
                            showErrorIcon={showErrorIcon}
                            eyeVisible={eyeVisible}
                            setEyeVisible={setEyeVisible}
                            icon={icon}
                        />
                    )}
                </StyledInputWrapper>

                <InputHelpText error={errorMessage} helpText={helpText} />
            </Flexbox>
        )
    }
)

InputText.displayName = 'InputText'
