import * as RPopover from '@radix-ui/react-popover'
import styled, {css} from 'styled-components'

export const StyledPopoverArrow = styled(RPopover.Arrow)`
    ${({theme: {palette}}) => css`
        fill: ${palette.neutral.white};
    `}
`

export const StyledPopoverContent = styled(RPopover.Content)`
    position: relative;
    z-index: 2;
    width: fit-content;
    outline-style: none;
    & span {
        transform: translateY(280%) !important;
        left: 16px !important;
        visibility: visible !important;
        z-index: 2;
    }
`

export const StyledWrapper = styled.div`
    position: relative;
`

export const StyledPopoverAnchor = styled(RPopover.Anchor)`
    ${({$anchorPosition}: {$anchorPosition?: string}) => css`
        position: absolute;
        top: ${$anchorPosition ?? '25%'};
        width: 100%;
        ${!!$anchorPosition &&
        css`
            height: 100%;
        `}
    `}
`
