import {СrownIcon} from '@/assets/icons/icons'
import {Modal} from '@/components/ui/modal/Modal'
import {useTranslation} from 'react-i18next'
import {useMe} from '../../../authentication/queries/useMe'
import {
    StyledAvatarBox,
    StyledButton,
    StyledButtonBox,
    StyledCancelButton,
    StyledCrownIconBox,
    StyledExplanation,
    StyledFooterBox,
    StyledHeader,
    StyledImageSection,
    StyledModalContainer,
    StyledSubTitle,
    StyledTimerBox,
    StyledTitle,
    StyledTitleBox,
    modalStyles
} from './style'
import GoalCountdown from '../../../goal/components/goal-countdown/GoalCountdown'
import {LocalStorageManager} from '@/utilities/localStorage'
import {useSendRequestToGetUltimateStatus} from '../../../gift/queries/useSendRequestToGetUltimateStatus'
import {useEffect} from 'react'
import {convertTimeToLocalFromUTC} from '@/utilities/helpers'
import {Avatar} from '@/components/ui/avatar/Avatar'
import {UltimateStatusContentList} from '../contentList/UltimateStatusContentList'
import {GuestType} from '../../../authentication/types'
import {USER_ROLE_GUEST} from '@/utilities/constants/user'
import {selectChoosePaymentsMethodModal, selectErrorModal, selectGetUltimateStatusModal} from '@/store/selectors'
import {useRootStore} from '@/store'
import {Spinner} from '@/components/ui/spinner/Spinner'

export const UltimateStatusModal = () => {
    const {t} = useTranslation()
    const {data: userData} = useMe()
    const user = userData?.type === USER_ROLE_GUEST ? (userData as GuestType) : null
    const {closeModal} = useRootStore(selectGetUltimateStatusModal)
    const {openModal: openChoosePaymentsMethodModal} = useRootStore(selectChoosePaymentsMethodModal)
    const {openErrorModal} = useRootStore(selectErrorModal)
    const handleCloseModal = () => {
        if (!LocalStorageManager.shownUltimateStatusModalFirstTime.get()) {
            LocalStorageManager.shownUltimateStatusModalFirstTime.set()
        }
        closeModal()
    }

    const {
        mutate: getUltimateStatus,
        error,
        isError,
        isPending
    } = useSendRequestToGetUltimateStatus({
        onSuccess: handleCloseModal
    })

    const ultimateOffer = user?.type_attributes?.ultimate_offer
    const cashbackOffer = user?.type_attributes?.cashback_offer

    const offerEndDateLocal: string = convertTimeToLocalFromUTC({
        date:
            typeof ultimateOffer?.end_date === 'string'
                ? ultimateOffer.end_date
                : typeof cashbackOffer?.end_date === 'string'
                  ? cashbackOffer.end_date
                  : ''
    })

    const handleRefusedUltimateStatus = () => {
        LocalStorageManager.refusedUltimateStatus.set(true)
        handleCloseModal()
    }

    useEffect(() => {
        if (error) {
            if (error?.response?.status === 303) {
                openChoosePaymentsMethodModal({
                    paymentMethodUrls: error?.response?.data,
                    isRecurring: true
                })
            } else {
                openErrorModal({message: error.message})
            }
        }
    }, [isError, error])

    return (
        <Modal fullScreen onClose={handleCloseModal} modalStyles={modalStyles}>
            <StyledModalContainer>
                {isPending && <Spinner />}
                <StyledHeader>
                    <StyledImageSection>
                        <StyledCrownIconBox>
                            <СrownIcon />
                        </StyledCrownIconBox>
                        <StyledAvatarBox>
                            <Avatar
                                height={68}
                                width={68}
                                radius={50}
                                imageUrl={user?.avatar?.url}
                                name={user?.full_name}
                            />
                        </StyledAvatarBox>
                    </StyledImageSection>
                    <StyledTitleBox>
                        <StyledTitle>{t('ultimate_status_modal:title')}</StyledTitle>
                        <StyledSubTitle>
                            {t('ultimate_status_modal:description')}
                            <StyledTimerBox>
                                <GoalCountdown endDate={offerEndDateLocal || ''} />
                            </StyledTimerBox>
                        </StyledSubTitle>
                    </StyledTitleBox>
                </StyledHeader>
                <UltimateStatusContentList />
                <div>
                    <StyledFooterBox>
                        <StyledButtonBox>
                            <StyledButton onClick={() => getUltimateStatus()} fullWidth>
                                {t('ultimate_status_modal:button')}
                            </StyledButton>
                        </StyledButtonBox>
                        <StyledCancelButton onClick={handleRefusedUltimateStatus}>
                            {t('ultimate_status_modal:cancel_button')}
                        </StyledCancelButton>
                        <StyledExplanation>{t('ultimate_status_modal:explanation')}</StyledExplanation>
                    </StyledFooterBox>
                </div>
            </StyledModalContainer>
        </Modal>
    )
}
