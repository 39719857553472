import {Modal} from '@/components/ui/modal/Modal'
import {HostProfile} from '../host-profile/HostProfile'
import {FC, useEffect, useState} from 'react'
import {StyledWrapper, modalStyles} from './style'
import useMixPanel from '@/integrations/mixpanel/hooks/useMixpanel'
import {MixpanelEventNameE} from '@/integrations/mixpanel/types'
import {useRootStore} from '@/store'
import {selectHostProfileModal} from '@/store/selectors.ts'

export const HostProfileModal: FC = () => {
    const {closeModal: onClose, hostUsername} = useRootStore(selectHostProfileModal)
    const mixpanel = useMixPanel()
    const [isWatchedVideo, setIsWatchedVideo] = useState(false)
    const [scrolledImages, setScrolledImages] = useState(1)

    useEffect(() => {
        mixpanel?.startTimeEvent(MixpanelEventNameE.ProfileModalClose)
        mixpanel?.trackEvent(MixpanelEventNameE.ProfileModalOpen, {
            watched_video: isWatchedVideo,
            scrolled_images: scrolledImages
        })
    }, [])

    const handleClose = () => {
        onClose()
        mixpanel?.trackEvent(MixpanelEventNameE.ProfileModalClose, {
            watched_video: isWatchedVideo,
            scrolled_images: scrolledImages
        })
    }

    const handleScrollImages = () => {
        setScrolledImages(prevSpate => prevSpate + 1)
    }

    return (
        <Modal
            fullScreen
            width="100%"
            maxWidth="936px"
            minHeight="657px"
            onClose={handleClose}
            modalStyles={modalStyles}
        >
            <StyledWrapper>
                <HostProfile
                    isModal
                    hostUserName={hostUsername}
                    setIsWatchedVideo={() => setIsWatchedVideo(true)}
                    setScrolledImages={handleScrollImages}
                    scrolledImages={scrolledImages}
                    isWatchedVideo={isWatchedVideo}
                />
            </StyledWrapper>
        </Modal>
    )
}

HostProfileModal.displayName = 'HostProfileModal'
