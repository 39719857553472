import styled, {css} from 'styled-components'

export const StyledSaveButton = styled.div`
    ${({theme: {palette, typography}}) => css`
        color: ${palette.primary['200']};
        ${typography.textMd};
        font-weight: 600;
        & span {
            color: ${palette.primary['700']};
            ${typography.textXs};
            font-weight: 500;
        }
    `}
`

export const StyledSaveButtonContainer = styled.div`
    position: absolute;
    right: 3%;
    top: 27%;
    display: flex;
    gap: 4px;
`
