import styled, {DefaultTheme, css} from 'styled-components'

export const StyledForWhomRoot = styled.div`
    ${({theme: {spacing, palette, mediaQueries}}) => css`
        display: flex;
        align-items: center;
        max-width: 350px;
        gap: ${spacing}px;
        & p {
            color: ${palette.primary['700']};
            max-width: 100%;
            overflow: auto;
            word-wrap: break-word;
        }
        ${mediaQueries.l} {
            width: 100%;
            max-width: 650px;
            margin: ${spacing}px auto 0px;
        }
    `}
`

export const tagIsForOthersStyles = (theme: DefaultTheme) => css`
    background: ${theme.palette.gradient['300']};
    color: ${theme.palette.neutral.white};
    width: fit-content;
    white-space: nowrap;
`
