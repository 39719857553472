import {useQuery} from '@tanstack/react-query'
import {QUERY_KEYS} from './keys'
import {MICROMORGI_API} from '../services/micromorgi.http'

export const useFetchMicromorgiPackages = () => {
    return useQuery({
        queryKey: [QUERY_KEYS.micromorgi_packages],
        queryFn: MICROMORGI_API.getMicromorgiPackages
    })
}
