import {MoengageObjectResponseType} from '../types'

export enum TemplateNamesE {
    host_profile = 'host_profile',
    package_promo = 'package_promo',
    goal_promo = 'goal_promo',
    guest_profile = 'guest_profile',
    take_action = 'take_action',
    referral_bonus = 'referral_bonus'
}

export enum ConnectorNamesE {
    popup = 'popup',
    card = 'card',
    carousel_card = 'carousel_card',
    banner_top_header = 'banner_top_header'
}

/* This function parses the backend response according to correct models needed
 *  for the combination and return the object used in the frontend
 */
export default async (originalResponse: MoengageObjectResponseType | null = null) => {
    // get combination name from response
    let name
    let templateName
    let connectorName: ConnectorNamesE | undefined

    if (originalResponse?.connector?.type === ConnectorNamesE.banner_top_header) {
        name = originalResponse?.connector.type
        connectorName = originalResponse?.connector.type
    } else {
        name = `${originalResponse?.dynamic_template?.type + '__' + originalResponse?.connector.type}`
        templateName = name.split('__')[0]
        connectorName = originalResponse?.connector.type
    }

    let connectorParser
    let templateParser

    // Find correct connector models based on existing combinations
    switch (connectorName) {
        case ConnectorNamesE.card:
            connectorParser = (await import(`../components/connectors/card-connector/CardConnector`)).model
            break
        case ConnectorNamesE.popup:
            connectorParser = (await import(`../components/connectors/popup-connector/PopupConnector`)).model
            break
        case ConnectorNamesE.banner_top_header:
            connectorParser = (
                await import(`../components/connectors/top-header-banner-connector/TopHeaderBannerConnector`)
            ).model
            break
        default:
            return null
    }

    // Find correct template models based on existing combinations
    switch (templateName) {
        case TemplateNamesE.host_profile:
            templateParser = (await import('../components/templates/host-profile-template/HostProfileTemplate')).model
            break
        case TemplateNamesE.package_promo:
            templateParser = (await import('../components/templates/package-promo-template/PackagePromoTemplate')).model
            break
        case TemplateNamesE.goal_promo:
            templateParser = (await import('../components/templates/goal-promo-template/GoalPromoTemplate')).model
            break
        default:
            if (originalResponse?.connector?.type === ConnectorNamesE.banner_top_header) {
                return {
                    name,
                    id: originalResponse?.id,
                    connector: {
                        name: connectorName,
                        ...connectorParser(originalResponse?.connector)
                    },
                    template: null
                }
            } else {
                return null
            }
    }

    if (
        originalResponse?.connector?.type !== ConnectorNamesE.banner_top_header &&
        (!connectorParser || !templateParser)
    ) {
        return null
    }
    const connectorData = originalResponse?.connector
    const templateData = originalResponse?.dynamic_template

    return {
        name,
        id: originalResponse?.id,
        connector: {name: connectorName, ...connectorParser(connectorData)},
        dynamic_template: {name: templateName, ...templateParser(templateData)}
    }
}
