import {HostGoalCard} from '@/features/goal/components/host-goal-card/HostGoalCard'
import {useTranslation} from 'react-i18next'
import {StyledGoalIcon, StyledNoGoalsText, StyledPastGoalsBtn, StyledTitle} from './style'
import goalIcon from '@/assets/images/goalIcon.png'
import {ROUTE_MY_GOALS, ROUTE_PAST_GOALS} from '@/utilities/constants/routeNames'
import {useNavigate} from 'react-router-dom'
import {GoalType} from '@/features/goal/types'
import {FC} from 'react'

type HostProfileGoalsSectionProps = {
    goals: GoalType[]
    hasPastGoals: boolean
    hostFirstName: string
    userGuest?: boolean
    hostId?: number
}

export const HostProfileGoalsSection: FC<HostProfileGoalsSectionProps> = ({
    goals,
    hasPastGoals,
    hostFirstName,
    userGuest,
    hostId
}) => {
    const {t} = useTranslation()
    const navigate = useNavigate()

    const handlePastGoalsClick = () => {
        if (userGuest) {
            navigate(ROUTE_PAST_GOALS.replace(':rookieId', `${hostId}`))
        } else {
            navigate(ROUTE_MY_GOALS)
        }
    }

    return (
        <div>
            <StyledTitle>
                <StyledGoalIcon src={goalIcon} />
                {t('my_goals:active_goals')}
            </StyledTitle>
            {goals?.length ? (
                goals?.map(goal => <HostGoalCard key={goal?.id} goal={goal} />)
            ) : (
                <StyledNoGoalsText>
                    {t('goal_card:has_not_active_goals', {
                        USER: hostFirstName
                    })}
                </StyledNoGoalsText>
            )}
            {hasPastGoals && (
                <StyledPastGoalsBtn onClick={handlePastGoalsClick}>{t('my_goals:past_goals')}</StyledPastGoalsBtn>
            )}
        </div>
    )
}

HostProfileGoalsSection.displayName = 'HostProfileGoalsSection'
