import * as Tabs from '@radix-ui/react-tabs'
import styled, {css, keyframes} from 'styled-components'

const slideAnimation = keyframes`
    from {
        transform: translateX(-50%);
    }
    to {
        transform: translateX(100%);
    }
`

type StyledGiftPanelWrapperProps = {
    $isPlayAnimation: boolean
    $delay: number
}

export const StyledGiftPanelWrapper = styled.div<StyledGiftPanelWrapperProps>`
    ${({$isPlayAnimation, $delay, theme: {palette}}) => css`
        background-color: ${palette.primary[300]};
        position: relative;
        overflow: hidden;
        &:after {
            display: ${$isPlayAnimation ? 'block' : 'none'};
            content: '';
            z-index: 1;
            top: 0;
            left: 0;
            transform: translateX(100%);
            width: 100%;
            height: 100%;
            position: absolute;
            animation: ${slideAnimation} ${$delay}ms;
            background: linear-gradient(
                to right,
                rgba(255, 255, 255, 0) 0%,
                rgba(255, 255, 255, 0.5) 50%,
                rgba(255, 255, 255, 1) 74%,
                rgba(255, 255, 255, 0.8) 76%,
                rgba(255, 255, 255, 0) 77%,
                rgba(255, 255, 255, 0) 79%,
                rgba(255, 255, 255, 0) 92%,
                rgba(255, 255, 255, 0) 100%
            );
        }
    `}
`
export const StyledTabsWrapper = styled(Tabs.Root)`
    display: flex;

    & div:nth-child(2) {
        border-bottom-left-radius: 7px;
    }
    & div:nth-child(3) {
        border-top-left-radius: 7px;
    }
`

export const StyledTabsList = styled(Tabs.List)`
    display: flex;
    flex-direction: column;
    margin: 1px 0;
    overflow: hidden;
`

export const StyledTab = styled(Tabs.Trigger)`
    ${({theme: {palette, spacing}}) => css`
        cursor: pointer;
        z-index: 1
        position: relative;
        border-radius: 7px 0 0 7px;
        padding: ${spacing}px ${spacing * 2}px;
        display: flex;
        justify-content: center;
      
        &[data-state='inactive'] {
            &:first-child path {
                fill: ${palette.primary[950]};
            }
            &:last-child path {
                stroke: ${palette.primary[950]};
            }
        }

        &[data-state='active'] {
            background-color: ${palette.neutral.white};
            position: relative;

            &:first-child {
                &::after {
                    bottom: -4px;
                    right: -4px;
                    transform: rotate(270deg);
                }
            }

            &:last-child {
                &::after {
                    top: -8px;
                    right: -8px;
                }
            }

            &::after {
                content: ' ';
                width: 16px;
                height: 8px;
                border-color: ${palette.neutral.white};
                position: absolute;
                background: radial-gradient(circle at 0 0, rgba(204, 0, 0, 0) 8px, ${palette.neutral.white} 8px);
                z-index: 1;
            }
        }
    `}
`

export const StyledContent = styled(Tabs.Content)`
    ${({theme: {palette}}) => css`
        &:not([hidden]) {
            width: 100%;
            margin: 1px 1px 1px 0px;

            box-shadow: ${palette.neutral[1000]} 0px 1px 6px;

            background-color: ${palette.neutral.white};

            border-top-right-radius: 7px;
            border-bottom-right-radius: 7px;
        }
    `}
`
