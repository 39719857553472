import {Button} from '@/components/ui/button/Button'
import styled, {css} from 'styled-components'

export const StyledWalletModalContainer = styled.div`
    ${({theme: {spacing}}) => css`
        padding: ${spacing * 3}px;
    `}
`
export const StyledTitle = styled.div`
    ${({theme: {typography}}) => css`
        ${typography.textXXl}
        text-align: center;
        font-weight: 400;
        letter-spacing: -0.05em;
    `}
`
export const StyledDescription = styled.div`
    ${({theme: {spacing, typography, palette}}) => css`
        ${typography.textSm}
        color:${palette.neutral[25]};
        margin: ${spacing * 4}px 0;
        text-align: center;
        font-weight: 400;
        line-height: 20px;
    `}
`
export const StyledBalanceWrapper = styled.div`
    ${({theme: {spacing, palette, flexUtils}}) => css`
        position: relative;
        ${flexUtils.centered}
        border: ${spacing / 4}px solid ${palette.neutral[75]};
        height: 140px;
        flex-direction: column;
        border-radius: ${spacing * 1.25}px;
        padding-bottom: calc(52px / 3);
        margin: 10px 0 31px 0;
    `}
`
export const StyledBalanceTitle = styled.div`
    ${({theme: {spacing, typography, palette}}) => css`
        ${typography.textSm}
        position: absolute;
        top: -10px;
        padding: 0 ${spacing * 2}px;
        text-align: center;
        font-weight: 400;
        line-height: 20px;
        background-color: ${palette.neutral.white};
        pointer-events: none;
    `}
`

export const StyledBalance = styled.div`
    ${({theme: {flexUtils, palette, typography}}) => css`
        ${typography.text4Xl}
        ${flexUtils.centered}
        color:${palette.primary[900]};
        align-items: center;
        font-weight: 700;
        letter-spacing: -0.05em;
    `}
`

export const StyledButton = styled(Button)`
    position: absolute;
    bottom: -31px;
    width: fit-content;
`
