import {ReactNode} from 'react'
import {StyledPlayButton, StyledRoot} from './style'
import {DefaultTheme, FlattenSimpleInterpolation} from 'styled-components'

type ResponsiveVideoType = {
    video: string
    adaptToAspectRatio?: boolean
    fullHeight?: boolean
    controls?: boolean
    videoStyles?: (theme: DefaultTheme) => FlattenSimpleInterpolation
    children?: ReactNode
    withPlayButton?: boolean
    className?: string
}
const ResponsiveVideo = ({
    video,
    controls = true,
    children,
    videoStyles,
    withPlayButton = false,
    className
}: ResponsiveVideoType) => {
    return (
        <StyledRoot videoStyles={videoStyles} className={className}>
            {children}
            <video
                onContextMenu={e => {
                    e.preventDefault()
                }}
                controls={controls}
                controlsList="nodownload"
            >
                <source src={`${video}#t=0.1`} />
            </video>
            {withPlayButton && <StyledPlayButton />}
        </StyledRoot>
    )
}

export default ResponsiveVideo
