import {createGlobalStyle} from 'styled-components'

export const GlobalStyles = createGlobalStyle`
  .plyr--full-ui input[type=range]::-webkit-slider-runnable-track,
  .plyr__control--overlaid,
  .plyr--video .plyr__control:hover {
    background: linear-gradient(to right, rgb(134, 73, 209) var(--value, 0), rgb(239 236 255 / 50%) var(--value, 0)) !important;
  }

  .plyr__video-wrapper > video {
    max-height: 100vh;
  }
`
