import {GuestType} from '@/features/authentication/types'
import {useRootStore} from '@/store'
import {selectBuyMorgisModal} from '@/store/selectors'
import {Trans, useTranslation} from 'react-i18next'
import {userStatuses} from '../chatBadge/ChatBadge'
import {StyledPopoverArrow} from '../context-menu/style'
import {Popover} from '../popover/Popover'
import {StatusBadgeTrigger} from './statusBadgeTrigger/StatusBadgeTrigger'
import {StyledLink, StyledTooltipContent, popoverStyles} from './style'
import {replaceThousands} from '@/utilities/helpers'
import {useTheme} from 'styled-components'

export type Status = userStatuses

export const labelBackgroundColor: Record<Status, string> = {
    guest: '#E6E7EC',
    silver: '#E6E7EC',
    gold: '#FED75A',
    diamond: '#A6D7FF',
    ultimate: 'linear-gradient(226deg, #5EDCDC 0%, #EEC5EF 23.64%, #A7D5F4 45.94%, #7DF5ED 73.38%, #F2F2A9 100%)'
}

type StatusBadgeProps = {
    user: GuestType
    isLight?: boolean
}

export const StatusBadge = ({isLight, user}: StatusBadgeProps) => {
    const {t} = useTranslation()
    const theme = useTheme()
    const balance = user?.type_attributes?.micro_morgi_balance
    const guestStatus = user?.type_attributes?.level_status
    const nextDollarAmount = user?.type_attributes?.next_dollar_amount ?? null
    const nextAmountToShow = nextDollarAmount !== null ? replaceThousands(nextDollarAmount) : null
    const {openModal: openBuyMorgisModal} = useRootStore(selectBuyMorgisModal)
    if (!guestStatus) {
        return null
    }
    return (
        <div>
            <Popover
                isModal
                trigger={<StatusBadgeTrigger balance={balance} guestStatus={guestStatus} isLight={isLight} />}
                popoverStyles={() => popoverStyles({theme})}
            >
                <StyledTooltipContent>
                    <StyledPopoverArrow width={14} height={13} />
                    <Trans
                        t={t}
                        i18nKey="leader_status_tooltip:tooltip"
                        values={{
                            context: guestStatus,
                            NEXT_LEVEL_AMOUNT: nextAmountToShow
                        }}
                    />
                    <StyledLink onClick={() => openBuyMorgisModal({})}>
                        {t('leader_status_tooltip:link_text')}
                    </StyledLink>
                </StyledTooltipContent>
            </Popover>
        </div>
    )
}
