import {CloseIcon} from '@/assets/icons/icons'
import {NavLink} from 'react-router-dom'
import styled, {DefaultTheme, FlattenSimpleInterpolation, css} from 'styled-components'

export const StyledRoot = styled.div`
    ${({
        color,
        backgroundColor,
        shouldDisplay = true,
        theme: {mediaQueries, spacing}
    }: {
        color?: string
        backgroundColor?: string
        shouldDisplay?: boolean
        customStyles?: FlattenSimpleInterpolation
        theme: DefaultTheme
    }) => css`
        margin: 0px auto ${spacing}px;
        padding: ${spacing * 2}px;
        transition: transform 0.2s ease-in-out;
        will-change: transform;
        border-radius: 4px;
        pointer-events: auto;
        color: ${color};
        background-color: ${backgroundColor};
        display: ${shouldDisplay ? 'block' : 'none'};
        ${mediaQueries.m} {
            margin: 0 auto ${spacing * 2}px auto;
            right: auto;
            padding: ${spacing * 2}px ${spacing * 2}px;
            max-width: 800px;
            min-width: 600px;
        }
    `}
`

export const StyledContainer = styled.div`
    ${({theme: {spacing}}) => css`
        display: grid;
        grid-template-columns: auto 1fr auto;
        gap: ${spacing * 2}px;
        align-items: center;
    `}
`
export const StyledContent = styled.div`
    ${({theme: {typography}}) => css`
        grid-column: 2;
        display: flex;
        flex-direction: column;
        justify-content: center;
        & h3 {
            font-weight: 700;
            margin: 0;
            ${typography.textSm}
        }
        & div {
            word-break: break-word;
        }
    `}
`

export const StyledCloseIcon = styled(CloseIcon)`
    grid-column: 3;
    cursor: pointer;
`

export const StyledAction = styled(NavLink)(
    ({color, theme: {spacing}}: {color?: string; theme: DefaultTheme}) => css`
        cursor: pointer;
        text-decoration: underline;
        margin-top: ${spacing}px;
        color: ${color};
    `
)
