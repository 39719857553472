import {Carousel} from '@/components/ui/carousel/Carousel'
import {
    StyledContent,
    StyledCoverMediaRoot,
    StyledDetailsCard,
    StyledItemWrapper,
    StyledName,
    cardRootStyles,
    carouselStyles,
    slideStyles
} from './style'
import {GoalStatusesE, GoalType} from '@/features/goal/types'
import {EditIcon} from '@/assets/icons/icons'
import {useTheme} from 'styled-components'
import {HostStatusSection} from './host_status_section/HostStatusSection'
import {GuestStatusSection} from './guest-status-section/GuestStatusSection'
import {MediaLightBox} from '@/components/ui/media-light-box/MediaLightBox'
import {useMemo, useState} from 'react'
import {GoalCardDetails} from '../../goal-card-details/GoalCardDetails'
import {EditMediaModal} from '../../edit-media-modal/EditMediaModal'

type CoverMediaProps = {
    goal?: Partial<GoalType>
    isPastGoal?: boolean
    icon?: JSX.Element
    isHost: boolean
    onEditClick?: () => void
    onWithdrawClick?: () => void
    onShareClick?: () => void
    isPreview?: boolean
}

export const CoverMedia = ({
    goal,
    isPastGoal = false,
    icon,
    isHost,
    onEditClick,
    onWithdrawClick,
    onShareClick,
    isPreview = false
}: CoverMediaProps) => {
    const theme = useTheme()
    const [isPreviewOpen, setIsPreviewOpen] = useState(false)
    const [activeIndex, setActiveIndex] = useState(0)
    const [isOpenEditMediaModal, setIsOpenEditMediaModal] = useState(false)

    const canEditMedia = useMemo(() => {
        switch (goal?.status) {
            case GoalStatusesE.DEFAULT:
            case GoalStatusesE.PENDING:
            case GoalStatusesE.SUSPENDED:
                return true
            default:
                return false
        }
    }, [goal])

    const showEditButton = canEditMedia && isHost && !isPreview

    const remappedMedia = goal?.media?.map(photo => {
        return {
            type: photo?.type,
            url: photo?.url
        }
    })

    const carouselOptions = {
        arrows: false,
        drag: !(remappedMedia?.length === 1)
    }

    const handleChangeMedia = ({newIndex}: {newIndex: number}) => {
        setActiveIndex(newIndex)
    }

    return (
        <StyledCoverMediaRoot>
            <Carousel
                media={remappedMedia ?? []}
                carouselStyles={() => carouselStyles({theme, isPastGoal})}
                slideStyles={() => slideStyles({theme, isPastGoal})}
                videoPreview
                customOptions={carouselOptions}
                onSwipe={index => handleChangeMedia(index)}
                onClickSlide={() => setIsPreviewOpen(true)}
                videoPreviewWithPlayButton
            />

            <StyledContent>
                {/* LEFT SIDE */}
                <StyledItemWrapper isLeftPosition={true}>
                    <StyledName>
                        {icon}
                        <span>{goal?.name}</span>
                    </StyledName>

                    {showEditButton && <EditIcon onClick={() => setIsOpenEditMediaModal(true)} />}
                </StyledItemWrapper>

                {/* RIGHT SIDE */}
                <StyledItemWrapper>
                    {isHost ? (
                        <HostStatusSection goal={goal} onShareClick={onShareClick} />
                    ) : (
                        <GuestStatusSection goal={goal} onShareClick={onShareClick} />
                    )}
                </StyledItemWrapper>
            </StyledContent>
            {goal && (
                <StyledDetailsCard>
                    <GoalCardDetails
                        goal={goal}
                        cardRootStyles={cardRootStyles}
                        isGoalPageVariant
                        onEditClick={onEditClick}
                        onWithdrawClick={onWithdrawClick}
                    />
                </StyledDetailsCard>
            )}

            {isPreviewOpen && (
                <MediaLightBox
                    currentIndex={activeIndex}
                    media={remappedMedia ?? []}
                    onClose={() => setIsPreviewOpen(false)}
                />
            )}
            {isOpenEditMediaModal && <EditMediaModal goal={goal} onClose={() => setIsOpenEditMediaModal(false)} />}
        </StyledCoverMediaRoot>
    )
}
