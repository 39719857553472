import {DefaultTheme, FlattenSimpleInterpolation} from 'styled-components'
import {StyledTagRoot} from './style'

type CardProps = {
    icon?: JSX.Element
    title?: string
    tagStyles?: (theme: DefaultTheme) => FlattenSimpleInterpolation
    onClick?: () => void
    isIconRightPosition?: boolean
}

export const Tag = ({icon, title, tagStyles, onClick, isIconRightPosition = false}: CardProps) => {
    return (
        <StyledTagRoot tagStyles={tagStyles} onClick={onClick}>
            {!!icon && !isIconRightPosition && icon}
            <span>{title}</span>
            {!!icon && isIconRightPosition && icon}
        </StyledTagRoot>
    )
}
