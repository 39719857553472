import {ChatAboutUs, GiftAboutUs, Premium, Social} from '@/assets/icons/icons'
import {Modal} from '@/components/ui/modal/Modal'
import {useRootStore} from '@/store'
import {SplideTrack} from '@splidejs/react-splide'
import {selectAboutUsModal} from '@/store/selectors'
import {Trans, useTranslation} from 'react-i18next'
import {
    StyledButtonBox,
    StyledIconContainer,
    StyledModalContainer,
    StyledModalFooter,
    StyledSplide,
    StyledSplideSlide,
    StyledStrong
} from './style'
import {Button} from '@/components/ui/button/Button'
import {Link, useNavigate} from 'react-router-dom'
import {
    ROUTE_PRIVACY_POLICY,
    ROUTE_SIGNUP_GUEST_SOCIAL,
    ROUTE_SIGNUP_HOST,
    ROUTE_TOS_GUEST
} from '@/utilities/constants/routeNames'
import {useTheme} from 'styled-components'

export const AboutUsModal = () => {
    const {t} = useTranslation()
    const navigate = useNavigate()
    const {closeModal} = useRootStore(selectAboutUsModal)
    const theme = useTheme()
    const messages = [
        {
            icon: <Social />,
            description: 'about_us:first_step_message'
        },
        {
            icon: <ChatAboutUs />,
            description: 'about_us:second_step_message'
        },
        {
            icon: <GiftAboutUs />,
            description: 'about_us:third_step_message'
        },
        {
            icon: <Premium />,
            description: 'about_us:fourth_step_message'
        }
    ]

    const signUpGuest = () => {
        navigate(ROUTE_SIGNUP_GUEST_SOCIAL)
        closeModal()
    }

    const signUpHost = () => {
        navigate(ROUTE_SIGNUP_HOST)
        closeModal()
    }

    return (
        <Modal maxHeight="600px" maxWidth="400px" title={t('about_us:title')} onClose={closeModal}>
            <StyledModalContainer>
                <StyledSplide
                    options={{rewind: true, arrows: false, gap: 32, direction: theme.direction}}
                    hasTrack={false}
                >
                    <SplideTrack>
                        {messages.map(({icon, description}, index) => (
                            <StyledSplideSlide key={index}>
                                <div>
                                    <StyledIconContainer>{icon}</StyledIconContainer>
                                    <Trans t={t} i18nKey={description} components={{bold: <StyledStrong />}} />
                                </div>
                            </StyledSplideSlide>
                        ))}
                    </SplideTrack>
                </StyledSplide>
                <StyledModalFooter>
                    <span> {t('about_us:cta_message')}</span>
                    <StyledButtonBox>
                        <Button onClick={signUpHost}>{t('common:rookie')}</Button>
                        <Button onClick={signUpGuest}> {t('common:leader')}</Button>
                    </StyledButtonBox>
                    <p>
                        <Trans
                            t={t}
                            i18nKey="about_us:disclaimer"
                            components={{
                                a1: <Link target="_blank" to={ROUTE_TOS_GUEST} />,
                                a2: <Link target="_blank" to={ROUTE_PRIVACY_POLICY} />
                            }}
                        />
                    </p>
                </StyledModalFooter>
            </StyledModalContainer>
        </Modal>
    )
}
