import {
    StyledAvatarContainer,
    StyledButton,
    StyledChatFill,
    StyledContainer,
    StyledDescription,
    StyledMessageTemplatesWrapper,
    StyledMessageWrapper,
    StyledTemplateItem,
    StyledTextArea,
    StyledTextAreaWrapper
} from '@/features/profile/components/connect-now-modal/style.ts'
import {Avatar} from '@components/ui/avatar/Avatar.tsx'
import {debounce} from 'lodash'
import {useTranslation} from 'react-i18next'
import {GeneralError, HostType} from '@/features/authentication/types.ts'
import {Button} from '@components/ui/button/Button.tsx'
import {HaveBannedElementObj} from '@/features/generated-content-security/types.ts'

export interface SendMessageProps {
    isPaymentAtFirstMessageEnabled?: boolean
    host?: HostType
    setHaveBannedContent: (content: HaveBannedElementObj | null) => void
    setMessage: (message: string) => void
    error: GeneralError | null
    templateValue: string
    messageTemplateList: string[]
    message: string
    haveBannedContent: HaveBannedElementObj | null
    setTemplateValue: (value: string) => void
    onSubmit: () => void
    triggerMixpanel: () => void
    maxLength?: number
}

export const SendFirstMessage = ({...props}: SendMessageProps) => {
    const {t} = useTranslation()

    const {
        isPaymentAtFirstMessageEnabled,
        host,
        setHaveBannedContent,
        setMessage,
        error,
        templateValue,
        messageTemplateList,
        message,
        haveBannedContent,
        setTemplateValue,
        onSubmit,
        maxLength
    } = props

    return (
        <StyledContainer>
            <StyledDescription>{t('start_connecting_modal:description', {USER: host?.full_name})}</StyledDescription>

            <StyledMessageWrapper>
                <StyledAvatarContainer>
                    <Avatar imageUrl={host?.avatar?.url} name={host?.full_name} height={94} width={94} radius={4} />
                    <StyledChatFill height={27} width={27} />
                </StyledAvatarContainer>

                <StyledTextAreaWrapper>
                    <StyledTextArea
                        placeholder={t('start_connecting_modal:label_leader')}
                        onChange={debounce(setMessage, 300)}
                        getHaveBannedElements={setHaveBannedContent}
                        errorMessage={error?.response?.data?.message || ''}
                        valueToInsert={templateValue}
                        inModal
                        maxChars={maxLength}
                    />
                </StyledTextAreaWrapper>

                <StyledMessageTemplatesWrapper>
                    {messageTemplateList.map((template, index) => {
                        return (
                            <StyledTemplateItem
                                key={index}
                                selected={message?.includes(t(template))}
                                onClick={() => setTemplateValue(t(template))}
                            >
                                {t(template)}
                            </StyledTemplateItem>
                        )
                    })}
                </StyledMessageTemplatesWrapper>
            </StyledMessageWrapper>
            <StyledButton>
                <Button disabled={!message || haveBannedContent?.isBanned} onClick={onSubmit}>
                    {t(
                        isPaymentAtFirstMessageEnabled
                            ? 'pay_at_first_connection:cta_step_0'
                            : 'start_connecting_modal:button_text'
                    )}
                </Button>
            </StyledButton>
        </StyledContainer>
    )
}
