import {useGetFilterChatTopics} from '@/features/chat-fav-topics/queries/useGetFilterChatTopics.ts'
import {FC} from 'react'
import {Topic} from '@/features/chat-fav-topics/types.ts'
import {StyledTopicsChip, StyledTopicsContainer} from '../style'
import {FavoriteChatTopicIcon} from '@/assets/icons/icons'
import {selectChatFavTopics} from '@/store/selectors'
import {useRootStore} from '@/store'

type ChatTopicsFilterCardProps = {
    onSelectItem: (chatTopic: Topic) => void
    selectedItems: Topic[]
}

export const ChatTopicsFilterCard: FC<ChatTopicsFilterCardProps> = ({onSelectItem, selectedItems}) => {
    const {data: chatTopics} = useGetFilterChatTopics()
    const topics = useRootStore(selectChatFavTopics)
    const filteredChatTopics = chatTopics?.filter(topic => topic?.key_name in topics)
    return (
        <StyledTopicsContainer>
            {filteredChatTopics?.map((topic, index) => {
                const isSelected = !!selectedItems.find(item => topic.id === item.id)
                return (
                    <StyledTopicsChip
                        key={index}
                        onClick={() => {
                            onSelectItem(topic)
                        }}
                        isSelected={isSelected}
                    >
                        <FavoriteChatTopicIcon />
                        {topics[topic?.key_name]}
                    </StyledTopicsChip>
                )
            })}
        </StyledTopicsContainer>
    )
}
