import {Popover} from '@/components/ui/popover/Popover'
import styled, {css} from 'styled-components'

export const StyledRoot = styled.div`
    position: absolute;
    top: 40px;
    right: 20px;
`
export const StyledPopover = styled(Popover)`
    box-shadow: none;
    border-radius: 20px;
`

export const StyledToolTipContent = styled.div`
    ${({theme: {spacing, typography, mediaQueries, palette}}) => css`
        ${typography.textXs};
        width: 147px;
        padding: ${spacing}px 4px;
        text-align: center;
        line-height: 16px;
        background-color: ${palette.neutral.white};
        border-radius: 20px;
        box-shadow:
            0 0 20px 4px rgba(154, 161, 177, 0.15),
            0 4px 80px -8px rgba(36, 40, 47, 0.25),
            0 4px 4px -2px rgba(91, 94, 105, 0.15);
        & span {
            text-decoration: underline;
            cursor: pointer;
        }
        &::before {
            content: '';
            position: absolute;
            top: -19px;
            left: 67%;
            transform: translateX(-50%);
            border-width: 10px;
            border-style: solid;
            border-color: transparent transparent ${palette.neutral.white} transparent;
            ${mediaQueries.m} {
                left: 50%;
            }
        }
    `}
`
