export const MUTATION_KEYS = {
    upload_surprise: 'upload-surprise',
    unlock_host_surprise_by_id: 'unlock-host-surprise-by-id'
}

export const QUERY_KEYS = {
    my_surprises: 'my-surprises',
    host_surprises: 'host_surprises',
    unlock_host_surprise: 'unlock-host-surprise'
}
