import {GoalGrandeWhiteIcon, GoalSnapWhiteIcon, GoalSpecialWhiteIcon} from '@/assets/icons/icons'
import {useTranslation} from 'react-i18next'
import {StyledGoalTypeLabel} from './style'

const GoalTypeLabel = ({goalType}: {goalType: string}) => {
    const {t} = useTranslation()

    switch (goalType) {
        case 'small_size_goal':
            return (
                <StyledGoalTypeLabel>
                    <GoalSnapWhiteIcon />
                    <span>{t('create_goal:goal_type_snap')}</span>
                </StyledGoalTypeLabel>
            )
        case 'medium_size_goal':
            return (
                <StyledGoalTypeLabel>
                    <GoalSpecialWhiteIcon />
                    <span>{t('create_goal:goal_type_special')}</span>
                </StyledGoalTypeLabel>
            )
        case 'large_size_goal':
            return (
                <StyledGoalTypeLabel>
                    <GoalGrandeWhiteIcon />
                    <span>{t('create_goal:goal_type_grande')}</span>
                </StyledGoalTypeLabel>
            )
        default:
            return null
    }
}

export default GoalTypeLabel
