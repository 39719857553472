import {QUERY_KEYS as AUTHENTICATION_QUERY_KEYS} from '@/features/authentication/queries/keys'
import {QUERY_KEYS as CHANNELS_QUERY_KEYS} from '@/features/chat/queries/keys'

import {PaymentError} from '@/features/guest-payments/types'
import {useMutation, useQueryClient} from '@tanstack/react-query'
import {AxiosResponse} from 'axios'
import {MEGA_GIFT_API} from '../services/megaGift.http'
import {MUTATION_KEYS} from './keys'

export const useSendMegaGift = ({hostID, onSuccess}: {hostID: string; onSuccess?: () => void}) => {
    const queryClient = useQueryClient()
    return useMutation<AxiosResponse, PaymentError, {hostID: string}>({
        mutationKey: [MUTATION_KEYS.send_mega_gift],
        mutationFn: () => MEGA_GIFT_API.sendMegaGift({hostID}),
        onSuccess: () => {
            onSuccess?.()
            queryClient.invalidateQueries({queryKey: [AUTHENTICATION_QUERY_KEYS.user]})
            queryClient.invalidateQueries({queryKey: [CHANNELS_QUERY_KEYS.fetchChannels]})
        }
    })
}
