import {useMutation, useQueryClient} from '@tanstack/react-query'
import {APPROACH_FIRST_API} from '../services/approachFirst.http'
import {SendApproachFirstBody} from '../types'
import {MUTATION_KEYS, QUERY_KEYS} from './keys'
import {QUERY_KEYS as AUTHENTICATION_QUERY_KEYS} from '@/features/authentication/queries/keys'
import {AxiosResponse} from 'axios'
import {GeneralError} from '@/features/authentication/types'

type SendApproachFirstProps = {
    guestID: string
    body: Omit<SendApproachFirstBody, 'message'>
    onSuccess: () => void
}

export const useSendApproachFirst = (props: SendApproachFirstProps) => {
    const queryClient = useQueryClient()
    return useMutation<AxiosResponse, GeneralError, SendApproachFirstBody>({
        mutationKey: [MUTATION_KEYS.send_approach_first],
        mutationFn: (params: Pick<SendApproachFirstBody, 'message'>) =>
            APPROACH_FIRST_API.sendApproachFirst({...props, body: {...props.body, ...params}}),
        onSuccess: () => {
            props.onSuccess()
            queryClient.invalidateQueries({queryKey: [AUTHENTICATION_QUERY_KEYS.user]})
            queryClient.invalidateQueries({queryKey: [QUERY_KEYS.approach_first]})
        }
    })
}
