import {LanguagesType} from '@/features/country/types'
import {Lens} from '@dhmk/zustand-lens'

type GlobalTranslateState = {
    userID?: string
    translateToggle: boolean
    currentLanguage?: LanguagesType
}

type GlobalTranslateActions = {
    overturnToggleSwitch: () => void
    initialTranslateConfig: (props: GlobalOptionalTranslateState) => void
}

export type GlobalTranslateModal = GlobalTranslateState & GlobalTranslateActions

const initialModal: GlobalTranslateState = {
    translateToggle: false
}

const createGlobalTranslateSlice: Lens<GlobalTranslateModal> = set => ({
    ...initialModal,
    overturnToggleSwitch: () =>
        set(state => {
            state.initialTranslateConfig({translateToggle: !state.translateToggle})
            return {translateToggle: !state.translateToggle}
        }),
    initialTranslateConfig: props =>
        set(state =>
            initialGlobalTranslateInLocalStorage({
                userID: state.userID,
                ...props
            })
        )
})
export default createGlobalTranslateSlice
const GLOBAL_TRANSLATION_IN_LOCAL_STORAGE_KEY_MAP = {
    translation: 'translation'
}

type GlobalOptionalTranslateState = {
    userID?: string
    translateToggle?: boolean
    currentLanguage?: LanguagesType
}

const initialGlobalTranslateInLocalStorage = (props: GlobalOptionalTranslateState) => {
    const {userID, ...extraProps} = props

    const prevTranslationConfig = localStorage.getItem(GLOBAL_TRANSLATION_IN_LOCAL_STORAGE_KEY_MAP.translation) || '{}'
    const parsePrevTranslationConfig = JSON.parse(prevTranslationConfig)

    const translateConfig = Object.assign(parsePrevTranslationConfig, {
        [String(userID)]: {...parsePrevTranslationConfig[String(userID)], ...extraProps}
    })

    localStorage.setItem(GLOBAL_TRANSLATION_IN_LOCAL_STORAGE_KEY_MAP.translation, JSON.stringify(translateConfig))

    return {userID, ...translateConfig[String(userID)]}
}
