import {MoengageObjectResponseType} from '../../types'
import {ConnectorNamesE} from '../../services/moengage.model'
import {connectorsList} from '../connectors'
import {FC, useEffect} from 'react'
import {useSeenMoengageCombination} from '../../queries/useSeenMoengageCombination'
import CTA from '../CTA/CTA'
import PackagePromoTemplate, {PackagePromoProps} from '../templates/package-promo-template/PackagePromoTemplate'
/** This component handles the combination of all "single-host-profile" template
 * along with all suitable connectors
 * */

// Add all possible views for this template
// Current supported connectors: ['popup', 'card', 'mini-popup', 'top-banner', ...] - all
const templateList: {[key: string]: FC<PackagePromoProps>} = {
    [ConnectorNamesE.popup]: PackagePromoTemplate,
    [ConnectorNamesE.card]: PackagePromoTemplate
}

const PackagePromoCombination = ({
    data,
    closeHandler
}: {
    data: MoengageObjectResponseType
    closeHandler: () => void
}) => {
    const {name: connectorName, cta_button_text, cta_button_color} = data.connector

    const correctPackageData = {
        ...data?.dynamic_template?.package,
        id: data?.dynamic_template?.package?.id || 0,
        promo: {
            type: data?.dynamic_template?.promo?.type,
            percent: data?.dynamic_template?.promo?.amount
        }
    }

    const isCTAInConnector = connectorName !== ConnectorNamesE.card

    const CtaComponent = () =>
        data.id ? (
            <CTA
                backgroundColor={cta_button_color}
                small={!isCTAInConnector}
                ctaText={cta_button_text}
                ctaType={data?.dynamic_template?.cta_type}
                promo={data?.dynamic_template?.promo}
                campaignId={data.id}
                packageData={correctPackageData}
            />
        ) : (
            <></>
        )

    const {mutate} = useSeenMoengageCombination()

    useEffect(() => {
        const timer = setTimeout(() => mutate({campaign_id: `${data?.id}`}), 3000)
        return () => clearTimeout(timer)
    }, [])

    if (connectorName) {
        const Template = templateList[connectorName]
        const Connector = connectorsList[connectorName]
        return data?.dynamic_template?.package ? (
            <Connector
                closeHandler={closeHandler}
                {...data.connector}
                CtaComponent={isCTAInConnector ? CtaComponent : undefined}
            >
                <Template
                    correctPackageData={correctPackageData}
                    CtaComponent={!isCTAInConnector ? CtaComponent : undefined}
                />
            </Connector>
        ) : null
    } else {
        return null
    }
}

export default PackagePromoCombination
