import {Animation} from '@/components/ui/animation/Animation'
import alertAnimation from '@/assets/animations/Alert-2.json'
import {
    StyledAnimationBox,
    StyledFailureContainer,
    StyledFailureSubTitle,
    StyledFailureSubTitleBox,
    StyledFailureTitle,
    StyledFailureTitleBox
} from './style'
import {useTranslation} from 'react-i18next'
import {Button} from '@/components/ui/button/Button'

export const FailureStep = ({backToBuyMicromorgiPackage}: {backToBuyMicromorgiPackage: () => void}) => {
    const {t} = useTranslation()
    return (
        <StyledFailureContainer>
            <StyledAnimationBox>
                <Animation animationData={alertAnimation} />
            </StyledAnimationBox>
            <div>
                <StyledFailureTitleBox>
                    <StyledFailureTitle>{t('buy_micromorgies:failure:title')}</StyledFailureTitle>
                </StyledFailureTitleBox>

                <StyledFailureSubTitleBox>
                    <StyledFailureSubTitle>{t('buy_micromorgies:failure:description')}</StyledFailureSubTitle>
                </StyledFailureSubTitleBox>
            </div>

            <div>
                <Button onClick={backToBuyMicromorgiPackage}>{t('buy_micromorgies:failure:button')}</Button>
            </div>
        </StyledFailureContainer>
    )
}
