import {useLexicalComposerContext} from '@lexical/react/LexicalComposerContext'
import {$getRoot, COMMAND_PRIORITY_LOW, KEY_ENTER_COMMAND} from 'lexical'
import {useEffect, useState} from 'react'

export function SendMessagePlugin({
    children,
    sendMessage,
    banned
}: {
    children?: JSX.Element | null
    sendMessage?: () => void
    banned?: boolean
}) {
    const [editor] = useLexicalComposerContext()
    const [isSending, setIsSending] = useState(false)

    const sendMessageHandler = async () => {
        if (banned || isSending) return
        setIsSending(true)
        await sendMessage?.()
        editor.update(() => {
            const root = $getRoot()
            root.getChildren().forEach(n => n.remove())
        })
        setIsSending(false)
    }

    useEffect(() => {
        const onEnterSendMessage = editor.registerCommand(
            KEY_ENTER_COMMAND,
            (event: KeyboardEvent) => {
                event.preventDefault()
                event.stopPropagation()
                if (event.code === 'Enter' && !event.shiftKey) {
                    sendMessageHandler()
                    return true
                } else {
                    return false
                }
            },
            COMMAND_PRIORITY_LOW
        )
        return onEnterSendMessage
    }, [editor, sendMessageHandler])

    return <span onClick={sendMessageHandler}>{children}</span>
}
