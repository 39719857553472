import styled, {DefaultTheme, css, FlattenSimpleInterpolation} from 'styled-components'

export const StyledRoot = styled.div`
    ${({theme: {spacing}}) => css`
        display: flex;
        flex-direction: column;
        gap: ${spacing * 2}px;
    `}
`
export const StyledSection = styled.div`
    ${({
        theme: {spacing},
        sectionStyles
    }: {
        theme: DefaultTheme
        sectionStyles?: () => FlattenSimpleInterpolation
    }) => css`
        padding: ${spacing * 2}px;
        border-radius: 14px;
        box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.25);
        display: flex;
        flex-direction: column;
        gap: ${spacing * 2}px;
        ${sectionStyles && sectionStyles()};
    `}
`
export const StyledSectionTitle = styled.span`
    ${({theme: {typography}}) => css`
        display: flex;
        justify-content: space-between;
        ${typography.textXl}
        font-weight: 700;
    `}
`
