import styled, {css} from 'styled-components'

export const StyledPageHeader = styled.div`
    ${({theme: {spacing}}) => css`
        display: flex;
        justify-content: space-between;
        text-align: center;
        align-items: center;
        grid-template-columns: ${spacing * 3}px 1fr auto;
        width: 100%;
        & span {
            cursor: pointer;
            min-height: 16px;
        }
    `}
`

export const StyledTitle = styled.h1<{$withMargin?: boolean; infoIcon?: boolean}>`
    ${({$withMargin = true, infoIcon, theme: {typography, spacing, mediaQueries}}) => css`
        ${infoIcon &&
        css`
            display: flex;
            align-items: center;
            justify-content: center;
        `};
        ${typography.textXXl};
        font-weight: 500;
        ${$withMargin
            ? css`
                  margin: ${spacing * 2}px auto;
              `
            : ``}

        letter-spacing: -0.05em;
        width: 100%;
        ${mediaQueries.m} {
            ${typography.displayMd};
            margin: ${$withMargin ? `${spacing * 4}px auto` : '0'};
        }
    `}
`

export const StyledInfoBox = styled.div`
    ${({theme: {spacing, mediaQueries}}) => css`
        margin-left: ${spacing}px;
        display: flex;
        ${mediaQueries.m} {
            margin-left: ${spacing * 2}px;
        }
    `}
`
