import {useMe} from '@/features/authentication/queries/useMe'
import {QUERY_KEYS} from '@/features/goal/queries/keys.ts'
import {GOAL_API} from '@/features/goal/services/goal.http.ts'
import {useQuery} from '@tanstack/react-query'

export const useGoalsType = () => {
    const {data: user, loggedIn} = useMe()
    return useQuery({
        queryKey: [QUERY_KEYS.goal_type],
        queryFn: GOAL_API.getGoalsTypes,
        enabled: !!user && !!loggedIn
    })
}
