import styled, {DefaultTheme, css} from 'styled-components'

export const StyledRootWrapper = styled.div`
    ${({theme: {spacing}, promoColor}: {theme: DefaultTheme; promoColor?: string}) => css`
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        position: relative;
        border-radius: 16px;
        background: rgba(249, 249, 249, 0.94);
        padding: ${spacing * 3.75}px ${spacing * 1.5}px;
        border: 2px solid ${promoColor};
        gap: ${spacing * 2.25}px;
    `}
`

export const StyledPromoWrapper = styled.div`
    ${({promoColor}: {promoColor?: string}) => css`
        display: flex;
        justify-content: center;
        align-items: center;
        min-width: 110px;
        min-height: 115px;
        border: 3px solid ${promoColor};
        box-shadow: 0px 2px 10px 0px rgba(134, 73, 209, 0.2);
        position: relative;
        border-radius: 10px;
    `}
`

export const StyledPromoLabel = styled.div`
    ${({
        theme: {spacing, typography, palette},
        promoType,
        promoLabelGradients
    }: {
        theme: DefaultTheme
        promoType?: string
        promoLabelGradients?: string
    }) => css`
        position: absolute;
        bottom: -19px;
        left: -12px;
        min-width: 130px;
        padding: ${spacing * 0.75}px;
        border-radius: 100px;
        text-align: center;
        ${typography.textMd}
        font-weight: 700;
        color: ${promoType === 'cashback' ? palette.neutral.black : palette.neutral.white};
        box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
        letter-spacing: -0.3px;
        background: ${promoLabelGradients};
    `}
`

export const StyledDiscountSection = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
`

export const StyledDiscountOldValue = styled.div`
    ${({theme: {typography}}) => css`
        ${typography.textSm}
        font-weight: 500;
        color: #858585;
        text-decoration: line-through solid red;
    `}
`

export const StyledDiscountAmount = styled.div`
    ${({theme: {palette, typography}}) => css`
        ${typography.text4Xl}
        font-weight: 700;
        letter-spacing: -1.5px;
        color: ${palette.primary[200]};
    `}
`

export const StyledCashbackTitle = styled.p`
    ${({theme: {typography}}) => css`
        ${typography.textSm}
        font-weight: 500;
        color: #858585;
    `}
`

export const StyledOfferAmount = styled.div`
    ${({theme: {palette, typography}}) => css`
        ${typography.text4Xl}
        font-weight: 700;
        color: ${palette.primary[200]};
        display: flex;
        align-items: center;
        min-width: 100px;
        justify-content: center;
    `}
`

export const StyledImageWrapper = styled.div`
    position: absolute;
    top: -45px;
    right: 4px;

    & img {
        width: 97px;
    }
`

export const StyledMorgiCashback = styled.img`
    width: 35px;
    height: 35px;
`

export const StyledOfferCTA = styled.div`
    ${({theme: {spacing, mediaQueries}}) => css`
        ${mediaQueries.m} {
            padding: ${spacing}px ${spacing * 2.5}px;
        }
    `}
`
