import {Modal} from '@/components/ui/modal/Modal'
import {useMe} from '@/features/authentication/queries/useMe'
import {HaveBannedElementObj} from '@/features/generated-content-security/types'
import {useGetHost} from '@/features/guest/queries/useGetHost'
import {useHandleError} from '@/hooks/useHandleError'
import useRedirectToChatChannel from '@/hooks/useRedirectToChatChannel'
import {useRootStore} from '@/store'
import {selectConnectModal} from '@/store/selectors'
import {useEffect, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {useSendFirstMessageToHost} from '../../queries/useSendFirstMessageToHost'
import useMixPanel from '@/integrations/mixpanel/hooks/useMixpanel'
import {UserRoleE} from '@/types'
import {MixpanelEventNameE} from '@/integrations/mixpanel/types'
import {Spinner} from '@/components/ui/spinner/Spinner'
import {GeneralError, GuestType, HostType} from '@/features/authentication/types.ts'
import {SendFirstMessage} from '@/features/profile/components/connect-now-modal/components/SendFirstMessage.tsx'
import {SendFirstPaidMessage} from '@/features/profile/components/connect-now-modal/components/SendFirstPaidMessage.tsx'
import {USER_ROLE_GUEST} from '@/utilities/constants/user'
import {ReachedLimitModal} from '@/features/gift/reachedLimitModal/ReachedLimitModal'
import {StyledWrapper} from './style'
import {TagManager} from '@/integrations/tag-manager/tagManager'
import {CHAT_API} from '@/features/chat/services/chat.http'

const messageTemplateList = [
    'start_connecting_modal:message_template_1',
    'start_connecting_modal:message_template_2',
    'start_connecting_modal:message_template_3',
    'start_connecting_modal:message_template_4',
    'start_connecting_modal:message_template_5',
    'start_connecting_modal:message_template_6',
    'start_connecting_modal:message_template_7',
    'start_connecting_modal:message_template_8',
    'start_connecting_modal:message_template_9'
]

export const ConnectNowModal = () => {
    const {t} = useTranslation()
    const {isOpen, hostID, closeModal, mixpanelData} = useRootStore(selectConnectModal)
    const [message, setMessage] = useState<string>('')
    const [templateValue, setTemplateValue] = useState<string>('')
    const [haveBannedContent, setHaveBannedContent] = useState<HaveBannedElementObj | null>(null)
    const [step, setStep] = useState(0)
    const {data: userData} = useMe()
    const [visibleReachedLimit, setVisibleReachedLimit] = useState(false)
    const {
        data: host,
        isError: isHostProfileError,
        error: hostProfileError,
        isLoading
    } = useGetHost({filter: `${hostID}`})
    const redirectToChatChannel = useRedirectToChatChannel({
        receiverId: parseInt(`${hostID}`)
    })
    const mixpanel = useMixPanel()
    const user = userData?.type === USER_ROLE_GUEST ? (userData as GuestType) : null

    const isFirstTransaction = user?.type_attributes.is_first_transaction
    const canOpenConnection = user?.type_attributes.can_open_connection
    const isOpenConnection = isFirstTransaction && !canOpenConnection

    const eventData = {
        userRole: UserRoleE.Guest,
        userId: `${hostID}`,
        mixpanelData: mixpanelData
    }

    useEffect(() => {
        if (isOpen) {
            setVisibleReachedLimit(true)
        }
    }, [isOpen])

    const onCloseReachedLimit = () => {
        setVisibleReachedLimit(false)
        closeModal()
    }

    useEffect(() => {
        mixpanel?.triggerBackendEvent({
            eventType: MixpanelEventNameE.OpenConnectionClick,
            ...eventData
        })
    }, [])

    const triggerMixpanel = () => {
        mixpanel?.triggerBackendEvent({
            eventType: MixpanelEventNameE.OpenConnectionSuccess,
            ...eventData
        })
    }
    const onSuccess = () => {
        closeModal()
        triggerMixpanel()
        TagManager.dataLayer({
            event: 'friend_new_connection_opened_success_success'
        })
    }

    const {mutateAsync, isPending, error: sendError, isError: isSendError} = useSendFirstMessageToHost(onSuccess)

    const isError = isSendError || isHostProfileError
    const error = hostProfileError || sendError

    useHandleError({isError, error})

    const isPaymentAtFirstMessageEnabled = false

    const onSubmit = async () => {
        if (user?.id) {
            if (isPaymentAtFirstMessageEnabled) {
                setStep(1)
            } else {
                const {language} = await CHAT_API.getMessageLanguage(message)
                await mutateAsync({message: message, hostId: Number(hostID), language})
                redirectToChatChannel?.()
            }
        }
    }

    const sendMessageProps = {
        isPaymentAtFirstMessageEnabled,
        setHaveBannedContent,
        host: host as HostType,
        setMessage,
        error: error as GeneralError | null,
        templateValue,
        messageTemplateList,
        message,
        haveBannedContent,
        setTemplateValue,
        onSubmit,
        triggerMixpanel,
        maxLength: 120
    }

    const title = isPaymentAtFirstMessageEnabled
        ? step === 0
            ? 'pay_at_first_connection:title_step_0'
            : 'pay_at_first_connection:title_step_1'
        : 'start_connecting_modal:title'

    return (
        <>
            {isOpenConnection ? (
                visibleReachedLimit && <ReachedLimitModal onCloseCallBack={onCloseReachedLimit} />
            ) : (
                <Modal
                    title={t(title, {USER: host?.full_name})}
                    onClose={closeModal}
                    maxHeight="600px"
                    height="90%"
                    minHeight="460px"
                    width="90vw"
                    maxWidth="400px"
                >
                    {isLoading ? (
                        <Spinner />
                    ) : (
                        <StyledWrapper>
                            {isPending && <Spinner />}

                            {!isPaymentAtFirstMessageEnabled ? (
                                <SendFirstMessage {...sendMessageProps} />
                            ) : (
                                <>
                                    {step === 0 ? (
                                        <SendFirstMessage {...sendMessageProps} />
                                    ) : (
                                        <SendFirstPaidMessage {...sendMessageProps} />
                                    )}
                                </>
                            )}
                        </StyledWrapper>
                    )}
                </Modal>
            )}
        </>
    )
}
