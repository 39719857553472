import {useQuery} from '@tanstack/react-query'
import {QUERY_KEYS} from './keys'
import {GUEST_API} from '../services/guest.http'

export const useGetSavedHosts = () => {
    return useQuery({
        queryKey: [QUERY_KEYS.saved_hosts],
        queryFn: () => GUEST_API.getSavedHosts()
    })
}
