import {
    StyledBgIcon,
    StyledPagination,
    StyledPaginationItem,
    StyledRoot,
    carouselStyles,
    ctaBarStyles,
    StyledExpertiseIconContainer,
    StyledOpenRandomSurpriseCTA
} from './style'
import {useTheme} from 'styled-components'
import {FC, useEffect, useMemo, useState} from 'react'
import {MediaTypeE} from '@/types'
import {CATEGORY_ART_EXPERTISE, CATEGORY_SPORT_EXPERTISE} from '@/utilities/constants/expertises'
import {MusicNotesIcon} from '@/assets/icons/icons'
import medalIcon from '@assets/images/Medal.png'
import HostProfileVerificationIcon from '../host-profile-verification-icon/HostProfileVerificationIcon'
import useWindowDimensions from '@/hooks/useWindowDimensions'
import {CtaBar} from '../cta-bar/CtaBar'
import {ProfileEventsPropsType} from '@/integrations/mixpanel/types'
import {useGetConnectedUser} from '@/hooks/useGetConnectedUser'
import {SubscriptionStatusesE} from '@/features/chat/helpers/helpers'
import {HostPartialType} from '@/features/authentication/types'
import {ExpertiseIcon} from '@/components/commons/expertise-icon/ExpertiseIcon'
import {ProfileCarousel} from '@/components/ui/profile-carousel/ProfileCarousel'
import {Photos, addSurprisesToPhotos} from '../../helpers/helpers'
import {Button} from '@/components/ui/button/Button'
import {useRootStore} from '@/store'
import {selectOpenSurprisesModal} from '@/store/selectors'
import noAvatar from '@/assets/images/noAvatar.png'
import surpriseImage from '@/assets/images/my-surprises/new_surprises.png'
import {useTranslation} from 'react-i18next'

type HostProfilePhotoSliderProps = {
    photos: Photos[]
    isGuest?: boolean
    isSpecial?: boolean
    expertise?: string
    expertiseCategory?: string
    isVerifiedHost?: boolean
    hostGenderId?: number
    hostName?: string
    setScrolledImages?: () => void
    host?: HostPartialType
    mixpanelData: ProfileEventsPropsType
    handleSetEndTime?: () => void
    isInFeed?: boolean
}

export const HostProfilePhotoSlider: FC<HostProfilePhotoSliderProps> = ({
    photos,
    isGuest,
    isSpecial = false,
    expertiseCategory,
    expertise,
    isVerifiedHost,
    hostGenderId,
    hostName,
    setScrolledImages,
    host,
    mixpanelData,
    handleSetEndTime,
    isInFeed = false
}) => {
    const theme = useTheme()
    const {openModal: openSurprisesModal} = useRootStore(selectOpenSurprisesModal)
    const {width} = useWindowDimensions()
    const {t} = useTranslation()
    const [isMobile, setIsMobile] = useState(width <= 448)
    const [activeIndex, setActiveIndex] = useState(0)
    const {connectedUser: connectedHost} = useGetConnectedUser(host?.id)
    const subscriptionStatusActive =
        !!connectedHost && connectedHost?.subscription_status === SubscriptionStatusesE.ACTIVE

    const BLOCKED_PHOTO_INDEX = 4

    useEffect(() => {
        setIsMobile(width <= 448)
    }, [width])

    const hostCardClosedRatio = 390 / 302
    const hostCardOpenRatio = 478 / 344

    const carouselOptions = {
        arrows: false,
        heightRatio: isMobile ? hostCardOpenRatio : hostCardClosedRatio,
        drag: !(photos?.length === 1)
    }

    const renderCorrectBgIcon = () => {
        switch (expertiseCategory) {
            case CATEGORY_ART_EXPERTISE:
                return <MusicNotesIcon />
            case CATEGORY_SPORT_EXPERTISE:
                return <img src={medalIcon} />
            default:
                return null
        }
    }
    const onSwipeCarousel = ({newIndex}: {newIndex: number}) => {
        setScrolledImages?.()
        setActiveIndex(newIndex)
    }

    const photosWithSurprises = useMemo(() => addSurprisesToPhotos(photos || []), [photos])
    const photosToRemap = subscriptionStatusActive ? photosWithSurprises : photos

    const remappedMedia = photosToRemap?.length
        ? photosToRemap?.map(photo => ({
              type: MediaTypeE.IMAGE,
              url: photo?.url,
              surprise: !!photo?.surprise && (
                  <StyledOpenRandomSurpriseCTA>
                      <img src={surpriseImage} alt="random surprise" />
                      <Button
                          onClick={() =>
                              !!host?.id &&
                              !!host?.username &&
                              openSurprisesModal({
                                  hostId: host.id,
                                  hostUserName: host.username
                              })
                          }
                      >
                          {t('rookie_profile_preview:host_surprises:random_button_text')}
                      </Button>
                  </StyledOpenRandomSurpriseCTA>
              )
          }))
        : [{type: MediaTypeE.IMAGE, url: noAvatar}]

    const isShowCta = subscriptionStatusActive || (!subscriptionStatusActive && !(activeIndex === BLOCKED_PHOTO_INDEX))

    const correctPhotosArray = isInFeed ? photos : remappedMedia

    const randomSurpriseIndex =
        !!remappedMedia?.length && remappedMedia?.findIndex(el => 'surprise' in el && el?.surprise)
    const expertisesOnBlockedImage = !subscriptionStatusActive && activeIndex === BLOCKED_PHOTO_INDEX
    const showExpertisesIcon =
        isSpecial && expertise && !isInFeed && !expertisesOnBlockedImage && !(activeIndex === randomSurpriseIndex)

    return (
        <StyledRoot>
            {showExpertisesIcon && (
                <StyledExpertiseIconContainer>
                    <ExpertiseIcon expertise={expertise} expertiseCategory={expertiseCategory} />
                </StyledExpertiseIconContainer>
            )}
            {showExpertisesIcon && <StyledBgIcon>{renderCorrectBgIcon()}</StyledBgIcon>}
            <ProfileCarousel
                media={correctPhotosArray ?? []}
                carouselStyles={() => carouselStyles({theme, isSpecial, isInFeed})}
                customOptions={carouselOptions}
                withOverlay
                onSwipe={onSwipeCarousel}
                host={host}
                isGuest={isGuest}
                isInFeed={isInFeed}
                subscriptionStatusActive={subscriptionStatusActive}
            />
            {correctPhotosArray?.length > 1 && (
                <StyledPagination isInFeed={isInFeed} photoCount={correctPhotosArray?.length}>
                    {correctPhotosArray?.map((_, idx) => (
                        <StyledPaginationItem key={idx} isActive={idx === activeIndex} />
                    ))}
                </StyledPagination>
            )}
            {isVerifiedHost && <HostProfileVerificationIcon hostGenderId={hostGenderId} hostName={hostName} />}
            {isGuest && isShowCta && !isInFeed && (
                <CtaBar
                    host={host}
                    ctaBarStyles={() => ctaBarStyles({theme})}
                    mixpanelData={mixpanelData}
                    handleSetEndTime={handleSetEndTime}
                />
            )}
        </StyledRoot>
    )
}

HostProfilePhotoSlider.displayName = 'HostProfilePhotoSlider'
