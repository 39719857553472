import {BannedElement, ValidationResult} from '../types'

export const validateString = (
    text: string | null,
    bannedElementsList: BannedElement[],
    badElementsList: BannedElement[]
): ValidationResult => {
    let bannedString = false
    let validLink = false
    let numberOfBannedElements = 0

    // get filtered banned elements array with all keys which we need
    const filteredBannedElements: BannedElement[] = badElementsList.map(item => {
        const matchString = bannedElementsList.find(el => el.url && el.url.includes(`${item.host?.toLowerCase()}`))
        if (matchString) {
            return {
                ...item,
                type: matchString.type
            }
        } else {
            return {
                ...item,
                type: 'unlisted'
            }
        }
    })

    // replace all bad words, links, numbers
    const filteredMessage = text?.replace(
        new RegExp(
            filteredBannedElements.map(({string}) => string && string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&')).join('|'),
            'gi'
        ),
        item => {
            const banned = filteredBannedElements.find(el => el.string === item && el.type === 'hidden')
            const good = filteredBannedElements.find(el => el.string === item && el.type === 'valid')
            const number = filteredBannedElements.find(el => el.string === item && el.type === 'number')
            const word = filteredBannedElements.find(
                el => el.string && el.string.toLowerCase() === item.toLowerCase() && el.type === 'word'
            )
            if (banned) {
                bannedString = true
                numberOfBannedElements = numberOfBannedElements + 1
                return `<span>${item}</span>`
            } else if (good) {
                validLink = true
                numberOfBannedElements = numberOfBannedElements + 1
                return `<span>${good.host}</span>`
            } else if (number) {
                bannedString = true
                numberOfBannedElements = numberOfBannedElements + 1
                return `<span >${item}</span>`
            } else if (word) {
                bannedString = true
                numberOfBannedElements = numberOfBannedElements + 1
                return `<span>${item}</span>`
            } else {
                return `${item}`
            }
        }
    )

    return {
        filteredMessageText: filteredMessage,
        bannedString,
        validLink,
        filteredBannedElements,
        numberOfBannedElements
    }
}
