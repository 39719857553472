import {InputHelpText} from '@/components/ui/input-help-text/InputHelpText'
import {DefaultNamespace} from 'i18next'
import React, {ButtonHTMLAttributes} from 'react'
import {StyledSwitchRoot, StyledSwitchThumb, StyledToggleBox, StyledToggleContainer, StyledToggleSpan} from './style'

export interface ToggleSwitchProps extends ButtonHTMLAttributes<HTMLButtonElement> {
    id?: string
    label?: string | DefaultNamespace | JSX.Element
    labelPosition?: 'left' | 'right'
    isSmall?: boolean
    disabled?: boolean
    checked?: boolean
    isLight?: boolean
    isChecked?: boolean
    errors?: {
        message: string
        type?: string
        shouldFocus?: boolean
    }
}

const ToggleSwitch = React.forwardRef<HTMLButtonElement, ToggleSwitchProps>(
    (
        {
            label,
            labelPosition = 'right',
            errors,
            isSmall,
            disabled,
            isLight = false,
            isChecked = false,
            ...rest
        }: ToggleSwitchProps,
        ref
    ) => {
        return (
            <>
                <StyledToggleContainer labelPosition={labelPosition}>
                    {!!label && <StyledToggleSpan>{label}</StyledToggleSpan>}
                    <StyledToggleBox>
                        <StyledSwitchRoot
                            disabled={!!disabled}
                            $isSmall={!!isSmall}
                            ref={ref}
                            $isLight={isLight}
                            $isChecked={isChecked}
                            {...rest}
                        >
                            <StyledSwitchThumb $isSmall={!!isSmall} $isLight={isLight} $isChecked={isChecked} />
                        </StyledSwitchRoot>
                    </StyledToggleBox>
                </StyledToggleContainer>
                {!!errors && disabled && <InputHelpText error={errors.message} />}
            </>
        )
    }
)

ToggleSwitch.displayName = 'ToggleSwitch'

export default ToggleSwitch
