import {useMe} from '@/features/authentication/queries/useMe'
import {useEffect, useState} from 'react'
import {useTheme} from 'styled-components'
import useWindowDimensions from './useWindowDimensions'

const headerBannerID = 'headerTopBanner'
const useContainerDimensions = () => {
    const {sizing, breakpoints} = useTheme()
    const {height, width} = useWindowDimensions()
    const {data: user, loggedIn} = useMe()

    const [isMobile, setIsMobile] = useState(width <= breakpoints.width.m)
    const [headerTopBanner, setHeaderTopBanner] = useState(document.getElementById(headerBannerID))

    const [heightOfContainer, setHeightOfContainer] = useState<number>(
        height -
            +(isMobile ? sizing.headerMobileHeight.replace('px', '') : sizing.headerDesktopHeight.replace('px', '')) -
            +(headerTopBanner ? headerTopBanner.offsetHeight : 0)
    )

    useEffect(() => {
        if ((user && loggedIn) || !loggedIn) {
            const newHeight =
                height -
                +(isMobile
                    ? sizing.headerMobileHeight.replace('px', '')
                    : sizing.headerDesktopHeight.replace('px', '')) -
                +(headerTopBanner ? headerTopBanner.offsetHeight : 0)
            if (newHeight !== heightOfContainer) setHeightOfContainer(newHeight)
        }
    }, [height, isMobile, heightOfContainer, headerTopBanner, user])

    useEffect(() => {
        if (user) {
            setHeaderTopBanner(document.getElementById(headerBannerID))
        }
    }, [user])

    useEffect(() => {
        setIsMobile(width <= breakpoints.width.m)
    }, [width])

    return {height: heightOfContainer, width, heightOfHeader: height - heightOfContainer}
}
export default useContainerDimensions
