import {CloseIcon, FilterByPathActiveIcon, FilterTabIcon, GoalsTabIcon, HostsTabIcon} from '@/assets/icons/icons'
import {SelectedFiltersList} from '@/components/commons/selected-filters-list/SelectedFiltersList'
import useContainerDimensions from '@/hooks/useContainerDimensions'
import {useRootStore} from '@/store'
import {selectGoalFilters} from '@/store/selectors'
import {breakpoints} from '@/theme/mediaQueries'
import {ROUTE_GOALS_MAIN_PAGE, ROUTE_HOME} from '@/utilities/constants/routeNames'
import {FC, useEffect, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {useNavigate} from 'react-router-dom'
import {FeedVariantE} from '../../constants'
import {StyledActiveFilters, StyledFeedBarRoot, StyledIcon, StyledSelectedFilters, StyledTab} from './style'
import {useMe} from '@/features/authentication/queries/useMe'
import {GuestType} from '@/features/authentication/types'
import {remapHostActiveFilters} from '../../helpers/helpers'
import {useHandleError} from '@/hooks/useHandleError'
import {useClearActiveFilters} from '../../queries/useClearActiveFilters'
import {Spinner} from '@/components/ui/spinner/Spinner'
import {useTheme} from 'styled-components'
import useFeedStore from '../../store'

type FeedBarProps = {
    onFilterClick: () => void
    variant: FeedVariantE
}

const feedTabs = {
    host: {
        id: 'host',
        icon: <HostsTabIcon />,
        name: 'main_leader_tabs:hosts'
    },
    goal: {
        id: 'goal',
        icon: <GoalsTabIcon />,
        name: 'main_leader_tabs:goals'
    }
}

export const FeedBar: FC<FeedBarProps> = ({onFilterClick, variant}) => {
    const {t} = useTranslation()
    const navigate = useNavigate()
    const {spacing} = useTheme()
    const {width} = useContainerDimensions()
    const setIsFetchingWithHostFilters = useFeedStore(state => state.setIsFetchingWithHostFilters)
    const [isMobile, setIsMobile] = useState(width <= breakpoints.width.m)

    // Host filters
    const {data: user} = useMe()
    const guest = user as GuestType
    const guestHasActiveFilters = !!guest?.type_attributes?.leaders_carousels_filters
    const currentFilters = guest?.type_attributes?.leaders_carousels_filters?.filters
    const remappedActiveHostFilters = currentFilters ? remapHostActiveFilters(currentFilters) : []
    const {
        mutate: clearHostFilters,
        isPending: isClearFiltersPending,
        isError: isClearFiltersError,
        error: clearFiltersError
    } = useClearActiveFilters()

    useHandleError({isError: isClearFiltersError, error: clearFiltersError})

    // Goal filters
    const {filters, clearGoalFilters} = useRootStore(selectGoalFilters)
    const selectedGoalFilters = filters.selectedFiltersNames
    const remapSelectedGoalFilters = selectedGoalFilters.map(item => ({
        label: item,
        id: item
    }))

    const hasSelectedFilters = variant === FeedVariantE.goals ? !!selectedGoalFilters.length : guestHasActiveFilters
    const showGoalActiveFilters = !!hasSelectedFilters && !isMobile
    const showHostActiveFilters = guestHasActiveFilters && !!remappedActiveHostFilters.length
    const showActiveFiltersSection = variant === FeedVariantE.goals ? showGoalActiveFilters : showHostActiveFilters

    const clearFilters = () => {
        if (variant === FeedVariantE.goals) {
            clearGoalFilters()
        } else {
            clearHostFilters()
            setIsFetchingWithHostFilters(true)
        }
    }

    useEffect(() => {
        setIsMobile(width <= breakpoints.width.m)
    }, [width])

    return (
        <div>
            {isClearFiltersPending && <Spinner />}
            <StyledFeedBarRoot>
                <StyledTab>
                    {hasSelectedFilters ? (
                        <StyledActiveFilters>
                            <FilterByPathActiveIcon onClick={onFilterClick} /> <CloseIcon onClick={clearFilters} />
                        </StyledActiveFilters>
                    ) : (
                        <span>
                            <FilterTabIcon onClick={onFilterClick} />
                        </span>
                    )}
                    {t('main_leader_tabs:filter')}
                </StyledTab>
                <StyledTab onClick={() => navigate(ROUTE_HOME)}>
                    <StyledIcon isActive={variant === FeedVariantE.hosts}>{feedTabs.host.icon}</StyledIcon>
                    {t(feedTabs.host.name)}
                </StyledTab>
                <StyledTab onClick={() => navigate(ROUTE_GOALS_MAIN_PAGE)}>
                    <StyledIcon isActive={variant === FeedVariantE.goals}>{feedTabs.goal.icon}</StyledIcon>
                    {t(feedTabs.goal.name)}
                </StyledTab>
            </StyledFeedBarRoot>

            {showActiveFiltersSection && (
                <StyledSelectedFilters>
                    <SelectedFiltersList
                        bottomPadding={isMobile ? `${spacing}px` : undefined}
                        allSelectedFilters={
                            variant === FeedVariantE.goals ? remapSelectedGoalFilters : remappedActiveHostFilters
                        }
                    />
                </StyledSelectedFilters>
            )}
        </div>
    )
}
