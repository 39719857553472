import {Button} from '@/components/ui/button/Button'
import styled, {css} from 'styled-components'

export const StyledErrorModalContainer = styled.div`
    ${({theme: {spacing}}) => css`
        display: flex;
        flex-direction: column;
        padding: ${spacing * 3}px;
        gap: ${spacing * 2}px;
    `}
`
export const StyledErrorDescription = styled.span`
    ${({theme: {typography}}) => css`
        ${typography.textMd}
        text-align: center;
    `}
`
export const StyledOkButton = styled(Button)`
    ${({theme: {typography}}) => css`
        ${typography.textMd}
        font-weight: 600;
    `}
`
export const StyledAnimation = styled.div`
    margin: 0 auto;
    height: 100%;
    width: 100%;
    max-height: 220px;
    max-width: 240px;
`
