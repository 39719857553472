import {InfoIcon} from '@/assets/icons/icons'
import styled, {css} from 'styled-components'

export const StyledShowMore = styled.div`
    font-weight: 600;
    text-align: center;
    text-decoration: underline;
    cursor: pointer;
`
export const StyledInfoCircle = styled(InfoIcon)`
    cursor: pointer;
    opacity: 0.5;
    width: 18px;
    height: 18px;
`

export const StyledContainer = styled.div`
    ${({theme: {spacing, mediaQueries}}) => css`
        display: flex;
        border-radius: 14px;
        padding: ${spacing * 2}px;
        box-shadow: rgba(0, 0, 0, 0.25) 0px 4px 10px 0px;
        flex-direction: column;
        gap: ${spacing * 1.5}px;
        justify-content: center;
        ${mediaQueries.m} {
        }
    `}
`

export const StyledPreviewBox = styled.div`
    ${({theme: {spacing}}) => css`
        display: grid;
        grid-template-columns: 1fr 1fr;
        flex-wrap: wrap;
        gap: ${spacing * 2}px;
        justify-content: center;
        & img {
            position: absolute;
            width: 40px;
            height: 40px;
            top: 5%;
            right: 5%;
            z-index: 5;
        }
    `}
`

export const StyledHeaderBox = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
`

export const StyledInfoBox = styled.div`
    text-align: center;
    font-weight: 700;
    & p {
        text-decoration: underline;
    }
`

export const StyledNameBox = styled.div`
    ${({theme: {typography}}) => css`
        ${typography.textXl};
        font-weight: 700;
    `}
`

export const StyledEmptySection = styled.div`
    text-align: center;
    opacity: 50%;
`
export const StyledPreviewContainer = styled.div`
    position: relative;
`
