import styled, {css} from 'styled-components'

export const StyledRoot = styled.div`
    ${({theme: {spacing, palette}}) => css`
        display: flex;
        align-items: center;
        background-color: ${palette.neutral.white};
        box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15);
        border-radius: 10px;
        margin: ${spacing * 3}px 0;
        cursor: pointer;
        padding: ${spacing * 2}px ${spacing * 2}px ${spacing * 1.5}px;
        gap: ${spacing * 4}px;
        color: ${palette.primary[900]};
    `}
`

export const StyledIconsWrapper = styled.div`
    ${({theme: {spacing, flexUtils}}) => css`
        ${flexUtils.centered}
        flex-direction: column;
        gap: ${spacing / 2}px;
    `}
`

export const StyledTelegramIconWrapper = styled.div`
    ${({theme: {palette}}) => css`
        border: 1px solid ${palette.primary[900]};
        border-radius: 30px;
        width: 43px;
        height: 43px;
        padding: 6px;
    `}
`
