import styled, {css} from 'styled-components'

export const StyledSupporterCardRoot = styled.div`
    display: grid;
    justify-content: center;
`

export const StyledAvatar = styled.div`
    ${({theme: {spacing}}) => css`
        margin: 0 auto ${spacing * 2}px;
    `}
`

export const StyledName = styled.div`
    ${({theme: {truncateText}}) => css`
        overflow: hidden;
        max-width: 80px;
        ${truncateText};
    `}
`

export const StyledAmount = styled.div`
    ${({theme: {spacing}}) => css`
        display: flex;
        gap: ${spacing / 2}px;
        justify-content: center;
        align-items: center;
        font-weight: 700;
    `}
`
