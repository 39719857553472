import {Lens} from '@dhmk/zustand-lens'

type OpenSurprisesModalState = {
    isOpen: boolean
    hostId: number | null
    hostUserName: string | null
    surpriseId?: number | null
}

type OpenSurprisesModalActions = {
    openModal: (params: Omit<OpenSurprisesModalState, 'isOpen'>) => void
    closeModal: () => void
}

export type OpenSurprisesModal = OpenSurprisesModalState & OpenSurprisesModalActions

const initialModal: OpenSurprisesModalState = {isOpen: false, hostId: null, surpriseId: null, hostUserName: null}

export const createOpenSurprisesModalSlice: Lens<OpenSurprisesModal> = set => ({
    ...initialModal,
    openModal: params => set(() => ({...params, isOpen: true})),
    closeModal: () => set(() => ({...initialModal}))
})

export default createOpenSurprisesModalSlice
