import {useQuery} from '@tanstack/react-query'
import {QUERY_KEYS} from './keys'
import {RECURRING_GIFT_API} from '../services/recurringGift.http'

export const useFetchSubscribedAmounts = (isEdit: boolean, enabled = true) => {
    return useQuery({
        queryKey: [QUERY_KEYS.subscriptions_amounts, isEdit],
        queryFn: () => RECURRING_GIFT_API.getSubscriptionsAmounts(Number(isEdit)),
        enabled
    })
}
