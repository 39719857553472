import {useQuery} from '@tanstack/react-query'
import {QUERY_KEYS} from './keys'
import {GUEST_API} from '../services/guest.http'

export const useGetSubscriptionsToRenew = (enabled: boolean | undefined = true) => {
    return useQuery({
        queryKey: [QUERY_KEYS.subscriptions_to_renew],
        queryFn: GUEST_API.getSubscriptionsToRenew,
        enabled
    })
}
