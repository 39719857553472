import {Spinner} from '@/components/ui/spinner/Spinner'
import {useMe} from '@/features/authentication/queries/useMe.ts'
import {commonRoutes} from '@/utilities/constants'
import {Navigate, Outlet} from 'react-router-dom'

export const PrivateRoute = () => {
    // use http-only cookie
    const {loggedIn, isLoading} = useMe()

    if (isLoading) {
        return <Spinner />
    }

    return loggedIn ? <Outlet /> : <Navigate to={commonRoutes.LOGIN_GUEST.path} />
}
