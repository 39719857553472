import {InputText} from '@/components/commons/input-text/InputText'
import {Modal} from '@/components/ui/modal/Modal'
import {useMe} from '@/features/authentication/queries/useMe'
import {GuestType} from '@/features/authentication/types'
import {MegaGiftSection} from '@/features/mega-gift/components/mega-gift-section/MegaGiftSection'
import useMixPanel from '@/integrations/mixpanel/hooks/useMixpanel'
import {MixpanelEventNameE} from '@/integrations/mixpanel/types'
import {useRootStore} from '@/store'
import {selectBuyMorgisModal, selectCustomSendGiftModal} from '@/store/selectors'
import {USER_ROLE_GUEST} from '@/utilities/constants/user'
import {zodResolver} from '@hookform/resolvers/zod'
import {ChangeEvent, FC, useEffect, useState} from 'react'
import {useForm} from 'react-hook-form'
import {useTranslation} from 'react-i18next'
import {z} from 'zod'
import {PulseAnimation} from '../animations/pulse-animation/PulseAnimation'
import {useSendMicromorgi} from '../queries/useSendMicromorgi'
import {
    CustomSendGiftModalContainer,
    Line,
    StyledAmountForm,
    StyledBalance,
    StyledCurrentBalance,
    StyledCurrentBalanceTitle,
    StyledCustomSendGiftModalWrapper,
    StyledDescription,
    StyledGemContainer,
    StyledMicromorgiIconMotion,
    StyledPulseWrapper,
    StyledSubmitButton,
    StyledTitle
} from './style'
import {useDonateGoal} from '@/features/goal/queries/useDonateGoal'
import useSendChatMessage from '@/features/chat/hooks/useSendChatMessage'
import {ChannelID} from '@/features/chat/types'
import {generateChatChannelName, getCorrectUserId} from '@/utilities/helpers'
import {MessageTypeE} from '@/utilities/constants/chat'
import {Spinner} from '@/components/ui/spinner/Spinner'

const transitionDuration = 1.5

const modalOpenAnimation = {
    initial: {
        opacity: 0,
        x: '-50%',
        y: '-40%'
    },
    animate: {
        x: '-50%',
        y: '-50%',
        opacity: 1,
        transition: {duration: transitionDuration}
    }
}

const amountForm = z.object({
    amount: z.coerce.number().int().min(1, {message: 'validation:enter_valid_number'})
})

export const CustomSendGiftModal: FC = () => {
    const [amount, setAmount] = useState<number | undefined>(undefined)
    const {t} = useTranslation()
    const {data} = useMe()
    const mixpanel = useMixPanel()

    const user = data?.type === USER_ROLE_GUEST ? (data as GuestType) : null

    const {closeModal, maxDonation, minDonation, hostID, goalId, setStatus} = useRootStore(selectCustomSendGiftModal)
    const {openModal} = useRootStore(selectBuyMorgisModal)
    const {mutate: sendMicromorgi, isPending: isPendingSendMicromorgi} = useSendMicromorgi()
    const {mutateAsync: donateGoal, isPending: isPendingDonateGoal} = useDonateGoal(`${goalId}`, status => {
        setStatus(status)
        closeModal()
    })
    const channel = user && hostID ? generateChatChannelName(user?.id, user?.type, Number(hostID)) : ''
    const {sendChatMessage} = useSendChatMessage(channel as ChannelID)
    const goalDisabled = maxDonation && !!amount && amount > maxDonation // disable goal donation
    const disabledByMinDonation = minDonation && !!amount ? minDonation > amount : false
    const disabled = !amount || !!goalDisabled || disabledByMinDonation // button

    const onSubmit = async (data: z.infer<typeof amountForm>) => {
        if (hostID && user && channel) {
            if (amount && user?.type_attributes?.micro_morgi_balance < amount) {
                openModal({})
            } else {
                if (goalId) {
                    await donateGoal({hostID, amount})

                    await sendChatMessage({
                        type: MessageTypeE.TRANSACTION_GOAL,
                        userId: getCorrectUserId(user),
                        goalId: Number(goalId),
                        micromorgiAmount: amount
                    })
                } else {
                    await sendMicromorgi({amount: data.amount, hostID})

                    sendChatMessage({
                        type: MessageTypeE.MICROMORGI_TRANSACTION,
                        userId: getCorrectUserId(user),
                        micromorgiAmount: data.amount
                    })
                }
            }
            closeModal()
        }
    }

    const {
        register,
        handleSubmit,
        formState: {errors}
    } = useForm<z.infer<typeof amountForm>>({
        resolver: zodResolver(amountForm)
    })

    useEffect(() => {
        if (Object.keys(errors).length) {
            mixpanel?.trackEvent(MixpanelEventNameE.GiftMicromorgiError, {
                error_type: 'Insufficient amount',
                micro_morgi_gift_amount: amount,
                custom: 'Custom'
            })
        }
    }, [errors])

    const onChangeHandler = (e: ChangeEvent<HTMLInputElement>) => {
        const value = Math.abs(+e.target.value)
        if (value === 0) setAmount(undefined)
        else if (value > 0 && value <= 1000000) setAmount(value)
    }

    return (
        <Modal onClose={closeModal} width={'300px'} animation={modalOpenAnimation}>
            {(isPendingSendMicromorgi || isPendingDonateGoal) && <Spinner />}
            <CustomSendGiftModalContainer>
                <StyledPulseWrapper>
                    <PulseAnimation duration={transitionDuration} />
                </StyledPulseWrapper>
                <StyledGemContainer>
                    <StyledMicromorgiIconMotion $duration={transitionDuration} />
                </StyledGemContainer>
                <StyledCustomSendGiftModalWrapper>
                    <StyledTitle>{t('send_custom_amount:title')}</StyledTitle>
                    <StyledCurrentBalance>
                        <StyledCurrentBalanceTitle>{t('common:current_balance')}</StyledCurrentBalanceTitle>
                        <StyledBalance>{user?.type_attributes.micro_morgi_balance}</StyledBalance>
                    </StyledCurrentBalance>
                    <StyledDescription>
                        {t('send_custom_amount:description', {
                            USER: user?.full_name
                        })}
                    </StyledDescription>
                </StyledCustomSendGiftModalWrapper>
                <StyledAmountForm onSubmit={handleSubmit(onSubmit)}>
                    <InputText
                        {...register('amount')}
                        onChange={onChangeHandler}
                        min={0}
                        max={1000000}
                        placeholder={t('common:amount')}
                        type="number"
                        errorMessage={`${t(errors.amount?.message ?? '')}`}
                        value={amount || ''}
                    />
                    <Line />
                    <StyledSubmitButton disabled={disabled} variant="primary" type="submit">
                        {t('send_custom_amount:send_N_micromorgies', {
                            AMOUNT: amount || ''
                        })}
                    </StyledSubmitButton>
                </StyledAmountForm>
                {!goalId && <MegaGiftSection hostID={String(hostID)} onSuccessSendGift={closeModal} />}
            </CustomSendGiftModalContainer>
        </Modal>
    )
}
