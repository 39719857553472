import {useMutation, useQueryClient} from '@tanstack/react-query'
import {RECURRING_GIFT_API} from '../services/recurringGift.http'
import {MUTATION_KEYS, QUERY_KEYS} from './keys'
import {QUERY_KEYS as CHAT_QUERY_KEYS} from '@/features/chat/queries/keys'
import {QUERY_KEYS as GUEST_QUERY_KEYS} from '@/features/guest/queries/keys'

export const useDeleteGift = (rookieId: string) => {
    const queryClient = useQueryClient()
    return useMutation({
        mutationKey: [MUTATION_KEYS.delete_gift, rookieId],
        mutationFn: () => RECURRING_GIFT_API.deleteGift(rookieId),
        onSuccess: () => {
            queryClient.invalidateQueries({queryKey: [CHAT_QUERY_KEYS.fetchChannelDetails]})
            queryClient.invalidateQueries({queryKey: [QUERY_KEYS.subscribed_hosts]})
            queryClient.invalidateQueries({queryKey: [GUEST_QUERY_KEYS.host]})
        }
    })
}
