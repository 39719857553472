import useInfinityLoading from '@/hooks/useInfinityLoading'
import {useGetNotificationsHistory} from '../queries/useGetNotificationsHistory'
import {StyledNotificationContainer, StyledNotificationsTitle} from './style'
import {Spinner} from '@/components/ui/spinner/Spinner'
import {useGetNotificationUnreadCount} from '../queries/useGetNotificationUnreadCount'
import {NotificationsType} from '../types'
import dayjs from '@/dayjs'
import {useTranslation} from 'react-i18next'
import {useEffect} from 'react'
import {useSendSeenNotifications} from '../queries/useSendSeenNotifications'
import {NotificationsContainer} from './notificationsContainer/NotificationsContainer'
import useContainerDimensions from '@/hooks/useContainerDimensions'

export const Notifications = ({closeNotification}: {closeNotification: () => void}) => {
    const {t, i18n} = useTranslation()
    const {height: vh} = useContainerDimensions()
    const {
        data: allNotifications,
        fetchNextPage,
        hasNextPage,
        isFetchingNextPage
    } = useGetNotificationsHistory({limit: 10, appLang: i18n.language})

    const {mutate: sendSeenNotifications} = useSendSeenNotifications()
    const {data: lastReadTimeToken} = useGetNotificationUnreadCount()

    const notifications = allNotifications?.pages.flatMap(page => page.data)
    const [endRef] = useInfinityLoading({fetchFunction: fetchNextPage, dependencies: [hasNextPage]})

    useEffect(
        () => () => {
            sendSeenNotifications()
        },
        []
    )

    const sortNotifications = (notifications?: NotificationsType[]) => {
        return notifications?.sort((a, b) => dayjs(b.created_at).valueOf() - dayjs(a.created_at).valueOf())
    }

    const oldNotifications = !lastReadTimeToken?.last_read
        ? []
        : sortNotifications(
              notifications?.filter(notification =>
                  dayjs(notification.created_at).isBefore(dayjs(lastReadTimeToken?.last_read))
              )
          )

    const newNotifications = !lastReadTimeToken?.last_read
        ? notifications
        : sortNotifications(
              notifications?.filter(notification =>
                  dayjs(notification.created_at).isAfter(dayjs(lastReadTimeToken?.last_read))
              )
          )
    return (
        <StyledNotificationContainer vh={vh}>
            <StyledNotificationsTitle>{t('notifications:title')}</StyledNotificationsTitle>
            <NotificationsContainer notifications={newNotifications} closeNotification={closeNotification} />
            <NotificationsContainer notifications={oldNotifications} closeNotification={closeNotification} old />
            {hasNextPage && <div ref={endRef} />}
            {isFetchingNextPage && <Spinner inline overlay={false} size={30} />}
        </StyledNotificationContainer>
    )
}
