import {z} from 'zod'

export const BadWordsResponseSchema = z.array(
    z.object({name: z.string(), type: z.string(), url: z.string().optional()})
)

export const BadLinksResponseItemSchema = z.object({
    id: z.number(),
    type: z.string(),
    url: z.string().optional(),
    params: z.string()
})

export const BadLinksResponseSchema = z.array(BadLinksResponseItemSchema)
