import styled, {DefaultTheme, css} from 'styled-components'

export const StyledRoot = styled.div`
    width: 100%;
    position: relative;
    max-width: 480px;
    margin: 0px auto;
    height: fit-content;
`
export const StyledExpertiseIconContainer = styled.div`
    position: absolute;
    z-index: 2;
    top: 40px;
    left: 20px;
`
export const StyledBgIcon = styled.span`
    ${({theme: {spacing}}) => css`
        position: absolute;
        z-index: 1;
        bottom: ${spacing * 6.25}px;
        right: ${spacing * 2}px;
        opacity: 0.5;
    `}
`
export const StyledPagination = styled.div`
    ${({theme: {spacing}, isInFeed, photoCount}: {theme: DefaultTheme; isInFeed: boolean; photoCount: number}) => css`
        position: absolute;
        top: ${isInFeed ? '6px' : '16px'};
        height: ${isInFeed ? '3px' : '2px'};
        width: 100%;
        display: grid;
        grid-template-columns: repeat(${photoCount}, 1fr);
        grid-gap: ${photoCount < 20 ? `2px` : `1px`};  
        padding: ${isInFeed ? `0px ${spacing/2}px`  : `0px 12px`};
    `}
`
export const StyledPaginationItem = styled.div`
    ${({theme: {palette}, isActive}: {theme: DefaultTheme; isActive: boolean}) => css`
        background: ${palette.neutral.white};
        border-radius: 100px;
        width: 100%;
        opacity: ${isActive ? 1 : 0.2};
    `}
`

export const carouselStyles = ({
    theme,
    isSpecial,
    isInFeed
}: {
    theme: DefaultTheme
    isSpecial?: boolean
    isInFeed?: boolean
}) => css`
    position: relative;
    width: 100%;
    height: unset;
    border: ${isSpecial ? `2px solid ${theme.palette.neutral.black}` : 'none'};
    border-radius: 18px;
    .splide__track {
        border-radius: ${isInFeed ? '8px' : '16px'};
    }
    .splide__pagination {
        display: none;
        top: ${theme.spacing + 5}px;
        bottom: initial;
        width: 100%;
        display: flex;
        align-items: center;
        flex-wrap: nowrap;
        padding: 6px;
        & li {
            display: flex;
            width: 100%;
        }
    }
    /* pagination buttons */
    & button {
        height: 5px;
        width: 100%;
        margin: ${theme.spacing / 2}px;
        position: relative;
        border-radius: 100px;
        margin: 4px 1px;
        &.splide__pagination__page {
            display: none;
            background: ${theme.palette.neutral.white};
            opacity: 0.2;
        }

        &.splide__pagination__page.is-active {
            background: ${theme.palette.neutral.white};
            opacity: 1;
            transform: none;
        }
    }
`
export const ctaBarStyles = ({theme}: {theme: DefaultTheme}) => css`
    align-items: center;
    width: 90%;
    transform: translate(0px, -50%);
    margin: 0 auto;
    z-index: 2;
    & > button {
        color: ${theme.palette.neutral.white};
        max-width: unset;
    }

    ${theme.mediaQueries.m} {
        width: 80%;
    }
`
export const StyledOpenRandomSurpriseCTA = styled.div`
    ${({theme: {spacing}}) => css`
        position: absolute;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: ${spacing * 2.5}px;
        width: 100%;
        & > button {
            width: 90% !important;
            margin: 0 auto !important;
            height: unset !important;
            padding: ${spacing * 1.5}px ${spacing * 3}px !important;
        }
        & > img {
            max-width: 180px;
            max-height: 180px;
        }
    `}
`
