export const MUTATION_KEYS = {
    profile_photo: 'profile-photo',
    unblock_user: 'unblock-user',
    block_user: 'block-user',
    edit_user: 'edit-user',
    delete_user: 'delete-user',
    sent_first_message_to_host: 'sent-first-message-to-host',
    update_credit_card: 'update-credit-card'
}

export const QUERY_KEYS = {
    guest_profile: 'guest-profile',
    random_avatars: 'random-avatars',
    unsubscribe_user: 'unsubscribe-user'
}
