import styled, {css, DefaultTheme} from 'styled-components'

type StyledBtnProps = {
    icon?: boolean
    iconPosition?: string
    fullWidth?: boolean
    iconOnly?: boolean
    disabled?: boolean
    size?: 'sm' | 'md' | 'lg' | 'xl'
    variant?:
        | 'primary'
        | 'primaryDanger'
        | 'secondary'
        | 'tertiary'
        | 'ghost'
        | 'primaryOutlined'
        | 'outlined'
        | 'transparent'
        | 'small'
        | 'big'
        | 'iconOnly'
        | 'warning'
}

export const StyledButton = styled.button<StyledBtnProps>`
    ${({iconOnly, fullWidth = true, icon, iconPosition = 'right', theme: {shadows, spacing}}) => css`
        width: ${iconOnly ? '40px' : fullWidth ? '100%' : 'auto'};
        padding: ${spacing * 2}px ${spacing * 3}px;
        border: 0;
        border-radius: 100px;
        font-size: 16px;
        font-weight: 600;
        line-height: 1.4;
        display: grid;
        text-align: center;
        align-items: center;
        justify-content: 'center';
        position: relative;
        transition: ease-in-out 300ms;
        box-shadow: ${shadows['4xl']};
        outline: none;
        cursor: pointer;

        &:disabled {
            cursor: not-allowed;
        }
        & > span {
            display: grid;
            align-items: center;
            grid-template-columns: ${icon ? (iconPosition === 'right' ? '1fr auto' : 'auto 1fr') : '1fr'};
            grid-column-gap: 8px;
            & svg {
                width: 18px;
            }
        }
    `}
    ${({variant = 'primary', theme}) => ColorStyles(theme)[variant]};
    ${({size, fullWidth, iconOnly, theme}) => size && SizeStyles({theme, iconOnly, fullWidth})[size]};
`

const ColorStyles = ({palette, shadows, mediaQueries, spacing}: DefaultTheme) => ({
    iconOnly: css`
        background-color: ${palette.neutral.white};
        width: 100%;
        max-width: 56px;
        box-shadow: ${shadows.mdXl};
        border: none;
        padding: ${spacing * 2};
        ${mediaQueries.s} {
            & span {
                & svg {
                    width: 20;
                    height: 20;
                }
            }
        }
        ${mediaQueries.m} {
            & span {
                & svg {
                    width: 24px;
                    height: 24px;
                }
            }
        }
    `,
    small: css`
        padding: ${[spacing, spacing * 2]};
        & span {
            & svg {
                width: 16px;
            }
        }
    `,
    big: css`
        padding: ${[spacing * 3, spacing * 4]};
        & span {
            & svg {
                width: 16px;
            }
        }
    `,
    primary: css`
        color: ${palette.neutral.white};

        background-color: ${palette.primary['200']};

        border: 1px solid ${palette.primary['200']};

        &:disabled {
            background-color: ${palette.primary['400']};
            border: 1px solid ${palette.primary['400']};
        }
        & span {
            & svg {
                fill: ${palette.neutral.white};
            }
        }
        &:not(:disabled):hover {
            background-color: ${palette.primary['900']};
            border: 1px solid ${palette.primary['900']};
        }
        &:disabled:hover {
            background-color: ${palette.primary['400']};
            border: 1px solid ${palette.primary['400']};
        }
    `,
    primaryOutlined: css`
        background-color: ${palette.neutral.transparent};
        color: ${palette.primary['200']};
        border: 1px solid ${palette.primary['200']};
        box-sizing: 'border-box';
        & span {
            & svg {
                &:disabled {
                    fill: ${palette.primary['200']};
                }
            }
        }
    `,
    outlined: css`
        background-color: ${palette.neutral.transparent};
        border: 1px solid ${palette.primary['900']};
        color: ${palette.primary['900']};

        &:disabled {
            border: 1px solid ${palette.primary['700']};
            color: ${palette.primary['700']};
        }
    `,
    primaryDanger: css`
        color: ${palette.neutral.white};
        background: ${palette.danger['600']};
        border: 1px solid ${palette.danger['600']};
        box-shadow: ${shadows.xs};
        &:not(:disabled):hover {
            background: ${palette.danger['700']};
            border: 1px solid ${palette.danger['700']};
        }
        &:not(:disabled):focus {
            box-shadow: ${`${shadows.xs}, 0px 0px 0px 4px ${palette.neutral['100']}`};
        }
        &:disabled {
            background: ${palette.danger['200']};
            border: 1px solid ${palette.danger['200']};
            cursor: not-allowed;
        }
    `,
    secondary: css`
        color: ${palette.neutral.black};
        &:disabled {
            background-color: ${palette.tertiary['300']};
        }

        background-color: ${palette.tertiary['25']};

        &:not(:disabled):hover {
            background: ${palette.tertiary['400']};
        }
        & span {
            & svg {
                &:disabled {
                    fill: ${palette.neutral.black};
                }
            }
        }
    `,
    transparent: css`
        box-shadow: none;
        background-color: ${palette.neutral.transparent};
        border: none;
        color: ${palette.primary['900']};
        &:disabled {
            color: ${palette.neutral['950']};
        }

        color: ${palette.primary['900']};

        & span {
            & svg {
                fill: ${palette.primary['900']};
                &:disabled {
                    fill: ${palette.neutral['950']};
                }

                fill: ${palette.primary['900']};
            }
        }
    `,
    warning: css`
        background-color: ${palette.warning['50']};
        color: ${palette.neutral.white};
        & span {
            & svg {
                fill: ${palette.neutral.white};
            }
        }
        &:not(:disabled):hover {
            background-color: ${palette.warning['100']};
        }
    `,
    ghost: css`
        background: transparent;
        color: ${palette.neutral['700']};
        &:not(:disabled):hover {
            background: ${palette.neutral['50']};
        }

        &:disabled {
            background: ${palette.neutral.white};
            color: ${palette.neutral['300']};
            cursor: not-allowed;
        }
    `,
    tertiary: css`
        background: transparent;
        color: ${palette.neutral['600']};
        font-weight: 500;
        text-decoration: underline;
        &:not(:disabled):hover {
            background: transparent;
        }
        &:disabled {
            background: ${palette.neutral.white};
            color: ${palette.neutral['300']};
            cursor: not-allowed;
        }
    `
})

type SizesProps = {
    fullWidth?: boolean
    iconOnly?: boolean
    theme: DefaultTheme
}

const SizeStyles = ({theme: {spacing}, fullWidth, iconOnly}: SizesProps) => ({
    /*the Y padding must be ((min-height - line height) / 2)*/
    sm: css`
        min-height: 36px;
        width: ${iconOnly ? '36px' : fullWidth ? '100%' : 'auto'};
        ${!iconOnly &&
        css`
            padding: ${spacing * 2}px ${spacing * 3.5}px;
        `};
    `,
    md: css`
        min-height: 40px;
        width: ${iconOnly ? '40px' : fullWidth ? '100%' : 'auto'};
        ${!iconOnly &&
        css`
            padding: ${spacing * 2.5}px ${spacing * 4}px;
        `};
    `,
    lg: css`
        min-height: 44px;
        width: ${iconOnly ? '44px' : fullWidth ? '100%' : 'auto'};
        ${iconOnly &&
        css`
            width: 44px;
        `}
        ${!iconOnly &&
        css`
            padding: ${spacing * 2.5}px ${spacing * 4.5}px;
        `};
    `,
    xl: css`
        min-height: 60px;
        width: ${iconOnly ? '60px' : fullWidth ? '100%' : 'auto'};
        ${!iconOnly &&
        css`
            padding: ${spacing * 4}px ${spacing * 7}px;
        `};
    `
})
