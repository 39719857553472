import {StyledTopic} from './style'

type TopicItemProps = {
    icon: JSX.Element
    name: string
    isSelected: boolean
}

export const TopicItem = ({icon, name, isSelected}: TopicItemProps) => {
    return (
        <StyledTopic isSelected={isSelected}>
            {icon}
            {name}
        </StyledTopic>
    )
}
