import {MOBILE_TABLE_SCROLLBAR_WIDTH} from '@/components/commons/table/mobile-table/style'
import {Button} from '@/components/ui/button/Button'
import styled, {css} from 'styled-components'

export const StyledListOfSupportedUsersContainer = styled.section`
    ${({theme: {spacing}}) => css`
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: ${spacing * 2}px;
    `}
`

export const StyledListOfSupportedUsers = styled.div`
    ${({theme: {spacing, palette}}) => css`
        display: flex;
        flex-direction: column;
        gap: ${spacing * 1.5}px;
        overflow: auto;
        max-height: 320px;
        width: 100%;
        &::-webkit-scrollbar {
            width: ${MOBILE_TABLE_SCROLLBAR_WIDTH};
            background-color: ${palette.neutral[50]};
        }
        &::-webkit-scrollbar-thumb {
            background-color: ${palette.neutral[300]};
            border-radius: 3px;

            &:hover {
                background-color: ${palette.neutral[400]};
            }
        }
    `}
`
export const StyledDescriptionEndList = styled.span`
    ${({theme: {palette, typography, spacing}}) => css`
        ${typography.textSm}
        color: ${palette.neutral[500]};
        margin-top: ${spacing}px;
        font-weight: 500;
        line-height: 1.4;
        padding-right: ${spacing}px;
        text-align: center;
    `}
`
export const StyledButton = styled(Button)`
    ${({theme: {spacing}}) => css`
        padding: ${spacing * 2}px ${spacing * 3}px;
        width: 90%;
    `}
`
