import styled, {css} from 'styled-components'

export const StyledFeedPage = styled.div<{vh: number; heightOfHeader: number}>`
    ${({theme: {mediaQueries}, heightOfHeader, vh}) => css`
        position: absolute;
        top: ${heightOfHeader}px;
        left: 0px;
        height: ${vh}px;
        width: 100%;
        display: grid;
        grid-template-rows: 0fr auto;
        grid-template-columns: 1fr;
        align-items: center;
        ${mediaQueries.m} {
            position: relative;
            top: unset;
            left: unset;
            gap: 35px;
            grid-template-columns: 1fr 1fr;
            grid-template-rows: auto;
        }
    `}
`

export const StyledFeedBar = styled.div`
    ${({theme: {mediaQueries, spacing}}) => css`
        display: flex;
        flex-direction: column;
        width: 100%;
        gap: ${spacing}px;

        ${mediaQueries.m} {
            max-width: 550px;
            padding-left: 10%;
            box-sizing: content-box;
            gap: 0;
        }
    `}
`
export const StyledFeedWrapper = styled.div`
        height: 100%;
`
