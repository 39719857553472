import styled, {css} from 'styled-components'

export const StyledItemWrapper = styled.div`
    ${({theme: {spacing}}) => css`
        display: flex;
        gap: ${spacing * 2}px;
        margin-top: ${spacing * 3}px;
    `}
`

export const StyledItemTitle = styled.div`
    ${({theme: {spacing}}) => css`
        font-weight: 700;
        margin-bottom: ${spacing}px;
    `}
`

export const StyledItemText = styled.div`
    ${({theme: {palette}}) => css`
        color: ${palette.primary['700']};
    `}
`
