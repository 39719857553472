import {Card} from '@/components/ui/card/Card'
import {GoalStatusesE, GoalType} from '../../types'
import {
    StyledAmount,
    StyledCardRoot,
    StyledCardTitle,
    StyledGoalIcon,
    StyledHint,
    StyledInfo,
    StyledPastGoalSection,
    StyledResponsiveVideo,
    bodyCardClass,
    rootCardClass,
    tagIsForOthersStyles,
    StyledMedia,
    StyledCardContainer
} from './style'
import {Trans, useTranslation} from 'react-i18next'
import {generatePath, useNavigate} from 'react-router-dom'
import {Avatar} from '@/components/ui/avatar/Avatar'
import {GoaRightArrowIcon, MicromorgiIcon} from '@/assets/icons/icons'
import {Tag} from '@/components/ui/tag/Tag'
import {calculatePercentage, generateGoalSlug} from '@/utilities/helpers'
import {ROUTE_GOAL} from '@/utilities/constants/routeNames'
import {CircleProgress} from '@/components/ui/circle-progress/CircleProgress'
import {useMemo} from 'react'
import {getLocalDate, formatDate, isPasteDate} from '@/dayjs'
import {goalStatusesWithoutTimer, progressGradient} from '../../constant/goalCard'
import {additionalContent} from '../../constant/goalSize'
import GoalCountdown from '../goal-countdown/GoalCountdown'
import {StatusLabel} from '../status-label/StatusLabel'
import {MediaTypeE} from '@/types'
import {useRootStore} from '@/store'
import {selectGoalModal} from '@/store/selectors'

type CardProps = {
    goal: GoalType
    isInMoengage?: boolean
    isGoalTeaser?: boolean
    isGoalSummaryModal?: boolean
    isActive?: boolean
    isActionAllowed?: boolean
}

export const HostGoalCard = ({
    goal,
    isInMoengage = false,
    isGoalTeaser = false,
    isGoalSummaryModal = false,
    isActive = false,
    isActionAllowed = true
}: CardProps) => {
    const {t} = useTranslation()
    const navigate = useNavigate()
    const {
        name,
        media,
        type: {type},
        target_amount,
        donations_count,
        is_for_others,
        radiobox,
        end_date,
        status,
        slug,
        id
    } = goal
    const {openModal: openGoalModal} = useRootStore(selectGoalModal)
    const localEndDate = end_date && getLocalDate(end_date)
    const formattedLocalEndDate = formatDate(localEndDate)
    const hasEnded = localEndDate && isPasteDate(localEndDate)
    const hideTimer = goalStatusesWithoutTimer.includes(status)
    const isPastGoal = hasEnded || hideTimer
    const percentage = calculatePercentage(parseInt(`${donations_count}`), parseInt(target_amount))
    const allowRedirectToGoalPage = !isInMoengage && !isGoalTeaser && !isGoalSummaryModal
    const goalSlug = generateGoalSlug(`${slug}`, `${id}`)

    const strokeColor = useMemo(() => {
        switch (status) {
            case GoalStatusesE.SUCCESSFUL:
            case GoalStatusesE.AWAITING_PROOF:
            case GoalStatusesE.PROOF_PENDING_APPROVAL:
                return progressGradient.success
            case GoalStatusesE.CANCELED:
                return progressGradient.fail
            default:
                return progressGradient.default
        }
    }, [status])

    const pastGoalSectionText = hasEnded
        ? t('goal_card:headerText', {
              ENDED_DATE: formattedLocalEndDate
          })
        : t('goal_card:header_text_end_user')

    const withHint =
        status === GoalStatusesE.AWAITING_PROOF ||
        status === GoalStatusesE.PROOF_PENDING_APPROVAL ||
        status === GoalStatusesE.PROOF_STATUS_DECLINED

    const handleCardClick = () => {
        if (!isActionAllowed) return null

        if (isGoalSummaryModal) {
            openGoalModal({goalId: id})
        } else if (allowRedirectToGoalPage) {
            navigate(generatePath(ROUTE_GOAL, {slug: goalSlug}))
        } else {
            return undefined
        }
    }

    return (
        <StyledCardContainer>
            <Card
                rootCustomClass={() => rootCardClass({isActive})}
                bodyCustomClass={bodyCardClass}
                onClick={handleCardClick}
            >
                <>
                    {isPastGoal && <StyledPastGoalSection>{pastGoalSectionText}</StyledPastGoalSection>}
                    <StyledCardRoot>
                        <StyledMedia>
                            {media[0].type === MediaTypeE.VIDEO ? (
                                <StyledResponsiveVideo video={media[0].url} controls={false} />
                            ) : (
                                <Avatar imageUrl={media[0].url} name={name} radius={10} height={57} width={57} />
                            )}
                        </StyledMedia>
                        <StyledInfo>
                            {!isInMoengage && (
                                <div>
                                    <StyledGoalIcon>{additionalContent[type].icon}</StyledGoalIcon>
                                    <StyledCardTitle>
                                        <span>
                                            <Trans
                                                t={t}
                                                i18nKey={'goal_card:goal_type'}
                                                values={{
                                                    context: additionalContent[type].type_name
                                                }}
                                            />
                                        </span>
                                    </StyledCardTitle>
                                </div>
                            )}
                            <div>{name}</div>
                            <div>
                                <MicromorgiIcon height={14} width={14} />
                                <StyledAmount>{+target_amount}</StyledAmount>
                                {!isPastGoal && (
                                    <>
                                        <GoaRightArrowIcon />
                                        <span>
                                            <GoalCountdown endDate={end_date ?? ''} />
                                        </span>
                                    </>
                                )}
                            </div>
                            <div>
                                <Tag
                                    title={
                                        is_for_others
                                            ? t('goal_page:for_whom_section:others')
                                            : t('goal_page:for_whom_section:myself')
                                    }
                                    tagStyles={tagIsForOthersStyles}
                                />
                                {is_for_others && radiobox && <Tag title={radiobox} tagStyles={tagIsForOthersStyles} />}
                            </div>
                        </StyledInfo>
                        <CircleProgress percentage={percentage} progressColor={strokeColor} />
                    </StyledCardRoot>
                </>
            </Card>
            <div>
                <StatusLabel goalId={id} status={status} />
                {withHint && <StyledHint>{t('goal_card:proof_label_hint')}</StyledHint>}
            </div>
        </StyledCardContainer>
    )
}
