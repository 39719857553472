import {ChatTopicIcon, WishToBecomeIcon} from '@/assets/icons/icons'
import {Spinner} from '@/components/ui/spinner/Spinner'
import {useMe} from '@/features/authentication/queries/useMe'
import activitiesIcon from '@assets/images/activities.png'
import {FC} from 'react'
import {useTranslation} from 'react-i18next'
import {DefaultTheme, FlattenSimpleInterpolation} from 'styled-components'
import {getListToShow} from '../../helpers/helpers'
import {HostTopicsList} from './host-topics-list/HostTopicsList'
import {StyledIcon} from './host-topics-list/style'
import {StyledActivitiesIcon, StyledWish, StyledWrapper} from './style'
import {ExpertiseIcon} from '@/components/commons/expertise-icon/ExpertiseIcon'

export type TopicOption = {
    id: number
    is_popular: boolean
    key_name: string
    name: string
    users_count?: number
}

export type WishOption = {
    id: number
    key_name: string
    name: string
    is_subpath: boolean
    prepend: string
}

type HostTopicsProps = {
    comparedChatTopics?: TopicOption[]
    comparedActivities?: TopicOption[]
    hostsWish?: WishOption
    isSpecialHost?: boolean
    withIcon?: boolean
    withChatTopics?: boolean
    withActivities?: boolean
    withWishes?: boolean
    hostExpertise?: string
    hostExpertiseCategory?: string
    className?: string
    isInFeed?: boolean
    topicsListStyles?: (theme: DefaultTheme) => FlattenSimpleInterpolation
    expertiseName?: string
}
export const HostTopics: FC<HostTopicsProps> = ({
    comparedChatTopics = [],
    comparedActivities = [],
    hostsWish,
    isSpecialHost = false,
    withIcon = true,
    withChatTopics = false,
    withActivities = false,
    withWishes = false,
    hostExpertise,
    hostExpertiseCategory,
    className,
    isInFeed = false,
    expertiseName,
    topicsListStyles
}) => {
    const {t} = useTranslation()
    const {data, isFetching: isLoadingMe} = useMe()
    const isLoading = isLoadingMe

    const userChatTopics = data?.chat_topics
    const userActivities = data?.favorite_activities
    const favChatTopicsListToShow = getListToShow(comparedChatTopics, userChatTopics)
    const favActivitiesListToShow = getListToShow(comparedActivities, userActivities)

    return isLoading ? (
        <Spinner inline overlay={false} size={20} zIndex={1030} />
    ) : (
        <StyledWrapper isInFeed={isInFeed}>
            {withChatTopics && (
                <HostTopicsList
                    items={favChatTopicsListToShow}
                    icon={<ChatTopicIcon />}
                    withIcon={withIcon}
                    className={className}
                    topicsListStyles={topicsListStyles}
                    isInFeed={isInFeed}
                />
            )}
            {withActivities && (
                <HostTopicsList
                    items={favActivitiesListToShow}
                    icon={<StyledActivitiesIcon isInFeed={isInFeed} src={activitiesIcon} />}
                    withIcon={withIcon}
                    className={className}
                    topicsListStyles={topicsListStyles}
                    isInFeed={isInFeed}
                />
            )}
            {withWishes && hostsWish && !isSpecialHost && (
                <StyledWish>
                    <StyledIcon>
                        <WishToBecomeIcon />
                    </StyledIcon>
                    <span>
                        {t('rookie_profile_page:wish_to_become')} {hostsWish.name}
                    </span>
                </StyledWish>
            )}
            {isSpecialHost && hostExpertise && (
                <StyledWish>
                    <ExpertiseIcon expertise={hostExpertise} expertiseCategory={hostExpertiseCategory} />
                    {expertiseName}
                </StyledWish>
            )}
        </StyledWrapper>
    )
}

HostTopics.displayName = 'HostTopics'
