export const ACCEPT_IMAGE_FILE_TYPES = {
    'image/png': ['.png'],
    'image/jpg': ['.jpg'],
    'image/jpeg': ['.jpeg']
}

export const ACCEPT_VIDEO_FILE_TYPES = {
    'video/mp4': ['.mp4'],
    'video/quicktime': ['.mov']
}

export const MAX_FILE_SIZE = 80740352 // 80MB TODO check with backend

export const ACCEPTABLE_IMAGE_TYPES = ['jpeg', 'jpg', 'png']
export const ACCEPTABLE_VIDEO_TYPES = ['mp4', 'mov']
export const ACCEPTABLE_FILE_TYPE = [...ACCEPTABLE_IMAGE_TYPES, ...ACCEPTABLE_VIDEO_TYPES]
