import {MengageConnectorType} from '@/features/moengage/types'
import {getCorrectStringWithTranslation} from '@/utilities/helpers'
import {FC, ReactNode} from 'react'
import {StyledCard, StyledContent, StyledDescription, StyledHeader, StyledImageContainer, StyledMedia} from './style'
import {Flexbox} from '@/components/ui/flexbox/FlexBox'

type CardProps = {
    CtaComponent?: () => JSX.Element
    children?: ReactNode
}

const CardConnector: FC<CardProps & MengageConnectorType> = ({
    element_color,
    // borderElementColor,
    image,
    header_title,
    header_title_color,
    description,
    description_color,
    CtaComponent,
    children
}) => {
    const isTranslationEnabled = false

    return (
        <StyledCard elementColor={element_color}>
            {!!image && (
                <StyledImageContainer>
                    <StyledMedia imageUrl={image} />
                </StyledImageContainer>
            )}
            <Flexbox direction="column" gap={1}>
                {!!header_title && (
                    <StyledHeader titleColor={header_title_color}>
                        {getCorrectStringWithTranslation(header_title, isTranslationEnabled)}
                    </StyledHeader>
                )}
                {!!description && (
                    <StyledDescription descriptionColor={description_color}>
                        {getCorrectStringWithTranslation(description, isTranslationEnabled)}
                    </StyledDescription>
                )}
            </Flexbox>
            {!!children && <StyledContent>{children}</StyledContent>}
            {!!CtaComponent && (
                <div>
                    <CtaComponent />
                </div>
            )}
        </StyledCard>
    )
}

export default CardConnector

export const model = (connectorData: MengageConnectorType | null = null) => {
    if (!connectorData) return null
    return {
        image: connectorData['image'],
        element_color: connectorData['element_color'],
        header_title: connectorData['header_title'],
        header_title_color: connectorData['header_title_color'],
        description: connectorData['description'],
        description_color: connectorData['description_color'],
        page_to_present: connectorData['page_to_present'],
        cta_button_text: connectorData['cta_button_text'],
        cta_button_color: connectorData['cta_button_color'],
        cta_url: connectorData['cta_url'],
        timer: connectorData['timer'],
        notification: connectorData['notification']
    }
}
