import {CountdownTimer} from '@/components/commons/header/header-banner/components/countdown-timer/CountdownTimer'
import {formatDateWithUTC} from '@/dayjs'
import {useMe} from '@/features/authentication/queries/useMe'
import {GuestType} from '@/features/authentication/types'
import {useTranslation} from 'react-i18next'
import {StyledBannerRoot, StyledCountdown, StyledFor} from './style'

export const CashbackBanner = () => {
    const {t} = useTranslation()
    const {data} = useMe()
    const user = data as GuestType

    const ultimateOffer = user?.type_attributes?.ultimate_offer
    const cashBackOffer = user?.type_attributes?.cashback_offer

    const offerEndDateLocal =
        (cashBackOffer?.end_date && formatDateWithUTC(cashBackOffer.end_date, 'YYYY-MM-DD HH:mm:ss')) ||
        (ultimateOffer?.end_date && formatDateWithUTC(ultimateOffer.end_date, 'YYYY-MM-DD HH:mm:ss'))

    const isCashbackOfferAvailable = cashBackOffer?.is_available_cashback_offer

    if (!isCashbackOfferAvailable || !offerEndDateLocal) return

    return (
        <StyledBannerRoot>
            <StyledCountdown>
                <StyledFor>{t('common:for')}</StyledFor>
                <CountdownTimer targetDate={offerEndDateLocal} showDays={false} />
                <div>{t('common:only')}:</div>
            </StyledCountdown>
            <h4>{t('promotion_banners:explanation_banner_cashback:title')}</h4>
            <p>{t('promotion_banners:explanation_banner_cashback:description')}</p>
        </StyledBannerRoot>
    )
}
