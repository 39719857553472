import {useLexicalComposerContext} from '@lexical/react/LexicalComposerContext'
import {$createParagraphNode, $createTextNode, $getRoot} from 'lexical'
import {useEffect} from 'react'

export function OnChangePlugin({
    onChange,
    valueToInsert,
    isDisabled = false
}: {
    onChange: (value: string) => void
    valueToInsert?: string
    isDisabled?: boolean
}) {
    const [editor] = useLexicalComposerContext()

    function update() {
        editor.update(() => {
            const root = $getRoot()
            root.getChildren().forEach(n => n.remove())
            const textNode = $createTextNode(`${root.__cachedText}${valueToInsert}`)
            const paragraphNode = $createParagraphNode()
            paragraphNode.append(textNode).selectEnd()
            root.append(paragraphNode)
        })
    }

    useEffect(() => {
        !!valueToInsert && update()
    }, [valueToInsert])

    useEffect(() => {
        // make this editor editable needed to fix issue with autofocus when we have default value
        editor.setEditable(!isDisabled)
        return editor.registerUpdateListener(({editorState}) => {
            editorState.read(() => {
                const root = $getRoot()
                onChange(root.__cachedText || '')
            })
        })
    }, [editor, onChange, isDisabled])
    return null
}
