import {CSSProperties} from 'react'
import styled, {css, DefaultTheme, FlattenSimpleInterpolation} from 'styled-components'

export const StyledOverlay = styled.div(
    ({theme: {palette, zIndex}}) => css`
        inset: 0;
        position: absolute;
        background-color: ${palette.neutral[650]};
        z-index: ${zIndex.componentOverlay};
    `
)

interface StyledMediaProps {
    withOverlay?: boolean
    mediaStyles?: (theme: DefaultTheme) => FlattenSimpleInterpolation
    imageUrl?: string | null
    imageUrlWithStyle?: string
    width?: number
    height?: number
    radius?: number
    variant?: CSSProperties['backgroundSize']
}

export const StyledMediaRoot = styled.div(
    ({
        withOverlay = false,
        imageUrl,
        imageUrlWithStyle,
        height,
        width,
        radius = 0,
        variant = 'cover',
        mediaStyles
    }: StyledMediaProps) => css`
        background-image: ${imageUrlWithStyle || `url(${imageUrl})`};
        background-repeat: no-repeat;
        background-position: center;
        background-size: ${variant};
        position: relative;
        overflow: ${withOverlay ? 'hidden' : 'visible'};
        height: 100%;
        width: 100%;
        max-width: ${width ?? undefined};
        max-height: ${height ?? undefined};
        border-radius: ${radius}px;
        ${({theme}) => mediaStyles && mediaStyles(theme)};
    `
)
