import {TextareaWithBannedElementsTrap} from '@/features/generated-content-security/components/text-area-with-word-trap/TextareaWithBannedElementsTrap'
import {Flexbox} from '@components/ui/flexbox/FlexBox.tsx'
import styled, {DefaultTheme, css} from 'styled-components'

type ItemsProps = {isDisabled: boolean; theme: DefaultTheme}
export const StyledItemsWrapper = styled.div`
    ${({theme: {palette}}) => css`
        flex-grow: 1;
        background-color: ${palette.neutral.white};
        display: flex;
        justify-content: space-between;
        border-radius: 10px;
        border: solid 1px ${palette.neutral[400]};
        overflow: hidden;
        & > * + * {
            border-left: solid 1px ${palette.neutral[400]};
        }
    `}
`

export const StyledLabel = styled.label`
    ${({isDisabled, theme: {spacing, palette, typography}}: ItemsProps) => css`
        text-align: center;
        padding: ${spacing}px;
        display: grid;
        grid-template-rows: auto;
        justify-items: center;
        align-items: center;
        gap: ${spacing}px;
        opacity: ${isDisabled ? 0.5 : 1};
        cursor: ${isDisabled ? 'not-allowed' : 'pointer'};
        width: 100%;
        &:hover {
            cursor: ${isDisabled ? 'not-allowed' : 'pointer'};
            background-color: ${isDisabled ? 'transparent' : palette.primary[100]};
        }
        & span {
            ${typography.textXs}
            color: ${palette.neutral[300]};
        }
    `}
`

export const StyledPaidText = styled.span`
    ${({theme: {typography, palette}}) => css`
        ${typography.textSm}
        color: ${palette.primary[900]};
    `}
`

export const StyledContainerOptions = styled(Flexbox)`
    ${({theme, withBorder}: {theme: DefaultTheme; withBorder?: boolean}) => css`
        padding: ${theme.spacing}px;

        width: 100%;
        cursor: pointer;
        ${withBorder &&
        css`
            border-bottom: solid 1px ${theme.palette.neutral[400]};
        `}
    `}
`

export const StyledSubtitleModal = styled.h2`
    ${({theme: {typography, palette}}) => css`
        ${typography.textXXl}
        color: ${palette.primary[900]};
    `}
`

export const StyledAmountsWrapper = styled.h2`
    ${({theme: {mediaQueries}}) => css`
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 10px;
        width: 100%;
        ${mediaQueries.m} {
            grid-template-columns: repeat(3, 1fr);
        }
    `}
`

export const StyledUploadPaidMediaContainer = styled.h2`
    ${({theme}) => css`
        display: grid;
        grid-template-columns: auto 1fr auto;
        gap: ${theme.spacing}px;

        svg {
            cursor: pointer;
        }
    `}
`

export const StyledUploadMediaDescription = styled.div`
    ${({theme}) => css`
        display: grid;
        grid-template-rows: auto;
        gap: ${theme.spacing + 2}px;
        & p {
            ${theme.typography.textXxs}
            color: ${theme.palette.neutral[300]};
        }

        & span {
            ${theme.typography.textSm}
            color: ${theme.palette.primary[900]};
        }
    `}
`

export const StyledParagraph = styled.span`
    ${({theme}) => css`
        color: ${theme.palette.secondary[100]};
        text-align: center;
        ${theme.typography.textSm}
    `}
`

export const StyledPreview = styled.div`
    ${({theme}) => css`
        background-color: ${theme.palette.neutral[100]};
        position: relative;
        align-items: center;
        justify-content: center;
        display: flex;
        height: 68px;
        width: 68px;
        border-radius: 5px;
        overflow: hidden;

        & svg {
            z-index: 2;
            position: absolute;
            & path {
                fill: ${theme.palette.neutral['white']};
            }
        }

        & video {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }

        &:before {
            content: ' ';
            filter: brightness(60%);
            width: 100%;
            height: 100%;
            position: absolute;
            border-radius: 5px;
            background: ${theme.palette.gradient['900']};
            z-index: 1;
        }
    `}
`
export const StyledItem = styled.div`
    ${({isDisabled, theme: {spacing, palette, typography}}: ItemsProps) => css`
        text-align: center;
        padding: ${spacing}px;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: ${spacing}px;
        opacity: ${isDisabled ? 0.5 : 1};
        cursor: ${isDisabled ? 'not-allowed' : 'pointer'};
        width: 100%;
        position: relative;
        &:hover {
            background-color: ${isDisabled ? 'transparent' : palette.primary[100]};
        }
        & span {
            ${typography.textXs}
            color: ${palette.neutral[300]};
        }
    `}
`

export const StyledPaidMessageModalWrapper = styled(Flexbox)`
    ${({theme: {spacing, palette}}) => css`
        height: 100%;
        overflow: auto;
        &::-webkit-scrollbar {
            background: ${palette.neutral.transparent};
            width: 5px;
            border-radius: 3px;
        }

        &::-webkit-scrollbar-thumb {
            background: ${palette.neutral.black};
        }

        &::-webkit-scrollbar-track {
            margin-bottom: ${spacing * 4}px;
        }
        &::-webkit-scrollbar-button:start {
            display: none;
        }
        &::-webkit-scrollbar-button:end {
            display: none;
        }
    `}
`

export const StyledTextareaWithBannedElementsTrap = styled(TextareaWithBannedElementsTrap)`
    ${({theme: {typography}}) => css`
        & > div {
            ${typography.textMd}
            min-height: 56px;
            max-height: 56px;
            min-width: auto;
        }
    `}
`

export const StyledSpinnerWrap = styled.div`
    ${() => css`
        position: absolute;
        top: -50px;
        margin-left: auto;
        margin-right: auto;
        left: 0;
        right: 0;
        text-align: center;
    `}
`
