import styled, {DefaultTheme, FlattenSimpleInterpolation, css} from 'styled-components'

type StyledStyledInputProps = {
    disabled?: boolean
}

export const StyledInputRoot = styled.div`
    ${({
        radioInputStyles,
        theme
    }: {
        radioInputStyles?: (theme: DefaultTheme) => FlattenSimpleInterpolation
        theme: DefaultTheme
    }) => radioInputStyles && radioInputStyles(theme)};
`

export const StyledInput = styled.input<StyledStyledInputProps>`
    ${({disabled, theme: {palette}}) => css`
        position: absolute;
        opacity: 0;
        border: none;
        &:checked + ${StyledOptionLabel}:before {
            background-color: ${palette.primary[200]};
            box-shadow: inset 0 0 0 2px white;
            ${disabled &&
            css`
                cursor: not-allowed;
                opacity: 0.5;
            `};
        }
    `}
`

export const StyledOptionLabel = styled.label<StyledStyledInputProps>`
    ${({disabled, theme: {palette, spacing}}) => css`
        font-weight: 700;
        line-height: 1.8;
        cursor: pointer;

        &:before {
            content: '';
            border-radius: 50%;
            border: 1px solid ${palette.neutral[400]};
            display: inline-block;
            width: 15px;
            height: 15px;
            position: relative;
            vertical-align: middle;
            cursor: pointer;
            text-align: center;
            margin-right: ${spacing / 2}px;
        }

        ${disabled &&
        css`
            color: ${palette.neutral[400]};
            cursor: not-allowed;
            opacity: 0.5;
            &:before {
                cursor: not-allowed;
            }
        `};
    `}
`
