export const shadows = {
    xs: '0px 1px 2px rgba(16, 24, 40, 0.05)',
    sm: '0px 1px 3px rgba(16, 24, 40, 0.1), 0px 1px 2px rgba(16, 24, 40, 0.06)',
    md: '0px 4px 10px 0px rgb(0 0 0 / 15%)',
    mdXl: '0px 2px 10px rgba(0, 0, 0, 0.25)',
    lg: '0px 12px 16px -4px rgba(16, 24, 40, 0.08), 0px 4px 6px -2px rgba(16, 24, 40, 0.03)',
    xl: 'rgba(23, 29, 41, 0.08) 0px 0px 12px 0px',
    '2xl': '0px 24px 48px -12px rgba(16, 24, 40, 0.18)',
    '3xl': '0px 32px 64px -12px rgba(16, 24, 40, 0.14)',
    '4xl': 'rgba(23, 29, 41, 0.08) 0px 0px 12px 0px',
    '5xl': '0 0 0 1px hsla(0, 0%, 0%, 0.1), 0 4px 11px hsla(0, 0%, 0%, 0.1)',
    '6xl': '0px 4px 10px rgba(0, 0, 0, 0.15)',
    '7xl': ' 0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
    '8xl': ' 0 0 20px 4px rgba(154, 161, 177, 0.15),0 4px 80px -8px rgba(36, 40, 47, 0.25),0 4px 4px -2px rgba(91, 94, 105, 0.15)',
    '9xl': 'rgba(0, 0, 0, 0.5) 5px 5px 15px 5px;',
    '10xl': '0px 1px 4px rgba(0, 0, 0, 0.25)'
}
