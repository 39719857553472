import styled, {DefaultTheme, FlattenSimpleInterpolation, css} from 'styled-components'

export const StyledTagRoot = styled.div`
    ${({
        theme: {spacing},
        tagStyles
    }: {
        theme: DefaultTheme
        tagStyles?: (theme: DefaultTheme) => FlattenSimpleInterpolation
    }) => css`
        display: grid;
        align-items: center;
        grid-template-columns: auto 1fr;
        gap: ${spacing / 2}px;
        padding: ${spacing / 2}px;
        border-radius: ${spacing}px;
        font-size: 12px;
        font-weight: 700;
        height: fit-content;
        ${({theme}) => tagStyles && tagStyles(theme)};
    `}
`
