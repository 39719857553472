export const MUTATION_KEYS = {
    set_tips_and_gifts: 'set-tips-and-gifts',
    set_uncover_attachment: 'set-uncover-attachment',
    set_monthly_support: 'set-monthly-support',
    set_goal_support: 'set-goal-support',
    update_tips_and_gifts: 'update-tips-and-gifts',
    update_uncover_attachment: 'update-uncover-attachment',
    delete_monthly_support: 'delete-monthly-support',
    delete_goal_support: 'delete-goal-support'
}

export const QUERY_KEYS = {
    thank_yous_types: 'thank-yous-types',
    host_thank_yous: 'host-thank-yous',
    host_thank_yous_by_id: 'host-thank-yous-by-id',
    tips_and_gifts: 'tips-and-gifts',
    uncover_attachment: 'uncover-attachment',
    monthly_support_packages: 'monthly-support-packages',
    goal_support_packages: 'goal-support-packages'
}
