import styled, {DefaultTheme, css} from 'styled-components'
import * as AvatarPrimitive from '@radix-ui/react-avatar'

type AvatarProps = {
    width?: number
    height?: number
    radius?: number
    $uniqueColor?: string
}
export const AvatarRoot = styled(AvatarPrimitive.Root)<AvatarProps>`
    ${({width, height, radius, $uniqueColor, theme: {palette}}) => css`
        display: inline-flex;
        align-items: center;
        justify-content: center;
        vertical-align: middle;
        user-select: none;
        min-width: ${width}px;
        max-width: ${width}px;
        min-height: ${height}px;
        max-height: ${height}px;
        border-radius: ${radius}%;
        overflow: hidden;
        border: 1px ${palette.neutral['300']};
        background: ${$uniqueColor};
    `}
`
export const AvatarImage = styled(AvatarPrimitive.Image)<AvatarProps>`
    ${({width, height}) => css`
        width: ${width}px;
        height: ${height}px;
        object-fit: cover;
        border-radius: inherit;
    `}
`
export const AvatarFallback = styled(AvatarPrimitive.Fallback)`
    ${({$initialFontSize, theme: {palette}}: {$initialFontSize?: number; theme: DefaultTheme}) => css`
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        background: inherit;
        color: ${palette.neutral.white};
        font-size: ${$initialFontSize ?? 16}px;
    `}
`
