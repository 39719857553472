import {useTranslation} from 'react-i18next'
import useWindowDimensions from '@/hooks/useWindowDimensions'
import {StyledButtonWrapper, StyledEmptySection, StyledTitleWrapper} from './style'
import {Button} from '@/components/ui/button/Button'
import {ROUTE_HOME} from '@/utilities/constants/routeNames'
import {useNavigate} from 'react-router-dom'

export const GoalNotFoundSection = ({isGoalModalMode, onClose}: {isGoalModalMode: boolean; onClose: () => void}) => {
    const {t} = useTranslation()
    const {height: vh} = useWindowDimensions()
    const navigate = useNavigate()
    return (
        <StyledEmptySection vh={vh}>
            <StyledTitleWrapper>
                <p>{t('goal_page:not_found')}</p>
            </StyledTitleWrapper>
            <StyledButtonWrapper>
                <Button onClick={() => (isGoalModalMode ? onClose() : navigate(ROUTE_HOME))}>
                    {t('change_password:success:cta')}
                </Button>
            </StyledButtonWrapper>
        </StyledEmptySection>
    )
}
