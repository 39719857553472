import {StyledTabsList, StyledTabsTrigger} from './style'
import * as RadixTabs from '@radix-ui/react-tabs'
import {ReactNode} from 'react'

type FiltersTabsProps = {
    filterTabs: {value: string; icon: ReactNode}[]
    defaultValue?: string
    tabsContent: Record<string, ReactNode>
    selectedValueCount: Record<string, number>
}

export const FiltersTabs = ({filterTabs, defaultValue, selectedValueCount, tabsContent}: FiltersTabsProps) => {
    return (
        <RadixTabs.Root defaultValue={defaultValue ?? filterTabs[0].value}>
            <StyledTabsList $tabsCount={filterTabs.length}>
                {filterTabs.map((item, idx) => {
                    const count = selectedValueCount[item.value]

                    return (
                        <StyledTabsTrigger
                            key={item.value}
                            value={item.value}
                            $hasNextTab={!!filterTabs[idx + 1]}
                            $hasPrevTab={!!filterTabs[idx - 1]}
                            children={
                                <>
                                    {item.icon}
                                    {count > 0 && <div>{`+${count}`}</div>}
                                </>
                            }
                        />
                    )
                })}
            </StyledTabsList>

            {filterTabs.map(item => (
                <RadixTabs.Content value={item.value} key={item.value}>
                    {tabsContent[item.value]}
                </RadixTabs.Content>
            ))}
        </RadixTabs.Root>
    )
}
FiltersTabs.displayName = 'FiltersTabs'
