import {$createParagraphNode, $createTextNode, $getRoot} from 'lexical'
import {useEffect} from 'react'
import {$generateNodesFromDOM} from '@lexical/html'
import {useLexicalComposerContext} from '@lexical/react/LexicalComposerContext'
import {$createBannedElement} from '../banned-element-node/BannedElement'
import {ValidationResult} from '../../types'
import useMixPanel from '@/integrations/mixpanel/hooks/useMixpanel'
import {MixpanelEventNameE} from '@/integrations/mixpanel/types'

export function ShowTrappedTextPlugin({
    filteredValue,
    onBannedElementClick
}: {
    filteredValue: ValidationResult
    onBannedElementClick: (type: string, element: HTMLElement) => void
}) {
    const [editor] = useLexicalComposerContext()
    const mixpanel = useMixPanel()

    function update() {
        editor.update(() => {
            $getRoot()
                .getChildren()
                .forEach(n => n.remove())
            const parser = new DOMParser()
            const dom = parser.parseFromString(filteredValue.filteredMessageText || '', 'text/html')
            const nodes = $generateNodesFromDOM(editor, dom)
            const paragraphNode = $createParagraphNode()

            nodes.forEach(n => {
                const text = n.getTextContent()
                let textNode
                if (filteredValue.filteredBannedElements.find(el => el.string === text && el.type === 'hidden')) {
                    textNode = $createBannedElement(text, onBannedElementClick, 'bannedLink')
                    mixpanel?.trackEvent(MixpanelEventNameE.LinkBlockedFromSending, {link_url: text})
                } else if (filteredValue.filteredBannedElements.find(el => el.string === text && el.type === 'valid')) {
                    textNode = $createBannedElement(text, onBannedElementClick, 'validLink')
                } else if (
                    filteredValue.filteredBannedElements.find(el => el.string === text && el.type === 'number')
                ) {
                    textNode = $createBannedElement(text, onBannedElementClick, 'bannedNumber')
                    mixpanel?.trackEvent(MixpanelEventNameE.PhoneBlockedFromSending, {message_sent: text})
                } else if (
                    filteredValue.filteredBannedElements.find(
                        el => el.string && el.string.toLowerCase() === text.toLowerCase() && el.type === 'word'
                    )
                ) {
                    textNode = $createBannedElement(text, onBannedElementClick, 'bannedWord')
                } else {
                    textNode = $createTextNode(text)
                }
                paragraphNode.append(textNode).selectEnd()
            })
            $getRoot().append(paragraphNode)
        })
    }

    useEffect(() => {
        !!filteredValue.filteredMessageText && update()
    }, [
        filteredValue.numberOfBannedElements,
        filteredValue.filteredBannedElements[filteredValue.filteredBannedElements.length - 1]?.string?.length
    ])

    return null
}
