import {Button} from '@/components/ui/button/Button'
import styled, {css} from 'styled-components'

type StyledGifsListProps = {$isLoaded: boolean}

export const StyledGifsList = styled.div<StyledGifsListProps>`
    ${({$isLoaded, theme: {spacing, mediaQueries}}) => css`
        display: flex;
        max-height: 500px;
        min-height: max-content;
        overflow: auto;
        margin-bottom: 10px;
        ${$isLoaded
            ? css`
                  display: flex;
                  flex-direction: column;
                  align-items: center;
                  gap: ${spacing}px;
                  padding: 0px ${spacing * 1.25}px ${spacing * 1.25}px;
                  flex-direction: row;
                  flex-wrap: wrap;
                  justify-content: space-between;
                  & ${StyledGifItem} {
                      &:nth-child(4n + 1) {
                          background-color: rgb(230, 225, 255);
                      }
                      &:nth-child(4n + 2) {
                          background-color: rgb(255, 240, 221);
                      }
                      &:nth-child(4n + 3) {
                          background-color: rgb(255, 253, 222);
                      }
                      &:nth-child(4n + 4) {
                          background-color: rgb(255, 220, 242);
                      }
                      &:nth-child(even) {
                          position: relative;
                          top: 20px;

                          ${mediaQueries.m} {
                              top: 30px;
                          }
                          ${mediaQueries.l} {
                              top: 40px;
                          }
                      }
                  }

                  ${mediaQueries.m} {
                      gap: ${spacing * 1.25}px;
                  }
                  ${mediaQueries.l} {
                      gap: ${spacing * 1.5}px;
                  }
              `
            : css`
                  justify-content: center;
                  align-items: center;
              `}
    `}
`

export const StyledGifItem = styled.div`
    ${({theme: {spacing, mediaQueries}}) => css`
        height: 223px;
        width: 171px;
        display: flex;

        align-items: center;
        border-radius: ${spacing * 2}px;
        flex-direction: column;
        justify-content: space-between;
        width: 47%;
        padding: ${spacing}px ${spacing * 1.5}px;

        ${mediaQueries.m} {
            padding: ${spacing * 2}px ${spacing * 3}px;
        }
        ${mediaQueries.l} {
            padding: ${spacing * 2.5}px ${spacing * 4.5}px;
        }
    `}
`

export const StyledGif = styled.img`
    ${() => css`
        width: 100px;
        height: 100px;
    `}
`
export const StyledGifTitle = styled.p`
    ${({theme: {typography, palette, spacing}}) => css`
        ${typography.textMd}
        color: ${palette.neutral.black};
        margin-top: ${spacing * 1.25}px;
        font-weight: 600;
        line-height: ${spacing * 3}px;
        white-space: nowrap;
    `}
`
export const StyledGifAmountContainer = styled.div`
    ${({theme: {typography}}) => css`
        ${typography.textSm};
        font-weight: 600;
        display: flex;
        align-items: center;
    `}
`
export const StyledOpenConfirmModalButton = styled(Button)`
    ${({theme: {typography, spacing, palette}}) => css`
        ${typography.textSm}
        width: 90px;
        padding: 0;
        font-weight: 600;
        line-height: ${spacing * 2}px;
        white-space: nowrap;
        border-radius: 100px;
        letter-spacing: -0.3px;
        border: 1px solid ${palette.primary[100]};
        height: 28px;
        min-height: 28px;
    `}
`
