import {StyledMedia} from './style'
import ResponsiveVideo from '@/components/commons/responsive-video/ResponsiveVideo'
import {Media} from '@/components/ui/media/Media'
import {CloseCircleIcon} from '@/assets/icons/icons'
import {MouseEvent, useEffect} from 'react'

type PreviewMediaProps = {
    onRemove: (e: MouseEvent<Element, globalThis.MouseEvent>) => void
    url?: string
    isImage?: boolean
    onClick?: () => void
}

export const PreviewMedia = ({onRemove, url, isImage, onClick}: PreviewMediaProps) => {
    useEffect(() => {
        return () => {
            if (url) {
                URL.revokeObjectURL(url)
            }
        }
    }, [])

    return (
        <StyledMedia onClick={onClick}>
            {isImage ? <Media imageUrl={`${url}`} /> : <ResponsiveVideo video={`${url}`} controls={false} />}
            <CloseCircleIcon onClick={onRemove} />
        </StyledMedia>
    )
}
