import {HostPartialSchema} from '@/features/authentication/services/auth.schema'
import {GoalSchema} from '@/features/goal/services/goal.schema'
import {z} from 'zod'
import {ConnectorNamesE} from './moengage.model'

// Schema for the connector object
export const ConnectorSchema = z.object({
    type: z.nativeEnum(ConnectorNamesE).optional(),
    name: z.string().optional(),
    image: z.string().url().optional(),
    timer: z.string().optional(),
    cta_url: z.string().url().optional(),
    description: z.string().optional(),
    header_title: z.string().optional(),
    element_color: z.string().optional(),
    cta_button_text: z.string().optional(),
    page_to_present: z.union([z.array(z.string()).optional(), z.string().optional()]),
    cta_button_color: z.string().optional(),
    description_color: z.string().optional(),
    header_title_color: z.string().optional(),
    notification: z
        .union([
            z.string(),
            z.object({
                message: z.string().optional(),
                type: z.string().optional()
            })
        ])
        .optional()
})

export const PackageSchema = z.object({
    id: z.number(),
    micromorgi_count: z.number().optional(),
    price: z.number().optional(),
    level_status: z.string().optional(),
    is_visible: z.boolean().optional()
})

// Schema for the dynamic_template object
export const DynamicTemplateSchema = z.object({
    type: z.string().optional(),
    name: z.string().optional(),
    promo: z
        .object({
            type: z.string(),
            amount: z.number()
        })
        .optional(),
    package: PackageSchema.optional(),
    host_list: z.array(HostPartialSchema).optional(),
    goal: GoalSchema.optional(),
    cta_type: z.string().optional()
})

// Overall schema for the object
export const MoengageObjectSchema = z.object({
    id: z.number().optional(),
    name: z.string().optional(),
    connector: ConnectorSchema,
    dynamic_template: DynamicTemplateSchema.optional().nullable(),
    expires_at: z.string().optional().nullable()
})
