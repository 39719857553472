import {ReactNode, createContext} from 'react'
import mixpanelBrowser from 'mixpanel-browser'
import {UserRoleE} from '@/types.ts'
import {MixPanelContextType, MixpanelType} from '../../types'
import {useTriggerBackend} from '../../queries/useTriggerBackend'

export const MixPanelContext = createContext<MixPanelContextType | null>(null)

const MixPanelProvider = ({children, isActive}: {children: ReactNode; isActive: boolean}) => {
    const {mutate} = useTriggerBackend()
    const track = mixpanelBrowser.track.bind(mixpanelBrowser)
    const identify = mixpanelBrowser.identify.bind(mixpanelBrowser)
    const reset = mixpanelBrowser.reset.bind(mixpanelBrowser)
    const register_once = mixpanelBrowser.register_once.bind(mixpanelBrowser)
    const time_event = mixpanelBrowser.time_event.bind(mixpanelBrowser)
    const isMixpanelActive = isActive && Object.keys(mixpanelBrowser).length

    const mixpanel: MixpanelType = {
        identifyUser: userID => {
            if (!userID) {
                console.warn('mixpanel-wrapper: userID not provided')
                return
            }

            // mixpanel.identify must be called in order to associate the profile properties you set
            // with that user. You only need to call it once per page load for a given user.
            return identify(userID)
        },
        trackEvent: (eventName, eventProperties) => {
            const timeoutDelay = 5000
            if (isActive) {
                setTimeout(() => {
                    if (!eventName) {
                        console.warn('mixpanel-wrapper: event name not provided')
                        return
                    }
                    if (eventProperties) {
                        if (typeof eventProperties === 'object' && !Array.isArray(eventProperties)) {
                            return track(eventName, eventProperties)
                        } else {
                            console.warn('mixpanel-wrapper: event properties provided are malformed')
                        }
                    } else {
                        return track(eventName)
                    }
                }, timeoutDelay)
            }
        },
        setSuperPropsOnce: superPropsObj => {
            if (typeof superPropsObj !== 'object' || Array.isArray(superPropsObj) || superPropsObj === null) {
                console.warn('mixpanel-wrapper: super properties provided are malformed')
                return
            }
            return register_once(superPropsObj)
        },
        startTimeEvent: eventName => {
            return time_event(eventName)
        },
        reset: () => {
            return reset()
        },
        triggerBackendEvent: ({eventType, userRole, userId, mixpanelData}) => {
            // provider
            if (isActive) {
                const data = () => {
                    switch (true) {
                        case !!userRole && userRole === UserRoleE.Host:
                            return {
                                type: eventType,
                                leader_id: userId,
                                data: mixpanelData ? mixpanelData : null
                            }
                        case !!userRole && userRole === UserRoleE.Guest:
                            return {
                                type: eventType,
                                rookie_id: userId,
                                data: mixpanelData ? mixpanelData : null
                            }
                        default:
                            return {
                                type: eventType,
                                data: mixpanelData ? mixpanelData : null
                            }
                    }
                }
                const body = data()

                mutate(body)
            }
        }
    }

    return <MixPanelContext.Provider value={isMixpanelActive ? {mixpanel} : null}>{children}</MixPanelContext.Provider>
}

export default MixPanelProvider
