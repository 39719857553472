import {Lens} from '@dhmk/zustand-lens'

type GetUltimateStatusModalState = {
    isOpen: boolean
}

type GetUltimateStatusModalActions = {
    openModal: (params: Omit<GetUltimateStatusModalState, 'isOpen'>) => void
    closeModal: () => void
}

export type GetUltimateStatusModal = GetUltimateStatusModalState & GetUltimateStatusModalActions

const initialModal: GetUltimateStatusModalState = {isOpen: false}

const createGetUltimateStatusModalSlice: Lens<GetUltimateStatusModal> = set => ({
    ...initialModal,
    openModal: params => set(() => ({...params, isOpen: true})),
    closeModal: () => set(() => ({...initialModal}))
})

export default createGetUltimateStatusModalSlice
