import {Modal} from '@/components/ui/modal/Modal'
import {useRootStore} from '@/store'
import {selectUpdateCreditCardModal} from '@/store/selectors'
import {FC} from 'react'
import {useTranslation} from 'react-i18next'
import {ListOfSupportedUsers} from '../components/list-of-supported-users/ListOfSupportedUsers'
import {StyledUpdateCreditCardModalContainer, StyledUpdateCreditCardModalDescription} from './style'

export const UpdateCreditCardModal: FC = () => {
    const {t} = useTranslation()
    const {closeModal} = useRootStore(selectUpdateCreditCardModal)
    return (
        <Modal
            title={t('leader_payments:title')}
            onClose={closeModal}
            minHeight="50vh"
            maxWidth="400px"
            maxHeight="95vh"
        >
            <StyledUpdateCreditCardModalContainer>
                <StyledUpdateCreditCardModalDescription>
                    {t('leader_payments:description')}
                </StyledUpdateCreditCardModalDescription>
                <ListOfSupportedUsers />
            </StyledUpdateCreditCardModalContainer>
        </Modal>
    )
}
