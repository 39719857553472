export const MUTATION_KEYS = {
    change_badge_visibility: 'change-badge-visibility'
}

export const QUERY_KEYS = {
    all_top_fans: 'all-top-fans',
    all_host_top_fans: 'all-host-top-fans',
    whos_top_fan_you_are: 'whos-top-fan-you-are',
    guest_top_fan_info: 'guest-top-fan-info'
}
