import styled, {DefaultTheme, css} from 'styled-components'

export const StyledGoalPageRoot = styled.div`
    ${({theme: {mediaQueries, sizing, spacing}, vh}: {theme: DefaultTheme; vh: number}) => css`
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 100%;
        width: 100%;
        min-height: calc(${vh}px - (${sizing.headerMobileHeight}));
        padding-bottom: calc(${sizing.goalSupportersSectionHeight} + ${spacing * 3}px);
        position: relative;

        & h1 {
            overflow: hidden;
            max-width: 290px;
            white-space: nowrap;
            text-overflow: ellipsis;

            ${mediaQueries.m} {
                max-width: 1000px;
            }
        }

        ${mediaQueries.m} {
            min-height: calc(${vh}px - (${sizing.headerDesktopHeight}));
        }
    `}
`

export const StyledSupportersSectionWrapper = styled.div`
    ${() => css`
        display: flex;
        place-content: center;
    `}
`
