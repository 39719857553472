import styled, {css} from 'styled-components'

export const StyledEditButton = styled.div`
    ${({theme: {palette, typography}}) => css`
        color: ${palette.primary['200']};
        ${typography.textXs};
        cursor: pointer;
        font-weight: 700;
    `}
`

export const StyledCanceled = styled.div`
    ${({theme: {palette, typography}}) => css`
        color: ${palette.danger['50']};
        ${typography.textXs};
    `}
`
