import {Lens} from '@dhmk/zustand-lens'

type WalletModalState = {
    isOpen: boolean
}

type WalletModalActions = {
    openModal: (params: Omit<WalletModalState, 'isOpen'>) => void
    closeModal: () => void
}

export type WalletModal = WalletModalState & WalletModalActions

const initialModal: WalletModalState = {isOpen: false}

const createWalletModalSlice: Lens<WalletModal> = set => ({
    ...initialModal,
    openModal: params => set(() => ({...params, isOpen: true})),
    closeModal: () => set(() => ({...initialModal}))
})

export default createWalletModalSlice
