import axios from '@/clientAPI.ts'
import {MoengageObjectSchema} from './moengage.schema'
import moengageModel from './moengage.model'
import {MoengageObjectResponseType} from '../types'

export const MOENGAGE_API = {
    getMoengage: async (): Promise<MoengageObjectResponseType | null> => {
        const url = '/campaigns'
        const {data} = await axios.get(url)
        const originalData = Array.isArray(data) ? null : MoengageObjectSchema.parse(data)
        return moengageModel(originalData)
    },
    combinationSeen: async ({campaign_id}: {campaign_id: string | number}) => {
        const url = '/campaigns/seen'
        return await axios.post(url, {campaign_id})
    },
    getFreeMorgis: async ({campaign_id}: {campaign_id: string | number}) => {
        await axios.post('/free-micromorgi', {
            campaign_id
        })
    }
}
