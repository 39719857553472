import axios from '@/clientAPI.ts'
import {Message} from '@/features/common/types'
import {AxiosResponse} from 'axios'
import * as types from '../types'
import {SubscriptionStatusesE} from '@/features/chat/helpers/helpers'

export const RECURRING_GIFT_API = {
    getSubscribedGuests: async (status: string): Promise<types.Donation[]> => {
        const {data} = await axios.get('/subscriptions/leaders', {params: {status}})
        return data
    },
    getSubscribedHosts: async (status: SubscriptionStatusesE): Promise<types.Donation[]> => {
        const {data} = await axios.get('/subscriptions/rookies', {params: {status}})
        return data
    },
    getSubscriptionsAmounts: async (
        is_edit: number //1 | 0
    ): Promise<types.DonationType[]> => {
        const {data} = await axios.get('/subscriptions/packages', {params: {is_edit}})
        return data
    },
    sendGift: async ({
        hostID,
        subscriptionId,
        campaign_id
    }: types.SendGiftRequest): Promise<AxiosResponse<types.CreditCardUrl | Message>> => {
        const {data} = await axios.post(`/rookies/${hostID}/subscriptions/old`, {
            subscription_package_id: subscriptionId,
            campaign_id
        })
        return data
    },
    deleteGift: async (hostID: string): Promise<Message> => {
        const {data} = await axios.delete(`/rookies/${hostID}/subscriptions`)
        return data
    }
}
