import {useQuery} from '@tanstack/react-query'
import {TRANSLATE_API} from '../services/translation.http.ts'
import {GetLanguageOfMessageProps} from '../types'
import {QUERY_KEYS} from './keys.ts'

export const useGetLanguageOfMessage = (params: GetLanguageOfMessageProps) => {
    return useQuery({
        queryKey: [QUERY_KEYS.language_of_message, params],
        queryFn: () => TRANSLATE_API.getLanguageOfMessage(params),
        enabled: !!params.text
    })
}
