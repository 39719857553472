import styled, {css} from 'styled-components'

export const StyledLogo = styled.img`
    ${({theme: {mediaQueries}}) => css`
        grid-column-start: 2;
        height: 100%;
        width: 100%;
        max-height: 30px;
        max-width: 58px;
        object-fit: contain;
        cursor: pointer;
        margin-bottom: 3px;
        ${mediaQueries.m} {
            max-height: 40px;
            max-width: 85px;
        }
    `}
`

export const StyledAnimation = styled.div`
    ${({theme: {mediaQueries}}) => css`
        height: 100%;
        width: 100%;
        max-height: 77px;
        max-width: 104px;
        transform: translate(-4px, 1px);
        ${mediaQueries.m} {
            max-width: 171px;
            max-height: 96px;
            margin-top: 3px;
        }
    `}
`
