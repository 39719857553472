import styled, {DefaultTheme, FlattenSimpleInterpolation, css} from 'styled-components'

export const StyledCardDetailsRoot = styled.div`
    ${({
        theme,
        cardRootStyles
    }: {
        cardRootStyles?: (theme: DefaultTheme) => FlattenSimpleInterpolation
        theme: DefaultTheme
    }) => css`
        z-index: 2;
        ${cardRootStyles && cardRootStyles(theme)};
    `}
`

export const StyledCard = styled.div`
    ${({theme: {spacing, mediaQueries}, inMainGoalPage}: {theme: DefaultTheme; inMainGoalPage: boolean}) => css`
        display: grid;
        align-items: center;
        grid-template-columns: auto 0fr;
        gap: ${spacing * 2}px;
        padding: ${spacing * 2}px;

        ${mediaQueries.m} {
            padding-top: ${inMainGoalPage ? spacing * 3 : spacing * 2}px;
            padding-bottom: ${inMainGoalPage ? spacing * 3 : spacing * 2}px;
        }
    `}
`

export const StyledPastGoalSection = styled.div`
    ${({theme: {palette, spacing, typography}}) => css`
        background-color: ${palette.neutral.black};
        color: ${palette.neutral.white};
        padding: ${spacing / 2}px ${spacing * 2}px ${spacing / 2}px;
        ${typography.textXs};
        font-weight: 700;
    `}
`

export const StyledAmountWrapper = styled.div`
    ${({theme: {typography, mediaQueries}, inMainGoalPage}: {theme: DefaultTheme; inMainGoalPage?: boolean}) => css`
        ${mediaQueries.m} {
            ${inMainGoalPage ? typography.textXXl : typography.textMd}

            & div {
                ${typography.textMd}
            }
        }
    `}
`

export const StyledAmount = styled.div`
    ${({theme: {typography, mediaQueries}}) => css`
        ${typography.textXs}
        font-weight: 700;
        ${mediaQueries.m} {
            ${typography.textSm}
        }
    `}
`

export const StyledInfo = styled.div`
    ${({theme: {spacing, mediaQueries}}) => css`
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        gap: ${spacing / 2}px;

        & div {
            display: flex;
            align-items: center;
            gap: ${spacing}px;
        }

        ${mediaQueries.m} {
            gap: ${spacing}px;
        }
    `}
`

export const StyledText = styled.div`
    ${({
        theme: {spacing, typography, mediaQueries},
        inMainGoalPage
    }: {
        theme: DefaultTheme
        inMainGoalPage?: boolean
    }) => css`
        display: flex;
        gap: ${spacing}px;
        font-weight: 700;
        ${typography.textXs}

        ${mediaQueries.m} {
            ${inMainGoalPage &&
            css`
                ${typography.textLg}
            `}
        }
    `}
`

export const StyledCardTitle = styled.div`
    ${({theme: {typography}}) => css`
        font-weight: 700;
        ${typography.textMd}
    `}
`

export const StyledGoalCountdown = styled.div<{inMainGoalPage?: boolean}>`
    ${({theme: {typography, mediaQueries}, inMainGoalPage}) => css`
        font-weight: 700;
        ${mediaQueries.m} {
            & div {
                ${inMainGoalPage && typography.textSm}
            }
        }
    `}
`
