import {QuestionMarkIcon} from '@/assets/icons/icons'
import {Button} from '@/components/ui/button/Button'
import {LinearProgress} from '@/components/ui/linear-progress/LinearProgress'
import {Modal} from '@/components/ui/modal/Modal'
import topFanModalImage from '@assets/images/topFan/topFanModalImage.png'
import styled, {css} from 'styled-components'

export const StyledTopFanCompleteModalWrapper = styled.div`
    ${({theme: {spacing}}) => css`
        display: grid;
        grid-template-rows: 1fr 0fr;
        padding: ${spacing}px;
        gap: ${spacing / 2}px;
        position: relative;
        z-index: 1;
        overflow: auto;
    `}
`
export const StyledIconWrapper = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    overflow: hidden;
    pointer-events: none;

    & svg {
        position: absolute;
    }

    & svg:nth-child(1) {
        width: 82.486px;
        height: 79.484px;
        transform: rotate(-10.744deg);
        top: -20px;
        right: -17px;
        mix-blend-mode: multiply;
    }

    & svg:nth-child(2) {
        width: 62.266px;
        height: 60px;
        top: 97px;
        left: -17px;
        mix-blend-mode: multiply;
    }

    & svg:nth-child(3) {
        width: 38.101px;
        height: 36.715px;
        transform: rotate(24.618deg);
        top: 184px;
        left: 28px;
        mix-blend-mode: multiply;
    }

    & svg:nth-child(4) {
        width: 25.298px;
        height: 24.377px;
        transform: rotate(19.94deg);
        top: 205px;
        right: 57px;
        mix-blend-mode: color-burn;

        & path {
            fill: #8b71ea;
        }
    }

    & svg:nth-child(5) {
        width: 73.602px;
        height: 70.923px;
        transform: rotate(-10.744deg);
        bottom: 148px;
        right: -41px;
        mix-blend-mode: multiply;
    }

    & svg:nth-child(6) {
        width: 38.101px;
        height: 36.715px;
        transform: rotate(24.618deg);
        bottom: 103px;
        left: -11px;
        mix-blend-mode: multiply;
    }

    & svg:nth-child(7) {
        width: 46.908px;
        height: 45.201px;
        transform: rotate(-26.823deg);
        bottom: -13px;
        right: 48px;
        mix-blend-mode: multiply;
    }
`
export const StyledModal = styled(Modal)`
    ${({theme: {spacing}}) => css`
        background: linear-gradient(180deg, #8f90fe 0%, #8b71ea 50.31%, #864bd2 100%);
        padding-bottom: ${spacing * 1.5}px;
    `}
`

export const StyledModalTitle = styled.div`
    ${({theme: {palette, typography}}) => css`
        ${typography.text2Xl}
        font-weight: 700;
        color: ${palette.neutral.white};
        text-align: center;
    `}
`
export const StyledUsername = styled.span`
    ${() => css`
        cursor: pointer;
    `}
`

export const StyledAvatarWrapper = styled.div`
    ${({theme: {spacing}}) => css`
        position: relative;
        margin-bottom: ${spacing * 2}px;
        display: flex;
    `}
`
export const StyledAvatar = styled.img`
    ${({theme: {spacing}}) => css`
        width: 151px;
        height: 151px;
        margin: ${spacing * 2.5}px auto;
        border-radius: 50%;
        object-fit: cover;
    `}
`
export const StyledTopFanBadge = styled.img.attrs(() => ({src: topFanModalImage}))`
    ${() => css`
        left: 50%;
        width: 95px;
        bottom: -16px;
        height: 82px;
        position: absolute;
        transform: translate(-50%, 0%);
    `}
`
export const StyledTopFanPerksBadge = styled.img.attrs(() => ({src: topFanModalImage}))`
    ${() => css`
        width: 35px;
        height: 30px;
    `}
`
export const StyledProgressWrapper = styled.div`
    ${({theme: {spacing, flexUtils}}) => css`
        ${flexUtils.centered}
        gap: ${spacing * 2}px;
        margin-top: 30px;
        width: 100%;
    `}
`
export const StyledPosition = styled.div`
    ${({theme: {typography, flexUtils}}) => css`
        ${typography.textMd}
        ${flexUtils.centered}
        min-width: 38px;
        min-height: 38px;
        font-weight: 700;
        border-radius: 50%;
    `}
`
export const StyledPositionNow = styled(StyledPosition)`
    ${({theme: {palette}}) => css`
        color: ${palette.neutral.black};
        background: linear-gradient(to bottom, #e5d2a5, #feb80c);
    `}
`
export const StyledPositionNext = styled(StyledPosition)`
    ${({theme: {palette}}) => css`
        color: ${palette.neutral.white};
        background: ${palette.primary[900]};
    `}
`
export const StyledProgress = styled(LinearProgress).attrs(({theme: {palette}}) => ({
    mainColor: 'linear-gradient(to bottom, #E5D2A5, #FEB80C)',
    secondaryColor: palette.primary[900]
}))`
    ${({theme: {spacing}}) => css`
        width: 192px;
        height: 16px;
        overflow: hidden;
        position: relative;
        border-radius: ${spacing}px;
    `}
`
export const StyledProgressInfoWrapper = styled.div`
    ${({theme: {flexUtils, palette, spacing}}) => css`
        ${flexUtils.centered}
        color: ${palette.neutral.white};
        gap: ${spacing / 4}px;
    `}
`

export const StyledProgressAmountNext = styled.span`
    ${() => css`
        font-weight: 700;
    `}
`
export const StyledTopFanPerksWrapper = styled.div`
    ${({theme: {spacing}}) => css`
        margin: ${spacing * 2}px auto ${spacing * 3}px;
        padding: ${spacing * 2}px ${spacing * 3}px;
        max-width: 237px;
        min-width: 237px;
        align-items: center;
        border-radius: ${spacing * 1.5}px;
        background-color: #3515ae;
    `}
`
export const StyledTopFanPerksTitleWrapper = styled.div`
    ${({theme: {spacing}}) => css`
        display: flex;
        gap: ${spacing * 0.75}px;
    `}
`
export const StyledTopFanPerksTitle = styled.span`
    ${({theme: {palette, typography}}) => css`
        ${typography.textMd}
        color: ${palette.neutral.white};
        align-self: center;
        text-align: start;
        font-weight: 800;
    `}
`
export const StyledTopFanPerksList = styled.div`
    ${({theme: {typography, mediaQueries}}) => css`
        ${typography.textXxs}
        display: flex;
        flex-direction: column;
        text-align: center;
        color: rgb(255, 255, 255, 0.75);
        text-align: start;
        line-height: 170%;
        width: 111px;
        margin: 0 auto;
        ${mediaQueries.m} {
            ${typography.textXs}
        }
    `}
`
export const StyledQuestionMarkIcon = styled(QuestionMarkIcon).attrs(() => ({fill: 'rgb(255, 255, 255, 0.5)'}))`
    ${() => css`
        cursor: pointer;
        align-self: center;
        margin-left: auto;
    `}
`
export const StyledSubmitButton = styled(Button)`
    ${({theme: {flexUtils, palette, spacing, typography, mediaQueries}}) => css`
        ${typography.textLg}
        ${flexUtils.centered}
        margin-top: ${spacing * 1.5}px;
        background-color: ${palette.primary[900]};
        padding: ${spacing * 1.5}px ${spacing * 3}px;
        gap: ${spacing}px;
        width: 100%;
        font-weight: 600;
        color: ${palette.neutral.white};
        ${mediaQueries.m} {
            ${typography.text2Xl}
        }
    `}
`
export const StyledScrollContainer = styled.div`
    ${({theme: {scrollbar, palette}}) => css`
        ${scrollbar}
        overflow-y: auto;
        display: grid;
        justify-content: center;
        align-items: center;
        justify-items: center;
        height: 100%;
        &::-webkit-scrollbar {
            background: ${palette.neutral.transparent};
            border-right-radius: 80px;
            height: 5px;
            width: 6px;
        }
        &::-webkit-scrollbar-thumb {
            background: ${palette.neutral[200]};
        }
        scrollbar-width: thin; // set the scrollbar width to 'thin'
        -webkit-scrollbar-width: 'thin'; // fallback for Safari
    `}
`
