import treasureAnimation from '@/assets/animations/Approved.json'
import {Animation} from '@/components/ui/animation/Animation'
import {Button} from '@/components/ui/button/Button'
import {Divider} from '@/components/ui/divider/Divider'
import {Modal} from '@/components/ui/modal/Modal'
import {PLAY} from '@/utilities/constants/animation'
import {FC} from 'react'
import {useTranslation} from 'react-i18next'
import {useTheme} from 'styled-components'
import {
    StyledAnimation,
    StyledDailyMicromorgiLimitModalContainer,
    StyledDailyMicromorgiLimitModalContent,
    StyledDailyMicromorgiLimitModalDescription
} from './style'

type DailyMicromorgiLimitModalProps = {
    onClose: () => void
}

export const DailyMicromorgiLimitModal: FC<DailyMicromorgiLimitModalProps> = ({onClose}) => {
    const {t} = useTranslation()
    const {palette} = useTheme()
    return (
        <Modal title={t('error_modal:daily_micromorgi_limit:title')} onClose={onClose} minHeight="580px">
            <StyledDailyMicromorgiLimitModalContainer>
                <StyledDailyMicromorgiLimitModalContent>
                    <StyledDailyMicromorgiLimitModalDescription>
                        {t('error_modal:daily_micromorgi_limit:description', {DATE: t('common:tomorrow')})}
                    </StyledDailyMicromorgiLimitModalDescription>
                    <StyledAnimation>
                        <Animation animationData={treasureAnimation} animationState={PLAY} />
                    </StyledAnimation>
                </StyledDailyMicromorgiLimitModalContent>

                <div>
                    <Divider topSpacing={0} background={palette.neutral[225]} />
                    <Button onClick={onClose}>{t('error_modal:daily_micromorgi_limit:cta')}</Button>
                </div>
            </StyledDailyMicromorgiLimitModalContainer>
        </Modal>
    )
}
