import {StyledAnimation, StyledCloseButton, StyledSurprise, StyledWrapper} from './style'

import {Animation} from '@/components/ui/animation/Animation'
import SloganLight from '@assets/animations/SloganAnimLight.json'
import {Media} from '@/components/ui/media/Media'
import {CloseIcon} from '@/assets/icons/icons'
import {Dispatch, SetStateAction, useRef} from 'react'
import {MediaTypeE} from '@/types'
import VideoPlayer from '@/components/ui/video-player/VideoPlayer'
import {MySurpriseSingleType} from '../../types'
import {Spinner} from '@/components/ui/spinner/Spinner'

export const SurpriseMediaSection = ({
    onClose,
    surprise,
    isShowSurprise,
    setIsShowSurprise,
    isLoading
}: {
    onClose: () => void
    surprise?: MySurpriseSingleType
    isShowSurprise: boolean
    setIsShowSurprise: Dispatch<SetStateAction<boolean>>
    isLoading: boolean
}) => {
    const containerRef = useRef<HTMLDivElement | null>(null)
    const onAnimationCompleteCallback = () => {
        setIsShowSurprise(true)
    }

    return (
        <StyledWrapper ref={containerRef}>
            {!isShowSurprise ? (
                <StyledAnimation>
                    <Animation animationData={SloganLight} onCompleteCallback={onAnimationCompleteCallback} />
                </StyledAnimation>
            ) : isLoading ? (
                <Spinner overlay={false} />
            ) : (
                surprise && (
                    <StyledSurprise containerHeight={containerRef?.current?.clientHeight}>
                        <StyledCloseButton
                            onClick={() => {
                                setIsShowSurprise(false)
                                onClose()
                            }}
                        >
                            <CloseIcon />
                        </StyledCloseButton>
                        {surprise.type === MediaTypeE.IMAGE ? (
                            <Media imageUrl={surprise.url} radius={10} variant="contain" />
                        ) : (
                            <VideoPlayer source={surprise.url} options={{autoplay: false}} />
                        )}
                    </StyledSurprise>
                )
            )}
        </StyledWrapper>
    )
}
