import {GoalStatusesE, GoalType} from '../../types'
import {
    StyledAmount,
    StyledPastGoalSection,
    StyledCardTitle,
    StyledInfo,
    StyledAmountWrapper,
    StyledCard,
    StyledCardDetailsRoot,
    StyledText,
    StyledGoalCountdown
} from './style'
import {useTranslation} from 'react-i18next'
import {calculatePercentage} from '@/utilities/helpers'
import {CircleProgress} from '@/components/ui/circle-progress/CircleProgress'
import {useEffect, useMemo, useState} from 'react'
import {getLocalDate, formatDate, isPasteDate} from '@/dayjs'
import {goalStatusesWithoutTimer, progressGradient} from '../../constant/goalCard'
import GoalCountdown from '../goal-countdown/GoalCountdown'
import {MicromorgiIcon} from '@/assets/icons/icons'
import {Avatar} from '@/components/ui/avatar/Avatar'
import {DefaultTheme, FlattenSimpleInterpolation} from 'styled-components'
import {useMe} from '@/features/authentication/queries/useMe'
import {USER_ROLE_GUEST} from '@/utilities/constants/user'
import {HostDetailsStatusSection} from './host-details-status-section/HostDetailsStatusSection'
import {GuestDetailsStatusSection} from './guest-details-status-section/GuestDetailsStatusSection'
import {breakpoints} from '@/theme/mediaQueries'
import useContainerDimensions from '@/hooks/useContainerDimensions'

type GoalCardDetailsProps = {
    goal: Partial<GoalType>
    cardRootStyles?: (theme: DefaultTheme) => FlattenSimpleInterpolation
    isGoalPageVariant?: boolean
    onEditClick?: () => void
    onWithdrawClick?: () => void
    inMainGoalPage?: boolean
}

const MOBILE_SIZE = breakpoints.width.m

export const GoalCardDetails = ({
    goal = {},
    cardRootStyles,
    isGoalPageVariant = false,
    onEditClick,
    onWithdrawClick,
    inMainGoalPage = false
}: GoalCardDetailsProps) => {
    const {t} = useTranslation()
    const {width} = useContainerDimensions()
    const {end_date, status, rookie} = goal || {}
    const donations_count = goal ? `${goal.donations_count}` : '0'
    const target_amount = goal ? `${goal.target_amount}` : '0'
    const {data} = useMe()
    const isGuest = data?.type === USER_ROLE_GUEST
    const localEndDate = end_date && getLocalDate(end_date)
    const formattedLocalEndDate = localEndDate && formatDate(localEndDate)
    const hasEnded = localEndDate && isPasteDate(localEndDate)
    const hideTimer = status && goalStatusesWithoutTimer.includes(status)
    const isPastGoal = hasEnded || hideTimer
    const percentage = calculatePercentage(parseInt(donations_count), parseInt(target_amount ?? '0'))
    const showCountdown = !isPastGoal && !isGoalPageVariant
    const [isMobile, setIsMobile] = useState<boolean>(width <= MOBILE_SIZE)

    useEffect(() => {
        setIsMobile(width <= MOBILE_SIZE)
    }, [width])

    const strokeColor = useMemo(() => {
        switch (status) {
            case GoalStatusesE.SUCCESSFUL:
            case GoalStatusesE.AWAITING_PROOF:
            case GoalStatusesE.PROOF_PENDING_APPROVAL:
                return progressGradient.success
            case GoalStatusesE.CANCELED:
                return progressGradient.fail
            default:
                return progressGradient.default
        }
    }, [status])

    const pastGoalSectionText = hasEnded
        ? t('goal_card:headerText', {
              ENDED_DATE: formattedLocalEndDate
          })
        : t('goal_card:header_text_end_user')

    return (
        <StyledCardDetailsRoot cardRootStyles={cardRootStyles}>
            {isPastGoal && <StyledPastGoalSection>{pastGoalSectionText}</StyledPastGoalSection>}
            <StyledCard inMainGoalPage={inMainGoalPage}>
                <StyledInfo>
                    {isGoalPageVariant ? (
                        <StyledCardTitle>{t('goal_page:goal_details:title')}</StyledCardTitle>
                    ) : (
                        <div>
                            <Avatar
                                name={rookie?.username}
                                imageUrl={rookie?.avatar?.url}
                                height={inMainGoalPage && !isMobile ? 45 : 30}
                                width={inMainGoalPage && !isMobile ? 45 : 30}
                            />
                            <StyledText inMainGoalPage={inMainGoalPage}>{rookie?.full_name}</StyledText>
                        </div>
                    )}
                    <StyledAmountWrapper inMainGoalPage={inMainGoalPage}>
                        <span>{t('goal_page:goal_details:raised')}</span>
                        <MicromorgiIcon height={14} width={14} />
                        <StyledAmount>
                            {+donations_count} / {+target_amount}
                        </StyledAmount>
                    </StyledAmountWrapper>
                    {isGoalPageVariant &&
                        (isGuest ? (
                            <GuestDetailsStatusSection goal={goal} />
                        ) : (
                            <HostDetailsStatusSection
                                goal={goal}
                                onEditClick={onEditClick}
                                onWithdrawClick={onWithdrawClick}
                                percentage={percentage}
                            />
                        ))}
                    {showCountdown && (
                        <StyledGoalCountdown inMainGoalPage={inMainGoalPage}>
                            <GoalCountdown endDate={end_date ?? ''} />
                        </StyledGoalCountdown>
                    )}
                </StyledInfo>
                <CircleProgress
                    size={inMainGoalPage && !isMobile ? 90 : 65}
                    percentage={percentage}
                    progressColor={strokeColor}
                />
            </StyledCard>
        </StyledCardDetailsRoot>
    )
}
