import {ArrowInSquareIcon, MicromorgiIcon, WalletIcon} from '@/assets/icons/icons'
import {useMe} from '@/features/authentication/queries/useMe'
import {GIFTS_AMOUNTS_LIST} from '@/featuresConfig'
import useMixPanel from '@/integrations/mixpanel/hooks/useMixpanel'
import {MixpanelEventNameE} from '@/integrations/mixpanel/types'
import {useRootStore} from '@/store'
import {
    selectBuyMorgisModal,
    selectConfirmSendGiftModal,
    selectCustomSendGiftModal,
    selectWalletModal
} from '@/store/selectors'
import {UserRoleE} from '@/types'
import {FC, RefObject, useRef} from 'react'
import {createPortal} from 'react-dom'
import {MoveMorgiIconAnimation} from '../../animations/move-morgi-icon-animation/MoveMorgiIconAnimation'
import {StyledAmount, StyledGift, StyledGiftContent, StyledGiftListWrapper, StyledWallet} from './style'
import {GuestType} from '@/features/authentication/types'
import {TypeOfConfirmModalE} from '../../confirm-send-gift-modal/store/createConfirmSendGiftModalSlice'

type GiftListProps = {
    hostID: string
    goalId?: string
    iconSize?: {width: number; height: number}
    withWallet?: boolean
    maxDonation?: number
    minDonation?: number
    typeOfConfirmSendModal?: TypeOfConfirmModalE
}
const transitionDuration: number = 6

export const GiftList: FC<GiftListProps> = ({
    hostID,
    goalId,
    withWallet = false,
    iconSize = {width: 29, height: 29},
    maxDonation,
    minDonation,
    typeOfConfirmSendModal = TypeOfConfirmModalE.giftList
}) => {
    const mixpanel = useMixPanel()
    const gemRef = useRef() as RefObject<HTMLSpanElement> | null
    const {openModal: openConfirmGiftModal, amount: amountStore, typeOfModal} = useRootStore(selectConfirmSendGiftModal)
    const {openModal: openCustomGiftModal} = useRootStore(selectCustomSendGiftModal)
    const {data} = useMe()
    const user = data as GuestType
    const {openModal: openBuyMorgisModal} = useRootStore(selectBuyMorgisModal)

    const triggerMixpanelEvent = () => {
        const eventData = {
            eventType: MixpanelEventNameE.GiftMicromorgisClick,
            userRole: UserRoleE.Guest,
            userId: hostID
        }
        mixpanel?.triggerBackendEvent({...eventData})
    }

    return (
        <StyledGiftListWrapper>
            {withWallet && <WalletButton />}
            {GIFTS_AMOUNTS_LIST.map(amount => {
                const disabledByMaxDonation = maxDonation ? maxDonation < amount : false
                const disabledByMinDonation = minDonation ? minDonation > amount : false
                const disabled = disabledByMaxDonation || disabledByMinDonation
                const isSelected = amountStore === amount
                return (
                    <StyledGift
                        key={amount}
                        aria-disabled={disabled}
                        onClick={() => {
                            if (user?.type_attributes?.micro_morgi_balance >= amount) {
                                !disabled &&
                                    openConfirmGiftModal({
                                        amount,
                                        hostID,
                                        goalId,
                                        typeOfModal: typeOfConfirmSendModal
                                    })
                                !disabled && triggerMixpanelEvent()
                            } else {
                                openBuyMorgisModal({})
                            }
                        }}
                    >
                        <StyledGiftContent>
                            <span ref={isSelected ? gemRef : null}>
                                <MicromorgiIcon height={iconSize.height} width={iconSize.width} />
                            </span>
                            <StyledAmount>{amount}</StyledAmount>

                            {isSelected &&
                                typeOfModal === TypeOfConfirmModalE.giftList &&
                                createPortal(
                                    <MoveMorgiIconAnimation
                                        transitionDuration={transitionDuration}
                                        iconSize={iconSize}
                                        gemRef={gemRef}
                                    />,
                                    document.body,
                                    `animation-${amount}`
                                )}
                        </StyledGiftContent>
                    </StyledGift>
                )
            })}
            <StyledGift
                onClick={() => {
                    openCustomGiftModal({
                        hostID,
                        maxDonation,
                        goalId,
                        minDonation
                    })
                }}
            >
                <StyledGiftContent>
                    <ArrowInSquareIcon />
                </StyledGiftContent>
            </StyledGift>
        </StyledGiftListWrapper>
    )
}

const WalletButton: FC = () => {
    const {data: user} = useMe()
    const {openModal: openWalletModal} = useRootStore(selectWalletModal)
    return (
        <StyledGift
            onClick={() => {
                openWalletModal({})
            }}
        >
            <StyledGiftContent>
                <WalletIcon />
                <StyledWallet>
                    {
                        (
                            user?.type_attributes as {
                                micro_morgi_balance: number
                            }
                        )?.micro_morgi_balance
                    }
                </StyledWallet>
            </StyledGiftContent>
        </StyledGift>
    )
}
