import {Spinner} from '@/components/ui/spinner/Spinner'
import {useGetFilterFavActivities} from '@/features/chat-fav-topics/queries/useGetFilterFavActivities'
import {useHandleError} from '@/hooks/useHandleError'
import {ActivitiesIcon} from '@/assets/icons/icons'
import {HostFiltersType} from '@/features/feed/types'
import {Dispatch, SetStateAction} from 'react'
import {TopicsList} from '../../topics-list/TopicsList'

type ActivitiesFilterProps = {
    activities: HostFiltersType['activities']
    setActivities: Dispatch<SetStateAction<HostFiltersType['activities']>>
}

export const ActivitiesFilter = ({activities, setActivities}: ActivitiesFilterProps) => {
    const {data: favActivities, isLoading, error, isError} = useGetFilterFavActivities()
    useHandleError({isError, error})

    return (
        <>
            {isLoading && <Spinner />}
            <TopicsList
                defaultTopicsList={activities}
                handleSelect={setActivities}
                icon={<ActivitiesIcon />}
                topicsList={favActivities || []}
            />
        </>
    )
}
