import {MicromorgiIcon} from '@/assets/icons/icons'
import {Modal} from '@/components/ui/modal/Modal'
import {Spinner} from '@/components/ui/spinner/Spinner'
import {useMe} from '@/features/authentication/queries/useMe'
import {GuestType} from '@/features/authentication/types'
import {useRootStore} from '@/store'
import {AnimationPlaces} from '@/store/createAnimationTriggersSlice'
import {selectAnimationsStore, selectTopFanPendingModal} from '@/store/selectors'
import {USER_ROLE_GUEST} from '@/utilities/constants/user'
import {FC} from 'react'
import {Trans, useTranslation} from 'react-i18next'
import {useNavigate} from 'react-router-dom'
import {useChangeBadgeVisibility} from '../queries/useChangeBadgeVisibility'
import {
    StyledAmount,
    StyledCheckbox,
    StyledContentContainer,
    StyledDescription,
    StyledSubmitButton,
    StyledText,
    StyledTopFanModalImage,
    StyledTopFanPendingModalContainer,
    StyledTopFanPerksDescription,
    StyledTopFanTitle
} from './style'

export const TopFanPendingModal: FC = () => {
    const {t} = useTranslation()
    const navigate = useNavigate()
    const {closeModal, channel} = useRootStore(selectTopFanPendingModal)
    const {data} = useMe()
    const user = data?.type === USER_ROLE_GUEST ? (data as GuestType) : null
    const {playAnimation} = useRootStore(selectAnimationsStore)
    const {mutate: changeBadgeVisibility, isPending, error} = useChangeBadgeVisibility()
    if (!channel) return <></>
    const isComplete = channel.top_fans?.status === 'completed'
    const amountToReach = channel.top_fans?.amount_to_reach ?? channel.top_fans_infos?.min_amount_to_reach
    const isTopFansBadgeVisible = !!user?.type_attributes?.is_top_fans_badge_visible
    const onRookieNameClick = () => {
        navigate(`/${channel.username}`)
        closeModal()
    }
    const onClickSubmit = () => {
        playAnimation({key: AnimationPlaces.GiftPanel, delay: 3000})
        closeModal()
    }
    return (
        <Modal fullScreen onClose={closeModal} maxWidth="500px">
            {isPending && <Spinner />}
            <StyledTopFanPendingModalContainer>
                <StyledContentContainer>
                    <StyledTopFanModalImage />
                    <StyledTopFanTitle>{t('top_fan_modal:first_title')}</StyledTopFanTitle>
                    <StyledAmount>
                        <MicromorgiIcon height={13} width={13} />
                        {amountToReach}
                    </StyledAmount>
                    <StyledDescription>{t('top_fan_modal:description')}</StyledDescription>
                    <StyledText>
                        <Trans
                            t={t}
                            i18nKey="top_fan_modal:first_text"
                            values={{
                                ROOKIE_NAME: channel.full_name
                            }}
                            components={{
                                span1: <span onClick={onRookieNameClick} />
                            }}
                        />
                    </StyledText>
                    <StyledTopFanTitle> {t('top_fan_modal:second_title')}</StyledTopFanTitle>
                    <StyledTopFanPerksDescription>
                        {t('top_fan_modal:second_text', {HOST_NAME: channel.full_name})}
                    </StyledTopFanPerksDescription>
                    {isComplete && (
                        <StyledCheckbox
                            id="check"
                            name=""
                            label={t('top_fan_modal:checkbox')}
                            defaultChecked={!isTopFansBadgeVisible}
                            onClick={() => {
                                changeBadgeVisibility(!isTopFansBadgeVisible)
                            }}
                            errorMessage={error?.response?.data.message}
                        />
                    )}
                </StyledContentContainer>
                <StyledSubmitButton onClick={onClickSubmit}>
                    <>{t('top_fan_modal:got_it')}</>
                </StyledSubmitButton>
            </StyledTopFanPendingModalContainer>
        </Modal>
    )
}
