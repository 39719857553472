import {GoalStatusesE, GoalType} from '@/features/goal/types'
import {StyledExplanationRoot} from './style'
import {useMemo} from 'react'
import {useTranslation} from 'react-i18next'
import {calculatePercentage} from '@/utilities/helpers'
import {MIN_SUCCESS_PERCENTAGE} from '@/features/goal/constant/goal'

export const HostStatusExplanationSection = ({goal}: {goal?: GoalType}) => {
    const {t} = useTranslation()
    const percentage = goal && calculatePercentage(parseInt(`${goal?.donations_count}`), parseInt(goal?.target_amount))

    const text = useMemo(() => {
        switch (goal?.status) {
            case GoalStatusesE.ACTIVE:
                if (percentage && percentage >= MIN_SUCCESS_PERCENTAGE) {
                    // active and ready to withdraw
                    return t('goal_page:notification:goal_achived_time_not_ended', {PERCENTAGE: Math.round(percentage)})
                } else {
                    // active
                    return t('goal_page:notification:goal_not_achieved_time_not_ended')
                }
            case GoalStatusesE.AWAITING_PROOF:
            case GoalStatusesE.PROOF_PENDING_APPROVAL:
                return t('goal_page:notification:goal_achieved_or_widthdraw')
            case GoalStatusesE.SUCCESSFUL:
                return t('goal_page:notification:goal_achieved_time_ended')
            case GoalStatusesE.CANCELED:
                return
            default:
                // status: pending, suspended, review
                return t('goal_page:notification:goal_not_achieved_time_not_ended')
        }
    }, [goal?.status, percentage])

    return <StyledExplanationRoot>{text}</StyledExplanationRoot>
}
