import {QUERY_KEYS} from '@/features/goal/queries/keys.ts'
import {GOAL_API} from '@/features/goal/services/goal.http.ts'
import {useQuery} from '@tanstack/react-query'
import {GoalsPathsRequest} from '../types'

export const useGoalsPaths = (params: GoalsPathsRequest) => {
    return useQuery({
        queryKey: [QUERY_KEYS.goals_path, params],
        queryFn: () => GOAL_API.getGoalsPaths(params)
    })
}
