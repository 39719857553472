import {Lens} from '@dhmk/zustand-lens'
import {SendGiftStatusesE} from '../../confirm-send-gift-modal/store/createConfirmSendGiftModalSlice'

type CustomSendGiftModalState = {
    isOpen: boolean
    hostID: string | null
    maxDonation?: number
    minDonation?: number
    goalId?: string | null
    status?: string | null
}

type CustomSendGiftModalActions = {
    openModal: (params: Omit<CustomSendGiftModalState, 'isOpen'>) => void
    closeModal: () => void
    setStatus: (status?: SendGiftStatusesE | null) => void
}

export type CustomSendGiftModal = CustomSendGiftModalState & CustomSendGiftModalActions

const initialModal: CustomSendGiftModalState = {isOpen: false, hostID: null, goalId: null}

export const createCustomSendGiftModalSlice: Lens<CustomSendGiftModal> = set => ({
    ...initialModal,
    setStatus: status => set(() => ({status})),
    openModal: params => set(() => ({...params, isOpen: true})),
    closeModal: () => set(() => ({...initialModal}))
})

export default createCustomSendGiftModalSlice
