import {GuestType} from '@/features/authentication/types.ts'
import {FC, useEffect, useState} from 'react'
import {FavoriteActivitiesFilterCard} from '@/features/feed/components/feed-card/filter-card/favorite-activities-filter-card/FavoriteActivitiesFilterCard.tsx'
import {ChatTopicsFilterCard} from '@/features/feed/components/feed-card/filter-card/chat-topic-filter-card/ChatTopicsFilterCard.tsx'
import {Topic} from '@/features/chat-fav-topics/types.ts'
import {useTranslation} from 'react-i18next'
import {feedCardVariants} from '@/features/feed/components/feed-card/FeedCard.tsx'
import {StyledApplyButton, StyledBannerText, StyledFilterBanner, StyledTopicsWrapper} from './style'
import {useGetActiveHost} from '@/features/feed/queries/useGetActiveHost'
import {useStoreActiveFilters} from '@/features/feed/queries/useStoreActiveFilters'
import {useGetMinMaxAge} from '@/features/guest/queries/useGetMinMaxAge'
import {MAX_AGE_TO_SELECT, MIN_AGE_TO_SELECT} from '@/features/guest/constants/hostFilters'
import {LocalStorageManager} from '@/utilities/localStorage'
import useMixPanel from '@/integrations/mixpanel/hooks/useMixpanel'
import {MixpanelEventNameE} from '@/integrations/mixpanel/types'

type FilterCardProps = {
    user: GuestType
    scrollToFeedStart: () => void
}
// This card can be either a ChatTopicFilterCard or a FavoriteActivitiesFilterCard
// this is discriminated by cardType
export const FilterCard: FC<FilterCardProps> = ({user, scrollToFeedStart}) => {
    const {t} = useTranslation()
    const mixpanel = useMixPanel()
    const [selectedItems, setSelectedItems] = useState<Topic[]>([])
    const [filterCardType] = LocalStorageManager.checkFeedFilterCardType.get() || [feedCardVariants.chatTopics]
    const isActivitiesFilterCard = filterCardType === feedCardVariants.favActivities
    const currentFilters = user.type_attributes.leaders_carousels_filters?.filters || {activities: [], chatTopics: []}
    const {data: hostCount} = useGetActiveHost()
    const {data: ageRange} = useGetMinMaxAge()
    const {mutate} = useStoreActiveFilters()

    const onItemClickHandler = (selectedItem: Topic) => {
        if (selectedItems && !selectedItems?.find(topic => topic?.id === selectedItem?.id)) {
            setSelectedItems([...selectedItems, selectedItem])
        } else {
            setSelectedItems(selectedItems?.filter(topic => topic?.id !== selectedItem.id))
        }
    }

    useEffect(() => {
        // set default selection
        if (user) {
            setSelectedItems(isActivitiesFilterCard ? currentFilters?.activities : currentFilters?.chatTopics)
        }
    }, [user])

    const onApplyHandler = () => {
        const filtersToApply = {
            activities: [],
            chatTopics: [],
            countries: [],
            languages: [],
            genders: [],
            specialHostCategory: [],
            fromAge: ageRange?.min_age || MIN_AGE_TO_SELECT,
            toAge: ageRange?.max_age || MAX_AGE_TO_SELECT,
            ...(filterCardType === feedCardVariants.chatTopics && {chatTopics: selectedItems}),
            ...(filterCardType === feedCardVariants.favActivities && {activities: selectedItems})
        }

        mutate(filtersToApply)
        setSelectedItems([])
        scrollToFeedStart()

        mixpanel?.trackEvent(MixpanelEventNameE.FilterApplied, {
            chat_topics: filtersToApply.chatTopics,
            activities: filtersToApply.activities,
            countries: filtersToApply.countries,
            languages: filtersToApply.languages,
            gender: filtersToApply.genders,
            special_host_category: filtersToApply.specialHostCategory,
            age_range: [filtersToApply.fromAge, filtersToApply.toAge]
        })
    }

    return (
        <StyledFilterBanner>
            <StyledBannerText>
                <h4>{t('interactive_feed:filtering_card:title', {ROOKIES_COUNT: hostCount?.rookies_count})}</h4>
                {isActivitiesFilterCard ? (
                    <p>{t('interactive_feed:filtering_card:sub_title_favActivities')}</p>
                ) : (
                    <p>{t('interactive_feed:filtering_card:sub_title_favChatTopic')}</p>
                )}
            </StyledBannerText>
            <StyledTopicsWrapper>
                {isActivitiesFilterCard ? (
                    <FavoriteActivitiesFilterCard onSelectItem={onItemClickHandler} selectedItems={selectedItems} />
                ) : (
                    <ChatTopicsFilterCard onSelectItem={onItemClickHandler} selectedItems={selectedItems} />
                )}
            </StyledTopicsWrapper>
            <StyledApplyButton onClick={onApplyHandler} disabled={!selectedItems.length} variant="outlined">
                {t('chat_topics_filter:apply_button')}
            </StyledApplyButton>
        </StyledFilterBanner>
    )
}
