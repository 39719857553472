import styled, {css} from 'styled-components'

export const StyledGoalUploaderRoot = styled.div`
    ${({theme: {spacing, mediaQueries}}) => css`
        width: 100%;
        margin-bottom: ${spacing}px;

        ${mediaQueries.m} {
            margin-bottom: ${spacing * 3}px;
        }
    `}
`

export const StyledWrapper = styled.div`
    ${({theme: {spacing}}) => css`
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: ${spacing * 3}px;
    `}
`

export const StyledUploadMore = styled.div`
    ${({theme: {palette, spacing}}) => css`
        color: ${palette.neutral['700']};
        cursor: pointer;
        border-bottom: 1px solid ${palette.neutral['700']};
        width: fit-content;
        margin: ${spacing * 2}px auto ${spacing}px;
    `}
`

export const StyledError = styled.div`
    ${({theme: {spacing}}) => css`
        display: flex;
        justify-content: flex-start;
        width: 100%;
        max-width: 400px;
        margin: ${spacing / 2}px auto;
    `}
`

export const dropzoneStyles = () => css`
    width: 100%;
`
