import {Button} from '@/components/ui/button/Button'
import styled from 'styled-components'
import TopConfettiBackground from '@assets/images/TopConfettiBackground.png'

export const StyledSuccessMegaGiftOverlayContainer = styled.div`
    position: fixed;
    height: 100%;
    width: 100%;
    z-index: 1033;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-image: url(${TopConfettiBackground});
    background-repeat: repeat-x;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    &:after {
        content: ' ';
        background-image: url(/src/assets/images/TopConfettiBackground.png);
        background-repeat: repeat-x;
        position: absolute;
        bottom: 0;
        height: 50%;
        width: 100%;
        transform: rotate(180deg);
        margin-bottom: -25px;
        pointer-events: none;
        z-index: 0;
    }
`
export const StyledSuccessMegaGiftOverlayImage = styled.img`
    max-width: 300px;
    width: 100%;
    height: 300px;
    margin-top: -50px;
`
export const StyledContinueButton = styled(Button)`
    width: 315px;
    margin-top: 24px;
    z-index: 1;
`
export const StyledSuccessMegaGiftOverlayDescription = styled.div`
    color: rgb(255, 255, 255);
    font-size: 24px;
    font-style: normal;
    margin-top: -30px;
    text-align: center;
    font-weight: 700;
    line-height: 24px;
    & span {
        background: linear-gradient(180deg, #ffda48 0%, #fc9f21 100%);
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
    }
`
