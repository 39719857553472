import {GoalStatusesE, GoalType} from '@/features/goal/types'
import {useMemo} from 'react'
import {useTranslation} from 'react-i18next'
import {ProofsMedia} from '../proofs-media/ProofsMedia'
import {guestProofData} from '@/features/goal/constant/goalProofs'
import {StyledProofNote, StyledProofSectionRoot, StyledText, StyledTitle} from '../host-proof-section/style'

export type ProofSectionType = {
    goal?: GoalType
    translatedProofNote?: JSX.Element
}

export const GuestProofSection = ({goal, translatedProofNote}: ProofSectionType) => {
    const {t} = useTranslation()
    const leaderDonationsAmount = goal && +goal?.leader_donations_amount
    const showProofNoteAndMedia = goal?.status === GoalStatusesE.SUCCESSFUL
    const status = goal?.status
    const proofsPending =
        status === GoalStatusesE.AWAITING_PROOF ||
        status === GoalStatusesE.PROOF_PENDING_APPROVAL ||
        status === GoalStatusesE.PROOF_STATUS_DECLINED
    const hasImageProof = goal?.has_image_proof
    const hasVideoProof = goal?.has_video_proof

    const proofData = useMemo(() => {
        switch (true) {
            case GoalStatusesE.ACTIVE === status && !!leaderDonationsAmount:
                return guestProofData[GoalStatusesE.ACTIVE]
            case proofsPending && hasImageProof && hasVideoProof:
                return guestProofData[GoalStatusesE.AWAITING_PROOF].both
            case proofsPending && hasImageProof:
                return guestProofData[GoalStatusesE.AWAITING_PROOF].image
            case proofsPending && hasVideoProof:
                return guestProofData[GoalStatusesE.AWAITING_PROOF].video
            case GoalStatusesE.SUCCESSFUL === status:
                return guestProofData[GoalStatusesE.SUCCESSFUL]
            default:
                // status - canceled, suspended, review
                return null
        }
    }, [goal?.status, leaderDonationsAmount])

    return proofData ? (
        <StyledProofSectionRoot backgroundColor={proofData?.backgroundColor}>
            <StyledTitle>
                {proofData?.icon}
                {t(proofData?.title)}
            </StyledTitle>
            {proofData?.text && (
                <StyledText>
                    {t(proofData?.text, {
                        user_first_name: goal?.rookie?.full_name
                    })}
                </StyledText>
            )}
            {showProofNoteAndMedia && (
                <>
                    <StyledProofNote>{translatedProofNote}</StyledProofNote>
                    <ProofsMedia proofs={goal?.proofs || []} />
                </>
            )}
        </StyledProofSectionRoot>
    ) : null
}
