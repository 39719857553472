import styled, {css} from 'styled-components'

export const StyledLabelContainer = styled.span`
    ${({theme: {palette, typography, spacing}}) => css`
        color: ${palette.neutral.white};
        display: flex;
        padding: ${spacing / 2}px;
        font-size: ${typography.textXxs};
        align-items: center;
        gap: ${spacing / 2}px;
        justify-content: center;
        border-radius: ${spacing / 2}px;
        background-color: ${palette.neutral['500']};
    `}
`
export const StyledLabelButton = styled.div`
    width: 10px;
    height: 10px;
    display: flex;
    cursor: pointer;
`
export const StyledLabelItem = styled.div`
    ${({theme: {flexUtils}}) => css`
        ${flexUtils.centered}
    `}
`
