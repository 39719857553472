import styled, {css} from 'styled-components'

export const StyledBannerRoot = styled.section`
    ${({theme: {spacing, palette, typography}}) => css`
        color: ${palette.success['600']};
        padding: ${spacing * 2}px ${spacing * 2}px ${spacing}px;
        position: relative;
        border-radius: 4px;
        background-color: ${palette.success['25']};
        ${typography.textXl};

        & h4 {
            font-weight: 600;
        }

        & p {
            ${typography.textSm}
        }
    `}
`

export const StyledCountdown = styled.div`
    ${({theme: {spacing, palette, typography}}) => css`
        top: -12px;
        left: 17px;
        display: flex;
        padding: ${spacing / 2}px;
        position: absolute;
        border-radius: 4px;
        background-color: ${palette.success['600']};
        color: ${palette.success['25']};
        gap: ${spacing / 2}px;

        & div {
            color: ${palette.success['25']};
            ${typography.textSm}
        }
    `}
`

export const StyledFor = styled.div`
    text-transform: capitalize;
`
