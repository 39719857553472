import styled, {DefaultTheme, FlattenSimpleInterpolation, css} from 'styled-components'
import {Splide, SplideSlide, SplideTrack} from '@splidejs/react-splide'

export const StyledSplide = styled(Splide)`
    ${({
        $carouselStyles,
        theme
    }: {
        $carouselStyles?: (theme: DefaultTheme) => FlattenSimpleInterpolation
        theme: DefaultTheme
    }) => {
        const {mediaQueries} = theme
        return css`
            position: relative;
            height: 100%;
            & .splide__arrow {
                display: none;
                ${mediaQueries.m} {
                    display: block;
                }
            }

            ${$carouselStyles && $carouselStyles(theme)}
        `
    }}
`

export const StyledSplideSlide = styled(SplideSlide)(
    ({
        theme,
        $slideStyles
    }: {
        $slideStyles?: (theme: DefaultTheme) => FlattenSimpleInterpolation
        theme: DefaultTheme
    }) => css`
        background-color: transparent;
        ${theme.flexUtils.centered};
        & > * {
            max-height: 100vh;
        }
        & > canvas {
            width: 100%;
            height: 100%;
        }
        ${$slideStyles && $slideStyles(theme)};
    `
)

export const StyledSplideTrack = styled(SplideTrack)`
    height: 100%;
`

//to style arrows we can use structure:
// export const styledArrows = (theme: DefaultTheme) => css`
//      styles for arrow wrapper
//     & button {
//          styles for arrows button
//       & /*children tags*/ {
//            styles for passed arrows
//         }
//     }
// `
export const StyledArrowsWrapper = styled.div`
    ${({
        arrowsStyles,
        theme
    }: {
        arrowsStyles?: (theme: DefaultTheme) => FlattenSimpleInterpolation
        theme: DefaultTheme
    }) => arrowsStyles && arrowsStyles(theme)};
`
export const StyledOverlay = styled.div`
    ${({theme: {palette}}) => css`
        background: ${palette.gradient[850]};
        width: 100%;
        height: 100%;
        position: absolute;
    `}
`

export const StyledImage = styled.div`
    ${({url}: {url: string}) => css`
        background-image: ${`url(${url})`};
        background-repeat: no-repeat;
        background-size: cover;
        background-position: 50% 50%;
        width: 100%;
        height: 100%;
    `}
`
export const StyledBlockedSection = styled.div`
    ${({theme: {palette, spacing, typography}}) => css`
        position: absolute;
        display: flex;
        flex-direction: column;
        gap: ${spacing * 3.75}px;
        max-width: 320px;
        overflow: auto;
        z-index: 2;
        & > span {
            color: ${palette.neutral.white};
            font-weight: 700;
            ${typography.text2Xl}
            text-shadow: 0px 0px 2.1px rgba(0, 0, 0, 0.3)
        }
        & > button {
            height: unset;
            &::-webkit-scrollbar {
                display: none;
            }
            margin: 0 auto;
            width: 100%;
        }
    `}
`
export const StyledFeedBlockedSlide = styled.div`
    ${({theme: {palette, spacing, typography}}) => css`
        position: absolute;
        display: flex;
        flex-direction: column;
        gap: ${spacing}px;
        max-width: 90%;
        height: 100%;
        justify-content: space-evenly;
        align-items: center;
        & > span {
            color: ${palette.neutral.white};
            font-weight: 700;
            ${typography.textXs}
        }
        & > button {
            display: flex;
            align-items: center;
            justify-content: center;
            height: 12px;
            ${typography.textXxs}
            padding: ${spacing * 1.25}px ${spacing / 2}px;
            &::-webkit-scrollbar {
                display: none;
            }
            margin: 0 auto;
        }
    `}
`

export const StyledCanvas = styled.canvas`
    filter: blur(10px);
`
