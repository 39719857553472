import {BackIcon} from '@/assets/icons/icons'
import {useNavigate} from 'react-router-dom'
import {StyledBackContainer} from './style'

export type BackProps = {
    text?: string
    icon?: JSX.Element
    callback?: () => void
    isWhite?: boolean
}

const Back = ({callback, text, icon = <BackIcon />, isWhite = false}: BackProps) => {
    const navigate = useNavigate()
    return callback ? (
        <StyledBackContainer isWhite={isWhite} text={!!text} onClick={callback}>
            {icon}
            {text && <span>{text}</span>}
        </StyledBackContainer>
    ) : (
        <StyledBackContainer isWhite={isWhite} text={!!text} onClick={() => navigate(-1)}>
            {icon}
            {text && <span>{text}</span>}
        </StyledBackContainer>
    )
}

export default Back
