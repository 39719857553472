import {TextareaWithBannedElementsTrap} from '@/features/generated-content-security/components/text-area-with-word-trap/TextareaWithBannedElementsTrap'
import styled, {DefaultTheme, css} from 'styled-components'

export const StyledBody = styled.div`
    width: 100%;
    height: 100%;
    overflow-y: hidden;
    min-height: 417px;
`

export const StyledSubtitle = styled.div`
    ${({theme: {spacing, typography, palette}}) => css`
        ${typography.textMd};
        line-height: 20px;
        text-align: center;
        color: ${palette.neutral.black};
        margin-bottom: ${spacing * 2.5}px;
    `}
`

export const StyledContent = styled.div`
    ${({theme: {palette}, isActive}: {theme: DefaultTheme; isActive: boolean}) => css`
        ${isActive &&
        css`
            background-color: ${palette.primary['300']};
        `}
    `}
`

export const StyledGoalCardWrapper = styled.div`
    ${({theme: {spacing}}) => css`
        padding: ${spacing * 2}px ${spacing * 2}px 0px;
    `}
`
export const StyledTextAreaWrapper = styled.div`
    ${({theme: {spacing}}) => css`
        padding: 0px ${spacing * 2}px ${spacing * 2}px;
    `}
`

export const StyledTextArea = styled(TextareaWithBannedElementsTrap)`
    ${({theme: {palette, spacing}}) => css`
        margin-bottom: ${spacing * 2}px;
        & > div {
            min-width: 200px;
            min-height: 108px;
            max-height: 108px;
            background-color: ${palette.neutral.white};
            overflow: scroll;
            &::-webkit-scrollbar {
                background: ${palette.neutral.transparent};
                width: 5px;
                border-radius: 3px;
            }
            &::-webkit-scrollbar-thumb {
                background: ${palette.neutral[400]};
            }
            &::-webkit-scrollbar-track {
                margin-bottom: ${spacing}px;
            }
            &::-webkit-scrollbar-button:start {
                display: none;
            }
            &::-webkit-scrollbar-button:end {
                display: none;
            }
        }
    `}
`

export const StyledButton = styled.div`
    ${({theme: {typography, spacing}}) => css`
        width: 100%;
        display: flex;
        justify-content: flex-end;

        & button {
            ${typography.textSm};
            min-height: 100%;
            padding: ${spacing / 2}px ${spacing * 1.5}px;
            width: auto;
        }
    `}
`

export const modalStyles = (theme: DefaultTheme) => css`
    min-height: 506px;

    & h4 {
        ${theme.typography.textMd};
        color: ${theme.palette.neutral.black};
    }
`
