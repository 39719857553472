import {useMutation, useQueryClient} from '@tanstack/react-query'
import {MUTATION_KEYS, QUERY_KEYS} from './keys'
import {GUEST_API} from '../services/guest.http'

export const useSaveHost = () => {
    const queryClient = useQueryClient()
    return useMutation({
        mutationKey: [MUTATION_KEYS.save_host],
        mutationFn: (hostId: number) => GUEST_API.saveHost({hostId}),
        onSuccess: () => {
            queryClient.invalidateQueries({queryKey: [QUERY_KEYS.saved_hosts]})
        }
    })
}
