import styled, {FlattenSimpleInterpolation, css} from 'styled-components'

type StyledBaseContentProps = {
    rootClassName?: () => FlattenSimpleInterpolation
    bodyClassName?: () => FlattenSimpleInterpolation
    disabled?: boolean
}

export const StyledCardBox = styled.div<StyledBaseContentProps>`
    ${({disabled, rootClassName, theme: {palette, shadows}}) => css`
        box-shadow: ${shadows.xl};
        background: ${palette.neutral.white};
        border-radius: 16px;
        vertical-align: middle;
        opacity: ${disabled ? 0.5 : 1};
        cursor: ${disabled ? 'not-allowed' : 'pointer'};
        align-items: center;
        ${rootClassName}
    `}
`
export const StyledBaseContent = styled.div<StyledBaseContentProps>`
    ${({bodyClassName, theme: {spacing, mediaQueries}}) => css`
        vertical-align: middle;
        padding: ${spacing * 1.25}px;

        ${mediaQueries.m} {
            width: 100%;
        }
        ${bodyClassName}
    `}
`
