import {Animation} from '@/components/ui/animation/Animation'
import {Modal} from '@/components/ui/modal/Modal'
import {Line} from '@/features/gift/custom-send-gift-modal/style'
import {useRootStore} from '@/store'
import {selectErrorModal} from '@/store/selectors'
import Alert from '@assets/animations/Alert-1.json'
import {FC} from 'react'
import {useTranslation} from 'react-i18next'
import {StyledAnimation, StyledErrorDescription, StyledErrorModalContainer, StyledOkButton} from './style'

export const ErrorModal: FC = () => {
    const {t} = useTranslation()
    const {message: errorMessage, closeErrorModal} = useRootStore(selectErrorModal)

    return (
        <Modal title={t('error_modal:generic:title')} onClose={closeErrorModal}>
            <StyledErrorModalContainer>
                <StyledErrorDescription>{t(errorMessage || 'error_modal:generic:description')}</StyledErrorDescription>
                <StyledAnimation>
                    <Animation animationData={Alert} />
                </StyledAnimation>
                <Line />
                <StyledOkButton onClick={closeErrorModal}>{t('error_modal:generic:cta')}</StyledOkButton>
            </StyledErrorModalContainer>
        </Modal>
    )
}
