import {RouterProvider} from 'react-router-dom'
import {StyleSheetManager, ThemeProvider} from 'styled-components'
import {getAppTheme, GlobalStyles} from '@/theme/theme'
import router from './router'
import {Toaster} from 'react-hot-toast'
import AddMorgiToHomeScreenBanner from './components/commons/add-to-home-screen-banner/AddMorgiToHomeScreenBanner'
import {useMe} from './features/authentication/queries/useMe'
import {useEffect} from 'react'
import MixPanelProvider from './integrations/mixpanel/components/mix-panel-provider/MixPanelProvider'
import useIubenda from './hooks/useIubenda'
import {useInitNotifications} from './integrations/pusher/useInitNotification'
import usePusher from './integrations/pusher/hooks/usePusher'
import MoEngageSDKProvider from './integrations/moengage/MoengageSdkProvider'
import moengage from '@moengage/web-sdk'
import {envVars} from './envVars'
import {useTranslation} from 'react-i18next'
import {DirectionsE} from './types'
import rtlPlugin from 'stylis-plugin-rtl'
import {MoengageEvent} from './integrations/moengage/types'

export default function App() {
    const themeName = 'light' // get it from the app reducer
    const {i18n} = useTranslation()
    const direction = i18n.dir() as DirectionsE

    const {isMixpanelActive} = useIubenda()
    const {data: user} = useMe()

    // to testing pusher need to remove <React.StrictMode>
    const pusher = usePusher()
    const initNotifications = useInitNotifications()

    useEffect(() => {
        if (user?.id) initNotifications()
    }, [user])

    useEffect(() => {
        return () => {
            pusher?.disconnect()
        }
    }, [])

    useEffect(() => {

        moengage.initialize({
            app_id: envVars.VITE_APP_MOENGAGE_APP_ID,
            debug_logs: 0,
            cluster: envVars.VITE_APP_MOENGAGE_APP_CLUSTER || 'DC_2'
        })

        const moengageEventAction = (event: MoengageEvent) => {
            if (event?.detail?.name === 'MOE_ONSITE_MESSAGE_SHOWN') {
                console.log('MoEngage MOE_ONSITE_MESSAGE_SHOWN event:', event)
                if (event.detail?.data && event.detail?.data?.length) {
                    // do some processing on event.detail.data
                }
            }
        }

        window.addEventListener('MOE_AUTOMATED_EVENTS', moengageEventAction)

        return () => {
            window.removeEventListener('MOE_AUTOMATED_EVENTS', moengageEventAction)
        }
    }, [])

    return (
        <MoEngageSDKProvider MoEngage={moengage}>
            <MixPanelProvider isActive={isMixpanelActive}>
                <ThemeProvider theme={getAppTheme({name: themeName, direction})}>
                    <StyleSheetManager stylisPlugins={direction === DirectionsE.RTL ? [rtlPlugin] : undefined}>
                        <>
                            <Toaster />
                            <GlobalStyles />
                            <RouterProvider router={router} />
                            <AddMorgiToHomeScreenBanner />
                        </>
                    </StyleSheetManager>
                </ThemeProvider>
            </MixPanelProvider>
        </MoEngageSDKProvider>
    )
}
