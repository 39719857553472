import styled, {css} from 'styled-components'

export const StyledBanner = styled.div`
    ${({theme: {spacing, mediaQueries}}) => css`
        background: radial-gradient(154.24% 190.32% at 29.05% 141.96%, #f7f7fe 0%, #abacff 100%);
        box-shadow: 0px 2px 18px rgba(0, 6, 61, 0.15);
        border-radius: ${spacing}px;
        padding: 20px ${spacing * 3}px;
        min-height: 156px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0 auto;

        ${mediaQueries.m} {
            min-height: 248px;
            padding: ${spacing * 4}px ${spacing * 5}px;
        }
    `}
`

export const StyledBannerText = styled.div`
    ${({theme: {palette, mediaQueries, typography}}) => css`
        text-align: center;
        font-weight: 700;
        line-height: 29px;
        color: ${palette.primary};
        ${typography.text2Xl};

        & > div {
            background: linear-gradient(to top, #8c6ee0 0%, #b39aff 100%);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
        }

        ${mediaQueries.m} {
            ${typography.displayMd}
            line-height: 45px;
        }
    `}
`
