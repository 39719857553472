import {Button} from '@components/ui/button/Button.tsx'
import {DPDayRange} from '@rehookify/datepicker'
import styled, {DefaultTheme, FlattenInterpolation, ThemeProps, css} from 'styled-components'

export const StyledContainer = styled.div`
    display: inline-block;
`

export const CalendarsWrapper = styled.div`
    ${({theme: {spacing, mediaQueries}}) => css`
        display: flex;
        flex-direction: row;

        ${mediaQueries.m} {
            flex-direction: column;
            gap: ${spacing * 2}px;
        }
    `}
`

export const CalendarWrapper = styled.div`
    ${({theme: {spacing, palette, mediaQueries}}) => css`
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: ${spacing}px;
        padding: ${spacing * 1.5}px;

        &:not(:last-child) {
            border-right: 1px solid ${palette.neutral['200']};
            ${mediaQueries.m} {
                border-right: unset;
            }
        }
    `}
`
export const StyledCalendarHeader = styled.div`
    ${({theme: {palette, spacing}}) => css`
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        gap: ${spacing}px;

        .clickableText {
            cursor: pointer;
        }

        .clickableText:hover {
            color: ${palette.primary['600']};
        }

        .dayButton {
            width: 40px;
        }

        .yearButton {
            width: 90px;
        }
        .prevButton {
            left: 0;
            position: absolute;
            display: grid;
            place-items: center;
            cursor: pointer;
        }

        .nextButton {
            right: 0;
            position: absolute;
            display: grid;
            place-items: center;
            cursor: pointer;
        }
    `}
`

export const StyledDayGrid = styled.div`
    ${({theme: {spacing}}) => css`
        display: grid;
        grid-template-columns: repeat(7, 40px);
        row-gap: ${spacing / 2}px;
    `}
`

export const StyledYearMonthGrid = styled.div`
    ${({theme: {spacing}}) => css`
        display: grid;
        grid-template-columns: repeat(3, 80px);
        row-gap: ${spacing * 5}px;
        column-gap: ${spacing * 5}px;
    `}
`

export const StyledWeekDayContainer = styled.div`
    ${({theme: {typography, palette}}) => css`
        ${typography.textSm};
        display: table-cell;
        padding: 0.5em;
        text-align: center;
        color: ${palette.success[800]};
        text-align: center;
        font-weight: 700;
        line-height: 1.8;
    `}
`

type DayButtonWrapperProps = {
    rangeStatus: DPDayRange
    inCurrentMonth?: boolean
    datePickerRangeStyles?: FlattenInterpolation<ThemeProps<DefaultTheme>>
}

const styleByRangeStatus = ({datePickerRangeStyles, rangeStatus}: DayButtonWrapperProps) => {
    switch (rangeStatus) {
        case 'in-range':
            return css`
                ${({theme: {palette}}) => css`
                    background-color: ${palette.primary['200']};
                    border-radius: 0;
                    ${datePickerRangeStyles}
                `}
            `
        case 'range-start':
            return css`
                ${({theme: {palette}}) => css`
                    background-color: ${palette.primary['200']};
                    border-top-right-radius: 0;
                    border-bottom-right-radius: 0;
                `}
            `
        case 'range-end':
            return css`
                ${({theme: {palette}}) => css`
                    background-color: ${palette.primary['200']};
                    border-top-left-radius: 0;
                    border-bottom-left-radius: 0;
                `}
            `
        case 'will-be-in-range':
            return css`
                ${({theme: {palette}}) => css`
                    background-color: ${palette.primary['100']};
                    border-radius: 0;
                `}
            `
        case 'will-be-range-start':
            return css`
                ${({theme: {palette}}) => css`
                    background-color: ${palette.primary['100']};
                    border-top-right-radius: 0;
                    border-bottom-right-radius: 0;
                `}
            `
        case 'will-be-range-end':
            return css`
                ${({theme: {palette}}) => css`
                    background-color: ${palette.primary['100']};
                    border-top-left-radius: 0;
                    border-bottom-left-radius: 0;
                `}
            `
        default:
            return css``
    }
}

export const DayButtonWrapper = styled.div<DayButtonWrapperProps>`
    ${({datePickerRangeStyles, rangeStatus, inCurrentMonth}) => css`
        display: flex;
        border-radius: 100%;

        &:nth-child(7n),
        &.lastMonthDay {
            border-top-right-radius: 100%;
            border-bottom-right-radius: 100%;
        }

        &:nth-child(7n + 1),
        &.firstMonthDay {
            border-top-left-radius: 100%;
            border-bottom-left-radius: 100%;
        }

        ${inCurrentMonth &&
        css`
            ${styleByRangeStatus({datePickerRangeStyles, rangeStatus})}
        `}
    `}
`

export const StyledDayButton = styled(Button)`
    ${({theme: {palette}}) => css`
        &:disabled {
            color: ${palette.neutral[150]};
        }
        display: grid;
        padding: 0;
        width: 40px !important;
        height: 40px;
        border-radius: 100%;
        place-items: center;
        font-weight: 700;
    `}
`

export const StyledMonthYearButton = styled(Button)`
    ${({theme: {spacing}}) => css`
        cursor: pointer;
        padding: ${spacing * 2}px;
        text-align: center;
    `}
`

export const StyledBackButton = styled(Button)`
    ${({theme: {palette}}) => css`
        color: ${palette.primary['600']};
        margin-left: auto;
        margin-top: auto;
    `}
`

export const StyledCalendarHeaderControlButtons = styled.div`
    display: flex;
`
