import {Spinner} from '@/components/ui/spinner/Spinner'
import {useGetGenders} from '@/features/authentication/queries/useGetGenders'
import {SingleGenderType} from '@/features/authentication/types'
import {StyledGenderButton, StyledGendersWrapper} from './style'
import {StyledTabTitle} from '../style'
import {useTranslation} from 'react-i18next'
import {useHandleError} from '@/hooks/useHandleError'
import {HostFiltersType} from '@/features/feed/types'
import {Dispatch, SetStateAction, useEffect} from 'react'
import {GENDER_ALL_OPTION_KEY_NAME} from '@/features/guest/constants/hostFilters'
import i18n from '@/translations/i18n'

type GenderSectionProps = {
    genders: HostFiltersType['genders']
    setGenders: Dispatch<SetStateAction<HostFiltersType['genders']>>
}

export const GenderSection = ({genders, setGenders}: GenderSectionProps) => {
    const {t} = useTranslation()
    const {data, isLoading, isError, error} = useGetGenders({isLeader: true, appLang: i18n.language})
    useHandleError({isError, error})

    const allOption = data?.find(item => item.key_name === GENDER_ALL_OPTION_KEY_NAME)

    useEffect(() => {
        !genders.length && allOption && setGenders([allOption])
    }, [genders, data])

    const onSelectGender = (gender: SingleGenderType, isSelected: boolean) => {
        if (!data || !allOption) return

        switch (true) {
            case gender.key_name === GENDER_ALL_OPTION_KEY_NAME:
            case genders.length === data.length - 2 && !isSelected:
                // Set "All" tab if other 3 tabs are selected, or user clicks on "All" tab
                return setGenders([allOption])
            case isSelected:
                // Remove gender
                return setGenders(prevState => prevState.filter(item => item.id !== gender.id))
            default:
                // Add gender (remove the All tab if selected and add the selected new one)
                setGenders(prevState => {
                    return [...prevState.filter(item => item.key_name !== GENDER_ALL_OPTION_KEY_NAME), gender]
                })
        }
    }

    return (
        <div>
            {isLoading && <Spinner />}
            <StyledTabTitle>{t('common:gender')}</StyledTabTitle>
            <StyledGendersWrapper>
                {data?.map(gender => {
                    const isSelected = genders.some(selectedGender => selectedGender.id === gender.id)
                    return (
                        <StyledGenderButton
                            isSelected={isSelected}
                            onClick={() => onSelectGender(gender, isSelected)}
                            key={gender.id}
                        >
                            {gender.name}
                        </StyledGenderButton>
                    )
                })}
            </StyledGendersWrapper>
        </div>
    )
}
