import axios from '@/clientAPI.ts'
import * as types from '../types'

export const TRANSLATE_API = {
    getLanguageOfMessage: async ({
        text
    }: types.GetLanguageOfMessageProps): Promise<types.GetLanguageOfMessageRequest> => {
        const {data} = await axios.get(`/translations/get-language`, {params: {text}})
        return data
    },
    getTranslation: async ({contents, target}: types.GetTranslationProps): Promise<types.GetTranslationRequest> => {
        const {data} = await axios.post(`/translations`, {contents, target})
        return data
    }
}
