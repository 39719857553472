import {GuestPartialSchema, HostPartialSchema} from '@/features/authentication/services/auth.schema'
import {PageSchema} from '@/features/common/services/common.schema'
import {z} from 'zod'

export const TopFanSchema = z.object({
    id: z.number(),
    rookie_id: z.number(),
    leader_id: z.number(),
    is_visible: z.boolean(),
    micromorgi_amount: z.number(),
    amount_to_reach: z.number(),
    last_transaction_amount: z.number(),
    last_transaction_date: z.string().nullable(),
    position: z.number(),
    status: z.string(),
    min_amount_to_reach: z.number(),
    mega_gifts_count: z.number(),
    rookie: HostPartialSchema.nullable().optional(),
    leader: GuestPartialSchema.nullable().optional()
})

export const TopFansListResponseSchema = z.array(TopFanSchema)

export const TopFansPaginatedListResponseSchema = PageSchema(TopFanSchema)
