import styled, {css} from 'styled-components'

export const StyledVideoPreviewWrapper = styled.div`
    position: relative;
    display: grid;
    cursor: pointer;
    width: 100%;
    grid-template-rows: 84px;
`
export const StyledVideoPreview = styled.div`
    ${({theme: {palette}}) => css`
        position: relative;
        width: 100%;
        overflow: auto;
        border-radius: 10px;
        z-index: 2;
        & video {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }

        &:before {
            content: ' ';
            height: 100%;
            width: 100%;
            position: absolute;
            background: ${palette.gradient['900']};
            z-index: 1;
        }
        &::-webkit-scrollbar {
            display: none;
        }
    `}
`
export const StyledPlayButton = styled.div`
    position: absolute;
    top: 35%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 2;
`

export const StyledHostsName = styled.div`
    ${({theme: {palette, typography}}) => css`
        ${typography.textMd}
        color: ${palette.neutral.white};
        position: absolute;
        top: 60%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 100%;
        text-align: center;
        z-index: 2;
    `}
`
