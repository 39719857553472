import {useCountdown} from '@/hooks/useCountdown'
import {StyledCountdownRoot} from './style'
import {getLocalDate} from '@/dayjs'
import {useTranslation} from 'react-i18next'

type CountdownProps = {
    endDate: string
}

export const GoalCountdown = ({endDate}: CountdownProps) => {
    const {t} = useTranslation()
    const localEndDate = endDate ? getLocalDate(endDate) : ''
    const {days, hours, minutes, seconds} = useCountdown({date: localEndDate})

    const leading0 = (num: number) => {
        return num < 10 ? '0' + num : num
    }

    const formattedTimer = () => {
        if (days < 1) {
            return `${leading0(hours)}:${leading0(minutes)}:${leading0(seconds)}`
        } else {
            // if, for example, there are 1 day 14 hours left, you need to show 2 days left (days + 1)
            return `${days + 1} ${t('goal_card:timer')}`
        }
    }

    return <StyledCountdownRoot>{formattedTimer()}</StyledCountdownRoot>
}

export default GoalCountdown
