type SnippetsArgs = {
    siteID: string
    cookiePolicyID: string
}

type SnippetsResult = {
    scriptOptInOut: string
    bodyScript: string
}

type IubendaInitializeParams = {
    siteID: string
    cookiePolicyID: string
}

export const Iubenda = {
    iubenda: (args: SnippetsArgs) => {
        const snippets = Snippets.tags(args)

        const scriptOptInOut = () => {
            const script = document.createElement('script')
            script.innerHTML = snippets.scriptOptInOut
            return script
        }

        const scriptBody = () => {
            const script = document.createElement('script')
            script.setAttribute('src', snippets.bodyScript)
            script.setAttribute('async', 'true')
            return script
        }

        return {scriptOptInOut, scriptBody}
    },
    load(d: Document = document, s: string = 'script'): Promise<void> {
        return new Promise((resolve, reject) => {
            const js: HTMLScriptElement = d.createElement(s) as HTMLScriptElement
            const documentBody = d.body

            if (d.getElementById('iubenda-policy')) {
                return
            }

            js.id = 'iubenda-policy'
            js.src = 'https://cdn.iubenda.com/cs/iubenda_cs.js'

            // Load as last <body> child
            documentBody.insertBefore(js, documentBody.lastElementChild)

            js.onload = () => {
                resolve()
            }

            js.onerror = () => {
                reject('Cannot load Iubenda')
            }
        })
    },
    initialize: async ({siteID, cookiePolicyID}: IubendaInitializeParams): Promise<void> => {
        const iubenda = Iubenda.iubenda({siteID, cookiePolicyID})
        document.body.insertBefore(iubenda.scriptOptInOut(), document.body.childNodes[0])
        await Iubenda.load()
        //document.body.insertBefore(iubenda.scriptBody(), document.body.childNodes[0])
    }
}

const Snippets = {
    tags: ({siteID, cookiePolicyID}: SnippetsArgs): SnippetsResult => {
        if (!siteID) console.warn('[iubenda]', 'Iubenda siteID is required')
        if (!cookiePolicyID) console.warn('[iubenda]', 'Iubenda cookiePolicyID is required')

        const preferenceHandler = `preference => {
            dataLayer.push({
                iubenda_ccpa_opted_out: _iub.cs.api.isCcpaOptedOut()
            })

            if (!preference) {
                dataLayer.push({
                    event: 'iubenda_preference_not_needed'
                })
            } else {
                if (preference.consent === true) {
                    dataLayer.push({
                        event: 'iubenda_consent_given'
                    })
                } else if (preference.consent === false) {
                    dataLayer.push({
                        event: 'iubenda_consent_rejected'
                    })
                } else if (preference.purposes) {
                    for (var purposeId in preference.purposes) {
                        if (preference.purposes[purposeId]) {
                            dataLayer.push({
                                event: 'iubenda_consent_given_purpose_' + purposeId
                            })
                        }
                    }
                }
            }
        }`

        const scriptOptInOut = `var _iub = _iub || [];
                                _iub.csConfiguration = {
                                    "cookiePolicyInOtherWindow":true,
                                    "countryDetection":true,
                                    "gdprAppliesGlobally":false,
                                    "consentOnContinuedBrowsing":false,
                                    "whitelabel":false,
                                    "lang":"en",
                                    "siteId": ${siteID},
                                    "cookiePolicyId": ${cookiePolicyID},
                                    "cookiePolicyUrl":"https://www.morgi.org/cookie-policy",
                                    "banner":{ 
                                        "acceptButtonDisplay":true,
                                        "acceptButtonColor":"#8649d1",
                                        "acceptButtonCaptionColor":"white",
                                        "rejectButtonDisplay":true,
                                        "rejectButtonColor":"#ffffff",
                                        "rejectButtonCaptionColor":"#8649d1",
                                        "position":"float-bottom-center",
                                        "textColor":"#808080",
                                        "backgroundColor":"#ffffff",
                                        "acceptButtonCaption":"Dismiss",
                                        "rejectButtonCaption":"Disagree",
                                        "content": "<div id=\\"iubenda-cs-paragraph\\"><p class=\\"iub-p\\">Please notice that we use cookies on our site to improve performance and to enhance your user experience. By dismissing this message you agree to our <a href=\\"https://www.morgi.org/cookie-policy\\" class=\\"iubenda-cs-cookie-policy-lnk\\" target=\\"_blank\\" rel=\\"noopener\\">cookie policy</a>.</p><br /><p class=\\"iub-p\\"></p></div>"
                                        },
                                    "callback": {
                                        "onPreferenceExpressedOrNotNeeded": ${preferenceHandler}
                                    }
                                };`

        const bodyScript = 'https://cdn.iubenda.com/cs/iubenda_cs.js'

        return {scriptOptInOut, bodyScript}
    }
}
