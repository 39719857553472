import styled, {DefaultTheme, css} from 'styled-components'

export const StyledSupportersRoot = styled.div`
    ${({
        theme: {mediaQueries, palette, shadows, spacing, zIndex, sizing},
        isOpen
    }: {
        theme: DefaultTheme
        isOpen: boolean
    }) => css`
        width: 90vw;
        max-width: 650px;
        height: 100vh;
        max-height: ${isOpen ? '90vh' : sizing.goalSupportersSectionHeight};
        padding: ${spacing * 2}px;
        z-index: ${isOpen && zIndex.modalOverlay};
        border-radius: 10px 10px 0px 0px;
        background-color: ${palette.neutral.white};
        box-shadow: ${shadows.mdXl};
        cursor: pointer;
        transition: max-height 0.2s ease-in-out 0s;
        position: absolute;
        bottom: 0;
        left: 50%;
        transform: translate(-50%, -0%);

        ${mediaQueries.m} {
            width: 100%;
        }
    `}
`

export const StyledHeader = styled.div`
    ${({theme: {typography, spacing}, isOpen}: {theme: DefaultTheme; isOpen: boolean}) => css`
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        margin-bottom: ${isOpen ? `${spacing * 5}` : `${spacing * 2}`}px;

        & h4 {
            ${typography.textMd};
            grid-column: 2;
            text-align: center;
            font-weight: 700;
        }

        & svg {
            justify-self: end;
            cursor: pointer;
        }
    `}
`

export const StyledSupporterModal = styled.div`
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;

    & button {
        width: 100%;
        max-width: 400px;
    }

    & section {
        width: 100%;
    }
`

export const StyledSupportersList = styled.div`
    ${({theme: {spacing, mediaQueries}}) => css`
        display: grid;
        overflow-y: auto;
        grid-template-columns: repeat(4, 1fr);
        grid-auto-rows: min-content;
        row-gap: ${spacing * 4}px;

        ${mediaQueries.m} {
            grid-template-columns: repeat(6, 1fr);
        }
    `}
`

export const StyledButtonText = styled.div`
    ${({theme: {spacing, palette}}) => css`
        display: flex;
        justify-content: center;
        gap: ${spacing}px;

        & svg {
            & path {
                fill: ${palette.neutral.white};
            }
        }
    `}
`
