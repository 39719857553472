import { DonationType } from '@/features/recurring-gift/types'
import { Lens } from '@dhmk/zustand-lens'

type RecurringGiftModalState = {
    isOpen: boolean
    hostID: string | null
    selectedAmount?: DonationType
    minAmountToOpenConnection?: number
}

type RecurringGiftModalActions = {
    updateModalDate: (
        params: Omit<RecurringGiftModalState, 'isOpen' | 'hostID'>
    ) => void
    openModal: (params: Omit<RecurringGiftModalState, 'isOpen'>) => void
    closeModal: () => void
}

export type RecurringGiftModal = RecurringGiftModalState &
    RecurringGiftModalActions

const initialModal: RecurringGiftModalState = { hostID: null, isOpen: false }

const createRecurringGiftModalSlice: Lens<RecurringGiftModal> = (set) => ({
    ...initialModal,
    updateModalDate: (params) => set(() => ({ ...params })),
    openModal: (params) => set(() => ({ ...params, isOpen: true })),
    closeModal: () => set(() => ({ ...initialModal })),
})

export default createRecurringGiftModalSlice
