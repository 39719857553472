import React, {useState} from 'react'
import Cropper from 'react-easy-crop'

import {StyledRoot} from './style'

interface Props {
    cropShape: 'rect' | 'round'
    aspectRatio?: number
    setCroppedImage: (image: File) => void
    inputImg?: string
}

const ImageCropper: React.FC<Props> = ({cropShape, aspectRatio = 1, setCroppedImage, inputImg}) => {
    const [crop, setCrop] = useState<{x: number; y: number}>({x: 0, y: 0})
    const [zoom, setZoom] = useState<number>(1)

    const onCropComplete = async (
        croppedArea: {x: number; y: number; width: number; height: number},
        croppedAreaPixels: {x: number; y: number; width: number; height: number}
    ) => {
        const croppedImage = await getCroppedImg({
            imageSrc: inputImg!,
            crop: croppedAreaPixels,
            returnedData: 'blob',
            croppedArea
        })
        setCroppedImage(croppedImage as File)
    }

    return (
        <StyledRoot>
            <Cropper
                image={inputImg}
                crop={crop}
                zoom={zoom}
                aspect={aspectRatio} // 9/16
                cropShape={cropShape}
                onCropChange={setCrop}
                onCropComplete={onCropComplete}
                onZoomChange={setZoom}
            />
        </StyledRoot>
    )
}

// base64 string to Image
const createImage = (url: string) =>
    new Promise<HTMLImageElement>((resolve, reject) => {
        const image = new Image()
        image.addEventListener('load', () => resolve(image))
        image.addEventListener('error', error => reject(error))
        image.setAttribute('crossOrigin', 'anonymous')
        image.src = url
    })

type CroppedImgProps = {
    imageSrc: string
    crop: {x: number; y: number; width: number; height: number}
    returnedData: 'blob' | 'dataURL'
    croppedArea: {x: number; y: number; width: number; height: number}
}
const getCroppedImg = async ({imageSrc, crop, returnedData = 'blob'}: CroppedImgProps) => {
    const image = await createImage(imageSrc)
    const canvas = document.createElement('canvas')
    const ctx = canvas.getContext('2d')!

    /* setting canvas width & height allows us to
    resize from the original image resolution */
    canvas.width = crop.width
    canvas.height = crop.height

    ctx.drawImage(image, crop.x, crop.y, crop.width, crop.height, 0, 0, canvas.width, canvas.height)

    return new Promise<Blob | string>(resolve => {
        if (returnedData === 'blob') {
            canvas.toBlob(blob => {
                resolve(blob!)
            }, 'image/jpeg')
        } else {
            resolve(canvas.toDataURL('image/jpeg'))
        }
    })
}

export default ImageCropper
