import styled, {css} from 'styled-components'

export const StyledFeedHeading = styled.div`
    ${({theme: {mediaQueries, typography, palette, spacing}}) => css`
        ${typography.textXl};
        font-weight: 700;
        line-height: 24px;
        text-align: center;
        letter-spacing: -0.05em;
        width: 100%;
        margin-top: ${spacing}px;

        & > div {
            background: ${palette.gradient[400]};
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
        }

        ${mediaQueries.m} {
            ${typography.displayXXl};
            line-height: 77px;
            text-align: left;
        }
    `}
`
