import {Lens} from '@dhmk/zustand-lens'

type SelectGlobalLanguageModalState = {
    isOpen: boolean
}

type SelectGlobalLanguageModalActions = {
    openModal: (params: Omit<SelectGlobalLanguageModalState, 'isOpen'>) => void
    closeModal: () => void
}

export type SelectGlobalLanguageModal = SelectGlobalLanguageModalState & SelectGlobalLanguageModalActions

const initialModal: SelectGlobalLanguageModalState = {
    isOpen: false
}

const createSelectGlobalLanguageModalSlice: Lens<SelectGlobalLanguageModal> = set => ({
    ...initialModal,
    openModal: params => set(() => ({...params, isOpen: true})),
    closeModal: () => set(() => ({...initialModal}))
})

export default createSelectGlobalLanguageModalSlice
