import styled, {css, DefaultTheme} from 'styled-components'

export const StyledGifsRoot = styled.div`
    ${({theme: {spacing, mediaQueries, palette}}) => css`
        grid-column-gap: 8px;
        grid-row-gap: 8px;
        gap: ${spacing}px;
        overflow-y: auto;
        height: 100%;
        width: fit-content;
        display: grid;
        grid-template-columns: 1fr 1fr;
        justify-content: center;
        padding: 0px ${spacing * 1.25}px ${spacing}px ${spacing * 2}px;
        margin: 0 auto;
        ${mediaQueries.m} {
            gap: ${spacing * 2}px;
        }
        &::-webkit-scrollbar {
            background: ${palette.neutral.transparent};
            width: 5px;
            border-radius: 3px;
        }

        &::-webkit-scrollbar-thumb {
            background: ${palette.neutral.black};
        }

        &::-webkit-scrollbar-track {
            margin-bottom: ${spacing * 4}px;
        }
        &::-webkit-scrollbar-button:start {
            display: none;
        }
        &::-webkit-scrollbar-button:end {
            display: none;
        }
    `}
`
export const StyledTitleForEmpty = styled.h1`
    ${({theme: {spacing, typography, palette}}) => css`
        text-align: center;
        ${typography.displayMd};
        margin: ${spacing * 5} auto;
        align-self: center;
        grid-column: span 2;
        background: ${palette.gradient[700]};
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
    `}
`
export const StyledGif = styled.div`
    width: 164px;
    cursor: pointer;
    height: 164px;
    aspect-ratio: 1/1;
    border-radius: 10px;
`
export const StyledClearIcon = styled.span`
    cursor: pointer;
`
export const modalStyles = (theme: DefaultTheme) => css`
    height: 100%;
    width: 100%;
    min-width: 376px;
    max-width: 376px;
    border-radius: 0px;
    display: flex;
    flex-direction: column;
    ${theme.mediaQueries.m} {
        border-radius: 10px;
        width: auto;
    }
    & button {
        width: 90%;
        margin: ${theme.spacing * 2}px auto;
    }
`
export const inputStyles = (theme: DefaultTheme) => css`
    margin: 0 auto ${theme.spacing * 2}px;
    max-width: 340px;
`
