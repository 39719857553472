import {Lens} from '@dhmk/zustand-lens'

type SendMegaGiftModalState = {
    isOpen: boolean
    hostID: string | null
    onSuccessSendGift?: () => void
}

type SendMegaGiftModalActions = {
    openModal: (params: Omit<SendMegaGiftModalState, 'isOpen'>) => void
    closeModal: () => void
}

export type SendMegaGiftModal = SendMegaGiftModalState & SendMegaGiftModalActions

const initialModal: SendMegaGiftModalState = {hostID: null, isOpen: false}

const createSendMegaGiftModalSlice: Lens<SendMegaGiftModal> = set => ({
    ...initialModal,
    openModal: params => set(() => ({...params, isOpen: true})),
    closeModal: () => set(() => ({...initialModal}))
})

export default createSendMegaGiftModalSlice
