import {
    ActivitiesIcon,
    FilterAgeGenderIcon,
    FilterChatTopics,
    FilterCountryLanguageIcon,
    FilterSpecialHostIcon
} from '@/assets/icons/icons'
import {HostFiltersTabIdsE} from '../types'
import {GenderKeyNameE} from '@/features/constants/constants'

export const GENDER_ALL_OPTION_KEY_NAME = GenderKeyNameE.ALL
export const MIN_AGE_TO_SELECT = 1
export const MAX_AGE_TO_SELECT = 99

export const HOST_FEED_FILTER_TABS = [
    {
        value: HostFiltersTabIdsE.CHAT_TOPICS,
        icon: <FilterChatTopics />
    },
    {
        value: HostFiltersTabIdsE.ACTIVITIES,
        icon: <ActivitiesIcon />
    },
    {
        value: HostFiltersTabIdsE.COUNTRY_LANGUAGE,
        icon: <FilterCountryLanguageIcon />
    },
    {
        value: HostFiltersTabIdsE.SPECIAL_HOST_CATEGORY,
        icon: <FilterSpecialHostIcon />
    },
    {
        value: HostFiltersTabIdsE.GENDER,
        icon: <FilterAgeGenderIcon />
    }
]
