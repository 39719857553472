import Checkbox from '@/components/commons/checkbox/CheckBox'
import {Button} from '@/components/ui/button/Button'
import topFanModalImage from '@assets/images/topFan/topFanModalImage.png'
import styled, {css} from 'styled-components'

export const StyledTopFanPendingModalContainer = styled.div`
    ${({theme: {spacing, mediaQueries}}) => css`
        padding: 0 ${spacing * 2}px;
        height: calc(100% - 64px);
        width: 100%;
        display: grid;
        grid-template-rows: auto min-content;
        ${mediaQueries.m} {
            padding: 0 ${spacing * 3}px;
        }
    `}
`
export const StyledTopFanModalImage = styled.img.attrs(() => ({src: topFanModalImage}))`
    height: 188px;
`
export const StyledAmount = styled.div`
    ${({theme: {spacing}}) => css`
        font-weight: 600;
        display: flex;
        align-items: center;
        gap: ${spacing / 4}px;
    `}
`
export const StyledDescription = styled.div`
    ${({theme: {spacing, palette, typography}}) => css`
        ${typography.textSm}
        display: flex;
        color: ${palette.primary[700]};
        margin: ${spacing}px 0px ${spacing * 3}px;
        font-weight: 600;
        text-align: center;
    `}
`
export const StyledText = styled.span`
    ${({theme: {palette}}) => css`
        color: ${palette.primary[700]};
        text-align: center;
        white-space: break-spaces;
        & span {
            color: rgba(134, 73, 209, 0.5);
            cursor: pointer;
            font-weight: 700;
        }
    `}
`
export const StyledTopFanTitle = styled.h3`
    ${({theme: {spacing, typography}}) => css`
        ${typography.textLg}
        font-weight: 800;
        margin-top: ${spacing * 2}px;
    `}
`
export const StyledTopFanPerksDescription = styled.span`
    ${({theme: {palette}}) => css`
        color: ${palette.primary[700]};
        text-align: center;
        white-space: break-spaces;
    `}
`
export const StyledCheckbox = styled(Checkbox)`
    ${({theme: {spacing}}) => css`
        width: 100%;
        margin-top: ${spacing * 2}px;
    `}
`
export const StyledSubmitButton = styled(Button)`
    ${({theme: {spacing}}) => css`
        width: 100%;
        max-width: 400px;
        max-height: 56px;
        padding: ${spacing * 2}px ${spacing * 3}px;
        margin: ${spacing * 1.5}px 0;
        place-self: center;
    `}
`
export const StyledContentContainer = styled.div`
    ${({theme: {spacing, scrollbar, palette}}) => css`
        ${scrollbar}
        overflow-y: auto;
        display: grid;
        justify-content: center;
        align-items: center;
        justify-items: center;
        gap: ${spacing / 2}px;
        &::-webkit-scrollbar {
            background: ${palette.neutral.transparent};
            border-right-radius: 80px;
            height: 5px;
            width: 6px;
        }
        &::-webkit-scrollbar-thumb {
            background: ${palette.neutral[200]};
        }
        scrollbar-width: thin; // set the scrollbar width to 'thin'
        -webkit-scrollbar-width: 'thin'; // fallback for Safari
    `}
`
