import {useQuery} from '@tanstack/react-query'
import {QUERY_KEYS} from './keys'
import {GUEST_API} from '../services/guest.http'

export const useGetMinMaxAge = () => {
    return useQuery({
        queryKey: [QUERY_KEYS.ages],
        queryFn: () => GUEST_API.getMinMaxAge()
    })
}
