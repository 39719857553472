import {FC, PropsWithChildren} from 'react'
import {StyledContent, StyledOverlay, StyledRoot} from './style'
import {createPortal} from 'react-dom'
import {useLockBodyScroll} from '@/hooks/useLockBodyScroll'
import useContainerDimensions from '@/hooks/useContainerDimensions'
type SidePopoverProps = {
    isOpen: boolean
    onClose: () => void
    side?: 'left' | 'right',
    desktopMaxWidth: string
} & PropsWithChildren

export const SidePopover: FC<SidePopoverProps> = ({isOpen, onClose, children, side = 'right', desktopMaxWidth}) => {
    useLockBodyScroll({enable: isOpen})
    const {heightOfHeader} = useContainerDimensions()
    return createPortal(
        <>
            <StyledOverlay headerSize={heightOfHeader} onClick={onClose} isOpen={isOpen} />
            <StyledRoot isOpen={isOpen} side={side} headerSize={heightOfHeader} desktopMaxWidth={desktopMaxWidth}>
                <StyledContent>{children}</StyledContent>
            </StyledRoot>
        </>,
        document.body
    )
}
