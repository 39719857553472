import {useMutation, useQueryClient} from '@tanstack/react-query'
import {GOAL_API} from '../services/goal.http'
import {QUERY_KEYS} from './keys'

export const useSaveGoal = (goalId: string | number) => {
    const queryClient = useQueryClient()

    return useMutation({
        mutationFn: () => GOAL_API.saveGoal(`${goalId}`),
        onSuccess: () => {
            queryClient.invalidateQueries({queryKey: [QUERY_KEYS.selected_goal]})
            queryClient.invalidateQueries({queryKey: [QUERY_KEYS.saved_goals]})
            queryClient.invalidateQueries({queryKey: [QUERY_KEYS.supported_goals]})
        }
    })
}
