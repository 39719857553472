import {GroupBase, GroupHeadingProps} from 'react-select'
import {StyledGroupLabel} from './style'
import {OptionType} from './types/types'

const GroupHeading = (groupHeading: GroupHeadingProps<OptionType, boolean, GroupBase<OptionType>>) => {
    return <StyledGroupLabel>{groupHeading.children}</StyledGroupLabel>
}

export const customComponents = {
    GroupHeading
}
