import i18n from 'i18next'
import Backend from 'i18next-http-backend'
import LanguageDetector from 'i18next-browser-languagedetector'
import {initReactI18next} from 'react-i18next'

//dayjs locales //TODO: add others locales if needded
import 'dayjs/locale/it'
import 'dayjs/locale/en'
import 'dayjs/locale/ar'
import 'dayjs/locale/es'
import 'dayjs/locale/fr'
import 'dayjs/locale/ja'
import 'dayjs/locale/ko'
import 'dayjs/locale/nl'
import 'dayjs/locale/pt'
import 'dayjs/locale/ru'
import 'dayjs/locale/zh'
import {KNOWN_COOKIES} from '@/types'

export const FALLBACK_LANGUAGE = 'en_US'

export const APP_LANGUAGES = [
    {label: 'English (EN)', value: 'en_US', flagCode: 'gb'},
    {label: 'Español (ES)', value: 'es_419', flagCode: 'es'},
    {label: 'Português (PT)', value: 'pt_BR', flagCode: 'pt'}
]

const customLanguageDetector = {
    name: 'browserLanguage',
    lookup() {
        const found = []
        if (typeof navigator !== 'undefined') {
            if (navigator.language) {
                found.push(navigator.language)
            }
        }
        return found.length > 0 ? found : undefined
    }
}
const languageDetector = new LanguageDetector()
languageDetector.addDetector(customLanguageDetector)

i18n.use(Backend)
    .use(languageDetector)
    .use(initReactI18next)
    .init({
        ns: ['pages', 'validation', 'common'],
        defaultNS: 'pages',
        fallbackLng: FALLBACK_LANGUAGE,
        debug: false,
        keySeparator: ':',
        react: {
            useSuspense: false
        },
        supportedLngs: APP_LANGUAGES.map(language => language.value),
        interpolation: {
            escapeValue: false
        },
        detection: {
            order: ['cookie', 'localStorage', 'browserLanguage'],
            caches: ['cookie', 'localStorage'],
            lookupLocalStorage: 'i18nextLng',
            lookupCookie: 'i18nextLng',
            convertDetectedLanguage: lng => lng.replace('-', '_'),
            cookieOptions: {path: '/', domain: KNOWN_COOKIES.mainDomain}
        }
    })

export default i18n
