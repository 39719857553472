import {QUERY_KEYS} from '@/features/authentication/queries/keys.ts'
import {AUTH_API} from '@/features/authentication/services/auth.http.ts'
import {useQuery} from '@tanstack/react-query'

export const useGetLocalize = () => {
    return useQuery({
        queryKey: [QUERY_KEYS.localize],
        queryFn: AUTH_API.getLocalize
    })
}
