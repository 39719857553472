import {spacing} from '@/theme/theme'
import styled, {DefaultTheme, css} from 'styled-components'

export const StyledItem = styled.div`
    ${({theme: {spacing, palette, typography}, inHostProfile}: {theme: DefaultTheme; inHostProfile: boolean}) => css`
        display: flex;
        align-items: center;
        ${typography.textMd};
        line-height: 19px;
        color: ${palette.primary['800']};

        & p {
            margin: 0px ${spacing / 2}px 0px ${spacing * 2}px;
            color: ${inHostProfile ? palette.primary['700'] : palette.primary['800']};
        }

        & svg {
            cursor: auto !important;
            opacity: ${inHostProfile ? '0.5' : '1'};
        }
    `}
`

export const StyledCountItem = styled.div`
    ${({theme: {spacing, typography}}) => css`
        ${typography.textMd};
        font-weight: 700;
        text-decoration-line: underline;
        cursor: pointer;
        margin-left: ${spacing}px;
    `}
`

export const StyledTipsWrap = styled.div`
    ${({theme: {palette}}) => css`
        display: flex;
        max-width: 400px;
        flex-wrap: wrap;
        align-items: center;
        border-left: 2px solid ${palette.primary['800']};
        margin-top: 10px;
    `}
`

export const StyledTip = styled.div`
    ${({theme: {palette}}) => css`
        line-height: 17px;
        color: ${palette.primary['800']};
        margin-left: ${spacing}px;
    `}
`

export const StyledHide = styled.div`
    ${({
        theme: {spacing, palette, typography, mediaQueries},
        inHostProfile
    }: {
        theme: DefaultTheme
        inHostProfile: boolean
    }) => css`
        color: ${inHostProfile ? palette.primary['900'] : palette.primary['275']};
        font-weight: 700;
        text-decoration-line: underline;
        cursor: pointer;
        margin-left: ${spacing}px;

        ${mediaQueries.m} {
            ${inHostProfile && typography.textMd};
        }
    `}
`
