import styled, {DefaultTheme, css} from 'styled-components'

export const StyledButton = styled.button`
    ${({
        theme: {typography, spacing, palette},
        backgroundColor,
        small,
        buttonDisabled
    }: {
        theme: DefaultTheme
        backgroundColor?: string
        small?: boolean
        buttonDisabled?: boolean
    }) => css`
        background-color: ${backgroundColor ?? 'inherit'};
        outline: none;
        border: ${`1px ${backgroundColor ?? '#000'} solid`};
        border-radius: 100px;
        ${small ? typography.textXs : typography.textMd}
        color: ${backgroundColor ? palette.neutral.white : palette.neutral.black};
        font-weight: 600;
        line-height: 140%;
        padding: ${small ? `${spacing}px ${spacing}px` : `${spacing * 1.75}px ${spacing * 1.25}px`};
        width: 100%;
        cursor: pointer;
        opacity: ${buttonDisabled && '0.1'};
        text-align: center;
    `}
`

export const StyledNotification = styled.div`
    ${({theme: {spacing}}) => css`
        display: flex;
        justify-content: space-between;
        align-items: center;
        background: linear-gradient(0deg, #fc9f21 0%, #ffda48 38.54%, #ffe2ba 100%);
        box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15);
        border-radius: 10px;
        margin: ${spacing * 3}px 0px;
        cursor: pointer;
        padding: ${spacing * 2}px;
    `}
`

export const StyledActionBox = styled.div`
    ${({theme: {spacing, palette}}) => css`
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: ${spacing * 1.5}px ${spacing * 2}px;
        gap: ${spacing * 0.5}px;
        border: 1px solid ${palette.primary[900]};
        border-radius: 30px;
        width: 31px;
        height: 31px;
        padding: ${spacing}px;

        & svg {
            width: 15px !important;
            height: 15px !important;
        }
    `}
`

export const StyledDescription = styled.p`
    ${({theme: {typography}}) => css`
        margin: 0;
        ${typography.textSm}
        font-weight: 400;
    `}
`
