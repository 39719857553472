import {useTranslation} from 'react-i18next'
import {StyledCloseIcon, StyledTooltipContent} from './style'

import mySurprises from '@assets/images/my-surprises/mySurprises.png'

import {Popover} from '@/components/ui/popover/Popover'
import {ReactNode, useState} from 'react'

export const SurpriseTooltip = ({trigger, title, text}: {trigger: ReactNode; title: string; text: string}) => {
    const {t} = useTranslation()

    const [isOpen, setIsOpen] = useState(false)

    return (
        <Popover
            isModal={true}
            trigger={trigger}
            rootProps={{
                open: isOpen,
                onOpenChange: () => {
                    setIsOpen(!isOpen)
                }
            }}
        >
            <StyledTooltipContent>
                <StyledCloseIcon
                    onClick={() => {
                        setIsOpen(false)
                    }}
                />
                <p>{t(title)}</p>
                <img src={mySurprises} />
                <div>{t(text)}</div>
            </StyledTooltipContent>
        </Popover>
    )
}

SurpriseTooltip.displayName = 'SurpriseTooltip'
