import {StyledIconButton} from './style'

type IconButtonProps = {
    onClick?: () => void
    icon?: JSX.Element
}

export const IconButton = ({icon, onClick}: IconButtonProps) => {
    return <StyledIconButton onClick={onClick}>{icon}</StyledIconButton>
}
