import axios from '@/clientAPI.ts'
import * as types from '../types'

export const GIFS_API = {
    getAnimations: async ({order_by_chat}: types.GetAnimationsRequestType): Promise<types.Animation[]> => {
        const {data} = await axios.get('/animations', {params: {order_by_chat}})
        return data
    },
    getCurrentAnimation: async ({animation_id}: types.GetAnimationRequestType): Promise<types.Animation> => {
        const {data} = await axios.get(`/animations/${animation_id}`)
        return data
    }
}
