import {useTranslation} from 'react-i18next'
import {StyledTabTitle} from '../style'
import {StyledAgeFilterRoot} from './style'
import {MultiRangeSlider} from '../multi-range-slider/MultiRangeSlider'
import {Dispatch, SetStateAction} from 'react'

type AgeSectionProps = {
    fromAge: number
    toAge: number
    setFromAge: Dispatch<SetStateAction<number>>
    setToAge: Dispatch<SetStateAction<number>>
}

export const AgeSection = ({fromAge, toAge, setFromAge, setToAge}: AgeSectionProps) => {
    const {t} = useTranslation()

    return (
        <StyledAgeFilterRoot>
            <StyledTabTitle>
                {t('common:age')}
                {` (${fromAge}-${toAge})`}
            </StyledTabTitle>
            <MultiRangeSlider fromAge={fromAge} toAge={toAge} setFromAge={setFromAge} setToAge={setToAge} />
        </StyledAgeFilterRoot>
    )
}
