import * as React from 'react'
import * as Tooltip from '@radix-ui/react-tooltip'
import {StyledTooltipContent} from './style'

interface TooltipProps extends React.PropsWithChildren {
    variant?: 'light' | 'dark' | 'darkPurple'
    side?: 'top' | 'right' | 'bottom' | 'left'
    delayDuration?: number
    trigger?: React.ReactNode
    content: React.ReactNode
    paddingTop?: number
    paddingSides?: number
    color?: 'neutral' | 'primary' | 'secondary' | 'success' | 'tertiary' | 'warning' | 'danger'
    onlyHover?: boolean
    sideOffset?: number
    align?: 'start'
}

type TooltipElement = React.ElementRef<typeof Tooltip.Content>

const TooltipBase = React.forwardRef<TooltipElement, TooltipProps>((props, ref) => {
    const {paddingSides = 0, paddingTop = 6, children, content, color, onlyHover = false, ...rest} = props
    const [isOpen, setIsOpen] = React.useState(false)

    return (
        <Tooltip.Provider delayDuration={200}>
            <Tooltip.Root
                open={isOpen}
                onOpenChange={() => {
                    setIsOpen(!isOpen)
                }}
            >
                <Tooltip.Trigger
                    asChild
                    onClick={e => {
                        if (!onlyHover) {
                            e.preventDefault()
                            e.stopPropagation()
                            setIsOpen(!isOpen)
                        }
                    }}
                >
                    {children}
                </Tooltip.Trigger>
                <Tooltip.Portal>
                    <StyledTooltipContent
                        variant={rest.variant || 'light'}
                        $paddingTop={paddingTop}
                        $paddingSides={paddingSides}
                        sideOffset={5}
                        side="bottom"
                        color={color}
                        {...rest}
                        ref={ref}
                    >
                        {content}
                        <Tooltip.Arrow width={10} height={7} />
                    </StyledTooltipContent>
                </Tooltip.Portal>
            </Tooltip.Root>
        </Tooltip.Provider>
    )
})

TooltipBase.displayName = 'Tooltip'

export {TooltipBase}
export type {TooltipProps}
