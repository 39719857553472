export default function swDev() {
    const swURL = '/serviceWorker.js'

    if ('serviceWorker' in navigator) {
        window.addEventListener('load', function () {
            navigator.serviceWorker.register(swURL, {scope: '.'}).then(res => {
                console.warn('service worker is register', res)
            })
        })
    }
}
