import {QUERY_KEYS} from '@/features/authentication/queries/keys'
import {PaymentError} from '@/features/guest-payments/types'
import {TagManager} from '@/integrations/tag-manager/tagManager'
import {ROUTE_MESSAGE_CENTER} from '@/utilities/constants/routeNames'
import {LocalStorageManager} from '@/utilities/localStorage'
import {useMutation, useQueryClient} from '@tanstack/react-query'
import {AxiosResponse} from 'axios'
import {MICROMORGI_API} from '../services/micromorgi.http'
import {BuyMicromorgiPackageParams} from '../types'
import {MUTATION_KEYS} from './keys'

export const useBuyMicromorgiPackage = () => {
    const queryClient = useQueryClient()
    return useMutation<AxiosResponse, PaymentError, BuyMicromorgiPackageParams>({
        mutationKey: [MUTATION_KEYS.buy_micromorgi_package],
        mutationFn: MICROMORGI_API.buyMicromorgiPackage,
        onSuccess: ({data}) => {
            queryClient.invalidateQueries({queryKey: [QUERY_KEYS.user]})

            if(data?.transaction_id && data?.transaction_amount){
                TagManager.dataLayer({
                    event: 'purchase', 
                    transactionId: data?.transaction_id,
                    transactionAmount: data?.transaction_amount
                })
            }
        },
        onSettled: () => {
            LocalStorageManager.paymentStartLocation.set(location.pathname ?? ROUTE_MESSAGE_CENTER)
        }
    })
}
