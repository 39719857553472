import {Avatar} from '@/components/ui/avatar/Avatar'
import {StyledAmount, StyledAvatar, StyledName, StyledSupporterCardRoot} from './style'
import {MicromorgiIcon} from '@/assets/icons/icons'
import {SupporterType} from '@/features/goal/types'
import {generatePath, useNavigate} from 'react-router'
import {ROUTE_SUPPORTER_GUEST_PAGE} from '@/utilities/constants/routeNames'

export const SupporterCard = ({supporter}: {supporter: SupporterType}) => {
    const navigate = useNavigate()

    const redirectToSupporterProfile = () => {
        navigate(generatePath(ROUTE_SUPPORTER_GUEST_PAGE, {leaderId: `${supporter.id}`}))
    }

    return (
        <StyledSupporterCardRoot>
            <StyledAvatar onClick={redirectToSupporterProfile}>
                <Avatar height={50} width={50} imageUrl={supporter?.avatar?.url} name={supporter?.full_name} />
            </StyledAvatar>
            <StyledName>{supporter?.full_name}</StyledName>
            <StyledAmount>
                {supporter?.type_attributes?.leader_donations_amount}
                <MicromorgiIcon />
            </StyledAmount>
        </StyledSupporterCardRoot>
    )
}
