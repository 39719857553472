import {envVars} from '../../../envVars'

export const pusherConfig = {
    pusherAppKey: envVars.VITE_APP_PUSHER_APP_KEY,
    pusherAppCluster: envVars.VITE_APP_PUSHER_APP_CLUSTER,
    pusherAuthEndpoint: envVars.VITE_APP_PUSHER_AUTH_ENDPOINT
}

const baseNotificationConfig = {
    cluster: pusherConfig.pusherAppCluster,
    authEndpoint: pusherConfig.pusherAuthEndpoint,
    auth: {
        headers: {}
    }
}

export default baseNotificationConfig
