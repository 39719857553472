import styled, {css} from 'styled-components'

type ProgressProps = {
    percentage: number
    mainColor?: string
    secondaryColor?: string
}
export const StyledRoot = styled.div`
    display: grid;
    align-items: center;
    justify-items: center;
    text-align: center;
    position: relative;
    height: 25px;
    width: 100%;
    border-radius: 13px;
    overflow: hidden;
`

export const StyledProgressBarIndicator = styled.div<ProgressProps>(
    ({mainColor, percentage, theme: {palette}}) => css`
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        background: ${mainColor ?? palette.success[300]};
        z-index: 2;
        width: ${percentage}%;
    `
)
export const StyledProgressBarBorder = styled.div(
    ({secondaryColor}: {secondaryColor: string}) => css`
        z-index: 1;
        position: absolute;
        inset: 0;
        box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.15);
        border-radius: 13px;
        background-color: ${secondaryColor};
    `
)
export const StyledProgressBarText = styled.span<ProgressProps>(
    ({mainColor, percentage, theme: {palette, typography}}) => css`
        ${typography.textXs}
        font-weight: 700;
        z-index: 2;
        color: ${percentage >= 40 ? palette.neutral.white : mainColor};
    `
)
