import styled, {css} from 'styled-components'

export const AnimationWrapper = styled.div`
    ${({theme: {zIndex}}) => css`
        height: 100vh;
        position: fixed;
        width: 100%;
        top: 0;
        overflow: hidden;
        z-index: ${zIndex.modalOverlay};
        pointer-events: none;
    `}
`
