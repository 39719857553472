import React, {useState} from 'react'
import {AnimatePresence, PanInfo} from 'framer-motion'
import noAvatar from '@/assets/images/noAvatar.png'
import {StyledCarouselContainer, StyledDot, StyledDotContainer, StyledImageContainer, StyledSlide} from './style'

interface HostPhotoSliderProps {
    images: (string | undefined | null)[] | undefined
}

export const HostPhotoSlider: React.FC<HostPhotoSliderProps> = ({images}) => {
    const [currentIndex, setCurrentIndex] = useState(0)

    const handleDragEnd = (_: MouseEvent | TouchEvent | PointerEvent, info: PanInfo) => {
        if (!images) return
        if (info.offset.x > 50) {
            setCurrentIndex(prev => (prev - 1 + images.length) % images.length)
        } else if (info.offset.x < -50) {
            setCurrentIndex(prev => (prev + 1) % images.length)
        }
    }

    return (
        <StyledCarouselContainer>
            <StyledImageContainer>
                <AnimatePresence>
                    <StyledSlide
                        key={currentIndex}
                        url={images ? images[currentIndex] : noAvatar}
                        drag={images && images.length > 1 ? 'x' : false}
                        dragConstraints={{left: 0.5, right: 0.5}}
                        dragElastic={0.01}
                        onDragEnd={handleDragEnd}
                        initial={{opacity: 0}}
                        animate={{opacity: 1}}
                        exit={{opacity: 0}}
                        transition={{duration: 0.2}}
                    />
                </AnimatePresence>
            </StyledImageContainer>
            {images && images.length > 1 && (
                <StyledDotContainer photoCount={images.length}>
                    {images.map((_, index) => (
                        <StyledDot key={index} active={index === currentIndex} />
                    ))}
                </StyledDotContainer>
            )}
        </StyledCarouselContainer>
    )
}
