import {Button} from '@/components/ui/button/Button'
import styled, {css} from 'styled-components'

export const StyledConfirmSendGiftModalWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-top: 24px;
    padding-bottom: 16px;
`

export const StyledAmount = styled.div(
    ({theme: {palette, typography}}) => css`
        ${typography.displayMd}
        font-weight: 700;
        color: ${palette.primary[900]};
        letter-spacing: -0.05em;
    `
)
export const StyledConfirmText = styled.p(
    ({theme: {palette, typography}}) => css`
        ${typography.textXs}
        margin: 20px 0;
        font-weight: 400;
        color: ${palette.primary[900]};
        white-space: pre-wrap;
        text-align: center;
    `
)

export const StyledConfirmButton = styled(Button)(
    ({theme: {typography}}) => css`
        ${typography.textMd}
        max-width: 80%;
        font-weight: 600;
    `
)

export const StyledPulseWrapper = styled.div(
    ({theme: {zIndex}}) => css`
        position: fixed;
        top: -70px;
        left: 25%;
        z-index: ${zIndex.modalOverlay};
        display: flex;
        justify-content: center;
        align-items: center;
        pointer-events: none;
    `
)

export const StyledAnimation = styled.img(
    ({theme: {zIndex}}) => css`
        position: absolute;
        z-index: ${zIndex.modalOverlay};
        height: 100px;
        width: 100px;
        top: -50px;
        left: calc(50% - 50px);
    `
)
