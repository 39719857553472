import {Lens} from '@dhmk/zustand-lens'

type StarredMessageModalState = {
    isOpen: boolean
    channelID?: string | number
    guestID?: string | number
}

type StarredMessageModalActions = {
    openModal: (params: Omit<StarredMessageModalState, 'isOpen'>) => void
    closeModal: () => void
}

export type StarredMessageModal = StarredMessageModalState & StarredMessageModalActions

const initialModal: StarredMessageModalState = {isOpen: false}

const createStarredMessageModalSlice: Lens<StarredMessageModal> = set => ({
    ...initialModal,
    openModal: params => set(() => ({...params, isOpen: true})),
    closeModal: () => set(() => ({...initialModal}))
})

export default createStarredMessageModalSlice
