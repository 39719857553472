import styled, {DefaultTheme, css} from 'styled-components'

export const HeaderBannerContainer = styled.div`
    ${({
        isUltimateStatusOfferAvailable,
        theme: {palette, spacing, zIndex}
    }: {
        isUltimateStatusOfferAvailable: boolean
        theme: DefaultTheme
    }) => css`
        color: ${palette.success[25]};
        width: 100%;
        display: flex;
        padding: ${spacing / 4}px 0px;
        text-align: center;
        justify-content: center;
        background-color: ${palette.success[600]};
        gap: ${spacing / 2}px;
        position: sticky;
        top: 0;
        z-index: ${zIndex.headerBanner};
        ${isUltimateStatusOfferAvailable &&
        css`
            cursor: pointer;
        `}
    `}
`
