import styled, {css} from 'styled-components'

export const StyledTranslationSwitchWrapper = styled.div`
    ${({theme: {spacing}}) => css`
        display: grid;
        justify-content: center;
        margin: 0 auto;
        gap: ${spacing * 3.25}px;
    `}
`
