import {ReactNode} from 'react'
import {StyledIconsWrapper, StyledRoot, StyledTelegramIconWrapper} from './style'
import {RightArrowActionIcon, TelegramIcon} from '@/assets/icons/icons'
import {envVars} from '@/envVars'
import useMixPanel from '@/integrations/mixpanel/hooks/useMixpanel'
import {MixpanelEventNameE} from '@/integrations/mixpanel/types'
import {telegramBaseURL} from '@/utilities/constants/misc'

interface TelegramCardGuestProps {
    description: string | ReactNode
    icon?: ReactNode
    joinedTelegramBot: boolean
    telegramToken: string
}

export const TelegramCardGuest = ({icon, description, joinedTelegramBot, telegramToken}: TelegramCardGuestProps) => {
    const mixpanel = useMixPanel()

    if (joinedTelegramBot) return null

    const handleClickTelegramBotCard = () => {
        window.open(`${telegramBaseURL}${envVars.VITE_APP_TELEGRAM_BOT_NAME}?start=${telegramToken}`)
        mixpanel?.trackEvent(MixpanelEventNameE.ConnectTelegramClick)
    }

    return (
        <StyledRoot onClick={handleClickTelegramBotCard}>
            <p>{description}</p>
            <div>
                {icon ? (
                    icon
                ) : (
                    <StyledIconsWrapper>
                        <StyledTelegramIconWrapper>
                            <TelegramIcon fill="url(#paint0_linear_6086_2968)" width={27} height={26} />
                        </StyledTelegramIconWrapper>
                        <div>
                            <RightArrowActionIcon />
                        </div>
                    </StyledIconsWrapper>
                )}
            </div>
        </StyledRoot>
    )
}
