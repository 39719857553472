export const MUTATION_KEYS = {
    send_gift: 'send-gift',
    delete_gift: 'delete-gift'
}

export const QUERY_KEYS = {
    subscribed_guests: 'subscribed-guests',
    subscribed_hosts: 'subscribed-hosts',
    subscriptions_amounts: 'subscriptions-amounts'
}
