import {useMutation, useQueryClient} from '@tanstack/react-query'
import {MUTATION_KEYS, QUERY_KEYS} from './keys'
import {CHAT_API} from '../services/chat.http'

export const usePauseConnection = (onSuccess?: () => void) => {
    const queryClient = useQueryClient()
    return useMutation({
        mutationKey: [MUTATION_KEYS.pause_connection],
        mutationFn: ({channelID}: {channelID: string}) => CHAT_API.pauseConnection(channelID),
        onSuccess: () => {
            queryClient.invalidateQueries({queryKey: [QUERY_KEYS.fetchChannelDetails]})
            queryClient.invalidateQueries({queryKey: [QUERY_KEYS.fetchChannels]})
            onSuccess?.()
        }
    })
}
