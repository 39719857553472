import {forwardRef, InputHTMLAttributes} from 'react'

import {StyledInput, StyledInputRoot, StyledOptionLabel} from './style'
import {DefaultTheme, FlattenSimpleInterpolation} from 'styled-components'

export interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
    label?: string
    name?: string
    value: string | number
    checked?: boolean
    radioInputStyles?: (theme: DefaultTheme) => FlattenSimpleInterpolation
}

const Radio = forwardRef<HTMLInputElement, InputProps>(
    ({label, name, value, disabled, checked, radioInputStyles, ...rest}, ref) => {
        return (
            <StyledInputRoot radioInputStyles={radioInputStyles}>
                <StyledInput
                    type="radio"
                    id={`${value}`}
                    value={value}
                    name={name}
                    disabled={disabled}
                    checked={checked}
                    ref={ref}
                    {...rest}
                />
                <StyledOptionLabel htmlFor={`${value}`} disabled={disabled}>
                    {label}
                </StyledOptionLabel>
            </StyledInputRoot>
        )
    }
)

export default Radio
