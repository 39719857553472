export const MUTATION_KEYS = {
    save_host: 'save-host',
    unsave_host: 'unsave_host',
    renew_subscription: 'renew_subscription'
}

export const QUERY_KEYS = {
    host: 'host',
    subscriptions_to_renew: 'subscriptions-to-renew',
    subscriptions_to_renew_credit_card: 'subscriptions-to-renew-credit-card',
    saved_hosts: 'saved_hosts',
    ages: 'ages'
}
