import {debounce} from 'lodash'
import {useState, useEffect} from 'react'

function getWindowDimensions() {
    const {innerWidth: width, innerHeight: height} = window
    return {
        // prevent rounding error
        width: width - 1,
        height: height - 1
    }
}

export default function useWindowDimensions() {
    const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions())

    useEffect(() => {
        const debouncedHandleResize = debounce(handleResize, 300)
        function handleResize() {
            if (!document.querySelector('.plyr')) setWindowDimensions(getWindowDimensions())
        }

        window.addEventListener('resize', debouncedHandleResize)
        return () => window.removeEventListener('resize', debouncedHandleResize)
    }, [])

    return windowDimensions
}
