import {QUERY_KEYS} from '@/features/authentication/queries/keys'
import {GeneralError} from '@/features/authentication/types'
import {useMutation, useQueryClient} from '@tanstack/react-query'
import {AxiosResponse} from 'axios'
import {TOP_FAN_API} from '../services/topFan.http'
import {MUTATION_KEYS} from './keys'

export const useChangeBadgeVisibility = () => {
    const queryClient = useQueryClient()
    return useMutation<AxiosResponse, GeneralError, boolean>({
        mutationKey: [MUTATION_KEYS.change_badge_visibility],
        mutationFn: TOP_FAN_API.changeBadgeVisibility,
        onSuccess: () => {
            queryClient.invalidateQueries({queryKey: [QUERY_KEYS.user]})
        }
    })
}
