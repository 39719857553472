import {MicromorgiIcon, PurpleStar} from '@/assets/icons/icons'
import {Channel} from '@/features/chat/types'
import {useRootStore} from '@/store'
import {AnimationPlaces} from '@/store/createAnimationTriggersSlice'
import {selectAnimationsStore, selectTopFanCompleteModal, selectTopFanPendingModal} from '@/store/selectors'
import {replaceThousands} from '@/utilities/helpers'
import noAvatar from '@assets/images/noAvatar.png'
import {FC} from 'react'
import {Trans, useTranslation} from 'react-i18next'
import {useNavigate} from 'react-router-dom'
import {
    StyledAvatar,
    StyledAvatarWrapper,
    StyledIconWrapper,
    StyledModal,
    StyledModalTitle,
    StyledPositionNext,
    StyledPositionNow,
    StyledProgress,
    StyledProgressAmountNext,
    StyledProgressInfoWrapper,
    StyledProgressWrapper,
    StyledQuestionMarkIcon,
    StyledScrollContainer,
    StyledSubmitButton,
    StyledTopFanBadge,
    StyledTopFanCompleteModalWrapper,
    StyledTopFanPerksBadge,
    StyledTopFanPerksList,
    StyledTopFanPerksTitle,
    StyledTopFanPerksTitleWrapper,
    StyledTopFanPerksWrapper,
    StyledUsername
} from './style'

export const TopFanCompleteModal: FC = () => {
    const {t} = useTranslation()
    const {closeModal, channel} = useRootStore(selectTopFanCompleteModal)
    const {playAnimation} = useRootStore(selectAnimationsStore)
    const navigate = useNavigate()

    const amountToReach = channel?.top_fans?.amount_to_reach ?? channel?.top_fans_infos?.min_amount_to_reach
    const percentage =
        !!channel?.top_fans && !!amountToReach ? (+channel?.top_fans?.micromorgi_amount / +amountToReach) * 100 : 0

    const onRedirectToProfile = () => {
        navigate(`/${channel?.username}`)
        closeModal()
    }
    const onClickSubmit = () => {
        playAnimation({key: AnimationPlaces.GiftPanel, delay: 3000})
        closeModal()
    }

    if (!channel) return <></>
    return (
        <StyledModal maxWidth={'400px'} maxHeight="557px" height="90%" onClose={closeModal}>
            <StyledTopFanCompleteModalWrapper>
                <StyledScrollContainer>
                    <StyledModalTitle>
                        <Trans
                            t={t}
                            i18nKey="top_fan_info_modal:title_first"
                            values={{
                                ROOKIE_NAME: channel.full_name
                            }}
                            components={{
                                span: <StyledUsername onClick={onRedirectToProfile} />
                            }}
                        />
                    </StyledModalTitle>
                    <StyledModalTitle> {t('top_fan_info_modal:title_second')}</StyledModalTitle>

                    <TopFanAvatar url={channel.avatar?.url ?? null} />
                    <TopFanProgress position={channel.top_fans?.position} percentage={percentage} />
                    <TopFanProgressInfo
                        micromorgiNow={channel.top_fans?.micromorgi_amount ?? 0}
                        amountToReach={Number(amountToReach)}
                        showNextAmount={channel.top_fans?.position !== 1}
                    />
                    <TopFanPerks channel={channel} />
                </StyledScrollContainer>
                <StyledSubmitButton variant="primaryOutlined" onClick={onClickSubmit}>
                    <>
                        <MicromorgiIcon height={33} width={33} />
                        {t('top_fan_info_modal:button')}
                    </>
                </StyledSubmitButton>
            </StyledTopFanCompleteModalWrapper>

            <TopFanBackgroundIcons />
        </StyledModal>
    )
}

type TopFanPerksProps = {channel: Channel}
const TopFanPerks: FC<TopFanPerksProps> = ({channel}) => {
    const {t} = useTranslation()
    const {openModal} = useRootStore(selectTopFanPendingModal)
    return (
        <StyledTopFanPerksWrapper>
            <StyledTopFanPerksTitleWrapper>
                <StyledTopFanPerksBadge />
                <StyledTopFanPerksTitle>{t('top_fan_info_modal:info_header_title')}</StyledTopFanPerksTitle>
                <StyledQuestionMarkIcon
                    width={20}
                    height={20}
                    onClick={() => {
                        openModal({channel})
                    }}
                />
            </StyledTopFanPerksTitleWrapper>
            <StyledTopFanPerksList>
                <span>{t('top_fan_info_modal:info_item_1')}</span>
                <span>{t('top_fan_info_modal:info_item_2')}</span>
                <span>{t('top_fan_info_modal:info_item_3')}</span>
            </StyledTopFanPerksList>
        </StyledTopFanPerksWrapper>
    )
}

type TopFanAvatarProps = {url: string | null}
const TopFanAvatar: FC<TopFanAvatarProps> = ({url}) => {
    return (
        <StyledAvatarWrapper>
            <StyledAvatar src={url || noAvatar} />
            <StyledTopFanBadge />
        </StyledAvatarWrapper>
    )
}

type TopFanProgressProps = {position?: number; percentage: number}
const TopFanProgress: FC<TopFanProgressProps> = ({position, percentage}) => {
    return (
        <StyledProgressWrapper>
            <StyledPositionNow>{position}</StyledPositionNow>
            <StyledProgress percentage={position === 1 ? 100 : percentage} />
            {!!position && position !== 1 && <StyledPositionNext>{position - 1}</StyledPositionNext>}
        </StyledProgressWrapper>
    )
}

type TopFanProgressInfoProps = {micromorgiNow: number; amountToReach: number; showNextAmount?: boolean}
const TopFanProgressInfo: FC<TopFanProgressInfoProps> = ({amountToReach, micromorgiNow, showNextAmount = true}) => {
    return (
        <StyledProgressInfoWrapper>
            <MicromorgiIcon width={16} height={16} />
            {`${replaceThousands(micromorgiNow, true)} `}
            {showNextAmount && (
                <StyledProgressAmountNext>{`/ ${replaceThousands(amountToReach, true)}`}</StyledProgressAmountNext>
            )}
        </StyledProgressInfoWrapper>
    )
}

const TopFanBackgroundIcons: FC = () => {
    return (
        <StyledIconWrapper>
            <PurpleStar />
            <PurpleStar />
            <PurpleStar />
            <PurpleStar />
            <PurpleStar />
            <PurpleStar />
            <PurpleStar />
        </StyledIconWrapper>
    )
}
