import {Channel, StoreChannel} from '@/features/chat/types'
import {Lens} from '@dhmk/zustand-lens'

type TopFanPendingModalState = {
    isOpen: boolean
    channel?: Channel | StoreChannel | null
}

type TopFanPendingModalActions = {
    openModal: (params: Omit<TopFanPendingModalState, 'isOpen'>) => void
    closeModal: () => void
}

export type TopFanPendingModal = TopFanPendingModalState & TopFanPendingModalActions

const initialModal: TopFanPendingModalState = {isOpen: false, channel: null}

const createTopFanPendingModalSlice: Lens<TopFanPendingModal> = set => ({
    ...initialModal,
    openModal: params => set(() => ({...params, isOpen: true})),
    closeModal: () => set(() => ({...initialModal}))
})

export default createTopFanPendingModalSlice
