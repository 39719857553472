import styled, {css} from 'styled-components'

export const StyledSubTitle = styled.h2`
    ${({theme: {typography}}) => css`
        ${typography.textXXl};
        margin: 0 auto;
        text-align: center;
        font-weight: 400;
        line-height: 27px;
        letter-spacing: -0.05em;
    `}
`
