// Submit setError (from react use form) if used in a form and need to highlight field error.
// If the page cannot be displayed due to an error, submit isBlankPage
// In all other cases, our standard alert will be displayed

//TODO not final version
import {useRootStore} from '@/store'
import {selectAlertsStore} from '@/store/selectors'
import {AppStatusesE} from '@/utilities/constants/appStatus'
import {GeneralError, ValidationError, getErrorMessage} from '@/utilities/getAPIErrorMessage'
import {useEffect} from 'react'
import {FieldError} from 'react-hook-form'

type Field<T extends string> = `${T}`

type handleApiErrorProps<T extends string> = {
    isBlankPage?: boolean
    setError?: (field: Field<T>, arg: FieldError, config: {shouldFocus: boolean}) => void
    error: ValidationError | GeneralError | null
    isError: boolean
    shouldFocus?: boolean
}

export const useHandleError = <T extends string>({
    error,
    isBlankPage,
    setError,
    isError,
    shouldFocus = false
}: handleApiErrorProps<T>) => {
    const {addAlert} = useRootStore(selectAlertsStore)

    const errorsList = error?.response?.data.message || error?.response?.data

    useEffect(() => {
        if (isError && error) {
            const message = getErrorMessage(error)
            if (isBlankPage) {
                throw error
            } else if (errorsList && typeof errorsList === 'object' && setError) {
                Object.entries(errorsList).forEach(([field, err]) => {
                    const message = err[0]
                    setError(
                        field as Field<T>,
                        {
                            type: 'backend_error',
                            message
                        },
                        {
                            shouldFocus
                        }
                    )
                })
            } else {
                // General API errors
                return addAlert({
                    message,
                    variant: AppStatusesE.FAILURE,
                    dismissTimeout: 5000
                })
            }
        }
    }, [isError])
}
