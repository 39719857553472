import styled, {css} from 'styled-components'

export const RootContainer = styled.div`
    ${({theme: {spacing, mediaQueries}}) => css`
        min-height: ${spacing * 23}px;
        position: relative;
        margin: 0 auto;

        ${mediaQueries.l} {
            min-height: ${spacing * 36}px;
        }
    `}
`

export const StyledTelegramBanner = styled.div`
    ${({theme: {spacing, mediaQueries}}) => css`
        padding: ${spacing * 2.5}px ${spacing * 3}px;
        background: linear-gradient(180deg, #8f90fe 0%, #8b71ea 50.31%, #864bd2 100%);
        box-shadow: 0px 2px 18px rgba(0, 6, 61, 0.15);
        min-height: ${spacing * 18.75}px;
        text-align: center;
        border-radius: ${spacing}px;

        ${mediaQueries.l} {
            padding: ${spacing * 4}px ${spacing * 5}px;
            min-height: ${spacing * 30}px;
        }
    `}
`

export const StyledTelegramIconWrapper = styled.div`
    ${({theme: {palette, mediaQueries, spacing}}) => css`
    left: 50%;
    width: ${spacing * 13.75}px;
    border: ${spacing}px solid ${palette.neutral.white};
    bottom: -${spacing * 8}px;
    cursor: pointer;
    height: ${spacing * 13.75}px;
    display: flex;
    position: absolute;
    transform: translate(-50%, -50%);
    background:  ${palette.neutral.white};
    align-items: center;
    border-radius: ${spacing * 3.5}px;
    justify-content: center;
    ${mediaQueries.l} {
        width: ${spacing * 20}px;
        border: 11px solid ${palette.neutral.white};
        bottom: -${spacing * 10.75}px;
        height: ${spacing * 20}px;
    }

        &:after {
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            content: '';
            position: absolute;
            box-shadow: 0px 2px 18px rgba(0, 6, 61, 0.15;
            border-radius: ${spacing * 3}px;
        }

        
    `}
`

export const StyledTextContainer = styled.div`
    ${({theme: {palette, mediaQueries, typography, spacing, truncateText}}) => css`
        color: ${palette.neutral.white};

        & h4 {
            ${typography.textLg};
            text-align: center;
            font-weight: 600;
            line-height: ${spacing * 2.75}px;
            letter-spacing: -0.02em;
            text-transform: uppercase;
            ${truncateText};

            ${mediaQueries.l} {
                ${typography.text4Xl};
                line-height: 45px;
            }
        }

        & p {
            margin: ${spacing / 2}px 0 0 0;
            opacity: 0.7;
            ${typography.textSm};
            line-height: ${spacing * 2}px;
            letter-spacing: -0.02em;

            ${mediaQueries.l} {
                ${typography.textLg};
                margin: ${spacing}px 0 0 0;
            }
        }
    `}
`

export const StyledTelegramIconContainer = styled.div`
    ${({theme: {palette, mediaQueries, spacing}}) => css`
        width: 96px;
        height: 96px;
        display: flex;
        padding: ${spacing * 1.25}px ${spacing * 2.5}px;
        background: linear-gradient(180deg, #8f90fe 0%, #8b71ea 50.31%, #864bd2 100%);
        align-items: center;
        border-radius: ${spacing * 2.5}px;
        flex-direction: column;
        justify-content: center;

        ${mediaQueries.l} {
            width: 150px;
            height: 139px;
        }

        & svg {
            & path {
                fill: ${palette.neutral.white};
            }
        }

        & :first-child {
            width: 55px;
            height: 50px;

            ${mediaQueries.l} {
                width: ${spacing * 10}px;
                height: 70px;
            }
        }

        & :last-child {
            width: ${spacing * 2}px;
            height: 12px;

            ${mediaQueries.l} {
                width: 37px;
                height: 30px;
            }
        }
    `}
`
