import {InputStatusIconWrapper, StyledVisibilityToggle} from '@components/ui/input-status-icon/style.ts'
import {EyeClosedIcon, EyeOpenIcon, InputCheckIcon, InputWarningIcon} from '@/assets/icons/icons'
import {Dispatch, ReactNode, SetStateAction} from 'react'

interface InputStatusIconProps {
    hasError?: boolean
    touched?: boolean
    visibilityToggle: boolean
    icon?: ReactNode
    eyeVisible: boolean
    setEyeVisible: Dispatch<SetStateAction<boolean>>
    showErrorIcon: boolean
}
export const InputStatusIcon = ({
    hasError,
    showErrorIcon,
    touched,
    visibilityToggle,
    eyeVisible,
    setEyeVisible,
    icon
}: InputStatusIconProps) => {
    return (
        <InputStatusIconWrapper hasError={hasError}>
            {icon && !hasError && icon}
            {visibilityToggle && (
                <StyledVisibilityToggle onClick={() => setEyeVisible(!eyeVisible)}>
                    {eyeVisible ? <EyeOpenIcon /> : <EyeClosedIcon />}
                </StyledVisibilityToggle>
            )}
            {hasError && showErrorIcon && <InputWarningIcon />}
            {touched && !hasError && <InputCheckIcon />}
        </InputStatusIconWrapper>
    )
}

InputStatusIcon.displayName = 'InputStatusIcon'
