import {ChannelID} from '@/features/chat/types.ts'
import {useRootStore} from '@/store'
import {selectChatChannels, selectChatConversations} from '@/store/selectors'

const useChat = () => {
    const conversations = useRootStore(selectChatConversations)
    const channels = useRootStore(selectChatChannels)
    return {conversations, channels}
}

export default useChat

export const useChannelMessages = (channelID: ChannelID) => {
    const conversations = useRootStore(selectChatConversations)
    return conversations[channelID]
}
