import React, {useEffect, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {AddToScreenIcon, LogoSimple, PlusIcon, PressShareButtonIcon, SafariIcon} from '@/assets/icons/icons'

import {
    StyledFirstText,
    StyledHint,
    StyledOverlay,
    StyledPlusIconWrapper,
    StyledRoot,
    StyledRootForSafari,
    StyledTextSection
} from './style'
import useHomeScreenBannerVisibility from '@/hooks/useHomeScreenBannerVisibility'

interface BeforeInstallPromptEvent extends Event {
    prompt?(): Promise<void>
}

const AddMorgiToHomeScreenBanner = () => {
    const {t} = useTranslation()
    const {isHomeScreenBannerVisible, onClose} = useHomeScreenBannerVisibility()
    const [promptInstall, setPromptInstall] = useState<BeforeInstallPromptEvent | null>(null)
    const [isNotSupport, setIsNotSupport] = useState(false)

    useEffect(() => {
        const handler = (e: BeforeInstallPromptEvent) => {
            e.preventDefault()
            setPromptInstall(e)
        }

        if (!promptInstall) {
            window.addEventListener('beforeinstallprompt', e => handler(e))
        }

        return () => window.removeEventListener('beforeinstallprompt', handler)
    }, [])

    if (!isHomeScreenBannerVisible) return null

    const isSafari =
        window.navigator.vendor.match(/apple/i) &&
        !window.navigator.userAgent.match(/crios/i) &&
        !window.navigator.userAgent.match(/fxios/i) &&
        !window.navigator.userAgent.match(/Opera|OPT\//)

    const onClick = (evt: React.MouseEvent) => {
        evt.preventDefault()

        if (!promptInstall) {
            return setIsNotSupport(true)
        }

        if (promptInstall.prompt) {
            promptInstall.prompt()
        }
    }

    return (
        <>
            <StyledOverlay onClick={onClose} />
            {isNotSupport ? (
                <StyledRootForSafari>
                    {!isSafari && (
                        <StyledHint>
                            <SafariIcon />
                            <span>{t('add_morgi_to_home_screen:hint_open_safari')}</span>
                        </StyledHint>
                    )}
                    <StyledHint>
                        <PressShareButtonIcon />
                        <span>{t('add_morgi_to_home_screen:hint_share')}</span>
                    </StyledHint>
                    <StyledHint>
                        <AddToScreenIcon />
                        <span>{t('add_morgi_to_home_screen:hint_add')}</span>
                    </StyledHint>
                </StyledRootForSafari>
            ) : (
                <StyledRoot>
                    <div>
                        <LogoSimple />
                    </div>
                    <StyledTextSection>
                        <StyledFirstText>{t('add_morgi_to_home_screen:description_1')}</StyledFirstText>
                        <span>{t('add_morgi_to_home_screen:description_2')}</span>
                    </StyledTextSection>
                    <StyledPlusIconWrapper onClick={onClick}>
                        <PlusIcon />
                    </StyledPlusIconWrapper>
                </StyledRoot>
            )}
        </>
    )
}

export default AddMorgiToHomeScreenBanner
