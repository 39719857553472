import {GoalType} from '@/features/goal/types'
import {HostProofSection} from './host-proof-section/HostProofSection'
import {GuestProofSection} from './guest-proof-section/GuestProofSection'

export type ProofSectionType = {
    goal?: GoalType
    isHost?: boolean
    translatedProofNote?: JSX.Element
}

export const ProofSection = ({goal, isHost, translatedProofNote}: ProofSectionType) => {
    return isHost ? (
        <HostProofSection goal={goal} />
    ) : (
        <GuestProofSection goal={goal} translatedProofNote={translatedProofNote} />
    )
}
