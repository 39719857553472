import styled, { css } from "styled-components";

type ExpertiseIconsProps = {
    width: number
    height: number
}

export const StyledExpertiseIcon = styled.img<ExpertiseIconsProps>`
    ${({width, height}) => css`
        width: ${width}px;
        height: ${height}px;
    `}
`