import {NotificationsTypesE} from '../../services/notifications.schema'
import {
    StyledAlertIcon,
    StyledConnectionIcon,
    StyledCouponIcon,
    StyledDollarIcon,
    StyledGiftIcon,
    StyledInfoIcon,
    StyledLuckyMatchIcon,
    StyledMorgiAppreciateIcon,
    StyledPauseGiftIcon,
    StyledTelegramIcon,
    StyledTickIcon,
    StyledTopFanCongratulationIcon
} from './style'

type NotificationIconProps = {
    type: string
    old?: boolean
    color?: string
}

const NotificationIcon = ({type, old, color}: NotificationIconProps) => {
    switch (type) {
        case NotificationsTypesE.INFO:
        case NotificationsTypesE.GOAL:
            return <StyledInfoIcon old={old ? 'true' : undefined} color={color} width={24} height={24} />
        case NotificationsTypesE.ALERT:
            return <StyledAlertIcon old={old ? 'true' : undefined} width={24} height={24} color={color} />
        case NotificationsTypesE.CONGRATULATIONS:
            return <StyledTickIcon old={old ? 'true' : undefined} width={24} height={24} color={color} />
        case NotificationsTypesE.GIFT:
            return <StyledGiftIcon old={old ? 'true' : undefined} width={24} height={24} color={color} />
        case NotificationsTypesE.APPRECIATE:
            return <StyledMorgiAppreciateIcon old={old ? 'true' : undefined} width={24} height={24} color={color} />
        case NotificationsTypesE.RENEW:
            return <StyledDollarIcon old={old ? 'true' : undefined} width={24} height={24} color={color} />
        case NotificationsTypesE.COUPON:
            return <StyledCouponIcon old={old ? 'true' : undefined} width={24} height={24} color={color} />
        case NotificationsTypesE.TELEGRAM:
            return <StyledTelegramIcon old={old ? 'true' : undefined} width={24} height={24} color={color} />
        case NotificationsTypesE.PAUSE:
            return <StyledPauseGiftIcon old={old ? 'true' : undefined} width={24} height={24} color={color} />
        case NotificationsTypesE.CONNECTION:
            return <StyledConnectionIcon old={old ? 'true' : undefined} width={24} height={24} color={color} />
        case NotificationsTypesE.FIRST_MESSAGE:
            return <StyledLuckyMatchIcon old={old ? 'true' : undefined} width={24} height={24} color={color} />
        case NotificationsTypesE.TOP_FAN_CONGRATULATIONS:
            return (
                <StyledTopFanCongratulationIcon old={old ? 'true' : undefined} width={24} height={24} color={color} />
            )
        default:
            return <StyledInfoIcon old={old ? 'true' : undefined} width={24} height={24} fill="#394A64" />
    }
}

export default NotificationIcon
