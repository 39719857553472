import {useTranslation} from 'react-i18next'
import {StyledContainer, StyledContent, StyledRoot} from './style'
import {useEffect} from 'react'
import {CloseCircleIcon} from '@/assets/icons/icons'
import useRedirectToChatChannel from '@/hooks/useRedirectToChatChannel'

type AlertType = {
    hostName: string
    onClose: () => void
    hostId: string
}

export const FirstDonationBanner = ({onClose, hostName, hostId}: AlertType) => {
    const {t} = useTranslation()
    const redirectToChatChannel = useRedirectToChatChannel({receiverId: parseInt(`${hostId}`)})

    useEffect(() => {
        const timeoutId = setTimeout(() => {
            onClose()
        }, 3000)
        return () => {
            clearTimeout(timeoutId)
        }
    }, [])

    return (
        <StyledRoot onClick={redirectToChatChannel}>
            <StyledContainer>
                <StyledContent>
                    <h3>{t('goal_page:alert_open_chat:chat_unlocked')}</h3>
                    <div>
                        {t('goal_page:alert_open_chat:alert_text', {
                            ROOKIENAME: hostName
                        })}
                    </div>
                </StyledContent>

                <CloseCircleIcon
                    onClick={e => {
                        e.stopPropagation()
                        onClose()
                    }}
                />
            </StyledContainer>
        </StyledRoot>
    )
}
