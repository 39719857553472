import {Lens} from '@dhmk/zustand-lens'

type ReachedLimitModalState = {
    isOpen: boolean
}

type ReachedLimitModalActions = {
    openModal: (params: Omit<ReachedLimitModalState, 'isOpen'>) => void
    closeModal: () => void
}

export type ReachedLimitModal = ReachedLimitModalState & ReachedLimitModalActions

const initialModal: ReachedLimitModalState = {isOpen: false}

const createReachedLimitModalSlice: Lens<ReachedLimitModal> = set => ({
    ...initialModal,
    openModal: params => set(() => ({...params, isOpen: true})),
    closeModal: () => set(() => ({...initialModal}))
})

export default createReachedLimitModalSlice
