import {NavLink} from 'react-router-dom'
import styled, {DefaultTheme, css} from 'styled-components'

export const StyledWrapper = styled(NavLink)`
    ${({$forSubMenu, theme: {spacing, palette, typography}}: {$forSubMenu: boolean; theme: DefaultTheme}) => css`
        display: grid;
        grid-template-columns: ${spacing * 4}px 1fr;
        grid-template-rows: ${spacing * 4}px;
        grid-column-gap: ${spacing / 2}px;
        align-items: center;
        padding: ${spacing * 2}px 0 ${spacing * 2}px ${spacing * 2}px;
        text-decoration: none;
        font-weight: 700;
        ${typography.textSm};
        border-left: 3px solid transparent;
        color: ${palette.primary[900]};
        &.active {
            border-left-color: ${palette.primary[200]};
            background-color: ${palette.primary[300]};
        }
        cursor: pointer;
        border-bottom: 1px solid ${palette.primary[50]};
        & svg {
            max-width: 32px;
        }
        ${$forSubMenu &&
        css`
            font-weight: 400;
            border-bottom: none;
        `}
    `}
`

export const StyledTitle = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    grid-column-start: 2;
    height: 100%;
    & > * {
        max-height: 100%;
        width: 100%;
    }
`
