import styled, {DefaultTheme, css} from 'styled-components'

export const StyledTopic = styled.div`
    ${({theme: {spacing, palette}, isSelected}: {theme: DefaultTheme; isSelected: boolean}) => css`
        display: flex;
        align-items: center;
        gap: ${spacing / 2}px;
        color: ${isSelected ? palette.neutral.white : palette.primary['900']};
        border: 1px solid ${palette.primary['900']};
        cursor: pointer;
        margin: ${spacing / 2}px ${spacing / 2}px ${spacing / 2}px 0px;
        display: flex;
        padding: ${spacing}px ${spacing * 2}px;
        min-width: 80px;
        background: ${isSelected ? palette.primary['900'] : palette.neutral.white};
        align-items: center;
        font-weight: 700;
        border-radius: 100px;
        justify-content: center;
        line-height: 100%;

        & svg {
            fill: ${isSelected && palette.neutral.white};

            & path {
                stroke: ${isSelected && palette.neutral.white};
            }
        }
    `}
`
