import {useMe} from '@/features/authentication/queries/useMe'
import {useEffect} from 'react'
import {useGetChatTopics} from '../queries/useGetChatTopics'
import {useRootStore} from '@/store'
import {selectGlobalTranslateStore} from '@/store/selectors'
import {useTranslation} from 'react-i18next'
import {USER_ROLE_GUEST, USER_ROLE_HOST, USER_ROLE_REPRESENTATIVE} from '@/utilities/constants/user'

export const useGetAllTopics = () => {
    const {translateToggle} = useRootStore(selectGlobalTranslateStore)
    const {data: user} = useMe()
    const {data: chatFavoriteTopicsData, refetch: refetchChatFavoriteTopics} = useGetChatTopics(true)
    const {data: chatUnfavoriteTopicsData, refetch: refetchChatUnfavoriteTopics} = useGetChatTopics(false)
    const chatFavoriteTopics = chatFavoriteTopicsData ?? []
    const chatUnfavoriteTopics = chatUnfavoriteTopicsData ?? []
    const {
        i18n: {language}
    } = useTranslation()

    useEffect(() => {
        if (
            (translateToggle && user?.type === USER_ROLE_GUEST) ||
            [USER_ROLE_HOST, USER_ROLE_REPRESENTATIVE].includes(user?.type as string) ||
            !user
        ) {
            refetchChatFavoriteTopics()
            refetchChatUnfavoriteTopics()
        }
    }, [language, translateToggle])

    return {
        topics: [...chatFavoriteTopics, ...chatUnfavoriteTopics]
    }
}
