import * as RadixTabs from '@radix-ui/react-tabs'
import styled, {DefaultTheme, css} from 'styled-components'

export const StyledTabsList = styled(RadixTabs.List)`
    ${({$tabsCount}: {$tabsCount: number}) => css`
        display: grid;
        grid-template-columns: repeat(${$tabsCount}, 1fr);
        text-align: center;
    `}
`
export const StyledTabsTrigger = styled(RadixTabs.Trigger)`
    ${({
        $hasNextTab,
        $hasPrevTab,
        theme: {flexUtils, spacing, palette}
    }: {
        $hasNextTab: boolean
        $hasPrevTab: boolean
        theme: DefaultTheme
    }) => css`
        padding: ${spacing * 1.5}px ${spacing * 2}px;
        ${flexUtils.centered};
        gap: ${spacing / 2}px;
        font-weight: 700;
        cursor: pointer;
        &[data-state='inactive'] {
            border-bottom: 1px solid ${palette.primary[750]};
        }
        &[data-state='active'] {
            color: ${palette.primary[200]};
            & > svg {
                & > path {
                    fill: ${palette.primary[200]};
                }
                & > g > path {
                    stroke: ${palette.primary[200]};
                }
            }
            border: 1px solid ${palette.primary[750]};
            border-bottom: none;
            border-radius: 10px 10px 0px 0px;
            position: relative;

            z-index: 1;

            &::after,
            &::before {
                content: ' ';
                width: 16px;
                height: 8px;
                bottom: 0;
                position: absolute;
                z-index: 2;
                content: '';
            }

            ${$hasPrevTab &&
            css`
                &::after {
                    left: -8px;
                    background: radial-gradient(
                        circle at 0% 0%,
                        ${palette.neutral.white} 7px,
                        ${palette.primary[750]} 7px,
                        ${palette.primary[750]} 8px,
                        ${palette.neutral.white} 8px
                    );
                }
            `}
            ${$hasNextTab &&
            css`
                &::before {
                    right: -8px;
                    background: radial-gradient(
                        circle at 100% 0%,
                        ${palette.neutral.white} 7px,
                        ${palette.primary[750]} 7px,
                        ${palette.primary[750]} 8px,
                        ${palette.neutral.white} 8px
                    );
                }
            `}
        }
    `}
`
