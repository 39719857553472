import {Animation} from '@/components/ui/animation/Animation'
import {Button} from '@/components/ui/button/Button'
import {Modal} from '@/components/ui/modal/Modal'
import {useRootStore} from '@/store'
import {selectGoalDeletionSuccessModal} from '@/store/selectors'
import {ROUTE_MY_GOALS} from '@/utilities/constants/routeNames'
import Check from '@assets/animations/Check.json'
import {useTranslation} from 'react-i18next'
import {useNavigate} from 'react-router-dom'
import {StyledAnimation, StyledBody, StyledButton, StyledText} from './style'

export const GoalDeletionSuccessModal = () => {
    const {t} = useTranslation()
    const navigate = useNavigate()
    const {closeModal} = useRootStore(selectGoalDeletionSuccessModal)

    const handleClose = () => {
        navigate(ROUTE_MY_GOALS)
        closeModal()
    }

    return (
        <Modal onClose={handleClose} maxHeight={'557px'} title={t('stop_goal_popup:title')}>
            <StyledBody>
                <div>
                    <StyledAnimation>
                        <Animation animationData={Check} />
                    </StyledAnimation>
                    <StyledText>
                        <span>{t('delete_goal_popup:subtitle')}</span>
                        <p>{t('delete_goal_popup:description')}</p>
                    </StyledText>
                </div>
                <StyledButton>
                    <Button variant="outlined" onClick={handleClose}>
                        {t('delete_goal_popup:button_text')}
                    </Button>
                </StyledButton>
            </StyledBody>
        </Modal>
    )
}
