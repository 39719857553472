import styled, {css} from 'styled-components'

export const StyledBody = styled.div`
    ${({theme: {spacing, mediaQueries}}) => css`
        width: 100%;
        height: 100%;
        padding: ${spacing * 3}px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        gap: ${spacing * 2}px;
        overflow-y: hidden;
        min-height: 417px;

        & button {
            width: auto;
        }

        ${mediaQueries.m} {
            gap: ${spacing * 3}px;
        }
    `}
`

export const StyledAnimation = styled.div`
    ${({theme: {spacing}}) => css`
        margin: ${spacing * 2.5}px auto;
        height: 100%;
        width: 100%;
        max-height: 120px;
        max-width: 120px;
    `}
`

export const StyledText = styled.div`
    ${({theme: {typography, palette, spacing}}) => css`
        text-align: center;
        & span {
            ${typography.textXXl}
        }

        & p {
            margin-top: ${spacing * 2}px;
            color: ${palette.primary['700']};
        }
    `}
`

export const StyledButtonSection = styled.div`
    ${({theme: {mediaQueries, spacing}}) => css`
        display: flex;
        flex-direction: column;
        gap: ${spacing * 2}px;

        ${mediaQueries.m} {
            gap: ${spacing * 3}px;
        }
    `}
`

export const StyledStopButton = styled.div`
    text-align: center;
    text-decoration: underline;
    font-weight: 600;
    cursor: pointer;
    width: auto;
`
