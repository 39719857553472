import {Button} from '@/components/ui/button/Button'
import styled, {DefaultTheme, css} from 'styled-components'

export const modalStyles = (theme: DefaultTheme) => css`
    background: ${theme.palette.gradient[125]};
    color: ${theme.palette.neutral.white};
    display: grid;
    grid-template-rows: 0fr auto;
`

export const StyledWrapper = styled.div`
    ${({theme: {palette, mediaQueries, spacing}}) => css`
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-evenly;
        height: 100%;
        text-align: center;

        padding: ${spacing * 2}px;
        gap: ${spacing * 4}px;
        & img {
            width: 277px;
            height: 295px;
        }

        overflow: auto;
        &::-webkit-scrollbar {
            background: ${palette.neutral.transparent};
            width: 5px;
            border-radius: 3px;
        }
        &::-webkit-scrollbar-thumb {
            background: ${palette.neutral[400]};
        }
        &::-webkit-scrollbar-track {
            margin-bottom: ${spacing}px;
        }
        &::-webkit-scrollbar-button:start {
            display: none;
        }
        &::-webkit-scrollbar-button:end {
            display: none;
        }

        ${mediaQueries.m} {
            & img {
                width: 229px;
                height: 230px;
            }
        }
    `}
`

export const StyledTitle = styled.span`
    ${({theme: {typography, palette}}) => css`
        ${typography.text2Xl}
        font-weight: 700;
        letter-spacing: -1.2px;
        & span {
            color: ${palette.danger[750]};
        }
    `}
`
export const StyledButton = styled(Button)`
    ${({theme: {spacing}}) => css`
        display: flex;
        gap: ${spacing}px;
        padding: ${spacing * 2}px ${spacing * 4}px;
        justify-content: center;
        & img {
            height: 24px;
            width: 23px;
        }
    `}
`
export const StyledSubTitle = styled.p`
    ${({theme: {typography}}) => css`
        ${typography.textXl}
        font-weight: 500;
        opacity: 0.8;
    `}
`
