import {Modal} from '@/components/ui/modal/Modal'
import {
    StyledBody,
    StyledItem,
    StyledPopoverArrow,
    StyledPopoverContent,
    StyledSubtitle,
    StyledText,
    StyledTextPhoneMessage,
    modalStyles
} from './style'
import {useTranslation} from 'react-i18next'
import {
    EmailShareButton,
    FacebookShareButton,
    TwitterShareButton,
    TelegramShareButton,
    WhatsappShareButton
} from 'react-share'
import {
    EmailIcon,
    FacebookIcon,
    LinkIcon,
    TelegramMonoIcon,
    TextMessageIcon,
    TwitterIcon,
    WhatsappMonoIcon
} from '@/assets/icons/icons'
import {GoalType} from '../../types'
import {getPublicUrl} from '@/utilities/helpers'
import {USER_ROLE_GUEST} from '@/utilities/constants/user'
import {useMe} from '@/features/authentication/queries/useMe'
import useCopyToClipboard from '@/hooks/useCopyToClipboard'
import {Popover, PopoverSideE} from '@/components/ui/popover/Popover'
import {useState} from 'react'

type ShareGoalModalType = {
    onClose: () => void
    goal: GoalType
}

export const ShareGoalModal = ({onClose, goal}: ShareGoalModalType) => {
    const {t} = useTranslation()
    const {data: user} = useMe()
    const [isOpenPopover, setIsOpenPopover] = useState(false)

    const onSuccess = () => {
        setIsOpenPopover(true)
        setTimeout(() => {
            setIsOpenPopover(false)
        }, 2000)
    }
    const {copyToClipboard} = useCopyToClipboard(onSuccess)

    const isGuest = user?.type === USER_ROLE_GUEST
    const isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent)
    const isMobile =
        /Android|webOS|iPhone|iPad|iPod|BlackBerry|BB|PlayBook|IEMobile|Windows Phone|Kindle|Silk|Opera Mini/i.test(
            navigator.userAgent
        )

    const url = `${getPublicUrl()}/goal/${goal.slug}-${goal.id}`
    const message = t('share_goal_modal:message')
    const messageForSamePlatform = isGuest
        ? t('share_goal_modal:message_for_same_platform_leader', {
              ROOKIE_NAME: goal?.rookie?.full_name,
              GOAL_NAME: goal?.name,
              GOAL_LINK: url
          })
        : t('share_goal_modal:message_for_same_platform_rookie', {
              GOAL_NAME: goal?.name,
              GOAL_LINK: url
          })

    const textViaSMSForAndroid = `sms:?body=${messageForSamePlatform}`
    const textViaSMSForIOS = `sms:;body=${messageForSamePlatform}`
    const emailSubject = t('share_goal_modal:email_subject')

    const mergedOptions = {
        facebook: true,
        twitter: true,
        telegram: true,
        whatsapp: true,
        textMessage: true,
        email: true,
        link: true
    }

    return (
        <Modal onClose={onClose} title={t('common:share')} modalStyles={modalStyles}>
            <StyledSubtitle>{t('share_goal_modal:title')}</StyledSubtitle>
            <StyledBody>
                {mergedOptions.facebook && (
                    <FacebookShareButton resetButtonStyle={false} url={url} hashtag={message}>
                        <StyledItem>
                            <FacebookIcon />
                            <StyledText>{t('share:facebook')}</StyledText>
                        </StyledItem>
                    </FacebookShareButton>
                )}
                {mergedOptions.twitter && (
                    <TwitterShareButton resetButtonStyle={false} url={`${url} ${message}`}>
                        <StyledItem>
                            <TwitterIcon />
                            <StyledText>{t('share:twitter')}</StyledText>
                        </StyledItem>
                    </TwitterShareButton>
                )}
                {mergedOptions.telegram && (
                    <TelegramShareButton resetButtonStyle={false} url={messageForSamePlatform}>
                        <StyledItem>
                            <TelegramMonoIcon />
                            <StyledText>{t('share:telegram')}</StyledText>
                        </StyledItem>
                    </TelegramShareButton>
                )}
                {mergedOptions.whatsapp && (
                    <WhatsappShareButton resetButtonStyle={false} url={messageForSamePlatform}>
                        <StyledItem>
                            <WhatsappMonoIcon />
                            <StyledText>{t('share:whatsapp')}</StyledText>
                        </StyledItem>
                    </WhatsappShareButton>
                )}
                {mergedOptions.textMessage && isMobile && (
                    <StyledItem>
                        <TextMessageIcon />
                        <StyledTextPhoneMessage>
                            <a href={isIOS ? textViaSMSForIOS : textViaSMSForAndroid}>{t('share:text_message')}</a>
                        </StyledTextPhoneMessage>
                    </StyledItem>
                )}
                {mergedOptions.email && (
                    <EmailShareButton resetButtonStyle={false} url={messageForSamePlatform} subject={emailSubject}>
                        <StyledItem>
                            <EmailIcon />
                            <StyledText>{t('share:email')}</StyledText>
                        </StyledItem>
                    </EmailShareButton>
                )}
                {mergedOptions.link && (
                    <Popover
                        isModal
                        rootProps={{
                            open: isOpenPopover
                        }}
                        trigger={
                            <StyledItem onClick={() => copyToClipboard(url)}>
                                <LinkIcon />
                                <StyledText>{t('share_goal_modal:copy_goal_link')}</StyledText>
                            </StyledItem>
                        }
                        triggerProps={{asChild: false}}
                        side={PopoverSideE.top}
                    >
                        <StyledPopoverContent>
                            <StyledPopoverArrow width={14} height={13} />
                            {t('share:tooltip_content')}
                        </StyledPopoverContent>
                    </Popover>
                )}
            </StyledBody>
        </Modal>
    )
}
