type DataLayerType = {
    event?: string
    email?: string
    fullName?: string
    userName?: string
    userId?: number
    country?: string | number
    currency?: string
    micromorgiBalance?: number
    userType?: string
    pageTitle?: string
    pagePath?: string
    currentUrl?: string
    transactionId?: string
    transactionAmount?: number
}

type TagManagerType = {
    gtm: (args: { id: string }) => {
        noScript: () => HTMLElement
        script: () => HTMLScriptElement
    }
    initialize: (options: { gtmId: string; onload?: () => void }) => void
    dataLayer: (dataLayer: DataLayerType, dataLayerName?: string) => void
}

type WindowDataLayer = {
    [key: string]: unknown
}

export const TagManager: TagManagerType = {
    gtm: function (args) {
        const snippets = Snippets.tags(args)

        const script = () => {
            const scriptElement = document.createElement('script')
            scriptElement.innerHTML = snippets.script
            return scriptElement
        }

        const noScript = () => {
            const noscriptElement = document.createElement('noscript')
            noscriptElement.innerHTML = snippets.iframe
            return noscriptElement
        }

        return { noScript, script }
    },
    initialize: function ({ gtmId, onload }) {
        const gtm = this.gtm({ id: gtmId })
        document.head.appendChild(gtm.script())
        document.body.insertBefore(gtm.noScript(), document.body.childNodes[0])
        if (typeof onload === 'function') {
            onload()
        }
    },
    dataLayer: (dataLayer, dataLayerName = 'dataLayer') => {
        const myWindow = window as unknown as WindowDataLayer
        const currentDataLayer = myWindow[dataLayerName] as unknown[]
        currentDataLayer.push(dataLayer)
    },
}

const Snippets = {
    tags: function ({ id }: { id: string }) {
        if (!id) console.warn('[gtm]', 'GTM Id is required')

        const script = `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
            new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
            j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
            'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
            })(window,document,'script','dataLayer','${id}');`

        const iframe = `<iframe src="https://www.googletagmanager.com/ns.html?id=${id}"
                      height="0" width="0" style="display:none;visibility:hidden"></iframe>`

        return { iframe, script }
    },
}
