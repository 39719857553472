import {combinationList} from '@/features/moengage/components/combinations'
import TopHeaderBannerConnector from '@/features/moengage/components/connectors/top-header-banner-connector/TopHeaderBannerConnector'
import {QUERY_KEYS} from '@/features/moengage/queries/keys'
import {useGetMoengageCampaign} from '@/features/moengage/queries/useGetMoengageCampaign'
import {ConnectorNamesE} from '@/features/moengage/services/moengage.model'
import {useQueryClient} from '@tanstack/react-query'
import {ReactNode, createContext, useContext, useEffect, useState} from 'react'
import {useLocation} from 'react-router-dom'

const MoengageContext = createContext<{
    moengageCombinationUI: null | JSX.Element
    combinationName?: string | null
    closeHandler: (() => void) | null
}>({
    moengageCombinationUI: null,
    combinationName: null,
    closeHandler: null
})

export const useMoengageCombinationUI = () => useContext(MoengageContext)

const MoengageProvider = ({children}: {children: ReactNode}) => {
    const queryClient = useQueryClient()
    const {data: combination} = useGetMoengageCampaign()
    const [component, setComponent] = useState<JSX.Element | null>(null)
    const [isOpen, setIsOpen] = useState(true)
    const location = useLocation()
    const locationName = location?.pathname === '/' ? 'home-page' : location.pathname.replace(/\//g, '')
    const isAllowedOnPage =
        combination?.connector?.page_to_present === locationName ||
        !!combination?.connector?.page_to_present?.includes(locationName) ||
        !!combination?.connector?.page_to_present?.includes('all')

    useEffect(() => {
        if (isAllowedOnPage) {
            if (combination?.dynamic_template && combination.name) {
                const ContainerComponent = combinationList[combination.name]
                setComponent(
                    ContainerComponent ? (
                        <ContainerComponent data={combination} closeHandler={() => setIsOpen(false)} />
                    ) : null
                )
            } else if (combination?.connector?.name === ConnectorNamesE.banner_top_header) {
                setComponent(<TopHeaderBannerConnector data={combination} closeHandler={() => setIsOpen(false)} />)
            }
            setIsOpen(true)
        } else {
            setComponent(null)
        }
    }, [combination, locationName])

    useEffect(() => {
        queryClient.invalidateQueries({queryKey: [QUERY_KEYS.moengage_campaign]})
    }, [locationName])

    return (
        <MoengageContext.Provider
            value={{
                moengageCombinationUI: isOpen ? component : null,
                combinationName: combination?.name || null,
                closeHandler: () => setIsOpen(false)
            }}
        >
            {children}
        </MoengageContext.Provider>
    )
}

export default MoengageProvider
