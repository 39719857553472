import {spacing} from '@/theme/theme'
import {DefaultTheme, css} from 'styled-components'
import {GoalStatusesE} from '../../types'

const ColorStyles = (theme: DefaultTheme, status?: GoalStatusesE) => {
    switch (status) {
        case GoalStatusesE.PROOF_STATUS_DECLINED:
        case GoalStatusesE.SUSPENDED:
        case GoalStatusesE.AWAITING_PROOF:
            return css`
                background-color: rgba(223, 48, 48, 0.1);
                border: 1px solid rgba(223, 48, 48);
                & svg {
                    & path {
                        fill: #df3030;
                    }
                }
            `
        case GoalStatusesE.SUCCESSFUL:
            return css`
                background-color: ${theme.palette.success[700]};
                border: 1px solid ${theme.palette.success[700]};
                & svg {
                    & path {
                        fill: #00955f;
                    }
                }
            `

        case GoalStatusesE.REVIEW:
        case GoalStatusesE.PROOF_PENDING_APPROVAL:
        case GoalStatusesE.PENDING:
        default:
            return css`
                background-color: rgba(252, 159, 33, 0.1);
                border: 1px solid rgba(252, 159, 33);
                & svg {
                    & path {
                        fill: #fc9f21;
                    }
                }
            `
    }
}

export const tagStyles = (theme: DefaultTheme, status?: GoalStatusesE) => css`
    ${theme.typography.textSm};
    width: fit-content;
    background: ${theme.palette.success[700]};
    margin-bottom: ${spacing * 2}px;
    cursor: pointer;
    ${ColorStyles(theme, status)}
`
