import {convertToDayjs} from '@/dayjs'
import {padStart} from 'lodash'
import {Fragment, useEffect, useState} from 'react'

interface CountdownTimerProps {
    targetDate: string
    showDays?: boolean
    showHours?: boolean
    showMinutes?: boolean
    showSeconds?: boolean
    extraMaskDays?: string
    extraMaskHours?: string
    extraMaskMinutes?: string
    extraMaskSeconds?: string
}

interface TimeRemaining {
    days: number
    hours: number
    minutes: number
    seconds: number
}

export const CountdownTimer: React.FC<CountdownTimerProps> = ({
    targetDate,
    showDays = true,
    showHours = true,
    showMinutes = true,
    showSeconds = true,
    extraMaskDays,
    extraMaskHours,
    extraMaskMinutes,
    extraMaskSeconds
}) => {
    const [timeRemaining, setTimeRemaining] = useState<TimeRemaining>(calculateTimeRemaining())

    useEffect(() => {
        const intervalId = setInterval(() => {
            setTimeRemaining(calculateTimeRemaining())
        }, 1000)

        return () => clearInterval(intervalId)
    }, [])

    function calculateTimeRemaining(): TimeRemaining {
        const now = convertToDayjs()
        const target = convertToDayjs(targetDate)
        const difference = target.diff(now)

        if (difference <= 0) {
            return {days: 0, hours: 0, minutes: 0, seconds: 0}
        }

        const days = Math.floor(difference / (1000 * 60 * 60 * 24))
        const hours = Math.floor((difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60))
        const minutes = Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60))
        const seconds = Math.floor((difference % (1000 * 60)) / 1000)

        return {days, hours, minutes, seconds}
    }

    const formatTime = (value: number, showValue: boolean) => {
        return showValue ? padStart(value.toString(), 2, '0') : ''
    }

    return (
        <div>
            {showDays && (
                <Fragment>
                    {timeRemaining.days}
                    {extraMaskDays}:
                </Fragment>
            )}
            {showHours && (
                <Fragment>
                    {formatTime(timeRemaining.hours, showHours)}
                    {extraMaskHours}
                    {showMinutes || showSeconds ? ':' : ''}
                </Fragment>
            )}
            {showMinutes && (
                <Fragment>
                    {formatTime(timeRemaining.minutes, showMinutes)}
                    {extraMaskMinutes}
                    {showSeconds ? ':' : ''}
                </Fragment>
            )}
            {showSeconds && (
                <Fragment>
                    {formatTime(timeRemaining.seconds, showSeconds)} {extraMaskSeconds}
                </Fragment>
            )}
        </div>
    )
}
