import {Button} from '@/components/ui/button/Button'
import {useTranslation} from 'react-i18next'
import Success from '@/assets/animations/Success.json'
import {Animation} from '@/components/ui/animation/Animation'
import {
    StyledAnimationBox,
    StyledSuccessContainer,
    StyledSuccessSubTitle,
    StyledSuccessSubTitleBox,
    StyledSuccessTitle,
    StyledSuccessTitleBox
} from './style'
import {useRootStore} from '@/store'
import {selectReachedLimitModal} from '@/store/selectors'
import {MicromorgiPackage} from '@/features/gift/types'

export const SuccessStep = ({selectedOption}: {selectedOption?: MicromorgiPackage}) => {
    const {t} = useTranslation()
    const {closeModal} = useRootStore(selectReachedLimitModal)
    return (
        <StyledSuccessContainer>
            <StyledAnimationBox>
                <Animation animationData={Success} />
            </StyledAnimationBox>
            <div>
                <StyledSuccessTitleBox>
                    <StyledSuccessTitle>{t('buy_micromorgies:success:title')}</StyledSuccessTitle>
                </StyledSuccessTitleBox>

                <StyledSuccessSubTitleBox>
                    <StyledSuccessSubTitle>
                        {t('buy_micromorgies:success:description', {
                            AMOUNT: selectedOption?.micromorgi_count
                        })}
                    </StyledSuccessSubTitle>
                </StyledSuccessSubTitleBox>
            </div>

            <div>
                <Button onClick={closeModal}> {t('common:continue')}</Button>
            </div>
        </StyledSuccessContainer>
    )
}
