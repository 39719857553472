import styled, {css, keyframes} from 'styled-components'

const shine = keyframes`
    100% {
        background-position-x: -200%;
    }
`

export const StyledBanner = styled.div`
    ${({theme: {spacing, palette}}) => css`
        color: ${palette.neutral.white};
        display: flex;
        padding: ${spacing * 2}px 13px 27px 13px;
        background: linear-gradient(180deg, #8f90fe 0%, #8b71ea 50.31%, #864bd2 100%);
        box-shadow: 0px 2px 18px rgba(0, 6, 61, 0.15);
        align-items: center;
        border-radius: ${spacing}px;
        flex-direction: column;
        margin: 0 auto;
    `}
`
export const StyledTitleContainer = styled.div`
    ${({theme: {typography, mediaQueries}}) => css`
        font-size: ${typography.textLg};
        font-weight: 700;
        line-height: 140%;
        letter-spacing: -0.02em;

        ${mediaQueries.l} {
            ${typography.text3Xl};
        }
    `}
`
export const StyledCardAction = styled.div`
    ${({theme: {typography, mediaQueries}}) => css`
        ${typography.textMd};
        line-height: 140%;
        letter-spacing: -0.02em;

        & > span {
            text-decoration: underline;
            cursor: pointer;
        }

        ${mediaQueries.l} {
            ${typography.text2Xl}
        }
    `}
`
export const StyledCardBody = styled.div`
    ${({theme: {typography, mediaQueries, spacing}}) => css`
        width: 100%;
        display: flex;
        ${typography.textSm};
        margin-top: ${spacing * 3}px;
        line-height: 130%;
        justify-content: center;

        ${mediaQueries.l} {
            ${typography.textXXl};
        }
    `}
`
export const StyledPreloadCard = styled.div`
    width: 90%;
    display: flex;
    position: relative;
`
export const StyledPreloadCardPhoto = styled.div`
    ${({theme: {spacing, mediaQueries}}) => css`
        width: 107px;
        height: 136px;
        animation: 3.5s ${shine} linear infinite;
        background: linear-gradient(
            110deg,
            rgb(236, 236, 236, 0.5) 8%,
            rgb(245, 245, 245, 0.8) 18%,
            rgb(236, 236, 236, 0.5) 33%
        );
        margin-right: ${spacing}px;
        border-radius: ${spacing}px;
        background-size: 450% 100%;

        ${mediaQueries.l} {
            width: 166px;
            height: 212px;
        }
    `}
`
export const StyledPreloadHeartContainer = styled.div`
    ${({theme: {spacing, mediaQueries, palette}}) => css`
        right: 0;
        position: absolute;

        & > svg {
            ${mediaQueries.l} {
                height: 41px;
                width: 41px;
            }

            & > path {
                fill: ${palette.neutral.white};
                opacity: 0.2;
            }
        }

        ${mediaQueries.m} {
            right: ${spacing}px;
        }
    `}
`
export const StyledPreloadCardInfo = styled.div`
    ${({theme: {spacing, mediaQueries}}) => css`
        width: 65%;
        display: flex;
        min-width: 207px;
        margin-top: ${spacing}px;
        margin-left: ${spacing}px;
        flex-direction: column;

        & span {
            background: linear-gradient(
                110deg,
                rgb(236, 236, 236, 0.5) 8%,
                rgb(245, 245, 245, 0.8) 18%,
                rgb(236, 236, 236, 0.5) 33%
            );
            animation: 2.5s ${shine} linear infinite;
            border-radius: ${spacing / 2}px;
            display: block;
            margin-bottom: 12px;
            background-size: 200% 100%;

            &:nth-child(1) {
                width: 126px;
                height: 15.75px;
            };
            &:nth-child(2) {
                width: 191px;
                height: 13.3px;
            };
            &:nth-child(3){
                width: ${spacing * 4}px;
                height: ${spacing * 2}px;
            };
            &:last-child {
                width: 118px;
                height: 28px;
                align-self: end;
                margin-bottom: 0;
                margin-top: ${spacing * 2}px;
                border-radius: 35px;
                ${mediaQueries.l}{
                    width: 184px;
                    height: 44px;
                    margin-top: 77px;
                }
        }
    `}
`
