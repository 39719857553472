import {InputText} from '@/components/commons/input-text/InputText'
import {Modal} from '@/components/ui/modal/Modal'
import {Spinner} from '@/components/ui/spinner/Spinner'
import {useMe} from '@/features/authentication/queries/useMe'
import {useGetLanguages} from '@/features/country/queries/useGetLanguages'
import {LanguagesResponseType, LanguagesType} from '@/features/country/types'
import {useRootStore} from '@/store'
import {selectGlobalTranslateStore, selectSelectGlobalLanguageModal} from '@/store/selectors'
import {debounce} from 'lodash'
import {ChangeEvent, FC, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {StyledLabel, StyledLanguage, StyledLanguagesList, StyledSelectGlobalLanguageModalContainer} from './style'

export const SelectGlobalLanguageModal: FC = () => {
    const {t} = useTranslation()
    const {closeModal} = useRootStore(selectSelectGlobalLanguageModal)
    const [search, setSearch] = useState('')
    const {currentLanguage, initialTranslateConfig} = useRootStore(selectGlobalTranslateStore)
    const {data: languagesList, isLoading} = useGetLanguages(true)
    const {data: user} = useMe()
    const spokenLanguage = user?.spoken_languages[0]
    const filterLanguagesList = (list: LanguagesResponseType = []) =>
        list?.filter(lang => lang.name.toLowerCase().includes(search.toLowerCase())) || []

    const onChangeSearch = debounce((e: ChangeEvent<HTMLInputElement>) => setSearch(e.target.value), 300)
    const onSelectHandler = (lang: LanguagesType) => {
        initialTranslateConfig({currentLanguage: lang})
        closeModal()
    }
    const languageList = filterLanguagesList(languagesList)

    return (
        <Modal title={t('chat:choose_language_modal:title')} minHeight="300px" onClose={closeModal}>
            <StyledSelectGlobalLanguageModalContainer>
                {!isLoading ? (
                    <>
                        <InputText
                            name="input"
                            type="text"
                            placeholder={t('chat:choose_language_modal:placeholder')}
                            onChange={onChangeSearch}
                        />
                        <StyledLanguagesList>
                            {languageList.length ? (
                                languageList.map(lang => (
                                    <StyledLanguage
                                        key={lang.id}
                                        $isSelected={lang.id === (currentLanguage || spokenLanguage)?.id}
                                        onClick={() =>
                                            lang.id !== (currentLanguage || spokenLanguage)?.id && onSelectHandler(lang)
                                        }
                                    >
                                        {lang.name}
                                    </StyledLanguage>
                                ))
                            ) : (
                                <StyledLabel>{t('chat:choose_language_modal:no_serch_results')}</StyledLabel>
                            )}
                        </StyledLanguagesList>
                    </>
                ) : (
                    <Spinner />
                )}
            </StyledSelectGlobalLanguageModalContainer>
        </Modal>
    )
}
