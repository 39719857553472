import {useMe} from '@/features/authentication/queries/useMe'
import {useEffect} from 'react'
import {useGetFavoriteActivities} from '../queries/useGetFavoriteActivities'
import {useRootStore} from '@/store'
import {selectGlobalTranslateStore} from '@/store/selectors'
import {useTranslation} from 'react-i18next'
import {USER_ROLE_GUEST, USER_ROLE_HOST, USER_ROLE_REPRESENTATIVE} from '@/utilities/constants/user'

export const useGetAllActivities = () => {
    const {data: user} = useMe()
    const {translateToggle} = useRootStore(selectGlobalTranslateStore)
    const {data: chatFavoriteActivitiesData, refetch: refetchChatFavoriteActivities} = useGetFavoriteActivities(true)
    const {data: chatUnfavoriteActivitiesData, refetch: refetchChatUnfavoriteActivities} =
        useGetFavoriteActivities(false)
    const chatFavoriteActivities = chatFavoriteActivitiesData ?? []
    const chatUnfavoriteActivities = chatUnfavoriteActivitiesData ?? []
    const {
        i18n: {language}
    } = useTranslation()

    useEffect(() => {
        if (
            (translateToggle && user?.type === USER_ROLE_GUEST) ||
            [USER_ROLE_HOST, USER_ROLE_REPRESENTATIVE].includes(user?.type as string) ||
            !user
        ) {
            refetchChatFavoriteActivities()
            refetchChatUnfavoriteActivities()
        }
    }, [translateToggle, language])

    return {
        activities: [...chatFavoriteActivities, ...chatUnfavoriteActivities]
    }
}
