import {Animation} from '@/components/ui/animation/Animation'
import {StyledAnimation, StyledDescription, StyledSubtitle, StyledSuccessStepRoot, StyledTitle} from './style'
import Check from '@assets/animations/Check.json'
import useWindowDimensions from '@/hooks/useWindowDimensions'

type GoalSuccessStepProps = {
    title: string
    subtitle?: string
    description?: string | React.ReactNode
    children: React.ReactNode
}

export const GoalSuccessStep = ({title, subtitle, description, children}: GoalSuccessStepProps) => {
    const {height: vh} = useWindowDimensions()

    return (
        <StyledSuccessStepRoot vh={vh}>
            <div>
                <StyledAnimation>
                    <Animation animationData={Check} />
                </StyledAnimation>
                {title && <StyledTitle>{title}</StyledTitle>}
                {subtitle && <StyledSubtitle>{subtitle}</StyledSubtitle>}
                {description && <StyledDescription>{description}</StyledDescription>}
            </div>
            {children}
        </StyledSuccessStepRoot>
    )
}
