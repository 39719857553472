import {NumberObject} from '../types'

export const getNumbersFromString = (text: string): NumberObject[] => {
    let numbersArray: string[] = text?.match(/\d{6,}/g) || []
    numbersArray = [...new Set(numbersArray)]

    const numbersArrayWithObjects: NumberObject[] = numbersArray.map(number => {
        return {
            string: number,
            host: number,
            url: number,
            type: 'number'
        }
    })

    return numbersArrayWithObjects
}
