import {FC, useEffect, useMemo} from 'react'
import {useSeenMoengageCombination} from '../../queries/useSeenMoengageCombination'
import {MoengageObjectResponseType} from '../../types'
import CTA from '../CTA/CTA'
import {connectorsList} from '../connectors'
import {ConnectorNamesE} from '../../services/moengage.model'
import HostsProfileTemplate, {HostsProfileProps} from '../templates/host-profile-template/HostProfileTemplate'
import {generateGoalSlug} from '@/utilities/helpers'
import {getLocalDate, isPasteDate} from '@/dayjs'
import {pastGoalStatues} from '@/features/goal/constant/goal'
import {GoalType} from '@/features/goal/types'
import {HostPartialType} from '@/features/authentication/types'
import useWindowDimensions from '@/hooks/useWindowDimensions'

const templateList: {[key: string]: FC<HostsProfileProps>} = {
    [ConnectorNamesE.popup]: HostsProfileTemplate,
    [ConnectorNamesE.card]: HostsProfileTemplate
}

const HostProfileCombination = ({data, closeHandler}: {data: MoengageObjectResponseType; closeHandler: () => void}) => {
    const {name: connectorName, cta_button_text, cta_button_color} = data.connector
    const {width} = useWindowDimensions()
    const isMobile = useMemo(() => width <= 1024, [width])
    
    const isCarouselCard = connectorName === ConnectorNamesE.card

    //TODO get active goal
    let activeGoal: GoalType | undefined
    let host: HostPartialType | undefined
    if (data?.dynamic_template?.host_list?.length) {
        host = data.dynamic_template.host_list[0]
        const goalList =
            !!host.type_attributes && !!host.type_attributes.goals?.length
                ? (host.type_attributes.goals as GoalType[])
                : []

        if (goalList) {
            activeGoal = goalList.filter((goal: GoalType) => {
                const localEndDate = goal.end_date && getLocalDate(goal.end_date)
                const hasEnded = localEndDate && isPasteDate(localEndDate)

                return !pastGoalStatues.includes(goal.status) && !hasEnded
            })[0]
        }
    }

    const CtaComponent = () =>
        data.id ? (
            <CTA
                backgroundColor={cta_button_color}
                ctaText={cta_button_text}
                ctaType={data?.dynamic_template?.cta_type}
                promo={data?.dynamic_template?.promo}
                campaignId={data.id}
                goalSlug={generateGoalSlug(`${activeGoal?.slug}`, `${activeGoal?.id}`)}
                hostId={host?.id}
                small={isMobile}
            />
        ) : (
            <></>
        )

    const {mutate} = useSeenMoengageCombination()

    useEffect(() => {
        const timer = setTimeout(() => mutate({campaign_id: `${data?.id}`}), 3000)
        return () => clearTimeout(timer)
    }, [])

    if (connectorName) {
        const Template = templateList[connectorName]
        const Connector = connectorsList[connectorName]
        return data?.dynamic_template?.host_list && data?.dynamic_template?.host_list.length ? (
            <Connector
                closeHandler={closeHandler}
                {...data.connector}
                CtaComponent={isCarouselCard ? undefined : CtaComponent}
            >
                <Template
                    hostsList={data?.dynamic_template?.host_list}
                    promo={data?.dynamic_template?.promo}
                    CtaComponent={isCarouselCard ? CtaComponent : undefined}
                />
            </Connector>
        ) : null
    } else {
        return null
    }
}

export default HostProfileCombination
