import {MengageConnectorType, MoengageObjectResponseType} from '@/features/moengage/types'
import {getCorrectStringWithTranslation} from '@/utilities/helpers'
import {
    StyledBannerContainer,
    StyledCloseIconContainer,
    StyledDescription,
    StyledImageContainer,
    StyledTextContainer,
    StyledTitle
} from './style'
import {CloseIcon} from '@/assets/icons/icons'
import {useEffect} from 'react'
import {useSeenMoengageCombination} from '@/features/moengage/queries/useSeenMoengageCombination'

const TopHeaderBannerConnector = ({
    data,
    closeHandler
}: {
    data: MoengageObjectResponseType
    closeHandler: () => void
}) => {
    const {connector} = data
    const {element_color, image, header_title, header_title_color, description, description_color} = connector || {}
    const {mutate} = useSeenMoengageCombination()

    useEffect(() => {
        const timer = setTimeout(() => mutate({campaign_id: `${data?.id}`}), 3000)
        return () => clearTimeout(timer)
    }, [])

    return (
        <StyledBannerContainer elementColor={element_color}>
            {image && <StyledImageContainer image={image} />}
            <StyledTextContainer>
                {!!header_title && (
                    <StyledTitle titleColor={header_title_color}>
                        {getCorrectStringWithTranslation(header_title, false)}
                    </StyledTitle>
                )}
                {!!description && (
                    <StyledDescription descriptionColor={description_color}>
                        {getCorrectStringWithTranslation(description, false)}
                    </StyledDescription>
                )}
            </StyledTextContainer>
            <StyledCloseIconContainer onClick={closeHandler}>
                <CloseIcon />
            </StyledCloseIconContainer>
        </StyledBannerContainer>
    )
}

export const model = (connectorData: MengageConnectorType | null = null) => {
    if (!connectorData) return null
    return {
        image: connectorData['image'],
        element_color: connectorData['element_color'],
        header_title: connectorData['header_title'],
        header_title_color: connectorData['header_title_color'],
        description: connectorData['description'],
        description_color: connectorData['description_color'],
        page_to_present: connectorData['page_to_present'],
        timer: connectorData['timer']
    }
}

export default TopHeaderBannerConnector
