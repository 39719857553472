import {Button} from '@/components/ui/button/Button'
import {FC} from 'react'
import {StyledButtonBox, StyledDescription, StyledNotFoundContainer} from './style'
import useContainerDimensions from '@/hooks/useContainerDimensions'
import {useNavigate} from 'react-router-dom'
import {useTranslation} from 'react-i18next'
import {PageNotFoundIcon} from '@/assets/icons/icons'
import {ROUTE_HOME} from '@/utilities/constants/routeNames'

export const NotFound: FC = () => {
    const {height} = useContainerDimensions()
    const navigate = useNavigate()
    const {t} = useTranslation()
    return (
        <StyledNotFoundContainer height={height}>
            <PageNotFoundIcon />
            <div>
                <span>{t('common:page_not_found')}</span>
            </div>
            <StyledDescription>{t('pages:not_found:message')}</StyledDescription>
            <StyledButtonBox>
                <Button variant="outlined" onClick={() => navigate(ROUTE_HOME)}>
                    {t('pages:not_found:cta')}
                </Button>
            </StyledButtonBox>
        </StyledNotFoundContainer>
    )
}
NotFound.displayName = 'NotFound'
