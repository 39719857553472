import {FC, useEffect, useState} from 'react'
import Confetti, {ConfettiConfig} from 'react-dom-confetti'
import {useLocation} from 'react-router-dom'
import {ConfettiContainer} from './styled'
import {useMe} from '@/features/authentication/queries/useMe'
import {GuestType} from '@/features/authentication/types'
import {USER_ROLE_GUEST} from '@/utilities/constants/user'
import {LocalStorageManager} from '@/utilities/localStorage'

const animationDuration = 5000

const confettiConfig: ConfettiConfig = {
    angle: 90,
    spread: 180,
    startVelocity: 15,
    elementCount: 200,
    dragFriction: 0.03,
    duration: animationDuration,
    stagger: 2,
    width: '10px',
    height: '10px',
    colors: ['#FF4770', '#6D4CC9', '#FFC200', '#1AC7B8', '#7D57E6', '#FFA400', '#16AB9E']
}

const maxCashbackAnimationCount = 3
const maxUltimateStatusAnimationCount = 1
type ConfettiAnimationProps = {
    isModal?: boolean
}

const ConfettiAnimation: FC<ConfettiAnimationProps> = ({isModal = false}) => {
    const [confetti, setConfetti] = useState(false)
    const location = useLocation()
    const {data} = useMe()

    const user = data?.type === USER_ROLE_GUEST ? (data as GuestType) : null

    const cashbackAnimationCount = LocalStorageManager.counterConfettiForCashback.get()
    const ultimateStatusAnimationCount = LocalStorageManager.counterConfettiForUltimateStatus.get()
    const isShowAnimation =
        (cashbackAnimationCount && cashbackAnimationCount <= maxCashbackAnimationCount) ||
        (ultimateStatusAnimationCount && ultimateStatusAnimationCount <= maxUltimateStatusAnimationCount)

    useEffect(() => {
        const isUltimateStatusOfferAvailable = user?.type_attributes?.ultimate_offer?.is_ultimate_status_offer_available
        const isCashbackOfferAvailable = user?.type_attributes?.cashback_offer?.is_available_cashback_offer
        if (!isCashbackOfferAvailable) LocalStorageManager.counterConfettiForCashback.remove()
        else {
            if (Number(cashbackAnimationCount) <= maxCashbackAnimationCount)
                LocalStorageManager.counterConfettiForCashback.set(cashbackAnimationCount + 1)
        }
        if (!isUltimateStatusOfferAvailable) LocalStorageManager.counterConfettiForUltimateStatus.remove()
        else {
            if (Number(ultimateStatusAnimationCount) <= maxUltimateStatusAnimationCount)
                LocalStorageManager.counterConfettiForCashback.set(ultimateStatusAnimationCount + 1)
        }
    }, [location])

    useEffect(() => {
        const timer = setTimeout(() => {
            setConfetti(true)
        }, 1200)

        return () => {
            clearTimeout(timer)
        }
    }, [location])

    useEffect(() => {
        if (confetti) {
            const timer = setTimeout(() => {
                setConfetti(false)
            }, animationDuration)

            return () => clearTimeout(timer)
        }
    }, [confetti, location])
    if (!isShowAnimation) return <></>
    return (
        <ConfettiContainer visible={confetti} $isModal={isModal}>
            <Confetti active={confetti} config={confettiConfig} />
        </ConfettiContainer>
    )
}

export default ConfettiAnimation
