import {FC, ReactNode} from 'react'
import {DropzoneState} from 'react-dropzone'
import {StyledDropzone} from './style'
import {FlattenSimpleInterpolation} from 'styled-components'

interface DropzoneProps {
    children: ReactNode
    dropzoneState: DropzoneState
    dropzoneStyles?: () => FlattenSimpleInterpolation
}
export const Dropzone: FC<DropzoneProps> = ({children, dropzoneState, dropzoneStyles}) => {
    return (
        <StyledDropzone {...dropzoneState.getRootProps()} dropzoneStyles={dropzoneStyles}>
            <input {...dropzoneState.getInputProps()} />
            {children}
        </StyledDropzone>
    )
}

Dropzone.displayName = 'Dropzone'
