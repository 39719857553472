import {useTranslation} from 'react-i18next'
import {NotificationToast} from '../notificationToast/NotificationToast'
import {StyledNotification, StyledNotificationBox, StyledTitle} from '../style'
import {NotificationsType} from '../../types'

type NotificationsContainerProps = {
    notifications?: NotificationsType[]
    closeNotification: () => void
    old?: boolean
}

export const NotificationsContainer = ({notifications, closeNotification, old}: NotificationsContainerProps) => {
    const {t} = useTranslation()
    return (
        <>
            {!!notifications?.length && (
                <StyledNotificationBox>
                    <StyledTitle>
                        {old ? t('notifications:old_notifications') : t('notifications:new_notifications')}
                    </StyledTitle>
                    {notifications?.map(notification => {
                        if (!notification.content && !notification.campaign_notification) {
                            return null
                        } else {
                            return (
                                <StyledNotification old={old} key={notification.id}>
                                    <NotificationToast
                                        closeNotification={closeNotification}
                                        notification={notification}
                                        old={old}
                                    />
                                </StyledNotification>
                            )
                        }
                    })}
                </StyledNotificationBox>
            )}
        </>
    )
}
