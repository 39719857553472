import styled, {DefaultTheme, css} from 'styled-components'

export const StyledProofsMediaRoot = styled.div`
    ${({
        theme: {typography, spacing, mediaQueries},
        isSingleProof
    }: {
        theme: DefaultTheme
        isSingleProof: boolean
    }) => css`
        ${typography.textMd};
        margin-top: ${spacing * 2}px;
        display: grid;
        grid-template-columns: ${isSingleProof ? '1fr' : '1fr 1fr'};
        column-gap: ${spacing * 2}px;
        grid-template-rows: 72px;
        overflow: hidden;

        ${mediaQueries.m} {
            grid-template-rows: 100px;
        }
    `}
`

export const StyledMedia = styled.div`
    width: 100%;
    height: 100%;
    border-radius: 10px;
    overflow: hidden;
    position: relative;
    cursor: pointer;

    & > div {
        &:before {
            height: 100%;
            width: 100%;
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            bottom: 0;
            right: 0;
            background-color: rgba(0, 0, 0, 0.3);
            border-radius: 10;
        }
    }
`

export const StyledChildren = styled.span`
    ${({theme: {spacing, palette}}) => css`
        position: absolute;
        top: ${spacing}px;
        right: ${spacing}px;
        display: flex;
        gap: ${spacing / 2}px;
        color: ${palette.neutral.white};
        font-weight: 600;

        & svg {
            margin-top: 3px;
        }
    `}
`
