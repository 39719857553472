import {SendMessageProps} from '@/features/profile/components/connect-now-modal/components/SendFirstMessage.tsx'
import {useMemo, useState} from 'react'
import {useForm, useWatch} from 'react-hook-form'
import {useTranslation} from 'react-i18next'
import {zodResolver} from '@hookform/resolvers/zod'
import {Media} from '@components/ui/media/Media.tsx'
import ToggleSwitch from '@components/commons/toggleSwitch/ToggleSwitch.tsx'
import {MicromorgiIcon} from '@assets/icons/icons.tsx'
import {InputText} from '@components/commons/input-text/InputText.tsx'
import {Button} from '@components/ui/button/Button.tsx'
import {SelectedAmount} from '@/features/profile/components/connect-now-modal/types.ts'
import {
    StyledAmountCard,
    StyledAmountsContainer,
    StyledAmountsWrapper,
    StyledAvatarWrapper,
    StyledContainerInput,
    StyledContainerSwitch
} from '@/features/profile/components/connect-now-modal/style.ts'
import {Divider} from '@components/ui/divider/Divider.tsx'
import {Flexbox} from '@components/ui/flexbox/FlexBox.tsx'
import {Spinner} from '@components/ui/spinner/Spinner.tsx'
import {selectBuyMorgisModal, selectChoosePaymentsMethodModal, selectConnectModal} from '@/store/selectors.ts'
import {useRootStore} from '@/store'
import {z} from 'zod'
import {AxiosError} from 'axios'
import {computeAge, generateChatChannelName} from '@utilities/helpers.tsx'
import {useFetchMicromorgiPackages} from '@/features/gift/queries/useFetchMicromorgiPackages.ts'
import {useMe} from '@/features/authentication/queries/useMe.ts'
import {useSendFirstMessageToHost} from '@/features/profile/queries/useSendFirstMessageToHost.ts'
import {getErrorMessage, ValidationError} from '@utilities/getAPIErrorMessage.ts'
import {GeneralError, GuestType} from '@/features/authentication/types.ts'
import {PaymentMethodUrls} from '@/features/guest-payments/types.ts'
import {USER_ROLE_GUEST} from '@utilities/constants/user.ts'
import {MessageTypeE} from '@/utilities/constants/chat'
import {FIRST_PAID_MESSAGE_AMOUNTS} from '@/featuresConfig'
import useSendChatMessage from '@/features/chat/hooks/useSendChatMessage'

const amountForm = z.object({
    amount: z.coerce.number().int().min(1, {message: 'validation:enter_valid_number'}).nullable().optional()
})
export const SendFirstPaidMessage = ({...props}: SendMessageProps) => {
    const {host, message, triggerMixpanel} = props
    const {hostID, closeModal} = useRootStore(selectConnectModal)
    const [selectedAmount, setSelectedAmount] = useState<SelectedAmount>({})
    const birth_date = host?.type_attributes?.birth_date || ''
    const first_name = host?.type_attributes?.first_name || ''
    const {t} = useTranslation()
    const calculateAge = useMemo(() => computeAge(birth_date), [birth_date])
    const [toggleToChooseSupport, setToggleToChooseSupport] = useState(false)
    const isAmountSelected = Object.keys(selectedAmount).length === 0
    const {openModal: openChoosePaymentsMethodModal} = useRootStore(selectChoosePaymentsMethodModal)
    const {data} = useMe()
    const user = data?.type === USER_ROLE_GUEST ? (data as GuestType) : null
    const {mutateAsync, isPending} = useSendFirstMessageToHost(() => {
        closeModal()
    })
    const {openModal} = useRootStore(selectBuyMorgisModal)
    const {data: micromorgiPackages, isLoading: isLoadingMicromorgi} = useFetchMicromorgiPackages()
    const channel = generateChatChannelName(host?.id || 0, host?.type || '', user?.id || 0)
    const {sendChatMessage} = useSendChatMessage(channel)

    const {
        register,
        setValue,
        control,
        formState: {errors}
    } = useForm<z.infer<typeof amountForm>>({
        mode: 'all',
        reValidateMode: 'onBlur',
        resolver: zodResolver(amountForm),
        defaultValues: {
            amount: null
        }
    })

    const amountWatch = useWatch({
        control: control,
        name: 'amount'
    })

    const amounts = useMemo(() => {
        if (toggleToChooseSupport) {
            return FIRST_PAID_MESSAGE_AMOUNTS
        } else {
            return micromorgiPackages?.filter(({micromorgi_count}) => {
                return (
                    micromorgi_count === 100 ||
                    micromorgi_count === 250 ||
                    micromorgi_count === 500 ||
                    micromorgi_count === 1000
                )
            })
        }
    }, [toggleToChooseSupport, micromorgiPackages])

    const sendMessageWithMonthlySubscription = async (amountToSend: {subscription_package_id: number}) => {
        try {
            const payload = {
                message: message,
                hostId: Number(hostID),
                ...amountToSend
            }

            await mutateAsync(payload)
            triggerMixpanel()
        } catch (e) {
            const error = e as GeneralError | ValidationError
            if (!toggleToChooseSupport) {
                const errorStatus = (error as AxiosError).response?.status
                if (errorStatus === 303) {
                    openChoosePaymentsMethodModal({
                        paymentMethodUrls: error?.response?.data as PaymentMethodUrls,
                        isRecurring: true
                    })
                } else {
                    getErrorMessage(error)
                }
            }
        }
    }

    const handleSendMicromorgi = async (amount: {micromorgi_amount: number | string}) => {
        try {
            await mutateAsync({
                message: message,
                hostId: Number(hostID),
                micromorgi_amount: amount?.micromorgi_amount || ''
            })
            !!user &&
                (await sendChatMessage({
                    text: message,
                    type: MessageTypeE.MICROMORGI_TRANSACTION,
                    userId: user?.id,
                    micromorgiAmount: +amount?.micromorgi_amount,
                    needReInitChatBeforeSendToPubNub: true,
                    needSendToBackend: false
                }))
        } catch (error) {
            getErrorMessage(error as GeneralError | ValidationError)
        }
    }

    const handleClick = () => {
        if (toggleToChooseSupport) {
            const amountToSend = {micromorgi_amount: !isAmountSelected ? selectedAmount?.micromorgi_count : amountWatch}
            const myBalance = user?.type_attributes?.micro_morgi_balance || 0
            const amountSelected = amountToSend.micromorgi_amount || 0
            if (myBalance < amountSelected) {
                openModal({})
            } else {
                handleSendMicromorgi({micromorgi_amount: amountSelected})
            }
        } else {
            sendMessageWithMonthlySubscription({subscription_package_id: selectedAmount.id || 0})
        }
    }

    const onHandleToggle = () => {
        setToggleToChooseSupport(!toggleToChooseSupport)
        setSelectedAmount({})
    }

    return (
        <>
            {isPending || (isLoadingMicromorgi && <Spinner />)}
            <Flexbox direction={'column'} gap={2} align={'center'} padding={2}>
                <StyledAvatarWrapper gap={1} align={'center'}>
                    <Media className={'avatar'} imageUrl={host?.avatar?.url || ''} />
                    <p>
                        {first_name}, {calculateAge}
                    </p>
                </StyledAvatarWrapper>

                <StyledContainerSwitch gap={1} align={'center'} justify={'center'}>
                    <span>{t('pay_at_first_connection:monthly_support')}</span>
                    <ToggleSwitch
                        labelPosition={'left'}
                        isSmall
                        isLight
                        onClick={onHandleToggle}
                        checked={toggleToChooseSupport}
                    />
                    <span>{t('pay_at_first_connection:regular_support')}</span>
                </StyledContainerSwitch>

                <StyledAmountsContainer visible>
                    <p>{t('pay_at_first_connection:choose_amount')}</p>
                    <StyledAmountsWrapper>
                        {amounts?.map(item => {
                            const isActive = selectedAmount?.id === item?.id
                            return (
                                <StyledAmountCard
                                    align={'center'}
                                    justify={'center'}
                                    gap={0.5}
                                    isActive={isActive}
                                    key={item?.id}
                                    onClick={() => {
                                        if (isActive) {
                                            setSelectedAmount({})
                                        } else {
                                            setSelectedAmount(item)
                                        }
                                        setValue('amount', null)
                                    }}
                                >
                                    <MicromorgiIcon />
                                    <span>{item?.micromorgi_count}</span>
                                </StyledAmountCard>
                            )
                        })}
                    </StyledAmountsWrapper>
                </StyledAmountsContainer>

                <StyledAmountsContainer height={toggleToChooseSupport ? 'auto' : 100} visible={toggleToChooseSupport}>
                    <p>{t('pay_at_first_connection:other')}</p>

                    <StyledContainerInput>
                        <InputText
                            type={'number'}
                            placeholder={t('pay_at_first_connection:choose')}
                            errorMessage={errors.amount?.message}
                            {...register('amount')}
                            minLength={1}
                            onClick={() => {
                                setSelectedAmount({})
                            }}
                            onKeyDown={e => {
                                const target = e.target as HTMLInputElement // Type assertion
                                if (
                                    e.key === '-' ||
                                    e.key === 'e' ||
                                    e.key === '+' ||
                                    (e.key === '0' && target.value.length < 1) ||
                                    e.key === 'ArrowUp' ||
                                    e.key === 'ArrowDown'
                                ) {
                                    e.preventDefault()
                                }
                            }}
                        />
                    </StyledContainerInput>
                </StyledAmountsContainer>

                <Divider />

                <Button
                    disabled={
                        toggleToChooseSupport
                            ? !!((!amountWatch && isAmountSelected) || errors.amount)
                            : isAmountSelected
                    }
                    onClick={handleClick}
                >
                    {t('pay_at_first_connection:send')}
                </Button>
            </Flexbox>
        </>
    )
}
