import {Button} from '@/components/ui/button/Button'
import {StylePropsType} from '@/theme/style'
import styled, {DefaultTheme, css} from 'styled-components'

export const StyledRoot = styled.div`
    ${({ctaBarStyles, theme}: {ctaBarStyles?: StylePropsType; theme: DefaultTheme}) => css`
        display: grid;
        grid-template-columns: 0fr 1fr;
        z-index: 2;
        position: relative;
        align-items: center;
        column-gap: ${theme.spacing * 2}px;
        ${ctaBarStyles && ctaBarStyles(theme)}
    `}
`

export const StyledSaveButton = styled.div`
    ${({theme: {mediaQueries, palette, shadows}}) => css`
        border-radius: 100%;
        box-shadow: ${shadows.mdXl};
        background: ${palette.neutral.white};
        width: 50px;
        height: 50px;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        & svg {
            width: 20px;
        }
        ${mediaQueries.m} {
            & svg {
                width: 20px;
                height: 24px;
            }
        }
    `}
`

export const StyledConnectNowButton = styled(Button)`
    ${({gradient, theme: {palette, spacing}}: {gradient: boolean; theme: DefaultTheme}) => css`
        padding: ${spacing * 1.75}px 0px;
        color: ${palette.neutral.black};
        max-width: 220px;
        ${gradient &&
        css`
            background: linear-gradient(0deg, rgb(253, 204, 13) 40.28%, rgb(255, 226, 186) 88.19%);
            border: none;

            &:not(:disabled):hover {
                border: none;
            }
        `}
    `}
`
export const StyledSpinner = styled.span`
    & > div {
        position: absolute;
        top: 15px;
        left: 15px;
    }
`
