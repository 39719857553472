import {FC} from 'react'
import {StyledCashbackBanner, StyledTextContainer, StyledTimer} from './style'
import {MicromorgiIcon} from '@/assets/icons/icons'
import {Trans, useTranslation} from 'react-i18next'
import {GoalTimer} from './GoalTimer'
import {GuestType} from '@/features/authentication/types'
import {formatDateWithUTC} from '@/dayjs'

type CashbackCardProps = {
    user: GuestType
}

export const CashbackCard: FC<CashbackCardProps> = ({user}) => {
    const {t} = useTranslation()
    const endDate = user?.type_attributes?.cashback_offer?.end_date

    return (
        <StyledCashbackBanner>
            <MicromorgiIcon />
            <StyledTextContainer>
                <Trans i18nKey={'interactive_feed:cashback_card:text'} components={{br: <br />, span: <span></span>}} />
            </StyledTextContainer>
            <StyledTimer>
                {t('interactive_feed:cashback_card:time_left')}
                <GoalTimer endDate={(!!endDate && formatDateWithUTC(endDate, 'YYYY-MM-DD HH:mm:ss')) || ''} />
            </StyledTimer>
        </StyledCashbackBanner>
    )
}

CashbackCard.displayName = 'CashbackCard'
