import styled, {css} from 'styled-components'

export const StyledTopicsContent = styled.div`
    ${({vh}: {vh: number}) => css`
        height: 100%;
        max-height: calc(${vh}px - 260px);
        overflow-y: auto;
    `}
`

export const StyledTopicsList = styled.div`
    ${({theme: {spacing}}) => css`
        height: 100%;
        overflow: auto;
        margin: ${spacing * 2}px 0px;
        display: flex;
        flex-wrap: wrap;
    `}
`
