import {VerifiedHostIcon} from '@/assets/icons/icons'
import {useNavigate} from 'react-router-dom'
import {ROUTE_FAQ_GUEST} from '@/utilities/constants/routeNames'
import {Trans, useTranslation} from 'react-i18next'
import {useMemo} from 'react'
import {GenderKeyNameE} from '@/features/constants/constants'
import {StyledTootip, StyledTriggerWrapper} from './style'

type HostProfileVerificationIcon = {
    hostGenderId: number | undefined
    hostName: string | undefined
}

export const HostVerificationIcon = ({hostName, hostGenderId}: HostProfileVerificationIcon) => {
    const navigate = useNavigate()
    const {t} = useTranslation()

    const hostGender = useMemo(() => {
        switch (hostGenderId) {
            case 1:
                return GenderKeyNameE.FEMALE
            case 2:
                return GenderKeyNameE.MALE
            default:
                return GenderKeyNameE.OTHER
        }
    }, [hostGenderId])

    const handleClickVerify = () => {
        navigate(ROUTE_FAQ_GUEST)
    }

    return (
        <StyledTootip
            content={
                <Trans
                    t={t}
                    i18nKey="interactive_feed_rookie_card:verify_tooltip"
                    components={{
                        span: <span onClick={() => handleClickVerify()} />
                    }}
                    values={{
                        USER_NAME: hostName,
                        context: hostGender
                    }}
                />
            }
        >
            <StyledTriggerWrapper>
                <VerifiedHostIcon />
            </StyledTriggerWrapper>
        </StyledTootip>
    )
}

HostVerificationIcon.displayName = 'HostVerificationIcon'
