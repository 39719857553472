import {GoalStatusesE, GoalType} from '@/features/goal/types'
import {useTranslation} from 'react-i18next'
import {IconButton} from '../../../icon-button/IconButton'
import {GoalShareIcon} from '@/assets/icons/icons'
import GoalCountdown from '../../../goal-countdown/GoalCountdown'
import {StyledTimer, tagStyles} from './style'
import {Tag} from '@/components/ui/tag/Tag'
import {useTheme} from 'styled-components'
import {useMemo} from 'react'
import {tagContent} from '@/features/goal/constant/goalCard'

type HostStatusSectionProps = {
    goal?: Partial<GoalType>
    onShareClick?: () => void
}

export const HostStatusSection = ({goal, onShareClick}: HostStatusSectionProps) => {
    const {t} = useTranslation()
    const theme = useTheme()
    const showShareButton =
        goal?.status !== GoalStatusesE.PENDING &&
        goal?.status !== GoalStatusesE.SUSPENDED &&
        goal?.status !== GoalStatusesE.CANCELED
    const showTimer = goal?.status === GoalStatusesE.PENDING || goal?.status === GoalStatusesE.ACTIVE

    const tagData = useMemo(() => {
        switch (goal?.status) {
            case GoalStatusesE.CANCELED:
                return tagContent.fail
            case GoalStatusesE.SUSPENDED:
                return tagContent.suspended
            case GoalStatusesE.REVIEW:
                return tagContent.review
            default:
                return tagContent.success
        }
    }, [goal?.status])

    return (
        <>
            {showTimer ? (
                <StyledTimer>
                    <GoalCountdown endDate={goal?.end_date ?? ''} />
                </StyledTimer>
            ) : (
                <Tag
                    title={t(tagData?.title)}
                    tagStyles={() => tagStyles({theme: theme, background: tagData?.background})}
                />
            )}
            {showShareButton && <IconButton icon={<GoalShareIcon />} onClick={onShareClick} />}
        </>
    )
}

// !!!IMPORTANT - this rules only for HOST side!!!

// 1. If status "pending" - show only goal TIMER"
// 2. If status "active" - show TIMER and SHARE BUTTON
// 3. IF status "suspended" - show RED LABEL (text - suspended) and SHARE BUTTON
// 4. If status "review" - show RED LABEL (text - review) and SHARE BUTTON
// 5. IF status "awaiting_proof", "proof_pending_approval", "successful" - show GREEN LABEL (text - raised) and SHARE BUTTON
// 6. IF status "canceled" - show only RED LABEL (text - Not funded)
