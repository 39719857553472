import {Trans, useTranslation} from 'react-i18next'
import {GuestType} from '@/features/authentication/types.ts'
import {FC, useMemo} from 'react'
import {StyledBanner, StyledBannerText} from './style'

type MarketingCardProps = {
    index: number
    user: GuestType
}

export const MarketingCard: FC<MarketingCardProps> = ({user, index}) => {
    const {t} = useTranslation()

    const getPersonaText = useMemo(() => {
        /* if paying leader, show standard heading, else  show headings according to "persona" */
        const text = user?.total_subscriptions_count
            ? `home_carousel:random_headings_app:variant_${index}`
            : [
                  `rookie_card_personas:${user.persona}:${index}:title`,
                  `rookie_card_personas:${0}:${
                      // default persona is 7 --> 16/06/23 default changed to 0 by Ron
                      index
                  }:title`
              ]
        return <Trans t={t} i18nKey={text} components={{div: <div />}} />
    }, [t, user])

    return (
        <StyledBanner>
            <StyledBannerText>{getPersonaText}</StyledBannerText>
        </StyledBanner>
    )
}
