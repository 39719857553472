import ReactDOM from 'react-dom/client'
import App from './App.tsx'
import './translations/i18n.ts'
import {QueryClientProvider} from '@tanstack/react-query'
import {queryClient} from '@/queryClient.ts'
import swDev from './serviceWorkerDev'
import {Iubenda} from './iubenda.ts'
import {envVars} from './envVars.ts'
import PusherInstance from './pusher.ts'
import PusherProvider from './integrations/pusher/PusherProvider.tsx'
import {TagManager} from './integrations/tag-manager/tagManager.ts'

TagManager.initialize({
    gtmId: `${envVars.VITE_APP_GOOGLE_TAG_MANAGER_ID}`
})

Iubenda.initialize({
    siteID: `${envVars.VITE_APP_IUBENDA_SITE_ID}`,
    cookiePolicyID: `${envVars.VITE_APP_IUBENDA_COOKIE_POLICY_ID}`
})

const pusherInstance = PusherInstance.getInstance()

console.log('v:170520241720')

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
    <QueryClientProvider client={queryClient}>
        <PusherProvider pusher={pusherInstance}>
            <App />
        </PusherProvider>
    </QueryClientProvider>
)

swDev()
