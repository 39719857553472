import {useRootStore} from '@/store'
import {selectChatFavTopicsSetLanguagesMethod, selectChatFavTopicsSetTopicMethod} from '@/store/selectors'
import {useEffect} from 'react'
import {useGetAllActivities} from './useGetAllActivities'
import {useGetAllLanguages} from './useGetAllLanguages'
import {useGetAllTopics} from './useGetAllTopics'

export const useChatFavTopics = () => {
    const setTopics = useRootStore(selectChatFavTopicsSetTopicMethod)
    const setLanguages = useRootStore(selectChatFavTopicsSetLanguagesMethod)

    const {topics} = useGetAllTopics()
    const {activities} = useGetAllActivities()
    const {languages} = useGetAllLanguages()

    useEffect(() => {
        if (topics && activities) setTopics([...topics, ...activities])
    }, [topics, activities])

    useEffect(() => {
        if (languages) setLanguages(languages)
    }, [languages])

    return {}
}
