import MegaGiftImage from '@assets/images/supportMonthlyMegaGift.png'
import styled, {css} from 'styled-components'

export const StyledAmountWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 100%;
`

export const StyledMegaGiftAmount = styled.span`
    ${({theme: {typography, spacing}}) => css`
        ${typography.textSm}
        align-items: center;
        font-weight: 600;
        line-height: normal;
        margin-left: ${spacing / 2}px;
        letter-spacing: -0.7px;
    `}
`

export const StyledMegaGiftDescription = styled.span`
    ${({theme: {palette, typography, spacing}}) => css`
        ${typography.textSm}
        color: ${palette.neutral[50]};
        font-weight: 400;
        line-height: 140%;
        margin: 0 ${spacing / 2}px;
    `}
`
export const StyledMegaGiftDescriptionWrapper = styled.div`
    ${() => css`
        text-wrap: nowrap;
        display: flex;
        align-items: center;
    `}
`
export const StyledMegaGiftImage = styled.img.attrs(() => ({src: MegaGiftImage}))`
    ${() => css`
        width: 40px;
        height: 40px;
    `}
`

export const StyledRightPart = styled.div`
    ${({theme: {typography}}) => css`
        ${typography.textMd}
        max-width: 50px;
        text-align: center;
        font-weight: 700;
        line-height: normal;
        letter-spacing: -0.8px;
    `}
`
export const StyledMegaGiftTitle = styled.div`
    ${({theme: {typography, spacing}}) => css`
        ${typography.displayMd}
        font-weight: 700;
        line-height: normal;
        margin-left: ${spacing * 0.75}px;
        letter-spacing: -1.8px;
    `}
`
