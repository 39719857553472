import {GoalStatusesE, GoalType} from '@/features/goal/types'
import {StyledButtonSection, StyledProofNote, StyledProofSectionRoot, StyledText, StyledTitle} from './style'
import {useMemo} from 'react'
import {useTranslation} from 'react-i18next'
import {Button} from '@/components/ui/button/Button'
import {generatePath, useNavigate} from 'react-router-dom'
import {ROUTE_GOAL_PROOF} from '@/utilities/constants/routeNames'
import {ProofsMedia} from '../proofs-media/ProofsMedia'
import {hostProofData} from '@/features/goal/constant/goalProofs'

export const HostProofSection = ({goal}: {goal?: GoalType}) => {
    const {t} = useTranslation()
    const navigate = useNavigate()
    const showButtonSection =
        goal?.status === GoalStatusesE.AWAITING_PROOF || goal?.status === GoalStatusesE.PROOF_STATUS_DECLINED
    const showProofNoteAndMedia =
        goal?.status === GoalStatusesE.PROOF_PENDING_APPROVAL || goal?.status === GoalStatusesE.SUCCESSFUL

    const proofData = useMemo(() => {
        switch (goal?.status) {
            case GoalStatusesE.PENDING:
                return hostProofData[GoalStatusesE.PENDING]
            case GoalStatusesE.SUSPENDED:
                return hostProofData[GoalStatusesE.SUSPENDED]
            case GoalStatusesE.REVIEW:
                return hostProofData[GoalStatusesE.REVIEW]
            case GoalStatusesE.AWAITING_PROOF:
                return hostProofData[GoalStatusesE.AWAITING_PROOF]
            case GoalStatusesE.PROOF_PENDING_APPROVAL:
                return hostProofData[GoalStatusesE.PROOF_PENDING_APPROVAL]
            case GoalStatusesE.PROOF_STATUS_DECLINED:
                return hostProofData[GoalStatusesE.PROOF_STATUS_DECLINED]
            case GoalStatusesE.SUCCESSFUL:
                return hostProofData[GoalStatusesE.SUCCESSFUL]
            default:
                // status - active, canceled
                return null
        }
    }, [goal?.status])

    return proofData ? (
        <StyledProofSectionRoot backgroundColor={proofData?.backgroundColor}>
            <StyledTitle>
                {proofData?.icon}
                {t(proofData?.title)}
            </StyledTitle>
            {proofData?.text && !showButtonSection && <StyledText>{t(proofData?.text)}</StyledText>}
            {showProofNoteAndMedia && (
                <>
                    <StyledProofNote>{goal?.proof_note}</StyledProofNote>
                    <ProofsMedia proofs={goal?.proofs || []} />
                </>
            )}
            {showButtonSection && (
                <StyledButtonSection>
                    <Button onClick={() => navigate(generatePath(ROUTE_GOAL_PROOF, {goalId: `${goal?.id}`}))}>
                        {t('goal_page:proof:button_text')}
                    </Button>
                    <span>{t(proofData?.text)}</span>
                </StyledButtonSection>
            )}
        </StyledProofSectionRoot>
    ) : null
}
