import styled, {DefaultTheme, css} from 'styled-components'

export const StyledWrapper = styled.div`
    ${({
        withGradientMenuItem = false,
        isOpen,
        theme: {spacing, palette, typography}
    }: {
        isOpen: boolean
        withGradientMenuItem?: boolean
        theme: DefaultTheme
    }) => css`
        ${typography.textSm};
        display: grid;
        grid-template-columns: ${spacing * 4}px 1fr ${spacing * 2}px;
        grid-template-rows: ${spacing * 4}px;
        grid-column-gap: ${spacing / 2}px;
        align-items: center;
        padding: ${spacing * 2}px 0 ${spacing * 2}px ${spacing * 2}px;
        text-decoration: none;
        font-weight: 700;
        border-left: 3px solid transparent;
        cursor: pointer;
        color: ${palette.primary[900]};
        border-bottom: 1px solid ${palette.primary[50]};
        & svg {
            max-width: 32px;
        }
        ${withGradientMenuItem &&
        css`
            background: ${isOpen ? 'linear-gradient(90deg, #4CC9F0 -0.06%, #F72585 99%)' : 'none'};
            border-left-color: ${isOpen && 'rgb(76, 201, 240)'};
            color: ${isOpen && palette.neutral.white};
            & svg {
                fill: ${isOpen && palette.neutral.white};
            }
        `}
    `}
`

export const StyledTitle = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    grid-column-start: 2;
    height: 100%;
    & > * {
        max-height: 100%;
        width: 100%;
    }
`
export const StyledArrowDownIcon = styled.div`
    ${({isOpen, theme: {mediaQueries}}: {isOpen: boolean; theme: DefaultTheme}) => css`
        grid-column-start: 3;
        transform: ${isOpen && 'rotate(-180deg)'};
        transition: transform 0.25s ease-in;
        display: flex;
        align-items: center;
        justify-content: center;
        
        & svg {
            width: 16px;
            height: 16px;
        }

        ${mediaQueries.m} {
            padding-right: 1px;
        }
    `}
`
export const StyledListItems = styled.div`
    ${({listHeight, isOpen}: {listHeight: number; isOpen: boolean}) => css`
        padding: 0;
        display: grid;
        overflow: ${isOpen ? 'visible' : 'hidden'};
        max-height: ${isOpen ? `${listHeight}px` : 0};
        height: auto;
        opacity: ${isOpen ? 1 : 0};
        transition: all 0.15s ease-in-out;
        width: 100%;
        grid-auto-columns: 1fr;
        grid-auto-flow: row;
        align-items: center;
        justify-content: start;
        list-style: none;
    `}
`
