import styled, {css} from 'styled-components'

type StyledContainerProps = {
    fullWidth: boolean
}

export const StyledContainer = styled.div<StyledContainerProps>`
    ${({fullWidth, theme: {spacing, mediaQueries}}) => css`
        width: 100%;
        padding: 0 ${spacing * 1.5}px;
        max-width: ${fullWidth ? '100%' : '1980px'};
        margin: 0 auto;
        ${mediaQueries.m} {
            padding: 0 ${spacing * 2}px;
        }
    `}
`
