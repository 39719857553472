import axios from '@/clientAPI.ts'
import {GetAllHostTopFansPropsType} from '../queries/useGetAllHostTopFan'
import {GetAllTopFansPropsType} from '../queries/useGetAllTopFans'
import {TopFansListResponseType, TopFansPaginatedListResponseType} from '../types'
import {TopFansListResponseSchema, TopFansPaginatedListResponseSchema} from './topFan.schema'
import {AxiosResponse} from 'axios'

export const TOP_FAN_API = {
    getAllTopFans: async ({
        page,
        limit = 10,
        status,
        recent_pay
    }: GetAllTopFansPropsType): Promise<TopFansPaginatedListResponseType> => {
        const params = {page, limit, status, recent_pay}
        const url = `/top-fans`
        const {data} = await axios.get(url, {params})
        return TopFansPaginatedListResponseSchema.parse(data)
    },
    getAllHostTopFans: async ({
        page,
        limit,
        status,
        recent_pay,
        hostId
    }: GetAllHostTopFansPropsType): Promise<TopFansPaginatedListResponseType> => {
        const params = {page, limit, status, recent_pay}
        const url = `/top-fans/${hostId}`
        const {data} = await axios.get(url, {params})
        return TopFansPaginatedListResponseSchema.parse(data)
    },
    getWhosTopFanYouAre: async ({
        page,
        limit
    }: {
        page: string
        limit: number
    }): Promise<TopFansPaginatedListResponseType> => {
        const params = {page, limit}
        const url = `/top-fans/leader/rookies`
        const {data} = await axios.get(url, {params})
        return TopFansPaginatedListResponseSchema.parse(data)
    },
    getGuestTopFanInfo: async ({hostId}: {hostId: string}): Promise<TopFansListResponseType> => {
        const url = `/top-fans/leader-position/${hostId}`
        const {data} = await axios.get(url)
        return TopFansListResponseSchema.parse(data)
    },
    changeBadgeVisibility: async (isVisible: boolean): Promise<AxiosResponse<string>> => {
        const url = '/top-fans/visibility'
        return await axios.post(url, {
            is_visible: isVisible
        })
    }
}
