import {ChatFill} from '@/assets/icons/icons'
import {TextareaWithBannedElementsTrap} from '@/features/generated-content-security/components/text-area-with-word-trap/TextareaWithBannedElementsTrap'
import styled, {DefaultTheme, css} from 'styled-components'
import {Flexbox} from '@components/ui/flexbox/FlexBox.tsx'

export const StyledContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    overflow-x: hidden;
    overflow-y: auto;
`

export const StyledDescription = styled.div`
    ${({theme: {typography, spacing}}) => css`
        ${typography.textMd}
        padding: ${spacing * 2}px;
        text-align: center;
    `}
`

export const StyledMessageWrapper = styled.div`
    ${({theme: {spacing, palette}}) => css`
        display: flex;
        background: ${palette.primary[300]};
        align-items: center;
        flex-direction: column;
        padding: ${spacing * 2}px 0 ${spacing}px 0;
    `}
`

export const StyledAvatarContainer = styled.div`
    ${({theme: {spacing}}) => css`
        position: relative;
        margin-bottom: ${spacing * 3.5}px;
    `}
`

export const StyledChatFill = styled(ChatFill)`
    ${({theme: {spacing}}) => css`
        position: absolute;
        bottom: -${spacing * 1.5}px;
        left: 37px;
    `}
`

export const StyledTextArea = styled(TextareaWithBannedElementsTrap)`
    ${({theme: {typography, palette, mediaQueries}}) => css`
        max-width: 300px;
        margin: 0 auto;

        ${typography.textMd}
        & > div {
            min-width: 100%;
            min-height: 77px;
            max-height: 77px;
            background-color: rgba(255, 255, 255, 0.5);
            color: ${palette.primary[900]};
        }

        ${mediaQueries.m} {
            max-width: 340px;
        }
    `}
`

export const StyledTextAreaWrapper = styled.div`
    ${({theme: {spacing}}) => css`
        padding: 0 ${spacing}px;
        width: 100%;
    `}
`

export const StyledButton = styled.div`
    ${({theme: {spacing}}) => css`
        width: 100%;
        margin: ${spacing * 9}px auto ${spacing * 2}px;
        padding: 0 ${spacing * 2}px;
    `}
`
export const StyledMessageTemplatesWrapper = styled.div`
    ${({theme: {spacing, scrollbar}}) => css`
        display: flex;
        padding: ${spacing * 2.5}px 0px ${spacing * 2}px ${spacing * 2}px;
        width: 100%;
        height: 100%;
        overflow-x: scroll;
        touch-action: auto;
        ${scrollbar}
    `}
`

export const StyledTemplateItem = styled.div`
    ${({selected, theme: {spacing, palette}}: {selected: boolean; theme: DefaultTheme}) => css`
        cursor: pointer;
        padding: ${spacing * 1.5}px ${spacing * 2}px;
        margin: 2px;
        color: ${palette.primary[900]};
        border: 1px solid ${palette.primary[900]};
        border-radius: 100px;
        white-space: nowrap;
        font-weight: 700;
        line-height: 17px;
        letter-spacing: -0.3px;

        ${selected &&
        css`
            color: ${palette.neutral.white};
            background: ${palette.primary[900]};
        `}
    `}
`

export const StyledAvatarWrapper = styled(Flexbox)`
    ${({theme: {spacing, typography}}) => css`
        & p {
            margin: 0;
            font-weight: 600;
            ${typography.textMd}
        }

        .avatar {
            width: 60px;
            height: 60px;
            border-radius: ${spacing / 2}px;
        }
    `}
`

export const StyledContainerSwitch = styled(Flexbox)`
    ${({theme: {typography}}) => css`
        cursor: pointer;

        & span {
            ${typography.textMd}
        }
    `}
`

export const StyledAmountsContainer = styled.div`
    ${({
        theme: {spacing, typography},
        height,
        visible
    }: {
        theme: DefaultTheme
        height?: string | number
        visible?: boolean
    }) => css`
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: ${spacing * 1.5}px;
        width: 100%;
        height: ${height};
        visibility: ${visible ? 'visible' : 'hidden'};

        & p {
            margin: 0;
            ${typography.textXXl}
        }
    `}
`

export const StyledContainerInput = styled.div`
    ${() => css`
        width: 100%;

        & input[type='number']::-webkit-inner-spin-button,
        & input[type='number']::-webkit-outer-spin-button {
            -webkit-appearance: none;
            margin: 0;
        }
    `}
`

export const StyledAmountsWrapper = styled.div`
    ${({theme: {spacing, mediaQueries}}) => css`
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        gap: ${spacing}px;

        ${mediaQueries.m} {
            gap: ${spacing * 2}px;
        }
    `}
`

export const StyledAmountCard = styled(Flexbox)`
    ${({theme: {spacing, typography, palette}, isActive}: {theme: DefaultTheme; isActive: boolean}) => css`
        border: 2px solid ${palette.primary[50]};
        border-radius: ${spacing}px;
        min-width: 102px;
        width: 110px;
        max-height: 64px;
        height: 64px;
        cursor: pointer;

        & svg {
            height: 40px;
            width: 40px;
        }

        & span {
            font-weight: 700;
            ${typography.text2Xl}
        }

        ${isActive &&
        css`
            color: ${palette?.primary[200]};
            border-color: ${palette?.primary[200]};
        `}
    `}
`

export const StyledWrapper = styled.div`
    overflow: auto;
    height: 100%;
`
