import styled, {css} from 'styled-components'

export const StyledBuyMorgiSuccessStepModalContainer = styled.div`
    ${({theme: {spacing}}) => css`
        height: 100%;
        display: flex;
        padding: 0px ${spacing * 3}px ${spacing * 3}px;
        overflow: auto;
        flex-direction: column;
    `}
`

export const StyledAnimation = styled.div`
    ${({theme: {spacing}}) => css`
        width: 205px;
        height: auto;
        margin-bottom: ${spacing * 4}px;
    `}
`

export const StyledBuyMorgiSuccessStepContent = styled.div`
    ${({theme: {flexUtils}}) => css`
        ${flexUtils.centered}
        height: 100%;
        position: relative;
        flex-grow: 1;
        flex-direction: column;
    `}
`

export const StyledBuyMorgiSuccessStepContentTitle = styled.h2`
    ${({theme: {typography}}) => css`
        ${typography.textXXl}
        text-align: center;
        font-weight: 400;
        line-height: 27px;
        letter-spacing: -0.05em;
    `}
`
export const StyledBuyMorgiSuccessStepContentDescription = styled.p`
    ${({theme: {typography, spacing, palette}}) => css`
        ${typography.textSm}
        color: ${palette.neutral[25]};
        text-align: center;
        font-weight: 400;
        line-height: 20px;
        white-space: pre-wrap;
        margin-top: ${spacing * 2}px;
    `}
`
