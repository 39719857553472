import {ZodType, z} from 'zod'

export const enum GoalTypeCounterE {
    SNAP = '1',
    SPECIAL = '2',
    GRANDE = '3'
}

export const PageSchema = <T>(DataSchema: ZodType<T>) =>
    z.object({
        current_page: z.number(),
        data: z.array(DataSchema),
        first_page_url: z.string(),
        from: z.nullable(z.number()),
        last_page: z.number(),
        last_page_url: z.string(),
        links: z.array(
            z.object({
                url: z.nullable(z.string()),
                label: z.string(),
                active: z.boolean()
            })
        ),
        next_page_url: z.nullable(z.string()),
        path: z.string(),
        per_page: z.string(),
        prev_page_url: z.nullable(z.string()),
        to: z.nullable(z.number()),
        total: z.number(),
        metadata: z
            .object({
                type_counter: z.object({
                    [GoalTypeCounterE.SNAP]: z.number(),
                    [GoalTypeCounterE.SPECIAL]: z.number(),
                    [GoalTypeCounterE.GRANDE]: z.number()
                })
            })
            .optional()
    })

export const MessageSchema = z.object({
    message: z.string()
})
