import {useQuery} from '@tanstack/react-query'
import {QUERY_KEYS} from './keys'
import {THANK_YOUS_API} from '../services/thankYous.http'

export const useGetThankYousByHostId = (hostId: string, enabled?: boolean) => {
    return useQuery({
        queryKey: [QUERY_KEYS.host_thank_yous_by_id, hostId],
        queryFn: () => THANK_YOUS_API.getThankYousByHostId({hostId}),
        enabled
    })
}
