import {NOTIFICATIONS_API} from '../services/notifications.http'
import {useQuery} from '@tanstack/react-query'
import {QUERY_KEYS} from './keys'

export const useGetNotificationUnreadCount = () => {
    return useQuery({
        queryKey: [QUERY_KEYS.notification_unread_count],
        queryFn: () => NOTIFICATIONS_API.getNotificationUnreadCount()
    })
}
