import {Header} from '@/components/commons/header/Header'
import {StyledRoot} from './style'
import {Component as Goal} from '@pages/all-user-role-pages/goal/Goal.tsx'
import {Container} from '@/components/ui/container/Container'
import {useLockBodyScroll} from '@/hooks/useLockBodyScroll'

export const GoalPageModal = () => {
    useLockBodyScroll({enable: true})

    return (
        <StyledRoot>
            <Header />
            <Container>
                <Goal />
            </Container>
        </StyledRoot>
    )
}
