export const MUTATION_KEYS = {
    store_filters: 'store-filters',
    clear_filters: 'clear-filters',
    send_seen_talents: 'send-seen-talents'
}

export const QUERY_KEYS = {
    feed: 'feed',
    monthly_support: 'monthly-support',
    active_host: 'active-host'
}
