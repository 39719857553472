import styled, {css, DefaultTheme} from 'styled-components'
import * as Switch from '@radix-ui/react-switch'

export const StyledToggleContainer = styled.div`
    ${({labelPosition, theme: {flexUtils, spacing}}: {labelPosition: string; theme: DefaultTheme}) => css`
        ${flexUtils.centered};
        gap: ${spacing / 2}px;
        flex-direction: ${labelPosition === 'right' ? 'row-reverse' : 'row'};
    `}
`

export const StyledToggleSpan = styled.span`
    ${({theme: {palette}}: {theme: DefaultTheme}) => css`
        font-weight: 700;
        color: ${palette.primary['900']};
    `}
`

export const StyledToggleBox = styled.div`
    ${({theme: {flexUtils}}: {theme: DefaultTheme}) => css`
        ${flexUtils.centered};
    `}
`

type StyledSwitchProps = {
    $isSmall: boolean
    $isLight: boolean
    $isChecked: boolean
    theme: DefaultTheme
}
export const StyledSwitchRoot = styled(Switch.Root)<StyledSwitchProps>`
    ${({$isSmall, disabled, $isLight, $isChecked, theme: {palette}}) => css`
        width: ${$isSmall ? '40px' : '48px'};
        height: ${$isSmall ? '20px' : '24px'};
        cursor: pointer;
        transition: all 0.5s ease 0s;
        background-color: ${palette.neutral['500']};
        border-radius: 40px;
        position: relative;

        &[data-state='checked'] {
            background-color: ${palette.primary['200']};
        }

        ${disabled &&
        css`
            background-color: ${palette.neutral['950']};
            cursor: not-allowed;
        `}

        ${($isLight || $isChecked) &&
        css`
            background-color: ${$isChecked ? palette.neutral['white'] : palette.primary['300']};
            border: 1px solid ${$isChecked ? palette.neutral['50'] : palette.primary['450']};

            &[data-state='checked'] {
                background-color: ${palette.primary['300']};
                border: 1px solid ${palette.primary['450']};
            }
        `};
    `}
`

export const StyledSwitchThumb = styled(Switch.Thumb)<StyledSwitchProps>`
    ${({$isSmall, $isLight, $isChecked, theme: {palette}}) => css`
        display: block;
        width: ${$isSmall ? '14px' : '18px'};
        height: ${$isSmall ? '14px' : '18px'};
        background-color: white;
        border-radius: 50%;
        transition: all 0.5s ease 0s;
        transform: translateX(3px);
        will-change: transform;

        ${StyledSwitchRoot}[data-state='checked'] & {
            transform: ${$isSmall ? 'translateX(22px)' : 'translateX(27px)'};
        }

        ${($isLight || $isChecked) &&
        css`
            background-color: ${$isChecked ? palette.neutral['50'] : palette.primary['450']};
            border: 1px solid ${$isChecked ? palette.neutral['50'] : palette.primary['450']};

            &[data-state='checked'] {
                background-color: ${palette.primary['450']};
                border: 1px solid ${palette.primary['450']};
            }
        `}
    `}
`
