import {useMe} from '@/features/authentication/queries/useMe'
import {USER_ROLE_GUEST} from '@/utilities/constants/user'
import {LocalStorageManager} from '@/utilities/localStorage'
import {SessionStorageManager} from '@/utilities/sessionStorage'
import {useEffect, useState} from 'react'

const useHomeScreenBannerVisibility = (): {isHomeScreenBannerVisible: boolean; onClose: () => void} => {
    const {data: user} = useMe()

    const [isHomeScreenBannerVisible, setIsHomeScreenBannerVisible] = useState(false)
    const sessionCountForAddHomeScreenBanner = LocalStorageManager.sessionCountForAddHomeScreenBanner.get()
    const isMobile =
        /Android|webOS|iPhone|iPad|iPod|BlackBerry|BB|PlayBook|IEMobile|Windows Phone|Kindle|Silk|Opera Mini/i.test(
            navigator.userAgent
        )
    const sessionsQuantity = 3
    const isConnectedTelegramBot = !!user?.joined_telegram_bot_at
    const isLessThreeSession = sessionCountForAddHomeScreenBanner <= sessionsQuantity

    const handleBannerVisibility = () => {
        const alreadySeen = SessionStorageManager.seenAddHomeScreenBanner.get()
        if (!alreadySeen) {
            setIsHomeScreenBannerVisible(!isHomeScreenBannerVisible)
            SessionStorageManager.seenAddHomeScreenBanner.set(true)
        }
    }

    useEffect(() => {
        // handle "add to homescreen" shortcut
        if (user && user?.type === USER_ROLE_GUEST && isMobile && isConnectedTelegramBot && isLessThreeSession) {
            // TODO timeout use only for development due to react strict mode
            const timeout = setTimeout(() => handleBannerVisibility(), 100)
            return () => clearTimeout(timeout)
        }
    }, [user])

    return {isHomeScreenBannerVisible, onClose: () => setIsHomeScreenBannerVisible(false)}
}

export default useHomeScreenBannerVisibility
