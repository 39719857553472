import styled, {css, DefaultTheme} from 'styled-components'
import {Flexbox} from '@components/ui/flexbox/FlexBox.tsx'

type StyledCardAmountToSelectProps = {
    theme: DefaultTheme
    isSelected: boolean
    disabled?: boolean
}
export const StyledCardAmountToSelect = styled(Flexbox)<StyledCardAmountToSelectProps>`
    ${({theme: {typography, palette, spacing}, disabled, isSelected}) => css`
        ${typography.text2Xl}
        font-weight: 700;
        color: ${palette.primary[900]};
        padding: ${spacing + 2}px;
        border-radius: 10px;
        cursor: pointer;
        border: solid 2px ${palette.neutral[75]};
        transition: border 0.3s ease;
        height: 70px;

        ${isSelected &&
        css`
            border: solid 2px ${palette.primary[200]};
            color: ${palette.primary[200]};
        `}

        ${disabled &&
        css`
            cursor: not-allowed;
            opacity: 0.5;
        `}
    `}
`
