import {Button} from '@/components/ui/button/Button'
import {Media} from '@/components/ui/media/Media'
import {Modal} from '@/components/ui/modal/Modal'
import {capitalize} from 'lodash'
import {useTranslation} from 'react-i18next'
import {StyledDivider, StyledGif, modalStyles} from './style'
import useSendChatMessage from '@/features/chat/hooks/useSendChatMessage'
import {useGetChannelDetails} from '@/features/chat/queries/useGetChannelDetails'
import {useParams} from 'react-router-dom'
import {useMe} from '@/features/authentication/queries/useMe'
import {MessageTypeE} from '@/utilities/constants/chat'
import {useRootStore} from '@/store'
import {selectSendGifModal} from '@/store/selectors'
import {ChannelID} from '@/features/chat/types'
import {getCorrectUserId} from '@/utilities/helpers'

const SendGifModal = () => {
    const {closeModal, gif} = useRootStore(selectSendGifModal)
    const {t} = useTranslation()
    const gifUrl = !!gif && gif?.images.fixed_height.url
    const {channel} = useParams()
    const {data: user} = useMe()
    const {data: channelDetails} = useGetChannelDetails(channel as ChannelID)
    const {sendChatMessage, isPending} = useSendChatMessage(channel as ChannelID)
    const correctUserId = user ? getCorrectUserId(user) : undefined

    const handleSendGif = async () => {
        if (user && correctUserId && channelDetails && gifUrl) {
            await sendChatMessage({
                type: MessageTypeE.GIF,
                userId: correctUserId,
                gifUrl
            })
        }
        closeModal()
    }
    return (
        <div>
            <Modal title={t('gifs_and_stickers:send_gif')} onClose={closeModal} modalStyles={modalStyles}>
                <StyledGif>
                    <Media radius={10} imageUrl={gifUrl || ''} />
                </StyledGif>
                <StyledDivider />
                <Button type="submit" onClick={handleSendGif} disabled={isPending}>
                    {capitalize(t('common:send'))}
                </Button>
            </Modal>
        </div>
    )
}

export default SendGifModal
