import {User} from '@/features/authentication/types'
import {createContext, ReactNode} from 'react'
import {MixpanelTrackEvent} from '../mixpanel/types'

type TrackEventsType = <TName extends MixpanelTrackEvent['name']>(
    eventName: TName,
    eventProperties?: MixpanelTrackEvent['properties'],
    timeoutDelay?: number
) => void

type MoEngageType = {
    add_unique_user_id: (id: string) => void
    add_email: (email: string) => void
    add_user_attribute: (key: string, value: string) => void
    add_last_name: (username: string) => void
    add_first_name: (fullName: string) => void
    add_gender: (gender: string) => void
    track_event: TrackEventsType
}

export type MoEngageSdkContextType = {
    moEngageWrapper: {
        identifyUser: (user: User) => void
        trackEvent: TrackEventsType
    }
}

export const MoEngageSdkContext = createContext<MoEngageSdkContextType | null>(null)
MoEngageSdkContext.displayName = 'MoEngageSdkContext'

type MoEngageProviderProps = {
    children: ReactNode
    MoEngage: MoEngageType
}

const MoEngageSDKProvider = ({children, MoEngage}: MoEngageProviderProps) => {
    const isMoEngageActive = () => !Object.keys(MoEngage).length

    const moEngageWrapper: {
        identifyUser: (user: User) => void
        trackEvent: TrackEventsType
    } = {
        identifyUser: (user: User) => {
            if (!user) {
                console.warn('moEngage-wrapper: userID not provided')
                return
            }
            if (isMoEngageActive()) {
                console.warn('moEngage-wrapper: moEngage not active')
                return
            }

            // moEngage.add_unique_user_id must be called in order to associate the profile properties you set
            // with that user. You only need to call it once per page load for a given user.
            MoEngage.add_unique_user_id(`${user.id}`)
            MoEngage.add_email(user.email)
            MoEngage.add_user_attribute('type', user.type)
            MoEngage.add_last_name(user.username)
            MoEngage.add_first_name(user.full_name)
            MoEngage.add_gender(`${user?.gender?.name}`)
        },
        trackEvent: (eventName, eventProperties, timeoutDelay = 0) => {
            setTimeout(() => {
                if (!eventName) {
                    console.warn('moEngage-wrapper: event name not provided')
                    return
                }
                if (isMoEngageActive()) {
                    console.warn('moEngage-wrapper: moEngage not active')
                    return
                }
                if (eventProperties) {
                    if (typeof eventProperties === 'object' && !Array.isArray(eventProperties)) {
                        return MoEngage.track_event(eventName, eventProperties)
                    } else {
                        console.warn('moEngage-wrapper: event properties provided are malformed')
                    }
                } else {
                    return MoEngage.track_event(eventName)
                }
            }, timeoutDelay)
        }
    }

    return <MoEngageSdkContext.Provider value={{moEngageWrapper}}>{children}</MoEngageSdkContext.Provider>
}

export default MoEngageSDKProvider
