import {ChevronLeftIcon, ChevronRightIcon} from '@/components/ui/icon'
import dayjs from '@/dayjs.ts'
import {
    CalendarWrapper,
    DayButtonWrapper,
    StyledCalendarHeader,
    StyledCalendarHeaderControlButtons,
    StyledDayButton,
    StyledDayGrid,
    StyledWeekDayContainer
} from '@components/commons/calendar/style.ts'
import {PopoverClose} from '@radix-ui/react-popover'
import {DPCalendar, DPDay, DPMonth, DPPropGetter, DPYear} from '@rehookify/datepicker'
import {capitalize} from '@utilities/helpers'
import {FC, Fragment, PropsWithChildren, ReactNode, useCallback} from 'react'
import {DefaultTheme, FlattenInterpolation, ThemeProps} from 'styled-components'
import SingleSelect from '../singleSelect/SingleSelect'
import {OptionType} from '../singleSelect/types'

type DateSelectorProps = {
    mode: 'single' | 'multiple' | 'range'
    calendar: DPCalendar
    dayButton: (day: DPDay) => DPPropGetter
    weekDays: string[]
    locale?: string
    changeDate: (value: Date, key: 'month' | 'year') => void
    months: DPMonth[]
    years: DPYear[]
    onNextMonth?: () => void
    onPrevMonth?: () => void
    datePickerRangeStyles?: FlattenInterpolation<ThemeProps<DefaultTheme>>
}

export const DateSelector: FC<DateSelectorProps> = ({
    calendar,
    onNextMonth,
    onPrevMonth,
    weekDays,
    dayButton,
    locale = 'en',
    mode,
    changeDate,
    months,
    years,
    datePickerRangeStyles
}) => {
    const {month, year, days} = calendar
    const inMonthDays = days.filter(dpDay => dpDay.inCurrentMonth)
    const PopoverCloseWrapper = useCallback(
        ({children}: {children?: ReactNode}) => <PopoverClose asChild>{children}</PopoverClose>,
        []
    )
    const activeMonth = months.filter(({disabled}) => !disabled)
    const indexCurrentMonth = activeMonth.findIndex(({month: monthName}) => monthName === month)

    let ButtonWrapper: FC<PropsWithChildren> = Fragment
    if (mode === 'single') {
        ButtonWrapper = PopoverCloseWrapper
    }

    const monthsList: OptionType[] = months.map(month => ({
        id: month.month,
        label: month.month,
        value: month.month,
        name: month.month,
        disabled: month.disabled,
        extraPayload: {...month}
    }))
    const yearsList: OptionType[] = years.map(year => ({
        id: year.year.toString(),
        label: year.year.toString(),
        value: year.year.toString(),
        name: year.year.toString(),
        disabled: year.disabled,
        extraPayload: {...year}
    }))

    return (
        <CalendarWrapper>
            <StyledCalendarHeader>
                <SingleSelect
                    name="months"
                    onChange={date => {
                        const dateMonth = date?.extraPayload as DPMonth
                        dateMonth.month !== month && changeDate(dateMonth?.$date, 'month')
                    }}
                    value={{id: month, label: month, value: month, name: month}}
                    options={monthsList}
                />
                <SingleSelect
                    name="years"
                    onChange={date => {
                        const dateYear = date?.extraPayload as DPYear
                        dateYear.year.toString() !== year && changeDate(dateYear?.$date, 'year')
                    }}
                    value={{id: year, label: year, value: year, name: year}}
                    options={yearsList}
                />

                <StyledCalendarHeaderControlButtons>
                    {onNextMonth && 0 < indexCurrentMonth && (
                        <button onClick={onNextMonth}>
                            <ChevronLeftIcon size={20} />
                        </button>
                    )}
                    {onPrevMonth && activeMonth.length - 1 > indexCurrentMonth && (
                        <button onClick={onPrevMonth}>
                            <ChevronRightIcon size={20} />
                        </button>
                    )}
                </StyledCalendarHeaderControlButtons>
            </StyledCalendarHeader>
            <StyledDayGrid>
                {weekDays.map((_, index) => (
                    <StyledWeekDayContainer key={index}>
                        {capitalize(
                            dayjs()
                                .locale(locale)
                                .day((index + 7) % 7)
                                .format('dddd')
                                .slice(0, 2)
                        )}
                    </StyledWeekDayContainer>
                ))}
            </StyledDayGrid>
            <StyledDayGrid>
                {days.map((dpDay, index) => {
                    const isPrimary =
                        dpDay.inCurrentMonth &&
                        (dpDay.selected || dpDay.range === 'will-be-range-end' || dpDay.range === 'will-be-range-start')

                    const isFirstDayOfTheMonth = inMonthDays[0].$date.toISOString() === dpDay.$date.toISOString()

                    const isLastDayOfTheMonth =
                        inMonthDays[inMonthDays.length - 1].$date.toISOString() === dpDay.$date.toISOString()

                    return (
                        <ButtonWrapper key={`${year}-${month}-${dpDay.day}-${index}`}>
                            {dpDay.inCurrentMonth ? (
                                <DayButtonWrapper
                                    datePickerRangeStyles={datePickerRangeStyles}
                                    className={`${isFirstDayOfTheMonth ? 'firstMonthDay' : ''} ${
                                        isLastDayOfTheMonth ? 'lastMonthDay' : ''
                                    } `}
                                    inCurrentMonth={dpDay.inCurrentMonth}
                                    rangeStatus={dpDay.range}
                                >
                                    <StyledDayButton
                                        {...dayButton(dpDay)}
                                        variant={dpDay.now || isPrimary ? 'primary' : 'transparent'}
                                        disabled={dpDay.disabled}
                                    >
                                        {dpDay.day}
                                    </StyledDayButton>
                                </DayButtonWrapper>
                            ) : (
                                <span />
                            )}
                        </ButtonWrapper>
                    )
                })}
            </StyledDayGrid>
        </CalendarWrapper>
    )
}
