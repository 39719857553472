import styled, {keyframes} from 'styled-components'

const loading = keyframes`
  0% { content: "."; }
  33% { content: ".."; }
  66% { content: "..."; }
`

export const StyledDotsAnimation = styled.span`
    width: 20px;
    display: inline-block;

    &:after {
        content: '...';
        animation: ${loading} 1.5s infinite;
    }
`
