import {useQuery} from '@tanstack/react-query'
import {QUERY_KEYS} from './keys'
import {GUEST_API} from '../services/guest.http'

export const useGetHost = ({filter = '', isUsername = undefined}: {filter?: string | number; isUsername?: boolean}) => {
    return useQuery({
        queryKey: [QUERY_KEYS.host, filter, isUsername],
        queryFn: () => GUEST_API.getHost({filter, isUsername}),
        enabled: !!filter
    })
}
