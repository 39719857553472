import {useMutation} from '@tanstack/react-query'
import {MIXPANEL_API} from '../services/mixpanel.http'
import {MixpanelEventNameE, TriggerBackendEventParamsType} from '../types'

export const useTriggerBackend = (onSuccess?: () => void) => {
    return useMutation({
        mutationFn: (body: TriggerBackendEventParamsType<MixpanelEventNameE>) => MIXPANEL_API.sendMixpanelData(body),
        onSuccess: () => {
            onSuccess?.()
        }
    })
}
