import {useQuery} from '@tanstack/react-query'
import {MOENGAGE_API} from '../services/moengage.http'
import {QUERY_KEYS} from './keys'
import {LocalStorageManager} from '@/utilities/localStorage'

export const useGetMoengageCampaign = () => {
    return useQuery({
        queryKey: [QUERY_KEYS.moengage_campaign],
        queryFn: () => MOENGAGE_API.getMoengage(),
        enabled: !!LocalStorageManager.loginUser.get()
    })
}
