import {Lens} from '@dhmk/zustand-lens'

type PaidMessageModalState = {
    isOpen: boolean
    hostID?: string | null
    leaderID?: number | null
    channelID?: string | null
    type?: string
}

type PaidMessageModalActions = {
    openModal: (params: Omit<PaidMessageModalState, 'isOpen'>) => void
    closeModal: () => void
}

export type PaidMessageModal = PaidMessageModalState & PaidMessageModalActions

const initialModal: PaidMessageModalState = {
    isOpen: false,
    hostID: null,
    leaderID: null,
    channelID: null,
    type: undefined
}

const createPaidMessageModalSlice: Lens<PaidMessageModal> = set => ({
    ...initialModal,
    openModal: params => set(() => ({...params, isOpen: true})),
    closeModal: () => set(() => ({...initialModal}))
})

export default createPaidMessageModalSlice
