import {useQuery} from '@tanstack/react-query'
import {QUERY_KEYS} from './keys'
import {CHAT_FAV_TOPICS_API} from '../services/chatFavTopics.http'

export const useGetFavoriteActivities = (is_popular = true) => {
    return useQuery({
        queryKey: [QUERY_KEYS.favorite_activities, {is_popular}],
        queryFn: () => CHAT_FAV_TOPICS_API.getFavActivities(is_popular)
    })
}
