import {MUTATION_KEYS} from './keys'
import {useMutation} from '@tanstack/react-query'
import {MY_SURPRISES_API} from '../services/mySurprises.http'

export const useUnlockHostSurpriseById = ({
    hostId,
    surpriseId
}: {
    hostId: number | string
    surpriseId?: number | null
}) => {
    return useMutation({
        mutationKey: [MUTATION_KEYS.unlock_host_surprise_by_id, hostId, surpriseId],
        mutationFn: (surpriseId: number | string) => MY_SURPRISES_API.unlockHostSurpriseById(hostId, surpriseId)
    })
}
