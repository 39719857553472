import {useMe} from '@/features/authentication/queries/useMe'
import {UserRoleE} from '@/types'
import {FC, ReactNode} from 'react'

type PrivateFragmentProps = {
    permittedRoles: UserRoleE[]
    children: ReactNode
}

export const PrivateFragment: FC<PrivateFragmentProps> = ({permittedRoles, children}) => {
    const {data: user} = useMe()
    const isAllowed = permittedRoles.includes(user?.type as UserRoleE)
    return isAllowed ? <>{children}</> : <></>
}
