import styled, {DefaultTheme, css} from 'styled-components'

export const HeaderWrapper = styled.div`
    ${({isLight, theme: {sizing, mediaQueries, zIndex, palette}}: {isLight: boolean; theme: DefaultTheme}) => css`
        height: ${sizing.headerMobileHeight};
        display: flex;
        align-items: center;
        z-index: ${zIndex.header};
        position: relative;
        ${mediaQueries.m} {
            height: ${sizing.headerDesktopHeight};
            border-bottom: ${isLight ? `1px solid ${palette.neutral[600]}` : `1px solid rgba(255,255,255,.2)`};
        }
    `}
`

export const HeaderGrid = styled.div`
    ${({theme: {spacing}}) => css`
        display: grid;
        grid-template-columns: 1fr auto 1fr;
        position: relative;
        align-items: center;
        column-gap: ${spacing * 2}px;
    `}
`

export const StyledLogo = styled.img`
    ${({theme: {mediaQueries}}) => css`
        grid-column-start: 2;
        height: 100%;
        width: 100%;
        max-height: 35px;
        max-width: 64px;
        object-fit: contain;
        cursor: pointer;
        margin-bottom: 3px;
        ${mediaQueries.m} {
            max-height: 40px;
            max-width: 85px;
        }
    `}
`

export const StyledNavbarToggler = styled.div`
    ${({isLight, theme: {palette}}: {isLight: boolean; theme: DefaultTheme}) => css`
        height: 20px;
        cursor: pointer;
        justify-self: start;
        & svg {
            width: 20px;
            & path {
                fill:  ${isLight ? palette.primary[900] : palette.neutral.white};
            },
        }
    `}
`

export const StyledNavBarBox = styled.div`
    ${({theme: {spacing}}) => css`
        display: flex;
        gap: ${spacing * 2}px;
    `}
`
