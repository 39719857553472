import {QUERY_KEYS} from './keys'
import {MySurprisesPropsType} from '../types'
import {useQuery} from '@tanstack/react-query'
import {MY_SURPRISES_API} from '../services/mySurprises.http'

export const useGetMySurprises = ({page, limit}: MySurprisesPropsType) => {
    return useQuery({
        queryKey: [QUERY_KEYS.my_surprises, page, limit],
        queryFn: () => MY_SURPRISES_API.getMySurprises()
    })
}
