import styled, {DefaultTheme, css} from 'styled-components'

export const StyledBannerContainer = styled.div`
    ${({theme: {palette, spacing}, elementColor}: {theme: DefaultTheme; elementColor?: string}) => css`
        pointer-events: auto;
        z-index: 1029;
        margin: 0 auto 8px auto;
        max-width: 600px;
        padding: ${spacing * 1}px ${spacing * 2}px;
        gap: ${spacing * 2}px;
        border-radius: 10px;
        background-color: ${elementColor || palette.primary[200]};
        display: flex;
        justify-content: space-between;
        align-items: center;
        opacity: 0.8;
    `}
`

export const StyledImageContainer = styled.div`
    ${({image}: {image?: string}) => css`
        background-image: ${image ? `url(${image})` : 'none'};
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
        width: 50px;
        height: 50px;
    `}
`

export const StyledTextContainer = styled.div`
    ${({theme: {spacing}}) => css`
        min-width: 216px;
        max-width: 400px;
        min-height: 16px;
        display: flex;
        flex-direction: column;
        margin-left: ${spacing * 2}px;
    `}
`

export const StyledTitle = styled.h5`
    ${({theme: {palette, typography}, titleColor}: {theme: DefaultTheme; titleColor?: string}) => css`
        ${typography.text2Xl}
        font-weight: 700;
        margin: 0;
        color: ${titleColor || palette.neutral.white};
    `}
`

export const StyledDescription = styled.p`
    ${({theme: {palette, typography}, descriptionColor}: {theme: DefaultTheme; descriptionColor?: string}) => css`
        ${typography.textLg}
        font-weight: 400;
        margin: 0;
        color: ${descriptionColor || palette.neutral.white};
    `}
`

export const StyledCloseIconContainer = styled.div`
    ${({theme: {palette}}) => css`
        cursor: pointer;
        max-width: 80px;
        max-height: 35px;
        display: flex;
        align-items: center;
        color: ${palette.neutral.white};

        & svg {
            fill: ${palette.neutral.white};

            & path {
                fill: ${palette.neutral.white};
            }
        }
    `}
`
