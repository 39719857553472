import {LockFillIcon, SurprisesIcon} from '@/assets/icons/icons'
import {Popover} from '@/components/ui/popover/Popover'
import {useMe} from '@/features/authentication/queries/useMe'
import {remapCurrency} from '@/features/profile/constants'
import {FC, useState} from 'react'
import {Trans, useTranslation} from 'react-i18next'
import {AmountItemProps} from '../amount-item/AmountItem'
import {
    StyledAmount,
    StyledAmountContainer,
    StyledAmountIconGem,
    StyledPrice,
    StyledSurprisesWrapper,
    StyledToolTipWrapper,
    StyledWrapper,
    ToolTipContent,
    ToolTipLink
} from './style'
import {envVars} from '@/envVars'

export const GiftItem: FC<Omit<AmountItemProps, 'isSelected' | 'onSelect'>> = ({
    amount,
    amountIcon,
    modalContainer,
    activeSubscriptionAmount
}) => {
    const {t} = useTranslation()
    const {data: user} = useMe()
    const [isOpen, setIsOpen] = useState<boolean>(false)

    return (
        <StyledWrapper disabled={activeSubscriptionAmount === amount?.amount}>
            <StyledAmountContainer>
                <StyledAmountIconGem>{amountIcon}</StyledAmountIconGem>
                <StyledAmount>{amount.amount}</StyledAmount>
                <StyledPrice>
                    {t(`gift_rookie:morgi_each_month`, {
                        PRICE: `${remapCurrency[user?.currency as string]}${amount.dollar_amount}`,
                        Morgis: amount.amount
                    })}
                    {amount.surprises_count && (
                        <StyledSurprisesWrapper>
                            <SurprisesIcon height={22} width={17} />
                            <span>{`${amount.surprises_count}`}</span>
                            {t(`gift_rookie:surprises`)}
                        </StyledSurprisesWrapper>
                    )}
                </StyledPrice>
            </StyledAmountContainer>
            {amount.is_locked && (
                <StyledAmountContainer>
                    {amount.is_locked && (
                        <StyledToolTipWrapper>
                            <Popover
                                isModal
                                trigger={<LockFillIcon />}
                                rootProps={{
                                    open: isOpen ? isOpen : undefined,
                                    onOpenChange: () => {
                                        setIsOpen(false)
                                    }
                                }}
                                contentProps={{
                                    side: 'top',
                                    align: 'center',
                                    sideOffset: 10,
                                    hideWhenDetached: true,
                                    avoidCollisions: true,
                                    collisionBoundary: modalContainer
                                }}
                                triggerProps={{asChild: false}}
                            >
                                <ToolTipContent>
                                    <Trans
                                        t={t}
                                        i18nKey="gift_rookie:blocked_amount_typpy"
                                        components={{
                                            span: (
                                                <ToolTipLink href={`mailto:${envVars.VITE_APP_CUSTOMER_CARE_EMAIL}`} />
                                            )
                                        }}
                                    />
                                </ToolTipContent>
                            </Popover>
                        </StyledToolTipWrapper>
                    )}
                </StyledAmountContainer>
            )}
        </StyledWrapper>
    )
}
