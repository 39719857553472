import axios from '@/clientAPI.ts'
import * as types from '../types'

export const APPROACH_FIRST_API = {
    getApproachFirst: async ({limit = 50}): Promise<types.ApproachFirstResponseType> => {
        const url = `/approach`
        const {data} = await axios.get(url, {params: {limit}})
        return data
    },
    sendApproachFirst: async ({guestID, body}: types.SendApproachFirstRequestType) => {
        const {data} = await axios.post(`/approach/${guestID}`, {...body, type: 'message'})
        return data
    }
}
