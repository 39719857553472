import {
    SnapGoalIcon,
    SpecialGoalIcon,
    GrandeGoalIcon,
    GoalSnapWhiteIcon,
    GoalGrandeWhiteIcon,
    GoalSpecialWhiteIcon
} from '@/assets/icons/icons'
import {UnitsE} from '@/dayjs'
import {GoalTypeCounterE} from '@/features/common/services/common.schema'
import {palette} from '@/theme/palette'

export const additionalContent = {
    small_size_goal: {
        whiteIcon: <GoalSnapWhiteIcon />,
        icon: <SnapGoalIcon />,
        type_name: 'snap',
        duration_unit: UnitsE.DAYS,
        size: GoalTypeCounterE.SNAP,
        activeBackgroundColor: `${palette.light.primary[900]}`
    },
    medium_size_goal: {
        whiteIcon: <GoalSpecialWhiteIcon />,
        icon: <SpecialGoalIcon />,
        type_name: 'special',
        duration_unit: UnitsE.MONTH,
        size: GoalTypeCounterE.SPECIAL,
        activeBackgroundColor: `${palette.light.primary[850]}`
    },
    large_size_goal: {
        whiteIcon: <GoalGrandeWhiteIcon />,
        icon: <GrandeGoalIcon />,
        type_name: 'grande',
        duration_unit: UnitsE.MONTHS,
        size: GoalTypeCounterE.GRANDE,
        activeBackgroundColor: `${palette.light.primary[550]}`
    }
}
