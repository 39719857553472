import {StyledButton} from './style'
import {ButtonHTMLAttributes, forwardRef, ReactNode} from 'react'

export interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
    children: ReactNode
    className?: string
    fullWidth?: boolean
    icon?: boolean
    iconPosition?: 'right' | 'left'
    iconOnly?: boolean
    disabled?: boolean
    size?: 'sm' | 'md' | 'lg' | 'xl'
    variant?:
        | 'primary'
        | 'primaryDanger'
        | 'secondary'
        | 'tertiary'
        | 'ghost'
        | 'primaryOutlined'
        | 'outlined'
        | 'transparent'
        | 'small'
        | 'big'
        | 'iconOnly'
        | 'warning'
}

export const Button = forwardRef<HTMLButtonElement, ButtonProps>(
    (
        {
            children,
            className,
            disabled,
            fullWidth,
            iconPosition,
            icon,
            iconOnly,
            size,
            type = 'button',
            variant = 'primary',
            ...rest
        },
        ref
    ) =>
        (
            <StyledButton
                className={className}
                disabled={disabled}
                fullWidth={fullWidth}
                icon={icon}
                iconPosition={iconPosition}
                iconOnly={iconOnly}
                ref={ref}
                size={size}
                type={type}
                variant={variant}
                {...rest}
            >
                {children}
            </StyledButton>
        ) as React.ReactElement
)

Button.displayName = 'Button'
