import {useMe} from '@/features/authentication/queries/useMe'
import {ROUTE_MESSAGE_CENTER_CHAT} from '@/utilities/constants/routeNames'
import {generateChatChannelName} from '@/utilities/helpers'
import {generatePath, useNavigate} from 'react-router-dom'

type UseRedirectToChatChannelProps = {
    receiverId: number
}

const useRedirectToChatChannel = ({receiverId}: UseRedirectToChatChannelProps) => {
    const navigate = useNavigate()
    const {data: user} = useMe()
    if (user) {
        const channelName = generateChatChannelName(user?.id, String(user?.type), receiverId)
        const path = generatePath(ROUTE_MESSAGE_CENTER_CHAT, {channel: channelName})

        return () => navigate(path)
    } else return
}
export default useRedirectToChatChannel
