import styled, {css} from 'styled-components'

type StyledInputStatusIconProps = {
    hasError?: boolean
}
export const InputStatusIconWrapper = styled.span<StyledInputStatusIconProps>`
    ${({theme: {spacing}}) => css`
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: ${spacing}px;
        display: grid;
        align-items: center;
        column-gap: ${spacing}px;
        justify-content: center;
        grid-template-columns: auto auto;
    `}
`

export const StyledVisibilityToggle = styled.span`
    height: 100%;
    max-height: 24px;
    cursor: pointer;
`
