import {Lens} from '@dhmk/zustand-lens'

type GoalPageModalState = {
    isOpen: boolean
    goalId: string | number
}

type GoalPageModalActions = {
    openModal: (params: Omit<GoalPageModalState, 'isOpen'>) => void
    closeModal: () => void
}

export type GoalPageModal = GoalPageModalState & GoalPageModalActions

const initialModal: GoalPageModalState = {isOpen: false, goalId: ''}

const createGoalModalSlice: Lens<GoalPageModal> = set => ({
    ...initialModal,
    openModal: params => set(() => ({...params, isOpen: true})),
    closeModal: () => set(() => ({...initialModal}))
})

export default createGoalModalSlice
