import {useTranslation} from 'react-i18next'
import {StyledButtonText, StyledHeader, StyledSupporterModal, StyledSupportersList, StyledSupportersRoot} from './style'
import {useMemo, useState} from 'react'
import {Chat, CloseIcon} from '@/assets/icons/icons'
import {useFetchSupporters} from '@/features/goal/queries/useFetchSupporters'
import {Spinner} from '@/components/ui/spinner/Spinner'
import {StyledOverlay} from '@/components/ui/modal/style'
import {SupportersCarousel} from './supporters-carousel/SupportersCarousel'
import {SupporterCard} from './supporter-card/SupporterCard'
import {Button} from '@/components/ui/button/Button'
import {useHandleError} from '@/hooks/useHandleError'
import {useLockBodyScroll} from '@/hooks/useLockBodyScroll'
import {generatePath, useNavigate} from 'react-router-dom'
import {CHAT_TYPE_GROUP} from '@/utilities/constants/chat'
import {ROUTE_MESSAGE_CENTER_CHAT} from '@/utilities/constants/routeNames'

export const SupportersSection = ({goalId, groupId}: {goalId: string; groupId: string}) => {
    const {t} = useTranslation()
    const navigate = useNavigate()
    const [isOpenSupportersModal, setIsOpenSupportersModal] = useState(false)
    const {data, isLoading, isError, error} = useFetchSupporters(goalId)
    const MIN_SUPPORTERS_NUMBER_FOR_GOAL_GROUP = 2
    const numberOfSupporters = data && data?.length
    const showGoalGroupButton = isOpenSupportersModal && data && data?.length >= MIN_SUPPORTERS_NUMBER_FOR_GOAL_GROUP

    useHandleError({isError, error})

    const onOpenModal = () => {
        if (!isOpenSupportersModal) {
            setIsOpenSupportersModal(true)
        }
    }

    useLockBodyScroll({enable: isOpenSupportersModal})

    const title = useMemo(() => {
        switch (true) {
            case !numberOfSupporters:
                return t('goal_page:supporters:title_no')
            case numberOfSupporters === 1:
                return t('goal_page:supporters:title_one', {NUMBER_SUPPORTERS: numberOfSupporters})
            default:
                return t('goal_page:supporters:title', {NUMBER_SUPPORTERS: numberOfSupporters})
        }
    }, [numberOfSupporters])

    return (
        <>
            {isOpenSupportersModal && <StyledOverlay onClick={() => setIsOpenSupportersModal(false)} />}
            <StyledSupportersRoot isOpen={isOpenSupportersModal} onClick={onOpenModal}>
                {isLoading ? (
                    <Spinner />
                ) : (
                    <StyledSupporterModal>
                        <section>
                            <StyledHeader isOpen={isOpenSupportersModal}>
                                <h4>{title}</h4>
                                {isOpenSupportersModal && <CloseIcon onClick={() => setIsOpenSupportersModal(false)} />}
                            </StyledHeader>

                            {isOpenSupportersModal ? (
                                <StyledSupportersList>
                                    {data?.map(supporter => (
                                        <SupporterCard key={supporter?.id} supporter={supporter} />
                                    ))}
                                </StyledSupportersList>
                            ) : (
                                <SupportersCarousel supporters={data} />
                            )}
                        </section>
                        {showGoalGroupButton && (
                            <Button>
                                <StyledButtonText
                                    onClick={() =>
                                        navigate(
                                            generatePath(ROUTE_MESSAGE_CENTER_CHAT, {
                                                channel: `${CHAT_TYPE_GROUP}-${groupId}`
                                            })
                                        )
                                    }
                                >
                                    <Chat /> {t('goal_page:supporters:button_text')}
                                </StyledButtonText>
                            </Button>
                        )}
                    </StyledSupporterModal>
                )}
            </StyledSupportersRoot>
        </>
    )
}
