import dayjs, {ManipulateType} from 'dayjs'
import timezone from 'dayjs/plugin/timezone'
import utc from 'dayjs/plugin/utc'
import 'dayjs/locale/it'
import 'dayjs/locale/en'
import 'dayjs/locale/de'
import 'dayjs/locale/ko'
import 'dayjs/locale/ar'
import i18n from 'i18next'
import localizedFormat from 'dayjs/plugin/localizedFormat'
import isYesterday from 'dayjs/plugin/isYesterday'
import isToday from 'dayjs/plugin/isToday'
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore'
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter'

dayjs.extend(localizedFormat)
dayjs.extend(isYesterday)
dayjs.extend(isToday)
dayjs.extend(utc)
dayjs.extend(isSameOrBefore)
dayjs.extend(isSameOrAfter)

export const GLOBAL_DATE_FORMAT = 'MM/DD/YYYY'
export const convertToDayjs = (date?: Date | string) => dayjs(date)
export const formatDate = (date: string, formatType = GLOBAL_DATE_FORMAT) =>
    date ? dayjs(date).format(formatType) : '-'
export const isPasteDate = (date: string): boolean => dayjs(date)?.valueOf() < dayjs(new Date()).valueOf()
export const formatDateWithUTC = (date: Date | string, format: string = 'YYYY-MM-DD HH:mm:ss') =>
    date && dayjs(date).utc(true).local().format(format)

export const getTimezone = (): string => {
    dayjs.extend(utc)
    dayjs.extend(timezone)
    return dayjs.tz.guess()
}

export const displayChatDate = (inputDate: string) => {
    const day = dayjs(inputDate).format('dddd') // 'Friday'
    const isYesterday = dayjs(inputDate).isYesterday()
    const isToday = dayjs(inputDate).isToday()
    const isThisWeek = dayjs(inputDate).isAfter(dayjs().subtract(7, 'day'))
    if (isToday) return dayjs(inputDate).locale(i18n.language).format('LT')
    if (isYesterday) return i18n.t('common:yesterday')
    if (isThisWeek) return day
    return dayjs(inputDate).locale(i18n.language).format('L')
}

export const getLocalDate = (date: string, formatType?: string) => {
    const timeZone = getTimezone()
    const localDate = dayjs(date).tz(timeZone)

    if (formatType) {
        return formatDate(dayjs(localDate).format(), formatType)
    } else {
        return localDate.format()
    }
}

export const enum UnitsE {
    MONTHS = 'months',
    WEEK = 'week',
    MONTH = 'month',
    DAY = 'day',
    DAYS = 'days',
    HOUR = 'hour',
    MINUTE = 'minute',
    SECOND = 'second',
    MILLISECOND = 'millisecond',
    YEARS = 'years'
}

export const getDifference = (date: string, units: UnitsE) => {
    const pastDate = dayjs(date)
    const currentDate = dayjs()

    return dayjs(pastDate).diff(currentDate, units)
}

export const getGoalRangeDate = ({duration_value, unit}: {duration_value?: number; unit?: ManipulateType}) => {
    const min = dayjs()
    const max = duration_value && unit && min.add(duration_value, unit)
    const minDate = getLocalDate(dayjs(min.add(1, UnitsE.DAY)).format())
    const maxDate = getLocalDate(dayjs(max).format())

    return {minDate, maxDate}
}

export const getPastDateFromNow = (value: number, units: UnitsE) => {
    const currentDate = dayjs()
    const date = currentDate.subtract(value, units)
    return getLocalDate(dayjs(date).format())
}

export const getDateInObject = (date?: Date | string) => {
    const currentDate = dayjs(date)
    return {year: currentDate.year(), month: currentDate.month(), day: 1}
}

export const convertDateObjectToDate = ({day, month, year}: {year: number; month: number; day: number}) =>
    dayjs(`${year}-${+month}-${day}`)

export const getFirstDateInThisMonth = () => dayjs().startOf('month').toDate()

export default dayjs
