import styled, {DefaultTheme, css} from 'styled-components'

export const GuestFooterRoot = styled.div`
    ${({theme: {mediaQueries, spacing, shadows, palette}}) => css`
        width: 100vw;
        max-width: 650px;
        height: fit-content;
        background-color: ${palette.neutral.white};
        box-shadow: ${shadows.mdXl};
        cursor: pointer;
        position: absolute;
        bottom: 0;
        ${mediaQueries.m} {
            width: 100%;
            border-radius: ${spacing * 1.25}px ${spacing * 1.25}px 0px 0px;
        }
    `}
`

export const StyledTagWrapper = styled.div`
    position: absolute;
    top: -14px;
    width: 100%;
    display: flex;
    justify-content: center;
`

export const StyledText = styled.div`
    ${({theme: {spacing}, isBold}: {theme: DefaultTheme; isBold?: boolean}) => css`
        display: flex;
        justify-content: center;
        align-items: center;
        gap: ${spacing / 2}px;
        padding: ${spacing * 2.5}px ${spacing}px ${spacing * 2}px;
        text-align: center;
        ${isBold &&
        css`
            font-weight: 700;
        `}

        & svg {
            height: 13px;
            width: 13px;
        }
    `}
`

export const tagStyles = ({theme, background}: {theme: DefaultTheme; background: string}) => css`
    background: ${background};
    color: ${theme.palette.neutral.white};
    position: absolute;
    ${theme.typography.textSm}
    padding: ${theme.spacing / 2}px ${theme.spacing}px;
    height: auto;
`
