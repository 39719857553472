export const MUTATION_KEYS = {
    buy_micromorgi_package: 'buy-micromorgi-package',
    send_micromorgi: 'send-micromorgi',
    send_request_to_get_ultimate_status: 'send-request-to-get-ultimate-status'
}

export const QUERY_KEYS = {
    micromorgi_packages: 'micromorgi-packages',
    micromorgi_transactions: 'micromorgi-transactions',
    animations: 'animations',
    current_animation: 'current-animation'
}
