import {useMe} from '@/features/authentication/queries/useMe'
import {useRootStore} from '@/store'
import {selectChatChannels} from '@/store/selectors'
import {generateChatChannelName} from '@/utilities/helpers'

export const useGetConnectedUser = (userId?: number) => {
    const {data} = useMe()
    const channels = useRootStore(selectChatChannels)
    const channelId = !!data && !!userId && generateChatChannelName(+data.id, data.type, userId)
    const connectedUser = !!channelId && channels[channelId]

    return {connectedUser, channelId}
}

