import * as routes from '@/utilities/constants/routeNames'
import {USER_ROLE_REPRESENTATIVE} from '@/utilities/constants/user'
import {Navigate, Outlet, useLocation} from 'react-router-dom'
import {GuestType} from '@/features/authentication/types'

export const GuestRouteRedirect = ({user}: {user: GuestType}) => {
    const location = useLocation()
    const isUserProfileCompleted =
        !!user.chat_topics &&
        !!user.chat_topics.length &&
        !!user.favorite_activities &&
        !!user.favorite_activities.length &&
        !!user.spoken_languages &&
        !!user.spoken_languages.length &&
        !!user.type_attributes?.wish

    const needShowCompleteProfilePage = !user.gender?.id

    switch (true) {
        case needShowCompleteProfilePage:
            return <Navigate to={routes.ROUTE_COMPLETE_PROFILE} />
        // when new fields are added to the profile that old users must add
        case !isUserProfileCompleted &&
            ![routes.ROUTE_DELETE_PROFILE, routes.ROUTE_ADD_NEW_INFO_PAGE, routes.ROUTE_COMPLETE_PROFILE].includes(
                location.pathname
            ) &&
            user &&
            user.type !== USER_ROLE_REPRESENTATIVE:
            return <Navigate to={routes.ROUTE_WELCOME_BACK_PAGE} />
        default:
            return <Outlet />
    }
}
