import {useQuery} from '@tanstack/react-query'
import {QUERY_KEYS} from './keys'
import {GENERATED_CONTENT_SECURITY_API} from '../services/generatedContentSecurity.http'

export const useGetBadWordsList = () => {
    return useQuery({
        queryKey: [QUERY_KEYS.bad_words],
        queryFn: () => GENERATED_CONTENT_SECURITY_API.getBadWordsList()
    })
}
