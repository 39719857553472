import styled, {DefaultTheme, css} from 'styled-components'
type StyledPropsType = {
    isOpen: boolean
    theme: DefaultTheme
    side: 'left' | 'right'
    headerSize: number, 
    desktopMaxWidth: string
}
export const StyledRoot = styled.div`
    ${({headerSize, isOpen, side, desktopMaxWidth, theme: {palette, mediaQueries, zIndex}}: StyledPropsType) => css`
        position: fixed;
        bottom: 0;
        background-color: ${palette.neutral.white};
        overflow: visible;
        z-index: ${zIndex.sidePopover};
        transform: ${side === 'left' ? 'translateX(-100%)' : 'translateX(100%)'};
        overflow-y: auto;
        top: ${headerSize}px;
        left: 0px;
        right: 0px;
        width: 100vw;
        max-width: 100%;
        transition: none;
        ${isOpen &&
        css`
            transform: translateX(0);
        `}
        ${mediaQueries.m} {
            left: ${side === 'left' ? 0 : 'auto'};
            right: ${side === 'right' ? 0 : 'auto'};
            width: 100%;
            max-width: ${desktopMaxWidth};
            transition: transform 300ms;
        }
    `}
`
export const StyledOverlay = styled.div`
    ${({
        headerSize,
        isOpen,
        theme: {palette, zIndex}
    }: {
        isOpen: boolean
        theme: DefaultTheme
        headerSize: number
    }) => css`
        position: fixed;
        top: ${headerSize}px;
        left: 0;
        bottom: 0;
        right: 0;
        background-color: ${palette.neutral[700]};
        z-index: ${zIndex.sidePopover};
        display: ${isOpen ? 'block' : 'none'};
    `}
`
export const StyledContent = styled.div`
    width: 100%;
    height: 100%;
`
