import styled, {css} from 'styled-components'

export const StyledOptionContainer = styled.div`
    position: relative;
    visibility: 'visible';
    width: 100%;
    height: 100%;
`
export const StyledSelect = styled.div`
    ${({theme: {palette, spacing}}) => css`
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 ${spacing * 1.5}px;
        line-height: 1.8;
        background-color: ${palette.neutral.white};
        border: 1px solid hsl(0, 0%, 80%);
        border-radius: 4px;
        height: 100%;
    `}
`
export const StyledPlaceholder = styled.span`
    ${({theme: {palette}}) => css`
        line-height: 19.6px;
        color: ${palette.neutral['500']};
    `}
`

export const StyledArrow = styled.span`
    ${({theme: {palette}}) => css`
        width: 0;
        height: 0;
        border-left: 4px solid ${palette.neutral.transparent};
        border-right: 4px solid ${palette.neutral.transparent};
        border-top: 4px solid ${palette.primary['900']};
    `}
`

export const StyledOptions = styled.div`
    ${({theme: {typography, palette, spacing, shadows}}) => css`
        position: absolute;
        z-index: 1033;
        background-color: ${palette.neutral.white};
        border-radius: 10px;
        box-shadow: ${shadows['5xl']};
        margin-bottom: ${spacing}px;
        margin-top: ${spacing}px;
        width: 100%;
        padding: 0 0 10px 13px;
        font-size: ${typography.textMd};
        color: ${palette.primary['900']};
        max-height: 400px;
        overflow-y: auto;
        text-align: start;
    `}
`
export const StyledOptionsItem = styled.p`
    ${({theme: {spacing}}) => css`
        padding-top: ${spacing * 2}px;
        font-weight: 500;
    `}
`
