import {HostCard} from '../host-card/HostCard'
import {
    StyledContainer,
    StyledHeaderBox,
    StyledImage,
    StyledSpinnerContainer,
    StyledSplide,
    StyledSplideSlide,
    StyledTitle
} from './style'
import {SplideTrack} from '@splidejs/react-splide'
import {useTheme} from 'styled-components'
import Crown from '@assets/images/crown.png'
import {useSendSeenTalents} from '@/features/feed/queries/useSendSeenTalents'
import {useEffect, useState} from 'react'
import {Spinner} from '@/components/ui/spinner/Spinner'
import {useTranslation} from 'react-i18next'
import {HostPartialType} from '@/features/authentication/types'
import {LocalStorageManager} from '@/utilities/localStorage'
import {SplideType} from '@/components/ui/profile-carousel/ProfileCarousel'

export const TalentsCard = ({hosts}: {hosts: HostPartialType[]}) => {
    const {t} = useTranslation()
    const theme = useTheme()
    const [seenIdsArray, setSeenIdsArray] = useState<number[]>(LocalStorageManager.seenTalentIds.get() ?? [])

    const onSuccess = (id: number) => {
        setSeenIdsArray([id])
    }
    const {mutate, data: topHosts, isPending} = useSendSeenTalents(onSuccess)
    const hostIds = hosts?.map(item => item.id)[0]

    const setSeenTalentId = ({newIndex}: {splide: SplideType; newIndex: number; prevIndex: number}) => {
        setSeenIdsArray(prevState => {
            const currentTalentId = +`${topHosts?.data[newIndex]?.id}`
            // Check if id doesn't exist
            if (!prevState.includes(currentTalentId)) {
                return [...prevState, currentTalentId]
            }
            return prevState
        })
    }

    useEffect(() => {
        LocalStorageManager.seenTalentIds.set(seenIdsArray)
    }, [seenIdsArray])

    useEffect(() => {
        if (hostIds) {
            mutate({rookiesIDs: seenIdsArray.length ? seenIdsArray : ([hostIds] as number[])})
        }
    }, [])

    return (
        <>
            <StyledContainer>
                <StyledHeaderBox>
                    <StyledTitle>{t('talents_card:title')}</StyledTitle>
                    <div>
                        <StyledImage src={Crown} />
                    </div>
                </StyledHeaderBox>
                {isPending ? (
                    <StyledSpinnerContainer>
                        <Spinner overlay={false} color="white" inline />
                    </StyledSpinnerContainer>
                ) : (
                    <StyledSplide
                        options={{rewind: true, arrows: false, gap: 32, direction: theme.direction}}
                        hasTrack={false}
                        onMove={(splide: SplideType, newIndex: number, prevIndex: number) => {
                            setSeenTalentId?.({splide, newIndex, prevIndex})
                        }}
                    >
                        <SplideTrack>
                            {topHosts?.data?.map((topHost, i) => (
                                <StyledSplideSlide key={i}>
                                    <HostCard inTalentCard content={topHost} />
                                </StyledSplideSlide>
                            ))}
                        </SplideTrack>
                    </StyledSplide>
                )}
            </StyledContainer>
        </>
    )
}
