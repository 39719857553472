import {useMutation, useQueryClient} from '@tanstack/react-query'
import {MUTATION_KEYS, QUERY_KEYS as NOTIFICATION_QUERY_KEYS} from './keys'
import {NOTIFICATIONS_API} from '../services/notifications.http'

export const useSendSeenNotifications = () => {
    const queryClient = useQueryClient()
    return useMutation({
        mutationKey: [MUTATION_KEYS.notifications_seen],
        mutationFn: NOTIFICATIONS_API.sendSeenNotifications,
        onSuccess: () => {
            queryClient.invalidateQueries({queryKey: [NOTIFICATION_QUERY_KEYS.notification_unread_count]})
        }
    })
}
