import {CSSProperties, FC, ReactNode} from 'react'
import {DefaultTheme, FlattenSimpleInterpolation} from 'styled-components'
import {StyledMediaRoot, StyledOverlay} from './style'

interface MediaProps {
    children?: ReactNode
    withOverlay?: boolean
    onClick?: () => void
    mediaStyles?: (theme: DefaultTheme) => FlattenSimpleInterpolation
    imageUrl?: string | null
    imageUrlWithStyle?: string
    width?: number
    height?: number
    radius?: number
    variant?: CSSProperties['backgroundSize']
    className?: string
}

export const Media: FC<MediaProps> = ({
    children,
    onClick,
    withOverlay = false,
    imageUrl,
    imageUrlWithStyle,
    height,
    width,
    radius = 0,
    variant = 'cover',
    className,
    mediaStyles
}) => {
    return (
        <StyledMediaRoot
            className={className}
            onClick={onClick}
            mediaStyles={mediaStyles}
            height={height}
            width={width}
            radius={radius}
            imageUrl={imageUrl}
            imageUrlWithStyle={imageUrlWithStyle}
            variant={variant}
        >
            {withOverlay && <StyledOverlay />}
            {children}
        </StyledMediaRoot>
    )
}

Media.displayName = 'Media'
