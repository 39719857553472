import {
    StyledErrorWrapper,
    StyledAnimation,
    StyledButton,
    StyledOverlay,
    StyledErrorContainer,
    StyledLinkBox,
    StyledTitleBox,
    StyledLinkContainer
} from '@components/commons/error-box/style.ts'
import {FC} from 'react'
import {Animation} from '@/components/ui/animation/Animation'
import Alert from '@assets/animations/Alert-1.json'
import {useTranslation} from 'react-i18next'
import {useRootStore} from '@/store'
import {selectLogoutModal} from '@/store/selectors'
import Back from '../back/Back'
import {LogoutIcon} from '@/components/ui/icon'

interface ErrorBoxProps {
    title: string
}
export const ErrorBox: FC<ErrorBoxProps> = ({title}) => {
    const {t} = useTranslation()
    const {openModal: openLogoutModal} = useRootStore(selectLogoutModal)

    return (
        <>
            <StyledOverlay />
            <StyledErrorWrapper>
                <StyledErrorContainer>
                    <div>
                        <StyledLinkBox>
                            <Back />
                            <LogoutIcon onClick={() => openLogoutModal({})} />
                        </StyledLinkBox>
                        <StyledAnimation>
                            <Animation animationData={Alert} />
                        </StyledAnimation>
                    </div>
                    <StyledTitleBox>
                        <p>{title}</p>
                    </StyledTitleBox>
                    <StyledLinkContainer>
                        <StyledButton onClick={() => window.location.reload()}>
                            {t('common:reload_the_page')}
                        </StyledButton>
                    </StyledLinkContainer>
                </StyledErrorContainer>
            </StyledErrorWrapper>
        </>
    )
}

ErrorBox.displayName = 'ErrorBox'
