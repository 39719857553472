import styled, {css} from 'styled-components'

export const StyledMultiRangeSliderRoot = styled.div`
    ${({theme: {flexUtils, palette, spacing, shadows}}) => css`
        ${flexUtils.centered};
        height: 50px;
        position: relative;

        input[type='range'] {
            pointer-events: none;
            position: absolute;
            height: 0;
            width: 320px;
            outline: none;
        }

        input[type='range']::-webkit-slider-thumb {
            -webkit-appearance: none;
            -webkit-tap-highlight-color: transparent;
            background-color: ${palette.neutral.white};
            border: none;
            border-radius: 50%;
            box-shadow: ${shadows['10xl']};
            cursor: pointer;
            height: 34px;
            width: 34px;
            margin-top: ${spacing / 2}px;
            pointer-events: all;
            position: relative;
        }
    `}
`

export const StyledRangeSlider = styled.div`
    ${() => css`
        position: relative;
        width: 320px;
        height: 50px;
    `}
`

export const StyledSliderTrack = styled.div`
    ${({theme: {palette}}) => css`
        position: absolute;
        border-radius: 3px;
        height: 2px;
        background-color: ${palette.primary['900']};
        opacity: 0.3;
        width: 100%;
        z-index: 1;
        top: 50%;
    `}
`

export const StyledRange = styled.div`
    ${({theme: {palette}}) => css`
        position: absolute;
        border-radius: 3px;
        height: 2px;
        background-color: ${palette.primary['200']};
        z-index: 2;
        top: 50%;
    `}
`

export const StyledMinInput = styled.input`
    ${() => css`
        -webkit-appearance: 'none';
        -webkit-tap-highlight-color: 'transparent';
        z-index: 3;
        margin-right: 50px;
    `}
`

export const StyledMaxInput = styled.input`
    ${() => css`
        -webkit-appearance: 'none';
        -webkit-tap-highlight-color: 'transparent';
        z-index: 4;
        margin-left: 25px;
    `}
`
