import styled, {css} from 'styled-components'

export const StyledTitle = styled.div`
    ${({theme: {spacing, typography}}) => css`
        ${typography.textMd}
        margin: ${spacing * 1.25}px 0 ${spacing * 3.75}px;
        font-weight: 700;
        line-height: 140%;
    `}
`

export const StyledSpecialHostRoot = styled.div`
    ${() => css`
        padding-left: 1px;
    `}
`
