import {MicromorgiIconMotion} from '@/assets/icons/icons'
import {Button} from '@/components/ui/button/Button'
import styled, {css} from 'styled-components'

export const StyledCustomSendGiftModalWrapper = styled.div`
    ${({theme: {spacing}}) => css`
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding-top: 0px ${spacing * 3}px ${spacing * 3}px;

        gap: ${spacing * 3}px;
    `}
`

export const StyledTitle = styled.h2`
    ${({theme: {typography, palette}}) => css`
        ${typography.textXXl}
        text-align: center;
        font-weight: 400;
        letter-spacing: -0.05em;
        color: ${palette.primary[900]};
    `}
`

export const StyledPulseWrapper = styled.div`
    ${({theme: {zIndex}}) => css`
        position: fixed;
        top: -70px;
        left: 25%;
        z-index: ${zIndex.modalOverlay};
        display: flex;
        justify-content: center;
        align-items: center;
        pointer-events: none;
    `}
`
export const StyledCurrentBalance = styled.div`
    ${({theme: {palette, spacing}}) => css`
        width: 100%;
        border: ${spacing / 4}px solid ${palette.neutral[75]};
        display: flex;
        padding: ${spacing * 2}px ${spacing * 1.5}px ${spacing * 1.5}px;
        position: relative;
        align-items: center;
        border-radius: ${spacing * 1.5}px;
        flex-direction: column;
        justify-content: center;
    `}
`
export const StyledCurrentBalanceTitle = styled.span`
    ${({theme: {typography, palette, spacing}}) => css`
        ${typography.textSm}
        top: -${spacing * 1.5}px;
        padding: 0 ${spacing * 1.5}px;
        z-index: 1;
        position: absolute;
        background: ${palette.neutral.white};
    `}
`
export const StyledBalance = styled.h2`
    ${({theme: {palette}}) => css`
        font-size: 32px;
        height: 100%;
        display: flex;
        align-items: center;
        font-weight: 700;
        letter-spacing: -0.05em;
        color: ${palette.primary[900]};
    `}
`
export const StyledDescription = styled.p`
    ${({theme: {typography, palette, spacing}}) => css`
        ${typography.textSm}
        color: ${palette.neutral[25]};
        margin-bottom: ${spacing * 3}px;
        text-align: center;
        font-weight: 400;
        line-height: 20px;
    `}
`
export const StyledAmountForm = styled.form`
    ${({theme: {spacing}}) => css`
        display: flex;
        flex-direction: column;
        gap: ${spacing * 2}px;
    `}
`

export const Line = styled.div`
    ${({theme: {palette}}) => css`
        width: 100%;
        border-top: 1px solid ${palette.neutral[400]};
    `}
`

export const StyledSubmitButton = styled(Button)`
    ${({theme: {typography}}) => css`
        ${typography.textMd}
        font-weight: 600;
        & :disabled {
            cursor: not-allowed;
        }
    `}
`
export const StyledGemContainer = styled.div`
    ${() => css`
        position: absolute;
        top: -40px;
        left: 119px;
    `}
`
type StyledMicromorgiIconMotionAttrs = {$duration: number}

export const StyledMicromorgiIconMotion = styled(MicromorgiIconMotion).attrs<StyledMicromorgiIconMotionAttrs>(
    ({$duration}) => ({
        animate: {
            transition: {
                duration: $duration,
                delay: 2.3,
                type: 'ease-out',
                repeat: 2,
                repeatDelay: 0.3
            },
            scale: [1, 1.1, 1]
        }
    })
)<StyledMicromorgiIconMotionAttrs>`
    ${() => css``}
`

export const CustomSendGiftModalContainer = styled.div`
    ${({theme: {spacing}}) => css`
        padding: 0px ${spacing * 3}px ${spacing * 3}px;
    `}
`
