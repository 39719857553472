import {Avatar} from '@/components/ui/avatar/Avatar'
import {Button} from '@/components/ui/button/Button'
import {Modal} from '@/components/ui/modal/Modal'
import {useMe} from '@/features/authentication/queries/useMe'
import {GuestType} from '@/features/authentication/types'
import {HaveBannedElementObj} from '@/features/generated-content-security/types'
// import {PROOF_MODEL} from '@/features/goal/services/goal.schema'
import {useGetGuest} from '@/features/profile/queries/useGetGuest'
import useWindowDimensions from '@/hooks/useWindowDimensions'
import {useRootStore} from '@/store'
import {selectStarredMessageModal} from '@/store/selectors'
import {breakpoints} from '@/theme/mediaQueries'
import {USER_ROLE_GUEST} from '@/utilities/constants/user'
import {debounce} from 'lodash'
import {FC, useEffect, useState} from 'react'
import {useTranslation} from 'react-i18next'
import InspirationsStarredMessageModal from '../inspirations-starred-message-modal/InspirationsStarredMessageModal'
// import {useSendMessageToChannel} from '../queries/useSendMessageToChannel'
import {
    StyledInspirationButton,
    StyledInspirationsStarredMessageModalContainer,
    StyledSendMessageAvatar,
    StyledSendMessageContainer,
    StyledStarredMessageYellowIcon,
    StyledSubTitle,
    StyledTextareaWithBannedElementsTrap
} from './style'
import {getCorrectUserId, generateChatChannelName} from '@/utilities/helpers'
import useSendChatMessage from '../hooks/useSendChatMessage'
import {ChannelID} from '../types'
import {MessageTypeE} from '@/utilities/constants/chat'

const StarredMessageModal: FC = () => {
    const {t} = useTranslation()
    const {data: user} = useMe()
    const {width} = useWindowDimensions()
    const {closeModal, guestID} = useRootStore(selectStarredMessageModal)
    const [message, setMessage] = useState<string>()
    const [haveBannedContent, setHaveBannedContent] = useState<HaveBannedElementObj | null>(null)
    const [isOpenInspirationsStarredMessageModal, setIsOpenInspirationsStarredMessageModal] = useState(false)
    const {data} = useGetGuest(String(guestID))
    // const {mutate: sendMessageToChannel, error} = useSendMessageToChannel()
    const [isMobile, setIsMobile] = useState(width <= breakpoints.width.m)
    const correctUserId = user ? getCorrectUserId(user) : undefined

    const channelName =
        !!user && !!guestID && !!correctUserId && generateChatChannelName(correctUserId, user?.type ?? '', +guestID)

    const {sendChatMessage} = useSendChatMessage(channelName as ChannelID)

    useEffect(() => {
        setIsMobile(width <= breakpoints.width.m)
    }, [width])

    const guest = data?.type === USER_ROLE_GUEST ? (data as GuestType) : null

    const onSendMessage = () => {
        sendChatMessage({isStarred: true, userId: +String(correctUserId), text: message, type: MessageTypeE.TEXT})

        closeModal()
    }
    return (
        <>
            <Modal
                maxWidth="400px"
                title={t('starred_message_modal:title')}
                onClose={closeModal}
                subHeader={
                    <StyledSubTitle>
                        {t(`starred_message_modal:description_${guest?.gender?.key_name}`, {
                            LEADER_NAME: guest?.username
                        })}
                    </StyledSubTitle>
                }
            >
                <StyledInspirationsStarredMessageModalContainer>
                    <StyledSendMessageContainer>
                        <StyledSendMessageAvatar>
                            <Avatar
                                imageUrl={guest?.avatar?.url}
                                name={guest?.full_name}
                                radius={5}
                                height={isMobile ? 94 : 160}
                                width={isMobile ? 94 : 160}
                            />
                            <StyledStarredMessageYellowIcon height={isMobile ? 30 : 37} width={isMobile ? 30 : 37} />
                        </StyledSendMessageAvatar>
                        <StyledTextareaWithBannedElementsTrap
                            placeholder={t('starred_message_modal:placeholder', {LEADER_NAME: guest?.full_name})}
                            onChange={debounce(setMessage, 300)}
                            getHaveBannedElements={setHaveBannedContent}
                            // errorMessage={error?.response?.data?.message || ''}
                            inModal
                        />
                    </StyledSendMessageContainer>
                    <StyledInspirationButton
                        onClick={() => {
                            setIsOpenInspirationsStarredMessageModal(true)
                        }}
                    >
                        {t('starred_message_modal:inspiration_button')}
                    </StyledInspirationButton>
                    <Button onClick={onSendMessage} disabled={!message || haveBannedContent?.isBanned}>
                        {t('starred_message_modal:button')}
                    </Button>
                </StyledInspirationsStarredMessageModalContainer>
            </Modal>
            {isOpenInspirationsStarredMessageModal && (
                <InspirationsStarredMessageModal
                    leaderName={String(guest?.full_name)}
                    onClose={() => {
                        setIsOpenInspirationsStarredMessageModal(false)
                    }}
                />
            )}
        </>
    )
}
export default StarredMessageModal
