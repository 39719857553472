import styled, {css, DefaultTheme} from 'styled-components'

export const StyledOptionContainer = styled.div`
    ${({disabled, theme: {palette, spacing}}: {disabled?: boolean; theme: DefaultTheme}) => css`
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-top: ${spacing / 4}px;
        padding-left: ${spacing * 2}px;
        padding-right: ${spacing * 2}px;
        padding-bottom: ${spacing}px;
        cursor: ${disabled ? 'not-allowed' : 'pointer'};
        color: ${palette.primary['900']};
    `}
`

export const StyledOptionBox = styled.div`
    ${({theme: {flexUtils}}) => css`
        ${flexUtils.centered};
    `}
`

export const StyledOptionItemBox = styled.div`
    position: relative;
`

export const StyledOptionSelectBox = styled.div`
    height: 34px;
    width: 100%;
    max-width: 180px;
`

export const StyledOptionSpan = styled.span`
    ${({disabled, theme: {palette}}: {disabled?: boolean; theme: DefaultTheme}) => css`
        color: ${disabled ? palette.neutral['950'] : palette.primary['900']};
    `}
`
