import {Lens} from '@dhmk/zustand-lens'

type CancelRecurringGiftModalState = {
    isOpen: boolean
    hostID: string | null
}

type CancelRecurringGiftModalActions = {
    openModal: (params: Omit<CancelRecurringGiftModalState, 'isOpen'>) => void
    closeModal: () => void
}

export type CancelRecurringGiftModal = CancelRecurringGiftModalState & CancelRecurringGiftModalActions

const initialModal: CancelRecurringGiftModalState = {
    isOpen: false,
    hostID: null
}

const createCancelRecurringGiftModalSlice: Lens<CancelRecurringGiftModal> = set => ({
    ...initialModal,
    openModal: params => set(() => ({...params, isOpen: true})),
    closeModal: () => set(() => ({...initialModal}))
})

export default createCancelRecurringGiftModalSlice
