import styled from 'styled-components'
import {motion} from 'framer-motion'

type StyledPulseAttrs = {$duration: number}
export const StyledPulse = styled(motion.div).attrs<StyledPulseAttrs>(({$duration}) => {
    return {
        initial: {
            x: 0,
            y: 0,
            width: 150,
            height: 150,
            opacity: 0,
            backgroundColor: 'white',
            borderRadius: '100%'
        },
        animate: {
            transition: {
                duration: $duration,
                delay: 2.3,
                type: 'ease-out',
                repeat: 2,
                repeatDelay: 0.3
            },
            opacity: [0, 0.2, 0],
            scale: [2, 10],
            transitionEnd: {
                display: 'none'
            }
        }
    }
})<StyledPulseAttrs>`
    ${({theme: {zIndex}}) => `
    width: 150;
    height: 150;
    opacity: 0;
    background-color: 'white';
    position: 'fixed';
    top: '35%';
    left: '50%';
    border-radius: '100%';
    z-index: ${zIndex.modalOverlay};`}
`
