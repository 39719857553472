import {Lens} from '@dhmk/zustand-lens'

type AboutUsModalState = {
    isOpen: boolean
}

type AboutUsModalActions = {
    openModal: (params: Omit<AboutUsModalState, 'isOpen'>) => void
    closeModal: () => void
}

export type AboutUsModal = AboutUsModalState & AboutUsModalActions

const initialModal: AboutUsModalState = {isOpen: false}

const createAboutUsModalSlice: Lens<AboutUsModal> = set => ({
    ...initialModal,
    openModal: params => set(() => ({...params, isOpen: true})),
    closeModal: () => set(() => ({...initialModal}))
})

export default createAboutUsModalSlice
