import {useMe} from '@/features/authentication/queries/useMe.ts'
import {USER_ROLE_GUEST, USER_ROLE_HOST} from '@/utilities/constants/user'
import {Outlet} from 'react-router-dom'
import {GuestRouteRedirect} from './guest-route-redirect/GuestRouteRedirect'
import {GuestType, HostType} from '@/features/authentication/types'
import {HostRouteRedirect} from './host-route-redirect/HostRouteRedirect'

// the user here is logged in, but can have problems on his account
// here we check the user status and choose to let him inside or
// push it backwards into a logged-in blocking route
export const UserStatusControlRoute = () => {
    const {data: user} = useMe()
    const isHost = !!user && user.type === USER_ROLE_HOST
    const isGuest = !!user && user.type === USER_ROLE_GUEST

    switch (true) {
        case isGuest:
            return <GuestRouteRedirect user={user as GuestType} />
        case isHost:
            return <HostRouteRedirect user={user as HostType} />
        default:
            return <Outlet />
    }
}
