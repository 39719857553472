import {NewMicromorgiIcon, RedeemIcon} from '@/assets/icons/icons'
import {useRootStore} from '@/store'
import {FC} from 'react'
import {GiftList} from '../gift-list/GiftList'
import {MorgiList} from '../morgi-list/MorgiList'
import {StyledContent, StyledGiftPanelWrapper, StyledTab, StyledTabsList, StyledTabsWrapper} from './style'
import {TypeOfConfirmModalE} from '../../confirm-send-gift-modal/store/createConfirmSendGiftModalSlice'

type TabsValue = 'gift' | 'morgi'

export const TABS_VALUE: Record<string, TabsValue> = {
    gift: 'gift',
    morgi: 'morgi'
}

type GiftPanelProps = {
    hostId: string
    selectedTab?: TabsValue
    minDonation?: number
    typeOfConfirmSendModal?: TypeOfConfirmModalE
}

export const GiftPanel: FC<GiftPanelProps> = ({
    hostId,
    selectedTab = TABS_VALUE.gift,
    minDonation,
    typeOfConfirmSendModal
}) => {
    const {isPlayAnimation, delay} = useRootStore(state => state.animations.giftPanel)

    return (
        <StyledGiftPanelWrapper $isPlayAnimation={isPlayAnimation} $delay={delay}>
            <StyledTabsWrapper defaultValue={selectedTab}>
                <StyledTabsList>
                    <StyledTab value={TABS_VALUE.gift}>
                        <RedeemIcon />
                    </StyledTab>
                    <StyledTab value={TABS_VALUE.morgi}>
                        <NewMicromorgiIcon width={16} height={22} />
                    </StyledTab>
                </StyledTabsList>
                <StyledContent value={TABS_VALUE.gift}>
                    <MorgiList hostID={hostId} minDonation={minDonation} />
                </StyledContent>
                <StyledContent value={TABS_VALUE.morgi}>
                    <GiftList
                        hostID={hostId}
                        minDonation={minDonation}
                        typeOfConfirmSendModal={typeOfConfirmSendModal}
                    />
                </StyledContent>
            </StyledTabsWrapper>
        </StyledGiftPanelWrapper>
    )
}
