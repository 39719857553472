import {palette as mainPalette} from './palette'
import mediaQueries, {breakpoints} from './mediaQueries'
import ResetStyles from './reset'
import {shadows} from './shadows'
import {css, DefaultTheme} from 'styled-components'
import {typography} from './typography'
import {DirectionsE} from '@/types'

const GlobalStyles = ResetStyles
export const spacing = 8

interface ThemeInterface {
    name?: 'light'
    //TODO: we will support the dark mode decommenting this one and adding the dark types to our style.d.ts
    /*name: 'light' | 'dark'*/
    direction?: DirectionsE
}

const getAppTheme = ({name = 'light', direction = DirectionsE.LTR}: ThemeInterface): DefaultTheme => {
    const palette = mainPalette[name]
    const sizing = {
        headerMobileHeight: '64px',
        headerDesktopHeight: '110px',
        goalSupportersSectionHeight: '120px',
        feedSectionHeaderMobile: '138px',
        moengageIconWidth: '80px',
        saveHostIconWidth: '60px',
        desktopSidebarWidth: '530px'
    }
    const zIndex = {
        modalOverlay: 1033,
        modal: 1033,
        sidePopover: 1029,
        nav: 1032,
        componentOverlay: 3,
        profileAlert: 1029,
        tooltip: 1034,
        top: 1034,
        header: 1030,
        headerBanner: 1031,
        topSpinner: 1029
    }

    const flexUtils = {
        centered: css`
            display: flex;
            align-items: center;
            justify-content: center;
        `,
        start: css`
            display: flex;
            align-items: center;
            justify-content: flex-start;
        `,
        end: css`
            display: flex;
            align-items: center;
            justify-content: flex-end;
        `,
        columnSpaceBetween: css`
            display: flex;
            align-items: center;
            justify-content: space-between;
        `
    }

    const truncateText = css`
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
    `
    const label = {
        position: 'relative',
        color: palette.primary['900'],
        fontWeight: '700',
        fontSize: 14,
        lineHeight: '1.8',
        marginBottom: 8
    }
    const input = {
        width: '100%',
        fontSize: 16,
        border: `1px solid ${palette.neutral['400']}`,
        borderRadius: 4,
        position: 'relative',
        color: palette.primary['900'],
        padding: spacing * 2,
        outline: 'none',
        '&::placeholder': {
            color: palette.neutral['500']
        },
        '&:focus': {
            boxShadow: `0 0 0 2px ${palette.primary['400']}`,
            borderColor: palette.primary['200']
        },
        '&[readonly], &[disabled]': {
            cursor: 'default',
            border: `none`,
            backgroundColor: palette.neutral['950'],
            color: palette.neutral['25']
        }
    }

    const inputError = {
        borderColor: palette.danger['25'],
        '&:focus': {
            boxShadow: `0 0 0 2px ${palette.warning['400']}`,
            borderColor: palette.danger['25']
        }
    }

    const textarea = {
        ...input,
        resize: 'none',
        minHeight: 40,
        outline: 'none',
        lineHeight: 'auto'
    }

    const control = {label, input, textarea, inputError}

    const link = css`
        ${typography.textSm};
        cursor: pointer;
        text-decoration: none;
        color: ${palette?.primary?.['700']};
        font-weight: 600;
        &:hover {
            color: ${palette?.neutral?.['900']};
            text-decoration: none;
        }
        &:focus {
            color: ${palette?.neutral?.['900']};
        }
        &:visited {
            color: ${palette?.neutral?.['500']};
        }
        &:active {
            color: ${palette?.primary?.['700']};
        }
        &:link {
            color: ${palette?.neutral?.['600']};
        }
    `

    const scrollbar = css`
        &::-webkit-scrollbar {
            background: transparent;
            height: 5px;
        }
        &::-webkit-scrollbar-thumb {
            background: ${palette?.primary?.['900']};
        }
        scrollbar-width: thin;
        -webkit-scrollbar-width: thin;
    `

    return {
        spacing,
        flexUtils,
        zIndex,
        mediaQueries,
        breakpoints,
        palette,
        typography,
        control,
        shadows,
        truncateText,
        sizing,
        link,
        scrollbar,
        direction
    }
}

export {GlobalStyles, getAppTheme}
