import { FC } from 'react'
import avatar from '@assets/images/avatar1.png'
import gucciBug from '@assets/images/gucciBug.png'
import { useNavigate } from 'react-router-dom'
import { ROUTE_GOALS_MAIN_PAGE } from '@utilities/constants/routeNames.ts'
import { Trans, useTranslation } from 'react-i18next'
import {
    StyledBannerText,
    StyledGoalBanner,
    StyledGoalImage,
    StyledGoalName,
    StyledGoalTeaserContainer,
    StyledGoalWrapper,
} from './style'
import { DashedEgg } from '@/assets/icons/icons'
import { Button } from '@/components/ui/button/Button'
import useMixPanel from '@/integrations/mixpanel/hooks/useMixpanel'
import { MixpanelEventNameE } from '@/integrations/mixpanel/types'
import { LocalStorageManager } from '@/utilities/localStorage'

type GoalTeaserCardProps = {
    index: number
}

const dataForCard = [
    {
        image: gucciBug,
        userAvatar: avatar,
        goalName: 'Gucci Bag',
        description: `interactive_feed:goal_card:description:variant_0`,
        teaserColor: 'yellow',
    },
    {
        image: gucciBug,
        userAvatar: avatar,
        goalName: 'Gucci Bag',
        description: `interactive_feed:goal_card:description:variant_1`,
        teaserColor: 'yellow',
    },
    {
        image: gucciBug,
        userAvatar: avatar,
        goalName: 'Gucci Bag',
        description: `interactive_feed:goal_card:description:variant_2`,
        teaserColor: 'yellow',
    },
]

export const GoalTeaserCard: FC<GoalTeaserCardProps> = ({ index }) => {
    const navigate = useNavigate()
    const { t } = useTranslation()
    const mixpanelWrapper = useMixPanel()
    const handleGoalTeaserClick = () => {
        mixpanelWrapper?.trackEvent(
            MixpanelEventNameE.UserClickedOnGoalTeaserOnFeed,
            {
                teasers_seen_by_user_lifetime:
                    LocalStorageManager.fiftyRookieCardSessionCount.get(),
                teaser_color: dataForCard[index].teaserColor,
            }
        )
        navigate(ROUTE_GOALS_MAIN_PAGE)
    }

    return (
        <StyledGoalTeaserContainer>
            <StyledGoalWrapper>
                <StyledGoalImage url={dataForCard[index ?? 0]?.image} />
                <StyledGoalName>
                    <img src={dataForCard[index ?? 0]?.userAvatar} />
                    <p>
                        <Trans
                            i18nKey={'interactive_feed:goal_card:title'}
                            components={{ bold: <strong /> }}
                            values={{
                                GOAL_NAME: dataForCard[index ?? 0]?.goalName,
                            }}
                        />
                    </p>
                </StyledGoalName>
            </StyledGoalWrapper>
            <StyledGoalBanner>
                <DashedEgg />
                <StyledBannerText>
                    <p>
                        <Trans i18nKey={dataForCard[index ?? 0]?.description} components={{div: <span />}} />
                    </p>
                    <Button onClick={handleGoalTeaserClick}>
                        <span>{t(`interactive_feed:goal_card:button`)}</span>
                    </Button>
                </StyledBannerText>
            </StyledGoalBanner>
        </StyledGoalTeaserContainer>
    )
}
