import {envVars} from '@/envVars'
import {GifsResponseType} from '../types'
import axios from 'axios'
const fetchTrending = 'https://api.giphy.com/v1/gifs/trending'
const fetchSearch = 'https://api.giphy.com/v1/gifs/search'

export const GIFS_API = {
    getGifs: async ({limit = 10, offset}: {limit?: number; offset?: number}): Promise<GifsResponseType> => {
        const url = fetchTrending
        const {data} = await axios.get(url, {params: {api_key: envVars.VITE_APP_GIPHY_API_KEY, limit, offset}})
        return data
    },
    getSearchGifs: async ({
        limit = 10,
        q = '',
        offset
    }: {
        limit?: number
        q?: string
        offset?: number
    }): Promise<GifsResponseType> => {
        const url = fetchSearch
        const {data} = await axios.get(url, {params: {api_key: envVars.VITE_APP_GIPHY_API_KEY, limit, q, offset}})
        return data
    }
}
