import {GoalStatusesE, GoalType} from '@/features/goal/types'
import {useTranslation} from 'react-i18next'
import {useMemo} from 'react'
import {StyledCanceled, StyledEditButton} from './style'
import {MIN_SUCCESS_PERCENTAGE} from '@/features/goal/constant/goal'

type HostStatusSectionProps = {
    goal: Partial<GoalType>
    onEditClick?: () => void
    onWithdrawClick?: () => void
    percentage: number
}

export const HostDetailsStatusSection = ({goal, onEditClick, onWithdrawClick, percentage}: HostStatusSectionProps) => {
    const {t} = useTranslation()
    const showWithdrawButton = percentage >= MIN_SUCCESS_PERCENTAGE && goal?.status !== GoalStatusesE.SUSPENDED

    const content = useMemo(() => {
        switch (goal?.status) {
            case GoalStatusesE.ACTIVE:
                return (
                    <StyledEditButton onClick={showWithdrawButton ? onWithdrawClick : onEditClick}>
                        {showWithdrawButton
                            ? t('goal_page:goal_details:withdraw_button')
                            : t('goal_page:goal_details:edit_goal_active')}
                    </StyledEditButton>
                )

            case GoalStatusesE.SUSPENDED:
                return (
                    <StyledEditButton onClick={onEditClick}>
                        {t('goal_page:goal_details:edit_goal_suspended')}
                    </StyledEditButton>
                )
            case GoalStatusesE.CANCELED:
                return <StyledCanceled>{t('goal_page:goal_details:status_canceled')}</StyledCanceled>
            default:
                return null
        }
    }, [goal?.status])

    return <>{content}</>
}

// !!!IMPORTANT - this rules only for HOST side!!!

// 1. If status "pending", "review", "awaiting_proof", "proof_pending_approval", "successful" - don't show anything
// 2. If status "active" - show "edit goal time" button
// 3. If status "suspended" - show "edit goal" button
// 4. If status "active" and percentage >= 75% - show "Withdraw Morgis" button
// 5. If status "canceled" - show red "canceled" word
