import React, {ReactNode} from 'react'
import {StyledCircleBackground, StyledCircleProgress, StyledProgressRoot, StyledSvg, StyledText} from './style'
import {useTheme} from 'styled-components'

interface CircleProgressProps {
    percentage?: number
    size?: number
    strokeWidth?: number
    circleColor?: string
    progressColor?: string | string[]
    icon?: ReactNode
    animated?: boolean
    staticCircleStrokeWidth?: number
    staticCircleStrokeColor?: string | string[]
    isEmpty?: boolean
    percentageColor?: string
}

export const CircleProgress: React.FC<CircleProgressProps> = ({
    percentage = 0,
    size = 65,
    strokeWidth = 5,
    staticCircleStrokeWidth = 1,
    staticCircleStrokeColor,
    progressColor,
    icon,
    animated = true,
    isEmpty = false,
    percentageColor
}) => {
    const theme = useTheme()
    const radius = (size - strokeWidth) / 2
    const circumference = radius * 2 * Math.PI
    const offset = circumference - (percentage / 100) * circumference
    const isGradientProgressColor = Array.isArray(progressColor)
    const gradientId = progressColor && `${progressColor[0]}${progressColor[1]}${progressColor[2]}`.replace(/#/g, '')

    const isStaticColorGradient = Array.isArray(staticCircleStrokeColor)
    const staticColorGradientId =
        staticCircleStrokeColor &&
        `${staticCircleStrokeColor[0]}${staticCircleStrokeColor[1]}${staticCircleStrokeColor[2]}`.replace(/#/g, '')

    const StaticCircleColor = isStaticColorGradient
        ? `url(#${staticColorGradientId})`
        : staticCircleStrokeColor ?? `${theme.palette.neutral['650']}`

    return (
        <StyledProgressRoot>
            <StyledSvg width={size} height={size}>
                <StyledCircleBackground
                    cx="50%"
                    cy="50%"
                    r={radius}
                    strokeWidth={staticCircleStrokeWidth}
                    circleColor={StaticCircleColor}
                />

                {isGradientProgressColor ? (
                    <defs>
                        <linearGradient
                            id={gradientId}
                            x1="0%"
                            y1="0%"
                            x2="100%"
                            y2="0%"
                            gradientTransform="rotate(90)"
                        >
                            {progressColor.map((color, index) => (
                                <stop
                                    key={index}
                                    offset={`${((index + 1) / progressColor.length) * 100}%`}
                                    stopColor={color}
                                />
                            ))}
                        </linearGradient>
                    </defs>
                ) : null}

                <StyledCircleProgress
                    cx="50%"
                    cy="50%"
                    r={radius}
                    strokeWidth={strokeWidth}
                    progressColor={isGradientProgressColor ? `url(#${gradientId})` : progressColor}
                    offset={offset}
                    circumference={circumference}
                    animated={animated}
                />

                {!icon && !isEmpty && (
                    <StyledText
                        x="50%"
                        y="-50%"
                        textAnchor="middle"
                        dominantBaseline="middle"
                        transform="rotate(90)"
                        percentageColor={percentageColor}
                    >
                        {Math.round(percentage)}%
                    </StyledText>
                )}
            </StyledSvg>
            {icon && <span>{icon}</span>}
        </StyledProgressRoot>
    )
}
