import {Lens} from '@dhmk/zustand-lens'

type GoalDeletionSuccessModalState = {
    isOpen: boolean
}

type GoalDeletionSuccessModalActions = {
    openModal: (params: Omit<GoalDeletionSuccessModalState, 'isOpen'>) => void
    closeModal: () => void
}

export type GoalDeletionSuccessModal = GoalDeletionSuccessModalState & GoalDeletionSuccessModalActions

const initialModal: GoalDeletionSuccessModalState = {isOpen: false}

const createGoalDeletionSuccessModalSlice: Lens<GoalDeletionSuccessModal> = set => ({
    ...initialModal,
    openModal: params => set(() => ({...params, isOpen: true})),
    closeModal: () => set(() => ({...initialModal}))
})

export default createGoalDeletionSuccessModalSlice
