import {AvatarFallback, AvatarImage, AvatarRoot} from '@components/ui/avatar/style.ts'
import {getInitials, getUniqueColor} from '@utilities/helpers'
import NoAvatarImage from '@assets/images/noAvatar.png'
import {useTheme} from 'styled-components'
interface AvatarProps {
    name?: string
    imageUrl?: string
    width?: number
    height?: number
    radius?: number
    initialFontSize?: number
}

export const Avatar = ({name, imageUrl, height = 40, width = 40, radius = 50, initialFontSize}: AvatarProps) => {
    const {palette} = useTheme()

    const colorSet = [
        `${palette.danger[125]}`,
        `${palette.secondary[200]}`,
        `${palette.secondary[200]}`,
        `${palette.secondary[400]}`,
        `${palette.primary[950]}`,
        `${palette.success[125]}`,
        `${palette.primary[400]}`,
        `${palette.secondary[300]}`,
        `${palette.danger[100]}`,
        `${palette.warning[400]}`,
        `${palette.tertiary[300]}`
    ]
    const uniqueColor = getUniqueColor(colorSet, name)
    const initials = getInitials(name).slice(0, 2)[0].toLocaleUpperCase()

    return (
        <AvatarRoot
            height={height}
            width={width}
            radius={radius}
            $uniqueColor={!imageUrl && !!name ? uniqueColor : undefined}
        >
            <AvatarImage src={!imageUrl && !name ? NoAvatarImage : imageUrl} alt={name} height={height} width={width} />
            {!!name && (
                <AvatarFallback $initialFontSize={initialFontSize} delayMs={600}>
                    {initials}
                </AvatarFallback>
            )}
        </AvatarRoot>
    )
}
Avatar.displayName = 'Avatar'
