import {Chat, MicromorgiIcon} from '@/assets/icons/icons'
import {Spinner} from '@/components/ui/spinner/Spinner'
import {ROUTE_MESSAGE_CENTER_CHAT} from '@/utilities/constants/routeNames'
import {FC} from 'react'
import {useTranslation} from 'react-i18next'
import {useNavigate} from 'react-router-dom'
import {
    StyledButtonForRedirectToChat,
    StyledProfileBannerContainer,
    StyledProfileBannerCost,
    StyledProfileBannerDescription,
    StyledProfileBannerImg,
    StyledProfileBannerImgWrapper,
    StyledProfileBannerInform,
    StyledProfileBannerTitle,
    StyledProfileBannerWrapper
} from './style'

export const CHAT_TYPE_GROUP = 'group'

type ProfileBannerProps = {
    cost: number
    image: string | JSX.Element
    description: string | JSX.Element
    title: string
    isLoading?: boolean
    onClick?: () => void
    isRedirectToChat?: boolean
    groupId?: number
}

export const ProfileBanner: FC<ProfileBannerProps> = ({
    cost,
    description,
    image,
    title,
    isLoading = false,
    onClick,
    isRedirectToChat = false,
    groupId
}) => {
    const {t} = useTranslation()
    const navigate = useNavigate()
    return (
        <StyledProfileBannerContainer onClick={onClick}>
            {!isLoading ? (
                <StyledProfileBannerWrapper>
                    <StyledProfileBannerImgWrapper>
                        {typeof image === 'string' ? <StyledProfileBannerImg src={image} /> : image}
                    </StyledProfileBannerImgWrapper>

                    <StyledProfileBannerInform>
                        <StyledProfileBannerTitle>{title}</StyledProfileBannerTitle>
                        <StyledProfileBannerCost>
                            {t('top_fan_card:cost')}
                            <MicromorgiIcon width={18} height={18} />
                            <span>{cost}</span>
                        </StyledProfileBannerCost>
                        <StyledProfileBannerDescription>{description}</StyledProfileBannerDescription>
                    </StyledProfileBannerInform>
                    {isRedirectToChat && (
                        <StyledButtonForRedirectToChat
                            onClick={e => {
                                e.stopPropagation()
                                navigate(
                                    ROUTE_MESSAGE_CENTER_CHAT.replace(':channel?', `${CHAT_TYPE_GROUP}-${groupId}`)
                                )
                            }}
                        >
                            <Chat width={30} height={30} opacity={0.2} />
                        </StyledButtonForRedirectToChat>
                    )}
                </StyledProfileBannerWrapper>
            ) : (
                <Spinner />
            )}
        </StyledProfileBannerContainer>
    )
}
