import { useContext } from 'react'
import { MoEngageSdkContext } from '../MoengageSdkProvider'

const useMoEngageSDK = () => {
    const context = useContext(MoEngageSdkContext)
    if (context) {
        return context.moEngageWrapper
    } 
    return
}

export default useMoEngageSDK