import { Modal } from '@/components/ui/modal/Modal'
import { useGetGuest } from '@/features/profile/queries/useGetGuest'
import { useRootStore } from '@/store'
import { selectPauseConnectionModal } from '@/store/selectors'
import PauseConnectionImage from '@assets/images/pause-connection.svg'
import { FC, useEffect } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { usePauseConnection } from '../queries/usePauseConnection'
import {
    StyledButton,
    StyledPauseConnectionImage,
    StyledPauseConnectionModalContainer,
    StyledPauseConnectionModalDescription,
    StyledPauseConnectionModalTitle,
} from './style'
import useMixPanel from '@/integrations/mixpanel/hooks/useMixpanel'
import { MixpanelEventNameE } from '@/integrations/mixpanel/types'
import { UserRoleE } from '@/types'
import { Spinner } from '@/components/ui/spinner/Spinner'
import { useHandleError } from '@/hooks/useHandleError'

export const PauseConnectionModal: FC = () => {
    const { t } = useTranslation()
    const { closeModal, guestID, channelID } = useRootStore(
        selectPauseConnectionModal
    )

    const { data: guest } = useGetGuest(+String(guestID), !!guestID)
    const mixpanel = useMixPanel()

    const eventData = {
        userRole: UserRoleE.Host,
        userId: `${guestID}`,
    }

    useEffect(() => {
        mixpanel?.triggerBackendEvent({
            eventType: MixpanelEventNameE.PauseConnectionClick,
            ...eventData,
        })
    }, [])

    const onSuccess = () => {
        mixpanel?.triggerBackendEvent({
            eventType: MixpanelEventNameE.PausedConnectionSuccess,
            ...eventData,
        })
        closeModal()
    }
    const { mutate, isPending, isError, error } = usePauseConnection(onSuccess)

    useHandleError({ isError, error })

    const onPauseConnection = () => {
        if (channelID) mutate({ channelID })
    }

    return (
        <Modal
            maxWidth="400px"
            minHeight="400px"
            title={t('common:pausing_a_connection')}
            onClose={closeModal}
        >
            <StyledPauseConnectionModalContainer>
                {isPending && <Spinner />}
                <StyledPauseConnectionModalTitle>
                    <Trans
                        t={t}
                        i18nKey="chat_pause_connection:title"
                        values={{ LEADER: guest?.full_name }}
                        components={{ strong: <strong /> }}
                    />
                </StyledPauseConnectionModalTitle>
                <StyledPauseConnectionImage src={PauseConnectionImage} />
                <StyledPauseConnectionModalDescription>
                    <Trans
                        t={t}
                        i18nKey="chat_pause_connection:description"
                        values={{ LEADER: guest?.full_name }}
                        components={{ strong: <strong /> }}
                    />
                </StyledPauseConnectionModalDescription>
                <StyledButton variant="secondary" onClick={onPauseConnection}>
                    {t('common:pause_your_connection')}
                </StyledButton>
            </StyledPauseConnectionModalContainer>
        </Modal>
    )
}
