import {HostSchema, UserSchema} from '@/features/authentication/services/auth.schema'
import {PageSchema} from '@/features/common/services/common.schema'
import {z} from 'zod'
import {GoalStatusesE, PoofStatusesE} from '../types'
import {MediaTypeE} from '@/types'
import {GuestProfileSchema} from '@/features/profile/services/profile.schema'

const GoalStatuses = z.nativeEnum(GoalStatusesE)

const ProofStatuses = z.nativeEnum(PoofStatusesE)

const GoalMediaTypes = z.nativeEnum(MediaTypeE)

const ProofSchema = z.object({
    type: z.string(),
    url: z.string()
})
export enum GoalTypesE {
    SMALL_GOAL_SIZE = 'small_size_goal',
    MEDIUM_GOAL_SIZE = 'medium_size_goal',
    LARGE_GOAL_SIZE = 'large_size_goal'
}

const GoalTypes = z.nativeEnum(GoalTypesE)

export const GoalSizeSchema = z.object({
    currency_type: z.string(),
    duration_type: z.string(),
    duration_value: z.number(),
    id: z.number(),
    is_active: z.number(),
    max: z.number(),
    min: z.number(),
    type: GoalTypes
})

export const enum GOAL_MODEL {
    Name = 'name',
    Details = 'details',
    TargetAmount = 'target_amount',
    StartDate = 'start_date',
    EndDate = 'end_date',
    IsForOther = 'is_for_others',
    ForMySelf = 'for_my_self',
    ProofData = 'proof_data',
    HasImageProof = 'has_image_proof',
    HasVideoProof = 'has_video_proof',
    ProofNote = 'proof_note',
    PathsLocations = 'paths_locations',
    TypeId = 'type_id',
    Radiobox = 'radiobox',
    ForOthersMessage = 'for_others_message'
}

export const goalTargetAmountErrorMessage = (type: GoalTypesE) => {
    switch (type) {
        case GoalTypesE.SMALL_GOAL_SIZE:
            return 'validation:snap_goal_amount_value'
        case GoalTypesE.MEDIUM_GOAL_SIZE:
            return 'validation:special_goal_amount_value'
        case GoalTypesE.LARGE_GOAL_SIZE:
            return 'validation:grande_goal_amount_value'
        default:
            return null
    }
}

interface RequestCreateGoalSchemaProps {
    minTargetAmount?: number
    maxTargetAmount?: number
    type?: GoalTypesE
}

export const CreateGoalValidation = ({
    minTargetAmount = 0,
    maxTargetAmount = 0,
    type = GoalTypesE.SMALL_GOAL_SIZE
}: RequestCreateGoalSchemaProps) =>
    z.object({
        [GOAL_MODEL.Name]: z
            .string()
            .nonempty({message: 'validation:required_goal_name'})
            .max(50, {message: 'validation:max_goal_name_length'}),
        [GOAL_MODEL.PathsLocations]: z.array(z.string()).refine(value => value.length, {
            message: 'validation:required_goal_media'
        }),
        [GOAL_MODEL.Details]: z
            .string()
            .nonempty({message: 'validation:required_goal_details'})
            .max(150, {message: 'validation:max_goal_details_length'}),
        [GOAL_MODEL.TargetAmount]: z.string().refine(
            val => {
                const num = Number(val)
                return !isNaN(num) && num >= minTargetAmount && num <= maxTargetAmount
            },
            {
                message: goalTargetAmountErrorMessage(type) || ''
            }
        ),
        [GOAL_MODEL.EndDate]: z.string().nonempty({message: 'validation:required_goal_end_date'}),
        [GOAL_MODEL.ForOthersMessage]: z
            .object({
                [GOAL_MODEL.IsForOther]: z.string(),
                [GOAL_MODEL.Radiobox]: z.string().optional()
            })
            .refine(
                data => {
                    const isForOther = data[GOAL_MODEL.IsForOther] === GOAL_MODEL.IsForOther
                    const noRadiobox = !data[GOAL_MODEL.Radiobox]

                    return !isForOther || !noRadiobox
                },
                {
                    message: 'validation:required_goal_for_whom_others'
                }
            ),
        [GOAL_MODEL.ProofNote]: z.string().nonempty({message: 'validation:required_goal_proof_note'}),
        [GOAL_MODEL.ProofData]: z
            .object({
                [GOAL_MODEL.HasImageProof]: z.boolean(),
                [GOAL_MODEL.HasVideoProof]: z.boolean()
            })
            .refine(value => value[GOAL_MODEL.HasImageProof] || value[GOAL_MODEL.HasVideoProof], {
                message: 'validation:select_one_type_of_proof'
            })
    })

export const CreateGoalValidationSchema = CreateGoalValidation({})

export const CreationGoalSchema = z.object({
    [GOAL_MODEL.Name]: z.string(),
    [GOAL_MODEL.PathsLocations]: z.array(z.string()),
    [GOAL_MODEL.Details]: z.string(),
    [GOAL_MODEL.TargetAmount]: z.string(),
    [GOAL_MODEL.IsForOther]: z.boolean(),
    [GOAL_MODEL.ProofNote]: z.string(),
    [GOAL_MODEL.HasImageProof]: z.boolean(),
    [GOAL_MODEL.HasVideoProof]: z.boolean(),
    [GOAL_MODEL.EndDate]: z.string(),
    [GOAL_MODEL.TypeId]: z.string(), //number
    [GOAL_MODEL.StartDate]: z.string(),
    [GOAL_MODEL.Radiobox]: z.string().optional()
})

export const enum PROOF_MODEL {
    Message = 'message',
    Video = 'video',
    Photo = 'photo',
    Proofs = 'proofs'
}

interface RequestProofSchemaProps {
    hasImageProof?: boolean
    hasVideoProof?: boolean
}

export const ProofGoalValidation = ({hasImageProof = true, hasVideoProof = true}: RequestProofSchemaProps) =>
    z.object({
        [PROOF_MODEL.Message]: z
            .string()
            .nonempty({message: 'validation:proof_message_required'})
            .max(120, {message: 'validation:proof_message_length'}),
        [PROOF_MODEL.Video]: z.array(ProofSchema).refine(
            data => {
                return !hasVideoProof || data.length > 0
            },
            {
                message: 'validation:proof_video_required'
            }
        ),
        [PROOF_MODEL.Photo]: z.array(ProofSchema).refine(
            data => {
                return !hasImageProof || data.length > 0
            },
            {
                message: 'validation:proof_photo_required'
            }
        )
    })

export const ProofGoalValidationSchema = ProofGoalValidation({})

export const GoalMediaSchema = z.object({
    goal_id: z.number(),
    id: z.number(),
    is_main: z.boolean(),
    path_location: z.string(),
    type: GoalMediaTypes,
    url: z.string()
})

export const GoalProofSchema = z.object({
    admin_id: z.number().nullable(),
    created_at: z.string().datetime(),
    declined_reason: z.union([z.string(), z.null()]),
    goal_id: z.number(),
    id: z.number(),
    path_location: z.string(),
    status: ProofStatuses,
    type: GoalMediaTypes,
    updated_at: z.string().datetime(),
    url: z.string()
})

export const GoalSchema = z.object({
    broadcast: z
        .object({
            created_at: z.string().datetime(), //parse to date 2023-12-14T15:26:15.000000Z
            display_name: z.string(),
            id: z.number(),
            is_goal: z.boolean()
        })
        .nullable(),
    cancelled_at: z.union([z.string().datetime(), z.null()]),
    cancelled_reason: z.union([z.string(), z.null()]),
    created_at: z.string().datetime(),
    currency_type: z.string(),
    details: z.string(),
    donations_count: z.union([z.string(), z.number()]),
    end_date: z.string().datetime(),
    goal_group: z
        .object({
            created_at: z.string().datetime(),
            goal_id: z.number(),
            id: z.number(),
            key_name: z.string(),
            updated_at: z.string().datetime(),
            user_id: z.number()
        })
        .nullable(),
    has_image_proof: z.boolean(),
    has_video_proof: z.boolean(),
    id: z.number(),
    is_for_others: z.boolean(),
    is_saved: z.boolean(),
    leader_donations_amount: z.number(),
    media: z.array(GoalMediaSchema),
    name: z.string(),
    proof_note: z.string(),
    proofs: z.array(GoalProofSchema).nullable(),
    radiobox: z.union([z.string(), z.null()]),
    rookie: HostSchema.partial(),
    rookie_id: z.number(),
    slug: z.string(),
    start_date: z.string().datetime(),
    status: GoalStatuses,
    target_amount: z.string(),
    thank_you_message: z.string(),
    type: GoalSizeSchema,
    type_id: z.number()
})

export const EditGoalSchema = z.object({
    [GOAL_MODEL.Name]: z
        .string()
        .nonempty({message: 'validation:required_goal_name'})
        .max(50, {message: 'validation:max_goal_name_length'}),
    [GOAL_MODEL.Details]: z
        .string()
        .nonempty({message: 'validation:required_goal_details'})
        .max(150, {message: 'validation:max_goal_details_length'}),
    [GOAL_MODEL.EndDate]: z.string()
})

export const EditGoalEndDateValidationSchema = z.object({
    [GOAL_MODEL.EndDate]: z.string().datetime()
})

export const EditMediaSchema = z.object({
    [GOAL_MODEL.EndDate]: z.string(),
    [GOAL_MODEL.PathsLocations]: z.array(z.string())
})

export const SortParamSchema = z.object({
    time_range: z.optional(z.number()),
    order_by: z.optional(z.string()),
    order_direction: z.optional(z.string())
})

export const GetGoalsListRequestSchema = z.object({
    page: z.string().optional(),
    limit: z.number().optional(),
    typeId: z.string().optional(),
    sortParams: z.optional(SortParamSchema),
    pathIDs: z.array(z.string()).optional()
    // subPathIds: z.array(z.number()).optional()
})

const Path = z.object({
    id: z.number(),
    name: z.string(),
    key_name: z.string(),
    is_subpath: z.boolean(),
    parent_id: z.number().nullable(),
    prepend: z.string(),
    goal_count: z.number()
})

export const GoalsResponseSchema = PageSchema(GoalSchema)
export const PathGoalsResponseSchema = PageSchema(Path)

export const CreateBroadcastSchema = z.object({
    users: z.array(z.number()),
    goals: z.array(z.number()),
    message: z.string().nullable(),
    teaser: z.boolean().optional(),
    goal_id: z.number().optional(),
    media: z.string().optional(),
    type: z.string().optional(),
    morgi: z.number().optional(),
    meta: z
        .object({
            representative_id: z.number().optional(),
            representative_first_name: z.string().optional(),
            rookie: z.object({full_name: z.string().optional()}).optional(),
            message: z.string().nullable().optional()
        })
        .optional()
})

export const MessageSchema = z.object({
    id: z.number(),
    broadcast_id: z.number(),
    created_at: z.string(),
    representative_id: z.number().optional(),
    representative_first_name: z.string().optional(),
    paid_message_id: z.number().optional(),
    paid_message: z
        .object({
            message: z.string(),
            morgi: z.number()
        })
        .optional(),
    morgi: z.number().optional(),
    message: z.string(),
    attachment: z
        .object({
            id: z.number(),
            url: z.string(),
            type: z.string(),
            morgi: z.number().optional(),
            created_at: z.string(),
            broadcast_message_id: z.number()
        })
        .optional(),
    teaser: z
        .object({
            id: z.number(),
            goal_id: z.number(),
            group_message_id: z.number(),
            type: z.string()
        })
        .nullable()
        .optional()
})

export const BroadcastSchema = z.object({
    id: z.number(),
    sender_id: z.number(),
    is_goal: z.boolean(),
    display_name: z.string().optional(),
    goals: z.array(GoalSchema),
    users: z.array(UserSchema),
    sender: UserSchema,
    image_link: z.string(),
    created_at: z.string(),
    representative_id: z.number().optional(),
    messages: z.array(MessageSchema)
})

export const NicknameOfLeaderSchema = z.object({
    nickname: z.string(),
    nicknamer_id: z.number(),
    nicknamed_id: z.number(),
    updated_at: z.string(),
    created_at: z.string(),
    id: z.number()
})

export const ProofRequestSchema = z.object({
    message: z.string(),
    proofs: z.array(ProofSchema)
})

export const GetSavedGoalsListSchema = z.object({
    page: z.optional(z.number()),
    limit: z.optional(z.number()),
    sortParam: z.optional(SortParamSchema)
})

export const GetPastGoalsListSchema = z.object({
    page: z.optional(z.number()),
    limit: z.optional(z.number()),
    rookieId: z.string()
})

export const GetSupportedGoalsListSchema = z.object({
    page: z.optional(z.number()),
    limit: z.optional(z.number()),
    sortParam: z.optional(SortParamSchema),
    isActive: z.number()
})

export const GoalsPathsRequestSchema = z.object({
    name: z.optional(z.string()),
    typeId: z.optional(z.string()),
    sortParams: z.optional(SortParamSchema)
})

export const UpdateLeaderNicknameRequestSchema = z.object({
    leaderId: z.string(),
    nicknameId: z.string(),
    data: z.object({nickname: z.string()})
})

export const SupporterSchema = GuestProfileSchema.extend({
    type_attributes: z.object({
        leader_donations_amount: z.number()
    })
})
