import {typography} from '@/theme/typography'
import styled, {css} from 'styled-components'

export const StyledWrapper = styled.div`
    ${({theme: {mediaQueries, spacing}}) => css`
        position: relative;
        height: 100%;
        width: 100%;
        display: grid;
        grid-template-rows: auto 1fr auto;
        overflow: hidden;
        padding: ${spacing * 2.5}px;

        ${mediaQueries.m} {
            max-width: 530px;
        }
    `}
`

export const StyledHeader = styled.div`
    ${({theme: {spacing}}) => css`
        display: grid;
        gap: ${spacing * 2}px;
    `}
`

export const StyledTitleWrapper = styled.div`
    ${({theme: {spacing, mediaQueries}}) => css`
        display: grid;
        grid-template-columns: 0fr 1fr;
        align-items: center;
        margin-bottom: ${spacing * 2}px;
        & h1 {
            margin: 0px;
            padding: ${spacing}px 0px;
            text-align: center;
            font-weight: 500;
            letter-spacing: -0.05em;
            ${typography.textXXl}

            ${mediaQueries.m} {
                ${typography.displayMd}
            }
        }
        & svg {
            cursor: pointer;
        }
    `}
`

export const StyledContent = styled.div`
    ${({theme: {spacing}}) => css`
        height: 100%;
        display: grid;
        padding-right: ${spacing * 2.5}px;
        overflow-y: auto;
    `}
`

export const StyledFilterItem = styled.div`
    ${({theme: {typography, spacing}}) => css`
        padding: ${spacing}px 0;

        & div {
            align-items: center;
            display: flex;
        }

        & label {
            ${typography.textLg};
            font-weight: 700;
        }
    `}
`

export const StyledFilterText = styled.div`
    ${({theme: {typography, spacing}}) => css`
        ${typography.textLg};
        font-weight: 700;
        padding-bottom: ${spacing}px;
    `}
`

export const StyledButtonSection = styled.div`
    ${({theme: {spacing, mediaQueries}}) => css`
        width: 100%;
        padding-top: ${spacing * 2}px;

        ${mediaQueries.m} {
            & button {
                width: 80%;
                margin: 0 auto;
            }
        }
    `}
`

export const StyledClearAll = styled.div`
    ${({theme: {spacing, palette, typography}}) => css`
        margin: 0 auto;
        text-align: center;
        padding: ${spacing * 2}px;
        color: ${palette.primary['700']};
        cursor: pointer;
        ${typography.textMd};
        font-weight: 700;
        transition: opacity 0.5s;
        &[aria-disabled='true'] {
            opacity: 0.6;
            cursor: default;
        }
    `}
`
export const StyledInputWrapper = styled.div`
    ${({theme: {spacing}}) => css`
        padding-bottom: ${spacing * 2}px;
    `}
`
