import {envVars} from '@/envVars'

const basePubnubConfig = {
    restore: true,
    heartbeatInterval: 0,
    publishKey: envVars.VITE_APP_PUBNUB_PUBLISH_KEY,
    subscribeKey: envVars.VITE_APP_PUBNUB_SUBSCRIBE_KEY,
    // cipherKey: "myCipherKey",
    logVerbosity: false,
    // ssl: true,
    presenceTimeout: 130
}

export default basePubnubConfig
