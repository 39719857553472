import {StyledPopoverContent, StyledPopoverTrigger} from '@/components/ui/popover/style'
import * as RPopover from '@radix-ui/react-popover'
import {PropsWithChildren, ReactNode, forwardRef} from 'react'
import {useTheme} from 'styled-components'

type PopoverProps = {
    rootProps?: RPopover.PopoverProps
    triggerProps?: RPopover.PopoverTriggerProps
    contentProps?: RPopover.PopoverContentProps
    trigger?: ReactNode
    className?: string
} & PropsWithChildren

export const PopoverForDatePicker = forwardRef<HTMLDivElement, PopoverProps>(
    ({trigger, children, rootProps, triggerProps, contentProps, className}, ref) => {
        const theme = useTheme()
        return (
            <RPopover.Root {...rootProps}>
                <StyledPopoverTrigger {...triggerProps}>{trigger}</StyledPopoverTrigger>

                <StyledPopoverContent
                    style={{zIndex: theme.zIndex.modal}}
                    ref={ref}
                    sideOffset={8}
                    className={className}
                    {...contentProps}
                >
                    {children}
                </StyledPopoverContent>
            </RPopover.Root>
        )
    }
)
