import styled, {css} from 'styled-components'
import {Splide, SplideSlide} from '@splidejs/react-splide'

export const StyledSplide = styled(Splide)`
    ${({theme: {palette}}) => css`
        position: relative;
        width: 100%;
        height: 100%;

        .splide__pagination__page {
            height: 8px;
            width: 8px;
            border-radius: 50%;
            opacity: 50;
            background-color: ${palette.neutral.white};
        }
        .splide__pagination__page.is-active {
            height: 10px;
            width: 10px;
            border-radius: 50%;
            opacity: 100;
            background-color: ${palette.neutral.white};
            transform: none;
        }
    `}
`

export const StyledSplideSlide = styled(SplideSlide)(
    ({theme: {spacing}}) => css`
        background-color: transparent;
        display: flex;
        align-items: center;
        flex-direction: column;
        text-align: center;
        margin-bottom: ${spacing * 1.25}px;
        padding: ${spacing * 2.5}px;
    `
)

export const StyledTitle = styled.div`
    ${({theme: {mediaQueries, palette}}) => css`
        color: ${palette.neutral.white};
        font-size: 16px;
        font-style: italic;
        ${mediaQueries.l} {
            font-size: 40px;
        }
    `}
`

export const StyledContainer = styled.div`
    ${({theme: {mediaQueries}}) => css`
        background: linear-gradient(30deg, rgb(172, 110, 247) 35%, rgb(134, 73, 209) 100%);
        border-radius: 10px;
        position: relative;
        min-height: 255px;
        ${mediaQueries.l} {
            min-height: 358px;
        }
    `}
`

export const StyledHeaderBox = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 20px;
`

export const StyledImage = styled.img`
    ${({theme: {mediaQueries}}) => css`
        width: 100%;
        height: 100%;
        ${mediaQueries.l} {
            width: 32px;
            height: 32px;
        }
    `}
`

export const StyledSpinnerContainer = styled.div`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
`
