import {BackIcon} from '@/assets/icons/icons'
import {ReactNode} from 'react'
import {StyledInfoBox, StyledPageHeader, StyledTitle} from './style'

interface PageHeaderProps {
    title: string | ReactNode
    onBack?: () => void
    extraContent?: JSX.Element
    infoIcon?: JSX.Element
    withMargin?: boolean
}

export const PageHeader = ({title, onBack, extraContent = <></>, infoIcon, withMargin}: PageHeaderProps) => {
    return (
        <>
            <StyledPageHeader>
                {onBack && (
                    <span>
                        <BackIcon onClick={onBack} />
                    </span>
                )}
                <StyledTitle infoIcon={!!infoIcon} $withMargin={withMargin}>
                    {title}
                    {!!infoIcon && <StyledInfoBox>{infoIcon}</StyledInfoBox>}
                </StyledTitle>
                {extraContent}
            </StyledPageHeader>
        </>
    )
}
