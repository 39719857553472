import {useMe} from '@/features/authentication/queries/useMe'
import {useEditUser} from '@/features/profile/queries/useEditUser'
import {APP_LANGUAGES, FALLBACK_LANGUAGE} from '@/translations/i18n'
import {FC} from 'react'
import {useTranslation} from 'react-i18next'
import {OptionType} from '../../singleSelect/types'
import {StyledSelectAppsLanguageWrapper, StyledSingleSelect} from './style'

const options: OptionType[] = APP_LANGUAGES.map(option => ({
    id: option.value,
    label: option.value.split('_')[0].toUpperCase(),
    name: option.label,
    value: option.value
}))

export const SelectAppsLanguage: FC = () => {
    const {i18n} = useTranslation()
    const {mutate: editProfile} = useEditUser()
    const {data: user} = useMe()

    const defaultValue = () => {
        const correctLanguage = options.find(lang => lang.label === i18n.language?.toUpperCase().replace(/[-_].*/, ''))
        if (correctLanguage) {
            return correctLanguage
        } else {
            return options.find(lang => lang.label === FALLBACK_LANGUAGE)
        }
    }

    const onChangeLanguage = (selectedLanguage: OptionType) => {
        i18n.changeLanguage(selectedLanguage.value)

        user &&
            editProfile({
                language: selectedLanguage.value,
                spoken_languages_ids: user?.spoken_languages.map(({id}) => id)
            })
    }
    return (
        <StyledSelectAppsLanguageWrapper>
            <StyledSingleSelect
                name="AppLanguage"
                options={options}
                value={defaultValue()}
                onChange={onChangeLanguage}
                isSearchable={false}
            />
        </StyledSelectAppsLanguageWrapper>
    )
}
