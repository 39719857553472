import {Lens} from '@dhmk/zustand-lens'

type MeetMoreHostModalState = {
    isOpen: boolean
}

type MeetMoreHostModalActions = {
    openModal: (params: Omit<MeetMoreHostModalState, 'isOpen'>) => void
    closeModal: () => void
}

export type MeetMoreHostModal = MeetMoreHostModalState & MeetMoreHostModalActions

const initialModal: MeetMoreHostModalState = {isOpen: false}

const createMeetMoreHostModalSlice: Lens<MeetMoreHostModal> = set => ({
    ...initialModal,
    openModal: params => set(() => ({...params, isOpen: true})),
    closeModal: () => set(() => ({...initialModal}))
})

export default createMeetMoreHostModalSlice
