import {useLexicalComposerContext} from '@lexical/react/LexicalComposerContext'
import {useEffect} from 'react'

export function MyCustomAutoFocusPlugin() {
    const [editor] = useLexicalComposerContext()

    useEffect(() => {
        // Focus the editor when the effect fires!
        editor.focus()
    }, [editor])

    return null
}
