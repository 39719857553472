import styled, {DefaultTheme, css} from 'styled-components'

export const StyledEmptySection = styled.div`
    ${({theme: {mediaQueries, spacing, sizing}, vh}: {theme: DefaultTheme; vh: number}) => css`
        display: grid;
        grid-template-rows: 1fr 0fr;
        justify-content: center;
        padding: ${spacing * 2}px;
        align-items: center;
        height: 100%;
        min-height: calc(${vh}px - (${sizing.headerMobileHeight}));
        ${mediaQueries.m} {
            min-height: calc(${vh}px - (${sizing.headerDesktopHeight}));
        }
    `}
`

export const StyledTitleWrapper = styled.div`
    ${({theme: {typography, mediaQueries, palette, spacing}}) => css`
        height: 100%;
        display: flex;
        align-items: center;

        & p {
            text-align: center;
            ${typography.displayMd}
            background: ${palette.gradient[400]};
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            margin: ${spacing * 5}px auto;
            ${mediaQueries.m} {
                font-size: 52px;
            }
        }
    `}
`

export const StyledButtonWrapper = styled.div`
    ${({theme: {mediaQueries, spacing}}) => css`
        margin: ${spacing}px 0px 0px;
        padding: ${spacing * 3}px 0px;
        ${mediaQueries.m} {
            margin: ${spacing * 3}px 0px 0px;
        }
        & button {
            margin: 0 auto;
        }
    `}
`
