import styled, {DefaultTheme, css} from 'styled-components'

export const StyledTabsRoot = styled.div`
    ${() => css`
        display: grid;
        justify-content: space-between;
        grid-template-columns: repeat(3, auto);
    `}
`

export const StyledTab = styled.div`
    ${({
        theme: {palette, spacing, typography, mediaQueries},
        isActive,
        activeBackground,
        disabled
    }: {
        theme: DefaultTheme
        isActive: boolean
        activeBackground: string
        disabled: boolean
    }) => css`
        ${typography.textXs};
        display: grid;
        grid-template-columns: 0fr auto 0fr;
        align-items: center;
        gap: ${spacing / 2}px;
        padding: ${spacing * 0.75}px ${spacing}px;
        border: 1px solid ${palette.primary['900']};
        border-radius: 26px;
        font-weight: 700;
        text-transform: capitalize;
        cursor: ${disabled ? 'not-allowed' : 'pointer'};
        color: ${isActive ? palette.neutral.white : palette.primary['900']};
        background-color: ${isActive ? activeBackground : palette.neutral.white};
        transition:
            color 0.3s ease-in-out,
            background-color 0.3s ease-in-out;
        opacity: ${disabled ? '0.5' : '1'};

        & svg {
            height: 18px;
            width: 23px;
        }

        ${mediaQueries.m} {
            padding: ${spacing * 1.5}px;
            ${typography.textXl};
            & svg {
                height: 23px;
                width: 33px;
            }
        }
    `}
`
