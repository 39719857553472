import {BackIcon, SearchIcon} from '@/assets/icons/icons'
import Checkbox from '@/components/commons/checkbox/CheckBox'
import {InputText} from '@/components/commons/input-text/InputText'
import {Button} from '@/components/ui/button/Button'
import {SidePopover} from '@/components/ui/side-popover/SidePopover'
import {Spinner} from '@/components/ui/spinner/Spinner'
import {useHandleError} from '@/hooks/useHandleError'
import useWindowDimensions from '@/hooks/useWindowDimensions'
import useMixPanel from '@/integrations/mixpanel/hooks/useMixpanel'
import {MixpanelEventNameE} from '@/integrations/mixpanel/types'
import {useRootStore} from '@/store'
import {selectGoalFilters} from '@/store/selectors'
import {breakpoints} from '@/theme/mediaQueries'
import {debounce} from 'lodash'
import {ChangeEvent, useEffect, useMemo, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {useTheme} from 'styled-components'
import {useGoalsPaths} from '../../queries/useGoalsPaths'
import {FilterTabs} from '../filter-tabs/FilterTabs'
import {
    StyledButtonSection,
    StyledClearAll,
    StyledContent,
    StyledFilterItem,
    StyledFilterText,
    StyledHeader,
    StyledInputWrapper,
    StyledTitleWrapper,
    StyledWrapper
} from './style'

type GoalFiltersProps = {
    isOpen: boolean
    onClose: () => void
}

export const GoalFilters = ({isOpen, onClose}: GoalFiltersProps) => {
    const {t} = useTranslation()
    const {palette} = useTheme()
    const {width} = useWindowDimensions()
    const mixpanel = useMixPanel()
    const {sortParams, filters, setFilters, clearGoalFilters} = useRootStore(selectGoalFilters)
    const [typeId, setTypeId] = useState<string | undefined>()
    const [typeName, setTypeName] = useState<string | undefined>()
    const [searchValue, setSearchValue] = useState('')
    const [pathIDs, setPathIDs] = useState<string[]>(filters.pathIDs)
    const [selectedFiltersNames, setSelectedFiltersNames] = useState<string[]>([])
    const {sizing} = useTheme()

    const [isMobile, setIsMobile] = useState(width <= breakpoints.width.m)
    const disabledClearAllButton = !(
        typeId ||
        pathIDs.length ||
        selectedFiltersNames.length ||
        selectedFiltersNames.length
    )
    useEffect(() => {
        setIsMobile(width <= breakpoints.width.m)
    }, [width])

    const {data, isLoading, isError, error} = useGoalsPaths({
        typeId,
        sortParams,
        name: searchValue ? searchValue : undefined
    })

    useEffect(() => {
        if (isOpen) {
            setTypeId(filters.typeId)
            setPathIDs(filters.pathIDs)
            setSelectedFiltersNames(filters.selectedFiltersNames)
        }
    }, [isOpen])

    useHandleError({isError, error})

    const onApply = () => {
        setFilters({typeId, pathIDs, selectedFiltersNames})
        mixpanel?.trackEvent(MixpanelEventNameE.GoalFilterApplied, {goal_type: typeName ?? 'none', path: pathIDs})
        onClose()
    }

    const onChange = (e: ChangeEvent<HTMLInputElement>) => {
        const eventValue = e.target.value
        setSearchValue(eventValue)
    }

    const handleCheckPath = (id: string, name: string) => {
        if (!pathIDs.includes(id)) {
            setPathIDs([...new Set([...pathIDs, id])])
            setSelectedFiltersNames([...new Set([...selectedFiltersNames, name])])
        } else {
            setPathIDs(pathIDs.filter(checkedID => checkedID !== id))
            setSelectedFiltersNames(selectedFiltersNames.filter(checkedName => checkedName !== name))
        }
    }

    const onClear = () => {
        if (disabledClearAllButton) return
        setFilters({typeId: undefined, pathIDs: [], selectedFiltersNames: []})
        setTypeId(undefined)
        setPathIDs([])
        setSelectedFiltersNames([])
        clearGoalFilters()
    }

    const changeType = (typeId?: string, typeName?: string) => {
        setPathIDs([])
        setSelectedFiltersNames([])
        setTypeId(typeId)
        setTypeName(typeName)
    }

    const subtitle = useMemo(() => {
        switch (true) {
            case !!searchValue:
                return t('modal_goals:title_result')
            case !!typeId:
                return t('modal_goals:title_suggested')
            default:
                return t('modal_goals:title_paths')
        }
    }, [searchValue, typeId])

    return (
        <SidePopover isOpen={isOpen} onClose={onClose} desktopMaxWidth={sizing.desktopSidebarWidth}>
            {isLoading && <Spinner />}
            <StyledWrapper>
                <StyledHeader>
                    <StyledTitleWrapper>
                        <BackIcon onClick={onClose} fill={palette.neutral[25]} />
                        <h1>{t('path_filter:filter_by')}</h1>
                    </StyledTitleWrapper>

                    <FilterTabs
                        typeId={typeId}
                        changeType={changeType}
                        numberGoalsByType={data?.metadata?.type_counter}
                    />
                    <StyledInputWrapper>
                        <InputText
                            icon={
                                <SearchIcon
                                    fill="rgb(115, 131, 156)"
                                    height={isMobile ? 21 : 30}
                                    width={isMobile ? 21 : 30}
                                />
                            }
                            type="text"
                            name="test"
                            placeholder={t('modal_goals:search_path')}
                            onChange={debounce(onChange, 300)}
                            autoComplete="off"
                        />
                    </StyledInputWrapper>

                    <StyledFilterText>{subtitle}</StyledFilterText>
                </StyledHeader>

                <StyledContent>
                    {data?.data && (
                        <div>
                            {data?.data.map(item => (
                                <StyledFilterItem key={item.id}>
                                    <Checkbox
                                        id={`${item.id}`}
                                        name={`${item.name}`}
                                        label={`${item.name} (${item.goal_count})`}
                                        onClick={() => handleCheckPath(`${item.id}`, `${item.name}`)}
                                        checked={!!pathIDs.includes(`${item.id}`)}
                                        readOnly
                                    />
                                </StyledFilterItem>
                            ))}
                        </div>
                    )}
                </StyledContent>

                <StyledButtonSection>
                    <Button onClick={onApply}>{t('chat_topics_filter:apply_button')}</Button>{' '}
                    <StyledClearAll aria-disabled={disabledClearAllButton} onClick={onClear}>
                        {t('chat_topics_filter:clear_all_button')}
                    </StyledClearAll>
                </StyledButtonSection>
            </StyledWrapper>
        </SidePopover>
    )
}
