import {useQuery} from '@tanstack/react-query'
import {QUERY_KEYS} from './keys'
import {CHAT_FAV_TOPICS_API} from '../services/chatFavTopics.http'

export const useGetFilterFavActivities = (is_popular = true, filter_for_component = false) => {
    return useQuery({
        queryKey: [QUERY_KEYS.filter_favorite_activities],
        queryFn: () => CHAT_FAV_TOPICS_API.getFilterFavActivities(is_popular, filter_for_component)
    })
}
