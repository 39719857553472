import {useQuery} from '@tanstack/react-query'
import {QUERY_KEYS} from './keys'
import {AUTH_API} from '../services/auth.http'

export const useGetGenders = ({
    enabled = true,
    isLeader,
    appLang
}: {
    enabled?: boolean
    isLeader?: boolean
    appLang?: string
}) => {
    return useQuery({
        queryKey: [QUERY_KEYS.genders, isLeader, appLang],
        queryFn: () => AUTH_API.getGenders(isLeader),
        enabled
    })
}
