import {FC} from 'react'
import {FeedVariantE} from '../../constants'
import {Trans, useTranslation} from 'react-i18next'
import {StyledFeedHeading} from './style'

type FeedHeadingProps = {
    variant: FeedVariantE
}

export const FeedHeading: FC<FeedHeadingProps> = ({variant}) => {
    const {t} = useTranslation()

    switch (variant) {
        case FeedVariantE.goals:
            return (
                <StyledFeedHeading>
                    <Trans t={t} i18nKey="goals_main_page:filter_title" components={{div: <div />}} />
                </StyledFeedHeading>
            )
        default:
            return (
                <StyledFeedHeading>
                    <Trans t={t} i18nKey="interactive_feed:title" components={{div: <div />}} />
                </StyledFeedHeading>
            )
    }
}
