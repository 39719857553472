import {Lens} from '@dhmk/zustand-lens'
import {TopicsResponse} from '../types'
import {LanguagesType} from '@/features/country/types'

type ChatFavTopicsState = {topics: Record<string, string>; languages: Record<string, string>}

const getTopicsForStore = (list: TopicsResponse) =>
    list.reduce((acc, topic) => Object.assign({[topic.key_name]: topic.name}, acc), {})

const getLanguagesForStore = (list: LanguagesType[]) =>
    list.reduce((acc, language) => Object.assign({[language.code]: language.name}, acc), {})

type ChatFavTopicsActions = {
    setTopics: (topics: TopicsResponse) => void
    setLanguages: (languages: LanguagesType[]) => void
}

export type ChatFavTopics = ChatFavTopicsState & ChatFavTopicsActions

const initialModal: ChatFavTopicsState = {
    topics: {},
    languages: {}
}

const createChatFavTopicsSlice: Lens<ChatFavTopics> = set => ({
    ...initialModal,
    setTopics: topics => set(() => ({topics: getTopicsForStore(topics)})),
    setLanguages: languages => set(() => ({languages: getLanguagesForStore(languages)}))
})

export default createChatFavTopicsSlice
