import styled, {css} from 'styled-components'

export const StyledAnimationBox = styled.div`
    width: 205px;
    height: 205px;
    margin: 0 auto;
`
export const StyledSuccessContainer = styled.div`
    ${({theme: {spacing}}) => css`
        text-align: center;
        display: flex;
        flex-direction: column;
        padding: 0 ${spacing * 3}px ${spacing * 4}px;
        gap: ${spacing * 3.25}px;
    `}
`

export const StyledSuccessTitleBox = styled.div`
    ${({theme: {spacing}}) => css`
        padding-bottom: ${spacing * 2}px;
    `}
`

export const StyledSuccessTitle = styled.span`
    ${({theme: {typography}}) => css`
        ${typography.textXXl};
        font-weight: 400;
        line-height: 27px;
        letter-spacing: -0.05em;
    `}
`

export const StyledSuccessSubTitleBox = styled.div`
    ${({theme: {spacing}}) => css`
        padding-bottom: ${spacing * 2}px;
    `}
`

export const StyledSuccessSubTitle = styled.span`
    ${({theme: {palette}}) => css`
        color: ${palette.neutral['25']};
        text-align: center;
        font-weight: 400;
        line-height: 20px;
        white-space: pre-wrap;
    `}
`
