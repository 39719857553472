import {ChannelID, PubnubMessage} from '@/features/chat/types'
import {usePubNub} from 'pubnub-react'
import useMarkAsRead from './useMarkAsRead'

const usePubnubSendMessage = (channel?: ChannelID) => {
    const pubnub = usePubNub()
    const {markAsRead} = useMarkAsRead(channel as ChannelID)

    // Util to send a message
    const sendMessage = (message: PubnubMessage) => {
        if (channel) {
            pubnub.publish(
                {
                    channel: `${channel}`,
                    message
                },
                (status, response) => {
                    markAsRead(`${response.timetoken}`)
                    if (status.statusCode === 200) {
                        // Sent successfully
                    }
                }
            )
        }
    }

    // We return the util to send a message
    return {sendMessage}
}

export default usePubnubSendMessage
