import {TopMegaFanIcon} from '@/assets/icons/icons'
import {useMe} from '@/features/authentication/queries/useMe'
import {HostType} from '@/features/authentication/types'
import {useGetHost} from '@/features/guest/queries/useGetHost'
import useMixPanel from '@/integrations/mixpanel/hooks/useMixpanel'
import {MixpanelEventNameE} from '@/integrations/mixpanel/types'
import {ROUTE_TOP_FAN_GUESTSBOARD} from '@/utilities/constants/routeNames'
import {USER_ROLE_HOST} from '@/utilities/constants/user'
import {FC} from 'react'
import {Trans, useTranslation} from 'react-i18next'
import {useNavigate} from 'react-router-dom'
import {ProfileBanner} from '../profile-banner/ProfileBanner'

type TopFanBannerProps = {
    userName?: string
}
export const TopFanBanner: FC<TopFanBannerProps> = ({userName}) => {
    const {t} = useTranslation()
    const navigate = useNavigate()

    const {data} = useMe()
    const me = data?.type === USER_ROLE_HOST ? (data as HostType) : null
    const isMe = me ? userName === me?.username : false
    const {data: host, isLoading: isLoadingHost} = useGetHost({filter: isMe ? undefined : userName, isUsername: true})

    const hostTopFansInfos = (isMe ? me : host)?.type_attributes.top_fans_infos
    const count = hostTopFansInfos ? hostTopFansInfos.completed_counter : 0
    const cost = hostTopFansInfos ? hostTopFansInfos.min_amount_to_reach : 0
    const pendingCounter = hostTopFansInfos ? hostTopFansInfos.pending_counter : 0
    const groupIdForRedirect = hostTopFansInfos?.group_id

    const isRedirectToChat: boolean =
        isMe && Boolean((isMe ? me : host)?.type === USER_ROLE_HOST) && !!groupIdForRedirect

    const mixpanel = useMixPanel()
    const onProfileBannerClick = () => {
        !isLoadingHost && navigate(ROUTE_TOP_FAN_GUESTSBOARD, {state: {userName}})

        mixpanel?.trackEvent(MixpanelEventNameE.ClickedOnHostLeaderbord, {
            value_invested: cost,
            host_username: host?.username
        })
    }

    return (
        <ProfileBanner
            groupId={groupIdForRedirect}
            isRedirectToChat={isRedirectToChat}
            cost={cost}
            description={
                <>
                    <Trans
                        t={t}
                        i18nKey={'top_fan_card:completed'}
                        values={{COUNT: count}}
                        components={{
                            span: <span />
                        }}
                    />
                    {isMe && (
                        <>
                            {` | `}
                            <Trans
                                t={t}
                                i18nKey={'top_fan_card:progress'}
                                values={{COUNT: pendingCounter}}
                                components={{
                                    span: <span />
                                }}
                            />
                        </>
                    )}
                </>
            }
            image={<TopMegaFanIcon />}
            title={t('top_fan_card:title').toUpperCase()}
            isLoading={isLoadingHost}
            onClick={onProfileBannerClick}
        />
    )
}
