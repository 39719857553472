import styled, {css} from 'styled-components'

export const StyledLanguagesList = styled.div`
    ${({theme: {mediaQueries}}) => css`
        display: flex;
        flex-direction: column;
        height: 70vh;
        overflow-y: auto;
        ${mediaQueries.m} {
            height: 385px;
        }
    `}
`
export const StyledLabel = styled.div`
    ${({theme: {palette, typography, spacing}}) => css`
        ${typography.textMd}
        color: ${palette.primary[800]};
        padding: ${spacing}px 0;
        font-weight: 400;
        line-height: 19px;
    `}
`

type StyledLanguageProps = {
    $isSelected?: boolean
}
export const StyledLanguage = styled(StyledLabel)<StyledLanguageProps>`
    ${({$isSelected = false, theme: {palette, spacing}}) => css`
        cursor: pointer;
        &:hover {
            background-color: ${palette.primary[625]};
        }
        ${$isSelected &&
        css`
            cursor: not-allowed;
            font-weight: 500;
            padding-left: ${spacing}px;
            background-color: ${palette.primary[625]};
        `}
    `}
`
export const StyledSelectGlobalLanguageModalContainer = styled.div`
    ${({theme: {spacing}}) => css`
        padding: ${spacing * 3}px;
        display: flex;
        flex-direction: column;
        gap: ${spacing}px;
    `}
`
