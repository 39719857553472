import {Button} from '@/components/ui/button/Button'
import styled, {css} from 'styled-components'

export const StyledFilterBanner = styled.div`
    ${({theme: {spacing}}) => css`
        display: flex;
        padding: 24px 12px;
        flex-direction: column;
        justify-content: space-between;
        background: linear-gradient(180deg, #8f90fe 0%, #8b71ea 50.31%, #864bd2 100%);
        box-shadow: 0px 2px 18px rgba(0, 6, 61, 0.15);
        min-height: 515px;
        border-radius: ${spacing}px;
        margin: 0 auto;
        position: relative;

        & > ::-webkit-scrollbar {
            display: none;
        }
    `}
`

export const StyledBannerText = styled.div`
    ${({theme: {palette, typography, spacing}}) => css`
        ${typography.textLg};
        color: ${palette.neutral.white};
        line-height: 22px;
        letter-spacing: -0.02em;
        text-transform: uppercase;

        & h4 {
            font-weight: 800;
        }

        & p {
            margin-top: ${spacing / 2}px;
            font-weight: 600;
        }
    `}
`
export const StyledTopicsWrapper = styled.div`
    height: 340px;
    overflow-y: scroll;
`

export const StyledTopicsContainer = styled.div`
    ${({theme: {spacing, mediaQueries}}) => css`
        display: flex;
        flex-wrap: wrap;
        gap: ${spacing / 2}px;

        ${mediaQueries.l} {
            gap: ${spacing}px;
        }
    `}
`

type StyledTopicsChipProps = {
    isSelected: boolean
}

export const StyledTopicsChip = styled.span<StyledTopicsChipProps>`
    ${({theme: {palette, mediaQueries, spacing, typography}, isSelected}) => css`
        color: ${palette.neutral.white};
        border: 1px solid ${palette.neutral.white};
        cursor: pointer;
        display: flex;
        padding: ${spacing}px ${spacing * 2}px;
        align-items: center;
        font-weight: 700;
        border-radius: ${spacing * 12.5}px;
        letter-spacing: -0.3px;
        justify-content: center;
        gap: ${spacing / 2}px;

        ${mediaQueries.m} {
            gap: ${spacing}px;
        }

        ${isSelected &&
        css`
            color: ${palette.primary[900]};
            border: 2px solid ${palette.primary[900]};
            background: ${palette.neutral.white};
        `}
        & svg {
            width: ${spacing * 2}px;
            height: ${spacing * 2}px;
        }

        & > svg > path {
            fill: ${palette.neutral.white};
            stroke: ${palette.primary[900]};
        }

        ${mediaQueries.l} {
            font-size: ${spacing * 2}px;

            & svg {
                width: 20px;
                height: 20px;
            }
        }

        ${mediaQueries.xxxl} {
            padding: 14px 28px;
            ${typography.textLg}

            svg {
                margin-right: ${spacing}px;
            }
        }

        ${mediaQueries.l} {
            ${typography.textMd};
        }
    `}
`

export const StyledApplyButton = styled(Button)`
    ${({theme: {palette, spacing}}) => css`
        background-color: ${palette.neutral.white};
        width: ${spacing * 30}px;
        border: none;
        align-self: center;

        &:disabled {
            color: rgb(0, 6, 61);
        }
    `}
`
