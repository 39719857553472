import styled, {css, DefaultTheme} from 'styled-components'
import {GroupBase, StylesConfig} from 'react-select'
import {OptionType} from './types/types'

export type StyledContainerProps = {
    disabled: boolean
    $subSelect: boolean
    theme: DefaultTheme
}

export const StyledContainer = styled.div<StyledContainerProps>`
    ${({disabled, $subSelect, theme: {spacing}}) => css`
        position: relative;
        cursor: ${disabled ? 'not-allowed' : 'pointer'};
        margin-bottom: ${spacing}px;
        .select__menu {
            overflow: ${$subSelect ? 'visible' : 'auto'};
            &-list {
                overflow: ${$subSelect ? 'visible' : 'auto'};
            }
        }
    `}
`

export const StyledGroupLabel = styled.div`
    ${({theme: {palette, typography, spacing}}) => css`
        color: ${palette.primary['900']};
        ${typography.textMd};
        padding: ${spacing / 4}px ${spacing * 2}px ${spacing}px;
        font-weight: 500;
    `}
`

export const StyledLabelContainer = styled.div`
    ${({theme: {spacing}}) => css`
        display: flex;
        gap: ${spacing}px;
        margin-bottom: ${spacing}px;
        flex-wrap: wrap;
    `}
`
export const StyledLabelSpan = styled.div`
    ${({theme: {typography}}) => css`
        ${typography.textSm};
    `}
`

type StylesProps = {
    theme: DefaultTheme
    isError: boolean
    touched: boolean
    isShowSaveButton: boolean
}

//the following code should be jss for react select custom styling
export const customStyles = ({
    theme,
    isError,
    isShowSaveButton
}: StylesProps): StylesConfig<OptionType, boolean, GroupBase<OptionType>> => ({
    control: (baseStyles, state) => ({
        ...baseStyles,
        padding: `${theme.spacing}px`,
        width: '100%',
        minHeight: '54px',
        cursor: 'pointer',
        border: `1px solid ${theme.palette.neutral['950']}`,
        ':hover': {
            border: state.menuIsOpen
                ? `1px solid ${theme.palette.neutral['950']}`
                : `1px solid ${theme.palette.neutral['950']}`
        },
        borderRadius: '4px',
        ...(isError && {
            border: `1px solid ${theme.palette.danger['800']}`,
            boxShadow: 'none'
        }),
        ...(isError &&
            state.menuIsOpen && {
                border: `1px solid ${theme.palette.danger['800']}`,
                boxShadow: 'none'
            }),
        backgroundColor: state.isDisabled ? theme.palette.neutral[950] : theme.palette.neutral.transparent
    }),

    placeholder: provided => ({
        ...provided,
        //text/md regular
        fontSize: '16px',
        lineHeight: '24px',
        fontWeight: 400,
        marginLeft: '2px',
        color: theme.palette.neutral['500']
    }),
    valueContainer: baseStyles => ({
        ...baseStyles,
        padding: 0,
        textAlign: 'start',
        //text/md medium,
        fontSize: '16px',
        lineHeight: '24px',
        fontWeight: 400,
        color: theme.palette.neutral['900']
    }),
    indicatorsContainer: baseStyles => ({
        ...baseStyles,
        padding: isShowSaveButton ? '0px 8px 0px 0px' : `${theme.spacing}px`,
        '& svg': {
            fill: `${theme.palette.neutral['25']}`
        }
    }),

    dropdownIndicator: (baseStyles, state) => ({
        ...baseStyles,
        padding: 0,
        transform: state.selectProps.menuIsOpen ? 'rotate(180deg)' : 'rotate(0deg)',
        transition: 'transform 0.2s ease'
    }),

    indicatorSeparator: baseStyles => ({
        ...baseStyles,
        display: 'none'
    }),

    menu: baseStyles => ({
        ...baseStyles,
        borderRadius: `${theme.spacing * 2}px`,
        overflow: 'hidden',
        marginTop: theme.spacing,
        boxShadow: theme.shadows['5xl']
    }),

    menuList: baseStyles => ({
        ...baseStyles,
        overflowX: 'hidden',
        cursor: 'pointer',
        padding: '10px 0'
    }),
    option: baseStyles => ({
        ...baseStyles,
        textAlign: 'start',
        //text/md medium,
        fontSize: '16px',
        lineHeight: '24px',
        fontWeight: 500,
        textTransform: 'capitalize',
        color: theme.palette.neutral['900'],
        borderRadius: `${theme.spacing * 1.5}px`,
        '&:active': {
            background: 'transparent'
        }
    }),
    group: baseStyles => ({
        ...baseStyles,
        padding: '0'
    }),

    multiValue: baseStyles => ({
        ...baseStyles,
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        gap: theme.spacing,
        height: 24,
        background: theme.palette.neutral.white,
        border: `1px solid ${theme.palette.neutral['300']}`,
        borderRadius: '6px'
    }),
    multiValueLabel: baseStyles => ({
        ...baseStyles,
        //Text sm/Medium
        fontWeight: 500,
        fontSize: '16px',
        lineHeight: '20px',
        textTransform: 'capitalize',
        color: theme.palette.neutral['400']
    }),
    multiValueRemove: baseStyles => ({
        ...baseStyles
    }),
    input: baseStyles => ({
        ...baseStyles,
        margin: 0
    })
})
