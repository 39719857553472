import {useTranslation} from 'react-i18next'
import {StyledTabTitle} from '../style'
import {InputText} from '@/components/commons/input-text/InputText'
import {debounce} from 'lodash'
import {ChangeEvent, Dispatch, SetStateAction, useEffect, useRef} from 'react'
import {StyledInputWrap, StyledItem, StyledListWrap, StyledSearchedFiltersList, inputStyles} from './style'
import {FilterCountryLanguageIcon} from '@/assets/icons/icons'
import {LanguagesType, SingleCountryWithLanguageType} from '@/features/country/types'

type ItemType = LanguagesType | SingleCountryWithLanguageType

type SearchedFiltersListProps<T> = {
    title: string
    data: T[]
    handleChange: (value: string) => void
    defaultData: T[]
    handleSelect: Dispatch<SetStateAction<T[]>>
}

export const SearchedFiltersList = <T extends ItemType>({
    title,
    data,
    defaultData,
    handleChange,
    handleSelect
}: SearchedFiltersListProps<T>) => {
    const {t} = useTranslation()

    const onChange = (e: ChangeEvent<HTMLInputElement>) => {
        const eventValue = e.target.value
        handleChange(eventValue)
    }

    const onSelect = (item: T, isSelected: boolean) => {
        if (isSelected) {
            // Remove
            handleSelect(prevState => prevState.filter(prevItem => prevItem.id !== item.id))
        } else {
            // Add
            handleSelect([...defaultData, item])
        }
    }

    const horizontalListRef = useRef<HTMLDivElement>(null)

    useEffect(() => {
        // scrolling element with mouse wheel
        const handleWheel = (e: WheelEvent) => {
            const horizontalList = horizontalListRef.current
            if (horizontalList) {
                const delta = Math.max(-1, Math.min(1, e.deltaY))
                horizontalList.scrollLeft -= delta * 300
                e.preventDefault()
            }
        }
        if (horizontalListRef.current) {
            horizontalListRef.current.addEventListener('wheel', handleWheel)
        }
        return () => {
            if (horizontalListRef.current) {
                horizontalListRef.current.removeEventListener('wheel', handleWheel)
            }
        }
    }, [])

    return (
        <StyledSearchedFiltersList>
            <StyledTabTitle>{t(title)}</StyledTabTitle>
            <StyledInputWrap>
                <InputText
                    type="text"
                    name="test"
                    placeholder={t('path_filter:filter_search_country')}
                    onChange={debounce(onChange, 300)}
                    autoComplete="off"
                    inputStyles={inputStyles}
                />
            </StyledInputWrap>
            <StyledListWrap ref={horizontalListRef}>
                {data?.map(item => {
                    const isSelected = defaultData.some(defaultItem => defaultItem.id === item.id)
                    return (
                        <StyledItem key={item.id} isSelected={isSelected} onClick={() => onSelect(item, isSelected)}>
                            <FilterCountryLanguageIcon />
                            {item.name}
                        </StyledItem>
                    )
                })}
            </StyledListWrap>
        </StyledSearchedFiltersList>
    )
}
