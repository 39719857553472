import styled, {css} from 'styled-components'

export const StyledOverlay = styled.div`
    ${({theme: {zIndex}}) => css`
        position: fixed;
        inset: 0;
        background-color: transparent;
        z-index: ${zIndex.modalOverlay};
    `}
`

export const StyledRoot = styled.div`
    ${({theme: {zIndex, palette}}) => css`
        position: fixed;
        display: grid;
        grid-template-columns: 0fr auto 0fr;
        padding: 25px 20px;
        align-items: center;
        background: ${palette.neutral.white};
        box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15);
        border-radius: 10px;
        width: 345px;
        top: 84%;
        left: 50%;
        transform: translate(-50%, -50%);
        overflow: visible;
        z-index: ${zIndex.modal};
    `}
`

export const StyledRootForSafari = styled.div`
    ${({theme: {zIndex, palette}}) => css`
        top: 84%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 345px;
        padding: 25px 20px;
        z-index: ${zIndex.modal};
        overflow: visible;
        position: fixed;
        background: ${palette.neutral.white};
        box-shadow: rgba(0, 0, 0, 0.15) 0px 4px 10px;
        align-items: center;
        border-radius: 10px;
    `}
`

export const StyledHint = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    & span {
        margin-left: 6px;
    }
`

export const StyledPlusIconWrapper = styled.div`
    cursor: pointer;

    & path {
        fill: ${({theme}) => theme.palette.primary[200]};
    }
`

export const StyledTextSection = styled.div`
    display: flex;
    flex-direction: column;
    padding-left: 15px;
`

export const StyledFirstText = styled.span`
    font-weight: 700;
`
