import {EditorConfig, NodeKey, TextNode} from 'lexical'

export class BannedElement extends TextNode {
    __text: string
    __onClick: (type: string, element: HTMLElement) => void
    __elementType: string

    constructor(
        text: string,
        onClick: (type: string, element: HTMLElement) => void,
        elementType: string,
        key?: NodeKey
    ) {
        super(text, key)
        this.__text = text
        this.__mode = 2
        this.__onClick = onClick
        this.__elementType = elementType
    }
    static getType(): string {
        return 'banned'
    }

    static clone(node: BannedElement): BannedElement {
        return new BannedElement(node.__text, node.__onClick, node.__elementType)
    }

    createDOM(config: EditorConfig): HTMLElement {
        const element = super.createDOM(config)
        element.addEventListener('click', event => {
            event.preventDefault()
            this.__onClick(this.__elementType, element)
        })
        element.style.cursor = 'pointer'
        element.style.backgroundColor = this.__elementType !== 'validLink' ? '#ff9999' : '#99ff9d'
        return element
    }
    updateDOM(prevNode: BannedElement, dom: HTMLElement, config: EditorConfig): boolean {
        const isUpdated = super.updateDOM(prevNode, dom, config)

        return isUpdated
    }
}

export function $createBannedElement(
    text: string,
    onClick: (type: string, element: HTMLElement) => void,
    elementType: string
): BannedElement {
    return new BannedElement(text, onClick, elementType)
}
