import {ArrowInSquareIcon, MicromorgiIcon} from '@/assets/icons/icons'
import {useRootStore} from '@/store'
import {selectBuyMorgisModal, selectConfirmSendGiftModal, selectPaidGifsModal} from '@/store/selectors'
import {FC} from 'react'
import {useGetPaidGifs} from '../../queries/useGetPaidGifs'
import {StyledAmount, StyledGift, StyledGiftContent, StyledGiftListWrapper} from '../style'
import {StyledAnimation} from './style'
import {useMe} from '@/features/authentication/queries/useMe'
import {GuestType} from '@/features/authentication/types'

type MorgiListProps = {hostID: string; minDonation?: number}

const countAnimation = 5

export const MorgiList: FC<MorgiListProps> = ({hostID, minDonation}) => {
    const {data: gifs} = useGetPaidGifs({order_by_chat: true})
    const {openModal: openConfirmGiftModal} = useRootStore(selectConfirmSendGiftModal)
    const {openModal: openPaidGifsModal} = useRootStore(selectPaidGifsModal)
    const {openModal: openBuyMorgisModal} = useRootStore(selectBuyMorgisModal)
    const {data} = useMe()
    const user = data as GuestType

    return (
        <StyledGiftListWrapper>
            {gifs?.slice(0, countAnimation).map(gif => (
                <StyledGift
                    key={gif.id}
                    aria-disabled={minDonation ? gif.amount < minDonation : false}
                    onClick={() => {
                        if (user?.type_attributes?.micro_morgi_balance >= gif.amount) {
                            openConfirmGiftModal({amount: gif.amount, hostID, animationID: gif.id})
                        } else {
                            openBuyMorgisModal({})
                        }
                    }}
                >
                    <StyledGiftContent>
                        <StyledAnimation src={gif.url} />
                        {gif.amount && (
                            <StyledAmount>
                                <MicromorgiIcon height={14} width={14} />
                                {gif.amount}
                            </StyledAmount>
                        )}
                    </StyledGiftContent>
                </StyledGift>
            ))}
            <StyledGift
                onClick={() => {
                    openPaidGifsModal({hostID, minDonation})
                }}
            >
                <StyledGiftContent>
                    <ArrowInSquareIcon />
                </StyledGiftContent>
            </StyledGift>
        </StyledGiftListWrapper>
    )
}
