import {Lens} from '@dhmk/zustand-lens'

type PaidGifsModalState = {
    isOpen: boolean
    hostID?: string | null
    leaderID?: number | null
    channelID?: string | null
    minDonation?: number
}

type PaidGifsModalActions = {
    openModal: (params: Omit<PaidGifsModalState, 'isOpen'>) => void
    closeModal: () => void
}

export type PaidGifsModal = PaidGifsModalState & PaidGifsModalActions

const initialModal: PaidGifsModalState = {isOpen: false, hostID: null, leaderID: null, channelID: null}

const createPaidGifsModalSlice: Lens<PaidGifsModal> = set => ({
    ...initialModal,
    openModal: params => set(() => ({...params, isOpen: true})),
    closeModal: () => set(() => ({...initialModal}))
})

export default createPaidGifsModalSlice
