import {Lens} from '@dhmk/zustand-lens'

type BuyMorgisModalState = {
    isOpen: boolean
    campaignId?: string
}

type BuyMorgisModalActions = {
    openModal: (params: Omit<BuyMorgisModalState, 'isOpen'>) => void
    closeModal: () => void
}

export type BuyMorgisModal = BuyMorgisModalState & BuyMorgisModalActions

const initialModal: BuyMorgisModalState = {isOpen: false}

const createBuyMorgisModalSlice: Lens<BuyMorgisModal> = set => ({
    ...initialModal,
    openModal: params => set(() => ({...params, isOpen: true})),
    closeModal: () => set(() => ({...initialModal}))
})

export default createBuyMorgisModalSlice
