import axios from '@/clientAPI.ts'
import {
    HostThankYousType,
    SetOptionsRequestType,
    ThankYousTypesType,
    TipsAndGiftsType,
    UncoverAttachmentType
} from '../types'
import {
    HostThankYousSchema,
    ThankYousTypesSchema,
    TipsAndGiftsSchema,
    UncoverAttachmentSchema
} from './thankYous.schema'

export const THANK_YOUS_API = {
    getThankYousTypes: async (): Promise<ThankYousTypesType> => {
        const url = '/thanks-yous/types'
        const {data} = await axios.get(url)
        return ThankYousTypesSchema.parse(data)
    },
    getThankYousByHostId: async ({hostId}: {hostId: string}): Promise<HostThankYousType> => {
        const url = `/rookie/${hostId}/thanks-yous`
        const {data} = await axios.get(url)
        return HostThankYousSchema.parse(data)
    },
    getHostThankYous: async (): Promise<HostThankYousType> => {
        const url = `/thanks-yous`
        const {data} = await axios.get(url)
        return HostThankYousSchema.parse(data)
    },
    getTipsAndGiftsOptions: async (): Promise<TipsAndGiftsType> => {
        const url = `/tips-and-gifts`
        const {data} = await axios.get(url)
        return TipsAndGiftsSchema.parse(data)
    },
    getUncoverAttachmentOptions: async (): Promise<UncoverAttachmentType> => {
        const url = `/tips-and-gifts/uncover-attachment`
        const {data} = await axios.get(url)
        return UncoverAttachmentSchema.parse(data)
    },
    setTipsAndGifts: async (data: SetOptionsRequestType) => {
        const url = `/thanks-yous/in-chat-tips-and-gifts`
        return await axios.post(url, {...data})
    },
    setUncoverAttachment: async (data: SetOptionsRequestType) => {
        const url = `/thanks-yous/uncover-attachment`
        return await axios.post(url, {...data})
    },
    updateTipsAndGifts: async (data: SetOptionsRequestType) => {
        const url = `/thanks-yous/in-chat-tips-and-gifts`
        return await axios.patch(url, {...data})
    },
    updateUncoverAttachment: async (data: SetOptionsRequestType) => {
        const url = `/thanks-yous/uncover-attachment`
        return await axios.patch(url, {...data})
    }
}
