import {useTranslation} from 'react-i18next'
import {StyledForWhomRoot, tagIsForOthersStyles} from './style'
import {Tag} from '@/components/ui/tag/Tag'

export const ForWhomGoalSection = ({
    isForOthers,
    text
}: {
    isForOthers?: boolean
    text?: string | null | JSX.Element
}) => {
    const {t} = useTranslation()
    const tagTitle = isForOthers ? t('goal_page:for_whom_section:others') : t('goal_page:for_whom_section:myself')

    return (
        <StyledForWhomRoot>
            <Tag title={tagTitle} tagStyles={tagIsForOthersStyles} />
            <p>{text}</p>
        </StyledForWhomRoot>
    )
}
