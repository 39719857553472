import React from 'react'
import {StyledContainer} from '@components/ui/container/style.ts'

interface ContainerProps {
    className?: string
    children: React.ReactNode
    fullWidth?: boolean
}

export const Container = ({children, className, fullWidth = false}: ContainerProps) => (
    <StyledContainer fullWidth={fullWidth} className={className}>
        {children}
    </StyledContainer>
)

Container.displayName = 'Container'
