import {useQuery} from '@tanstack/react-query'
import {GIFS_API} from '../services/gifs.http'
import {GetAnimationRequestType} from '../types'
import {QUERY_KEYS} from './keys'

export const useGetCurrentPaidGif = (props: GetAnimationRequestType) => {
    return useQuery({
        queryKey: [QUERY_KEYS.current_animation, props],
        queryFn: () => GIFS_API.getCurrentAnimation(props),
        enabled: !!props.animation_id
    })
}
