import {useMemo} from 'react'
import Plyr from 'plyr-react'
import cx from 'classnames'
import {GlobalStyles} from './style'
import 'plyr-react/plyr.css'

type VideoPlayerProps = {
    source: string
    options?: Plyr.Options | null
}

const defaultVideoOptions = {
    frameBorder: '0',
    autoplay: true,
    allowFullScreen: true,
    tooltip: {controls: true, seek: true},
    clickToPlay: true,
    hideControls: false,
    controls: ['play-large', 'play', 'progress', 'current-time', 'mute', 'volume', 'fullscreen']
}

export const VideoPlayer = ({source, options = null, ...rest}: VideoPlayerProps) => {
    const videoOptions = useMemo(
        () => (options ? {...defaultVideoOptions, ...options} : defaultVideoOptions),
        [options]
    )

    return (
        <>
            <GlobalStyles />
            <Plyr
                source={{
                    type: 'video',
                    sources: [
                        {
                            src: source
                        }
                    ]
                }}
                className={cx('plyr-react plyr')}
                {...rest}
                options={videoOptions}
            />
        </>
    )
}

export default VideoPlayer
