import {MixPanelContextType} from '@/integrations/mixpanel/types'
import {useContext} from 'react'
import {MixPanelContext} from '../components/mix-panel-provider/MixPanelProvider'

const useMixPanel = () => {
    const mixPanelContext = useContext<MixPanelContextType | null>(MixPanelContext)
    return mixPanelContext?.mixpanel
}

export default useMixPanel
