export const NOTIFICATION_TYPE_INIT_REQUIRED = {
    transactionGoal: 'transaction_goal',
    rookieBlockedLeader: 'rookie_blocked_leader',
    leaderBlockedRookie: 'leader_blocked_rookie',
    blockedLeader: 'blocked_leader',
    leaderNewGift: 'leader_new_gift',
    rookieNewGift: 'rookie_new_gift',
    leaderFirstGiftToRookie: 'leader_first_gift_to_rookie',
    rookieFirstGiftFromLeader: 'rookie_first_gift_from_leader',
    leaderReferredRookieWelcome: 'leader_referred_rookie_welcome',
    leaderReferredRookie: 'leader_referred_rookie',
    leaderRenewedGift: 'leader_renewed_gift',
    rookieRenewedGift: 'rookie_renewed_gift',
    rookieRecieveMicromorgis: 'rookie_receive_micromorgi',
    converterFirstMessage: 'converter_first_message',
    moengageNotification: 'moengage_notification',
    leaderFreeSubscription:  'leader_free_subscription',
    rookieFreeSubscription: 'rookie_free_subscription',
    leaderPauseConnection: 'leader_pause_connection'
}