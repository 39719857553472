import {InfoIcon} from '@/assets/icons/icons'
import {Button} from '@/components/ui/button/Button'
import styled, {css} from 'styled-components'

export const StyledWrapper = styled.span`
    ${({theme: {spacing, typography}}) => css`
        display: flex;
        flex-direction: column;
        gap: ${spacing * 2}px;
        ${typography.textMd}
        & p {
            align-self: center;
        }
    `}
`

export const StyledText = styled.div`
    ${({theme: {spacing, typography}}) => css`
        ${typography.textMd}
        align-self: center;
        font-weight: 700;
        text-decoration: underline;
        display: flex;
        gap: ${spacing * 2}px;
        align-items: center;
        margin: 0 auto;
        cursor: pointer;
    `}
`

export const StyledButton = styled(Button)`
    ${({theme: {spacing}}) => css`
        display: flex;
        gap: ${spacing}px;
        padding: ${spacing}px ${spacing * 4}px;
        justify-content: center;
        & img {
            height: 24px;
            width: 23px;
        }
    `}
`

export const StyledInfoCircle = styled(InfoIcon)`
    ${({theme: {spacing}}) => css`
        cursor: pointer;
        opacity: 0.5;
        width: 18px;
        height: 18px;
        margin-left: ${spacing * 2}px;
    `}
`

export const StyledListWrapper = styled.div`
    ${({theme: {spacing}}) => css`
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-gap: ${spacing * 2}px;
        margin-bottom: ${spacing}px;
    `}
`

export const StyledEmptySection = styled.div`
    text-align: center;
    opacity: 50%;
`
