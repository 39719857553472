import styled, {css} from 'styled-components'

export const StyledBody = styled.div`
    ${({theme: {spacing, mediaQueries}}) => css`
        width: 100%;
        height: 100%;
        padding: ${spacing * 3}px;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: ${spacing * 2}px;
        overflow-y: hidden;

        & button {
            width: auto;
        }

        ${mediaQueries.m} {
            gap: ${spacing * 3}px;
        }
    `}
`

export const StyledEditIcon = styled.div`
    ${({theme: {spacing}}) => css`
        margin: ${spacing * 2}px 0px ${spacing * 2}px;
    `}
`

export const StyledText = styled.div`
    ${({theme: {typography, palette, spacing}}) => css`
        text-align: center;
        & span {
            ${typography.textXXl}
        }

        & p {
            margin-top: ${spacing * 2}px;
            color: ${palette.primary['700']};
        }
    `}
`

export const StyledStopButton = styled.div`
    ${({theme: {palette}}) => css`
        text-align: center;
        text-decoration: underline;
        color: ${palette.danger['25']};
        font-weight: 600;
        cursor: pointer;
    `}
`
