import {useState} from 'react'
import {SelectForOptionProps, SubSelect} from '../../types/types'
import {
    StyledArrow,
    StyledOptionContainer,
    StyledOptions,
    StyledOptionsItem,
    StyledPlaceholder,
    StyledSelect
} from './style'

const SelectForOption = ({options, defaultValue = null, onChange, placeholder}: SelectForOptionProps) => {
    const [selectedValue, setSelectedValue] = useState(defaultValue)
    const [isOpen, setIsOpen] = useState<boolean>(false)
    const handleOnOpenSelect = (event: React.MouseEvent<HTMLDivElement>) => {
        event.stopPropagation()
        event.preventDefault()
        setIsOpen(!isOpen)
    }

    const onValueChangeHandle = (event: React.MouseEvent<HTMLParagraphElement>, subOption: SubSelect) => {
        event.stopPropagation()
        event.preventDefault()
        setSelectedValue(subOption)
        onChange?.(subOption)
        setIsOpen(false)
    }
    return (
        <StyledOptionContainer>
            <StyledSelect onClick={handleOnOpenSelect}>
                {selectedValue ? (
                    <span>{selectedValue.label}</span>
                ) : (
                    <StyledPlaceholder className={StyledPlaceholder}>{placeholder}</StyledPlaceholder>
                )}
                <StyledArrow></StyledArrow>
            </StyledSelect>
            {isOpen && (
                <StyledOptions>
                    {options.map((option: SubSelect) => {
                        return (
                            <StyledOptionsItem
                                key={option.value}
                                onClick={(event: React.MouseEvent<HTMLParagraphElement>) =>
                                    onValueChangeHandle(event, option)
                                }
                            >
                                {option.label}
                            </StyledOptionsItem>
                        )
                    })}
                </StyledOptions>
            )}
        </StyledOptionContainer>
    )
}

export default SelectForOption
