export const MUTATION_KEYS = {
    create_goal: 'create-goal'
}

export const QUERY_KEYS = {
    goal_type: 'goal-type',
    goals_path: 'goals-path',
    selected_goal: 'selected-goal',
    supporters_list: 'supporters-list',
    goals_list: 'goals-list',
    past_goals: 'past-goals',
    supported_goals: 'supported-goals',
    broadcasts_supporters: 'broadcasts-supporters',
    broadcasts: 'broadcasts',
    saved_goals: 'saved-goals',
    host_goals: 'host-goals'
}
