import styled, {css} from 'styled-components'

export const StyledSignupButton = styled.div`
    ${({theme: {spacing}}) => css`
        justify-self: end;
        & button {
            font-weight: 600;
            padding: ${spacing}px ${spacing * 2}px;
        }
    `}
`
