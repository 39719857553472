import styled, {css} from 'styled-components'

export const StyledLabel = styled.label`
    ${({theme: {spacing}}) => css`
        display: inline-block;
        position: relative;
        font-weight: 700;
        line-height: 1.8;
        margin-bottom: ${spacing}px;
    `}
`
