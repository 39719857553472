import styled, {DefaultTheme, FlattenSimpleInterpolation, css} from 'styled-components'

export const StyledCountdownRoot = styled.div`
    ${({
        theme: {typography, palette},
        countdownStyles
    }: {
        theme: DefaultTheme
        countdownStyles?: (theme: DefaultTheme) => FlattenSimpleInterpolation
    }) => css`
        display: flex;
        ${typography.textXxs};
        color: ${palette.primary[200]};
        ${({theme}) => countdownStyles && countdownStyles(theme)};
    `}
`
