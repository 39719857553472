import {HeartIconFilled} from '@/assets/icons/icons'
import {Trans, useTranslation} from 'react-i18next'
import {feedCardVariants} from '../FeedCard'
import {FC} from 'react'
import {
    StyledBanner,
    StyledCardAction,
    StyledCardBody,
    StyledPreloadCard,
    StyledPreloadCardInfo,
    StyledPreloadCardPhoto,
    StyledPreloadHeartContainer,
    StyledTitleContainer
} from './style'
import {FeedCardVariant} from '@/features/feed/types'
import {useClearActiveFilters} from '@/features/feed/queries/useClearActiveFilters'
import {useHandleError} from '@/hooks/useHandleError'
import {Spinner} from '@/components/ui/spinner/Spinner'

type LastFilterCardProps = {
    cardType: FeedCardVariant
    scrollToFeedStart: () => void
}

export const LastFilterCard: FC<LastFilterCardProps> = ({cardType, scrollToFeedStart}) => {
    const {t} = useTranslation()
    const {
        mutate: clearFilters,
        isPending: isClearFiltersPending,
        isError: isClearFiltersError,
        error: clearFiltersError
    } = useClearActiveFilters(scrollToFeedStart)

    useHandleError({isError: isClearFiltersError, error: clearFiltersError})
    return (
        <StyledBanner>
            {isClearFiltersPending && <Spinner />}
            <StyledTitleContainer>
                <Trans t={t} i18nKey="interactive_feed:info_card:title" />
            </StyledTitleContainer>
            <StyledCardAction>
                <Trans
                    t={t}
                    i18nKey="interactive_feed:info_card:action"
                    components={{
                        span: <span onClick={() => clearFilters()} />
                    }}
                />
            </StyledCardAction>
            <StyledCardBody>
                {cardType === feedCardVariants.allCaughtUp ? (
                    <Trans t={t} i18nKey="interactive_feed:info_card:text" />
                ) : (
                    <StyledPreloadCard>
                        <StyledPreloadCardPhoto />
                        <StyledPreloadHeartContainer>
                            <HeartIconFilled />
                        </StyledPreloadHeartContainer>
                        <StyledPreloadCardInfo>
                            <span />
                            <span />
                            <span />
                            <span />
                        </StyledPreloadCardInfo>
                    </StyledPreloadCard>
                )}
            </StyledCardBody>
        </StyledBanner>
    )
}

LastFilterCard.displayName = 'LastFilterCard'
