import {FeedVariantE} from '@/features/feed/constants.ts'
import {StyledFeedBar, StyledFeedPage, StyledFeedWrapper} from './style'
import {FeedHeading} from '@/features/feed/components/feed-heading/FeedHeading'
import {FeedBar} from '@/features/feed/components/feed-bar/FeedBar'
import {FeedFilters} from '@/features/feed/components/feed-filters/FeedFilters'
import {useEffect, useState} from 'react'
import {Outlet, useLocation} from 'react-router-dom'
import {ROUTE_GOALS_MAIN_PAGE} from '@/utilities/constants/routeNames'
import useContainerDimensions from '@/hooks/useContainerDimensions'

const Component = () => {
    const {height: vh, heightOfHeader} = useContainerDimensions()
    const location = useLocation()
    const variant = location.pathname === ROUTE_GOALS_MAIN_PAGE ? FeedVariantE.goals : FeedVariantE.hosts
    const [isFilterVisible, setIsFilterVisible] = useState(false)

    useEffect(() => {
        setIsFilterVisible(false)
    }, [variant])

    return (
        <StyledFeedPage vh={vh} heightOfHeader={heightOfHeader}>
            <StyledFeedBar>
                <FeedHeading variant={variant} />
                <FeedBar onFilterClick={() => setIsFilterVisible(true)} variant={variant} />
                <FeedFilters
                    variant={variant}
                    isFilterVisible={isFilterVisible}
                    onCloseFilters={() => setIsFilterVisible(false)}
                />
            </StyledFeedBar>
            <StyledFeedWrapper>
                <Outlet />
            </StyledFeedWrapper>
        </StyledFeedPage>
    )
}
Component.displayName = 'InteractiveFeed'

export default Component
