import {HostPartialSchema, UserSchema} from '@/features/authentication/services/auth.schema'
import {z} from 'zod'
import {CardUpdateTypes} from '../constants/card'
import {SubscriptionsKeysE, SubscriptionsStatusE} from '../types'

export const SubscriptionSchema = z.object({
    id: z.number(),
    amount: z.number(),
    status: z.nativeEnum(SubscriptionsStatusE),
    rookie_id: z.number(),
    leader_id: z.number(),
    ended_by: z.nullable(z.unknown()),
    is_recurring: z.boolean(),
    canceled_at: z.nullable(z.unknown()),
    failed_at: z.nullable(z.unknown()),
    deleted_at: z.nullable(z.unknown()),
    subscription_at: z.string(),
    last_subscription_at: z.string(),
    valid_until_at: z.nullable(z.unknown()),
    next_donation_at: z.string(),
    last4: z.nullable(z.unknown()),
    rookie: UserSchema,
    leader: UserSchema
})

const SubscriptionToRenewSchema = z.object({
    credit_card: z.object({last4: z.number()}),
    data: z.array(SubscriptionSchema)
})

export const SubscriptionsSchemaToRenewCard = z.object({
    [SubscriptionsKeysE.Errors]: z.array(SubscriptionToRenewSchema),
    [SubscriptionsKeysE.Subscriptions]: z.array(SubscriptionToRenewSchema)
})

export const SubscriptionsSchema = z.object({
    errors: z.array(z.unknown()),
    subscriptions: z.array(SubscriptionSchema)
})

const SubscriptionUpdateSchema = z.object({
    id: z.string(),
    action: z.string()
})

export const SubscriptionsUpdateSchema = z.object({
    [SubscriptionsKeysE.Errors]: z.array(SubscriptionUpdateSchema).optional(),
    [SubscriptionsKeysE.Subscriptions]: z.array(SubscriptionUpdateSchema),
    need_ccbill: z.boolean().optional(),
    type: z.nativeEnum(CardUpdateTypes)
})

export const AgesSchema = z.object({max_age: z.number(), min_age: z.number()})

export const SavedHostResponseSchema = z.array(HostPartialSchema)
