import {FC} from 'react'
import {useTranslation} from 'react-i18next'
import {useCountdown} from '@/hooks/useCountdown'

interface GoalTimerProps {
    endDate: string
}

export const GoalTimer: FC<GoalTimerProps> = ({endDate}) => {
    const {days, hours, minutes, seconds} = useCountdown({date: endDate})
    const {t} = useTranslation()

    return (
        <div>
            {days < 1 ? (
                <>
                    <span>
                        {hours < 10 ? '0' + hours : hours}
                        <span>:</span>
                    </span>
                    <span>
                        {minutes < 10 ? '0' + minutes : minutes}
                        <span>:</span>
                    </span>
                    <span>{seconds < 10 ? '0' + seconds : seconds}</span>
                </>
            ) : (
                <span>
                    {Math.ceil(days)}
                    <span> {t('goal_card:timer')}</span>
                </span>
            )}
        </div>
    )
}

GoalTimer.displayName = 'GoalTimer'
