import {motion} from 'framer-motion'
import styled, {css} from 'styled-components'

interface DotProps {
    active?: boolean
}

export const StyledCarouselContainer = styled.div(
    ({theme: {spacing}}) => css`
        position: relative;
        width: 100%;
        height: 100%;
        overflow: hidden;
        border-radius: ${spacing * 1.25}px;
    `
)

export const StyledImageContainer = styled.div`
    position: absolute;
    width: 100%;
    height: 100%;
    overflow: hidden;
`
type StyledSlideProps = {
    url: string | undefined | null
}
export const StyledSlide = styled(motion.div)<StyledSlideProps>`
    ${({url}) => css`
        width: 100%;
        height: 100%;
        background-color: rgb(127, 130, 158);
        background-image: linear-gradient(rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0) 21.88%), url(${url});
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
    `}
`
type DotContainerProps = {
    photoCount: number
}
export const StyledDotContainer = styled.div<DotContainerProps>(
    ({theme: {spacing}, photoCount}) => css`
        display: grid;
        grid-template-columns: repeat(${photoCount}, 1fr);
        grid-gap: ${photoCount < 20 ? `2px` : `unset`};
        padding: 6px ${spacing / 2}px ${spacing / 2}px ${spacing / 2}px;
    `
)
export const StyledDot = styled.div<DotProps>(
    ({theme: {spacing}, active}) => css`
        height: ${spacing / 2}px;
        width: 100%;
        opacity: 0.2;
        background-color: white;
        border-radius: 100px;
        cursor: pointer;
        position: relative;
        z-index: 1;

        ${active &&
        css`
            opacity: 1;
        `}
    `
)
