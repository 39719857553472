import {MicromorgiIcon} from '@/assets/icons/icons'
import {useMe} from '@/features/authentication/queries/useMe'
import {remapCurrency} from '@/features/profile/constants'
import {FC} from 'react'
import {useTranslation} from 'react-i18next'
import {StyledAmountContainer, StyledPrice} from '../gift-item/style'
import {
    StyledAmountWrapper,
    StyledMegaGiftAmount,
    StyledMegaGiftDescription,
    StyledMegaGiftDescriptionWrapper,
    StyledMegaGiftImage,
    StyledMegaGiftTitle,
    StyledRightPart
} from './style'
import {DonationType} from '@/features/recurring-gift/types'

type MegaGiftItemProps = {amount: DonationType}
export const MegaGiftItem: FC<MegaGiftItemProps> = ({amount}) => {
    const {t} = useTranslation()
    const {data: user} = useMe()
    return (
        <>
            <StyledAmountWrapper>
                <div>
                    <StyledAmountContainer>
                        <StyledMegaGiftImage />
                        <StyledMegaGiftTitle>{t('monthly_support_mega_gift:title')}</StyledMegaGiftTitle>
                        <StyledPrice>
                            {t(`gift_rookie:morgi_prise`, {
                                PRICE: `${remapCurrency[user?.currency as string]}${amount.dollar_amount}`
                            })}
                        </StyledPrice>
                    </StyledAmountContainer>
                    <StyledAmountContainer>
                        <StyledMegaGiftDescriptionWrapper>
                            <StyledMegaGiftDescription>
                                {t('monthly_support_mega_gift:description')}
                            </StyledMegaGiftDescription>
                            <MicromorgiIcon height={14} width={14} />
                            <StyledMegaGiftAmount>{amount.amount}</StyledMegaGiftAmount>
                        </StyledMegaGiftDescriptionWrapper>
                    </StyledAmountContainer>
                </div>

                <StyledRightPart>{t('monthly_support_mega_gift:right_part')}</StyledRightPart>
            </StyledAmountWrapper>
        </>
    )
}
