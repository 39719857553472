import axios from '@/clientAPI.ts'
import {BadLinksResponseSchema, BadWordsResponseSchema} from './generatedContentSecurity.schema'
import {BadLinksResponseType, BadWordsResponseType} from '../types'

export const GENERATED_CONTENT_SECURITY_API = {
    getBadWordsList: async (): Promise<BadWordsResponseType> => {
        const url = '/bad-words'
        const {data} = await axios.get(url)
        return BadWordsResponseSchema.parse(data)
    },
    getBadLinksList: async (): Promise<BadLinksResponseType> => {
        const url = '/urls-controls'
        const {data} = await axios.get(url)
        return BadLinksResponseSchema.parse(data)
    },
    checkBadWords: async ({message}: {message: string}): Promise<string> => {
        const url = '/bad-words/check'
        return await axios.post(url, {message})
    }
}
