import React, {InputHTMLAttributes} from 'react'
import {StyledCheckboxInput, StyledLabel} from './style'
import {DefaultNamespace} from 'i18next'
import {InputHelpText} from '@/components/ui/input-help-text/InputHelpText'

export interface CheckboxProps extends InputHTMLAttributes<HTMLInputElement> {
    id: string
    label?: string | DefaultNamespace | React.ReactNode
    labelPosition?: 'left' | 'right'
    name?: string
    placeholder?: string
    disabled?: boolean
    errorMessage?: string | DefaultNamespace
    onClick?: (e: React.MouseEvent<HTMLInputElement>) => void
    className?: string
}

const Checkbox = React.forwardRef<HTMLInputElement, CheckboxProps>(
    ({label, id, labelPosition = 'right', disabled, errorMessage, className, ...rest}: CheckboxProps, ref) => {
        return (
            <>
                <div className={className}>
                    <StyledCheckboxInput id={id} type="checkbox" disabled={disabled} {...rest} ref={ref} />
                    <StyledLabel htmlFor={id} disabled={disabled} $labelPosition={labelPosition}>
                        {label}
                    </StyledLabel>
                </div>
                {errorMessage && (
                    <div>
                        <InputHelpText error={errorMessage} />
                    </div>
                )}
            </>
        )
    }
)

Checkbox.displayName = 'Checkbox'

export default Checkbox
