import {GifType} from '@/features/gifs/types'
import {Lens} from '@dhmk/zustand-lens'

type SendGifModalState = {
    isOpen: boolean
    gif?: GifType | null
}

type SendGifModalActions = {
    openModal: (params: Omit<SendGifModalState, 'isOpen'>) => void
    closeModal: () => void
}

export type SendGifModal = SendGifModalState & SendGifModalActions

const initialModal: SendGifModalState = {isOpen: false, gif: null}

const createSendGifModalSlice: Lens<SendGifModal> = set => ({
    ...initialModal,
    openModal: params => set(() => ({...params, isOpen: true})),
    closeModal: () => set(() => ({...initialModal}))
})

export default createSendGifModalSlice
