import {QUERY_KEYS} from '@/features/goal/queries/keys.ts'
import {GOAL_API} from '@/features/goal/services/goal.http.ts'
import {useQuery} from '@tanstack/react-query'

export const useGoalsListOfUser = (userID: string | number | undefined) => {
    return useQuery({
        queryKey: [QUERY_KEYS.host_goals, userID],
        queryFn: () => GOAL_API.getHostGoals(`${userID}`),
        enabled: !!userID
    })
}
