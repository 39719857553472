import {FC, useEffect} from 'react'
import {FeedCard} from '@/features/feed/types.ts'
import {GoalTeaserCard} from '@/features/feed/components/feed-card/goal-teaser-card/GoalTeaserCard.tsx'
import {useMe} from '@/features/authentication/queries/useMe.ts'
import {GuestType, HostPartialType} from '@/features/authentication/types.ts'
import {TelegramBotCard} from '@/features/feed/components/feed-card/telegram-bot-card/TelegramBotCard.tsx'
import {MarketingCard} from '@/features/feed/components/feed-card/marketing-card/MarketingCard.tsx'
import {FilterCard} from '@/features/feed/components/feed-card/filter-card/FilterCard.tsx'
import {CashbackCard} from './cashback-card/CashbackCard'
import {LastFilterCard} from './last-filter-card/LastFilterCard'
import {MoengageCard} from './moengage-card/MoengageCard'
import {HostCard} from './host-card/HostCard'
import useFeedStore from '@/features/feed/store'
import {TalentsCard} from './talents-card/TalentsCard'

export const feedCardVariants = {
    hostCard: 'host',
    marketing: 'marketing',
    lastFilter: 'lastFilter',
    allCaughtUp: 'allCaughtUp',
    seeMoreRookies: 'seeMoreRookies',
    telegramBot: 'telegramBot',
    goalTeaser: 'goalTeaser',
    filter: 'filter',
    moengage: 'moengage',
    chatTopics: 'chatTopics',
    favActivities: 'favActivities',
    cashback: 'cashback',
    topHost: 'topHost'
} as const

type FeedCardProps = {
    card: FeedCard
    carouselCardIndex: number
    scrollToFeedStart: () => void
    hosts: HostPartialType[]
}

export const Card: FC<FeedCardProps> = ({card, carouselCardIndex, scrollToFeedStart, hosts}) => {
    const {data} = useMe(false)
    const user = data as GuestType // This component is only visible to Guest
    const {updateCardView} = useFeedStore()

    useEffect(() => {
        updateCardView(`${card.id}`)
    }, [])

    const cardType = card.cardType

    switch (cardType) {
        case feedCardVariants.marketing:
            return <MarketingCard index={card.index} user={user} />
        case feedCardVariants.lastFilter:
            return <LastFilterCard cardType={cardType} scrollToFeedStart={scrollToFeedStart} />
        case feedCardVariants.telegramBot:
            return <TelegramBotCard user={user} />
        case feedCardVariants.cashback:
            return <CashbackCard user={user} />
        case feedCardVariants.goalTeaser:
            return <GoalTeaserCard index={card.index} />
        case feedCardVariants.filter:
            return <FilterCard user={user} scrollToFeedStart={scrollToFeedStart} />
        case feedCardVariants.moengage:
            return <MoengageCard />
        case feedCardVariants.topHost:
            return <TalentsCard hosts={hosts} />
        default: // cardType is `undefined` for host cards
            return <HostCard content={{...card, id: +card.id}} carouselCardIndex={carouselCardIndex} />
    }
}
Card.displayName = 'FeedCard'
