import {Channel} from '@/features/chat/types'
import {Lens} from '@dhmk/zustand-lens'

type TopFanCompleteModalState = {
    isOpen: boolean
    channel?: Channel | null
}

type TopFanCompleteModalActions = {
    openModal: (params: Omit<TopFanCompleteModalState, 'isOpen'>) => void
    closeModal: () => void
}

export type TopFanCompleteModal = TopFanCompleteModalState & TopFanCompleteModalActions

const initialModal: TopFanCompleteModalState = {isOpen: false, channel: null}

const createTopFanCompleteModalSlice: Lens<TopFanCompleteModal> = set => ({
    ...initialModal,
    openModal: params => set(() => ({...params, isOpen: true})),
    closeModal: () => set(() => ({...initialModal}))
})

export default createTopFanCompleteModalSlice
