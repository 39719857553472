import styled, {css} from 'styled-components'

type MicromorgiPackageItemContainerProps = {
    $isSelected: boolean
}

export const StyledMicromorgiPackageItemContainer = styled.div<MicromorgiPackageItemContainerProps>`
    ${({$isSelected, theme: {spacing, palette}}) => css`
        display: flex;
        flex-direction: column;
        border: 2px solid #cccdd8;
        cursor: pointer;
        transition: transform 0.2s ease;
        border-radius: ${spacing}px;
        width: 46%;
        padding: ${spacing * 1.5}px;
        gap: ${spacing}px;
        ${$isSelected &&
        css`
            transform: translateY(-10px);
            border-color: #8649d1;
            color: ${palette.primary['200']};
        `};
    `}
`
type MicromorgiPackageItemRowProps = {justifyContent?: 'space-between' | 'flex-start'}
export const StyledMicromorgiPackageItemRow = styled.div<MicromorgiPackageItemRowProps>`
    ${({justifyContent = 'space-between'}) => css`
        display: flex;
        flex-direction: row;
        justify-content: ${justifyContent};
        align-items: center;
    `}
`

export const StyledDelAmount = styled.span`
    ${() => css`
        position: relative;

        text-decoration: none;
        &:before {
            content: '';
            border-bottom: 1px solid red;
            position: absolute;
            width: 100%;
            height: 50%;
            transform: rotate(-12deg);
        }
    `}
`
export const StyledDisAmountContainer = styled.div`
    ${({theme: {typography, palette}}) => css`
        ${typography.textSm}
        color: ${palette.neutral[700]};
    `}
`
export const StyledAmountContainer = styled.div`
    flex-direction: column;
    display: flex;
`
export const StyledAmount = styled.span`
    ${({theme: {typography}}) => css`
        ${typography.displaySm}
        display: flex;
        align-items: center;
        font-weight: 700;
        line-height: 25px;
        letter-spacing: -0.05em;
    `}
`
export const StyledNowSpan = styled.span`
    ${({theme: {typography}}) => css`
        ${typography.textSm}
    `}
`
export const StyledPriceSpan = styled.span`
    ${({theme: {typography, palette}}) => css`
        ${typography.textXs}
        color: ${palette.neutral[700]};
        font-weight: 400;
    `}
`
