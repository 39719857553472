import {LabelHTMLAttributes, ReactNode} from 'react'
import {StyledLabel} from './style'
import {DefaultNamespace} from 'i18next'

interface LabelProps extends LabelHTMLAttributes<HTMLLabelElement> {
    text: DefaultNamespace | ReactNode | string
}

export const Label = ({text, ...rest}: LabelProps) => {
    return <StyledLabel {...rest}>{text}</StyledLabel>
}

Label.displayName = 'Label'
