import {SignupButton} from '../signup-button/SignupButton.tsx'
import {HeaderActionItems} from '../header-action-items/HeaderActionItems.tsx'
import {useMe} from '@/features/authentication/queries/useMe.ts'

interface RightCtaHeaderProps {
    isLight?: boolean
}

export const RightCtaHeader = ({isLight = true}: RightCtaHeaderProps) => {
    const {loggedIn} = useMe()

    if (loggedIn) {
        return <HeaderActionItems isLight={isLight} />
    } else {
        return <SignupButton />
    }
}
