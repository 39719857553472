import {FC, MouseEvent, useMemo} from 'react'
import {HostCardContent} from '@/features/feed/types'
import {computeAge} from '@/utilities/helpers'
import heartFilled from '@/assets/images/heart-filled.png'
import heartEmpty from '@/assets/images/heart-empty.png'
import {Flexbox} from '@/components/ui/flexbox/FlexBox'
import {Spinner} from '@components/ui/spinner/Spinner.tsx'
import {useSaveHost} from '@/features/guest/queries/useSaveHost.ts'
import {useUnsaveHost} from '@/features/guest/queries/useUnsaveHost.ts'
import {useGetSavedHosts} from '@/features/guest/queries/useGetSavedHosts.ts'
import {HostTopics, TopicOption} from '@/features/profile/components/host-profile-topics/HostTopics.tsx'
import {
    StyledCardContainer,
    StyledExpertiseIconWrapper,
    StyledFavTopicsContainer,
    StyledInfoContainer,
    StyledInfoWrapper,
    StyledOnlineCircle,
    StyledOverlaySlider,
    StyledSliderWrapper,
} from '@/features/feed/components/feed-card/host-card/style'
import {HostPhotoSlider} from '@/features/feed/components/feed-card/host-card/host-photo-slider/HostSlider'
import {HostVerificationIcon} from '@/features/feed/components/feed-card/host-card/host-verification-icon/HostVerificationIcon'
import {HostMedia} from '@/features/feed/components/feed-card/host-card/host-media/HostMedia'
import {StyledCardContent, StyledSavedButtonContainer, StyledTitleWrapper} from './style'
import { ExpertiseIcon } from '@/components/commons/expertise-icon/ExpertiseIcon'

type HostCardsProps = {
    content: HostCardContent
    CtaComponent?: () => JSX.Element
}

export const MoengageHostCard: FC<HostCardsProps> = ({content, CtaComponent}) => {
    const {mutate: saveHost, isPending: isPendingSave} = useSaveHost()
    const {mutate: unsaveHost, isPending: isPendingUnsave} = useUnsaveHost()
    const {data: savedHosts} = useGetSavedHosts()
    const isSavedHost = !!savedHosts?.find(({id}) => id === content.id)
    const isSaving = isPendingSave || isPendingUnsave
    const expertise = content.type_attributes?.expertise?.key_name
    const expertiseCategory = content.type_attributes?.expertise?.category

    const birthDate = content.type_attributes?.birth_date || ''
    const rookieAge = useMemo(() => {
        return computeAge(birthDate)
    }, [birthDate])

    const photosUrls = content.type_attributes?.photos?.map(photo => photo.url)

    const likeButtonClickHandler = (e: MouseEvent) => {
        e.stopPropagation()
        if (!content.id) return
        if (isSavedHost) {
            unsaveHost(content.id)
        } else {
            saveHost(content.id)
        }
    }

    if (!content.username) return <Spinner />

    return (
        <StyledCardContainer isSpecial={!!content.type_attributes?.special_type}>
            <StyledSliderWrapper>
                <StyledOverlaySlider />
                <StyledExpertiseIconWrapper>
                    {expertise && !!content.type_attributes?.special_type && (
                        <ExpertiseIcon expertise={expertise} expertiseCategory={expertiseCategory}/>
                    )}
                </StyledExpertiseIconWrapper>

                <HostPhotoSlider images={photosUrls} />
            </StyledSliderWrapper>

            <StyledSavedButtonContainer onClick={likeButtonClickHandler}>
                {isSaving && <Spinner inline size={16} />}
                {!isSaving && <img alt={'like button'} src={isSavedHost ? heartFilled : heartEmpty} />}
            </StyledSavedButtonContainer>

            <StyledCardContent>
                <StyledInfoContainer>
                    <StyledInfoWrapper>
                        <StyledTitleWrapper>
                            <h4>{content.full_name}</h4>
                            <span>{`, ${rookieAge}`}</span>
                            {content.has_verified_id && (
                                <HostVerificationIcon hostGenderId={content.gender?.id} hostName={content.full_name} />
                            )}
                            {content.is_online && <StyledOnlineCircle />}
                        </StyledTitleWrapper>

                        <p>{content.description}</p>
                    </StyledInfoWrapper>
                    <StyledFavTopicsContainer>
                        {!!content.chat_topics?.length && !!content.favorite_activities?.length && (
                            <HostTopics
                                withChatTopics
                                comparedChatTopics={content.chat_topics as TopicOption[]} // TODO: this is due to deepPartial
                                withActivities
                                comparedActivities={content.favorite_activities as TopicOption[]}
                            />
                        )}
                    </StyledFavTopicsContainer>
                </StyledInfoContainer>

                <Flexbox justify="space-between" align="end">
                    <HostMedia content={content} />
                    <Flexbox align="end" height={'100%'}>
                        {!!CtaComponent && <CtaComponent/>}
                    </Flexbox>
                </Flexbox>
            </StyledCardContent>
        </StyledCardContainer>
    )
}

MoengageHostCard.displayName = 'MoengageHostCard'
