import {CATEGORY_ART_EXPERTISE, CATEGORY_SPORT_EXPERTISE, expertiseIcons} from '@/utilities/constants/expertises'
import microphone from '@assets/images/expertise/pop.png'
import football from '@assets/images/expertise/football.png'
import {FC} from 'react'
import { StyledExpertiseIcon } from './style'

type ExpertiseIconProps = {
    expertise: string | undefined
    expertiseCategory: string | undefined
    width?: number
    height?: number
}

export const ExpertiseIcon: FC<ExpertiseIconProps> = ({expertise, expertiseCategory, width = 24, height = 24}) => {

    const renderCorrectExpertiseIcon = () => {
        switch (expertiseCategory) {
            case CATEGORY_ART_EXPERTISE:
                return microphone
            case CATEGORY_SPORT_EXPERTISE:
                if (expertise) {
                    return expertiseIcons[expertise] ? expertiseIcons[expertise] : football
                }
        }
    }

    return <StyledExpertiseIcon width={width} height={height} src={renderCorrectExpertiseIcon()} alt="expertise-icon" />
}

ExpertiseIcon.displayName = 'ExpertiseIcon'
