import styled, {DefaultTheme, css} from 'styled-components'

export const StyledNotificationCard = styled.div`
    ${({old, color, theme: {spacing, typography}}: {old?: boolean; color?: string; theme: DefaultTheme}) => css`
        position: relative;
        display: flex;
        flex-direction: column;
        border-left: ${old ? 'none' : `3px solid ${color}`};
        ${typography.textXs};
        padding: ${old
            ? `${spacing * 1.5}px ${spacing * 1.5}px ${spacing * 2}px 0px`
            : `${spacing * 1.5}px ${spacing * 1.5}px ${spacing * 2}px ${spacing * 1.5}px`};
        cursor: pointer;
    `}
`

export const StyledDescriptionContainer = styled.div`
    ${({theme: {spacing}}) => css`
        display: flex;
        align-items: center;
        gap: ${spacing}px;
    `}
`

export const StyledDate = styled.div`
    ${({theme: {typography}}) => css`
        position: absolute;
        ${typography.textXxs};
        right: 10px;
        bottom: 0px;
    `}
`

export const StyledNotificationTitle = styled.span`
    ${({theme: {spacing}}) => css`
        font-weight: 700;
        margin-right: ${spacing / 2}px;
    `}
`

export const StyledIconBox = styled.div`
    width: 24px;
    height: 24px;
`
