import {FC, ReactNode, useEffect, useState} from 'react'
import {createPortal} from 'react-dom'
import {useLockBodyScroll} from '@/hooks/useLockBodyScroll'
import {CloseIcon, RightArrowActionIcon} from '@/assets/icons/icons'
import {StyledCloseIcon, StyledCounter, StyledHeader, StyledOverlay, styledArrows, styledCarousel} from './style'
import {useTheme} from 'styled-components'
import {MediaTypeE} from '@/types'
import {Carousel} from '../carousel/Carousel'

// /**
//  * Usage example:
//  *
//  *      - remap photos or videos to media array like
//  *           const photosMap = photos?.map((photo) => {
//  *             return {
//  *                 type: 'image',
//  *                 url: photo?.url,
//  *             }
//  *         })
//  *
//  *          const media = [...new Set([...photosMap, videoMap])]
//  *
//  *
//  *       - declare options for plyr
//  **/

interface MediaLightBoxProps {
    media: ({type: MediaTypeE; url: string | null} | undefined)[]
    plyrOptions?: Plyr.Options
    onClose: () => void
    autoplay?: boolean
    currentIndex?: number
    isMultipleTypesAutoplayVideo?: boolean
    headerInfo?: ReactNode
}

export const MediaLightBox: FC<MediaLightBoxProps> = ({
    media,
    plyrOptions = {},
    onClose,
    autoplay = false,
    currentIndex = 0,
    isMultipleTypesAutoplayVideo = false,
    headerInfo = null
}) => {
    const {palette} = useTheme()
    const [activeIndex, setActiveIndex] = useState(currentIndex)

    useLockBodyScroll({enable: true})

    const slideCarousel = (newIndex: number) => {
        if (newIndex < 0) {
            newIndex = 0
        } else if (newIndex >= media.length) {
            newIndex = media.length - 1
        }
        setActiveIndex(newIndex)
    }

    useEffect(() => {
        if (autoplay === true) {
            const interval = setInterval(() => {
                slideCarousel(activeIndex + 1)
            }, 4000)

            return () => {
                if (interval) {
                    clearInterval(interval)
                }
            }
        }
    }, [activeIndex, media])

    // use isMultipleTypesAutoplayVideo = true, If you want photo and video carousel and autoplay video
    const correctOptions = isMultipleTypesAutoplayVideo
        ? {...plyrOptions, autoplay: media[activeIndex]?.type === MediaTypeE.VIDEO}
        : plyrOptions

    return createPortal(
        <StyledOverlay>
            <StyledHeader>
                {headerInfo ? (
                    headerInfo
                ) : (
                    <StyledCounter>
                        {activeIndex + 1} / {media?.length}
                    </StyledCounter>
                )}
                <StyledCloseIcon onClick={onClose}>
                    <CloseIcon fill={palette.neutral.white} />
                </StyledCloseIcon>
            </StyledHeader>
            <Carousel
                media={media.map((media, idx) =>
                    media?.type === MediaTypeE.VIDEO && idx !== activeIndex
                        ? {...media, options: {autoplay: false}}
                        : media
                )}
                videoOptions={correctOptions}
                customArrows={{
                    next: <RightArrowActionIcon fill={palette.neutral.white} />,
                    prev: <RightArrowActionIcon fill={palette.neutral.white} />
                }}
                customOptions={{
                    rewind: false,
                    pagination: false,
                    drag: !(media?.length === 1),
                    start: currentIndex
                }}
                arrowsStyles={styledArrows}
                carouselStyles={styledCarousel}
                onSwipe={({newIndex}) => slideCarousel(newIndex)}
            />
        </StyledOverlay>,
        document.body
    )
}

MediaLightBox.displayName = 'MediaLightBox'
