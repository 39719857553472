import {forwardRef, useCallback, useEffect} from 'react'
import ReactSelect, {GroupBase, MultiValue, SelectInstance, SingleValue} from 'react-select'
import CreatableSelect from 'react-select/creatable'
import {customStyles, StyledContainer, StyledIndicatorBox} from './style'
import {useTheme} from 'styled-components'
import {Label} from '@components/ui/label/Label'
import {InputHelpText} from '@components/ui/input-help-text/InputHelpText'
import {DropdownArrowIcon} from '@/assets/icons/icons'
import {OptionType, SingleSelectProps} from './types'

const SingleSelect = forwardRef<SelectInstance<OptionType, boolean, GroupBase<OptionType>>, SingleSelectProps>(
    (
        {
            name,
            label,
            options = [],
            helpText,
            value,
            readOnly,
            disabled,
            defaultValue,
            errorMessage,
            touched = false,
            isSearchable = true,
            isCreatable = false,
            isClearable,
            addOptionMessage = '',
            onChange,
            requestAddNewOption, //for request to save
            ...rest
        }: SingleSelectProps,
        ref
    ) => {
        const theme = useTheme()
        const inputText = ''
        // Label for new item creation
        const createLabel = useCallback(
            (name: string) => (
                <span>
                    {addOptionMessage}
                    <span>{name}</span>
                </span>
            ),
            [addOptionMessage]
        )

        const handleAddNewOption = async (inputText: string) => {
            if (!requestAddNewOption) return

            const newOption = await requestAddNewOption(inputText)
            return newOption
        }

        const handleSelectChange = async (selectedOption: MultiValue<OptionType> | SingleValue<OptionType>) => {
            if (Array.isArray(selectedOption)) return
            if (selectedOption && !(selectedOption as OptionType).id) {
                const newOption = await handleAddNewOption(inputText)
                newOption && onChange?.(newOption) //take key which you need in form
            } else {
                onChange?.(selectedOption as OptionType) //take key which you need in form
            }
        }

        const defaultSelectProps = {
            // menuIsOpen: true, //for checking styles in options
            maxMenuHeight: 300,
            minMenuHeight: 250,
            touched,
            disabled,
            isSearchable,
            isCreatable,
            isDisabled: readOnly || disabled,
            isClearable,
            options,
            name,
            classNamePrefix: isCreatable ? 'creatable_select' : 'select',
            value:
                options.find(option => {
                    return option.value === value?.value
                }) || undefined, //There is no state, so we throw it into select
            hideSelectedOptions: false,
            createLabel,
            isOptionDisabled: ({disabled}: OptionType) => !!disabled,
            onChange: handleSelectChange,
            ...rest
        }
        useEffect(() => {
            if (defaultValue && !value) onChange?.(defaultValue)
        }, [defaultValue])

        const selectComponentProps = {
            formatCreateLabel: createLabel,
            components: {
                DropdownIndicator: () => {
                    return (
                        <StyledIndicatorBox>
                            <DropdownArrowIcon width={8} height={4} />
                        </StyledIndicatorBox>
                    )
                }
            },
            ...defaultSelectProps,
            styles: customStyles({theme, isError: !!errorMessage, touched: touched})
        }

        return (
            <div>
                <StyledContainer disabled={!!disabled}>
                    {label && <Label text={label} />}

                    {isCreatable ? (
                        <div>
                            <InputHelpText error={errorMessage} />
                            <CreatableSelect ref={ref} {...selectComponentProps} />
                        </div>
                    ) : (
                        <div>
                            <ReactSelect ref={ref} {...selectComponentProps} />
                            <InputHelpText error={errorMessage} />
                        </div>
                    )}
                    {helpText && <InputHelpText helpText={helpText} />}
                </StyledContainer>
            </div>
        )
    }
)

SingleSelect.displayName = 'SingleSelect'

export default SingleSelect
