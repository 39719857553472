import styled, {DefaultTheme, css} from 'styled-components'

export const StyledTimer = styled.div`
    ${({theme: {palette, typography}}) => css`
        background-color: ${palette.primary['75']};
        padding: 5px;
        border-radius: 7px;

        & div {
            ${typography.textXs};
            color: ${palette.neutral.white};
            font-weight: 700;
        }
    `}
`

export const tagStyles = ({theme, background}: {theme: DefaultTheme; background: string}) => css`
    background: ${background};
    gap: 0;
    color: ${theme.palette.neutral.white};
    text-wrap: nowrap;
`
