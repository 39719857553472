import {useQuery} from '@tanstack/react-query'
import {GIFS_API} from '../services/gifs.http'
import {GetAnimationsRequestType} from '../types'
import {QUERY_KEYS} from './keys'

export const useGetPaidGifs = (props: GetAnimationsRequestType) => {
    return useQuery({
        queryKey: [QUERY_KEYS.animations, props],
        queryFn: () => GIFS_API.getAnimations(props)
    })
}
