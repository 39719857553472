import {useRootStore} from '@/store'
import {selectGoalFilters, selectResetChatState} from '@/store/selectors'

// Add your reset action here if you need to clear the store on logout

export const useResetStore = () => {
    const resetChatStore = useRootStore(selectResetChatState)
    const {clearGoalFilters} = useRootStore(selectGoalFilters)

    const resetStore = () => {
        resetChatStore()
        clearGoalFilters()
    }

    return resetStore
}
