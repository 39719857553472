import {MicromorgiIconMotion} from '@/assets/icons/icons'
import {AnimatePresence, motion} from 'framer-motion'
import {FC, RefObject, useEffect, useState} from 'react'
import {AnimationWrapper} from './style'

export type MoveMorgiIconAnimationProps = {
    transitionDuration?: number
    iconSize: {width: number; height: number}
    gemRef: RefObject<HTMLSpanElement> | null
}

const defaultWidth = 59
const defaultHeight = 82

export const MoveMorgiIconAnimation: FC<MoveMorgiIconAnimationProps> = ({transitionDuration = 0, iconSize, gemRef}) => {
    const [endPos, setEndPos] = useState<{x: number[]; y: number[]} | null>(null)

    useEffect(() => {
        const containerDOMRect = document.getElementById('id-of-confirm-modal')?.getBoundingClientRect()
        const iconDOMRect = gemRef?.current?.getBoundingClientRect()

        if (containerDOMRect && iconDOMRect) {
            const initialX = iconDOMRect.x
            const initialY = iconDOMRect.y
            const finalX = containerDOMRect.left - iconDOMRect.width / 2 + containerDOMRect.width / 2
            const finalY = containerDOMRect.top - defaultHeight / 2

            setEndPos({
                x: [initialX, initialX, initialX, finalX, finalX, finalX, finalX, finalX, finalX, finalX],
                y: [initialY, initialY, initialY, finalY, finalY, finalY, finalY, finalY, finalY, finalY]
            })
        }
    }, [])

    const getScale = (scaleValue: number) =>
        `${(defaultWidth / iconSize.width) * scaleValue}, ${(defaultHeight / iconSize.height) * scaleValue}`

    return (
        <AnimationWrapper>
            <AnimatePresence>
                <motion.div
                    initial={{
                        zIndex: 11,
                        scale: 0.35,
                        position: 'absolute'
                    }}
                    animate={{
                        ...endPos,
                        scale: [
                            getScale(0.35),
                            getScale(0.4),
                            getScale(0.35),
                            getScale(1),
                            getScale(0.8),
                            getScale(1),
                            getScale(0.8),
                            getScale(1)
                        ],
                        transition: {duration: transitionDuration},
                        opacity: 1,
                        zIndex: 1034,
                        position: 'absolute',
                        pointerEvents: 'none',
                        ...iconSize
                    }}
                >
                    <MicromorgiIconMotion {...iconSize} />
                </motion.div>
            </AnimatePresence>
        </AnimationWrapper>
    )
}
