import {useGetFilterFavActivities} from '@/features/chat-fav-topics/queries/useGetFilterFavActivities.ts'
import {FC} from 'react'
import {Topic} from '@/features/chat-fav-topics/types.ts'
import {StyledTopicsChip, StyledTopicsContainer} from '../style'
import {FavoriteActivitiesIcon} from '@/assets/icons/icons'
import {useRootStore} from '@/store'
import {selectChatFavTopics} from '@/store/selectors'

type FavoriteActivitiesFilterCardProps = {
    onSelectItem: (favoriteActivities: Topic) => void
    selectedItems: Topic[]
}

export const FavoriteActivitiesFilterCard: FC<FavoriteActivitiesFilterCardProps> = ({onSelectItem, selectedItems}) => {
    const {data: favoriteActivities} = useGetFilterFavActivities()
    const activities = useRootStore(selectChatFavTopics)
    return (
        <StyledTopicsContainer>
            {favoriteActivities?.map((activity, index) => {
                const isSelected = !!selectedItems.find(item => activity.id === item.id)
                return (
                    <StyledTopicsChip
                        key={index}
                        onClick={() => {
                            onSelectItem(activity)
                        }}
                        isSelected={isSelected}
                    >
                        <FavoriteActivitiesIcon />
                        {activities[activity?.key_name]}
                    </StyledTopicsChip>
                )
            })}
        </StyledTopicsContainer>
    )
}
