import {QUERY_KEYS as AUTHENTICATION_QUERY_KEYS} from '@/features/authentication/queries/keys'
import {useMutation, useQueryClient} from '@tanstack/react-query'
import {MICROMORGI_API} from '../services/micromorgi.http'
import {SendMicromorgiParams} from '../types'
import {MUTATION_KEYS} from './keys'

export const useSendMicromorgi = (onSuccess?: () => void) => {
    const queryClient = useQueryClient()
    return useMutation({
        mutationKey: [MUTATION_KEYS.send_micromorgi],
        mutationFn: (props: SendMicromorgiParams) => MICROMORGI_API.sendMicromorgi(props),
        onSuccess: () => {
            onSuccess?.()
            queryClient.invalidateQueries({queryKey: [AUTHENTICATION_QUERY_KEYS.user]})
        }
    })
}
