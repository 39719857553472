import axios from '@/clientAPI.ts'
import {MixpanelEventNameE, TriggerBackendEventParamsType} from '../types'

export const MIXPANEL_API = {
    sendMixpanelData: async (body: TriggerBackendEventParamsType<MixpanelEventNameE>) => {
        const {data} = await axios.post('/mixpanel/events', body)
        return data
    },
    sendMixpanelSwipeData: async ({rookies_ids}: {rookies_ids: string[]}) => {
        const {data} = await axios.post('/mixpanel/events/carousel-swipe', {rookies_ids})
        return data
    }
}
