import styled, {css} from 'styled-components'

export const StyledCountdown = styled.div`
    ${({theme: {palette, typography}}) => css`
        color: ${palette.primary['200']};
        font-weight: 700;
        & div {
            ${typography.textXs};
        }
    `}
`

export const StyledCanceled = styled.div`
    ${({theme: {palette, typography}}) => css`
        color: ${palette.danger['50']};
        ${typography.textXs};
    `}
`
