import {useQuery} from '@tanstack/react-query'
import {QUERY_KEYS} from './keys'
import {CHAT_FAV_TOPICS_API} from '../services/chatFavTopics.http'
import {useMe} from '@/features/authentication/queries/useMe'

export const useGetFilterChatTopics = (is_popular = true, filter_for_component = false) => {
    const {data: user, loggedIn} = useMe()
    return useQuery({
        queryKey: [QUERY_KEYS.filter_chat_topics],
        queryFn: () => CHAT_FAV_TOPICS_API.getFilterChatTopics(is_popular, filter_for_component),
        enabled: !!user && !!loggedIn
    })
}
