import {useGetMoengageCampaign} from '@/features/moengage/queries/useGetMoengageCampaign'
import {ConnectorNamesE} from '@/features/moengage/services/moengage.model'
import {useMoengageCombinationUI} from '@/integrations/moengage/MoengageProvider'

export const MoengageCard = () => {
    const {moengageCombinationUI} = useMoengageCombinationUI()
    const {data: combination} = useGetMoengageCampaign()

    return <>{!!combination && combination.connector.name === ConnectorNamesE.card && moengageCombinationUI}</>
}

MoengageCard.displayName = 'MoengageCard'
