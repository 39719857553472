import {Divider} from '@/components/ui/divider/Divider'
import styled, {css, DefaultTheme} from 'styled-components'

export const StyledGif = styled.div`
    width: 200px;
    height: 200px;
    border-radius: 10px;
    cursor: pointer;
    aspect-ratio: 1/1;
    margin: 0 auto;
`

export const StyledDivider = styled(Divider)`
    ${({theme: {mediaQueries}}) => css`
        ${mediaQueries.m} {
            display: none;
        }
    `}
`

export const modalStyles = (theme: DefaultTheme) => css`
    width: 100%;
    height: 55%;
    align-items: center;
    top: unset;
    transform: translate(-50%, -100%);
    ${theme.mediaQueries.m} {
        transform: translate(-50%, -50%);
        top: 50%;
    }
    & button {
        width: 90%;
        margin: 0px auto ${theme.spacing * 2}px;
    }
`
