export enum UserStatusesE {
    ACCEPTED = 'accepted',
    PENDING = 'pending',
    DELETED = 'deleted',
    REJECTED = 'rejected',
    UNTRUSTED = 'untrusted',
    BLOCKED = 'blocked',
    NEW = 'new',
    FRAUD = 'fraud'
}

export enum IdCardStatuses {
    PENDING = 'pending',
    APPROVED = 'approved',
    REJECTED = 'rejected',
    DEFAULT = 'default'
}

export enum GenderKeyNameE {
    ALL = 'all',
    MALE = 'male',
    FEMALE = 'female',
    OTHER = 'other'
}

export enum OldRoles {
    leader = 'guest',
    rookie = 'host'
}
