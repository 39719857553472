import styled, {css} from 'styled-components'

export const InspirationsStarredMessageModalContainer = styled.div`
    ${({theme: {spacing}}) => css`
        display: grid;
        grid-template-rows: auto 0fr;
        padding: ${spacing * 3}px;
        max-height: 100%;
        overflow-y: auto;
    `}
`

export const StyledInspirationsStarredMessageList = styled.div`
    ${({theme: {spacing}}) => css`
        display: grid;
        overflow-x: hidden;
        overflow-y: auto;
        margin-bottom: ${spacing * 2.25}px;
        justify-content: end;
        max-height: 380px;
        & div:nth-child(3n + 2) {
            max-width: 177px;
        }
        & div:nth-child(3n) {
            max-width: 279px;
        }
    `}
`
export const StyledInspirationsStarredMessage = styled.div`
    ${({theme: {palette, spacing}}) => css`
        max-width: 246px;
        color: ${palette.neutral.white};
        padding: ${spacing * 1.25}px;
        background: ${palette.gradient[50]};
        justify-self: end;
        border-radius: ${spacing * 1.25}px 0px ${spacing * 1.25}px ${spacing * 1.25}px;
        margin-bottom: ${spacing * 1.25}px;
    `}
`
