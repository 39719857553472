import {QUERY_KEYS} from './keys'
import {useQuery} from '@tanstack/react-query'
import {MY_SURPRISES_API} from '../services/mySurprises.http'

export const useUnlockHostSurprise = ({hostId, enabled}: {hostId: number | string; enabled: boolean}) => {
    return useQuery({
        queryKey: [QUERY_KEYS.unlock_host_surprise, hostId],
        queryFn: () => MY_SURPRISES_API.unlockHostSurprise(hostId),
        enabled
    })
}
