import {Dispatch, SetStateAction} from 'react'
import {Topic} from '@/features/chat-fav-topics/types'
import {StyledTopicsContent, StyledTopicsList} from './style'
import useContainerDimensions from '@/hooks/useContainerDimensions'
import {TopicItem} from '../host-filters/topic-item/TopicItem'
import {selectChatFavTopics} from '@/store/selectors'
import {useRootStore} from '@/store'

type TopicsListProps = {
    topicsList: Topic[]
    defaultTopicsList: Topic[]
    handleSelect: Dispatch<SetStateAction<Topic[]>>
    icon: JSX.Element
}

export const TopicsList = ({topicsList, defaultTopicsList, handleSelect, icon}: TopicsListProps) => {
    const {height: vh} = useContainerDimensions()
    const topics = useRootStore(selectChatFavTopics)
    const filteredItems = topicsList?.filter(topic => topic?.key_name in topics)
    const onSelect = (item: Topic, isSelected: boolean) => {
        if (isSelected) {
            // Remove
            handleSelect(prevState => prevState.filter(prevItem => prevItem.id !== item.id))
        } else {
            // Add
            handleSelect([...defaultTopicsList, item])
        }
    }

    return (
        <StyledTopicsContent vh={vh}>
            <StyledTopicsList>
                {filteredItems?.map(item => {
                    const isSelected = defaultTopicsList.some(defaultTopic => defaultTopic.id === item.id)
                    return (
                        <div key={`${item.id}${item.name}`} onClick={() => onSelect(item, isSelected)}>
                            <TopicItem icon={icon} name={topics[item.key_name]} isSelected={isSelected} />
                        </div>
                    )
                })}
            </StyledTopicsList>
        </StyledTopicsContent>
    )
}
