import styled, {css} from 'styled-components'

export const StyledSupportedUser = styled.div`
    ${({theme: {spacing}}) => css`
        display: flex;
        align-items: center;
        gap: ${spacing}px;
        padding-right: ${spacing}px;
    `}
`
export const StyledSupportedUserContent = styled.div`
    ${() => css`
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        width: 100%;
    `}
`

export const StyledSupportedUserInform = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
`

export const StyledSupportedUserLastPaymentDate = styled.span`
    ${({theme: {typography}}) => css`
        ${typography.textXxs};
        text-wrap: nowrap;
    `}
`

export const StyledSupportedUserInformName = styled.span`
    max-width: 140px;
    overflow: hidden;
    text-overflow: ellipsis;
`

export const StyledSupportedUserInformAmount = styled.span`
    ${({theme: {palette, spacing, typography}}) => css`
        ${typography.textLg}
        gap: ${spacing / 4}px;
        display: flex;
        align-items: center;
        font-weight: 700;
        color: ${palette.primary[900]};
    `}
`

export const radioInputStyles = () => css`
    label {
        font-weight: 400;
    }
`
