import {useTranslation} from 'react-i18next'
import {Card} from '@components/ui/card/Card.tsx'
import {rootCardClass, StyledHint, StyledStatusSection} from '@/features/goal/components/upload-area/style.ts'
import {CircleProgress} from '@components/ui/circle-progress/CircleProgress.tsx'
import {progressGradient} from '@/features/goal/constant/goalCard.ts'
import {FilledCloseIcon, GoalUploadIcon, UploadPhotoIcon, UploadVideoIcon} from '@assets/icons/icons.tsx'
import {uploadAreaType} from '@components/ui/file-uploader/FileUploader.tsx'
import {useParams} from 'react-router-dom'
import {useGetChannelDetails} from '@/features/chat/queries/useGetChannelDetails.ts'
import {ChannelID} from '@/features/chat/types.ts'
import {Media} from '@components/ui/media/Media.tsx'
import {StyledPreview, StyledUploadPaidMediaContainer} from '@/features/chat/components/AttachmentsPanel/style.ts'
import {truncate} from 'lodash'
import {MediaTypeE} from '@/types.ts'

export const UploadAreaPaidMessage = ({
    isDragReject,
    isDragActive,
    uploadedFile,
    fileRejection,
    handleRemove,
    progress,
    isError,
    isSuccessfulUpload
}: uploadAreaType) => {
    const {t} = useTranslation()
    const isMediaUploaded = isSuccessfulUpload && uploadedFile && progress === 100
    const errorCode = fileRejection?.errors[0].code
    const {channel} = useParams()
    const {data: channelDetails} = useGetChannelDetails(channel as ChannelID)
    const fileType = uploadedFile ? uploadedFile.type.split('/')[0] : ''

    return (
        <Card rootCustomClass={rootCardClass}>
            <StyledUploadPaidMediaContainer>
                {!isMediaUploaded ? (
                    <CircleProgress
                        percentage={!isError ? progress : 0}
                        staticCircleStrokeWidth={5}
                        staticCircleStrokeColor={isError || errorCode ? progressGradient.singleFail : undefined}
                        progressColor={progressGradient.default}
                        icon={progress !== 0 ? `${progress}%` : <GoalUploadIcon />}
                        animated={false}
                    />
                ) : (
                    <StyledPreview>
                        {fileType === MediaTypeE.IMAGE ? (
                            <>
                                <Media variant={'cover'} imageUrl={URL.createObjectURL(uploadedFile)} />
                                <UploadPhotoIcon />
                            </>
                        ) : (
                            <>
                                <video>
                                    <source src={URL.createObjectURL(uploadedFile)} />
                                </video>
                                <UploadVideoIcon />
                            </>
                        )}
                    </StyledPreview>
                )}

                <StyledStatusSection>
                    {!isDragActive && !isDragReject && (
                        <>
                            <span>
                                {uploadedFile?.name
                                    ? truncate(uploadedFile?.name)
                                    : t('create_goal:image_uploader:default:title')}
                            </span>
                            {!errorCode ? (
                                <StyledHint>
                                    {' '}
                                    {t('paid_media_modal:upload_media_hint', {
                                        LEADER_NAME: channelDetails?.full_name
                                    })}
                                </StyledHint>
                            ) : (
                                t(`validation:${errorCode}`)
                            )}
                        </>
                    )}
                    {isDragActive && !isDragReject && <strong>{t('common:release_here')}</strong>}
                    {isDragReject && <span>{t('validation:file_not_accepted')}</span>}
                </StyledStatusSection>
                {isMediaUploaded && <FilledCloseIcon onClick={handleRemove} />}
            </StyledUploadPaidMediaContainer>
        </Card>
    )
}
