import styled, {css} from 'styled-components'

export const StyledUpdateCreditCardModalContainer = styled.div`
    ${({theme: {spacing, mediaQueries}}) => css`
        display: flex;
        flex-direction: column;
        gap: ${spacing * 2}px;
        height: min-content;
        padding: ${spacing / 2}px ${spacing}px ${spacing * 2}px ${spacing}px;
        ${mediaQueries.m} {
            padding: ${spacing / 2}px ${spacing * 3}px ${spacing * 3}px ${spacing * 3}px;
        }
    `}
`
export const StyledUpdateCreditCardModalDescription = styled.span`
    text-align: center;
`
