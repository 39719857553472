import {Lens} from '@dhmk/zustand-lens'
//use this when you need light and dark header on one page
type HeaderMode = 'light' | 'dark'

type HeaderModeState = {
    isHeaderLight: boolean
}

type HeaderModeAction = {
    toggleHeaderMode: (mode: HeaderMode) => void
}

const initialState: HeaderModeState = {
    isHeaderLight: true
}

export type HeaderState = HeaderModeState & HeaderModeAction

const createHeaderModeSlice: Lens<HeaderState> = set => ({
    ...initialState,
    toggleHeaderMode: mode => set({isHeaderLight: mode === 'light'})
})
export default createHeaderModeSlice
