import {palette} from '@/theme/palette'
import {TopicOption} from '../components/host-profile-topics/HostTopics'
import blurred_img_1 from '@/assets/images/random-blurred-surprises/blurred_img_1.png'
import blurred_img_2 from '@/assets/images/random-blurred-surprises/blurred_img_2.png'
import blurred_img_3 from '@/assets/images/random-blurred-surprises/blurred_img_3.png'
import blurred_img_4 from '@/assets/images/random-blurred-surprises/blurred_img_4.png'
import blurred_img_5 from '@/assets/images/random-blurred-surprises/blurred_img_5.png'
import blurred_img_6 from '@/assets/images/random-blurred-surprises/blurred_img_6.png'
import blurred_img_7 from '@/assets/images/random-blurred-surprises/blurred_img_7.png'
import blurred_img_8 from '@/assets/images/random-blurred-surprises/blurred_img_8.png'
import blurred_img_9 from '@/assets/images/random-blurred-surprises/blurred_img_9.png'
import blurred_img_10 from '@/assets/images/random-blurred-surprises/blurred_img_10.png'
import {MediaTypeE} from '@/types'

export type TopicOptionToShow = {
    id: number
    is_popular: boolean
    key_name: string
    name: string
    users_count?: number
    index: number
    color?: string
    isCommon?: boolean
}

export type Photos = {
    id?: number
    user_id?: number
    main?: boolean
    created_at?: string
    under_validation?: boolean
    type?: MediaTypeE
    url: string | null | undefined
    path_location?: string | null | undefined
    percent?: number
    chance?: boolean
    surprise?: boolean
}

export const getListToShow = (viewedList?: TopicOption[], userList?: TopicOption[]): TopicOptionToShow[] => {
    const listToShow = (viewedList ?? []).reduce((acc: TopicOptionToShow[], currentValue) => {
        const idUserList = (userList ?? []).map(({id}) => id)

        // Check if we have same items in both lists
        if (idUserList.includes(currentValue.id)) {
            // Get index of items in user list
            const indexOfElement = idUserList.indexOf(currentValue.id)

            // Create list in item object add index of item which they have in user list
            acc.push({
                ...currentValue,
                index: indexOfElement,
                color: `${palette.light.primary['200']}`,
                isCommon: true
            })
            // Sort list by index in user list
            acc.sort((a, b) => a.index - b.index)
        } else {
            acc.push({
                ...currentValue,
                index: 10 + acc.length, // We have max 5 topics so 10 > 5
                color: '#00063D',
                isCommon: false
            })
        }
        return acc
    }, [])
    return listToShow
}

export const commonTopicsNames = (userTopics?: TopicOption[], comparedTopics?: TopicOption[]) => {
    return userTopics && comparedTopics
        ? comparedTopics
              .filter(chatTopic => {
                  return userTopics.some(userTopic => userTopic.id === chatTopic.id)
              })
              .map(commonChatTopic => commonChatTopic.name)
        : []
}

export const blurredPhotos = [
    blurred_img_1,
    blurred_img_2,
    blurred_img_3,
    blurred_img_4,
    blurred_img_5,
    blurred_img_6,
    blurred_img_7,
    blurred_img_8,
    blurred_img_9,
    blurred_img_10
]

/*
 surprises in profile slider: 

 - Starting from the 3rd photo, each subsequent photo has a 10% chance of being followed by a surprise photo.
 - Once a surprise photo is added, the next 5 photos will not have this chance. After these 5 photos, the remaining photos will once again have a 10% chance of being followed by a surprise.
*/

export function addSurprisesToPhotos(initialPhotos: Photos[]) {
    const copiedPhotos = initialPhotos?.map(photo => ({...photo}))
    let totalPhotos = copiedPhotos?.length
    let skipUntilIndex = -1 //Tracks the index until which the "chance" logic should be skipped

    for (let i = 2; i < totalPhotos; i++) {
        if (i <= skipUntilIndex) {
            continue // Skip until the skipUntilIndex is reached
        }

        const randomChance = Math.floor(Math.random() * 10)
        copiedPhotos[i].percent = randomChance
        if (randomChance === 0) {
            copiedPhotos[i].chance = true
            skipUntilIndex = i + 5

            const randomBlurredPhoto = blurredPhotos[Math.floor(Math.random() * blurredPhotos.length)]
            const blurredPhoto = {
                id: i,
                type: MediaTypeE.IMAGE,
                user_id: copiedPhotos[0].user_id,
                main: false,
                under_validation: false,
                url: randomBlurredPhoto,
                surprise: true
            }

            copiedPhotos.splice(i + 1, 0, blurredPhoto)

            totalPhotos++
            skipUntilIndex++
        }
    }

    return copiedPhotos
}
