import {ReactNode} from 'react'
import {StyledItemText, StyledItemTitle, StyledItemWrapper} from './style'

type ContentItem = {
    id: number
    icon: ReactNode
    title: string
    text: string
}

type UltimateStatusContentProps = {
    content: ContentItem
}

export const UltimateStatusContent = ({content}: UltimateStatusContentProps) => {
    return (
        <StyledItemWrapper>
            <div>{content.icon}</div>
            <div>
                <StyledItemTitle>{content.title}</StyledItemTitle>
                <StyledItemText>{content.text}</StyledItemText>
            </div>
        </StyledItemWrapper>
    )
}
