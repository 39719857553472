import React from 'react'
import {StyledItem, StyledWrapper} from './style'

export type FilterItem = {
    icon?: React.ReactNode
    label: string
    id?: number | string
}

export const SelectedFiltersList = ({
    allSelectedFilters,
    bottomPadding
}: {
    allSelectedFilters: FilterItem[]
    bottomPadding?: string
}) => {
    return (
        <StyledWrapper $bottomPadding={bottomPadding}>
            {allSelectedFilters?.map((filter, idx, arr) => {
                const uniqueKey = `${filter?.id}-${idx}`
                return (
                    <StyledItem key={uniqueKey}>
                        {filter?.icon}
                        {filter?.label}
                        {idx < arr.length - 1 && ', '}
                    </StyledItem>
                )
            })}
        </StyledWrapper>
    )
}
SelectedFiltersList.displayName = 'SelectedFiltersList'
