import {useTranslation} from 'react-i18next'
import {StyledTitle, StyledWrapper} from './style'
import {ItemMenu} from '../menuItemsList'

export const MenuItem = ({
    item,
    onClose,
    forSubMenu = false
}: {
    item: ItemMenu
    onClose: () => void
    forSubMenu?: boolean
}) => {
    const {t} = useTranslation()
    const {icon, title, to, titleValue, show, action} = item

    return show ? (
        to ? (
            <StyledWrapper
                to={to}
                $forSubMenu={forSubMenu}
                onClick={() => {
                    onClose()
                    action?.()
                }}
            >
                {!!icon && icon}
                <StyledTitle>
                    {t(title)} {!!titleValue && `(${titleValue})`}
                </StyledTitle>
            </StyledWrapper>
        ) : (
            <StyledWrapper
                $forSubMenu={forSubMenu}
                as="span"
                onClick={() => {
                    onClose()
                    action?.()
                }}
            >
                {!!icon && icon}
                <StyledTitle>
                    {t(title)} {!!titleValue && `(${titleValue})`}
                </StyledTitle>
            </StyledWrapper>
        )
    ) : null
}

MenuItem.displayName = 'MenuItem'
