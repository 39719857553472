import treasureAnimation from '@/assets/animations/Success.json'
import {Animation} from '@/components/ui/animation/Animation'
import {Button} from '@/components/ui/button/Button'
import {Divider} from '@/components/ui/divider/Divider'
import {Modal} from '@/components/ui/modal/Modal'
import {PLAY} from '@/utilities/constants/animation'
import {FC} from 'react'
import {useTranslation} from 'react-i18next'
import {useTheme} from 'styled-components'
import {
    StyledAnimation,
    StyledBuyMorgiSuccessStepContent,
    StyledBuyMorgiSuccessStepContentDescription,
    StyledBuyMorgiSuccessStepContentTitle,
    StyledBuyMorgiSuccessStepModalContainer
} from './style'

type BuyMorgiSuccessStepModalProps = {
    onClose: () => void
    amount: number
}

export const BuyMorgiSuccessStepModal: FC<BuyMorgiSuccessStepModalProps> = ({amount, onClose}) => {
    const {t} = useTranslation()
    const {palette, spacing} = useTheme()
    return (
        <Modal
            fullScreen
            onClose={onClose}
            width="100%"
            maxWidth="500px"
            height="668px"
            title={t('common:buy_morgi_package')}
        >
            <StyledBuyMorgiSuccessStepModalContainer>
                <StyledBuyMorgiSuccessStepContent>
                    <StyledAnimation>
                        <Animation animationData={treasureAnimation} animationState={PLAY} />
                    </StyledAnimation>
                    <StyledBuyMorgiSuccessStepContentTitle>
                        {t('buy_micromorgies:success:title')}
                    </StyledBuyMorgiSuccessStepContentTitle>
                    <StyledBuyMorgiSuccessStepContentDescription>
                        {t('buy_micromorgies:success:description', {AMOUNT: amount})}
                    </StyledBuyMorgiSuccessStepContentDescription>
                </StyledBuyMorgiSuccessStepContent>
                <div>
                    <Divider bottomSpacing={spacing * 6} background={palette.neutral[225]} />
                    <Button onClick={onClose}>{t('common:continue')}</Button>
                </div>
            </StyledBuyMorgiSuccessStepModalContainer>
        </Modal>
    )
}
