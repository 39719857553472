import React, {ChangeEvent, KeyboardEvent, forwardRef, useCallback, useEffect, useRef, useState} from 'react'
import ReactSelect, {GroupBase, MultiValue, OptionProps, SelectInstance, SingleValue} from 'react-select'
import CreatableSelect from 'react-select/creatable'
import {customStyles, StyledContainer, StyledLabelContainer} from './style'
import {useTheme} from 'styled-components'
import {Label} from '@components/ui/label/Label'
import {InputHelpText} from '@components/ui/input-help-text/InputHelpText'
import {customComponents} from '@components/commons/select/commons'
import {Option} from './components/option/Option'
import {LabelSelect} from './components/label/Label'
import {DropdownArrowIcon} from '@/assets/icons/icons'
import {GroupedOptionType, OptionType, SelectProps, SubSelect} from './types/types'
import {debounce} from 'lodash'
import {SaveButton} from './components/saveButton/SaveButton'
import {remapApiOption} from '@/utilities/helpers'
import {useClickOutside} from '@/hooks/useClickOutside'

const Select = forwardRef<SelectInstance<OptionType, boolean, GroupBase<OptionType>>, SelectProps>(
    (
        {
            name,
            options = [],
            label,
            icon,
            value,
            helpText,
            isMulti = false,
            disabled,
            maxItems = 5,
            touched = false,
            isCreatable = false,
            isSearchable = true,
            canBeSaved = true, //for show Save Button
            subSelect,
            subSelectPlaceholder, //placeholder for subSelect
            defaultValue,
            showCheckbox,
            closeAfterSelect = false,
            addOptionMessage,
            closeMenuOnSelect = !isMulti,
            errorMessage,
            maxItemsErrorMessage,
            requestAddNewOption, //for request to save
            onChange,
            onBlur,
            customLabelHandler,
            showSelectedOptions = true,
            menuPlacement = 'bottom',
            countable,
            maxLength,
            maxMenuHeight,
            ...rest
        }: SelectProps,
        ref
    ) => {
        const subRef = useRef<SelectInstance<OptionType, boolean, GroupBase<OptionType>>>(null)
        const containerRef = useRef(null)
        const theme = useTheme()
        const [currentSubInputId, setCurrentSubInputId] = useState<number | undefined>(undefined)
        const [selectedOptions, setSelectedOptions] = useState<OptionType[]>([])
        const [maxLimitError, setMaxLimitError] = useState(false)
        const [isLoading, setIsLoading] = useState<boolean>(false)
        const [searchTerm, setSearchTerm] = useState<string>('')
        const [isShowSaveButton, setIsShowSaveButton] = useState<boolean>(false)
        const maxLimitErrorMessage = maxLimitError && maxItemsErrorMessage
        // Label for new item creation
        const createLabel = useCallback(
            (name: string) => (
                <span>
                    {addOptionMessage}
                    <span>{name}</span>
                </span>
            ),
            [addOptionMessage]
        )

        useEffect(() => {
            if (defaultValue) {
                handleOnChange(defaultValue)
            }
        }, [])
        const removeSelectOption = (id: number) => {
            const newSelectedOptions = selectedOptions.filter(option => option.id !== id)
            setSelectedOptions(newSelectedOptions)
            handleOnChange(newSelectedOptions)
        }

        const debouncedSearchText = useCallback(
            debounce((searchText: string) => {
                if (!searchText) {
                    setIsShowSaveButton(false)
                    return null
                }
                const isHasGroups = Object.keys(options[0]).includes('options')
                const modifyOptions = (options: GroupedOptionType[] | OptionType[]) =>
                    isHasGroups
                        ? (options as GroupedOptionType[]).map(option => option.options).flat()
                        : (options as OptionType[])

                const isFindExistingOption =
                    0 <=
                    modifyOptions(options).findIndex(
                        (option: OptionType) => option.label.toLowerCase() === searchText.toLowerCase()
                    )

                setIsShowSaveButton(!isFindExistingOption)
            }, 500),
            []
        )

        useEffect(() => debouncedSearchText.cancel(), [])

        const findExistingOption = (searchText: string) => {
            debouncedSearchText.cancel()
            debouncedSearchText(searchText)
        }

        const handleAddNewOption = (e: React.MouseEvent<HTMLParagraphElement>) => {
            e.stopPropagation()
            e.preventDefault()
            if (requestAddNewOption) {
                setIsLoading(true)
                requestAddNewOption(searchTerm, {
                    onSuccess: data => {
                        setIsShowSaveButton(false)
                        setSearchTerm('')
                        setSelectedOptions(
                            isMulti
                                ? [...selectedOptions, remapApiOption(data as {id: number; name: string})]
                                : [remapApiOption(data as {id: number; name: string})]
                        )
                        onChange?.(
                            isMulti
                                ? [...selectedOptions, remapApiOption(data as {id: number; name: string})]
                                : [remapApiOption(data as {id: number; name: string})]
                        )
                    },
                    onSettled: () => {
                        setIsLoading(false)
                    }
                })
            }
        }

        const onSelectSubOption = (option: OptionType, subOption: SubSelect) => {
            const updatedSelectedOptions = selectedOptions.map(selectedOption =>
                selectedOption.id === option.id ? {...selectedOption, subOption} : selectedOption
            )
            onSelectWithSubOptions(updatedSelectedOptions)
        }

        const onSelectWithSubOptions = (selectedOptions: OptionType[]) => {
            setSelectedOptions(selectedOptions)
            onChange?.(selectedOptions)
        }

        const onSelectMultipleOptions = (selectedOptions: OptionType[]) => {
            const isWithinLimit = selectedOptions.length <= maxItems
            if (!isWithinLimit) {
                setMaxLimitError(true)
                return
            }
            setSelectedOptions(selectedOptions)
            onChange?.(selectedOptions)
        }

        const onSelectSingleOption = (selectedOption: OptionType) => {
            setSelectedOptions([selectedOption])
            onChange?.([selectedOption])
        }

        const handleOnChange = (selectedOption: MultiValue<OptionType> | SingleValue<OptionType>) => {
            const isArray = Array.isArray(selectedOption)
            if (isArray && subSelect) {
                onSelectWithSubOptions(selectedOption)
            } else if (isArray) {
                onSelectMultipleOptions(selectedOption)
            } else {
                onSelectSingleOption(selectedOption as OptionType)
            }
        }

        const onInputChange = (inputText: string, action: {action: string}) => {
            if (action.action === 'input-change') {
                if (!maxLength || (!!maxLength && inputText.length <= maxLength)) {
                    setSearchTerm(inputText)
                }
                findExistingOption(inputText)
            }
        }
        const selectProps = {
            openMenuOnFocus: !!currentSubInputId,
            menuIsOpen: currentSubInputId ? true : undefined, //for checking styles in options
            forwardRef: ref,
            ref: subRef,
            blurInputOnSelect: closeAfterSelect,
            maxMenuHeight: maxMenuHeight ?? 300,
            minMenuHeight: 250,
            options,
            subSelect,
            isShowSaveButton,
            onBlur: () => {
                if (maxLimitError) {
                    setMaxLimitError(!maxLimitError)
                }
                onBlur?.()
            },
            name,
            value: selectedOptions,
            onInputChange,
            inputValue: searchTerm,
            closeMenuOnSelect,
            isSearchable,
            isCreatable,
            isMulti,
            isDisabled: rest.readOnly || disabled,
            classNamePrefix: isCreatable ? 'creatable_select' : 'select',
            createLabel,
            disabled,
            maxItems,
            isClearable: false,
            hideSelectedOptions: false,
            controlShouldRenderValue: !isMulti,
            defaultValue,
            onChange: handleOnChange, //take key which you need in form
            onKeyDown: (event: ChangeEvent<HTMLInputElement> & KeyboardEvent) => {
                if (event.key === 'Backspace' && !event.target.value) {
                    event.preventDefault()
                }
            },
            menuPlacement,
            ...rest
        }

        useEffect(() => {
            if (value) setSelectedOptions(value)
        }, [value])

        const selectComponentProps = {
            formatCreateLabel: createLabel,
            components: {
                ...customComponents,
                Option: (innerProps: OptionProps<OptionType, boolean, GroupBase<OptionType>>) => {
                    const extraPayload = innerProps?.data?.extraPayload as {
                        Children?: React.ElementType<{
                            selectedOptions: OptionType[]
                            data: OptionType
                            currentSubInputId?: number
                            onBlur?: () => void
                        }>
                    }
                    const Children = extraPayload?.Children
                    return (
                        <div>
                            <Option
                                showCheckbox={!!showCheckbox}
                                isMulti={isMulti}
                                optionData={innerProps}
                                subSelect={subSelect}
                                subSelectPlaceholder={subSelectPlaceholder}
                                onSelectSubOption={onSelectSubOption}
                                selectedOptions={selectedOptions}
                            />
                            {Children ? (
                                <Children
                                    selectedOptions={selectedOptions}
                                    data={innerProps.data}
                                    currentSubInputId={currentSubInputId}
                                />
                            ) : null}
                        </div>
                    )
                },
                DropdownIndicator: () => {
                    return !isShowSaveButton && <DropdownArrowIcon width={8} height={4} />
                }
            },
            ...selectProps,
            styles: customStyles({
                theme,
                touched: touched,
                isShowSaveButton: isShowSaveButton,
                isError: !!errorMessage || maxLimitError
            })
        }

        useClickOutside({
            element: containerRef?.current,
            callback: () => {
                if (currentSubInputId) {
                    setCurrentSubInputId(undefined)
                }
            },
            condition: !!currentSubInputId && !!containerRef?.current,
            dependencies: [containerRef?.current, !!currentSubInputId]
        })

        return (
            <div
                onClick={e => {
                    const event = e.target as EventTarget & {autofocus: boolean; id?: string}
                    const id = event.id?.slice(String(event.id).lastIndexOf('-') + 1)
                    if (event?.id?.includes('input-sub-option') && id) setCurrentSubInputId(+id)
                    else setCurrentSubInputId(undefined)
                }}
            >
                {label && <Label text={label} />}
                {!!selectedOptions.length && showSelectedOptions && (
                    <StyledLabelContainer>
                        {isMulti &&
                            selectedOptions.map(selectedOption => (
                                <LabelSelect
                                    key={selectedOption.id}
                                    selectedOption={selectedOption}
                                    removeSelectOption={removeSelectOption}
                                    icon={icon}
                                    customLabelHandler={customLabelHandler}
                                />
                            ))}
                    </StyledLabelContainer>
                )}
                <div>
                    <InputHelpText error={errorMessage || maxLimitErrorMessage} />
                </div>
                <StyledContainer $subSelect={!!subSelect?.length} disabled={!!disabled} ref={containerRef}>
                    {isCreatable ? (
                        <CreatableSelect {...selectComponentProps} />
                    ) : (
                        <ReactSelect {...selectComponentProps} />
                    )}
                    <SaveButton
                        canBeSaved={canBeSaved}
                        isShowSaveButton={isShowSaveButton}
                        isLoading={isLoading}
                        countable={countable}
                        maxLength={maxLength}
                        handleAddNewOption={handleAddNewOption}
                        searchTerm={searchTerm}
                        subSelect={!!subSelect?.length}
                    />
                </StyledContainer>
                <InputHelpText helpText={helpText} />
            </div>
        )
    }
)

Select.displayName = 'CustomSelect'

export default Select
