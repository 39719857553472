import styled, {css} from 'styled-components'

export const RangeInputWrapper = styled.div`
    ${({theme: {spacing}}) => css`
        display: flex;
        align-items: center;
        gap: ${spacing}px;
    `}
`

export const StyledInput = styled.input`
    ${({theme: {palette, spacing}}) => css`
        background-color: ${palette.neutral.white};
        padding: ${spacing}px;
        border-radius: 4px;
        border: 1px solid ${palette.neutral[400]};
    `}
`

export const MultiInputWrapper = styled.div`
    ${({theme: {palette, spacing}}) => css`
        display: flex;
        min-height: 40px;
        min-width: 160px;
        flex-wrap: wrap;
        gap: ${spacing}px;
        padding: ${spacing}px;
        background-color: ${palette.neutral.white};
        border-radius: 4px;

        .dateChip {
            padding: ${spacing}px;
            display: grid;
            place-items: center;
            border: 1px solid ${palette.neutral['400']};
            border-radius: 4px;
        }
    `}
`
export const CalendarWrapper = styled.div`
    ${({theme: {spacing}}) => css`
        display: flex;
        flex-direction: column;
        margin-top: ${spacing * 2}px;
    `}
`

export const DateInputStyledProps = (isHasError: boolean) => css`
    ${!isHasError &&
    css`
        flex-direction: row-reverse;
    `}
    & span {
        position: inherit;
        transform: none;
        right: 0;
    }
`
export const DatePickerWrapper = styled.div`
    position: relative;
    & div[data-radix-popper-content-wrapper] {
        position: absolute !important;
        top: 85px !important;
        transform: translate(0, 0) !important;
    }
`
