import {Lens} from '@dhmk/zustand-lens'

type HostProfileModalState = {
    isOpen: boolean
    hostUsername: string | undefined
}

type HostProfileModalActions = {
    openModal: (params: Omit<HostProfileModalState, 'isOpen'>) => void
    closeModal: () => void
}

export type HostProfileModal = HostProfileModalState & HostProfileModalActions

const initialState: HostProfileModalState = {isOpen: false, hostUsername: undefined}

const createHostProfileModalSlice: Lens<HostProfileModal> = set => ({
    ...initialState,
    openModal: params => set(() => ({...params, isOpen: true})),
    closeModal: () => set(() => ({...initialState}))
})

export default createHostProfileModalSlice
