import {InfoCircleIcon, MicromorgiIcon} from '@/assets/icons/icons'
import {useRootStore} from '@/store'
import {selectSendMegaGiftModal} from '@/store/selectors'
import megaGiftSmall from '@assets/images/megaGiftSmall.png'
import {FC} from 'react'
import {useTranslation} from 'react-i18next'
import {
    MegaGiftSectionContainer,
    MegaGiftSectionDescription,
    MegaGiftSectionDescriptionCost,
    MegaGiftSectionImage,
    MegaGiftSectionSendButton,
    StyledPopoverContent
} from './style'
import {MEGA_GIFT} from '@/featuresConfig'
import {Flexbox} from '@/components/ui/flexbox/FlexBox'
import {Popover} from '@/components/ui/popover/Popover'

type MegaGiftSection = {
    hostID: string
    onOpenModal?: () => void
    onSuccessSendGift?: () => void
}

export const MegaGiftSection: FC<MegaGiftSection> = ({hostID, onOpenModal, onSuccessSendGift}) => {
    const {t} = useTranslation()
    const {openModal: openSendMegaGiftModal} = useRootStore(selectSendMegaGiftModal)
    const onOpenModalHandler = () => {
        openSendMegaGiftModal({hostID, onSuccessSendGift})
        onOpenModal?.()
    }
    return (
        <MegaGiftSectionContainer>
            <MegaGiftSectionImage src={megaGiftSmall} />
            <MegaGiftSectionDescription>
                <Flexbox gap={0.5}>
                    <p>{t('monthly_support_mega_gift:title')}</p>
                    <Popover trigger={<InfoCircleIcon />} isModal withArrow={true}>
                        <StyledPopoverContent>
                            <h4>{t('sent_mega_gift_modal:subtitle')}</h4>
                            <p>{t('sent_mega_gift_modal:tooltip_description')}</p>
                        </StyledPopoverContent>
                    </Popover>
                </Flexbox>
                <MegaGiftSectionDescriptionCost>
                    <MicromorgiIcon height={14} width={14} />
                    {MEGA_GIFT.cost}
                </MegaGiftSectionDescriptionCost>
            </MegaGiftSectionDescription>
            <MegaGiftSectionSendButton variant="primaryOutlined" onClick={onOpenModalHandler}>
                {t('sent_mega_gift_modal:cta_text')}
            </MegaGiftSectionSendButton>
        </MegaGiftSectionContainer>
    )
}
