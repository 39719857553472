import dayjs from '@/dayjs.ts'
import {DateSelector} from '@components/commons/calendar/DateSelector.tsx'
import {CalendarsWrapper, StyledContainer} from '@components/commons/calendar/style.ts'
import {useDatePicker} from '@rehookify/datepicker'
import {FC, useEffect, useState} from 'react'
import {DefaultTheme, FlattenInterpolation, ThemeProps} from 'styled-components'

export type CalendarProps = {
    selectedDates: Date[]
    onDatesChange: (dates: Date[]) => void
    mode: 'single' | 'multiple' | 'range'
    toggle?: boolean
    minDate?: Date
    maxDate?: Date
    locale?: string
    focusDate: Date | null
    datePickerRangeStyles?: FlattenInterpolation<ThemeProps<DefaultTheme>>
}

export const Calendar: FC<CalendarProps> = ({
    selectedDates,
    onDatesChange,
    mode,
    toggle = false,
    minDate,
    maxDate,
    locale = 'en',
    focusDate,
    datePickerRangeStyles
}) => {
    const [isShowCalendar, setIsShowCalendar] = useState(mode === 'single')
    const duration = (dayjs(maxDate).year() - dayjs(minDate).year()) * 2

    const {
        data: {weekDays, calendars, months, years},
        propGetters: {dayButton},
        actions: {setMonth, setYear, setNextMonth, setPreviousMonth}
    } = useDatePicker({
        calendar: {mode: 'fluid'},
        ...(maxDate && minDate && duration > 0 ? {years: {numberOfYears: duration, mode: 'fluid'}} : {}),
        locale: {locale},
        selectedDates,
        onDatesChange,
        dates: {toggle, mode, minDate, maxDate}
    })
    const CHANGES_DATE_FUNC_MAP = {
        month: setMonth,
        year: setYear
    }

    useEffect(() => {
        if (mode !== 'single' && focusDate) {
            setYear(focusDate)
            setMonth(focusDate)
        }
        setIsShowCalendar(true)
    }, [focusDate])

    if (!isShowCalendar) return <></>
    return (
        <StyledContainer>
            <CalendarsWrapper>
                {calendars.map((calendar, index) => (
                    <DateSelector
                        mode={mode}
                        changeDate={(date, key) => {
                            CHANGES_DATE_FUNC_MAP[key]?.(date)
                        }}
                        locale={locale}
                        key={`${calendar.month}-${calendar.year}-${index}`}
                        calendar={calendar}
                        dayButton={dayButton}
                        weekDays={weekDays}
                        months={months}
                        years={years}
                        onNextMonth={setPreviousMonth}
                        onPrevMonth={setNextMonth}
                        datePickerRangeStyles={datePickerRangeStyles}
                    />
                ))}
            </CalendarsWrapper>
        </StyledContainer>
    )
}
