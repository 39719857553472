import {BadWordsResponseType, WordObject} from '../types'

export const getBadWordsListFromString = (
    text: string | undefined,
    bannedWordsList: BadWordsResponseType | undefined
): WordObject[] => {
    let badWordsArray: string[] = []

    const textToCheck = text?.toLowerCase()
    const bannedWordsListStringList = bannedWordsList?.map(({name}) => name)
    bannedWordsListStringList?.forEach(word => {
        const partBadWordsArray = textToCheck?.match(
            new RegExp(`(^|[^_0-9a-zA-Zа-яёА-ЯЁ])${word}([^_0-9a-zA-Zа-яёА-ЯЁ]|$)`, 'g')
        )
        if (partBadWordsArray && partBadWordsArray.length) {
            badWordsArray = [...partBadWordsArray, ...badWordsArray]
        }
    })

    badWordsArray = [...new Set(badWordsArray)]

    const wordsArrayWithObjects: WordObject[] = badWordsArray.map(word => {
        const value = word.trim()
        return {
            string: value,
            host: value,
            url: value,
            type: 'word'
        }
    })

    return wordsArrayWithObjects
}
