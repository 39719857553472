import {useQuery} from '@tanstack/react-query'
import {GOAL_API} from '../services/goal.http'
import {QUERY_KEYS} from './keys'

export const useFetchSupporters = (goalID: string | number) => {
    return useQuery({
        queryKey: [QUERY_KEYS.supporters_list, goalID],
        queryFn: () => GOAL_API.getSupporters(`${goalID}`)
    })
}
