// Add constant here for existing combinations

import {FC} from 'react'
import GoalPromoCombination from './GoalPromoCombination'
import {MoengageObjectResponseType} from '../../types'
import PackagePromoCombination from './PackagePromoCombination'
import HostProfileCombination from './HostProfileCombination'

// This must be agreed with backend
export enum CombinationNames {
    // popup variants
    host_profile__popup = 'host_profile__popup',
    package_promo__popup = 'package_promo__popup',
    goal_promo__popup = 'goal_promo__popup',
    // card variants
    host_profile__card = 'host_profile__card',
    package_promo__card = 'package_promo__card'
}

export const PROMO_COLORS = {
    discount: '#68EC92',
    cashback: '#FFEA7C',
    free_morgis: '#D31559'
}

export const CARD_GRADIENTS = {
    discount: 'linear-gradient(187deg, #4E8B43 -2.99%, #68EC92 29.81%, #FFF 80.77%)',
    cashback: 'linear-gradient(187deg, #EF3F0F -0.47%, #FFDA48 52.76%, #FFF 80.77%)',
    free_morgis: 'linear-gradient(162deg, #E32EB0 12.03%, #4CE9FF 94.85%)'
}

export const PROMO_LABEL_GRADIENTS = {
    discount: 'linear-gradient(180deg, #68EC92 0%, #349152 100%)',
    cashback: 'linear-gradient(0deg, #FC9F21 0%, #FFDA48 38.54%, #FFE2BA 100%)',
    free_morgis: 'linear-gradient(162deg, #E32EB0 12.03%, #4CE9FF 94.85%)'
}

export const PROMO_LABEL_NAMES = {
    discount: 'Offer',
    cashback: 'Cashback',
    free_morgis: 'Free morgis'
}

export const PROMO_TYPES = {
    discount: 'discount',
    cashback: 'cashback',
    free_morgis: 'free_morgis'
}

export const combinationList: {[key: string]: FC<{data: MoengageObjectResponseType; closeHandler: () => void}> | null} =
    {
        [CombinationNames.host_profile__popup]: HostProfileCombination,
        [CombinationNames.package_promo__popup]: PackagePromoCombination,
        [CombinationNames.goal_promo__popup]: GoalPromoCombination,
        [CombinationNames.host_profile__card]: HostProfileCombination,
        [CombinationNames.package_promo__card]: PackagePromoCombination
    }
