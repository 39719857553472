import {CloseIcon} from '@/assets/icons/icons'
import styled, {css} from 'styled-components'

export const StyledTooltipContent = styled.div`
    ${({theme: {spacing, palette, typography, shadows}}) => css`
        position: relative;
        max-width: 320px;
        min-height: 230px;
        ${typography.textSm}
        padding: ${spacing * 2}px ${spacing * 3}px;
        border-radius: 10px;
        box-shadow: ${shadows['8xl']};
        color: ${palette.neutral.black};
        display: grid;
        justify-content: space-between;
        & p {
            text-align: center;
            font-weight: 700;
        }
        & img {
            width: 84px;
            height: 90px;
            margin: 0 auto;
        }
    `}
`
export const StyledCloseIcon = styled(CloseIcon)`
    ${({theme: {spacing}}) => css`
        cursor: pointer;
        position: absolute;
        top: ${spacing * 2}px;
        right: ${spacing * 2}px;
        width: 14px;
        height: 14px;
    `}
`
