import {FC} from 'react'
import {FeedVariantE} from '../../constants'
import {GoalFilters} from '@/features/goal/components/goal-filters/GoalFilters'
import {HostFilters} from '@/features/guest/components/host-filters/HostFilters'

type FeedFiltersProps = {
    variant: FeedVariantE
    isFilterVisible: boolean
    onCloseFilters: () => void
}

export const FeedFilters: FC<FeedFiltersProps> = ({variant, isFilterVisible, onCloseFilters}) => {
    switch (variant) {
        case FeedVariantE.goals:
            return <GoalFilters isOpen={isFilterVisible} onClose={onCloseFilters} />
        default:
            return <HostFilters isOpen={isFilterVisible} onClose={onCloseFilters} />
    }
}
