import styled, {css} from 'styled-components'
import {Flexbox} from '@components/ui/flexbox/FlexBox.tsx'

type StyledInputWrapperProps = {
    hasError: boolean
    touched: boolean
    visibilityToggle?: boolean
}

export const StyledInputWrapper = styled(Flexbox)<StyledInputWrapperProps>`
    ${({hasError, touched, visibilityToggle = false, theme: {palette, typography, spacing}}) => css`
        ${typography.textMd}
        border: 1px solid ${palette.neutral[400]};
        border-radius: 4px;
        box-sizing: border-box;
        position: relative;
        color: ${palette.primary[900]};
        background-color: ${palette.neutral.white};
        padding: ${spacing * 2}px;
        padding-right: ${(touched || visibilityToggle) && !(touched && visibilityToggle)
            ? `${spacing * 6}px`
            : `${spacing * 2}px`};
        outline: none;
        width: 100%;
        &:has(input:focus) {
            box-shadow: 0 0 0 2px ${palette.primary[400]};
            border-color: ${palette.primary[200]};
        }

        &:has(input:disabled) {
            background-color: ${palette.neutral[950]};
            color: ${palette.neutral[25]};
            cursor: default;
            border: none;
        }

        ${hasError &&
        css`
            border-color: ${palette.danger[25]};
            &:has(input:focus) {
                box-shadow: 0 0 0 2px ${palette.warning[400]};
                border-color: ${palette.danger[25]};
            }
        `};

        ${touched && visibilityToggle
            ? css`
                  padding-right: ${spacing * 8.25}px;
              `
            : ''};
    `}
`
