import axios from '@/clientAPI.ts'
import {
    CitiesResponseType,
    CountriesResponseType,
    CountriesWithLanguageResponseType,
    LanguagesResponseType,
    RegionsResponseType
} from '../types'
import {
    CitiesResponseSchema,
    CountriesResponseSchema,
    CountriesWithLanguageResponseSchema,
    LanguagesResponseSchema,
    RegionsResponseSchema
} from './country.schema'

export const COUNTRY_API = {
    getCountries: async (): Promise<CountriesResponseType> => {
        const url = `/countries`
        const {data} = await axios.get(url)
        return CountriesResponseSchema.parse(data)
    },
    getCountriesWithLanguage: async (): Promise<CountriesWithLanguageResponseType> => {
        const params = {
            response_type: 'extended'
        }
        const url = `/countries`
        const {data} = await axios.get(url, {params})
        return CountriesWithLanguageResponseSchema.parse(data)
    },
    getRegions: async ({countryId}: {countryId: number | string}): Promise<RegionsResponseType> => {
        const params = {
            country_id: countryId,
            response_type: 'extended'
        }
        const url = `/regions`
        const {data} = await axios.get(url, {params})
        return RegionsResponseSchema.parse(data)
    },
    getCities: async ({countryId}: {countryId: number | string}): Promise<CitiesResponseType> => {
        const params = {
            country_id: countryId,
            response_type: 'extended'
        }
        const url = `/cities`
        const {data} = await axios.get(url, {params})
        return CitiesResponseSchema.parse(data)
    },
    getFilterCountries: async (): Promise<CountriesWithLanguageResponseType> => {
        const params = {
            response_type: 'extended'
        }
        const url = `/countries/filters`
        const {data} = await axios.get(url, {params})
        return CountriesWithLanguageResponseSchema.parse(data)
    },
    getLanguages: async ({is_translatable}: {is_translatable: boolean}): Promise<LanguagesResponseType> => {
        const url = `/languages`
        const {data} = await axios.get(url, {
            params: {is_translatable}
        })
        return LanguagesResponseSchema.parse(data)
    },
    getFilterLanguages: async (): Promise<LanguagesResponseType> => {
        const params = {
            response_type: 'extended'
        }
        const url = `/languages/filters`
        const {data} = await axios.get(url, {params})
        return LanguagesResponseSchema.parse(data)
    }
}
