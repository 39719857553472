import {useMutation, useQueryClient} from '@tanstack/react-query'
import {Message} from '@/features/common/types'
import {MICROMORGI_API} from '../services/micromorgi.http'
import {MUTATION_KEYS} from './keys'
import {PaymentError} from '@/features/guest-payments/types'
import {PaymentUrls} from '../types'
import {QUERY_KEYS as AUTHENTICATION_QUERY_KEYS} from '@/features/authentication/queries/keys'

type useSendRequestToGetUltimateStatusProps = {
    onSuccess: () => void
}

export const useSendRequestToGetUltimateStatus = ({onSuccess}: useSendRequestToGetUltimateStatusProps) => {
    const queryClient = useQueryClient()

    return useMutation<PaymentUrls | Message, PaymentError>({
        mutationKey: [MUTATION_KEYS.send_request_to_get_ultimate_status],
        mutationFn: MICROMORGI_API.sendRequestToGetUltimateStatus,
        onSuccess: () => {
            onSuccess()
            queryClient.invalidateQueries({queryKey: [AUTHENTICATION_QUERY_KEYS.user]})
        }
    })
}
