import axios from '@/clientAPI.ts'
import {HostPartialSchema} from '@/features/authentication/services/auth.schema'
import {HostPartialType, HostType} from '@/features/authentication/types'
import * as types from '../types'
import {SavedHostResponseSchema} from './guest.schema'

export const GUEST_API = {
    getHost: async ({filter, isUsername}: {filter: string | number; isUsername?: boolean}): Promise<HostType> => {
        const url = `/rookies/${filter}`
        const {data} = await axios.get(url, {params: {is_username: isUsername}})
        return data
    },
    saveHost: async ({hostId}: {hostId: number}): Promise<HostPartialType> => {
        const url = `/rookies/${hostId}/save`
        const {data} = await axios.post(url)
        return HostPartialSchema.parse(data)
    },
    unsaveHost: async ({hostId}: {hostId: number}): Promise<HostPartialType> => {
        const url = `/rookies/${hostId}/save`
        const {data} = await axios.delete(url)
        return HostPartialSchema.parse(data)
    },
    getSavedHosts: async (): Promise<HostPartialType[]> => {
        const url = `/rookies/saved`
        const {data} = await axios.get(url)
        return SavedHostResponseSchema.parse(data)
    },
    getSubscriptionsToRenew: async (): Promise<types.Subscriptions> => {
        const {data} = await axios.get(`/subscriptions/renew`)
        return data
    },
    getSubscriptionsToRenewCreditCard: async (): Promise<types.SubscriptionsToRenewCreditCard> => {
        const {data} = await axios.get(`/subscriptions/renew/credit-cards`)
        return data
    },
    renewSubscription: async (body: types.SubscriptionsUpdate) => {
        const {data} = await axios.post(`/subscriptions/renew`, body)
        return data
    },
    getMinMaxAge: async (): Promise<types.AgesType> => {
        const {data} = await axios.get(`/filters/ages`)
        return data
    }
}
