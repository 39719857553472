import {Spinner} from '@/components/ui/spinner/Spinner'
import {useTranslation} from 'react-i18next'
import {StyledSaveButton, StyledSaveButtonContainer} from './style'

type SaveButtonProps = {
    canBeSaved?: boolean
    isShowSaveButton: boolean
    subSelect?: boolean
    isLoading: boolean
    countable?: boolean
    maxLength?: number
    handleAddNewOption: (e: React.MouseEvent<HTMLParagraphElement>) => void
    searchTerm: string
}

export const SaveButton = ({
    canBeSaved,
    isShowSaveButton,
    subSelect,
    isLoading,
    countable,
    maxLength,
    handleAddNewOption,
    searchTerm
}: SaveButtonProps) => {
    const {t} = useTranslation()
    return (
        <>
            {canBeSaved && isShowSaveButton && !subSelect && (
                <StyledSaveButtonContainer>
                    {isLoading && <Spinner inline size={8} />}
                    <StyledSaveButton onClick={handleAddNewOption}>
                        {countable && maxLength && <span>{`${searchTerm.length}/${maxLength}`}</span>}{' '}
                        {t('signup:save_new_option')}
                    </StyledSaveButton>
                </StyledSaveButtonContainer>
            )}
        </>
    )
}
