import {FC, ReactNode, createContext, useContext, useState} from 'react'

type CustomSelectContextType = {
    openSelect: string[]
    setOpenSelect: (name: string[]) => void
}

const CustomSelectContext = createContext<CustomSelectContextType | undefined>(undefined)

export const CustomSelectProvider: FC<{children: ReactNode}> = ({children}) => {
    const [openSelect, setOpenSelect] = useState<string[]>([])

    return <CustomSelectContext.Provider value={{openSelect, setOpenSelect}}>{children}</CustomSelectContext.Provider>
}

export const useCustomSelect = () => {
    const context = useContext(CustomSelectContext)
    if (!context) {
        throw new Error('useCustomSelect must be used within a CustomSelectProvider')
    }
    return context
}
