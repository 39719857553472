import {useQuery} from '@tanstack/react-query'
import {QUERY_KEYS} from './keys'
import {FEED_API} from '../services/feed.http'

export const useGetActiveHost = () => {
    return useQuery({
        queryKey: [QUERY_KEYS.active_host],
        queryFn: () => FEED_API.getActiveHost()
    })
}
