import {Modal} from '@/components/ui/modal/Modal'
import {ChangeEvent, useEffect, useRef, useState} from 'react'
import {StyledClearIcon, StyledGif, StyledGifsRoot, StyledTitleForEmpty, inputStyles, modalStyles} from './style'
import {Media} from '@/components/ui/media/Media'
import {Button} from '@/components/ui/button/Button'
import {Divider} from '@/components/ui/divider/Divider'
import {InputText} from '@/components/commons/input-text/InputText'
import {CloseIcon, SearchIcon} from '@/assets/icons/icons'
import {useTranslation} from 'react-i18next'
import {capitalize} from '@/utilities/helpers'
import {useFetchSearchGifs} from '../../queries/useGetSearchGifs'
import {useFetchTrendingGifs} from '../../queries/useGetTrendingGifs'
import useInfinityLoading from '@/hooks/useInfinityLoading'
import {Spinner} from '@/components/ui/spinner/Spinner'
import {GifType} from '../../types'
import {useRootStore} from '@/store'
import {selectGifModal, selectSendGifModal} from '@/store/selectors'

const GifsModal = () => {
    const {closeModal} = useRootStore(selectGifModal)
    const {openModal: openSendGifModal} = useRootStore(selectSendGifModal)

    const {t} = useTranslation()
    const [searchString, setSearchString] = useState('')
    const [isTyping, setIsTyping] = useState(false)
    const LIMIT = 10
    const timeoutIdRef = useRef<NodeJS.Timeout | null>(null)
    const rootGifsRef = useRef<HTMLDivElement>(null)
    const [shouldScrollToTop, setShouldScrollToTop] = useState(false)
    // Fetch trending gifs
    const {
        data: trendingData,
        fetchNextPage: fetchNextTrendingPage,
        hasNextPage: trendingHasNextPage,
        isFetchingNextPage: trendingIsFetchingNextPage,
        isLoading: loadTrending
    } = useFetchTrendingGifs({limit: LIMIT})

    // Fetch search gifs based on searchString
    const {
        data: searchData,
        fetchNextPage: fetchNextSearchPage,
        hasNextPage: searchHasNextPage,
        isFetchingNextPage: searchIsFetchingNextPage,
        isLoading: loadSearch
    } = useFetchSearchGifs({limit: LIMIT, q: searchString, enabled: !!searchString && !isTyping})

    const gifs = searchString
        ? searchData?.pages.flatMap(page => page.data)
        : trendingData?.pages.flatMap(page => page.data) || []

    const hasNextPage = searchString ? searchHasNextPage : trendingHasNextPage
    const isFetchingNextPage = searchString ? searchIsFetchingNextPage : trendingIsFetchingNextPage
    const isLoading = loadSearch || loadTrending

    const [endRef] = useInfinityLoading({
        fetchFunction: searchString ? fetchNextSearchPage : fetchNextTrendingPage,
        dependencies: searchString ? [searchHasNextPage, searchString] : [trendingHasNextPage, searchString]
    })

    const handleSearchGifs = (e: ChangeEvent<HTMLInputElement>) => {
        const inputText = e.target.value
        // Update the search string immediately for quick typing
        setSearchString(inputText)

        // Set typing flag to true when user starts typing
        setIsTyping(true)

        // Clear the previous timeout
        if (timeoutIdRef.current) {
            clearTimeout(timeoutIdRef.current)
        }

        // Set a new timeout to wait for the user to stop typing
        timeoutIdRef.current = setTimeout(async () => {
            // Set typing flag to false after the debounce time (500ms)
            await setIsTyping(false)
        }, 500)
    }

    useEffect(() => {
        if (shouldScrollToTop && rootGifsRef.current) {
            rootGifsRef.current.scrollTop = 0
            setShouldScrollToTop(false)
        }
    }, [shouldScrollToTop])

    const handleClearSearchInput = () => {
        setSearchString('')
        setShouldScrollToTop(true)
    }

    const handleChooseGif = (gif: GifType) => {
        openSendGifModal({gif})
        closeModal()
    }

    return (
        <div>
            <Modal
                modalStyles={modalStyles}
                maxHeight="600px"
                title={t('gifs_and_stickers:send_gifs')}
                onClose={closeModal}
            >
                <InputText
                    name="search"
                    placeholder={capitalize(t('common:search'))}
                    inputStyles={inputStyles}
                    value={searchString}
                    onChange={e => handleSearchGifs(e)}
                    icon={
                        searchString ? (
                            <StyledClearIcon onClick={handleClearSearchInput}>
                                <CloseIcon />
                            </StyledClearIcon>
                        ) : (
                            <SearchIcon />
                        )
                    }
                    autoComplete="off"
                />
                {isLoading && <Spinner />}
                <>
                    <StyledGifsRoot ref={rootGifsRef}>
                        {gifs?.length ? (
                            gifs?.map((gif, idx) => {
                                return (
                                    <StyledGif key={`${gif?.id}-${idx}`} onClick={() => handleChooseGif(gif)}>
                                        <Media radius={10} imageUrl={gif.images.fixed_height.url} />
                                    </StyledGif>
                                )
                            })
                        ) : (
                            <StyledTitleForEmpty>{t('gifs_and_stickers:nothing_found')}</StyledTitleForEmpty>
                        )}
                        {hasNextPage && <div ref={endRef} id="ref" />}
                    </StyledGifsRoot>
                    {isFetchingNextPage && <Spinner inline overlay={false} size={30} />}
                </>

                <Divider />
                <Button onClick={closeModal}>{t('create_goal:button_cancel')}</Button>
            </Modal>
        </div>
    )
}

export default GifsModal
