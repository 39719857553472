import styled, {css} from 'styled-components'

export const StyledGiftListWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    height: 100%;
    text-align: center;
`
export const StyledGift = styled.div`
    ${({theme: {palette}}) => css`
        width: 100%;
        height: 100%;
        display: grid;
        align-content: center;

        &:hover {
            background-color: ${palette.primary[300]};
            z-index: 1;
            cursor: pointer;
        }
        &[aria-disabled='true'] {
            opacity: 0.5;
            cursor: not-allowed;
            pointer-events: none;
            cursor: 'not-allowed';
        }
    `}
`

export const StyledGiftContent = styled.div`
    ${() => css`
        border-right: 1px solid rgb(221, 221, 221);
        display: flex;
        align-items: center;
        flex-direction: column;
        gap: 4px;
        position: relative;
    `}
`
export const StyledAmount = styled.div`
    ${({theme: {typography}}) => css`
        ${typography.textSm};
        font-weight: 600;
        display: flex;
        align-items: center;
    `}
`
