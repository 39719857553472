import {Trans, useTranslation} from 'react-i18next'
import {StyledBalanceBox, StyledStatus, StyledStatusBox} from '../style'
import {MicromorgiIcon} from '@/assets/icons/icons'
import {Status, labelBackgroundColor} from '../StatusBadge'
import {replaceThousands} from '@/utilities/helpers'

type StatusBadgeTriggerProps = {
    balance?: number
    guestStatus: string
    nextDollarAmount?: number
    nextLevelAmount?: number
    isLight?: boolean
}

export const StatusBadgeTrigger = ({balance, guestStatus, isLight}: StatusBadgeTriggerProps) => {
    const {t} = useTranslation()
    const background = labelBackgroundColor[guestStatus as Status]
    return (
        <StyledStatusBox>
            <StyledStatus backgroundColor={background}>
                <Trans
                    t={t}
                    i18nKey="leader_statuses:status"
                    values={{
                        context: guestStatus
                    }}
                />
            </StyledStatus>
            <StyledBalanceBox isLight={!!isLight}>
                <MicromorgiIcon />
                {balance && replaceThousands(balance)}
            </StyledBalanceBox>
        </StyledStatusBox>
    )
}
