import styled, {css} from 'styled-components'

export const StyledBody = styled.div`
    ${({theme: {spacing}}) => css`
        width: 100%;
        height: 100%;
        padding: ${spacing * 3}px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        gap: ${spacing * 3}px;
        overflow-y: hidden;
        min-height: 417px;
    `}
`

export const StyledAnimation = styled.div`
    ${({theme: {spacing}}) => css`
        margin: ${spacing * 1.5}px auto ${spacing * 6}px;
        height: 100%;
        width: 100%;
        max-height: 110px;
        max-width: 110px;
    `}
`

export const StyledText = styled.div`
    ${({theme: {typography, palette}}) => css`
        text-align: center;
        & span {
            ${typography.textXXl}
        }

        & p {
            color: ${palette.primary['700']};
        }
    `}
`

export const StyledButton = styled.div`
    width: 100%;
    & button {
        width: 100%;
    }
`
