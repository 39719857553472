import {LocalStorageManager} from '@/utilities/localStorage'
import {useEffect} from 'react'
import {useSearchParams} from 'react-router-dom'

export const useUtmParams = () => {
    const [searchParams] = useSearchParams()
    const utmParams: {[key: string]: string} = {}
    const existingUtmParams = LocalStorageManager.utmParams.get()
    searchParams.forEach((value, key) => {
        if (key.startsWith('utm_')) {
            utmParams[key] = value
        }
    })

    useEffect(() => {
        if (Object.keys(utmParams).length) {
            LocalStorageManager.utmParams.set({...existingUtmParams, ...utmParams})
        }
    }, [])

    return utmParams
}
