import {BackIcon} from '@/assets/icons/icons'
import {SidePopover} from '@/components/ui/side-popover/SidePopover'
import {useTranslation} from 'react-i18next'
import {
    StyledButtonSection,
    StyledClearAll,
    StyledContent,
    StyledHeader,
    StyledTitleWrapper,
    StyledWrapper
} from './style'
import {Button} from '@/components/ui/button/Button'
import {useTheme} from 'styled-components'
import useContainerDimensions from '@/hooks/useContainerDimensions'
import {FiltersTabs} from '@/components/ui/filters-tabs/FiltersTabs'
import {ChatTopicsFilter} from './chat-topics-filter/ChatTopicsFilter'
import {HostFiltersTabIdsE} from '../../types'
import {
    GENDER_ALL_OPTION_KEY_NAME,
    HOST_FEED_FILTER_TABS,
    MAX_AGE_TO_SELECT,
    MIN_AGE_TO_SELECT
} from '../../constants/hostFilters'
import {ActivitiesFilter} from './activities-filter/ActivitiesFilter'
import {CountryAndLanguageFilter} from './country-and-language-filter/CountryAndLanguageFilter'
import {SpecialHostCategoryFilter} from './special-host-category-filter/SpecialHostCategoryFilter'
import {AgeAndGenderFilter} from './age-and-gender-filter/AgeAndGenderFilter'
import {Spinner} from '@/components/ui/spinner/Spinner'
import {useMe} from '@/features/authentication/queries/useMe'
import {GuestType} from '@/features/authentication/types'
import {HostFiltersType} from '@/features/feed/types'
import {useEffect, useMemo, useState} from 'react'
import {useStoreActiveFilters} from '@/features/feed/queries/useStoreActiveFilters'
import {GenderKeyNameE} from '@/features/constants/constants'
import {useHandleError} from '@/hooks/useHandleError'
import {useClearActiveFilters} from '@/features/feed/queries/useClearActiveFilters'
import {useGetMinMaxAge} from '../../queries/useGetMinMaxAge'
import {OptionType} from '@/components/commons/singleSelect/types'
import useMixPanel from '@/integrations/mixpanel/hooks/useMixpanel'
import {MixpanelEventNameE} from '@/integrations/mixpanel/types'
import useFeedStore from '@/features/feed/store'

type HostFiltersProps = {
    isOpen: boolean
    onClose: () => void
}

export const HostFilters = ({isOpen, onClose}: HostFiltersProps) => {
    const {t} = useTranslation()
    const {sizing} = useTheme()
    const {height: vh} = useContainerDimensions()
    const {palette} = useTheme()
    const mixpanel = useMixPanel()
    const {data: user} = useMe()
    const guest = user as GuestType
    const guestHasActiveFilters = !!guest?.type_attributes.leaders_carousels_filters
    const currentFilters = guest?.type_attributes.leaders_carousels_filters?.filters
    const setIsFetchingWithHostFilters = useFeedStore(state => state.setIsFetchingWithHostFilters)

    const [chatTopics, setChatTopics] = useState<HostFiltersType['chatTopics']>([])
    const [activities, setActivities] = useState<HostFiltersType['activities']>([])
    const [countries, setCountries] = useState<HostFiltersType['countries']>([])
    const [languages, setLanguages] = useState<HostFiltersType['languages']>([])
    const [genders, setGenders] = useState<HostFiltersType['genders']>([])
    const [fromAge, setFromAge] = useState<number>(1)
    const [toAge, setToAge] = useState<number>(99)
    const [specialHostCategory, setSpecialHostCategory] = useState<OptionType[]>([])

    const {
        data: ageRange,
        isLoading: isAgeRangeLoading,
        isError: isAgeRangeError,
        error: ageRangeError
    } = useGetMinMaxAge()

    const fromDefaultAge = ageRange?.min_age || MIN_AGE_TO_SELECT
    const toDefaultAge = ageRange?.max_age || MAX_AGE_TO_SELECT

    useEffect(() => {
        fromDefaultAge && setFromAge(currentFilters?.fromAge ?? fromDefaultAge)
        toDefaultAge && setToAge(currentFilters?.toAge ?? toDefaultAge)
    }, [ageRange, user])

    const isFiltersChanged =
        !countries.length &&
        !specialHostCategory?.length &&
        !languages.length &&
        !chatTopics.length &&
        !activities.length &&
        !(genders.length && genders[0]?.key_name !== GENDER_ALL_OPTION_KEY_NAME) &&
        fromDefaultAge === fromAge &&
        toDefaultAge === toAge

    const isDisabledClearButton = !(guestHasActiveFilters || (!guestHasActiveFilters && !isFiltersChanged))

    useEffect(() => {
        if (user) {
            const remappedSpecialHostCategory =
                currentFilters?.specialHostCategory?.map(item => {
                    return {
                        id: item.value,
                        value: `${item.value}`,
                        label: item.label,
                        name: item.label,
                        extraPayload: item.data
                    }
                }) || []
            // Set default value from userObject
            setChatTopics(currentFilters?.chatTopics || [])
            setActivities(currentFilters?.activities || [])
            setCountries(currentFilters?.countries || [])
            setLanguages(currentFilters?.languages || [])
            setGenders(currentFilters?.genders || [])
            setSpecialHostCategory(remappedSpecialHostCategory)
        }
    }, [user])

    const {
        mutate: storeFilters,
        isPending: isStoreFiltersPending,
        isError: isStoreFiltersError,
        error: storeFiltersError
    } = useStoreActiveFilters()

    const {
        mutate: clearFilters,
        isPending: isClearFiltersPending,
        isError: isClearFiltersError,
        error: clearFiltersError
    } = useClearActiveFilters()

    useHandleError({
        isError: isStoreFiltersError || isClearFiltersError || isAgeRangeError,
        error: storeFiltersError || clearFiltersError || ageRangeError
    })

    const activeFilters = useMemo(
        () => ({
            chatTopics,
            activities,
            countries,
            languages,
            genders: genders[0]?.key_name === GenderKeyNameE.ALL ? [] : genders,
            fromAge,
            toAge,
            specialHostCategory: specialHostCategory?.map(item => {
                return {
                    value: +item.id,
                    label: item.label,
                    data: item.extraPayload
                }
            }) as HostFiltersType['specialHostCategory']
        }),
        [chatTopics, activities, countries, languages, genders, fromAge, toAge, specialHostCategory]
    )

    const onApply = () => {
        storeFilters(activeFilters)
        setIsFetchingWithHostFilters(true)
        onClose()
        mixpanel?.trackEvent(MixpanelEventNameE.FilterApplied, {
            chat_topics: chatTopics,
            activities: activities,
            countries: countries,
            languages: languages,
            gender: genders,
            special_host_category: specialHostCategory,
            age_range: [fromAge, toAge]
        })
    }

    const onClear = () => {
        if (isDisabledClearButton) return

        if (guestHasActiveFilters) {
            clearFilters()
            setIsFetchingWithHostFilters(true)
        }

        setChatTopics([])
        setActivities([])
        setCountries([])
        setLanguages([])
        setGenders([])
        setSpecialHostCategory([])
        setFromAge(fromDefaultAge)
        setToAge(toDefaultAge)
    }

    const filterContent = {
        [HostFiltersTabIdsE.CHAT_TOPICS]: <ChatTopicsFilter chatTopics={chatTopics} setChatTopics={setChatTopics} />,
        [HostFiltersTabIdsE.ACTIVITIES]: <ActivitiesFilter activities={activities} setActivities={setActivities} />,
        [HostFiltersTabIdsE.COUNTRY_LANGUAGE]: (
            <CountryAndLanguageFilter
                countries={countries}
                setCountries={setCountries}
                languages={languages}
                setLanguages={setLanguages}
            />
        ),
        [HostFiltersTabIdsE.SPECIAL_HOST_CATEGORY]: (
            <SpecialHostCategoryFilter
                specialHostCategory={specialHostCategory}
                setSpecialHostCategory={setSpecialHostCategory}
            />
        ),
        [HostFiltersTabIdsE.GENDER]: (
            <AgeAndGenderFilter
                genders={genders}
                setGenders={setGenders}
                fromAge={fromAge}
                toAge={toAge}
                setFromAge={setFromAge}
                setToAge={setToAge}
            />
        )
    }

    return (
        <SidePopover isOpen={isOpen} onClose={onClose} desktopMaxWidth={sizing.desktopSidebarWidth}>
            {(isStoreFiltersPending || isClearFiltersPending || isAgeRangeLoading) && <Spinner />}
            <StyledWrapper vh={vh}>
                <StyledHeader>
                    <StyledTitleWrapper>
                        <BackIcon onClick={onClose} fill={palette.neutral[25]} />
                        <h1>{t('path_filter:filter_your_feed')}</h1>
                    </StyledTitleWrapper>
                </StyledHeader>

                <StyledContent>
                    <FiltersTabs
                        filterTabs={HOST_FEED_FILTER_TABS}
                        selectedValueCount={{
                            [HostFiltersTabIdsE.CHAT_TOPICS]: chatTopics.length,
                            [HostFiltersTabIdsE.ACTIVITIES]: activities.length,
                            [HostFiltersTabIdsE.COUNTRY_LANGUAGE]: countries.length + languages.length,
                            [HostFiltersTabIdsE.SPECIAL_HOST_CATEGORY]: specialHostCategory?.length,
                            [HostFiltersTabIdsE.GENDER]:
                                !!genders.length && genders[0]?.key_name !== GENDER_ALL_OPTION_KEY_NAME
                                    ? genders.length
                                    : 0
                        }}
                        tabsContent={filterContent}
                    />
                </StyledContent>

                <StyledButtonSection>
                    <Button onClick={onApply}>{t('chat_topics_filter:apply_button')}</Button>

                    <StyledClearAll aria-disabled={isDisabledClearButton} onClick={onClear}>
                        {t('chat_topics_filter:clear_all_button')}
                    </StyledClearAll>
                </StyledButtonSection>
            </StyledWrapper>
        </SidePopover>
    )
}
