import {MicromorgiIcon} from '@/assets/icons/icons'
import {Tag} from '@/components/ui/tag/Tag'
import {GiftList} from '@/features/gift/components/gift-list/GiftList'
import {footerData} from '@/features/goal/constant/goal'
import {GoalStatusesE, GoalType} from '@/features/goal/types'
import {useMemo} from 'react'
import {useTranslation} from 'react-i18next'
import {useTheme} from 'styled-components'
import {GuestFooterRoot, StyledTagWrapper, StyledText, tagStyles} from './style'

export const GuestFooter = ({goal}: {goal?: GoalType;}) => {
    const {t} = useTranslation()
    const theme = useTheme()
    const leaderDonationsAmount = goal && +goal?.leader_donations_amount
    const isActiveGoal = GoalStatusesE.ACTIVE === goal?.status
    const isActiveNotSupported = isActiveGoal && !leaderDonationsAmount
    const isActiveSupported = isActiveGoal && !!leaderDonationsAmount
    const isNotActiveSupported =
        (GoalStatusesE.AWAITING_PROOF === goal?.status ||
            GoalStatusesE.PROOF_PENDING_APPROVAL === goal?.status ||
            GoalStatusesE.PROOF_STATUS_DECLINED === goal?.status ||
            GoalStatusesE.SUCCESSFUL === goal?.status) &&
        !!leaderDonationsAmount
    const isCanceledSupported = GoalStatusesE.CANCELED === goal?.status && !!leaderDonationsAmount

    const content = useMemo(() => {
        switch (true) {
            case isActiveNotSupported:
                return footerData.not_canceled_not_supported
            case isActiveSupported:
                return footerData.not_canceled_supported
            case isNotActiveSupported:
                return footerData.not_active_supported
            case isCanceledSupported:
                return footerData.canceled_supported
            default:
                return null
        }
    }, [goal?.status, leaderDonationsAmount])

    return content ? (
        <GuestFooterRoot>
            <StyledTagWrapper>
                {content?.title && (
                    <Tag
                        title={`${t(content?.title, {amount: leaderDonationsAmount})}`}
                        tagStyles={() => tagStyles({theme: theme, background: content?.background})}
                    />
                )}
            </StyledTagWrapper>
            <StyledText isBold={isActiveNotSupported}>
                {t(`${content?.text}`, {amount: leaderDonationsAmount})}
                {content?.withIcon && <MicromorgiIcon />}
            </StyledText>
            {isActiveGoal && (
                <GiftList
                    hostID={`${goal?.rookie?.id}`}
                    goalId={`${goal?.id}`}
                    iconSize={{height: 13, width: 13}}
                    withWallet
                    maxDonation={+goal.target_amount - +goal.donations_count}
                />
            )}
        </GuestFooterRoot>
    ) : null
}

// hide footer - if: suspended, review, if didn't donate and canceled
// active - 2 variants (Supported; not Supported) - with gift panel
// awaiting_proof, proof_pending_approval, proof_status_declined, success - variant without gift panel
// canceled - if donate show variant without gift panel
