import styled, {css} from 'styled-components'

export const StyledDailyMicromorgiLimitModalContainer = styled.div`
    ${({theme: {spacing}}) => css`
        gap: ${spacing}px;
        display: flex;
        flex-direction: column;
        padding: ${spacing}px ${spacing * 3}px ${spacing * 2}px ${spacing * 3}px;
        flex-grow: 1;
    `}
`

export const StyledDailyMicromorgiLimitModalContent = styled.div`
    ${() => css`
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
    `}
`

export const StyledDailyMicromorgiLimitModalDescription = styled.p`
    ${() => css`
        opacity: 0.5;
        text-align: center;
    `}
`

export const StyledAnimation = styled.div`
    ${() => css`
        width: 220px;
        height: 200px;
        margin: auto;
    `}
`
