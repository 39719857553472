import {useEffect} from 'react'
import {useMe} from '@/features/authentication/queries/useMe'
import {USER_ROLE_GUEST} from '@/utilities/constants/user'
import {LocalStorageManager} from '@/utilities/localStorage'
import {GuestType} from '@/features/authentication/types'
import {useRootStore} from '@/store'
import {selectGetUltimateStatusModal} from '@/store/selectors'

export const useUltimateStatus = () => {
    const {data: userData} = useMe()
    const user = userData?.type === USER_ROLE_GUEST ? (userData as GuestType) : null

    const {openModal: openGetUltimateStatusModal} = useRootStore(selectGetUltimateStatusModal)

    const ultimateOffer = user?.type_attributes?.ultimate_offer
    const isRefusedUltimateStatus = LocalStorageManager.refusedUltimateStatus.get()

    const isShownUltimateStatusModalFirstTime = LocalStorageManager.shownUltimateStatusModalFirstTime.get()
    const isUltimateStatusOfferAvailable =
        ultimateOffer?.is_ultimate_status_offer_available &&
        !isRefusedUltimateStatus &&
        !isShownUltimateStatusModalFirstTime

    useEffect(() => {
        if (isUltimateStatusOfferAvailable) {
            openGetUltimateStatusModal({})
        }
    }, [isUltimateStatusOfferAvailable])
}
