import {QUERY_KEYS as AUTHENTICATION_QUERY_KEYS} from '@/features/authentication/queries/keys'
import {useMutation, useQueryClient} from '@tanstack/react-query'
import {FEED_API} from '../services/feed.http'
import {HostFiltersType} from '../types'
import {MUTATION_KEYS} from './keys'

export const useStoreActiveFilters = () => {
    const queryClient = useQueryClient()
    return useMutation({
        mutationKey: [MUTATION_KEYS.store_filters],
        mutationFn: (filters: HostFiltersType) => FEED_API.storeActiveFilters({filters}),
        onSuccess: () => {
            queryClient.invalidateQueries({queryKey: [AUTHENTICATION_QUERY_KEYS.user]})
        }
    })
}
