import axios from '@/clientAPI.ts'
import {HostSurpriseResponseType, MySurpriseSingleType, UploadSurprisePayloadType} from '../types'

export const MY_SURPRISES_API = {
    getMySurprises: async (): Promise<MySurpriseSingleType[]> => {
        const url = '/surprises'
        const {data} = await axios.get(url)
        return data
    },
    uploadSurprise: async (body: UploadSurprisePayloadType) => {
        const url = `/surprises`
        return await axios.post(url, {...body})
    },
    unlockHostSurprise: async (hostId: string | number): Promise<MySurpriseSingleType> => {
        const url = `/rookies/${hostId}/surprises/unlock`
        const {data} = await axios.get(url)
        return data
    },
    unlockHostSurpriseById: async (
        hostId: string | number,
        surpriseId: string | number
    ): Promise<MySurpriseSingleType> => {
        const url = `/rookies/${hostId}/surprises/${surpriseId}/unlock`
        const {data} = await axios.post(url)
        return data
    },
    getHostSurprises: async (
        hostId: string | number,
        limit?: number,
        page?: string
    ): Promise<HostSurpriseResponseType> => {
        const url = `/rookies/${hostId}/surprises`
        const {data} = await axios.get(url, {params: {limit, page}})
        return data
    }
}
