import {useInfiniteQuery} from '@tanstack/react-query'
import {QUERY_KEYS} from './keys'
import {GIFS_API} from '../services/gifs.http'

export const useFetchSearchGifs = ({limit = 10, q, enabled}: {limit?: number; q?: string; enabled?: boolean}) => {
    return useInfiniteQuery({
        queryKey: [QUERY_KEYS.search_gifs, limit, q],
        queryFn: ({pageParam = 0}) => GIFS_API.getSearchGifs({limit, q, offset: +pageParam}),
        initialPageParam: '0',
        getNextPageParam: ({pagination}) => (pagination.count >= limit ? `${pagination.offset + limit}` : undefined),
        enabled
    })
}
