import {useMutation} from '@tanstack/react-query'
import {MOENGAGE_API} from '../services/moengage.http'
import {MUTATION_KEYS} from './keys'

export const useSeenMoengageCombination = () => {
    return useMutation({
        mutationKey: [MUTATION_KEYS.seen_moengage_combination],
        mutationFn: ({campaign_id}: {campaign_id: string | number}) => MOENGAGE_API.combinationSeen({campaign_id})
    })
}
