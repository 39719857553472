import {VideoInteractiveFeedIcon} from '@/assets/icons/icons'
import {Flexbox} from '@/components/ui/flexbox/FlexBox'
import {HostCardContent} from '@/features/feed/types'
import {FC, useMemo} from 'react'
import {useTranslation} from 'react-i18next'
import {StyledGoalContainer, StyledPhotoCount, StyledVideoContainer} from './style'
import useWindowDimensions from '@/hooks/useWindowDimensions'
import {GoalStatusesE} from '@/features/goal/types'

type HostMediaProps = {
    content: HostCardContent
}

export const HostMedia: FC<HostMediaProps> = ({content}) => {
    const {t} = useTranslation()
    const {width} = useWindowDimensions()
    const isMobile = useMemo(() => width < 768, [width])

    const activeRookiesGoals = content.type_attributes?.goals?.filter(goals => goals.status === GoalStatusesE.ACTIVE)

    return (
        <Flexbox direction="column" gap={0.25}>
            {content?.type_attributes?.photos && content.type_attributes.photos.length > 1 && !isMobile && (
                <StyledPhotoCount>
                    {t('interactive_feed_rookie_card:photos', {
                        COUNT_PHOTOS: content.type_attributes.photos_count
                    })}
                </StyledPhotoCount>
            )}
            {(!!content?.type_attributes?.videos?.length || !!activeRookiesGoals?.length) && (
                <Flexbox align="center" gap={0.25} width={'100%'}>
                    {!!content?.type_attributes?.videos?.length && (
                        <StyledVideoContainer>
                            <VideoInteractiveFeedIcon />
                        </StyledVideoContainer>
                    )}
                    {!!activeRookiesGoals?.length && (
                        <StyledGoalContainer>
                            <span>
                                {t('interactive_feed_rookie_card:goals', {
                                    COUNT_GOALS: activeRookiesGoals?.length,
                                    context: activeRookiesGoals?.length === 1 ? 'single' : 'plural'
                                })}
                            </span>
                        </StyledGoalContainer>
                    )}
                </Flexbox>
            )}
        </Flexbox>
    )
}

HostMedia.displayName = 'HostMedia'
