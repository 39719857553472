import {Lens} from '@dhmk/zustand-lens'

export enum AnimationPlaces {
    GiftPanel = 'giftPanel'
}

type AnimationTriggersState = Record<
    AnimationPlaces,
    {
        isPlayAnimation: boolean
        delay: number
    }
>

type AnimationTriggersActions = {
    playAnimation: (params: {key: AnimationPlaces; delay: number}) => void
    stopAnimation: (params: {key: AnimationPlaces}) => void
}

export type AnimationTriggers = AnimationTriggersState & AnimationTriggersActions

const initialModal: AnimationTriggersState = {
    [AnimationPlaces.GiftPanel]: {
        isPlayAnimation: false,
        delay: 0
    }
}

export const createAnimationTriggersSlice: Lens<AnimationTriggers> = set => ({
    ...initialModal,
    playAnimation: ({delay, key}) => {
        setTimeout(() => set(() => ({[key]: {isPlayAnimation: false, delay: 0}})), delay)
        set(() => ({[key]: {isPlayAnimation: true, delay}}))
    },
    stopAnimation: params => set(() => ({[params.key]: {isPlayAnimation: false, delay: 0}}))
})
