import {useMutation} from '@tanstack/react-query'
import {MOENGAGE_API} from '../services/moengage.http'
import {MUTATION_KEYS} from './keys'

export const useGetFreeMorgis = () => {
    return useMutation({
        mutationKey: [MUTATION_KEYS.get_free_morgis],
        mutationFn: ({campaign_id}: {campaign_id: string | number}) => MOENGAGE_API.getFreeMorgis({campaign_id})
    })
}
