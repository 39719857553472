import {MoengageTemplateType} from '@/features/moengage/types'
import {StyledGoalPromoWrapper, StyledPromoLabel} from './style'
import {HostGoalCard} from '@/features/goal/components/host-goal-card/HostGoalCard'
import {GoalType} from '@/features/goal/types'
import {PROMO_LABEL_GRADIENTS, PROMO_LABEL_NAMES, PROMO_TYPES} from '../../combinations'
import GoalTypeLabel from './GoalTypeLabel'

export type GoalPromoProps = {
    goal: GoalType
    promo?: {
        type: string
        amount: number
    }
}

const GoalPromoTemplate: React.FC<GoalPromoProps> = ({goal, promo}) => {
    const promoType = promo?.type || ''
    const promoLabelGradients = PROMO_LABEL_GRADIENTS[promoType as keyof typeof PROMO_LABEL_GRADIENTS]
    const percent = promo ? Math.floor(promo?.amount * 100) : null
    const promoName = PROMO_LABEL_NAMES[promoType as keyof typeof PROMO_LABEL_NAMES]

    return (
        <StyledGoalPromoWrapper>
            <GoalTypeLabel goalType={goal?.type?.type} />
            {!!promo && (
                <StyledPromoLabel
                    promoType={promoType}
                    promoLabelGradients={promoLabelGradients}
                    //TODO add color
                >
                    {/* TODO correct text */}
                    {promoType === PROMO_TYPES.discount && '-'}{' '}
                    {promoType === PROMO_TYPES.free_morgis ? `${promo?.amount} ` : `${percent}% `}
                    {promoName}
                </StyledPromoLabel>
            )}
            <HostGoalCard goal={goal} isInMoengage />
        </StyledGoalPromoWrapper>
    )
}

export default GoalPromoTemplate

export const model = (templateData: MoengageTemplateType | null = null) => {
    if (!templateData) return null
    return {
        cta_type: templateData['cta_type'],
        promo: templateData['promo'],
        goal: templateData['goal']
    }
}

export const GOAL_TEMPLATE_CTA_TYPES = {
    check_goal: 'check_goal'
}
