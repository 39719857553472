import { Lens } from '@dhmk/zustand-lens'

export enum TypeOfConfirmModalE {
    giftList = 'giftList',
    paidMessage = 'paidMessage',
    openConnection = 'openConnection',
}

export enum SendGiftStatusesE {
    Success = 'success',
}
type ConfirmSendGiftModalState = {
    isOpen: boolean
    hostID: string | null
    goalId?: string | null
    amount: number | null
    animationID?: number
    status?: string | null
    onConfirmCallBack?: () => void
    confirmText?: string
    typeOfModal?: TypeOfConfirmModalE
}

type ConfirmSendGiftModalActions = {
    openModal: (params: Omit<ConfirmSendGiftModalState, 'isOpen'>) => void
    closeModal: () => void
    setStatus: (status?: SendGiftStatusesE | null) => void
}

export type ConfirmSendGiftModal = ConfirmSendGiftModalState &
    ConfirmSendGiftModalActions

const initialModal: ConfirmSendGiftModalState = {
    isOpen: false,
    hostID: null,
    goalId: null,
    amount: null,
    animationID: 0,
    onConfirmCallBack: undefined,
    confirmText: undefined,
    typeOfModal: TypeOfConfirmModalE.giftList,
}

const createConfirmSendGiftModalSlice: Lens<ConfirmSendGiftModal> = (set) => ({
    ...initialModal,
    setStatus: (status) => set(() => ({ status })),
    openModal: (params) => set(() => ({ ...params, isOpen: true })),
    closeModal: () => set(() => ({ ...initialModal })),
})

export default createConfirmSendGiftModalSlice
