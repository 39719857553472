type CopyToClipboardFunction = (text: string) => Promise<void>

type useCopyToClipboardType = {
    copyToClipboard: CopyToClipboardFunction
}

const useCopyToClipboard = (onSuccess: () => void): useCopyToClipboardType => {
    const copyToClipboard: CopyToClipboardFunction = async text => {
        let textArea = document.createElement('textarea')
        textArea.value = text

        textArea.style.top = '0'
        textArea.style.left = '0'
        textArea.style.position = 'fixed'

        document.body.appendChild(textArea)
        textArea.focus()
        textArea.select()

        try {
            document.execCommand('copy')
            onSuccess?.()
        } catch (err) {
            console.error('Fallback: Oops, unable to copy', err)
        }
        document.body.removeChild(textArea)
    }

    return {copyToClipboard}
}

export default useCopyToClipboard
