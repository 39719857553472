import styled, {css} from 'styled-components'

export const ConfettiContainer = styled.div<{visible: boolean; $isModal: boolean}>`
    ${({visible, $isModal}) => css`
        width: 100%;
        height: 500px;
        position: fixed;

        left: 50%;
        overflow: hidden;
        z-index: 1;
        transform: translate(-50%, 0);
        pointer-events: none;
        display: ${visible ? 'block' : 'none'};
        ${$isModal
            ? css`
                  top: 10px;
              `
            : css`
                  top: 25px;
              `}
        & > div {
            top: 0;
            bottom: 0;
            left: 50%;
            right: 0;
            width: 100%;
            height: 100%;
        }
    `}
`
