import styled, {DefaultTheme, css} from 'styled-components'

export const StyledWrapper = styled.div`
    ${({theme: {mediaQueries, spacing}, isInFeed}: {isInFeed?: boolean; theme: DefaultTheme}) => css`
        display: flex;
        flex-direction: column;
        font-weight: 700;
        width: 100%;
        gap: ${isInFeed ? '2px' : '4px'};
        ${mediaQueries.l} {
            gap: ${spacing}px;
        }
    `}
`

export const StyledWish = styled.div`
    display: flex;
    align-items: center;
    gap: 5px;
`
export const StyledActivitiesIcon = styled.img`
    ${({theme: {mediaQueries}, isInFeed}: {isInFeed?: boolean; theme: DefaultTheme}) => css`
        max-width: ${isInFeed ? '16px' : '24px'};
        ${mediaQueries.m} {
            max-width: 24px;
        }
    `}
`
export const StyledExpertiseIcon = styled.img`
    width: 24px;
    height: 24px;
`
