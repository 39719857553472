import {palette} from '@/theme/palette'
import {GoalStatusesE} from '../types'

export const goalStatusesWithoutTimer = [
    GoalStatusesE.AWAITING_PROOF,
    GoalStatusesE.SUCCESSFUL,
    GoalStatusesE.CANCELED,
    GoalStatusesE.PROOF_PENDING_APPROVAL
]

export const progressGradient = {
    success: [`${palette.light.success[625]}`, `${palette.light.success[650]}`, `${palette.light.success[600]}`],
    fail: [`${palette.light.danger[125]}`, `${palette.light.danger[150]}`, `${palette.light.danger[50]}`],
    singleFail: `${palette.light.danger[25]}`,
    singleSuccess: `${palette.light.success[400]}`,
    singleDefault: `${palette.light.primary[200]}`,
    default: [`${palette.light.primary[200]}`, `${palette.light.primary[225]}`, `${palette.light.primary[250]}`]
}

export const tagContent = {
    success: {
        background: `${palette.light.success[600]}`,
        title: 'goal_page:label_raised'
    },
    fail: {
        background: `${palette.light.danger[50]}`,
        title: 'goal_page:label_not_funded'
    },
    suspended: {
        background: `${palette.light.danger[50]}`,
        title: 'goal_page:label_suspended'
    },
    review: {
        background: `${palette.light.danger[50]}`,
        title: 'goal_card:proof_label_review'
    }
}
