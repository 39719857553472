import {HostPartialType} from '@/features/authentication/types'
import {StyledCardWrapper, StyledPromoLabel} from './style'
import {PROMO_LABEL_GRADIENTS, PROMO_LABEL_NAMES} from '../../combinations'
import {MoengageTemplateType} from '@/features/moengage/types'
import {MoengageHostCard} from './MoengageHostCard'

export type HostsProfileProps = {
    hostsList?: HostPartialType[]
    isMultiHosts?: boolean
    CtaComponent?: () => JSX.Element
    promo?: {
        type?: string
        amount?: number
    }
}

const HostsProfileTemplate: React.FC<HostsProfileProps> = ({hostsList, promo, CtaComponent}) => {
    const promoType = promo?.type
    const promoLabelGradients = PROMO_LABEL_GRADIENTS[promoType as keyof typeof PROMO_LABEL_GRADIENTS]
    const percent = Math.floor(promo?.amount ? promo?.amount * 100 : 0)
    const promoName = PROMO_LABEL_NAMES[promoType as keyof typeof PROMO_LABEL_NAMES]

    return (
        <>
            {hostsList?.length &&
                [hostsList[0]].map(host => {
                    return (
                        <StyledCardWrapper key={host.id}>
                            {promo && (
                                <StyledPromoLabel promoLabelGradients={promoLabelGradients}>
                                    {promoType === 'discount' && '-'}
                                    {promoType === 'free_morgis' ? `${promo?.amount} ` : `${percent}% `}
                                    {promoName}
                                </StyledPromoLabel>
                            )}
                            {/* TODO here should be host profile card from interactive feed */}
                            <MoengageHostCard content={{...host, cardType: 'host'}} CtaComponent={CtaComponent} />
                        </StyledCardWrapper>
                    )
                })}
        </>
    )
}

export default HostsProfileTemplate

export const model = (templateData: MoengageTemplateType | null = null) => {
    if (!templateData) return null
    return {
        cta_type: templateData['cta_type'],
        promo: templateData['promo'],
        host_list: templateData['host_list']
    }
}

export const HOST_PROFILE_CTA_TYPES = {
    send_message: 'send_message',
    connect_now: 'connect_now',
    support_monthly: 'support_monthly',
    became_top_fan: 'became_top_fan',
    send_gift: 'send_gift',
    send_tip: 'send_tip'
}
