import React from 'react'
import morgiRotatedIcon from '@/assets/images/morgiRotatedIcon3x.png'
import {PROMO_COLORS, PROMO_LABEL_GRADIENTS, PROMO_LABEL_NAMES, PROMO_TYPES} from '../../combinations'
import {
    StyledDiscountAmount,
    StyledImageWrapper,
    StyledPromoLabel,
    StyledPromoWrapper,
    StyledRootWrapper
} from './style'
import {MoengageTemplateType, PromoPackageType} from '@/features/moengage/types'
import {DynamicComponentRightArrowIcon} from '@/assets/icons/icons'
import CorrectClaimSection from './CorrectClaimSection'

export type PackagePromoProps = {
    correctPackageData: PromoPackageType & {
        promo?: {
            type?: string
            percent?: number
        }
    }
    CtaComponent?: () => JSX.Element
}

const PackagePromoTemplate: React.FC<PackagePromoProps> = ({correctPackageData, CtaComponent}) => {
    const promoType = correctPackageData?.promo?.type || ''
    const promoLabelGradients = PROMO_LABEL_GRADIENTS[promoType as keyof typeof PROMO_LABEL_GRADIENTS]
    const promoName = PROMO_LABEL_NAMES[promoType as keyof typeof PROMO_LABEL_NAMES]
    const promoColor = PROMO_COLORS[promoType as keyof typeof PROMO_COLORS]
    const discountPercent = Math.floor(
        (correctPackageData?.promo?.percent != null ? correctPackageData?.promo?.percent : 0) * 100
    )

    const getCorrectPromoLabel = () => {
        switch (promoType) {
            case PROMO_TYPES.discount:
                return (
                    <>
                        {'-'}
                        {`${discountPercent}% ${promoName}`}
                    </>
                )
            case PROMO_TYPES.cashback:
                return <>{`${discountPercent}% ${promoName}`}</>

            default:
                return promoName
        }
    }

    return (
        <StyledRootWrapper promoColor={promoColor}>
            <StyledPromoWrapper promoColor={promoColor}>
                <StyledImageWrapper>
                    <img src={morgiRotatedIcon} alt="morgi" />
                </StyledImageWrapper>
                <StyledDiscountAmount>
                    {promoType !== PROMO_TYPES.free_morgis
                        ? correctPackageData?.micromorgi_count
                        : correctPackageData?.promo?.percent}
                    +
                </StyledDiscountAmount>
                <StyledPromoLabel promoType={promoType} promoLabelGradients={promoLabelGradients}>
                    {getCorrectPromoLabel()}
                </StyledPromoLabel>
            </StyledPromoWrapper>
            {(promoType === PROMO_TYPES.free_morgis && !!CtaComponent) || promoType !== PROMO_TYPES.free_morgis ? (
                <DynamicComponentRightArrowIcon />
            ) : null}
            {(promoType === PROMO_TYPES.free_morgis && !!CtaComponent) || promoType !== PROMO_TYPES.free_morgis ? (
                <CorrectClaimSection
                    promoType={promoType}
                    correctPackageData={correctPackageData}
                    CtaComponent={CtaComponent}
                />
            ) : null}
        </StyledRootWrapper>
    )
}

export default PackagePromoTemplate

export const model = (templateData: MoengageTemplateType | null = null) => {
    if (!templateData) return null
    return {
        cta_type: templateData['cta_type'],
        promo: templateData['promo'],
        package: templateData['package']
    }
}

export const PACKAGE_PROMO_CTA_TYPES = {
    go_to_offer: 'go_to_offer'
}
