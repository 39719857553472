import {Dispatch, MouseEvent, SetStateAction, useMemo, useState} from 'react'
import {Dropzone} from '../dropzone/Dropzone'
import {Accept, FileRejection, FileWithPath, useDropzone} from 'react-dropzone'
import {StyledInput} from './style'
import {AxiosProgressEvent, AxiosRequestConfig} from 'axios'
import {MAX_FILE_SIZE} from '@/utilities/constants/fileUploader'
import {FlattenSimpleInterpolation} from 'styled-components'
import {UploadAreaTypeE} from '@/features/goal/types'
import {CropImageModal} from '@/components/commons/crop-image-modal/CropImageModal'
import {IdentifyDocumentTypeE} from '@/features/host/types'
import {IdCardStatusesE} from '@/utilities/constants/user'
import {MediaTypeE} from '@/types'

export type UploadAreaPropsType = {
    type?: UploadAreaTypeE
    documentType?: IdentifyDocumentTypeE
    idDocumentsStatus?: IdCardStatusesE
    rejectionReason?: string | null
    description?: string
    defaultMediaType?: MediaTypeE
}

export type uploadAreaType = {
    isDragActive?: boolean
    isDragReject?: boolean
    uploadedFile?: FileWithPath | null
    fileRejection?: FileRejection | null
    handleRemove?: (e: MouseEvent) => void
    progress?: number
    setProgress?: Dispatch<SetStateAction<number>>
    setUploadedFile?: Dispatch<SetStateAction<FileWithPath | null>>
    isError?: boolean
    isSuccessfulUpload?: boolean
    defaultMediaUrl?: string
    uploadAreaProps?: UploadAreaPropsType
    disabled?: boolean
}

type FileUploaderProps = {
    UploadArea: ({...props}: uploadAreaType) => JSX.Element
    uploadFunction: ({file, options}: {file: File; options: AxiosRequestConfig}) => void
    uploadedFile?: FileWithPath | null
    dropzone?: boolean
    accept?: Accept
    maxSize?: number
    onRemove?: () => void
    dropzoneStyles?: () => FlattenSimpleInterpolation
    isError: boolean
    clearErrors?: () => void
    isSuccessfulUpload: boolean
    defaultMediaUrl?: string
    uploadAreaProps?: UploadAreaPropsType
    withCropper?: boolean
    disabled?: boolean
    multiple?: boolean
}

export const FileUploader: React.FC<FileUploaderProps> = ({
    UploadArea,
    uploadFunction,
    dropzone = true,
    accept,
    maxSize = MAX_FILE_SIZE,
    onRemove,
    dropzoneStyles,
    isError,
    clearErrors,
    isSuccessfulUpload,
    defaultMediaUrl,
    uploadAreaProps,
    withCropper = false,
    disabled = false,
    multiple = false
}) => {
    const [uploadedFile, setUploadedFile] = useState<FileWithPath | null>(null)
    const [fileRejection, setFileRejection] = useState<FileRejection | null>(null)
    const [progress, setProgress] = useState(0)
    const [cropMode, setCropMode] = useState(false)
    const [imageToCrop, setImageToCrop] = useState<string | ArrayBuffer | null>('')

    const dropzoneState = useDropzone({
        onDrop: (acceptedFiles: FileWithPath[], fileRejections) => {
            !!clearErrors && clearErrors()

            if (fileRejections?.length > 0) {
                handleFileChange(null)
                setFileRejection(fileRejections[0])
            }

            if (acceptedFiles?.length) {
                if (withCropper) {
                    const reader = new FileReader()
                    reader.addEventListener(
                        'load',
                        () => {
                            setImageToCrop(reader.result)
                            setCropMode(true)
                        },
                        false
                    )
                    reader.readAsDataURL(acceptedFiles[0])
                } else {
                    handleFileChange(acceptedFiles)
                    setUploadedFile(acceptedFiles[0])
                    setFileRejection(null)
                }
            }
        },
        accept,
        maxSize,
        disabled: (!!uploadedFile || !!defaultMediaUrl || disabled) && !isError,
        multiple
    })

    const {isDragReject, isDragActive, open} = dropzoneState

    const progressFn = (progressEvent: AxiosProgressEvent) => {
        if (progressEvent.total) setProgress(Math.round((progressEvent.loaded * 100) / progressEvent.total))
    }

    const handleFileChange = (files: FileList | FileWithPath[] | null) => {
        const selectedFile = files && files[0]
        const options = {onUploadProgress: progressFn}

        if (selectedFile) {
            uploadFunction({file: selectedFile, options})
        }
    }

    const handleRemove = (e: MouseEvent) => {
        e.stopPropagation()
        onRemove?.()
        setUploadedFile(null)
        setFileRejection(null)
        setProgress(0)
    }
    const handleReplace = (e: MouseEvent) => {
        handleRemove(e)
        open()
    }

    const uploadArea = useMemo(() => {
        return (
            <UploadArea
                isDragReject={isDragReject}
                isDragActive={isDragActive}
                uploadedFile={uploadedFile}
                fileRejection={fileRejection}
                handleRemove={handleRemove}
                progress={progress}
                setProgress={setProgress}
                setUploadedFile={setUploadedFile}
                isError={isError}
                isSuccessfulUpload={isSuccessfulUpload}
                defaultMediaUrl={defaultMediaUrl}
                uploadAreaProps={uploadAreaProps}
                disabled={disabled}
            />
        )
    }, [
        isDragReject,
        isDragActive,
        uploadedFile,
        fileRejection,
        progress,
        isError,
        isSuccessfulUpload,
        defaultMediaUrl
    ])

    return (
        <>
            {dropzone ? (
                <Dropzone children={uploadArea} dropzoneState={dropzoneState} dropzoneStyles={dropzoneStyles} />
            ) : (
                <>
                    {uploadArea && <label htmlFor="file">{uploadArea}</label>}
                    <StyledInput id="file" type="file" onChange={e => handleFileChange(e.target.files)} />
                </>
            )}
            {cropMode && (
                <CropImageModal
                    onClose={() => {
                        setImageToCrop('')
                        setCropMode(false)
                    }}
                    handleFileToUpload={(file: File) => {
                        handleFileChange([file])
                        setUploadedFile(file)
                        setFileRejection(null)
                    }}
                    imageToCrop={`${imageToCrop}`}
                    handleReplace={handleReplace}
                />
            )}
        </>
    )
}
