import styled, {css} from 'styled-components'
import * as RPopover from '@radix-ui/react-popover'

export const StyledBody = styled.div`
    ${({theme: {spacing}}) => css`
        width: 100%;
        height: 100%;
        padding: 0px ${spacing * 3}px;
        display: flex;
        flex-direction: column;
        overflow-y: hidden;
    `}
`

export const StyledSubtitle = styled.div`
    ${({theme: {typography, palette, spacing}}) => css`
        ${typography.textXXl};
        color: ${palette.primary['900']};
        text-align: center;
        margin: ${spacing * 3}px 0px;
    `}
`

export const StyledItem = styled.div`
    ${({theme: {palette, spacing}}) => css`
        display: grid;
        grid-template-columns: 0fr 1fr;
        gap: ${spacing * 2}px;
        border: none;
        background-color: transparent;
        height: 60px;
        align-items: center;
        text-align: left;
        padding: [0; spacing * 3];
        border-bottom: 1px solid ${palette.neutral['950']};
        &:hover {
            background-color: ${palette.neutral['950']};
        }
        cursor: pointer;

        & svg {
            width: 20px;
            height: 20px;
            color: ${palette.neutral.black};
        }
    `}
`

export const StyledText = styled.div`
    ${({theme: {palette, typography}}) => css`
        ${typography.textMd}
        font-weight: 700;
        color: ${palette.primary['900']};
    `}
`

export const StyledTextPhoneMessage = styled.div`
    ${({theme: {palette, typography}}) => css`
    & a {
        ${typography.textMd}
        font-weight: 700;
        color: ${palette.primary['900']};
        text-decoration: none;
    },
    `}
`

export const StyledPopoverContent = styled.div`
    ${({theme: {spacing, palette}}) => css`
        padding: ${spacing / 2}px ${spacing}px;
        color: ${palette.neutral.white};
        background-color: ${palette.neutral['100']};
        border-radius: ${spacing / 2}px;
    `}
`

export const StyledPopoverArrow = styled(RPopover.Arrow)`
    ${({theme: {palette}}) => css`
        fill: ${palette.neutral['100']};
    `}
`

export const modalStyles = () => css`
    min-height: auto;
    max-width: 345px;
`
