import styled, {css} from 'styled-components'

export const StyledWrapper = styled.div`
    border-radius: 10px;
    position: relative;
    aspect-ratio: 1/1;
    cursor: pointer;
`

export const StyledMediaWrapper = styled.div`
    height: 100%;
    width: 100%;
    overflow: hidden;
    position: relative;

    object-fit: cover;
    border-radius: 10%;
    & > img {
        width: 88px;
        height: 88px;
        position: absolute;
        z-index: 1;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
    & > video {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
`

export const StyledItemIcon = styled.div`
    ${({theme: {spacing}}) => css`
        position: absolute;
        top: ${spacing * 2}px;
        right: ${spacing * 2}px;
        z-index: 2;

        & img {
            width: 30px;
            height: 32px;
        }
    `}
`
