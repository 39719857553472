import {HeartIcon, HeartIconFilled} from '@/assets/icons/icons'
import {StyledConnectNowButton, StyledRoot, StyledSaveButton, StyledSpinner} from './style'
import {useTranslation} from 'react-i18next'
import {StylePropsType} from '@/theme/style'
import {HostPartialType} from '@/features/authentication/types'
import {useRootStore} from '@/store'
import {selectConnectModal, selectRecurringGiftModal} from '@/store/selectors'
import {useSaveHost} from '@/features/guest/queries/useSaveHost'
import {useUnsaveHost} from '@/features/guest/queries/useUnsaveHost'
import {useGetSavedHosts} from '@/features/guest/queries/useGetSavedHosts'
import {Spinner} from '@/components/ui/spinner/Spinner'
import useRedirectToChatChannel from '@/hooks/useRedirectToChatChannel'
import {useGetConnectedUser} from '@/hooks/useGetConnectedUser'
import {ProfileEventsPropsType} from '@/integrations/mixpanel/types'
import {SubscriptionStatusesE} from '@/features/chat/helpers/helpers'
import {useEffect, useState} from 'react'

//TODO added all types of buttons
type CtaBarProps = {
    ctaBarStyles?: StylePropsType
    host?: HostPartialType
    gradient?: boolean
    mixpanelData?: ProfileEventsPropsType
    handleSetEndTime?: () => void
}

export const CtaBar = ({ctaBarStyles, gradient = false, host, mixpanelData, handleSetEndTime}: CtaBarProps) => {
    const {mutate: saveHost, isPending: isPendingSave} = useSaveHost()
    const {mutate: unsaveHost, isPending: isPendingUnsave} = useUnsaveHost()
    const {data: savedHosts, isFetching} = useGetSavedHosts()
    const isSavedHost = !!savedHosts?.find(({id}) => id === host?.id)
    const [isSaved, setIsSaved] = useState(isSavedHost)
    const loading = isPendingSave || isPendingUnsave || isFetching

    useEffect(() => {
        setIsSaved(isSavedHost)
    }, [isSavedHost])

    const handleSaveButtonClick = () => {
        if (loading) return
        if (host?.id) {
            if (isSaved) {
                setIsSaved(false)
                unsaveHost(host?.id)
            } else {
                setIsSaved(true)
                saveHost(host?.id)
            }
        }
    }
    const {t} = useTranslation()
    const {openModal} = useRootStore(selectConnectModal)
    const {openModal: openSelectMonthlySupportModal} = useRootStore(selectRecurringGiftModal)
    const {connectedUser: connectedHost} = useGetConnectedUser(host?.id)
    const redirectToChatChannel = useRedirectToChatChannel({receiverId: parseInt(`${host?.id}`)})

    const subscriptionStatusActive =
        !!connectedHost && connectedHost?.subscription_status === SubscriptionStatusesE.ACTIVE

    const handleConnectNow = () => {
        if (connectedHost) {
            redirectToChatChannel?.()
        } else {
            if (host?.id) {
                openModal({mixpanelData: mixpanelData, hostID: host.id})
                handleSetEndTime?.()
            }
        }
    }

    const handleEditGift = () => {
        if (!!host && subscriptionStatusActive) {
            openSelectMonthlySupportModal({hostID: `${host.id}`})
        }
    }

    const renderCorrectCtaText = () => {
        switch (true) {
            case subscriptionStatusActive:
                return t('common:edit_gift')
            case !!connectedHost:
                return t('common:chat_now')
            default:
                return t('common:connect_now')
        }
    }

    return (
        <StyledRoot ctaBarStyles={ctaBarStyles}>
            <StyledSaveButton onClick={() => handleSaveButtonClick()}>
                <StyledSpinner>{loading && <Spinner overlay={false} center={false} />}</StyledSpinner>
                {isSaved ? <HeartIconFilled /> : <HeartIcon />}
            </StyledSaveButton>
            <StyledConnectNowButton
                gradient={gradient}
                onClick={subscriptionStatusActive ? handleEditGift : handleConnectNow}
            >
                {renderCorrectCtaText()}
            </StyledConnectNowButton>
        </StyledRoot>
    )
}
CtaBar.displayName = 'CtaBar'
