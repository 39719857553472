import {FC, ReactNode} from 'react'
import {StyledRoot, StyledSection, StyledSectionTitle} from './style'
import {FlattenSimpleInterpolation} from 'styled-components'

type HostProfileSectionProps = {
    children: ReactNode
    title?: string
    infoIcon?: JSX.Element | null
    sectionStyles?: () => FlattenSimpleInterpolation
}

export const HostProfileSection: FC<HostProfileSectionProps> = ({children, title, infoIcon = null, sectionStyles}) => {
    return (
        <StyledRoot>
            {(title || infoIcon) && (
                <StyledSectionTitle>
                    {!!title && <p>{title}</p>}
                    {!!infoIcon && <div>{infoIcon}</div>}
                </StyledSectionTitle>
            )}
            <StyledSection sectionStyles={sectionStyles}>{children}</StyledSection>
        </StyledRoot>
    )
}

HostProfileSection.displayName = 'HostProfileSection'
