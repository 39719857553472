import {TextareaWithBannedElementsTrap} from '@/features/generated-content-security/components/text-area-with-word-trap/TextareaWithBannedElementsTrap'
import styled, {css} from 'styled-components'

export const StyledForm = styled.form`
    ${({theme: {spacing}}) => css`
        width: 100%;
        height: 100%;
        padding: ${spacing * 3}px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        gap: ${spacing * 3}px;
        overflow-y: hidden;
        min-height: 417px;

        & button {
            width: auto;
        }
    `}
`

export const StyledTextareaWithBannedElementsTrap = styled(TextareaWithBannedElementsTrap)`
    ${({theme: {typography}}) => css`
        & > div {
            ${typography.textMd}
            min-height: 94px;
            max-height: 94px;
            min-width: auto;
        }
    `}
`

export const StyledText = styled.div`
    ${({theme: {spacing}}) => css`
        display: grid;
        flex-direction: column;
        gap: ${spacing}px;
        width: 100%;
    `}
`
