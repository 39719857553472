import {StyledTab, StyledTabsRoot} from './style'
import {additionalContent} from '../../constant/goalSize'
import {GoalTypeCounterE} from '@/features/common/services/common.schema'
import {useTranslation} from 'react-i18next'

type FilterTabsProps = {
    typeId?: string
    changeType: (typeId?: string, typeName?: string) => void
    numberGoalsByType?: {
        [GoalTypeCounterE.SNAP]: number
        [GoalTypeCounterE.SPECIAL]: number
        [GoalTypeCounterE.GRANDE]: number
    }
}

export const FilterTabs = ({typeId, changeType, numberGoalsByType}: FilterTabsProps) => {
    const {t} = useTranslation()
    const handleTabClick = (id: string, typeName: string) => {
        if (typeId !== id) {
            changeType(`${id}`, typeName)
        } else {
            changeType(undefined, undefined)
        }
    }

    return (
        <StyledTabsRoot>
            {Object.entries(additionalContent).map(([key, tab]) => {
                const isDisabled = (!!numberGoalsByType && !numberGoalsByType[tab.size]) || !numberGoalsByType
                return (
                    <StyledTab
                        isActive={`${tab.size}` === typeId}
                        activeBackground={tab.activeBackgroundColor}
                        key={key}
                        onClick={() => !isDisabled && handleTabClick(`${tab.size}`, tab.type_name)}
                        disabled={isDisabled}
                    >
                        {tab.whiteIcon}
                        <span>{t(`modal_goals:${tab.type_name}`)}</span>
                        <span>({(numberGoalsByType && numberGoalsByType[tab.size]) ?? '0'})</span>
                    </StyledTab>
                )
            })}
        </StyledTabsRoot>
    )
}
