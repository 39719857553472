import {useQuery} from '@tanstack/react-query'
import {QUERY_KEYS} from './keys'
import {COUNTRY_API} from '../services/country.http'

export const useGetLanguages = (is_translatable = false) => {
    return useQuery({
        queryKey: [QUERY_KEYS.languages],
        queryFn: () => COUNTRY_API.getLanguages({is_translatable})
    })
}
