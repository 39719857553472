import {z} from 'zod'

export enum ThankYousTypesE {
    inChatTipsAndGifts = 'in_chat_tips_and_gifts',
    uncoverAttachment = 'uncover_attachment'
}

const ThankYousTypes = z.nativeEnum(ThankYousTypesE)
export const ThankYousTypesSchema = z.array(ThankYousTypes)

// Thank yous single schemas
export const SingleTipsAndGiftsSchema = z.object({
    id: z.number(),
    key_name: z.string(),
    name: z.string(),
    type: ThankYousTypes.optional()
})
export const SingleUncoverAttachmentSchema = z.object({
    id: z.number(),
    key_name: z.string(),
    name: z.string(),
    type: ThankYousTypes.optional()
})

// Thank yous array schemas
export const TipsAndGiftsSchema = z.array(SingleTipsAndGiftsSchema)
export const UncoverAttachmentSchema = z.array(SingleUncoverAttachmentSchema)

export const defaultDataSchema = z.object({
    support_amount: z.number().optional(),
    message: z.string(),
    dollar_amount: z.number().optional(),
    subscription_package: z
        .object({
            id: z.number(),
            amount: z.number(),
            dollar_amount: z.number(),
            is_default: z.boolean(),
            is_visible: z.boolean()
        })
        .optional()
})

export const HostThankYousSchema = z.object({
    in_chat_tips_and_gifts: TipsAndGiftsSchema,
    uncover_attachment: UncoverAttachmentSchema
})

export const SetOptionsSchema = z.object({tips_and_gifts_ids: z.array(z.number())})
