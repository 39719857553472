import styled, {css} from 'styled-components'

export const StyledSwitchWrapper = styled.div`
    ${({theme: {spacing}}) => css`
        display: flex;
        align-items: center;
        gap: ${spacing / 2}px;
    `}
`

export const StyledLabel = styled.label`
    ${({theme: {typography}}) => css`
        ${typography.textXxs}
        font-weight: 400;
        line-height: 12px;
        position: relative;
    `}
`
export const StyledLanguageLabel = styled.span<{isActive: boolean}>`
    ${({isActive}) =>
        isActive
            ? css`
                  text-decoration: underline;
                  cursor: pointer;
              `
            : css``}
`
