import {Button} from '@/components/ui/button/Button'
import styled, {css} from 'styled-components'

export const StyledCancelRecurringGiftModalContainer = styled.div`
    ${({theme: {spacing}}) => css`
        padding: ${spacing * 3}px;
        display: flex;
        flex-direction: column;
        gap: ${spacing * 3}px;
    `}
`
export const StyledLimitedAmountsList = styled.div`
    ${({theme: {spacing}}) => css`
        margin: 0 -${spacing * 3}px;
        max-height: 168px;
        overflow: auto;
    `}
`
export const StyledCancelRecurringGiftModalDescription = styled.span`
    ${({theme: {palette, typography}}) => css`
        ${typography.textSm}
        color: ${palette.neutral.black};
        text-align: center;
        font-weight: 400;
        line-height: 20px;
        white-space: pre-wrap;
    `}
`
export const StyledRejectButton = styled(Button)`
    ${({theme: {typography, spacing}}) => css`
        ${typography.textMd}
        font-weight: 600;
        padding: ${spacing * 2}px ${spacing * 3}px;
    `}
`
export const StyledCancelButton = styled(Button)`
    ${({theme: {typography, spacing, palette}}) => css`
        ${typography.textSm}
        color: ${palette.danger[800]};
        box-shadow: none;
        font-weight: 400;
        padding: ${spacing} 0;
        text-decoration: underline;
        padding: ${spacing * 2}px ${spacing * 3}px;
    `}
`
export const StyledConfirmButton = styled(Button)`
    ${({theme: {palette, typography, spacing}}) => css`
        ${typography.textMd}
        font-weight: 600;
        color: ${palette.primary[900]};
        border-width: 1px;
        padding: ${spacing * 2}px ${spacing * 3}px;
    `}
`
export const StyledCancelRecurringGiftModalStepSecondContainer = styled.div`
    ${({theme: {spacing, palette}}) => css`
        display: flex;
        flex-direction: column;
        gap: ${spacing * 2}px;
        padding-bottom: ${spacing * 4}px;
        border-bottom: 1px solid ${palette.neutral[225]};
    `}
`
export const StyledCancelRecurringGiftModalStepSecondDescription = styled.span`
    ${({theme: {palette, typography}}) => css`
        ${typography.textSm}
        color: ${palette.neutral.black};
        text-align: center;
        font-weight: 400;
        line-height: 20px;
        white-space: pre-wrap;
    `}
`
type StyledHostsAvatarProps = {$src: string}

export const StyledHostsAvatar = styled.div<StyledHostsAvatarProps>`
    ${({$src, theme: {spacing}}) => css`
        height: 122px;
        width: 100%;
        border-radius: ${spacing * 1.25}px;
        background-image: url(${$src});
        background-size: cover;
        overflow: visible;
        background-repeat: no-repeat;
        background-position: center center;
    `}
`
export const StyledCancelRecurringGiftModalStepSecondTitle = styled.h3`
    ${({theme: {palette, typography, spacing}}) => css`
        ${typography.textXXl}
        color: ${palette.primary[900]};
        text-align: center;
        font-weight: 400;
        letter-spacing: -0.05em;
        margin-top: ${spacing}px;
    `}
`
export const StyledButtonContainer = styled.div`
    display: flex;
    flex-direction: column;
`
export const StyledCancelRecurringGiftModalStepThirdContainer = styled.div`
    ${({theme: {spacing}}) => css`
        display: flex;
        flex-direction: column;
        gap: ${spacing * 4}px;
    `}
`
export const StyledCancelRecurringGiftModalStepThird = styled.div`
    ${({theme: {spacing, palette}}) => css`
        display: flex;
        flex-direction: column;
        gap: ${spacing * 2}px;
        padding-bottom: ${spacing * 4}px;
        border-bottom: 1px solid ${palette.neutral[225]};
    `}
`
export const StyledCancelRecurringGiftModalStepThirdTitle = styled.h3`
    ${({theme: {palette, typography}}) => css`
        ${typography.textXXl}
        color: ${palette.primary[900]};
        text-align: center;
        font-weight: 400;
        letter-spacing: -0.05em;
    `}
`
export const StyledCancelRecurringGiftModalStepThirdDescription = styled.p`
    ${({theme: {palette, typography}}) => css`
        ${typography.textSm}
        color: ${palette.neutral[25]};
        margin: 0;
        text-align: center;
        font-weight: 400;
        line-height: 20px;
        white-space: pre-wrap;
    `}
`
export const StyledMeetButton = styled(Button)`
    ${({theme: {typography, spacing}}) => css`
        ${typography.textMd}
        font-weight: 600;
        border-width: 1px;
        padding: ${spacing * 2}px ${spacing * 3}px;
    `}
`

export const StyledAnimationBox = styled.div`
    margin: 0 auto;
    width: 180px;
    height: 180px;
`
