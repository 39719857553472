import {MicromorgiIcon} from '@/assets/icons/icons'
import {Modal} from '@/components/ui/modal/Modal'
import {useMe} from '@/features/authentication/queries/useMe'
import {GuestType} from '@/features/authentication/types'
import {useRootStore} from '@/store'
import {selectBuyMorgisModal, selectWalletModal} from '@/store/selectors'
import {FC} from 'react'
import {useTranslation} from 'react-i18next'
import {
    StyledBalance,
    StyledBalanceTitle,
    StyledBalanceWrapper,
    StyledButton,
    StyledDescription,
    StyledTitle,
    StyledWalletModalContainer
} from './style'

export const WalletModal: FC = () => {
    const {data} = useMe()
    const user = data as GuestType
    const {t} = useTranslation()
    const {closeModal: closeWalletModal} = useRootStore(selectWalletModal)
    const {openModal: openBuyMorgiModal} = useRootStore(selectBuyMorgisModal)

    const balance = user?.type_attributes.micro_morgi_balance

    return (
        <Modal onClose={closeWalletModal} title={t(`common:wallet`)}>
            <StyledWalletModalContainer>
                <StyledTitle> {t(`common:micromorgi`)}</StyledTitle>
                <StyledDescription>{t(`wallet:description`)}</StyledDescription>
                <StyledBalanceWrapper>
                    <StyledBalanceTitle> {`${t(`common:current_balance`)}:`}</StyledBalanceTitle>
                    <StyledBalance>
                        <MicromorgiIcon height={40} width={40} />
                        {balance}
                    </StyledBalance>
                    <StyledButton
                        onClick={() => {
                            openBuyMorgiModal({})
                            closeWalletModal()
                        }}
                    >
                        {t(`common:get_more_micromorgi`)}
                    </StyledButton>
                </StyledBalanceWrapper>
            </StyledWalletModalContainer>
        </Modal>
    )
}
