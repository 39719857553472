import {QUERY_KEYS} from '@/features/authentication/queries/keys.ts'
import {AUTH_API} from '@/features/authentication/services/auth.http.ts'
import {TErrCodes} from '@/queryClient.ts'
import {KNOWN_COOKIES} from '@/types'
import {LocalStorageManager} from '@/utilities/localStorage'
import {useQuery} from '@tanstack/react-query'
import Cookies from 'js-cookie'

export const useMe = (enabled = false) => {
    const accessToken = Cookies.get(KNOWN_COOKIES.authCookie)

    const query = useQuery({
        queryKey: [QUERY_KEYS.user],
        queryFn: AUTH_API.me,
        meta: {errCode: TErrCodes.ME_FETCH_FAILED},
        enabled: enabled || !!LocalStorageManager.loginUser.get() || !!accessToken
    })

    if (query.isSuccess) {
        if (!LocalStorageManager.loginUser.get()) {
            LocalStorageManager.loginUser.set(query.data.type)
        }
    }

    return {...query, loggedIn: LocalStorageManager.loginUser.get() ?? query.data?.type}
}
