import styled, {DefaultTheme, css} from 'styled-components'

export const StyledBackContainer = styled.div`
    ${({isWhite, text, theme: {spacing}}: {isWhite: boolean; text: boolean; theme: DefaultTheme}) => css`
        display: grid;
        align-items: center;
        grid-template-columns: ${text ? 'auto 1fr' : '1fr'};
        grid-column-gap: ${spacing}px;
        cursor: pointer;
        text-decoration: none;
        padding: 0 0 0 ${spacing}px;
        svg {
            fill: ${isWhite ? 'white' : 'black'};
        }
    `}
`
