import styled, {css} from 'styled-components'
import MaskGroup from '@/assets/images/MaskGroup.svg'

export const StyledTitleWrapper = styled.div`
    ${({theme: {palette, spacing, typography}}) => css`
        text-align: center;
        white-space: pre-wrap;
        margin: 0 !important;
        padding: 0 ${spacing * 3}px;
        & h1 {
            ${typography.text2Xl}
            font-weight: 700;
            margin: 0;

            & span {
                color: ${palette.primary[200]};
                font-weight: 700;
            }
        }
    `}
`

export const StyledSubTitleWrapper = styled.div`
    ${({theme: {spacing, typography}}) => css`
        margin: ${spacing * 3}px 0 0 0;
        display: flex;
        gap: ${spacing * 2}px;
        flex-direction: column;
        & p {
            ${typography.textMd};
            font-weight: 400;
            text-align: center;
            white-space: pre-wrap;
        }
    `}
`

export const StyledSvgContainer = styled.div`
    position: relative;

    & svg {
        position: absolute;
        top: 50px;
        right: 80px;
    }
`

export const StyledMaskDiv = styled.div`
    mask-image: url(${MaskGroup});
    height: 110px;
    margin: 0 auto;
    width: 110px;
    mask-repeat: no-repeat;
    mask-position: center;
    background-size: cover;
`

export const StyledModalContainer = styled.div`
    ${({theme: {spacing}}) => css`
        padding: ${spacing}px ${spacing * 3}px ${spacing * 2}px ${spacing * 3}px;
    `}
`

export const StyledDivider = styled.div`
    ${({theme: {palette}}) => css`
        width: 100%;
        height: 1px;
        background-color: ${palette.neutral['400']};
    `}
`
