import Pusher from 'pusher-js'
import baseNotificationConfig, {pusherConfig} from './features/notifications/config/notificationConfig'
import Ajax from 'pusher-js/types/src/core/http/ajax'

const PusherInstance = (() => {
    let instance: Pusher | null = null

    const createInstance = (): Pusher => {
        Pusher.Runtime.createXHR = function () {
            const xhr = new XMLHttpRequest()
            xhr.withCredentials = true
            return xhr as Ajax
        }
        return new Pusher(pusherConfig.pusherAppKey, {
            ...baseNotificationConfig
        })
    }

    return {
        getInstance: function () {
            if (!instance) {
                instance = createInstance()
            }
            return instance
        },
        setAuthHeaders: function (accessToken: string): void {
            if (instance && instance.options.auth) {
                instance.options.auth.headers.Authorization = `Bearer ${accessToken}`
                instance.options.auth.headers.Accept = 'application/json'
            }
        },
        getAuthHeaders: function (): string | undefined {
            return instance && instance.options.auth && instance.options.auth.headers.Authorization
        },
        removeAuthHeaders: function (): void {
            if (instance && instance.options.auth) instance.options.auth.headers = {}
        }
    }
})()

export default PusherInstance
