import {uploadAreaType} from '@/components/ui/file-uploader/FileUploader'
import {StyledPercentage, StyledProgress, StyledUploadAreaRoot} from './style'
import {PlusCircleIcon} from '@/assets/icons/icons'
import {CircleProgress} from '@/components/ui/circle-progress/CircleProgress'
import {useTranslation} from 'react-i18next'
import {useTheme} from 'styled-components'

export const EditMediaUploadArea = ({isDragReject, isDragActive, progress}: uploadAreaType) => {
    const {t} = useTranslation()
    const theme = useTheme()
    const showIcon = !isDragActive && !isDragReject

    return (
        <StyledUploadAreaRoot isUploading={!!progress}>
            {!!progress && (
                <StyledProgress>
                    <CircleProgress
                        percentage={progress}
                        strokeWidth={3}
                        staticCircleStrokeColor={''}
                        progressColor={theme.palette.neutral['950']}
                        animated={false}
                        isEmpty={true}
                        size={18}
                    />
                    <StyledPercentage>{progress}%</StyledPercentage>
                </StyledProgress>
            )}

            {showIcon && !progress && <PlusCircleIcon />}
            {isDragActive && !isDragReject && <span>{t('common:release_here')}</span>}
            {isDragReject && <span>{t('validation:file_not_accepted')}</span>}
        </StyledUploadAreaRoot>
    )
}
