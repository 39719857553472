import styled, {css} from 'styled-components'

export const StyledSmallError = styled.small`
    ${({theme: {palette, spacing, typography, mediaQueries}}) => css`
        ${typography.textXs}
        color: ${palette.danger[25]};
        margin-top: ${spacing / 2}px;
        ${mediaQueries.m} {
            ${typography.textSm}
        }
    `}
`

export const StyledSmall = styled.span`
    ${({theme: {palette}}) => css`
        color: ${palette.primary[700]};
        font-weight: 500;
        line-height: 1.8;
    `}
`
