import {Modal} from '@/components/ui/modal/Modal'
import {StyledBody, StyledEditIcon, StyledStopButton, StyledText} from './style'
import {Trans, useTranslation} from 'react-i18next'
import {EditTimePopupIcon} from '@/assets/icons/icons'
import {GoalStatusesE} from '../../types'
import {Button} from '@/components/ui/button/Button'

type EditGoalModalProps = {
    onClose: () => void
    status?: GoalStatusesE
    onStopButtonClick: () => void
    onEditButtonClick: () => void
}
export const EditGoalModal = ({onClose, status, onStopButtonClick, onEditButtonClick}: EditGoalModalProps) => {
    const {t} = useTranslation()

    return (
        <Modal onClose={onClose} maxHeight="557px" title={t('edit_goal_time_popup:title')}>
            <StyledBody>
                <StyledEditIcon>
                    <EditTimePopupIcon />
                </StyledEditIcon>
                <StyledText>
                    <span>
                        <Trans t={t} i18nKey={'edit_goal_time_popup:subtitle'} values={{context: status}} />
                    </span>
                    <p>
                        <Trans t={t} i18nKey={'edit_goal_time_popup:description'} values={{context: status}} />
                    </p>
                </StyledText>
                <Button onClick={onEditButtonClick}>
                    <Trans t={t} i18nKey={'edit_goal_time_popup:button_text'} values={{context: status}} />
                </Button>
                <StyledStopButton onClick={onStopButtonClick}>
                    {t('edit_goal_time_popup:bottom_button_text')}
                </StyledStopButton>
            </StyledBody>
        </Modal>
    )
}
