import styled, {css} from 'styled-components'

export const StyledRoot = styled.div`
    ${({theme: {spacing, palette}}) => css`
        display: grid;
        grid-template-columns: 0fr 1fr 0fr;
        align-items: center;
        background-color: ${palette.neutral.white};
        box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15);
        border-radius: 10px;
        margin: ${spacing}px 0;
        cursor: pointer;
        padding: ${spacing * 2}px;
        gap: ${spacing * 2}px;
        color: ${palette.primary[900]};
        & p {
            font-weight: 700;
        }
    `}
`

export const StyledIconsWrapper = styled.div`
    ${({theme: {spacing, flexUtils}}) => css`
        ${flexUtils.centered}
        flex-direction: column;
        gap: ${spacing / 2}px;
    `}
`

export const StyledTelegramIconWrapper = styled.div`
    ${({theme: {palette}}) => css`
        width: 100%;
        border: 1px solid ${palette.secondary[500]};
        height: 57px;
        display: grid;
        align-items: center;
        aspect-ratio: 1 / 1;
        border-radius: 10px;
        justify-content: center;
    `}
`

export const StyledArrowIconWrapper = styled.div`
    ${({theme: {palette}}) => css`
        width: 31px;
        border: 1px solid ${palette.secondary[900]};
        height: 31px;
        display: grid;
        align-items: center;
        border-radius: 50%;
        justify-content: center;
    `}
`
