import {Modal} from '@/components/ui/modal/Modal'
import {StyledBody, StyledButtonsWrapper, StyledContentWrapper, StyledCropWrapper, StyledHeader} from './style'

import {useTranslation} from 'react-i18next'
import {Button} from '@/components/ui/button/Button'
import {ReplaceIcon} from '@/assets/icons/icons'
import ImageCropper from '@/components/ui/image-cropper/ImageCropper'
import {MouseEvent, useState} from 'react'

type CropImageModalProps = {
    onClose: () => void
    handleFileToUpload: (file: File) => void
    cropShape?: 'rect' | 'round'
    aspectRatio?: number
    imageToCrop?: string
    handleReplace: (event: MouseEvent) => void
}
export const CropImageModal = ({
    onClose,
    handleFileToUpload,
    cropShape = 'rect',
    aspectRatio = 3 / 4,
    imageToCrop,
    handleReplace
}: CropImageModalProps) => {
    const {t} = useTranslation()
    const [croppedImage, setCroppedImage] = useState<File | null>(null)

    return (
        <Modal onClose={onClose} maxHeight="680px" height="100%" width="85%" maxWidth="520px">
            <StyledBody>
                <StyledHeader>
                    <h1>{t('signup:crop_photo_title')}</h1>
                    <p>{t('signup:crop_photo_message')}</p>
                </StyledHeader>

                <StyledContentWrapper>
                    <StyledCropWrapper>
                        <ImageCropper
                            cropShape={cropShape}
                            aspectRatio={aspectRatio}
                            inputImg={imageToCrop}
                            setCroppedImage={(image: File) => setCroppedImage(image)}
                        />
                    </StyledCropWrapper>
                    <StyledButtonsWrapper>
                        <Button onClick={e => handleReplace(e)} variant="transparent" data-transparent>
                            <ReplaceIcon />
                            {t('common:replace_photo')}
                        </Button>

                        <Button
                            onClick={() => {
                                if (croppedImage) {
                                    handleFileToUpload(croppedImage)
                                }
                                onClose()
                            }}
                            disabled={!croppedImage}
                        >
                            {t('common:save')}
                        </Button>
                    </StyledButtonsWrapper>
                </StyledContentWrapper>
            </StyledBody>
        </Modal>
    )
}

CropImageModal.displayName = 'CropImageModal'
