import {useState} from 'react'
import {StyledItemIcon, StyledMediaWrapper, StyledWrapper} from './style'
import {Media} from '@/components/ui/media/Media'
import {MySurpriseSingleType} from '../../types'
import mySurprises from '@assets/images/my-surprises/mySurprises.png'
import newSurprises from '@assets/images/my-surprises/new_surprises.png'
import {MediaLightBox} from '@/components/ui/media-light-box/MediaLightBox'
import {MediaTypeE} from '@/types'
import ResponsiveVideo from '@/components/commons/responsive-video/ResponsiveVideo'

export const HostSurprisePreview = ({
    surprise,
    handleOpenSurprise
}: {
    surprise: MySurpriseSingleType
    handleOpenSurprise?: () => void
}) => {
    const {type, url, blurred_url} = surprise
    const lockSurprise = !url && !!blurred_url
    const [isPreviewOpen, setIsPreviewOpen] = useState(false)

    return (
        <>
            <StyledWrapper
                onClick={() => (handleOpenSurprise && lockSurprise ? handleOpenSurprise() : setIsPreviewOpen(true))}
            >
                {!lockSurprise && (
                    <StyledItemIcon>
                        {' '}
                        <img src={mySurprises} />
                    </StyledItemIcon>
                )}

                {type === MediaTypeE.VIDEO && !lockSurprise ? (
                    <StyledMediaWrapper>
                        <ResponsiveVideo video={url} controls={false} withPlayButton={true} />
                    </StyledMediaWrapper>
                ) : (
                    <StyledMediaWrapper>
                        {lockSurprise && <img src={newSurprises} />}
                        <Media imageUrl={`${url ?? blurred_url}`} radius={10} />
                    </StyledMediaWrapper>
                )}
            </StyledWrapper>

            {isPreviewOpen && !lockSurprise && (
                <MediaLightBox
                    media={[
                        {
                            type: type === MediaTypeE.VIDEO ? MediaTypeE.VIDEO : MediaTypeE.IMAGE,
                            url
                        }
                    ]}
                    onClose={() => setIsPreviewOpen(false)}
                />
            )}
        </>
    )
}
HostSurprisePreview.displayName = 'HostSurprisePreview'
