import {useQuery} from '@tanstack/react-query'
import {QUERY_KEYS} from './keys'
import {CHAT_FAV_TOPICS_API} from '../services/chatFavTopics.http'

export const useGetExpertises = ({enabled = true, appLang = '', expertise = ''}) => {
    return useQuery({
        queryKey: [QUERY_KEYS.expertises, appLang, expertise],
        queryFn: () => CHAT_FAV_TOPICS_API.getExpertises(expertise),
        enabled
    })
}
