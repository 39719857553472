import {QUERY_KEYS} from './keys'
import {useInfiniteQuery} from '@tanstack/react-query'
import {MY_SURPRISES_API} from '../services/mySurprises.http'

type HostSurprisesPropsType = {
    page?: string
    limit?: number
    hostId: string | number
}

export const useGetHostSurprises = ({page, hostId, limit = 4}: HostSurprisesPropsType) => {
    return useInfiniteQuery({
        queryKey: [QUERY_KEYS.host_surprises, page, hostId, limit],
        queryFn: ({pageParam}) => MY_SURPRISES_API.getHostSurprises(hostId, limit, pageParam),
        initialPageParam: '',
        getNextPageParam: ({current_page, last_page}) =>
            current_page !== last_page ? `${current_page + 1}` : undefined
    })
}
