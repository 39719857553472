import {Media} from '@/components/ui/media/Media'
import {hexToHSL} from '@/utilities/helpers'
import styled, {DefaultTheme, css} from 'styled-components'

export const StyledCard = styled.div`
    ${({theme: {mediaQueries, spacing}, elementColor}: {theme: DefaultTheme; elementColor?: string}) => css`
        margin: ${spacing * 6}px auto ${spacing * 2}px;
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 315px;
        padding: ${props => props.theme.spacing}px;
        background: linear-gradient(
            30deg,
            rgba(255, 255, 255, 1) 0%,
            hsl(${hexToHSL(elementColor ?? '')}, 90%, 70%) 35%,
            ${elementColor} 100%
        );
        border-radius: 10px;
        box-shadow: 0px 1px 6px 0px rgba(0, 0, 0, 0.15);
        position: relative;
        ${mediaQueries.l} {
            height: 370px;
        }
    `}
`

export const StyledImageContainer = styled.div`
    position: absolute;
    width: 80px;
    height: 80px;
    top: -40px;
    right: 20px;
`

export const StyledMedia = styled(Media)`
    width: 100%;
    height: 100%;
`

export const StyledHeader = styled.div`
    ${({theme: {typography, sizing}, titleColor}: {theme: DefaultTheme; titleColor?: string}) => css`
        max-width: calc(100% - ${sizing.moengageIconWidth});
        ${typography.text2Xl};
        font-weight: 700;
        text-align: left;
        line-height: 24px;
        color: ${titleColor};
    `}
`

export const StyledDescription = styled.p`
    ${({
        theme: {typography, sizing},
        descriptionColor
    }: {
        theme: DefaultTheme
        descriptionColor?: string
    }) => css`
        font-size: ${props => props.theme.spacing * 2}px;
        ${typography.textMd}
        text-align: left;
        line-height: 150%;
        color: ${descriptionColor};
        max-width: calc(100% - ${sizing.moengageIconWidth});
    `}
`

export const StyledContent = styled.div`
    display: flex;
    align-items: center;
    height: 100%;
`
