import styled, {css} from 'styled-components'
import {Button} from '@/components/ui/button/Button'

export const StyledErrorWrapper = styled.div`
    ${({theme: {typography, palette, mediaQueries, zIndex}}) => css`
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background-color: ${palette.neutral.white};
        overflow: visible;
        z-index: ${zIndex.modal};
        border-radius: 10px;
        width: 92%;
        height: 557px;

        ${mediaQueries.m} {
            width: 400px;
            max-height: 90vh;
        }

        & p {
            ${typography.textLg};
            font-weight: 600;
            color: ${palette.neutral['900']};
            text-align: center;
            overflow: auto;
        }
    `}
`

export const StyledErrorContainer = styled.div`
    ${({theme: {spacing}}) => css`
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding: ${spacing * 4}px ${spacing * 3}px ${spacing * 2}px;
        gap: ${spacing * 2}px;
    `}
`

export const StyledAnimation = styled.div`
    margin: 0 auto;
    height: 100%;
    width: 100%;
    max-height: 220px;
    max-width: 240px;
`

export const StyledButton = styled(Button)`
    ${({theme: {typography}}) => css`
        ${typography.textMd}
        font-weight: 600;
        width: 100%;
    `}
`

export const StyledOverlay = styled.div(
    ({theme: {palette, zIndex}}) => css`
        position: fixed;
        inset: 0;
        background: ${palette.neutral[700]};
        z-index: ${zIndex.modalOverlay};
        display: grid;
        align-content: center;
        justify-content: center;
    `
)

export const StyledLinkContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 16px;
    text-align: center;
`
export const StyledLinkBox = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 16px;
    & svg {
        cursor: pointer;
    }
    /* flex-direction: column; */
    /* gap: 8px; */
    /* flex-direction: column; */
    /* gap: 16px; */
    /* text-align: center; */
`

export const StyledTitleBox = styled.div`
    overflow: auto;
`
