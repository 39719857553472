import styled, {DefaultTheme, css} from 'styled-components'

export const StyledSuccessStepRoot = styled.div`
    ${({theme: {spacing, mediaQueries, sizing}, vh}: {theme: DefaultTheme; vh?: number}) => css`
        display: grid;
        align-items: center;
        justify-content: space-between;
        grid-template-rows: auto 0fr;
        width: 100%;
        max-width: 600px;

        margin: 0 auto;
        text-align: center;
        height: calc(${vh}px - (${sizing.headerMobileHeight}));

        & > div {
            display: flex;
            flex-direction: column;
            gap: ${spacing * 2}px;
        }

        ${mediaQueries.m} {
            padding: 0px ${spacing * 2}px;
            justify-content: center;
            height: 100%;
        }
    `}
`

export const StyledAnimation = styled.h3`
    ${({theme: {spacing, mediaQueries}}) => css`
        margin: 0 auto;
        height: 100%;
        width: 100%;
        max-height: 110px;
        max-width: 110px;

        ${mediaQueries.m} {
            margin: ${spacing * 3}px auto ${spacing}px;
            max-height: 150px;
            max-width: 150px;
        }
    `}
`

export const StyledTitle = styled.p`
    ${({theme: {typography, mediaQueries}}) => css`
        ${typography.textXXl}

        ${mediaQueries.m} {
            ${typography.displayMd}
        }
    `}
`

export const StyledSubtitle = styled.span`
    ${({theme: {typography, mediaQueries}}) => css`
        font-weight: 500;

        ${mediaQueries.m} {
            ${typography.textMd};
        }
    `}
`

export const StyledDescription = styled.div`
    ${({theme: {palette}}) => css`
        color: ${palette.neutral['700']};
    `}
`
