import dayjs, {convertToDayjs, formatDateWithUTC} from '@/dayjs'
import {GuestType} from '@/features/authentication/types.ts'
import {FC} from 'react'
import {useTranslation} from 'react-i18next'
import ConfettiAnimation from '../../confetti-animation/ConfettiAnimation'
import {CountdownTimer} from './components/countdown-timer/CountdownTimer'
import {HeaderBannerContainer} from './style'
import {useRootStore} from '@/store'
import {selectGetUltimateStatusModal} from '@/store/selectors'
import {LocalStorageManager} from '@/utilities/localStorage'

type HeaderBannerProps = {
    user: GuestType
}

export const HeaderBanner: FC<HeaderBannerProps> = ({user}) => {
    const {t} = useTranslation()
    const {openModal: openGetUltimateStatusModal} = useRootStore(selectGetUltimateStatusModal)
    const ultimateOffer = user?.type_attributes?.ultimate_offer
    const cashBackOffer = user?.type_attributes?.cashback_offer

    // check if the leader canceled the ultimate offer in the modal
    const isRefusedUltimateStatus = LocalStorageManager.refusedUltimateStatus.get()
    const isUltimateStatusOfferAvailable =
        !!ultimateOffer?.is_ultimate_status_offer_available && !isRefusedUltimateStatus
    const isCashbackOfferAvailable = !!cashBackOffer?.is_available_cashback_offer

    const isBothOffersAvailable = isUltimateStatusOfferAvailable && isCashbackOfferAvailable

    const isShowBanner: boolean = isUltimateStatusOfferAvailable || isCashbackOfferAvailable || isBothOffersAvailable

    const offerEndDateLocal =
        (isUltimateStatusOfferAvailable &&
            ultimateOffer?.end_date &&
            formatDateWithUTC(ultimateOffer.end_date, 'YYYY-MM-DD HH:mm:ss')) ||
        (isCashbackOfferAvailable &&
            cashBackOffer?.end_date &&
            formatDateWithUTC(cashBackOffer.end_date, 'YYYY-MM-DD HH:mm:ss'))

    const isExpiredTime = (offerEndDateLocal && convertToDayjs(offerEndDateLocal).isBefore(dayjs())) || false

    if (!offerEndDateLocal || !isShowBanner || isExpiredTime) return <></>

    return (
        <HeaderBannerContainer
            id="headerTopBanner"
            onClick={() => isUltimateStatusOfferAvailable && openGetUltimateStatusModal({})}
            isUltimateStatusOfferAvailable={isUltimateStatusOfferAvailable}
        >
            <ConfettiAnimation />
            <CountdownTimer targetDate={offerEndDateLocal} showDays={false} />
            <span>
                {isBothOffersAvailable && t('promotion_banners:ultimate_status_top_banner')}
                {isCashbackOfferAvailable &&
                    !isUltimateStatusOfferAvailable &&
                    t('promotion_banners:cashback_top_banner')}
                {!isCashbackOfferAvailable &&
                    isUltimateStatusOfferAvailable &&
                    t('promotion_banners:ultimate_status_top_banner')}
            </span>
        </HeaderBannerContainer>
    )
}
