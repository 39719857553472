import { MicromorgiIcon } from '@assets/icons/icons.tsx'
import { StyledCardAmountToSelect } from '@components/ui/card-amount-to-select/style.ts'

type CardAmountToSelectProps = {
    amount: SelectedAmount
    selected: boolean
    onClick: (amount: SelectedAmount) => void
    disabled?: boolean
}

export type SelectedAmount = {
    id: number
    amount: number
}
export const CardAmountToSelect = ({
    amount,
    selected,
    onClick,
    disabled,
}: CardAmountToSelectProps) => {
    return (
        <StyledCardAmountToSelect
            gap={1}
            align={'center'}
            justify={'center'}
            padding={1}
            onClick={() => onClick(amount)}
            disabled={disabled}
            isSelected={selected}
        >
            <MicromorgiIcon width={40} height={40} />
            <span>{amount.amount}</span>
        </StyledCardAmountToSelect>
    )
}
