import styled, {css} from 'styled-components'

export const StyledBody = styled.div`
    ${({theme: {spacing}}) => css`
        width: 100%;
        height: 100%;
        padding: ${spacing * 2}px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        gap: ${spacing * 3}px;
        overflow-y: hidden;

        & button {
            width: 100%;
        }
    `}
`

export const StyledMediaWrapper = styled.div`
    ${({theme: {spacing, mediaQueries}}) => css`
        gap: ${spacing}px;
        display: grid;
        max-height: 400px;
        overflow-x: hidden;
        overflow-y: auto;
        margin-bottom: ${spacing * 1.5}px;
        grid-template-columns: 1fr 1fr 1fr;

        ${mediaQueries.l} {
            gap: ${spacing * 3.75}px;
            grid-template-columns: 1fr 1fr;
        }
    `}
`
