import styled, {css} from 'styled-components'

export const StyledGoalPageWrapper = styled.div`
    ${({theme: {mediaQueries}}) => css`
        display: grid;
        height: 100%;
        width: 100%;
        margin: 0 auto;
        max-width: 650px;

        ${mediaQueries.l} {
            max-width: 868px;
        }
    `}
`

export const StyledDescription = styled.div`
    ${({theme: {typography, palette, spacing}}) => css`
        ${typography.textMd}
        max-width: 650px;
        width: 100%;
        margin: 0 auto;
        padding-bottom: ${spacing * 2}px;

        & div {
            font-weight: 700;
            margin: ${spacing * 2}px 0;
        }

        & span {
            color: ${palette.primary['700']};
        }
    `}
`

export const StyledTranslationSwitch = styled.div`
    ${({theme: {spacing}}) => css`
        padding-top: ${spacing * 2}px;
        width: 100%;
        margin: 0 auto;
        max-width: 650px;
    `}
`
