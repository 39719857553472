import axios from '@/clientAPI.ts'
import {Message} from '@/features/common/types'
import * as types from '../types'
import {AxiosResponse} from 'axios'

export const MICROMORGI_API = {
    getMicromorgiPackages: async (): Promise<types.MicromorgiPackages> => {
        const {data} = await axios.get(`/micromorgi-packages`)
        return data
    },
    buyMicromorgiPackage: async ({
        packageId,
        campaignId,
        only_links
    }: types.BuyMicromorgiPackageParams): Promise<AxiosResponse<types.PaymentUrls | Message>> => {
        console.log('request', campaignId)
        const data = await axios.post(`/micromorgi-packages/${packageId}/buy`, {
            campaign_id: campaignId,
            only_links
        })
        return data
    },
    sendMicromorgi: async ({
        hostID,
        amount,
        animation_id,
        campaign_id
    }: types.SendMicromorgiParams): Promise<Message> => {
        const {data} = await axios.post(`/rookies/${hostID}/micromorgi/${amount}`, {
            params: {
                animation_id,
                campaign_id
            },
            campaign_id
        })
        return data
    },
    getMicromorgiTransactions: async (
        params: types.GetMicromorgiTransactionsParams
    ): Promise<types.TransactionPage> => {
        const {data} = await axios.get('/transactions/micromorgi', {params})
        return data
    },
    sendRequestToGetUltimateStatus: async (): Promise<types.PaymentUrls | Message> => {
        const {data} = await axios.post(`/offer/ultimate-offer/buy`)
        return data
    }
}
