import {useMutation, useQueryClient} from '@tanstack/react-query'
import {FEED_API} from '../services/feed.http'
import {MUTATION_KEYS} from './keys'
import {QUERY_KEYS as AUTHENTICATION_QUERY_KEYS} from '@/features/authentication/queries/keys'

export const useClearActiveFilters = (onSuccess?: () => void) => {
    const queryClient = useQueryClient()

    return useMutation({
        mutationKey: [MUTATION_KEYS.clear_filters],
        mutationFn: () => FEED_API.clearActiveFilters(),
        onSuccess: () => {
            onSuccess?.()
            queryClient.invalidateQueries({queryKey: [AUTHENTICATION_QUERY_KEYS.user]})
        }
    })
}
