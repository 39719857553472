import React from 'react'
import {StyledListItems} from './style'

interface MenuProps {
    isOpen: boolean
    children: React.ReactNode
}

const getTransStyle = (i: number, isOpen: boolean) => {
    const transDelay = 310
    const transStep = 50
    return {
        opacity: isOpen ? 1 : 0,
        transform: isOpen ? 'translateY(0)' : 'translateY(-20px)',
        transition: `opacity ${transDelay}ms ${transDelay + i * transStep}ms, transform ${transDelay}ms ${
            transDelay + i * transStep
        }ms`
    }
}

const Menu: React.FC<MenuProps> = ({isOpen, children}) => {
    return (
        <StyledListItems>
            {React.Children.toArray(children).map((child, i) => (
                <div key={i} style={getTransStyle(i, isOpen)}>
                    {child}
                </div>
            ))}
        </StyledListItems>
    )
}

export default Menu
