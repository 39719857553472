import {StarredMessageYellowIcon} from '@/assets/icons/icons'
import {TextareaWithBannedElementsTrap} from '@/features/generated-content-security/components/text-area-with-word-trap/TextareaWithBannedElementsTrap'
import styled, {css} from 'styled-components'

export const StyledSubTitle = styled.div`
    ${({theme: {typography, spacing}}) => css`
        ${typography.textMd}
        line-height: 24px;
        margin-bottom: ${spacing}px;
        text-align: center;
        padding: 0 ${spacing * 2}px;
    `}
`
export const StyledInspirationsStarredMessageModalContainer = styled.div`
    ${({theme: {spacing}}) => css`
        display: flex;
        padding: 0 ${spacing}px ${spacing * 3}px;
        flex-direction: column;
        justify-content: space-between;
    `}
`
export const StyledSendMessageContainer = styled.div`
    ${({theme: {mediaQueries, spacing}}) => css`
        background: rgb(134, 73, 209);
        display: grid;
        align-items: center;
        justify-items: center;
        grid-gap: ${spacing / 2}px;
        border-radius: 20px;
        padding: ${spacing * 3}px ${spacing}px ${spacing * 2}px;
        ${mediaQueries.m} {
            padding: ${spacing * 3}px ${spacing * 2}px ${spacing * 2.5}px;
        }
    `}
`
export const StyledSendMessageAvatar = styled.div`
    ${({theme: {palette, mediaQueries, spacing}}) => css`
        border-radius: 20px;
        width: min-content;
        height: min-content;
        display: flex;
        align-items: center;
        flex-direction: column;
        border: 1px solid ${palette.secondary[800]};
        border-radius: 9px;
        position: relative;
        margin-bottom: ${spacing * 2}px;

        ${mediaQueries.m} {
            margin-bottom: ${spacing * 2.75}px;
        }
    `}
`
export const StyledStarredMessageYellowIcon = styled(StarredMessageYellowIcon)`
    ${({theme: {mediaQueries}}) => css`
        position: absolute;
        bottom: -17px;

        ${mediaQueries.m} {
            bottom: -22px;
        }
    `}
`
export const StyledTextareaWithBannedElementsTrap = styled(TextareaWithBannedElementsTrap)`
    ${({theme: {typography}}) => css`
        & > div {
            ${typography.textMd}
            height: 108px;
            min-width: initial;

            &::placeholder {
                color: rgba(34, 47, 62, 0.7);
            }
        }
    `}
`

export const StyledInspirationButton = styled.div`
    ${({theme: {palette, spacing, typography}}) => css`
        ${typography.textMd}
        color: ${palette.primary[700]};
        cursor: pointer;
        margin: ${spacing * 2.25}px 0;
        line-height: ${spacing * 3}px;
        text-decoration-line: underline;
        align-self: center;
    `}
`
