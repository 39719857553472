import styled, {DefaultTheme, css} from 'styled-components'

export const StyledUploadAreaRoot = styled.div`
    ${({theme: {palette, mediaQueries}, isUploading}: {theme: DefaultTheme; isUploading?: boolean}) => css`
        width: 102px;
        border: 1px dashed;
        cursor: pointer;
        height: 138px;
        display: flex;
        align-items: center;
        border-radius: 10px;
        justify-content: center;
        ${isUploading &&
        css`
            background-color: ${palette.neutral['750']};
        `}

        & span {
            font-weight: 700;
            text-align: center;
        }

        ${mediaQueries.l} {
            width: 150px;
            height: 200px;
        }
    `}
`

export const StyledProgress = styled.div`
    ${({theme: {spacing}}) => css`
        display: flex;
        flex-direction: column;
        gap: ${spacing}px;
        align-items: center;
    `}
`

export const StyledPercentage = styled.div`
    ${({theme: {palette}}) => css`
        display: flex;
        color: ${palette.neutral.white};
    `}
`
