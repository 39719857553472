import styled, {DefaultTheme, css} from 'styled-components'

export const StyledGendersWrapper = styled.div`
    ${({theme: {spacing, flexUtils}}) => css`
        ${flexUtils.centered};
        margin: ${spacing * 2}px 0px;
        flex-wrap: wrap;
        gap: ${spacing / 2}px;
    `}
`

export const StyledGenderButton = styled.div`
    ${({theme: {spacing, palette}, isSelected}: {theme: DefaultTheme; isSelected: boolean}) => css`
        border: 1px solid ${palette.primary['900']};
        cursor: pointer;
        display: flex;
        padding: ${spacing}px ${spacing * 2}px;
        min-width: 80px;
        align-items: center;
        font-weight: 700;
        border-radius: 100px;
        justify-content: center;

        ${isSelected
            ? css`
                  color: ${palette.neutral.white};
                  background: ${palette.primary['900']};
              `
            : css`
                  color: ${palette.primary['900']};
                  background: ${palette.neutral.white};
              `}
    `}
`
