import {MengageConnectorType} from '@/features/moengage/types'
import React, {ReactNode} from 'react'
import {getCorrectStringWithTranslation} from '@/utilities/helpers'
import {
    StyledCTA,
    StyledContent,
    StyledDescription,
    StyledHeader,
    StyledHeaderIcon,
    StyledPopup,
    StyledRoot
} from './style'

export type PopupConnectorProps = {
    children?: ReactNode
    CtaComponent?: () => JSX.Element
    closeHandler: () => void
}

export const PopupConnector: React.FC<PopupConnectorProps & MengageConnectorType> = ({
    CtaComponent,
    children,
    image,
    header_title,
    description,
    element_color,
    header_title_color,
    description_color,
    closeHandler
}) => {
    const isTranslationEnabled = false

    return (
        <StyledPopup onClose={closeHandler} elementColor={element_color}>
            {!!image && (
                <StyledHeaderIcon>
                    <img src={image} alt="" />
                </StyledHeaderIcon>
            )}
            <StyledRoot>
                {!!header_title && (
                    <StyledHeader titleColor={header_title_color}>
                        {getCorrectStringWithTranslation(header_title, isTranslationEnabled)}
                    </StyledHeader>
                )}
                {!!description && (
                    <StyledDescription descriptionColor={description_color}>
                        {getCorrectStringWithTranslation(description, isTranslationEnabled)}
                    </StyledDescription>
                )}
                {/* HERE RENDER TEMPLATE */}
                <StyledContent>{children}</StyledContent>
                {/* HERE RENDER CTA */}
                {!!CtaComponent && <StyledCTA>{<CtaComponent />}</StyledCTA>}
            </StyledRoot>
        </StyledPopup>
    )
}

export default PopupConnector

export const model = (connectorData: MengageConnectorType | null = null) => {
    if (!connectorData) return null
    return {
        image: connectorData['image'],
        element_color: connectorData['element_color'],
        header_title: connectorData['header_title'],
        header_title_color: connectorData['header_title_color'],
        description: connectorData['description'],
        description_color: connectorData['description_color'],
        page_to_present: connectorData['page_to_present'],
        cta_button_text: connectorData['cta_button_text'],
        cta_button_color: connectorData['cta_button_color'],
        cta_url: connectorData['cta_url'],
        timer: connectorData['timer'],
        notification: connectorData['notification']
    }
}
