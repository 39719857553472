import styled, {css} from 'styled-components'

export const StyledContentBox = styled.div`
    ${({theme: {mediaQueries}}) => css`
        scrollbar-width: auto;
        max-height: 250px;
        overflow: auto;
        ${mediaQueries.s} {
            max-height: 100%;
        }
        ${mediaQueries.m} {
            scrollbar-width: auto;
            max-height: 250px;
            overflow: auto;
        }
    `}
`

export const StyledContentTitle = styled.span`
    ${({theme: {typography}}) => css`
        ${typography.textMd};
        font-weight: 700;
    `}
`
