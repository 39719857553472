import {Spinner} from '@/components/ui/spinner/Spinner'
import {useGetMySurprises} from '@/features/my-surprises/queries/useGetMySurprises'
import {useState} from 'react'
import {useTranslation} from 'react-i18next'
import {
    StyledContainer,
    StyledEmptySection,
    StyledHeaderBox,
    StyledInfoBox,
    StyledInfoCircle,
    StyledNameBox,
    StyledPreviewBox,
    StyledPreviewContainer,
    StyledShowMore
} from './style'
import {useMe} from '@/features/authentication/queries/useMe'
import mySurprisesIcon from '@assets/images/my-surprises/mySurprises.png'
import {SurpriseTooltip} from '@/features/my-surprises/components/surprise-tooltip/SurpriseTooltip'
import {HostSurprisePreview} from '@/features/my-surprises/components/host-surprise-preview/HostSurprisePreview'

const STEP_SHOW_MORE = 4

export const ProfileSurpriseSection = () => {
    const {t} = useTranslation()
    const {data: user} = useMe()
    const {data: mySurprises, isLoading} = useGetMySurprises({limit: 20})

    const [visibleItems, setVisibleItems] = useState<number>(STEP_SHOW_MORE)
    const [allItemsLoaded, setAllItemsLoaded] = useState<boolean>(false)

    const handleShowMore = () => {
        const newVisibleItems = visibleItems + STEP_SHOW_MORE
        setVisibleItems(newVisibleItems)
        if (newVisibleItems >= (mySurprises?.length || 0)) {
            setAllItemsLoaded(true)
        }
    }

    const handleHideAll = () => {
        setVisibleItems(STEP_SHOW_MORE)
        setAllItemsLoaded(false)
    }

    if (isLoading) return <Spinner />

    return (
        <>
            <StyledHeaderBox>
                <StyledNameBox>{t('rookie_my_profile:host_surprises', {HOST_NAME: user?.username})}</StyledNameBox>
                <div>
                    <SurpriseTooltip
                        trigger={<StyledInfoCircle />}
                        title={'rookie_profile_preview:host_surprises:what_is_surprise_title'}
                        text={'rookie_profile_preview:host_surprises:what_is_surprise_text'}
                    />
                </div>
            </StyledHeaderBox>
            <StyledContainer>
                <StyledInfoBox>
                    <SurpriseTooltip
                        trigger={<p>{t('rookie_profile_preview:host_surprises:what_get_title')}</p>}
                        title={'rookie_profile_preview:host_surprises:what_get_title'}
                        text={'rookie_profile_preview:host_surprises:what_get_text'}
                    />
                </StyledInfoBox>
                {mySurprises && mySurprises.length > 0 ? (
                    <>
                        <StyledPreviewBox>
                            {mySurprises.slice(0, visibleItems).map(surprise => (
                                <StyledPreviewContainer key={surprise.id}>
                                    <img src={mySurprisesIcon} />
                                    <HostSurprisePreview surprise={surprise} />
                                </StyledPreviewContainer>
                            ))}
                        </StyledPreviewBox>
                        {mySurprises.length > STEP_SHOW_MORE && (
                            <StyledShowMore onClick={allItemsLoaded ? handleHideAll : handleShowMore}>
                                {allItemsLoaded
                                    ? t('common:see_less')
                                    : t('rookie_profile_preview:host_surprises:view_surprises_button', {
                                          SURPRISES_SHOW: STEP_SHOW_MORE
                                      })}
                            </StyledShowMore>
                        )}
                    </>
                ) : (
                    <StyledEmptySection>
                        {t('rookie_my_profile:has_no_surprises', {USER: user?.username})}
                    </StyledEmptySection>
                )}
            </StyledContainer>
        </>
    )
}
