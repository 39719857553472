import Pusher from 'pusher-js/types/src/core/pusher'
import {ReactNode, createContext} from 'react'

export const PusherContext = createContext<{pusher: Pusher} | null>(null)

const PusherProvider = ({children, pusher}: {children: ReactNode; pusher: Pusher}) => {
    return <PusherContext.Provider value={{pusher}}>{children}</PusherContext.Provider>
}

export default PusherProvider
