import {TooltipBase} from '@/components/ui/tooltip/Tooltip'
import styled, {css} from 'styled-components'

export const StyledTootip = styled(TooltipBase)(
    ({theme: {spacing, palette}}) => css`
        border-radius: ${spacing}px;
        width: 147px;
        padding: ${spacing}px ${spacing / 2}px;
        text-align: center;
        line-height: 16px;
        background-color: ${palette.neutral.white};
        box-shadow:
            0 0 20px 4px rgba(154, 161, 177, 0.15),
            0 4px 80px -8px rgba(36, 40, 47, 0.25),
            0 4px 4px -2px rgba(91, 94, 105, 0.15);

        & span {
            text-decoration: underline;
            cursor: pointer;
        }

        & svg {
            width: ${spacing * 2}px;
            height: ${spacing * 2}px;
        }
    `
)

export const StyledTriggerWrapper = styled.div(
    ({theme: {spacing, mediaQueries}}) => css`
        width: ${spacing * 3}px;
        height: ${spacing * 3}px;

        ${mediaQueries.l} {
            width: ${spacing * 4}px;
            height: ${spacing * 4}px;
        }

        & > svg {
            width: ${spacing * 3}px;
            height: ${spacing * 3}px;

            ${mediaQueries.l} {
                width: ${spacing * 4}px;
                height: ${spacing * 4}px;
            }
        }
    `
)
