import {useTranslation} from 'react-i18next'
import {GoalSuccessStep} from '../goal-success-step/GoalSuccessStep'
import {GoalType} from '../../types'
import {calculatePercentage} from '@/utilities/helpers'
import {StyledButtonWrapper, StyledDescription, StyledDivider} from './style'
import {Button} from '@/components/ui/button/Button'
import {useState} from 'react'
import {ShareGoalModal} from '../share-goal-modal/ShareGoalModal'

type SuccessDonationGoalProps = {
    onClose: () => void
    goal?: GoalType
    isFirstDonation: boolean
}
export const SuccessDonationGoal = ({onClose, goal, isFirstDonation}: SuccessDonationGoalProps) => {
    const {t} = useTranslation()
    const [isOpenShareGoalModal, setIsOpenShareGoalModal] = useState(false)
    const percentage =
        !!goal?.donations_count && !!goal?.target_amount
            ? calculatePercentage(+goal.donations_count, +goal?.target_amount)
            : 0

    return (
        <GoalSuccessStep
            title={t('donation_success_page:title')}
            description={
                <StyledDescription>
                    <p>
                        {isFirstDonation
                            ? t('donation_success_page:subtitle', {
                                  USERNAME: goal?.rookie?.full_name,
                                  context: goal?.rookie?.gender?.key_name
                              })
                            : t('donation_success_page:subtitle_second_support', {
                                  USERNAME: goal?.rookie?.full_name,
                                  context: goal?.rookie?.gender?.key_name
                              })}
                    </p>
                    {percentage < 75 && <p>{t('donation_success_page:description')}</p>}
                </StyledDescription>
            }
        >
            <div>
                <StyledDivider />

                <StyledButtonWrapper>
                    <Button onClick={() => setIsOpenShareGoalModal(true)}>
                        {t('donation_success_page:button_share')}
                    </Button>

                    <Button variant="outlined" onClick={onClose}>
                        {t('donation_success_page:button_close')}
                    </Button>
                </StyledButtonWrapper>
            </div>

            {isOpenShareGoalModal && goal && (
                <ShareGoalModal goal={goal} onClose={() => setIsOpenShareGoalModal(false)} />
            )}
        </GoalSuccessStep>
    )
}
