import styled, {DefaultTheme, css} from 'styled-components'

export const StyledOverlay = styled.div(
    ({theme: {zIndex}}) => css`
        position: fixed;
        inset: 0;
        background-color: rgba(0, 0, 0, 0.9);
        z-index: ${zIndex.modalOverlay};
    `
)

export const StyledHeader = styled.div(
    ({theme: {zIndex, spacing, flexUtils}}) => css`
        ${flexUtils.columnSpaceBetween}
        margin: ${spacing * 3}px;
        padding: ${spacing / 2}px;
        height: 100%;
        max-height: 40px;
        position: absolute;
        inset: 0;
        z-index: ${zIndex.modal};
        gap: ${spacing}px;
    `
)

export const StyledCloseIcon = styled.div(
    ({theme: {palette, spacing}}) => css`
        display: flex;
        padding: ${spacing / 2}px;
        background-color: ${palette.primary[200]};
        border-radius: 4px;
        cursor: pointer;
    `
)
export const StyledCounter = styled.div(
    ({theme: {palette, typography}}) => css`
        ${typography.textMd}
        color: ${palette.neutral[500]};
    `
)

export const styledCarousel = () => css`
    max-height: 100vh;
    .splide__slide {
        width: 100vw !important;
    }
    .splide__track {
        display: inline;
    }
`

export const styledArrows = (theme: DefaultTheme) => css`
    width: 100%;
    ${theme.flexUtils.columnSpaceBetween}
    position: absolute;
    top: 50%;
    & button {
        background-color: transparent;
        margin: ${theme.spacing / 2}px;
        box-shadow: none;
        padding: ${theme.spacing * 2}px ${theme.spacing * 3}px;
        width: auto;
        &:disabled {
            visibility: hidden;
        }
        & svg {
            width: 11px;
            height: 11px;
        }
    }
    & button:nth-child(1) {
        & svg {
            transform: rotate(180deg);
        }
    }
`
