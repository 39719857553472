import {Modal} from '@/components/ui/modal/Modal'
import styled, {DefaultTheme, css} from 'styled-components'

export const StyledPopup = styled(Modal)`
    ${({theme: {spacing, mediaQueries}, elementColor}: {theme: DefaultTheme; elementColor?: string}) => css`
        background-color: ${elementColor};
        padding: ${spacing * 2}px;
        ${mediaQueries.m} {
            width: 500px;
        }
    `}
`

export const StyledHeaderIcon = styled.div`
    ${({theme: {mediaQueries}}) => css`
        position: absolute;
        top: -80px;
        left: 120px;

        ${mediaQueries.m} {
            left: 35%;
        }

        & > img {
            max-width: 128px;
        }
    `}
`

export const StyledHeader = styled.div`
    ${({theme: {spacing}, titleColor}: {theme: DefaultTheme; titleColor?: string}) => css`
        font-size: ${spacing * 3}px;
        font-weight: 700;
        line-height: 24px;
        text-align: center;
        color: ${titleColor};
    `}
`

export const StyledDescription = styled.div`
    ${({theme: {spacing}, descriptionColor}: {theme: DefaultTheme; descriptionColor?: string}) => css`
        font-size: ${({theme}) => theme.spacing * 2}px;
        font-weight: 400;
        line-height: 150%;
        color: ${descriptionColor};
        text-align: center;
        margin: ${spacing * 2}px 0px;
        padding: 0px ${spacing * 2}px;
    `}
`

export const StyledRoot = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    overflow: auto;
`

export const StyledContent = styled.div`
    ${({theme: {spacing}}) => css`
        display: flex;
        justify-content: center;
        /* padding: 0px ${spacing * 2}px; */
    `}
`

export const StyledCTA = styled.div`
    ${({theme: {spacing}}: {theme: DefaultTheme}) => css`
        margin: ${spacing * 3}px 0px 0px;
        width: 100%;
        padding: ${spacing * 2}px 0px;
    `}
`
