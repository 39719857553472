import {Modal} from '@/components/ui/modal/Modal'
import {StyledAnimation, StyledBody, StyledButton, StyledText} from './style'
import {useTranslation} from 'react-i18next'
import {Button} from '@/components/ui/button/Button'
import Check from '@assets/animations/Alert-1.json'
import {Animation} from '@/components/ui/animation/Animation'
import {Divider} from '@/components/ui/divider/Divider'
import {useTheme} from 'styled-components'
import {useWithdrawMicroMorgisGoal} from '../../queries/useWithdrawMicroMorgisGoal'
import {Spinner} from '@/components/ui/spinner/Spinner'
import {useHandleError} from '@/hooks/useHandleError'

export const WithdrawModal = ({onClose, goalId}: {onClose: () => void; goalId?: string}) => {
    const {t} = useTranslation()
    const theme = useTheme()

    const {mutate, isPending, isError, error} = useWithdrawMicroMorgisGoal(`${goalId}`, onClose)

    useHandleError({isError, error})

    const handleWithdrawClick = () => {
        mutate()
    }

    return (
        <Modal onClose={onClose} maxHeight={'557px'} title={t('withdram_micrimorgis_popup:title')}>
            {isPending && <Spinner />}
            <StyledBody>
                <div>
                    <StyledAnimation>
                        <Animation animationData={Check} />
                    </StyledAnimation>
                </div>

                <StyledButton>
                    <StyledText>{t('withdram_micrimorgis_popup:description')}</StyledText>
                    <Divider topSpacing={theme.spacing * 2} bottomSpacing={theme.spacing * 2} />
                    <Button onClick={handleWithdrawClick}>{t('withdram_micrimorgis_popup:cta')}</Button>
                    <Button variant="outlined" onClick={onClose}>
                        {t('withdram_micrimorgis_popup:close_button')}
                    </Button>
                </StyledButton>
            </StyledBody>
        </Modal>
    )
}
