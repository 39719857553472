import {StyledSmall, StyledSmallError} from '@components/ui/input-help-text/style'
import {DefaultNamespace} from 'i18next'
import {ReactNode} from 'react'

interface InputHelpTextProps {
    error?: string | DefaultNamespace | boolean
    helpText?: string | DefaultNamespace | ReactNode
}
export const InputHelpText = ({error, helpText}: InputHelpTextProps) => {
    return (
        <>
            {error && <StyledSmallError>{error}</StyledSmallError>}
            {helpText && !error && <StyledSmall>{helpText}</StyledSmall>}
        </>
    )
}

InputHelpText.displayName = 'InputHelpText'
