import {typography} from '@/theme/typography'
import styled, {DefaultTheme, css} from 'styled-components'

export const StyledWrapper = styled.div`
    ${({theme: {mediaQueries, spacing}, vh}: {theme: DefaultTheme; vh: number}) => css`
        position: relative;
        height: ${vh}px;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding: 0px ${spacing * 1.5}px;

        ${mediaQueries.m} {
            max-width: 530px;
            padding: 0px ${spacing * 2.5}px;
        }
    `}
`

export const StyledHeader = styled.div`
    ${({theme: {spacing}}) => css`
        display: grid;
        gap: ${spacing * 2}px;
    `}
`

export const StyledTitleWrapper = styled.div`
    ${({theme: {spacing, mediaQueries}}) => css`
        display: grid;
        grid-template-columns: 0fr 1fr;
        align-items: center;

        & h1 {
            margin: 0px;
            padding: ${spacing}px 0px;
            text-align: center;
            font-weight: 500;
            letter-spacing: -0.05em;
            ${typography.textXXl}

            ${mediaQueries.m} {
                ${typography.displayMd}
            }
        }
        & svg {
            cursor: pointer;
        }
    `}
`

export const StyledContent = styled.div`
    ${({theme: {spacing, mediaQueries}}) => css`
        height: 100%;
        display: grid;
        overflow-y: auto;

        ${mediaQueries.m} {
            padding-right: ${spacing * 2.5}px;
        }
    `}
`

export const StyledButtonSection = styled.div`
    ${({theme: {spacing, mediaQueries}}) => css`
        width: 100%;
        padding-top: ${spacing * 2}px;

        ${mediaQueries.m} {
            & button {
                width: 80%;
                margin: 0 auto;
            }
        }
    `}
`

export const StyledClearAll = styled.div`
    ${({theme: {spacing, palette}}) => css`
        margin: 0 auto;
        text-align: center;
        padding: ${spacing * 2}px;
        color: ${palette.primary['700']};
        cursor: pointer;
        ${typography.textMd};
        font-weight: 700;
        transition: opacity 0.5s;
        &[aria-disabled='true'] {
            opacity: 0.6;
            cursor: default;
        }
    `}
`

export const StyledTabTitle = styled.div`
    ${({theme: {spacing, typography}}) => css`
        ${typography.textXXl};
        margin: ${spacing * 1.25}px 0 0;
        text-align: center;
        line-height: 27px;
    `}
`
