export enum MessageTypeE {
    MICROMORGI_TRANSACTION = 'micromorgi_transaction',
    GIFT_TRANSACTION = 'gift_transaction',
    IMAGE = 'image',
    VIDEO = 'video',
    PHOTO = 'photo',
    //TODO: when backend is ready, change to 'paid_message'
    PAID_MESSAGE = 'text',
    ALREADY_PAID_MESSAGE = 'already_paid_message',
    GOAL = 'goal',
    TRANSACTION_GOAL = 'micromorgi_transaction_goal',
    PAID_MEDIA_TRANSACTION = 'paid_media_transaction',
    SYSTEM_BROADCAST = 'system_broadcast',
    FIRST_TEXT = 'text',
    GOAL_TEASER = 'goal_teaser',
    GIF = 'gif',
    TEXT = 'message',
    PAID_MEDIA_TEXT = 'paid_media_text',
    MEGA_GIFT_TRANSACTION = 'mega_gift',
    DELETED = 'deleted',
    PAID_CONNECTION_MESSAGE = 'paid_connection_message',
    PAUSED_SUBSCRIPTION = 'subscription_paused'
}

export const ACCAPTABLE_IMAGE_TYPES = ['jpeg', 'jpg', 'png']
export const ACCAPTABLE_VIDEO_TYPES = ['mp4', 'mpg', 'avi', 'mov', 'quicktime']
export const ACCAPTABLE_FILE_TYPE = [...ACCAPTABLE_IMAGE_TYPES, ...ACCAPTABLE_VIDEO_TYPES]

export const MESSAGE_CENTER_TABS_IDS = {
    active: 'active',
    groups: 'groups',
    inactive: 'inactive'
}

export const MESSAGE_CENTER_TABS_HOST = [
    {
        value: MESSAGE_CENTER_TABS_IDS.active,
        name: 'message_center:tab_active'
    },
    {
        value: MESSAGE_CENTER_TABS_IDS.groups,
        name: 'message_center:tab_goal_groups'
    },
    {
        value: MESSAGE_CENTER_TABS_IDS.inactive,
        name: 'message_center:tab_inactive'
    }
]

export const MESSAGE_CENTER_TABS_GUEST = [
    {
        value: MESSAGE_CENTER_TABS_IDS.active,
        name: 'message_center:tab_active'
    },
    {
        value: MESSAGE_CENTER_TABS_IDS.inactive,
        name: 'message_center:tab_inactive'
    }
]

export const CHAT_TYPE_BROADCAST = 'broadcast'
export const CHAT_TYPE_GROUP = 'group'

export const MESSAGE_ICON_TYPE_BROADCAST = 'broadcast'
export const MESSAGE_ICON_TYPE_GROUP = 'group'
