import {useMe} from '@/features/authentication/queries/useMe'
import {useRootStore} from '@/store'
import {selectGlobalTranslateStore} from '@/store/selectors'
import {APP_LANGUAGES, FALLBACK_LANGUAGE} from '@/translations/i18n'
import {uniqueId} from 'lodash'
import {FC, useEffect} from 'react'
import {useGetLanguageOfMessage} from '../../queries/useGetLanguageOfMessage'
import {useGetTranslation} from '../../queries/useGetTranslation'
import {SwitchLanguage} from '../translate-switch/TranslateSwitch'
import {DotsLoader} from '@/components/ui/dotsLoader/DotsLoader'

// This component is a shell for the text. It uses the current translation settings
// in useGlobalTranslate. That is, if global translation is enabled, this component
// will understand what language the text is written in and translate it to the selected
// one in the global repository and show it.

// In case you need to bind not to the global language, but to the language that is selected
// as the main language for the application, you must use prop(isUseMainAppLanguage).
// onGetNativeLanguageOfText is a function that returns the juice of the language in which the text thrown into the content is written

type TranslateProps = {
    content: string
    isUseMainAppLanguage?: boolean
    onGetNativeLanguageOfText?: (language: string) => void
    nativeLanguage?: string
}
export const Translate: FC<TranslateProps> = ({
    content,
    onGetNativeLanguageOfText,
    isUseMainAppLanguage = false,
    nativeLanguage
}) => {
    const {data: user} = useMe()
    const {currentLanguage, translateToggle} = useRootStore(selectGlobalTranslateStore)
    const {data: languageData} = useGetLanguageOfMessage({text: nativeLanguage ? null : content})
    const languageOfText = nativeLanguage?.toLowerCase() || languageData?.language?.toLowerCase()
    const defaultLanguage: SwitchLanguage = APP_LANGUAGES.reduce(
        (acc, item) =>
            item.value.toUpperCase() === FALLBACK_LANGUAGE.toUpperCase()
                ? {code: item.value.toUpperCase(), name: item.label}
                : acc,
        {
            code: '',
            name: ''
        }
    )

    const {mutate, data: translate, isPending} = useGetTranslation()

    const spokenLanguages = user?.spoken_languages || []
    const hasOnlyEmojis =
        /^(?:\u00a9|\u00ae|[\u2000-\u3300]|\ud83c[\ud000-\udfff]|\ud83d[\ud000-\udfff]|\ud83e[\ud000-\udfff]|\u2764\uFE0F)+$/.test(
            content
        )

    const isTranslate =
        currentLanguage &&
        !hasOnlyEmojis &&
        spokenLanguages?.findIndex(({code}) => code.toLowerCase() === languageOfText) === -1
    const selectedLanguageCode = isUseMainAppLanguage
        ? String(defaultLanguage?.code).toLowerCase()
        : String(currentLanguage?.code).toLowerCase()

    useEffect(() => {
        if (translateToggle && isTranslate && currentLanguage && languageOfText) {
            mutate({
                target: selectedLanguageCode,
                contents: [{id: uniqueId(), text: content, language: languageOfText}]
            })
        }
    }, [isTranslate, currentLanguage, languageOfText, translateToggle])

    useEffect(
        () => {
            if (!nativeLanguage && languageOfText && onGetNativeLanguageOfText)
                onGetNativeLanguageOfText?.(languageOfText.toLowerCase())
        },
        nativeLanguage ? [] : [languageOfText]
    )
    if (isPending) return <DotsLoader />
    return <>{translateToggle && isTranslate && translate ? translate[0].text : content}</>
}
