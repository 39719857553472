import {Lens} from '@dhmk/zustand-lens'

type LogoutModalState = {
    isOpen: boolean
}

type LogoutModalActions = {
    openModal: (params: Omit<LogoutModalState, 'isOpen'>) => void
    closeModal: () => void
}

export type LogoutModal = LogoutModalState & LogoutModalActions

const initialModal: LogoutModalState = {isOpen: false}

export const createLogoutModalSlice: Lens<LogoutModal> = set => ({
    ...initialModal,
    openModal: params => set(() => ({...params, isOpen: true})),
    closeModal: () => set(() => ({...initialModal}))
})

export default createLogoutModalSlice
