import {useInfiniteQuery} from '@tanstack/react-query'
import {QUERY_KEYS} from './keys'
import {GIFS_API} from '../services/gifs.http'

export const useFetchTrendingGifs = ({limit = 10}: {limit?: number}) => {
    return useInfiniteQuery({
        queryKey: [QUERY_KEYS.gifs, limit],
        queryFn: ({pageParam = 0}) => GIFS_API.getGifs({limit, offset: +pageParam}),
        initialPageParam: '',
        getNextPageParam: ({pagination}) => (pagination?.count >= limit ? `${pagination.offset + limit}` : undefined)
    })
}
