import {Lens} from '@dhmk/zustand-lens'

type ApproachFirstModalState = {
    isOpen: boolean
    guestID: string | null
}

type ApproachFirstModalActions = {
    openModal: (params: Omit<ApproachFirstModalState, 'isOpen'>) => void
    closeModal: () => void
}

export type ApproachFirstModal = ApproachFirstModalState & ApproachFirstModalActions

const initialModal: ApproachFirstModalState = {isOpen: false, guestID: null}

const createApproachFirstModalSlice: Lens<ApproachFirstModal> = set => ({
    ...initialModal,
    openModal: params => set(() => ({...params, isOpen: true})),
    closeModal: () => set(() => ({...initialModal}))
})

export default createApproachFirstModalSlice
