import {useQuery} from '@tanstack/react-query'
import {QUERY_KEYS} from './keys'
import {RECURRING_GIFT_API} from '../services/recurringGift.http'
import {SubscriptionStatusesE} from '@/features/chat/helpers/helpers'

type useFetchSubscribedHostsProps = {
    enabled: boolean
    status: SubscriptionStatusesE
}
export const useFetchSubscribedHosts = ({enabled = true, status}: useFetchSubscribedHostsProps) => {
    return useQuery({
        queryKey: [QUERY_KEYS.subscribed_hosts, enabled, status],
        queryFn: () => RECURRING_GIFT_API.getSubscribedHosts(status),
        refetchOnMount: true,
        enabled
    })
}
