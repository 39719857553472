import {Dispatch, SetStateAction} from 'react'
import {AgeSection} from '../age-section/AgeSection'
import {GenderSection} from '../gender-section/GenderSection'
import {HostFiltersType} from '@/features/feed/types'
import {TranslationSwitchSection} from '../translation-switch-section/TranslationSwitchSection'

type AgeAndGenderFilterProps = {
    genders: HostFiltersType['genders']
    setGenders: Dispatch<SetStateAction<HostFiltersType['genders']>>
    fromAge: number
    toAge: number
    setFromAge: Dispatch<SetStateAction<number>>
    setToAge: Dispatch<SetStateAction<number>>
}

export const AgeAndGenderFilter = ({
    genders,
    setGenders,
    fromAge,
    toAge,
    setFromAge,
    setToAge
}: AgeAndGenderFilterProps) => {
    return (
        <div>
            <GenderSection genders={genders} setGenders={setGenders} />
            <AgeSection fromAge={fromAge} toAge={toAge} setFromAge={setFromAge} setToAge={setToAge} />
            <TranslationSwitchSection />
        </div>
    )
}
