import {Animation} from '@/components/ui/animation/Animation'
import {Button} from '@/components/ui/button/Button'
import {Modal} from '@/components/ui/modal/Modal'
import {Spinner} from '@/components/ui/spinner/Spinner'
import {useRootStore} from '@/store'
import {selectGoalDeletionSuccessModal} from '@/store/selectors'
import Cancel from '@assets/animations/Alert-2.json'
import {useTranslation} from 'react-i18next'
import {useStopGoal} from '../../queries/useStopGoal'
import {StyledAnimation, StyledBody, StyledButtonSection, StyledStopButton, StyledText} from './style'
import {GoalType} from '../../types'
import useMixPanel from '@/integrations/mixpanel/hooks/useMixpanel'
import {MixpanelEventNameE} from '@/integrations/mixpanel/types'
import {useFetchSupporters} from '../../queries/useFetchSupporters'

type StopGoalModalProps = {
    onClose: () => void
    onStopGoalClick: () => void
    goalId?: number
}
export const StopGoalModal = ({onClose, onStopGoalClick, goalId}: StopGoalModalProps) => {
    const {t} = useTranslation()
    const {openModal} = useRootStore(selectGoalDeletionSuccessModal)
    const {data: supporters} = useFetchSupporters(`${goalId}`)
    const mixpanel = useMixPanel()

    const onSuccess = (data: GoalType) => {
        onStopGoalClick()
        openModal(true)
        mixpanel?.trackEvent(MixpanelEventNameE.GoalCancelled, {
            goal_name: data?.name,
            goal_amount: data?.target_amount,
            goal_type: data?.type?.type,
            goal_end_date: data?.end_date,
            proof_type: {
                has_image_proof: data?.has_image_proof,
                has_video_proof: data?.has_video_proof
            },
            images_count: data?.media?.filter(item => item?.type === 'image')?.length,
            videos_count: data?.media?.filter(item => item?.type === 'video')?.length,
            number_of_supporters: supporters?.length,
            cancelled_reason: data?.cancelled_reason
        })
    }

    const {mutate, isPending} = useStopGoal(`${goalId}`, onSuccess)

    return (
        <Modal onClose={onClose} minHeight={'557px'} title={t('stop_goal_popup:title')}>
            {isPending && <Spinner />}
            <StyledBody>
                <StyledAnimation>
                    <Animation animationData={Cancel} />
                </StyledAnimation>
                <StyledText>
                    <span>{t('stop_goal_popup:subtitle')}</span>
                    <p>{t('stop_goal_popup:description')}</p>
                </StyledText>
                <StyledButtonSection>
                    <Button variant="warning" onClick={() => mutate()}>
                        {t('stop_goal_popup:button_text')}
                    </Button>
                    <StyledStopButton onClick={onClose}>{t('stop_goal_popup:bottom_button_text')}</StyledStopButton>
                </StyledButtonSection>
            </StyledBody>
        </Modal>
    )
}
