import styled, {css} from 'styled-components'

export const StyledProfileBannerContainer = styled.div`
    ${({onClick, theme: {mediaQueries, spacing, palette}}) => css`
        width: 100%;
        cursor: ${onClick ? 'pointer' : 'default'};
        position: relative;
        padding: ${spacing * 2}px;
        box-shadow: ${palette.neutral[1000]} 0px ${spacing / 4}px ${spacing * 1.5}px;
        border-radius: ${spacing}px;
        min-height: 70px;
        ${mediaQueries.m} {
            min-height: 130px;
        }
    `}
`

export const StyledProfileBannerWrapper = styled.div`
    ${({theme: {mediaQueries, spacing}}) => css`
        display: flex;
        gap: ${spacing * 1.5}px;
        height: 60px;
        align-items: center;

        ${mediaQueries.m} {
            height: 100px;
        }
    `}
`
export const StyledProfileBannerImgWrapper = styled.div`
    ${({theme: {mediaQueries, spacing}}) => css`
        width: 63px;
        height: 63px;
        & svg {
            width: 100%;
            height: 100%;
            background: linear-gradient(#8f90fe 0%, #8b71ea 50.31%, #864bd2 100%);
            border-radius: ${spacing * 2}px;
        }
        ${mediaQueries.m} {
            width: 100px;
            height: 100px;
        }
    `}
`
export const StyledProfileBannerImg = styled.img`
    ${() => css`
        width: 100%;
        height: 100%;
    `}
`
export const StyledProfileBannerInform = styled.div`
    ${({theme: {mediaQueries}}) => css`
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        ${mediaQueries.m} {
            height: 100%;
        }
    `}
`
export const StyledProfileBannerTitle = styled.p`
    ${({theme: {typography, palette}}) => css`
        ${typography.textSm}
        color: ${palette.primary[900]};
        max-height: 80px;
        overflow-y: auto;
        font-weight: 600;
    `}
`
export const StyledProfileBannerCost = styled.p`
    ${({theme: {spacing, palette}}) => css`
        display: flex;
        gap: ${spacing / 2}px;
        color: ${palette.primary[800]};
        & > span {
            color: ${palette.primary[900]};
            font-weight: 600;
        }
    `}
`

export const StyledProfileBannerDescription = styled.div`
    ${({theme: {mediaQueries, typography, palette}}) => css`
        ${typography.textXs}
        color: ${palette.primary[700]};
        font-weight: 400;
        margin-right: 5px;
        letter-spacing: -0.12px;
        ${mediaQueries.m} {
            ${typography.textSm}
        }
        & span {
            color: ${palette.primary[900]};
            font-weight: 400;
        }
    `}
`

export const StyledButtonForRedirectToChat = styled.div`
    ${({theme: {mediaQueries}}) => css`
        margin-left: auto;
        cursor: pointer;
        & > svg {
            width: 20px;
            height: 20px;
        }
        ${mediaQueries.m} {
            & > svg {
                width: 30px;
                height: 30px;
            }
        }
    `}
`
