import {MoengageObjectResponseType} from '../../types'
import {ConnectorNamesE} from '../../services/moengage.model'
import GoalPromoTemplate, {GoalPromoProps} from '../templates/goal-promo-template/GoalPromoTemplate'
import {connectorsList} from '../connectors'
import {FC, useEffect} from 'react'
import {useSeenMoengageCombination} from '../../queries/useSeenMoengageCombination'
import CTA from '../CTA/CTA'
import {generateGoalSlug} from '@/utilities/helpers'
/** This component handles the combination of all "single-host-profile" template
 * along with all suitable connectors
 * */

// Add all possible views for this template
// Current supported connectors: ['popup', 'card', 'mini-popup', 'top-banner', ...] - all
const templateList: {[key: string]: FC<GoalPromoProps>} = {
    [ConnectorNamesE.popup]: GoalPromoTemplate
}

const GoalPromoCombination = ({data, closeHandler}: {data: MoengageObjectResponseType; closeHandler: () => void}) => {
    const {name: connectorName, cta_button_text, cta_button_color} = data.connector

    const CtaComponent = () =>
        data.id ? (
            <CTA
                ctaType={data?.dynamic_template?.cta_type}
                backgroundColor={cta_button_color}
                promo={data?.dynamic_template?.promo}
                goalSlug={generateGoalSlug(
                    `${data?.dynamic_template?.goal?.slug}`,
                    `${data?.dynamic_template?.goal?.id}`
                )}
                campaignId={data.id}
                ctaText={cta_button_text}
            />
        ) : (
            <></>
        )

    const {mutate} = useSeenMoengageCombination()

    useEffect(() => {
        const timer = setTimeout(() => mutate({campaign_id: `${data?.id}`}), 3000)
        return () => clearTimeout(timer)
    }, [])

    if (connectorName) {
        const Template = templateList[connectorName]
        const Connector = connectorsList[connectorName]
        return data?.dynamic_template?.goal ? (
            <Connector closeHandler={closeHandler} {...data.connector} CtaComponent={CtaComponent}>
                <Template goal={data?.dynamic_template?.goal} promo={data?.dynamic_template?.promo} />
            </Connector>
        ) : null
    } else {
        return null
    }
}

export default GoalPromoCombination
