import styled, {css} from 'styled-components'

export const StyledBody = styled.div`
    ${({theme: {spacing}}) => css`
        width: 100%;
        height: 100%;
        position: relative;
        display: grid;
        align-items: center;
        padding: ${spacing * 2.5}px;
    `}
`

export const StyledText = styled.div`
    ${({theme: {spacing, typography, palette}}) => css`
        text-align: center;
        white-space: pre-line;
        align-self: end;
        & h4 {
            ${typography.textXXl}
            letter-spacing: -0.05em;
            margin: ${spacing * 3}px 0 ${spacing * 2}px 0;
            font-weight: 400;
        }
        & p {
            margin: 0;
            color: ${palette.neutral[500]};
            font-weight: 300;
        }
    `}
`
export const StyledButton = styled.div`
    ${({theme: {spacing, typography}}) => css`
        gap: 10px;
        width: 50%;
        margin: 0 auto;
        display: flex;
        padding-top: ${spacing * 4}px;
        flex-direction: column;
        justify-content: flex-end;

        & button {
            font-weight: 700;
            padding: ${spacing * 2}px ${spacing * 3}px;
            ${typography.textMd}
        }
    `}
`

export const StyledAnimation = styled.div`
    ${({theme: {mediaQueries}}) => css`
        margin: 0 auto;
        height: auto;
        max-width: 100px;
        width: 100%;
        ${mediaQueries} {
            max-width: 140px;
        }
    `}
`
