import {CalendarIcon} from '@/assets/icons/icons'
import {Label} from '@/components/ui/label/Label'
import {InputText, InputTextProps} from '@components/commons/input-text/InputText.tsx'
import {FC, useEffect, useState} from 'react'
import {DefaultTheme, FlattenInterpolation, ThemeProps} from 'styled-components'
import {Calendar, CalendarProps} from '../calendar/Calendar'
import {PopoverForDatePicker} from './components/popover-for-date-picker/PopoverForDatePicker'
import {CalendarWrapper, DateInputStyledProps, DatePickerWrapper} from './style'
import {useTranslation} from 'react-i18next'

type BaseDatePickerProps = {
    formatDateFn: (date: Date) => string
    onBlur?: () => void
    disabled?: boolean
    errorMessage?: string
} & Omit<CalendarProps, 'mode' | 'focusDate'>

type InputDatePickerProps = BaseDatePickerProps & {
    mode: 'single' | 'multiple' | 'range'
    placeholder?: string
    triggerProps?: Omit<InputTextProps, 'type' | 'readonly' | 'value'>
}

type DatePickerProps = InputDatePickerProps & {
    name: string
    label?: string | JSX.Element
    extraContent?: JSX.Element
    isAdditionalControl?: boolean
    open?: boolean
    onChangeIsOpen?: () => void
    datePickerRangeStyles?: FlattenInterpolation<ThemeProps<DefaultTheme>>
}

const TriggerByMode = {
    single: InputText,
    range: InputText
}

type FormatDateFnArgs = {
    dates: Date[]
    formatFn: (date: Date) => string
}
const formatFnByMode = {
    single: ({dates, formatFn}: FormatDateFnArgs) => {
        const date = dates[0]
        return date ? formatFn(date) : ''
    },
    range: ({dates, formatFn}: FormatDateFnArgs) => {
        const [start, end] = dates
        if (!start && !end) {
            return ''
        }
        const formattedStart = start ? formatFn(start) : ''
        const formattedEnd = end ? formatFn(end) : ''

        return `${formattedStart} - ${formattedEnd}`
    }
}

const LENGTH_DATES = {
    single: 1,
    range: 2
}

export const DatePicker: FC<DatePickerProps> = ({
    mode,
    selectedDates,
    formatDateFn,
    onBlur,
    disabled,
    placeholder = 'MM/DD/YYYY',
    errorMessage,
    name,
    label,
    extraContent,
    onDatesChange,
    isAdditionalControl = false,
    open,
    onChangeIsOpen,
    datePickerRangeStyles,
    ...props
}) => {
    const {i18n} = useTranslation()
    const isMultiple = mode === 'multiple'
    const correctMode = isMultiple ? 'range' : mode
    const Trigger = TriggerByMode[correctMode]
    const formatFn = formatFnByMode[correctMode]
    const [localDates, setLocalDate] = useState<Date[]>(selectedDates)

    const currentDates = isAdditionalControl ? localDates : selectedDates
    const changeDatesHandler = (dates: Date[]) => {
        if (isAdditionalControl) setLocalDate(dates)
        onDatesChange(dates)
    }

    useEffect(() => {
        if (typeof open !== 'undefined' && !open) setLocalDate(selectedDates)
    }, [open])

    return (
        <DatePickerWrapper>
            {label && <Label htmlFor={name} text={label} />}
            <PopoverForDatePicker
                rootProps={{
                    onOpenChange: open => {
                        if (!open) onBlur?.()
                        onChangeIsOpen?.()
                    },

                    open: disabled ? false : open
                }}
                trigger={
                    <Trigger
                        icon={<CalendarIcon />}
                        name={name}
                        disabled={!!disabled}
                        type="text"
                        placeholder={placeholder}
                        readOnly
                        value={formatFn({dates: selectedDates, formatFn: formatDateFn})}
                        errorMessage={errorMessage}
                        inputStyles={() => DateInputStyledProps(!!errorMessage)}
                        onClick={() => onChangeIsOpen?.()}
                    />
                }
                triggerProps={{
                    asChild: true
                }}
            >
                <CalendarWrapper>
                    <Calendar
                        {...props}
                        mode={correctMode}
                        locale={i18n.language.split('_')[0].toLowerCase()}
                        selectedDates={currentDates}
                        onDatesChange={changeDatesHandler}
                        datePickerRangeStyles={datePickerRangeStyles}
                        focusDate={
                            currentDates.length === LENGTH_DATES[correctMode] && currentDates[0]
                                ? currentDates[0]
                                : null
                        }
                    />

                    {isMultiple && (
                        <Calendar
                            {...props}
                            mode={correctMode}
                            locale={i18n.language.split('_')[0].toLowerCase()}
                            selectedDates={currentDates}
                            onDatesChange={changeDatesHandler}
                            datePickerRangeStyles={datePickerRangeStyles}
                            focusDate={
                                currentDates.length === LENGTH_DATES[correctMode] && currentDates[1]
                                    ? currentDates[1]
                                    : null
                            }
                        />
                    )}
                    {extraContent}
                </CalendarWrapper>
            </PopoverForDatePicker>
        </DatePickerWrapper>
    )
}
