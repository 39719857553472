import {InputStatusIconWrapper} from '@/components/ui/input-status-icon/style'
import {InputWrapper} from '@/components/ui/input-wrapper/InputWrapper'
import styled, {DefaultTheme, FlattenSimpleInterpolation, css} from 'styled-components'

export const StyledInputWrapper = styled(InputWrapper)`
    ${({
        inputStyles,
        theme
    }: {
        inputStyles?: (theme: DefaultTheme) => FlattenSimpleInterpolation
        theme: DefaultTheme
    }) => css`
        ${inputStyles && inputStyles(theme)}
        &:has(${InputStatusIconWrapper}) {
            & input {
                padding-right: ${theme.spacing * 2.5}px;
            }
        }
        & input[type='number'] {
            &::-webkit-inner-spin-button,
            input[type='number']::-webkit-outer-spin-button {
                -webkit-appearance: none;
            }
        }
    `};
`

export const StyledInput = styled.input`
    ${({theme: {typography, palette}}) => css`
        flex: 1;
        width: 100%;
        height: 100%;
        border: none;
        outline: none;
        color: ${palette.neutral['900']};

        ${typography.textMd}
        &::placeholder {
            color: ${palette.neutral[500]};
        }
    `}
`

export const StyledCount = styled.div`
    ${({hasError, theme: {typography, palette}}: {hasError: boolean; theme: DefaultTheme}) => css`
        font-weight: 500;
        ${typography.textXs}
        color: ${palette.primary[700]};
        ${hasError &&
        css`
            color: ${palette.warning[50]};
        `}
    `}
`
