import {envVars} from '@/envVars'
import {IdCardStatuses} from '@/features/constants/constants'

export const PARTY_MERCH_BLOCKING_REASONS = {
    noPayment: 'noPayment',
    [IdCardStatuses.DEFAULT]: IdCardStatuses.DEFAULT,
    [IdCardStatuses.PENDING]: IdCardStatuses.PENDING,
    [IdCardStatuses.REJECTED]: IdCardStatuses.REJECTED
}

export const telegramBaseURL = envVars.VITE_APP_TELEGRAM_BOT_URL ?? 'https://telegram.me/'
export const whatsappBaseURL = envVars.VITE_WHATSAPP_URL ?? 'https://wa.me'
export const customerCareHostLink = envVars.VITE_APP_CUSTOMER_CARE_HOST_LINK ?? 'https://hostsupport.morgis.com/'
export const customerCareGuestLink = envVars.VITE_APP_CUSTOMER_CARE_GUEST_LINK ?? 'https://support.morgis.com/'
export const learndashCourseURL = envVars.VITE_APP_LEARNDASH_COURSE_URL ?? 'https://course.morgis.com/'
