import {PromoPackageType} from '@/features/moengage/types'
import {PROMO_TYPES} from '../../combinations'
import {
    StyledCashbackTitle,
    StyledDiscountOldValue,
    StyledDiscountSection,
    StyledMorgiCashback,
    StyledOfferAmount,
    StyledOfferCTA
} from './style'
import morgiRotatedIcon from '@/assets/images/morgiRotatedIcon3x.png'
import {remapCurrency} from '@/features/profile/constants'
import {useMe} from '@/features/authentication/queries/useMe'

const CorrectClaimSection = ({
    promoType,
    CtaComponent,
    correctPackageData
}: {
    promoType: string
    CtaComponent?: () => JSX.Element
    correctPackageData: PromoPackageType & {
        promo?: {
            type?: string
            percent?: number
        }
    }
}) => {
    const {data} = useMe()
    const currencySymbol = remapCurrency[data?.currency || 'USD']
    const costWithDiscount =
        correctPackageData?.price != null && correctPackageData?.promo?.percent != null
            ? correctPackageData.price - correctPackageData.promo.percent * correctPackageData.price
            : 0

    switch (promoType) {
        case PROMO_TYPES.discount:
            return (
                <StyledDiscountSection>
                    <StyledDiscountOldValue>
                        {currencySymbol} {correctPackageData?.price}
                    </StyledDiscountOldValue>
                    <StyledOfferAmount>
                        {currencySymbol}
                        {costWithDiscount.toFixed(2)}
                    </StyledOfferAmount>
                    {!!CtaComponent && (
                        <StyledOfferCTA>
                            <CtaComponent />
                        </StyledOfferCTA>
                    )}
                </StyledDiscountSection>
            )
        case PROMO_TYPES.cashback:
            return (
                <StyledDiscountSection>
                    <StyledCashbackTitle>Cashback</StyledCashbackTitle>
                    <StyledOfferAmount>
                        {!!correctPackageData?.promo &&
                            Math.floor(
                                correctPackageData?.micromorgi_count != null &&
                                    correctPackageData?.promo?.percent != null
                                    ? correctPackageData?.micromorgi_count * correctPackageData?.promo?.percent
                                    : 0
                            )}
                        <StyledMorgiCashback src={morgiRotatedIcon} alt="morgi" />
                    </StyledOfferAmount>
                    {!!CtaComponent && (
                        <StyledOfferCTA>
                            <CtaComponent />
                        </StyledOfferCTA>
                    )}
                </StyledDiscountSection>
            )
        case PROMO_TYPES.free_morgis:
            return CtaComponent ? (
                <StyledDiscountSection>
                    <StyledOfferCTA>
                        <CtaComponent />
                    </StyledOfferCTA>
                </StyledDiscountSection>
            ) : null
        default:
            return null
    }
}

export default CorrectClaimSection
