import styled, {css, DefaultTheme} from 'styled-components'

export const inputStyles = () => css`
    border-radius: 24px;
`

export const StyledSearchedFiltersList = styled.div`
    ${() => css`
        display: grid;
        position: relative;
        min-height: 70px;
        grid-auto-flow: row;
    `}
`

export const StyledInputWrap = styled.div`
    ${({theme: {spacing}}) => css`
        margin: ${spacing * 1.25}px 0px ${spacing * 1.25}px ${spacing / 2}px;
    `}
`

export const StyledListWrap = styled.div`
    ${({theme: {spacing}}) => css`
        scrollbar-width: thin;
        width: 100%;
        display: flex;
        padding: ${spacing / 2}px 0px ${spacing * 2}px;
        overflow-x: auto;
        touch-action: auto;
        scroll-behavior: smooth;
        min-height: 78px;
    `}
`

export const StyledItem = styled.div`
    ${({theme: {spacing, palette}, isSelected}: {theme: DefaultTheme; isSelected: boolean}) => css`
        gap: ${spacing / 2}px;
        color: ${isSelected ? palette.neutral.white : palette.primary[900]};
        background-color: ${isSelected ? palette.primary[900] : palette.neutral.white};
        border: 1px solid ${palette.primary[900]};
        cursor: pointer;
        margin: 2px;
        display: flex;
        padding: ${spacing * 1.25}px ${spacing * 2}px;
        align-items: center;
        font-weight: 700;
        line-height: 17px;
        white-space: nowrap;
        border-radius: 100px;
        letter-spacing: -0.3px;

        & svg {
            & path {
                stroke: ${isSelected && palette.neutral.white};
            }
        }
    `}
`
