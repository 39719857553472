import styled, {css} from 'styled-components'

export const StyledBody = styled.div`
    ${({theme: {spacing}}) => css`
        width: 100%;
        height: 100%;
        padding: 0px ${spacing * 3}px ${spacing * 3}px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        overflow-y: hidden;
        min-height: 417px;
    `}
`

export const StyledAnimation = styled.div`
    ${({theme: {spacing}}) => css`
        margin: ${spacing * 1.5}px auto ${spacing * 3}px;
        height: 100%;
        width: 100%;
        max-height: 180px;
        max-width: 200px;
    `}
`

export const StyledText = styled.div`
    text-align: center;
`

export const StyledButton = styled.div`
    ${({theme: {spacing}}) => css`
        display: flex;
        flex-direction: column;
        gap: ${spacing * 2}px;

        width: 100%;
        & button {
            width: 100%;
        }
    `}
`
