import {PoofStatusesE, ProofType} from '@/features/goal/types'
import {StyledChildren, StyledMedia, StyledProofsMediaRoot} from './style'
import {MediaTypeE} from '@/types'
import {Media} from '@/components/ui/media/Media'
import ResponsiveVideo from '@/components/commons/responsive-video/ResponsiveVideo'
import {PhotoCameraIcon, VideoCameraIcon} from '@/assets/icons/icons'
import {useState} from 'react'
import {MediaLightBox} from '@/components/ui/media-light-box/MediaLightBox'

export const ProofsMedia = ({proofs}: {proofs: ProofType[] | null}) => {
    const [isImagePreviewOpen, setIsImagePreviewOpen] = useState(false)
    const [isVideoPreviewOpen, setIsVideoPreviewOpen] = useState(false)

    const filteredImages =
        !!proofs &&
        proofs
            .filter(item => item.status !== PoofStatusesE.DECLINED && item.type === MediaTypeE.IMAGE)
            .map(item => {
                if (item) {
                    return {
                        type: item?.type,
                        url: item?.url
                    }
                } else return undefined
            })

    const filteredVideos =
        !!proofs &&
        proofs
            .filter(item => item.status !== PoofStatusesE.DECLINED && item.type === MediaTypeE.VIDEO)
            .map(item => {
                if (item) {
                    return {
                        type: item?.type,
                        url: item?.url
                    }
                } else return undefined
            })

    const numberOfImages = !!filteredImages && filteredImages?.length
    const numberOfVideos = !!filteredVideos && filteredVideos?.length
    const mediaToPreview = isImagePreviewOpen ? filteredImages : filteredVideos

    const handleClosePreview = () => {
        setIsImagePreviewOpen(false)
        setIsVideoPreviewOpen(false)
    }

    return (
        <>
            <StyledProofsMediaRoot isSingleProof={!numberOfImages || !numberOfVideos}>
                {numberOfImages ? (
                    <StyledMedia onClick={() => setIsImagePreviewOpen(true)}>
                        <Media imageUrl={filteredImages[0]?.url || ''} />
                        <StyledChildren>
                            <span>{numberOfImages > 1 && `+${numberOfImages - 1}`}</span>
                            <PhotoCameraIcon />
                        </StyledChildren>
                    </StyledMedia>
                ) : null}
                {numberOfVideos ? (
                    <StyledMedia onClick={() => setIsVideoPreviewOpen(true)}>
                        <ResponsiveVideo video={filteredVideos[0]?.url || ''} controls={false} withPlayButton />
                        <StyledChildren>
                            <span>{numberOfVideos > 1 && `+${numberOfVideos - 1}`}</span>
                            <VideoCameraIcon />
                        </StyledChildren>
                    </StyledMedia>
                ) : null}
            </StyledProofsMediaRoot>
            {(isImagePreviewOpen || isVideoPreviewOpen) && (
                <MediaLightBox media={mediaToPreview || []} onClose={handleClosePreview} />
            )}
        </>
    )
}
