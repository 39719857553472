import styled, {css} from 'styled-components'

export const StyledIconButton = styled.div`
    ${({theme: {palette, shadows}}) => css`
        width: 40px;
        cursor: pointer;
        height: 40px;
        display: flex;
        box-shadow: ${shadows.mdXl};
        align-items: center;
        border-radius: 50%;
        justify-content: center;
        background-color: ${palette.neutral.white};
    `}
`
