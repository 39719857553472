import styled, {css} from 'styled-components'

export const StyledUploadAreaRoot = styled.div`
    ${({theme: {spacing}}) => css`
        display: flex;
        gap: ${spacing * 2}px;
    `}
`

export const StyledStatusSection = styled.div`
    ${({theme: {spacing}}) => css`
        display: grid;
        gap: ${spacing}px;
        align-items: center;
    `}
`

export const StyledHint = styled.p`
    ${({theme: {typography, palette}}) => css`
        ${typography.textXxs}
        color: ${palette.neutral['700']}
    `}
`

export const rootCardClass = () => css`
    box-shadow: rgba(0, 0, 0, 0.15) 0px 4px 10px 0px;
    border-radius: 10px;
    max-width: 400px;
    margin: 0 auto;
`
