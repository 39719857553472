import {GOAL_API} from '@/features/goal/services/goal.http.ts'
import {useMutation} from '@tanstack/react-query'
import {UploadMediaRequest, GeneralError} from '../types'

type responseType = {
    media_path: string
    message?: string
}

export const useSendGoalMedia = (onSuccess?: (media_path: string, message?: string) => void, onError?: (errMessage?: string) => void) => {
    return useMutation<responseType, GeneralError, UploadMediaRequest>({
        mutationFn: (params: UploadMediaRequest) => GOAL_API.uploadMedia(params),
        onSuccess: data => {
            onSuccess?.(data.media_path, data?.message)
        },
        onError: error => {
            onError?.(error?.response?.data?.message)
        }
    })
}
