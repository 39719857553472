import styled, {DefaultTheme, css} from 'styled-components'

export const StyledOverlay = styled.div`
    ${({isOpen, theme: {zIndex, palette}}: {isOpen: boolean; theme: DefaultTheme}) => css`
        position: fixed;
        z-index: ${zIndex.nav};
        display: ${isOpen ? 'block' : 'none'};
        background-color: ${palette.neutral[700]};
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
    `}
`

export const StyledRoot = styled.div`
    ${({isOpen, vh, theme: {palette, zIndex, mediaQueries}}: {isOpen: boolean; vh: number; theme: DefaultTheme}) => css`
        z-index: ${zIndex.nav};
        text-align: left;
        padding: 0;
        position: fixed;
        top: 0;
        left: 0;
        width: 100vw;
        height: ${vh}px;
        overflow-y: scroll;
        opacity: ${isOpen ? 1 : 0};
        transform: ${isOpen ? 'translateX(0)' : `translateX(-100%)`};
        transition: all 0.3s ease-in-out;
        background-color: ${palette.neutral.white};
        color: ${palette.neutral[900]};
        ${mediaQueries.m} {
            width: 40vw;
            max-width: 480px;
        }
    `}
`

export const StyledHeader = styled.div`
    ${({theme: {sizing, spacing}}) => css`
        display: grid;
        grid-template-columns: 1fr auto 1fr;
        align-items: center;
        padding: ${spacing * 2}px;
        height: ${sizing.headerMobileHeight};
        & svg {
            cursor: pointer;
        }
    `}
`

export const StyledLogo = styled.img`
    ${({theme: {mediaQueries}}) => css`
        grid-column-start: 2;
        height: 100%;
        width: 100%;
        max-height: 30px;
        max-width: 58px;
        object-fit: contain;
        cursor: pointer;
        margin-bottom: 3px;
        ${mediaQueries.m} {
            max-height: 40px;
            max-width: 85px;
        }
    `}
`
