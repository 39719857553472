import styled, {css} from 'styled-components'
import cashbackCardBackGround from '@/assets/images/cashbackCardBackGround.png'

export const StyledCashbackBanner = styled.div`
    ${({theme: {mediaQueries, palette, spacing}}) => css`
        width: 100%;
        display: flex;
        box-shadow: 0px 2px 18px rgba(0, 6, 61, 0.15);
        min-height: ${spacing * 18}px;
        align-items: center;
        border-radius: ${spacing * 1.25}px;
        background-size: cover;
        justify-content: center;
        background-color: ${palette.primary[900]};
        background-image: url(${cashbackCardBackGround});
        background-repeat: no-repeat;
        background-position: center;
        margin: 0 auto;

        ${mediaQueries.l} {
            min-height: 210px;
        }

        & > svg {
            width: 47px;
            height: 47px;

            ${mediaQueries.l} {
                width: 60px;
                height: 60px;
            }
        }
    `}
`

export const StyledTextContainer = styled.div`
    ${({theme: {typography, mediaQueries, palette, spacing, truncateText}}) => css`
        color: ${palette.neutral.white};
        margin: 0 ${spacing * 2}px 0 ${spacing}px;
        ${typography.textSm};
        font-weight: 600;
        ${truncateText}

        ${mediaQueries.l} {
            margin: 0 ${spacing * 3}px 0 ${spacing * 2}px;
            ${typography.textMd};
        }

        & > span {
            color: ${palette.tertiary[300]};
            ${typography.text4Xl};
            font-weight: 700;
            line-height: 54px;
            letter-spacing: -1.5px;

            ${mediaQueries.l} {
                ${typography.displayMd};
                line-height: 60px;
            }
        }
    `}
`

export const StyledTimer = styled.div`
    ${({theme: {typography, mediaQueries, palette, spacing}}) => css`
        color: ${palette.tertiary[300]};
        ${typography.textSm};
        font-weight: 700;
        letter-spacing: -0.65px;
        padding-right: ${spacing / 2}px;

        ${mediaQueries.l} {
            ${typography.textMd};
        }
    `}
`
