import styled, {css, DefaultTheme} from 'styled-components'
import * as RTooltip from '@radix-ui/react-tooltip'

type StyledTooltipProps = {
    variant: 'light' | 'dark' | 'darkPurple'
    $paddingTop: number | []
    $paddingSides?: number
    color?: 'neutral' | 'primary' | 'secondary' | 'success' | 'tertiary' | 'warning' | 'danger'
}

export const StyledTooltipContent = styled(RTooltip.Content)<StyledTooltipProps>`
    ${({theme: {palette, shadows, typography, spacing}, color}) => css`
        color: ${palette[color || 'neutral'][800]} !important;
        box-shadow: ${shadows.md};
        padding: ${spacing}px;
        text-align: center;
        font-size: ${typography.textXs};
        width: 100%;
        max-width: 250px;
        border-radius: 4px;
        z-index: 1;
    `}
    ${({variant = 'light', theme}) => ColorStyles(theme)[variant]};
`

const ColorStyles = ({palette}: DefaultTheme) => ({
    light: css`
        background: ${palette.neutral.white};
        color: ${palette.primary[900]};

        & svg {
            fill: ${palette.neutral.white};
        }
    `,
    dark: css`
        background: ${palette.neutral['100']};
        color: ${palette.neutral.white}!important;

        & svg {
            fill: ${palette.neutral['900']};
        }
    `,
    darkPurple: css`
        background: ${palette.primary[900]};
        color: ${palette.neutral.white} !important;
        & svg {
            fill: ${palette.primary[900]};
        }
    `
})
