export const breakpoints = {
    width: {
        xxxs: 250,
        xxs: 360,
        xs: 375,
        s: 412,
        m: 768,
        l: 1024,
        xl: 1366,
        xxl: 1440,
        xxxl: 1536,
        xxxxl: 1920
    }
}

const mediaQueries = {
    xs: `@media only screen and (min-width: ${breakpoints.width.xs}px)`,
    s: `@media only screen and (min-width: ${breakpoints.width.s}px)`,
    m: `@media only screen and (min-width: ${breakpoints.width.m}px)`,
    l: `@media only screen and (min-width: ${breakpoints.width.l}px)`,
    xl: `@media only screen and (min-width: ${breakpoints.width.xl}px)`,
    xxl: `@media only screen and (min-width: ${breakpoints.width.xxl}px)`,
    xxxl: `@media only screen and (min-width: ${breakpoints.width.xxxl}px)`,
    xxxxl: `@media only screen and (min-width: ${breakpoints.width.xxxxl}px)`
}

export default mediaQueries
