import styled, {DefaultTheme, css} from 'styled-components'

export const StyledProofSectionRoot = styled.div`
    ${({theme: {palette, spacing}, backgroundColor}: {backgroundColor?: string; theme: DefaultTheme}) => css`
        max-width: 650px;
        width: 100%;
        margin: ${spacing}px auto;
        background-color: ${backgroundColor};
        border: 1px solid ${palette.neutral['400']};
        padding: ${spacing * 2}px;
        border-radius: 5px;
    `}
`

export const StyledTitle = styled.div`
    ${({theme: {spacing, typography}}) => css`
        display: grid;
        grid-template-columns: 0fr auto;
        align-items: start;
        gap: ${spacing}px;
        ${typography.textXs};
        font-weight: 700;

        & svg {
            width: 16px;
            height: 16px;
        }
    `}
`

export const StyledText = styled.div`
    ${({theme: {typography, spacing}}) => css`
        ${typography.textMd};
        margin-top: ${spacing * 2}px;
    `}
`

export const StyledProofNote = styled.div`
    ${({theme: {typography, palette, spacing}}) => css`
        margin-top: ${spacing * 2}px;
        color: ${palette.neutral['700']};
        ${typography.textMd}
        word-break: break-word;
    `}
`

export const StyledButtonSection = styled.div`
    ${({theme: {typography, spacing}}) => css`
        display: grid;
        gap: ${spacing * 3}px;
        margin-top: ${spacing * 3}px;
        text-align: center;

        & button {
            width: 100%;
            max-width: 400px;
            margin: 0 auto;
        }

        & span {
            ${typography.textMd}
        }
    `}
`
