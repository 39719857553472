import {useTranslation} from 'react-i18next'
import {StyledCountItem, StyledHide, StyledItem, StyledTip, StyledTipsWrap} from './style'
import {ImagesIcon, RedeemIcon} from '@/assets/icons/icons'
import {useState} from 'react'
import {TipsAndGiftsType, UncoverAttachmentType} from '@/features/thank-yous/types'
import {ThankYousTypesE} from '@/features/thank-yous/services/thankYous.schema'

type ThanksYousHostSectionProps = {
    data?: TipsAndGiftsType | UncoverAttachmentType
    inHostProfile?: boolean
    count?: number
    type: ThankYousTypesE.inChatTipsAndGifts | ThankYousTypesE.uncoverAttachment
}

export const tipsData = {
    [ThankYousTypesE.inChatTipsAndGifts]: {
        title: 'set_thank_yous_modal:items:in_chat_tips_and_gifts',
        icon: <RedeemIcon />
    },
    [ThankYousTypesE.uncoverAttachment]: {
        title: 'set_thank_yous_modal:items:uncover_attachment',
        icon: <ImagesIcon />
    }
}

export const ThankYousTipsItem = ({data, inHostProfile = false, count, type}: ThanksYousHostSectionProps) => {
    const {t} = useTranslation()
    const [tipsShown, setTipsShown] = useState(false)

    return (
        <div>
            <StyledItem inHostProfile={inHostProfile}>
                {tipsData[type]?.icon}
                <p>{t(tipsData[type]?.title)}</p>
                {!tipsShown && <StyledCountItem onClick={() => setTipsShown(true)}>+{count}</StyledCountItem>}
            </StyledItem>
            {!!tipsShown && (
                <StyledTipsWrap>
                    {data?.map((item, index) => {
                        return (
                            <StyledTip key={index}>
                                {`${item.name}`}
                                {count && index < count - 1 && ','}
                            </StyledTip>
                        )
                    })}
                    <StyledHide inHostProfile={inHostProfile} onClick={() => setTipsShown(false)}>
                        {t('common:hide')}
                    </StyledHide>
                </StyledTipsWrap>
            )}
        </div>
    )
}
