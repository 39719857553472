import {PlayButton} from '@/assets/icons/icons'
import styled, {DefaultTheme, FlattenSimpleInterpolation, css} from 'styled-components'

export const StyledRoot = styled.div`
    ${({
        videoStyles,
        theme
    }: {
        videoStyles?: (theme: DefaultTheme) => FlattenSimpleInterpolation
        theme: DefaultTheme
    }) => css`
        height: 100%;
        width: 100%;
        overflow: hidden;
        position: relative;
        & > video {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
        ${videoStyles && videoStyles(theme)}
    `}
`
export const StyledPlayButton = styled(PlayButton)`
    ${({theme: {zIndex}}) => css`
        top: 50%;
        left: 50%;
        cursor: pointer;
        z-index: ${zIndex.modal};
        position: absolute;
        transform: translate(-50%, -50%);
    `}
`
