import {ErrorBox} from '@components/commons/error-box/ErrorBox.tsx'
import {useRouteError} from 'react-router-dom'
import {getErrorMessage} from './utilities/getAPIErrorMessage'
import {GeneralError} from './features/authentication/types'

export const ErrorBoundary = () => {
    const error = useRouteError()
    const message = getErrorMessage(error as GeneralError)
    return <ErrorBox title={message} />
}
