import styled, {DefaultTheme, css} from 'styled-components'

export const StyledNotificationContainer = styled.div<{vh: number}>`
    ${({vh, theme: {mediaQueries, spacing, palette}}) => css`
        padding: ${spacing * 2.5}px ${spacing * 2.5}px ${spacing}px ${spacing * 2.5}px;
        overflow-y: scroll;
        scrollbar-width: auto;
        scrollbar-width: thin;
        max-height: ${vh}px;
        scrollbar-color: ${palette.neutral.black} ${palette.neutral.transparent};
        height: auto;
        &::-webkit-scrollbar {
            background: ${palette.neutral.transparent};
            width: 3px;
            border-radius: 3px;
        }

        &::-webkit-scrollbar-thumb {
            background: ${palette.neutral.black};
        }

        &::-webkit-scrollbar-track {
            margin-bottom: ${spacing * 4}px;
        }
        &::-webkit-scrollbar-button:start {
            display: none;
        }
        &::-webkit-scrollbar-button:end {
            display: none;
        }

        ${mediaQueries.m} {
            max-height: 80vh;
            min-height: 40vh;
            padding: ${spacing * 2.5}px;
        }
    `}
`

export const StyledTitle = styled.h2`
    ${({theme: {spacing}}) => css`
        font-weight: 700;
        margin: ${spacing * 1.5}px 0px ${spacing * 2}px 0px;
    `}
`

export const StyledNotificationBox = styled.div`
    display: flex;
    flex-direction: column;
`

export const StyledNotification = styled.div`
    ${({old, theme: {palette, spacing}}: {old?: boolean; theme: DefaultTheme}) => css`
        border-bottom: ${old ? 'none' : `1px solid ${palette.neutral['600']}`};
        padding: ${spacing / 2}px 0;
    `}
`

export const StyledNotificationsTitle = styled.h1`
    ${({theme: {mediaQueries, typography, spacing}}) => css`
        margin: 0px;
        padding: ${spacing * 1.25}px 0px;
        ${typography.textXXl};
        text-align: center;
        font-weight: 500;
        letter-spacing: -0.05em;
        ${mediaQueries.m} {
            display: none;
        }
    `}
`
