import {ProfileEventsPropsType} from '@/integrations/mixpanel/types'
import {Lens} from '@dhmk/zustand-lens'

type ConnectNowModalState = {
    isOpen: boolean
    hostID: number | undefined
    mixpanelData?: ProfileEventsPropsType
}

type ConnectNowModalActions = {
    openModal: (params: Omit<ConnectNowModalState, 'isOpen'>) => void
    closeModal: () => void
}

export type ConnectNowModal = ConnectNowModalState & ConnectNowModalActions

const initialModal: ConnectNowModalState = {isOpen: false, hostID: undefined, mixpanelData: undefined}

const createConnectNowModalSlice: Lens<ConnectNowModal> = set => ({
    ...initialModal,
    openModal: params => {
        return set(() => ({...params, isOpen: true}))
    },
    closeModal: () => set(() => ({...initialModal}))
})

export default createConnectNowModalSlice
