import {useMutation, useQueryClient} from '@tanstack/react-query'
import {MUTATION_KEYS} from './keys'
import {PROFILE_API} from '../services/profile.http'
import {AxiosResponse} from 'axios'
import {GeneralError} from '@/utilities/getAPIErrorMessage'
import {FirstMessagePayload} from '../services/types'
import {QUERY_KEYS} from '@/features/authentication/queries/keys'
import {QUERY_KEYS as CHANNEL_QUERY_KEYS} from '@/features/chat/queries/keys'

export const useSendFirstMessageToHost = (onSuccess: () => void) => {
    const queryClient = useQueryClient()
    return useMutation<AxiosResponse, GeneralError, FirstMessagePayload>({
        mutationKey: [MUTATION_KEYS.sent_first_message_to_host],
        mutationFn: ({hostId, message, subscription_package_id, micromorgi_amount}) =>
            PROFILE_API.sendFirstMessage({hostId, message, subscription_package_id, micromorgi_amount}),
        onSuccess: () => {
            onSuccess()
            queryClient.invalidateQueries({queryKey: [QUERY_KEYS.user]})
            queryClient.invalidateQueries({queryKey: [CHANNEL_QUERY_KEYS.fetchChannels]})
        }
    })
}
