import {FavoriteTopicCircleCloseIcon} from '@/assets/icons/icons'
import {Topic} from '@/features/chat-fav-topics/types'
import {LanguagesType} from '@/features/country/types'
import {useRootStore} from '@/store'
import {selectChatFavLanguages, selectChatFavTopics} from '@/store/selectors'
import {LabelSelectProps} from '../../types/types'
import {StyledLabelButton, StyledLabelContainer, StyledLabelItem} from './style'

export const LabelSelect = ({selectedOption, removeSelectOption, icon, customLabelHandler}: LabelSelectProps) => {
    const topics = useRootStore(selectChatFavTopics)
    const languages = useRootStore(selectChatFavLanguages)

    return (
        <StyledLabelContainer>
            <StyledLabelItem>{icon}</StyledLabelItem>
            <StyledLabelItem>
                {customLabelHandler?.(selectedOption) ||
                    topics[(selectedOption.extraPayload as Topic).key_name] ||
                    languages[(selectedOption.extraPayload as LanguagesType).code] ||
                    selectedOption.label}
            </StyledLabelItem>
            <StyledLabelItem>
                <StyledLabelButton onClick={() => removeSelectOption(+selectedOption.id)}>
                    <FavoriteTopicCircleCloseIcon width={10} height={10} />
                </StyledLabelButton>
            </StyledLabelItem>
        </StyledLabelContainer>
    )
}
