import {Dispatch, SetStateAction, useEffect, useRef} from 'react'
import {
    StyledMultiRangeSliderRoot,
    StyledRangeSlider,
    StyledSliderTrack,
    StyledRange,
    StyledMinInput,
    StyledMaxInput
} from './style'
import {useGetMinMaxAge} from '@/features/guest/queries/useGetMinMaxAge'
import {useHandleError} from '@/hooks/useHandleError'
import {Spinner} from '@/components/ui/spinner/Spinner'
import {MAX_AGE_TO_SELECT, MIN_AGE_TO_SELECT} from '@/features/guest/constants/hostFilters'

type MultiRangeSlider = {
    fromAge: number
    toAge: number
    setFromAge: Dispatch<SetStateAction<number>>
    setToAge: Dispatch<SetStateAction<number>>
}

export const MultiRangeSlider = ({fromAge, toAge, setFromAge, setToAge}: MultiRangeSlider) => {
    const range = useRef<HTMLDivElement | null>(null)

    const {
        data: ageRange,
        isLoading: isAgeRangeLoading,
        isError: isAgeRangeError,
        error: ageRangeError
    } = useGetMinMaxAge()

    useHandleError({
        isError: isAgeRangeError,
        error: ageRangeError
    })

    const minAllowedAge = ageRange?.min_age || MIN_AGE_TO_SELECT
    const maxAllowedAge = ageRange?.max_age || MAX_AGE_TO_SELECT

    const getPercent = (value: number) =>
        Math.round(((value - MIN_AGE_TO_SELECT) / (MAX_AGE_TO_SELECT - MIN_AGE_TO_SELECT)) * 100)

    useEffect(() => {
        const minPercent = getPercent(fromAge)
        const maxPercent = getPercent(toAge)

        if (range.current) {
            range.current.style.left = `${minPercent}%`
            range.current.style.width = `${maxPercent - minPercent}%`
        }
    }, [fromAge, toAge, getPercent])

    const handleMinChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = parseInt(e.target.value)

        if (minAllowedAge <= value && value < toAge) {
            setFromAge(value)
        }
    }

    const handleMaxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = parseInt(e.target.value)

        if (maxAllowedAge >= value && value > fromAge) {
            setToAge(value)
        }
    }

    if (!fromAge && !toAge) return

    return (
        <StyledMultiRangeSliderRoot>
            {isAgeRangeLoading && <Spinner />}
            {/* MIN */}
            <StyledMinInput
                type="range"
                min={MIN_AGE_TO_SELECT}
                max={MAX_AGE_TO_SELECT}
                value={fromAge}
                onChange={handleMinChange}
                style={{zIndex: ('18' > `${toAge - 100}` && '5') || '1'}}
            />
            {/* MAX */}
            <StyledMaxInput
                type="range"
                min={MIN_AGE_TO_SELECT}
                max={MAX_AGE_TO_SELECT}
                value={toAge}
                onChange={handleMaxChange}
            />

            <StyledRangeSlider>
                <StyledSliderTrack />
                <StyledRange ref={range} />
            </StyledRangeSlider>
        </StyledMultiRangeSliderRoot>
    )
}
