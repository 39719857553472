import {Spinner} from '@/components/ui/spinner/Spinner'
import {useMe} from '@/features/authentication/queries/useMe'
import {GuestFooter} from '@/features/goal/components/goal-details/guest-footer/GuestFooter'
import {GoalNotFoundSection} from '@/features/goal/components/goal-details/not-found-goal-section/GoalNotFoundSection'
import {SupportersSection} from '@/features/goal/components/goal-details/supporters-section/SupportersSection'
import {useFetchGoal} from '@/features/goal/queries/useFetchGoal'
import {useHandleError} from '@/hooks/useHandleError'
import useWindowDimensions from '@/hooks/useWindowDimensions'
import {useRootStore} from '@/store'
import {
    selectChatChannels,
    selectConfirmSendGiftModal,
    selectCustomSendGiftModal,
    selectGoalModal
} from '@/store/selectors'
import {USER_ROLE_HOST} from '@/utilities/constants/user'
import {generateChatChannelName, getGoalIdFromSlug} from '@/utilities/helpers'
import {FC, useEffect, useState} from 'react'
import {useNavigate, useParams} from 'react-router-dom'
import {StyledGoalPageRoot, StyledSupportersSectionWrapper} from './style'
import {SuccessDonationGoal} from '@/features/goal/components/success-donation-goal/SuccessDonationGoal'
import {SendGiftStatusesE} from '@/features/gift/confirm-send-gift-modal/store/createConfirmSendGiftModalSlice'
import {GoalPageContent} from '@/features/goal/components/goal-page-content/GoalPageContent'
import {FirstDonationBanner} from '@/features/goal/components/first-donation-banner/FirstDonationBanner'
import {ChannelID} from '@/features/chat/types'

export const Component: FC = () => {
    const {closeModal: onClose, goalId: selectedGoalId} = useRootStore(selectGoalModal)
    const navigate = useNavigate()
    const isGoalModalMode = !!selectedGoalId
    const {height: vh} = useWindowDimensions()
    const {slug} = useParams()
    const goalId = selectedGoalId ? selectedGoalId : getGoalIdFromSlug(slug)
    const {data: goal, isLoading, isFetching, isError, error} = useFetchGoal(`${goalId}`)
    const {data} = useMe()
    const isHost = data?.type === USER_ROLE_HOST
    const [isFirstDonation, setIsFirstDonation] = useState(false)
    const [showFirstDonationBanner, setShowFirstDonationBanner] = useState(false)
    const channels = useRootStore(selectChatChannels)
    const channelId =
        data && goal && (generateChatChannelName(data?.id, data?.type ?? '', goal?.rookie_id) as ChannelID)
    const isChannelExist = !!channelId && !!channels[channelId]

    useEffect(() => {
        if (!goal?.leader_donations_amount && !isChannelExist) {
            setIsFirstDonation(true)
        }
    }, [goal])

    useHandleError({isError, error, isBlankPage: true})

    const {status: donationStandardAmountStatus, setStatus: setDonatioStandardAmountStatus} =
        useRootStore(selectConfirmSendGiftModal)
    const {status: donationCustomAmountStatus, setStatus: setDonationCustomAmountStatus} =
        useRootStore(selectCustomSendGiftModal)

    const status = donationStandardAmountStatus || donationCustomAmountStatus
    const setDonationStatus = () => {
        setDonatioStandardAmountStatus(null)
        setDonationCustomAmountStatus(null)
    }

    const handleClose = () => {
        if (isGoalModalMode) {
            onClose()
        } else {
            navigate(-1)
        }
    }

    useEffect(() => {
        return () => setDonationStatus()
    }, [])

    if (isLoading) {
        return <Spinner />
    }

    if (!!goal && !Object.keys(goal).length) {
        return <GoalNotFoundSection isGoalModalMode={isGoalModalMode} onClose={handleClose} />
    }

    return (
        <>
            {isFetching && <Spinner />}
            {status === SendGiftStatusesE.Success ? (
                <SuccessDonationGoal
                    isFirstDonation={isFirstDonation}
                    goal={goal}
                    onClose={() => {
                        setDonationStatus()
                        // first donation alert
                        if (isFirstDonation) {
                            setShowFirstDonationBanner(true)
                            setIsFirstDonation(false)
                        }
                    }}
                />
            ) : (
                <StyledGoalPageRoot vh={vh}>
                    {goal && (
                        <GoalPageContent goal={goal} handleClose={handleClose} isGoalModalMode={isGoalModalMode} />
                    )}

                    <StyledSupportersSectionWrapper>
                        {isHost ? (
                            <SupportersSection goalId={`${goal?.id}`} groupId={`${goal?.goal_group?.id}`} />
                        ) : (
                            <GuestFooter goal={goal} />
                        )}
                    </StyledSupportersSectionWrapper>

                    {showFirstDonationBanner && (
                        <FirstDonationBanner
                            onClose={() => setShowFirstDonationBanner(false)}
                            hostName={`${goal?.rookie?.full_name}`}
                            hostId={`${goal?.rookie_id}`}
                        />
                    )}
                </StyledGoalPageRoot>
            )}
        </>
    )
}
Component.displayName = 'Goal'
