import styled, {css} from 'styled-components'

type StyledLabelProps = {
    disabled?: boolean
    $labelPosition?: 'left' | 'right'
}

export const StyledCheckboxInput = styled.input`
    ${({theme: {palette}}) => css`
        &[type='checkbox'] {
            display: none;
            &:checked + ${StyledLabel}:before {
                border-color: ${palette.primary['200']};
                background-color: ${palette.primary['200']};
            }
            &:disabled + ${StyledLabel}:before {
                border-color: ${palette.neutral['950']};
            }
            &:checked:disabled + ${StyledLabel}:after {
                transform: scale(1) translateY(-50%);
            }
            &:checked:disabled + ${StyledLabel}:before {
                border-color: ${palette.neutral['500']};
                background-color: ${palette.neutral['500']};
            }
            &:checked + ${StyledLabel}:after {
                transform: scale(1) translateY(-50%);
            }
        }
    `}
`

export const StyledLabel = styled.label<StyledLabelProps>`
    ${({$labelPosition, disabled, theme: {spacing, palette, typography}}) => css`
        position: relative;
        font-size: ${typography.textSm};
        font-weight: 400;
        line-height: 19.6px;
        padding: ${$labelPosition === 'left' ? `0 ${spacing * 3.2}px 0 0` : `0 0 0 ${spacing * 3.2}px `};
        cursor: ${disabled ? 'not-allowed' : 'pointer'};
        white-space: pre-wrap;
        &:before,
        &:after {
            position: absolute;
            content: '';
            border-radius: 4px;
            transition: all 0.3s ease;
            transition-property: transform, border-color;
        }
        &:before {
            top: 50%;
            left: ${$labelPosition === 'right' && '0'};
            right: ${$labelPosition === 'left' && '0'};
            transform: translateY(-50%);
            width: 16px;
            height: 16px;
            border: 1px solid ${palette.neutral['500']};
        }

        &:after {
            top: 50%;
            left: ${$labelPosition === 'right' && '3px'};
            right: ${$labelPosition === 'left' && '3px'};
            transform: scale(0) translateY(-50%);
            width: 11px;
            height: 11px;
            background-image: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTAiIGhlaWdodD0iOCIgdmlld0JveD0iMCAwIDEwIDgiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxwYXRoIGQ9Ik0wLjUgMy41TDMuNSA2LjVMOSAxIiBzdHJva2U9IndoaXRlIi8+Cjwvc3ZnPgo=');
            background-repeat: no-repeat;
            background-size: contain;
            background-position: center;
        }
    `}
`

export const ErrorBox = styled.span`
    display: grid;
    position: relative;
`
