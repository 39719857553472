import {Modal} from '@/components/ui/modal/Modal'
import {useTranslation} from 'react-i18next'
import {StyledSubTitle} from './style'
import {useEffect, useState} from 'react'
import {MicromorgiPackage} from '../types'
import {useBuyMicromorgiPackage} from '../queries/useBuyMicromorgiPackage'
import {ReachedLimitContent} from './components/reachedLimitContent/ReachedLimitContent'
import {Spinner} from '@/components/ui/spinner/Spinner'
import {useRootStore} from '@/store'
import {selectChoosePaymentsMethodModal, selectReachedLimitModal} from '@/store/selectors'
import {useMe} from '@/features/authentication/queries/useMe'
import {GuestType} from '@/features/authentication/types'

export enum Variants {
    PURCHASE = 'purchase',
    SUCCESS = 'success',
    FAILURE = 'failure'
}

export const ReachedLimitModal = ({onCloseCallBack}: {onCloseCallBack?: () => void}) => {
    const {t} = useTranslation()
    const {data} = useMe()
    const user = data as GuestType
    const firstTransaction = user?.type_attributes.is_first_transaction
    const {closeModal: closeModalHandler} = useRootStore(selectReachedLimitModal)
    const {openModal: openChoosePaymentsMethodModal} = useRootStore(selectChoosePaymentsMethodModal)
    const [selectedOption, setSelectedOption] = useState<MicromorgiPackage | undefined>(undefined)
    const {
        mutate: buyMicromorgiPackage,
        data: buyMicromorgiPackageData,
        error: buyMicromorgiPackageError,
        isPending
    } = useBuyMicromorgiPackage()
    const [variant, setVariant] = useState<Variants>(Variants.PURCHASE)

    useEffect(() => {
        if (buyMicromorgiPackageData) {
            setVariant(Variants.SUCCESS)
        }
        if (buyMicromorgiPackageError && firstTransaction) {
            if (buyMicromorgiPackageError?.response?.status === 303) {
                openChoosePaymentsMethodModal({
                    paymentMethodUrls: buyMicromorgiPackageError?.response?.data
                })
            }
        }
        if (buyMicromorgiPackageError) {
            setVariant(Variants.FAILURE)
        }
    }, [buyMicromorgiPackageData, buyMicromorgiPackageError])

    const backToBuyMicromorgiPackage = () => {
        setVariant(Variants.PURCHASE)
    }

    const findSelectedOption = (pack: MicromorgiPackage) => {
        return pack === selectedOption
    }
    const selectPackClick = (pack: MicromorgiPackage) => {
        setSelectedOption(pack)
    }

    const handleBuyMicromorgiPackage = (packageId: string) => {
        buyMicromorgiPackage({packageId: packageId})
    }

    return (
        <Modal
            onClose={onCloseCallBack ? onCloseCallBack : closeModalHandler}
            maxHeight={'600px'}
            height={'550px'}
            title={t('riched_limit_modal:title')}
            subHeader={<StyledSubTitle>{t('riched_limit_modal:subtitle')}</StyledSubTitle>}
        >
            {isPending ? (
                <Spinner />
            ) : (
                <div>
                    <ReachedLimitContent
                        variant={variant}
                        backToBuyMicromorgiPackage={backToBuyMicromorgiPackage}
                        findSelectedOption={findSelectedOption}
                        handleBuyMicromorgiPackage={handleBuyMicromorgiPackage}
                        selectedOption={selectedOption}
                        selectPackClick={selectPackClick}
                    />
                </div>
            )}
        </Modal>
    )
}
