import {AlertStatus} from '@/features/authentication/types'
import {Lens} from '@dhmk/zustand-lens'

type AlertsType = {
    variant: AlertStatus
    message?: string
    title?: string
    dismissTimeout?: number
    onAlertClick?: () => void
}

export type AlertsStoreType = {
    alerts: AlertsType[]
    addAlert: ({variant, title, message, onAlertClick}: AlertsType) => void
    removeAlert: (index: number) => void
}

const createAlertsSlice: Lens<AlertsStoreType> = set => ({
    alerts: [],
    addAlert: ({variant, message, title, dismissTimeout, onAlertClick}) =>
        set(state => ({
            alerts: [...state.alerts, {variant, message, title, dismissTimeout, onAlertClick}]
        })),
    removeAlert: index =>
        set(state => ({
            alerts: state.alerts.filter((_, i) => i !== index)
        }))
})
export default createAlertsSlice
