import {SORT_OPTIONS} from '@/features/goal/constant/goal'
import {SortParam} from '@/features/goal/types'
import {Lens} from '@dhmk/zustand-lens'

export type FiltersType = {
    typeId?: string
    pathIDs: string[]
    selectedFiltersNames: string[]
}

type FiltersState = {
    filters: FiltersType
    sortParams: SortParam
}

const initialState: FiltersState = {
    filters: {
        typeId: undefined,
        pathIDs: [],
        selectedFiltersNames: []
    },
    sortParams: SORT_OPTIONS[0].param
}

type GoalFiltersAction = {
    setFilters: (filters: FiltersType) => void
    setSortParams: (sortParams: SortParam) => void
    clearGoalFilters: () => void
}

export type GoalFiltersState = FiltersState & GoalFiltersAction

export const createGoalFiltersSlice: Lens<GoalFiltersState> = set => ({
    ...initialState,
    setFilters: filters => set({filters}),
    setSortParams: sortParams => set({sortParams}),
    clearGoalFilters: () => set(initialState)
})
