import {motion} from 'framer-motion'
import styled, {css, DefaultTheme, FlattenSimpleInterpolation} from 'styled-components'

export const StyledOverlay = styled.div(
    ({theme: {palette, zIndex}}) => css`
        position: fixed;
        inset: 0;
        background: ${palette.neutral[700]};
        z-index: ${zIndex.modalOverlay};
        display: grid;
        align-content: center;
        justify-content: center;
    `
)

interface StyledModalProps {
    width?: string
    height?: string
    minWidth?: string
    maxWidth?: string
    minHeight?: string
    maxHeight?: string
    fullScreen: boolean
    theme: DefaultTheme
    withSubHeader?: boolean
    modalStyles?: (theme: DefaultTheme) => FlattenSimpleInterpolation
    vh: number
}

export const StyledModal = styled(motion.div)(
    ({
        width,
        height,
        minWidth,
        maxWidth,
        minHeight,
        maxHeight,
        fullScreen,
        modalStyles,
        withSubHeader,
        vh,
        theme: {palette, zIndex, mediaQueries}
    }: StyledModalProps) => css`
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background-color: ${palette.neutral.white};
        overflow: visible;
        z-index: ${zIndex.modal};
        border-radius: 10px;

        display: grid;
        grid-template-rows: ${withSubHeader ? '0fr 0fr 1fr' : '0fr 1fr'};

        min-width: ${minWidth ?? undefined};
        min-height: ${minHeight ?? undefined};
        max-width: ${maxWidth ?? undefined};
        max-height: ${maxHeight ?? undefined};
        width: ${width ?? '92%'};
        height: ${height ?? '557px'};

        ${mediaQueries.m} {
            width: ${width ?? '400px'};
            max-height: ${maxHeight ?? '90vh'};
        }

        ${fullScreen &&
        css`
            border-radius: 0;
            display: flex;
            flex-direction: column;
            width: 100vw;
            height: ${vh}px;
            ${mediaQueries.m} {
                width: ${width ?? '500px'};
                max-height: ${maxHeight ?? '668px'};
                border-radius: 16px;
            }
        `}
        ${({theme}) => modalStyles && modalStyles(theme)};
    `
)

export const StyledHeader = styled.div(
    ({theme: {spacing}}) => css`
        display: grid;
        grid-template-columns: ${spacing * 4}px 1fr ${spacing * 4}px;
        place-items: center;
        padding: ${spacing * 2}px;
        white-space: pre-wrap;
        position: relative;
        font-weight: 700;
        & span {
            height: 16px;
            grid-column: 1;
        }
        & h4 {
            grid-column: 2;
            margin-top: 0px;
            text-align: center;
        }
        & svg {
            grid-column: 3;
            cursor: pointer;
        }
    `
)
