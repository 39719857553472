import {palette} from '@/theme/palette'
import ResponsiveVideo from '@/components/commons/responsive-video/ResponsiveVideo'
import {spacing} from '@/theme/theme'
import {typography} from '@/theme/typography'
import styled, {DefaultTheme, css} from 'styled-components'

export const StyledCardRoot = styled.div`
    ${({theme: {mediaQueries}}) => css`
        display: grid;
        align-items: center;
        grid-template-columns: 0fr auto 0fr;
        padding: ${spacing * 1.25}px;
        gap: ${spacing * 1.25}px;
        ${mediaQueries.m} {
            padding: ${spacing * 2}px;
            gap: ${spacing * 2}px;
        }
    `}
`

export const StyledPastGoalSection = styled.div`
    ${({theme: {palette}}) => css`
        background-color: ${palette.neutral.black};
        color: ${palette.neutral.white};
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
        padding: ${spacing / 2}px ${spacing * 2}px ${spacing / 2}px;
        ${typography.textXs};
        font-weight: 700;
    `}
`

export const StyledMedia = styled.div`
    ${({theme: {palette}}) => css`
        border: 1px solid ${palette.neutral[950]};
        border-radius: 10px;
    `}
`

export const StyledInfo = styled.div`
    ${({theme: {mediaQueries}}) => css`
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        gap: ${spacing / 2}px;
        & div {
            display: flex;
            align-items: center;
            flex-wrap: wrap;
            gap: ${spacing / 2}px;
            ${mediaQueries.m} {
                gap: ${spacing}px;
            }
        }
    `}
`

export const StyledGoalIcon = styled.div`
    & svg {
        height: 16px;
        width: 16px;
    }
`

export const StyledCardTitle = styled.div`
    display: flex;
    gap: ${spacing}px;
    font-weight: 700;
    ${typography.textXs}
`

export const StyledAmount = styled.span`
    ${typography.textXxs};
    font-weight: 700;
`

export const rootCardClass = ({isActive}: {isActive?: boolean}) => css`
    box-shadow: rgba(0, 0, 0, 0.15) 0px 4px 10px 0px;
    border-radius: 10px;

    ${isActive &&
    css`
        border: 2px solid ${palette.light.primary['200']};
    `}
`

export const bodyCardClass = () => css`
    padding: 0;
`

export const tagIsForOthersStyles = (theme: DefaultTheme) => css`
    background: ${theme.palette.gradient['300']};
    color: ${theme.palette.neutral.white};
    max-width: 140px;
    & span {
        ${theme.truncateText}
    }
`
export const StyledResponsiveVideo = styled(ResponsiveVideo)`
    border-radius: 10%;
    width: 57px;
    height: 57px;
`

export const StyledHint = styled.p`
    ${({theme: {palette, spacing, typography, mediaQueries}}) => css`
        color: ${palette.primary[700]};
        padding-bottom: ${spacing * 2}px;
        ${typography.textXxs};
        ${mediaQueries.m} {
            ${typography.textXs};
        }
        ${mediaQueries.l} {
            ${typography.textSm};
        }
    `}
`
export const StyledCardContainer = styled.div`
    ${() => css`
        display: flex;
        flex-direction: column;
        gap: 16px;
    `}
`
