import axios from '@/clientAPI.ts'
import {ExpertiseResponse, Topic, TopicsResponse, WishResponse, WishToBecomeResponse, Wish} from '../types'
import {
    ExpertiseResponseSchema,
    TopicSchema,
    TopicsResponseSchema,
    WishResponseSchema,
    WishSchema,
    WishToBecomeResponseSchema
} from './chatFavTopics.schema'

export const CHAT_FAV_TOPICS_API = {
    getChatTopics: async (is_popular: boolean): Promise<TopicsResponse> => {
        const url = '/chat-topics'
        const {data} = await axios.get(url, {params: {is_popular}})
        return TopicsResponseSchema.parse(data)
    },
    getFavActivities: async (is_popular: boolean): Promise<TopicsResponse> => {
        const url = '/favorite-activities'
        const {data} = await axios.get(url, {params: {is_popular}})
        return TopicsResponseSchema.parse(data)
    },
    createChatTopic: async (name: string): Promise<Topic> => {
        const url = '/chat-topics'
        const {data} = await axios.post(url, {name})
        return TopicSchema.parse(data)
    },
    createFavActivity: async (name: string): Promise<Topic> => {
        const url = '/favorite-activities'
        const {data} = await axios.post(url, {name})
        return TopicSchema.parse(data)
    },
    getFilterChatTopics: async (is_popular: boolean, filter_for_component: boolean): Promise<TopicsResponse> => {
        const params = {
            response_type: 'extended',
            is_popular,
            filter_for_component
        }
        const url = '/chat-topics/filters'
        const {data} = await axios.get(url, {params})
        return TopicsResponseSchema.parse(data)
    },
    getFilterFavActivities: async (is_popular: boolean, filter_for_component: boolean): Promise<TopicsResponse> => {
        const params = {
            response_type: 'extended',
            is_popular,
            filter_for_component
        }
        const url = '/favorite-activities/filters'
        const {data} = await axios.get(url, {params})
        return TopicsResponseSchema.parse(data)
    },
    getWish: async (): Promise<WishResponse> => {
        const url = '/wishes'
        const {data} = await axios.get(url)
        return WishResponseSchema.parse(data)
    },
    createWish: async (name: string): Promise<Wish> => {
        const url = '/wishes'
        const {data} = await axios.post(url, {name})
        return WishSchema.parse(data)
    },
    getWishesToBecome: async (): Promise<WishToBecomeResponse> => {
        const url = `/paths`
        const {data} = await axios.get(url, {params: {response_type: 'extended'}})
        return WishToBecomeResponseSchema.parse(data)
    },
    getExpertises: async (expertise: string): Promise<ExpertiseResponse> => {
        const category: {[key: string]: string} = {
            art: '?category=art',
            sport: '?category=sport'
        }
        const url = `/expertises${category[expertise] || ''}`
        const {data} = await axios.get(url)
        return ExpertiseResponseSchema.parse(data)
    },
    getExpertisesCategories: async (): Promise<{category: string; category_name: string}[]> => {
        const url = `/expertises/categories`
        const {data} = await axios.get(url)
        return data
    }
}
