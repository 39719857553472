import {MicromorgiIcon} from '@/assets/icons/icons'
import styled, {DefaultTheme, css} from 'styled-components'

export const PurchaseBox = styled.div`
    ${({theme: {spacing}}) => css`
        position: relative;
        display: grid;
        grid-template-columns: 1fr 1fr;
        align-items: center;
        justify-content: space-around;
        height: 100%;
        max-height: 270px;
        overflow-y: auto;
        padding: ${spacing * 2}px ${spacing * 3}px;
    `}
`

export const StyledPackContainer = styled.div`
    ${({selected, theme: {spacing, palette}}: {selected?: boolean; theme: DefaultTheme}) => css`
        color: ${selected ? palette.primary['200'] : palette.primary['900']};
        padding: ${spacing * 3}px 0;
        scale: ${selected ? '1.1' : 'none'};
        cursor: pointer;
    `}
`

export const StyleMicromorgiIcon = styled(MicromorgiIcon)`
    width: 40px;
    height: 40px;
`

export const StyledAmount = styled.span`
    ${({theme: {typography}}) => css`
        ${typography.displayMd}
        font-weight: 700;
    `}
`

export const StyledAmountContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
`

export const StyledPackPrice = styled.span`
    ${({theme: {mediaQueries, typography}}) => css`
        display: block;
        text-align: center;
        font-weight: 400;
        line-height: 140%;
        ${typography.textXs}
        ${mediaQueries.m} {
            ${typography.textSm}
        }
    `}
`

export const StyledButtonBox = styled.div`
    ${({theme: {spacing}}) => css`
        padding: ${spacing * 2}px ${spacing * 3}px;
    `}
`

export const StyledPackPriceBox = styled.div`
    ${({theme: {spacing}}) => css`
        padding: ${spacing * 1.75}px ${spacing * 3}px 0;
    `}
`

export const StyledDivider = styled.div`
    position: fixed;
    top: 35%;
    height: 45%;
    left: 48%;
    width: 1px;
    background-color: rgba(0, 6, 61, 0.1);
`
