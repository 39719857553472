import styled, {css} from 'styled-components'

type AmountItemContainerProps = {
    $isActive: boolean
    disabled?: boolean
}
export const StyledAmountItemContainer = styled.div<AmountItemContainerProps>`
    ${({theme: {palette, spacing}, $isActive, disabled}) => css`
        cursor: pointer;
        min-height: 84px;
        max-height: 84px;
        display: flex;
        gap: ${spacing}px;
        padding-right: 8px;

        border-bottom: 1px solid ${palette.primary[50]};
        justify-content: center;
        flex-direction: column;
        ${!!disabled &&
        css`
            cursor: not-allowed;
            & span {
                opacity: 0.5;
            }
        `}

        &:hover {
            background-color: ${palette.primary[300]};
        }
        ${$isActive
            ? `
            background-color: ${palette.primary[300]};
        `
            : ``}
    `}
`
