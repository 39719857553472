import {useMe} from '@/features/authentication/queries/useMe'
import {useGetLanguages} from '@/features/country/queries/useGetLanguages'
import {useRootStore} from '@/store'
import {selectGlobalTranslateStore} from '@/store/selectors'
import {USER_ROLE_GUEST, USER_ROLE_HOST, USER_ROLE_REPRESENTATIVE} from '@/utilities/constants/user'
import {useEffect} from 'react'
import {useTranslation} from 'react-i18next'

export const useGetAllLanguages = () => {
    const {translateToggle} = useRootStore(selectGlobalTranslateStore)
    const {data: user} = useMe()

    const {data: languages, refetch: refetchLanguages} = useGetLanguages()

    const {
        i18n: {language}
    } = useTranslation()

    useEffect(() => {
        if (
            (translateToggle && user?.type === USER_ROLE_GUEST) ||
            [USER_ROLE_HOST, USER_ROLE_REPRESENTATIVE].includes(user?.type as string) ||
            !user
        )
            refetchLanguages()
    }, [language, translateToggle])

    return {
        languages: languages?.length ? languages.map(language => ({...language, key_name: language.code})) : []
    }
}
