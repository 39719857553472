import {spacing} from '@/theme/theme'
import styled, {DefaultTheme, css} from 'styled-components'

export const StyledGoalPromoWrapper = styled.div`
    ${({theme: {palette}}) => css`
        border: 1px solid ${palette.primary[200]};
        border-radius: ${spacing * 1.5}px;
        box-shadow: 0px ${spacing / 2}px ${spacing * 2}px 0px rgba(139, 109, 232, 0.6);
        position: relative;
        margin-top: ${spacing * 3}px;
        width: 100%;
        max-width: 360px;
        background: ${palette.neutral.white};
    `}
`
export const StyledPromoLabel = styled.div`
    ${({
        theme: {palette, typography},
        promoType,
        promoLabelGradients
    }: {
        theme: DefaultTheme
        promoType?: string
        promoLabelGradients?: string
    }) => css`
        z-index: 1;
        position: absolute;
        top: -${spacing * 2.5}px;
        right: 15px;
        padding: ${spacing}px;
        border-radius: ${spacing}px;
        box-shadow: 0px ${spacing / 2}px ${spacing / 2}px 0px rgba(0, 0, 0, 0.25);
        color: ${promoType === 'cashback' ? palette.neutral.black : palette.neutral.white};
        background: ${promoLabelGradients};
        text-align: center;
        ${typography.textMd}
        font-weight: 700;
        letter-spacing: -0.3px;
    `}
`

export const StyledGoalTypeLabel = styled.div`
    ${({theme: {palette, spacing, typography}}) => css`
        display: flex;
        align-items: center;
        justify-content: center;
        gap: ${spacing / 2}px;
        ${typography.textXs}
        font-weight: 700;
        padding: ${spacing}px;
        border-radius: ${spacing * 2}px;
        border: 1px solid ${palette.primary[200]};
        background: #3d3230;
        color: ${palette.neutral.white};
        position: absolute;
        top: -22px;
        left: -15px;
        z-index: 2;
    `}
`
