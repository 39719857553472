import {Modal} from '@/components/ui/modal/Modal'
import {
    StyledBody,
    StyledItem,
    StyledPopoverArrow,
    StyledPopoverContent,
    StyledSubtitle,
    StyledText,
    modalStyles
} from '@/features/goal/components/share-goal-modal/style'
import {useTranslation} from 'react-i18next'
import {
    EmailShareButton,
    FacebookShareButton,
    TwitterShareButton,
    TelegramShareButton,
    WhatsappShareButton
} from 'react-share'
import {EmailIcon, FacebookIcon, LinkIcon, TelegramMonoIcon, TwitterIcon, WhatsappMonoIcon} from '@/assets/icons/icons'

import {getPublicUrl} from '@/utilities/helpers'
import {useMe} from '@/features/authentication/queries/useMe'
import useCopyToClipboard from '@/hooks/useCopyToClipboard'
import {Popover, PopoverSideE} from '@/components/ui/popover/Popover'
import {useState} from 'react'
import {PublicRoute} from '@/utilities/constants/publicRoutes'
import {USER_ROLE_HOST} from '@/utilities/constants/user'
import {GenderKeyNameE} from '@/features/constants/constants'
import {StyledLabel} from './style'

type ShareProfileModalType = {
    onClose: () => void
    username?: string
    hostGender?: GenderKeyNameE
    type?: 'profile' | 'gift'
}

export const ShareProfileModal = ({onClose, username, hostGender, type = 'profile'}: ShareProfileModalType) => {
    const {t} = useTranslation()
    const {data: user} = useMe()
    const [isOpenPopover, setIsOpenPopover] = useState(false)

    const onSuccess = () => {
        setIsOpenPopover(true)
        setTimeout(() => {
            setIsOpenPopover(false)
        }, 2000)
    }
    const {copyToClipboard} = useCopyToClipboard(onSuccess)

    const url = getPublicUrl(`${PublicRoute.SHARE_ROOKIE_PROFILE}${username}?share=true`)
    const isHost = user?.type === USER_ROLE_HOST

    const hostMessage = t('invite_friends:invite_message', {rookie_link: url})
    const guestMessage = `${t('share_messages:leader_sharing', {
        context: hostGender
    })} ${url}`

    const profileShareMessage = isHost ? hostMessage : guestMessage
    const giftShareMessage = `${t('share_messages:successful_transaction')} ${url}`

    const getMessage = () => {
        switch (type) {
            case 'gift':
                return giftShareMessage
            case 'profile':
            default:
                return profileShareMessage
        }
    }

    const emailSubject = type === 'profile' ? t('share_messages:email_subject') : ''

    const mergedOptions = {
        facebook: true,
        twitter: true,
        telegram: true,
        whatsapp: true,
        email: true,
        link: true
    }

    return (
        <Modal onClose={onClose} title={t('common:share')} modalStyles={modalStyles}>
            <StyledSubtitle>{t('gift_rookie:share_with_friends')}</StyledSubtitle>
            <StyledBody>
                <StyledLabel>{t('common:messaging')}</StyledLabel>
                {mergedOptions.telegram && (
                    <TelegramShareButton resetButtonStyle={false} url={getMessage()}>
                        <StyledItem>
                            <TelegramMonoIcon />
                            <StyledText>{t('share:telegram')}</StyledText>
                        </StyledItem>
                    </TelegramShareButton>
                )}
                {mergedOptions.whatsapp && (
                    <WhatsappShareButton resetButtonStyle={false} url={getMessage()}>
                        <StyledItem>
                            <WhatsappMonoIcon />
                            <StyledText>{t('share:whatsapp')}</StyledText>
                        </StyledItem>
                    </WhatsappShareButton>
                )}
                {mergedOptions.email && (
                    <EmailShareButton resetButtonStyle={false} url={getMessage()} subject={emailSubject}>
                        <StyledItem>
                            <EmailIcon />
                            <StyledText>{t('share:email')}</StyledText>
                        </StyledItem>
                    </EmailShareButton>
                )}
                {mergedOptions.link && (
                    <Popover
                        isModal
                        rootProps={{
                            open: isOpenPopover
                        }}
                        trigger={
                            <StyledItem onClick={() => copyToClipboard(url)}>
                                <LinkIcon />
                                <StyledText> {t('share:copy_link')}</StyledText>
                            </StyledItem>
                        }
                        triggerProps={{asChild: false}}
                        side={PopoverSideE.top}
                    >
                        <StyledPopoverContent>
                            <StyledPopoverArrow width={14} height={13} />
                            {t('share:tooltip_content')}
                        </StyledPopoverContent>
                    </Popover>
                )}

                <StyledLabel>{t('common:social')}</StyledLabel>
                {mergedOptions.facebook && (
                    <FacebookShareButton resetButtonStyle={false} url={url} hashtag={getMessage()}>
                        <StyledItem>
                            <FacebookIcon />
                            <StyledText>{t('share:facebook')}</StyledText>
                        </StyledItem>
                    </FacebookShareButton>
                )}
                {mergedOptions.twitter && (
                    <TwitterShareButton resetButtonStyle={false} url={getMessage()}>
                        <StyledItem>
                            <TwitterIcon />
                            <StyledText>{t('share:twitter')}</StyledText>
                        </StyledItem>
                    </TwitterShareButton>
                )}
            </StyledBody>
        </Modal>
    )
}
