import {Lens} from '@dhmk/zustand-lens'

type GifModalState = {
    isOpen: boolean
}

type GifModalActions = {
    openModal: (params: Omit<GifModalState, 'isOpen'>) => void
    closeModal: () => void
}

export type GifModal = GifModalState & GifModalActions

const initialModal: GifModalState = {isOpen: false}

const createGifModalSlice: Lens<GifModal> = set => ({
    ...initialModal,
    openModal: params => set(() => ({...params, isOpen: true})),
    closeModal: () => set(() => ({...initialModal}))
})

export default createGifModalSlice
