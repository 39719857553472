import {GuestProfileExtendedSchema} from '@/features/authentication/services/auth.schema'
import {z} from 'zod'

export const ProfilePhotoResponseSchema = z.object({path_location: z.string(), url: z.string()})
export const GuestProfileSchema = GuestProfileExtendedSchema.deepPartial()
export const ProfileRandomAvatarsSchema = z.object({
    is_gifted: z.boolean(),
    data: z.array(
        z.object({
            id: z.number(),
            user_id: z.number(),
            url: z.string(),
            path_location: z.string(),
            main: z.boolean(),
            created_at: z.string(),
            under_validation: z.boolean()
        })
    )
})
