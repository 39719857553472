import {Button} from '@/components/ui/button/Button'
import styled, {css} from 'styled-components'

export const MegaGiftSectionContainer = styled.div`
    ${({theme: {palette, spacing, flexUtils}}) => css`
        ${flexUtils.centered}
        width: 100%;
        padding-top: ${spacing * 2.5}px;
        background-color: ${palette.neutral.white};
        justify-content: space-around;
        border-radius: 10px;
    `}
`
export const MegaGiftSectionImage = styled.img`
    ${() => css`
        width: 40px;
        height: 40px;
    `}
`
export const MegaGiftSectionDescription = styled.div`
    ${({theme: {typography}}) => css`
        ${typography.textSm}
        display: flex;
        align-items: start;
        font-weight: 600;
        line-height: normal;
        flex-direction: column;
        letter-spacing: -0.7px;
        justify-content: center;
    `}
`
export const StyledPopoverContent = styled.div`
    ${({theme: {typography, spacing}}) => css`
        white-space: break-spaces;
        padding: ${spacing * 2}px;
        max-width: 280px;

        & > h4 {
            font-weight: bold;
            ${typography.textSm};
            margin-bottom: ${spacing / 2}px;
            text-align: center;
        }

        & > p {
            ${typography.textXs};
        }
    `}
`

export const MegaGiftSectionDescriptionCost = styled.div`
    ${() => css`
        display: flex;
        align-items: center;
    `}
`
export const MegaGiftSectionSendButton = styled(Button)`
    ${({theme: {typography, spacing, palette}}) => css`
        ${typography.textSm}
        width: 90px;
        padding: 0;
        font-weight: 600;
        line-height: ${spacing * 2}px;
        white-space: nowrap;
        border-radius: 100px;
        letter-spacing: -0.3px;
        border: 1px solid ${palette.primary[100]};
        height: 28px;
        min-height: 28px;
    `}
`
