import styled, {css} from 'styled-components'

type StyledDividerProps = {
    width?: number
    height: number
    topSpacing: number
    bottomSpacing: number
    background?: string
}

export const StyledDivider = styled.div<StyledDividerProps>`
    ${({width, height, topSpacing, bottomSpacing, background, theme: {palette}}) => css`
        width: ${width ? `${width}px` : '100%'};
        height: ${height}px;
        margin-top: ${topSpacing}px;
        margin-bottom: ${bottomSpacing}px;
        background: ${background ?? palette.neutral['950']};
    `}
`
