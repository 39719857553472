import {Lens} from '@dhmk/zustand-lens'

type UpdateCreditCardState = {
    isOpen: boolean
}

type UpdateCreditCardActions = {
    openModal: (params: Omit<UpdateCreditCardState, 'isOpen'>) => void
    closeModal: () => void
}

export type UpdateCreditCard = UpdateCreditCardState & UpdateCreditCardActions

const initialModal: UpdateCreditCardState = {isOpen: false}

const createUpdateCreditCardModalSlice: Lens<UpdateCreditCard> = set => ({
    ...initialModal,
    openModal: params => set(() => ({...params, isOpen: true})),
    closeModal: () => set(() => ({...initialModal}))
})

export default createUpdateCreditCardModalSlice
