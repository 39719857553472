import {GuestType} from '@/features/authentication/types.ts'
import {FC} from 'react'
import {
    RootContainer,
    StyledTelegramBanner,
    StyledTelegramIconContainer,
    StyledTelegramIconWrapper,
    StyledTextContainer
} from './style'
import {RightArrowActionIcon, TelegramIcon} from '@/assets/icons/icons'
import {useTranslation} from 'react-i18next'
import useMixPanel from '@/integrations/mixpanel/hooks/useMixpanel'
import {MixpanelEventNameE} from '@/integrations/mixpanel/types'
import {LocalStorageManager} from '@/utilities/localStorage'
import {telegramBaseURL} from '@/utilities/constants/misc'
import {envVars} from '@/envVars'
import { TagManager } from '@/integrations/tag-manager/tagManager'

type TelegramBotCardProps = {
    user: GuestType
}

export const TelegramBotCard: FC<TelegramBotCardProps> = ({user}) => {
    const {t} = useTranslation()
    const mixPanelWrapper = useMixPanel()

    const handleClickTelegramBotCard = () => {
        {
            mixPanelWrapper &&
                mixPanelWrapper.trackEvent(MixpanelEventNameE.UserClickedOnTelegramTeaser, {
                    teasers_seen_by_user_lifetime: LocalStorageManager.fiftyRookieCardSessionCount.get()
                })
        }
        TagManager.dataLayer({
            event: MixpanelEventNameE.ConnectTelegramClick
        })
        window.open(`${telegramBaseURL}${envVars.VITE_APP_TELEGRAM_BOT_NAME}?start=${user.telegram_bot_token}`)
    }

    return (
        <RootContainer>
            <StyledTelegramBanner>
                <StyledTextContainer>
                    <h4>{t('interactive_feed:telegram_bot_card:title')}</h4>
                    <p>{t('interactive_feed:telegram_bot_card:description')}</p>
                </StyledTextContainer>
                <StyledTelegramIconWrapper onClick={handleClickTelegramBotCard}>
                    <StyledTelegramIconContainer>
                        <TelegramIcon fill="url(#paint0_linear_6086_2968)" />
                        <RightArrowActionIcon />
                    </StyledTelegramIconContainer>
                </StyledTelegramIconWrapper>
            </StyledTelegramBanner>
        </RootContainer>
    )
}
