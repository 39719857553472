import {useEffect} from 'react'
import {useLocation} from 'react-router-dom'

export const useLocationChange = (action: (pageName: string) => void) => {
    const {pathname} = useLocation()
    // TODO: put "name" inside the route's object and use this
    const pageName = pathname.substring(1) ? pathname.substring(1) : 'home'
    useEffect(() => {
        action(pageName)
    }, [pathname])
}
