export const MUTATION_KEYS = {
    create_chat_topic: 'create-chat-topic',
    create_favorite_activity: 'create-favorite-activity',
    create_wish: 'create-wish',
    create_wishes_to_become: 'create-wishes-to-become',
    create_chat_activities: 'create-chat-activities'
}

export const QUERY_KEYS = {
    chat_topics: 'chat-topics',
    favorite_activities: 'favorite-activities',
    filter_chat_topics: 'filter-chat-topics',
    filter_favorite_activities: 'filter-favorite-activities',
    wish: 'wish',
    wishes_to_become: 'wishes-to-become',
    expertises: 'expertises',
    expertises_categories: 'expertises-categories'
}
