import {Button} from '@/components/ui/button/Button'
import styled, {DefaultTheme, css} from 'styled-components'

export const modalStyles = (theme: DefaultTheme) => css`
    max-height: 100vh;
    width: 100vw;
    ${theme.mediaQueries.m} {
        width: 500px;
        height: 100%;
    }
`
export const StyledModalContainer = styled.div`
    ${({theme: {spacing}}) => css`
        padding: 0px ${spacing * 2.3}px ${spacing * 2.3}px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 100%;
    `}
`
export const StyledImageSection = styled.div`
    ${({theme: {spacing}}) => css`
        display: flex;
        margin-bottom: ${spacing * 2}px;
    `}
`
export const StyledCrownIconBox = styled.div`
    ${({theme: {palette}}) => css`
        width: 68px;
        height: 68px;
        display: flex;
        z-index: 1;
        align-items: center;
        border-radius: 50%;
        justify-content: center;
        background-color: ${palette.primary['200']};
    `}
`

export const StyledAvatarBox = styled.div`
    ${({theme: {spacing}}) => css`
        margin-left: -${spacing * 1.5}px;
    `}
`

export const StyledHeader = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
`

export const StyledTitle = styled.h3`
    ${({theme: {spacing, typography}}) => css`
        ${typography.textLg};
        font-weight: 900;
        margin-bottom: ${spacing}px;
    `}
`

export const StyledSubTitle = styled.span`
    ${({theme: {palette}}) => css`
        color: ${palette.primary['700']};
        font-weight: 600;
    `}
`

export const StyledTitleBox = styled.div`
    ${({theme: {spacing}}) => css`
        text-align: center;
        margin-bottom: ${spacing * 2}px;
    `}
`

export const StyledFooterBox = styled.div`
    ${({theme: {spacing}}) => css`
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
        padding-top: ${spacing * 2}px;
    `}
`

export const StyledButtonBox = styled.div`
    ${({theme: {mediaQueries, spacing}}) => css`
        width: 90%;
        margin-bottom: ${spacing * 2}px;
        ${mediaQueries.s} {
            width: 80%;
        }
        ${mediaQueries.l} {
            width: 90%;
        }
    `}
`

export const StyledButton = styled(Button)`
    ${({theme: {spacing}}) => css`
        padding: ${spacing * 2}px ${spacing * 3}px;
    `}
`

export const StyledCancelButton = styled.div`
    ${({theme: {spacing}}) => css`
        cursor: pointer;
        margin-bottom: ${spacing * 2}px;
        text-decoration: underline;
    `}
`

export const StyledExplanation = styled.span`
    ${({theme: {typography, palette}}) => css`
        color: ${palette.primary['700']};
        ${typography.textXs};
        font-weight: 600;
    `}
`

export const StyledTimerBox = styled.div`
    ${({theme: {typography, palette}}) => css`
        display: flex;
        justify-content: center;
        div {
            ${typography.textSm};
            color: ${palette.primary['700']};
        }
    `}
`
