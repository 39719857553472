import styled, {css} from 'styled-components'

export const StyledBody = styled.div`
    ${({theme: {spacing}}) => css`
        margin-top: -${spacing * 5}px;
    `}
`

export const StyledHeader = styled.div`
    ${({theme: {spacing, mediaQueries, palette, typography}}) => css`
        text-align: center;
        & h1 {
            padding: ${spacing}px 0px;
            ${typography.textXXl}
            font-weight: 500;
            letter-spacing: -0.05em;
            margin: ${spacing / 2}px 0px;
            ${mediaQueries.m} {
                ${typography.displayMd}
            }
        }
        & p {
            color: ${palette.primary[700]};
            white-space: pre-line;
            margin-bottom: ${spacing * 3}px;
        }
    `}
`

export const StyledButtonsWrapper = styled.div`
    ${({theme: {spacing}}) => css`
        display: flex;
        flex-direction: column;
        padding: ${spacing}px 0;
        gap: ${spacing}px;

        & button {
            width: 100%;
            padding: ${spacing * 2}px ${spacing * 3}px;
            grid-template-columns: 0fr auto;
            justify-content: center;
            gap: ${spacing}px;
        }
    `}
`

export const StyledContentWrapper = styled.div`
    width: 100%;
    margin: 0px auto;
    display: flex;
    max-width: 270px;
    flex-direction: column;
`
export const StyledCropWrapper = styled.div`
    ${({theme: {spacing, palette}}) => css`
        width: 100%;
        border: 1px solid ${palette.neutral.black};
        height: 360px;
        margin: 0px 0px ${spacing * 2}px;
        z-index: 1034;
        overflow: hidden;
        position: relative;
        border-radius: ${spacing}px;
    `}
`
