import {useMutation, useQueryClient} from '@tanstack/react-query'
import {MUTATION_KEYS} from './keys'
import {PROFILE_API} from '../services/profile.http'
import {EditUserPayload} from '../services/types'
import {QUERY_KEYS} from '@/features/authentication/queries/keys.ts'
import {User} from '@/features/authentication/types'

export const useEditUser = (onSuccess?: (data?: User) => void) => {
    const queryClient = useQueryClient()
    return useMutation({
        mutationKey: [MUTATION_KEYS.edit_user],
        mutationFn: (body: EditUserPayload) => PROFILE_API.updateProfile(body),
        onSuccess: data => {
            onSuccess?.(data)
            queryClient.invalidateQueries({queryKey: [QUERY_KEYS.user]})
        }
    })
}
