import {useMutation, useQueryClient} from '@tanstack/react-query'
import {GOAL_API} from '../services/goal.http'
import {QUERY_KEYS} from './keys'
import * as AUTH_KEYS from '@/features/authentication/queries/keys'
import {GoalType} from '../types'

export const useStopGoal = (goalId: string | number, onSuccess: (data: GoalType) => void) => {
    const queryClient = useQueryClient()

    return useMutation({
        mutationFn: () => GOAL_API.stopGoal(`${goalId}`),
        onSuccess: data => {
            queryClient.invalidateQueries({queryKey: [QUERY_KEYS.selected_goal]})
            queryClient.invalidateQueries({queryKey: [AUTH_KEYS.QUERY_KEYS.user]})
            onSuccess(data)
        }
    })
}
