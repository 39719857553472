import {StyledPopoverContent, StyledPopoverTrigger} from '@components/ui/popover/style.ts'
import * as RPopover from '@radix-ui/react-popover'
import {forwardRef, MutableRefObject, PropsWithChildren, ReactNode} from 'react'
import {DefaultTheme, FlattenSimpleInterpolation, useTheme} from 'styled-components'
import {StyledPopoverArrow} from '../context-menu/style'

export enum PopoverSideE {
    top = 'top',
    bottom = 'bottom'
}

type PopoverProps = {
    rootProps?: RPopover.PopoverProps
    triggerProps?: RPopover.PopoverTriggerProps
    portalProps?: RPopover.PopoverPortalProps
    contentProps?: RPopover.PopoverContentProps
    trigger?: ReactNode
    anchorRef?: MutableRefObject<HTMLElement | null>
    className?: string
    popoverStyles?: (theme: DefaultTheme) => FlattenSimpleInterpolation
    isModal?: boolean
    side?: PopoverSideE
    withArrow?: boolean
    withAnchor?: boolean
} & PropsWithChildren

export const Popover = forwardRef<HTMLDivElement, PopoverProps>(
    (
        {
            side = PopoverSideE.bottom,
            popoverStyles,
            isModal,
            trigger,
            children,
            rootProps,
            triggerProps,
            portalProps,
            contentProps,
            anchorRef,
            className,
            withArrow = false,
            withAnchor = false
        },
        ref
    ) => {
        const theme = useTheme()
        return (
            <RPopover.Root {...rootProps} {...(!!anchorRef?.current && {open: !!anchorRef?.current})}>
                {!!trigger && <StyledPopoverTrigger {...triggerProps}>{trigger}</StyledPopoverTrigger>}

                {/*  Please, don't delete this Anchor, it's  critical for TextareaWithBannedElementsTrap */}
                {withAnchor && <RPopover.Anchor virtualRef={anchorRef} />}
                <RPopover.Portal {...portalProps}>
                    <StyledPopoverContent
                        side={side}
                        style={isModal ? {zIndex: theme.zIndex.modal} : {}}
                        ref={ref}
                        $popoverStyles={popoverStyles}
                        sideOffset={8}
                        className={className}
                        {...contentProps}
                    >
                        {children}
                        {withArrow && <StyledPopoverArrow width={12} height={8} />}
                    </StyledPopoverContent>
                </RPopover.Portal>
            </RPopover.Root>
        )
    }
)
