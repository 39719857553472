import {GoalSchema} from '@/features/goal/services/goal.schema'
import {PageSchema} from '@/features/common/services/common.schema'
import {z} from 'zod'

export enum NotificationsTypesE {
    INFO = 'info',
    ALERT = 'alert',
    CONGRATULATIONS = 'congratulations',
    GIFT = 'gift',
    APPRECIATE = 'appreciate',
    RENEW = 'renew',
    TELEGRAM = 'telegram',
    COUPON = 'coupon',
    PAUSE = 'pause',
    CONNECTION = 'connection',
    REFER = 'refer',
    GOAL = 'goal',
    FIRST_MESSAGE = 'first_message',
    TOP_FAN_CONGRATULATIONS = 'top_fan_congratulations',
    HOST_GOAL_ACTIVATED = 'rookie_goal_activated',
    MOENGAGE_NOTIFICATION = 'moengage_notification',
    GUEST_CASHBACK = 'leader_cashback',
    VERIFIED_ID_CARD = 'verified_id_card',
    GUEST_WELCOME_FROM_HOST = 'leader_welcome_from_rookie',
    HOST_REJECTED_PAYMENT_ID_CARD = 'rookie_rejected_payment_id_card',
    ID_CARD_REJECTED = 'id_card_rejected',
    BACK_ID_CARD_REJECTED = 'back_id_card_rejected',
    FRONT_ID_CARD_REJECTED = 'front_id_card_rejected',
    SELFIE_ID_CARD_REJECTED = 'selfie_id_card_rejected',
    HOST_REJECTED_PAYMENT_NO_METHOD = 'rookie_rejected_payment_no_method',
    HOST_CHANGE_GIFT_AMOUNT = 'rookie_change_gift_amount',
    TELEGRAM_BOT = 'telegram_bot',
    GOT_BONUS_COUPON = 'got_bonus_coupon',
    GOT_REFUNDED_GIFT_COUPON = 'got_refunded_gift_coupon',
    HOST_LOGIN = 'rookie_login',
    GUEST_CHANGE_GIFT_AMOUNT = 'leader_change_gift_amount',
    DESCRIPTION_DECLINED = 'description_declined',
    PHOTO_DECLINED = 'photo_declined',
    HOST_VIDEO_DECLINED = 'rookie_video_declined',
    HOST_MERCH_CANCELED = 'rookie_merch_canceled',
    HOST_REJECTED_PAYMENT_MIN_50_USD = 'rookie_rejected_payment_min_50_usd',
    HOST_REJECTED_PAYMENT_GENERAL = 'rookie_rejected_payment_general',
    BLOCKED_GUEST = 'blocked_leader',
    HOST_BLOCKED_GUEST = 'rookie_blocked_leader',
    GUEST_BLOCKED_HOST = 'leader_blocked_rookie',
    INVALID_CARD_SUBSCRIPTION_CANCELED = 'invalid_card_subscription_canceled',
    GUEST_CANCELED_SUBSCRIPTION = 'leader_canceled_subscription',
    GIFT_INACTIVITY_REMINDER = 'gift_inactivity_reminder',
    GUEST_BUY_MICROMORGI_PACKAGE = 'leader_buy_micromorgi_package',
    DESCRIPTION_APPROVED = 'description_approved',
    PHOTO_APPROVED = 'photo_approved',
    HOST_VIDEO_APPROVED = 'rookie_video_approved',
    GUEST_NEW_GIFT = 'leader_new_gift',
    GUEST_FIRST_GIFT_TO_HOST = 'leader_first_gift_to_rookie',
    HOST_FIRST_GIFT_FROM_GUEST = 'rookie_first_gift_from_leader',
    HOST_RECEIVE_MICROMORGI = 'rookie_receive_micromorgi',
    GUEST_GOT_BONUS = 'leader_got_bonus',
    HOST_GOT_BONUS = 'rookie_got_bonus',
    HOST_NEW_GIFT = 'rookie_new_gift',
    MORGI_APPRECIATE = 'morgi_appreciate',
    GUEST_LOGIN = 'leader_login',
    HOST_MERCH_IN_ELABORATION = 'rookie_merch_in_elaboration',
    HOST_MERCH_SENT = 'rookie_merch_sent',
    GUEST_REVERRED_HOST = 'leader_referred_rookie',
    GUEST_RENEWED_GIFT = 'leader_renewed_gift',
    HOST_RENEWED_GIFT = 'rookie_renewed_gift',
    TRANSACTION_GOAL = 'transaction_goal',
    HOST_GOAL_APPROVED = 'rookie_goal_approved',
    HOST_GOAL_AMOUNT_REACHED = 'rookie_goal_amount_reached',
    HOST_GOAL_SUSPENDED = 'rookie_goal_suspended',
    HOST_GOAL_PROOF_APPROVED = 'rookie_goal_proof_approved',
    HOST_GOAL_PROOF_DECLINED = 'rookie_goal_proof_declined',
    GUEST_GOAL_COMPLETED = 'leader_goal_completed',
    GUEST_WELCOME_FROM_GOAL = 'leader_welcome_from_goal',
    TOP_FANS_COMPLETED = 'top_fans_completed',
    GUEST_PAUSE_CONNECTION = 'leader_pause_connection',
    GUEST_FREE_SUBSCRIPTION = 'leader_free_subscription',
    HOST_FREE_SUBSCRIPTION = 'rookie_free_subscription',
    CONVERTER_FIRST_MESSAGE = 'converter_first_message',
    GUEST_REJECTED_PAYMENT_NO_METHOD = 'leader_rejected_payment_no_method'
}

const NotificationsTypes = z.nativeEnum(NotificationsTypesE)

export const NotificationsSchema = z.object({
    created_at: z.string().datetime(),
    amount: z.union([z.number(), z.null()]),
    amount_micromorgi: z.union([z.number(), z.null()]),
    amount_morgi: z.union([z.number(), z.null()]),
    campaign_id: z.union([z.number(), z.null()]),
    campaign_notification: z.union([z.number(), z.null(), z.object({message: z.string()})]),
    content: z.string(),
    currency: z.string(),
    goal: z.union([z.null(), GoalSchema.partial()]),
    goal_id: z.union([z.number(), z.null()]),
    goal_end_date: z.string().datetime(),
    goal_name: z.string(),
    id: z.union([z.number(), z.null()]),
    new_birth_date: z.string().datetime().optional(),
    new_username: z.union([z.string(), z.null()]),
    notification_type: z.object({
        content: z.union([z.string(), z.null()]),
        title: z.union([z.string(), z.null()]),
        type: NotificationsTypes.nullable()
    }),
    old_amount: z.union([z.number(), z.null()]),
    reason: z.union([z.number(), z.null()]),
    ref_user_id: z.union([z.number(), z.null()]),
    ref_username: z.union([z.string(), z.null()]),
    seen_at: z.union([z.number(), z.null()]),
    title: z.union([z.string(), z.null()]),
    is_ref_user_blocked: z.boolean(),
    type: NotificationsTypes
})

export const NotificationsResponseSchema = PageSchema(NotificationsSchema)
