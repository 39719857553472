import {Avatar} from '@/components/ui/avatar/Avatar'
import {StyledSplide, StyledSplideSlide} from './style'
import {SplideTrack} from '@splidejs/react-splide'
import {SupporterType} from '@/features/goal/types'
import {useTheme} from 'styled-components'

type SupportersCarouselType = {
    supporters?: SupporterType[]
}

export const SupportersCarousel = ({supporters}: SupportersCarouselType) => {
    const PER_PAGE = 4
    const shouldBeCentered = supporters && supporters?.length < PER_PAGE
    const theme = useTheme()
    const correctOption = {
        direction: theme.direction,
        rewind: true,
        arrows: false,
        perPage: PER_PAGE,
        pagination: false
    }

    return (
        <StyledSplide options={correctOption} hasTrack={false} $shouldBeCentered={shouldBeCentered}>
            <SplideTrack>
                {supporters?.map(supporter => (
                    <StyledSplideSlide key={supporter?.id}>
                        <Avatar height={50} width={50} imageUrl={supporter?.avatar?.url} name={supporter?.full_name} />
                    </StyledSplideSlide>
                ))}
            </SplideTrack>
        </StyledSplide>
    )
}
