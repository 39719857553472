import * as RPopover from '@radix-ui/react-popover'
import styled, {FlattenSimpleInterpolation, css, DefaultTheme} from 'styled-components'

export const StyledPopoverTrigger = styled(RPopover.Trigger)`
    cursor: pointer;
`

export const StyledPopoverContent = styled(RPopover.Content)`
    ${({
        $popoverStyles,
        theme: {palette, shadows}
    }: {
        $popoverStyles?: (theme: DefaultTheme) => FlattenSimpleInterpolation
        theme: DefaultTheme
    }) => css`
        background-color: ${palette.neutral.white};
        border-radius: 10px;
        box-shadow: ${shadows.md};
        outline-style: none;
        ${({theme}) => $popoverStyles && $popoverStyles(theme)};
    `}
`
