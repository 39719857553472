import {useMe} from '@/features/authentication/queries/useMe'
import {REDIRECT_MAP} from '@/utilities/constants'
import {Navigate, Outlet} from 'react-router-dom'

export const GuestRoute = () => {
    const {data: user, isLoading} = useMe()
    const roleOfUser = user?.type
    const allowableUsersRole = ['leader']
    const isAdmitted = roleOfUser && allowableUsersRole.includes(roleOfUser)

    return isLoading || isAdmitted ? <Outlet /> : <Navigate to={roleOfUser ? REDIRECT_MAP[roleOfUser] : '/'} />
}
