import {Button} from '@/components/ui/button/Button'
import styled, {css, DefaultTheme} from 'styled-components'

type CardContainerProps = {
    isSpecial: boolean | undefined
}

export const StyledCardContainer = styled.div<CardContainerProps>(
    ({theme: {mediaQueries, spacing, palette}, isSpecial}) => css`
        width: 100%;
        cursor: pointer;
        height: ${spacing * 20}px;
        display: flex;
        padding: ${spacing}px;
        overflow: hidden;
        position: relative;
        box-shadow: rgba(0, 6, 61, 0.15) 0px 2px 18px;
        border-radius: 10px;
        margin: 0 auto;
        border: ${isSpecial && `2px solid #000a64`};
        background-color: ${palette.neutral.white};

        ${mediaQueries.l} {
            height: 232px;
            padding: ${spacing * 1.25}px;
        }
    `
)

export const StyledOverlaySlider = styled.div(
    ({theme: {spacing}}) => css`
        top: 0px;
        left: 0px;
        width: 100%;
        height: 100%;
        z-index: 1;
        position: absolute;
        background: linear-gradient(rgba(0, 0, 0, 0.463) 0%, rgba(255, 255, 255, 0) 50%);
        border-radius: ${spacing * 1.5}px;
    `
)

export const StyledSliderWrapper = styled.div(
    ({theme: {mediaQueries}}) => css`
        position: relative;
        max-width: 107px;
        width: 100%;

        ${mediaQueries.l} {
            max-width: 167px;
        }
    `
)
export const StyledExpertiseIconWrapper = styled.div`
    top: 18px;
    left: 10px;
    z-index: 2;
    position: absolute;
`

export const StyledSliderContainer = styled.div(
    ({theme: {spacing}}) => css`
        position: relative;
        border-radius: ${spacing * 1.5}px;
    `
)

type SaveButtonContainerProps = {
    isSaving: boolean
}
export const StyledSavedButtonContainer = styled.div<SaveButtonContainerProps>(
    ({theme: {spacing, mediaQueries}, isSaving}) => css`
        right: 13px;
        top: ${isSaving ? `-${spacing / 2}px` : '10px'};
        position: absolute;
        cursor: pointer;
        ${mediaQueries.l} {
            top: ${isSaving ? `${spacing / 2}px` : '12px'};
            right: 20px;
        }

        & > img {
            width: ${spacing * 3}px;
            height: ${spacing * 3}px;

            ${mediaQueries.l} {
                height: ${spacing * 4}px;
                width: ${spacing * 4}px;
            }
        }
    `
)
export const StyledCardContent = styled.div(
    ({theme: {spacing, mediaQueries, truncateText}}) => css`
        margin-left: ${spacing}px;
        width: 100%;
        ${truncateText};
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        ${mediaQueries.l} {
            margin-left: ${spacing * 2}px;
        }
    `
)
export const StyledOnlineCircle = styled.span(
    ({theme: {spacing, palette}}) => css`
        width: 11px;
        height: 11px;
        background-color: ${palette.success[300]};
        border-radius: 50%;
        flex-shrink: 0;
        margin-left: ${spacing / 2}px;
    `
)

export const StyledTitleWrapper = styled.div(
    ({theme: {mediaQueries, truncateText, typography, sizing, spacing}}) => css`
        width: calc(100% - ${sizing.saveHostIconWidth});
        display: flex;
        flex-wrap: nowrap;
        ${typography.textLg};
        align-items: center;
        font-weight: 600;
        line-height: ${spacing * 2}px;

        & > h4 {
            ${truncateText};
            padding: ${spacing / 2}px 0px;
        }

        ${mediaQueries.l} {
            ${typography.text3Xl};
            line-height: 34px;
        }

        & > h4 {
            ${truncateText};
        }
    `
)
export const StyledChatTopicsWrapper = styled.div(
    ({theme: {spacing, mediaQueries, palette, typography, truncateText}}) => css`
        display: flex;
        ${typography.textSm};
        font-weight: 500;
        line-height: ${spacing * 2}px;
        align-items: center;

        ${mediaQueries.l} {
            gap: ${spacing}px;
        }

        & svg {
            width: ${spacing * 2}px;
            height: ${spacing * 2}px;

            ${mediaQueries.l} {
                width: ${spacing * 3}px;
                height: ${spacing * 3}px;
            }
        }

        & span {
            color: ${palette.primary[900]};
            ${truncateText};
            ${typography.textXs};

            ${mediaQueries.l} {
                ${typography.textSm};
            }
        }
    `
)

export const StyledInfoWrapper = styled.div(
    ({theme: {spacing, mediaQueries, typography, truncateText}}) => css`
        display: flex;
        flex-direction: column;

        & > p {
            opacity: 0.5;
            ${typography.textXs};
            width: 100%;
            ${truncateText};
            text-align: left;
            ${mediaQueries.l} {
                ${typography.textSm};
                padding-top: ${spacing}px;
                line-height: 20px;
            }
        }
    `
)
export const StyledFavTopicsContainer = styled.div(
    ({theme: {spacing, truncateText}}) => css`
        display: flex;
        flex-direction: column;
        gap: ${spacing / 2}px;
        ${truncateText};
    `
)

export const StyledConnectNowButton = styled(Button)(
    ({theme: {spacing, mediaQueries, typography}}) => css`
        justify-content: center;
        padding: ${spacing / 2}px ${spacing}px;
        ${typography.textSm};

        ${mediaQueries.l} {
            padding: ${spacing * 1.5}px;
            ${typography.textLg};
        }
    `
)
export const StyledInfoContainer = styled.div(
    ({theme: {spacing, mediaQueries}}) => css`
        display: flex;
        flex-direction: column;
        gap: ${spacing / 2}px;

        ${mediaQueries.l} {
            gap: ${spacing}px;
        }
    `
)

export const topicsListStyles = ({theme}: {theme: DefaultTheme}) => css`
    ${theme.typography.textXs};
    font-weight: 500;
    ${theme.mediaQueries.m} {
        ${theme.typography.textSm};
    }
`
