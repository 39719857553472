import i18next from 'i18next'

export type GeneralError = Error & {
    response?: {data: {message: string}}
}

export type ValidationError = Error & {
    response?: {data: {message: {[field: string]: string[]}}}
}

export const getErrorMessage = (error: GeneralError | ValidationError) => {
    if (typeof error === 'object' && !Array.isArray(error) && error !== null) {
        const response = error?.response
        if (typeof response?.data === 'string') {
            return response.data
        } else {
            if (response && response.data) {
                if (typeof response.data.message === 'string') {
                    return response.data.message
                }
                return i18next.t('attentionModal:somethingWrong:title')
            }
        }
    }
    if (error instanceof Error && error.message) {
        return error.message
    }
    return i18next.t('attentionModal:somethingWrong:title')
}
