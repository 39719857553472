import {Popover} from '@/components/ui/popover/Popover'
import {Link} from 'react-router-dom'
import styled, {DefaultTheme, css} from 'styled-components'

export const StyledTextareaContainer = styled.div`
    width: 100%;
`

export const StyledTextareaWrapper = styled.div`
    ${({theme: {palette, spacing, typography}, isValid}: {theme: DefaultTheme; isValid: boolean}) => css`
        position: relative;
        background: ${palette.neutral.white};
        border: 1px solid ${isValid ? palette.neutral[400] : palette.danger[25]} ${!isValid && '!important'};
        border-radius: ${spacing / 2}px;
        /* 16px as font size to prevent zoom interface on safari */
        ${typography.textMd}
        & > div {
            color: ${palette.neutral['900']};
            width: 100%;
            min-width: 340px;
            max-height: 94px;
            height: 100%;
            padding: ${spacing * 2}px;
            outline: none;
            overflow-y: auto;
            border-radius: ${spacing / 2}px;

            & > p {
                width: 100%;
                height: 100%;
                margin: 0;
                overflow-x: hidden;
            }

            &:focus {
                box-shadow: 0 0 0 2px ${palette.primary[400]};
                border-color: ${palette.primary[600]};
            }
        }
    `}
`
export const StyledPlaceholder = styled.div`
    ${({theme: {palette, spacing, typography}}) => css`
        position: absolute;
        top: 0;
        left: 0;
        padding: ${spacing * 2}px;
        ${typography.textMd};
        color: ${palette.neutral[500]} !important;
        pointer-events: none;
    `}
`

export const StyledMaxChars = styled.span`
    ${({theme: {typography}}) => css`
        right: 8px;
        bottom: 2px;
        position: absolute;
        ${typography.textXxs};
        font-weight: 700;
        line-height: 1.8;
    `}
`
export const StyledLabel = styled.p`
    ${({theme: {typography, spacing}}) => css`
        margin: 0;
        margin-bottom: ${spacing}px;
        ${typography.textSm};
        font-weight: 700;
        line-height: 1.8;
    `}
`
export const StyledButtonWrapper = styled.span`
    position: absolute;
    right: 4px;
    bottom: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
`
export const StyledToolTip = styled(Popover)`
    ${({theme: {typography, palette, spacing}}) => css`
        max-width: 230px;
        text-align: center;
        ${typography.textXs};
        line-height: 130%;
        letter-spacing: 0;
        color: ${palette.primary[275]};
        outline: none;
        border: none;
        padding: ${spacing}px;
    `}
`

export const TooltipLink = styled(Link)`
    ${({theme: {palette}}) => css`
        text-decoration: underline;
        color: ${palette.primary[900]};
        cursor: pointer;
    `}
`
