import {Animation} from '@/components/ui/animation/Animation'
import {Button} from '@/components/ui/button/Button'
import {Modal} from '@/components/ui/modal/Modal'
import {useRootStore} from '@/store'
import {selectLogoutModal} from '@/store/selectors'
import Logout from '@assets/animations/Logout.json'
import {useTranslation} from 'react-i18next'
import {useLogout} from '../../queries/useLogout'
import {StyledAnimation, StyledBody, StyledButton, StyledText} from './style'
import {useHandleError} from '@/hooks/useHandleError'

export const LogoutModal = () => {
    const {t} = useTranslation()
    const {closeModal} = useRootStore(selectLogoutModal)

    const {mutate, isPending, error, isError} = useLogout(closeModal)

    useHandleError({isError, error})

    return (
        <Modal onClose={closeModal} title={t('logout_modal:header')} height="90%" minHeight="460px" maxHeight="555px">
            <StyledBody>
                <StyledAnimation>
                    <Animation animationData={Logout} />
                </StyledAnimation>
                <div>
                    <StyledText>
                        <h4>{t('logout_modal:title')}</h4>
                        <p>{t('logout_modal:message')}</p>
                    </StyledText>
                    <StyledButton>
                        <Button variant="primary" onClick={() => mutate()} disabled={isPending}>
                            {t('logout_modal:cta')}
                        </Button>
                    </StyledButton>
                </div>
            </StyledBody>
        </Modal>
    )
}

LogoutModal.displayName = 'LogoutModal'
