import {Modal} from '@/components/ui/modal/Modal'
import {StyledForm, StyledText, StyledTextareaWithBannedElementsTrap} from './style'
import {Trans, useTranslation} from 'react-i18next'
import {EditGoal, GoalStatusesE, GoalType} from '../../types'
import {Button} from '@/components/ui/button/Button'
import {DatePicker} from '@/components/commons/date-picker/DatePicker'
import dayjs, {UnitsE, getGoalRangeDate} from '@/dayjs'
import {SubmitHandler, useForm} from 'react-hook-form'
import {EditGoalSchema, GOAL_MODEL} from '../../services/goal.schema'
import {zodResolver} from '@hookform/resolvers/zod'
import {useGoalsType} from '../../queries/useGoalsType'
import {additionalContent} from '../../constant/goalSize'
import {Spinner} from '@/components/ui/spinner/Spinner'
import {InputText} from '@/components/commons/input-text/InputText'
import {Label} from '@components/ui/label/Label.tsx'
import {useEditGoal} from '../../queries/useEditGoal'
import {successToast} from '@/utilities/helpers'
import {useNotifyAdminGoalUpdated} from '../../queries/useNotifyAdminGoalUpdated'
import {useHandleError} from '@/hooks/useHandleError'
import useMixPanel from '@/integrations/mixpanel/hooks/useMixpanel'
import {MixpanelEventNameE} from '@/integrations/mixpanel/types'
import {useFetchSupporters} from '../../queries/useFetchSupporters'
import {useState} from 'react'
import {HaveBannedElementObj} from '@/features/generated-content-security/types'

type SetNewGoalDataModalProps = {
    onClose: () => void
    status?: GoalStatusesE
    goal?: GoalType
}
export const SetNewGoalDataModal = ({onClose, status, goal}: SetNewGoalDataModalProps) => {
    const {t} = useTranslation()
    const mixpanel = useMixPanel()
    const {data, isLoading} = useGoalsType()
    const {data: supporters, isLoading: isSupportersLoading} = useFetchSupporters(`${goal?.id}`)
    const [haveBannedContent, setHaveBannedContent] = useState<HaveBannedElementObj | null>(null)
    const isStatusSuspended = goal?.status === GoalStatusesE.SUSPENDED
    const dateNow = dayjs().format('YYYY-MM-DD')
    const startDate = dayjs(goal?.start_date).format('YYYY-MM-DD')
    const TIME_DIFFERENCE = {
        longer: 'longer',
        reduced: 'reduced'
    }

    // mixPanel
    const getTimeDifference = () => {
        // chech if previous end_date < 0, then new end_date is longer, else reduser
        const timeDifference = dayjs(goal?.end_date).diff(newEndDate, UnitsE.MILLISECOND)
        if (timeDifference < 0) {
            return TIME_DIFFERENCE.longer
        } else {
            return TIME_DIFFERENCE.reduced
        }
    }

    const successActions = () => {
        if (goal) {
            mixpanel?.trackEvent(MixpanelEventNameE.GoalTimeChanged, {
                goal_name: goal?.name,
                goal_amount: goal?.target_amount,
                goal_type: goal?.type?.type,
                goal_end_date: goal?.end_date,
                proof_type: {
                    has_image_proof: goal?.has_image_proof,
                    has_video_proof: goal?.has_video_proof
                },
                images_count: goal?.media?.filter(item => item?.type === 'image')?.length,
                videos_count: goal?.media?.filter(item => item?.type === 'video')?.length,
                number_of_supporters: supporters?.length,
                time: getTimeDifference(),
                days_since_start: dayjs(dateNow).diff(startDate, UnitsE.DAY),
                days_to_end: dayjs(goal?.end_date).diff(startDate, UnitsE.DAY),
                new_end_date: newEndDate
            })
        }

        onClose()
        successToast(isStatusSuspended ? 'common:goal_updated' : 'common:goal_end_date_updated')
    }

    // Notify the Admin that the goal has been updated if the status is SUSPENDED.
    const {mutate: notifyAdminGoalUpdated, isPending: isNotifyPending} = useNotifyAdminGoalUpdated(
        `${goal?.id}`,
        successActions
    )

    // Edit goal success
    const onSuccess = () => {
        if (isStatusSuspended) {
            notifyAdminGoalUpdated()
        } else {
            successActions()
        }
    }

    // Edit goal (status ACTIVE and SUSPENDED)
    const {mutate, isPending, error, isError} = useEditGoal(`${goal?.id}`, onSuccess, isStatusSuspended)

    const remappedGoalSize = data?.map(item => {
        return {
            ...item,
            ...additionalContent[item.type]
        }
    })

    const currentGoalType = remappedGoalSize?.find(item => goal?.type_id && goal?.type_id === item?.id)

    const {minDate, maxDate} = getGoalRangeDate({
        duration_value: currentGoalType?.duration_value,
        unit: currentGoalType?.duration_unit
    })

    const {
        register,
        handleSubmit,
        setValue,
        watch,
        formState: {errors, isValid},
        setError,
        clearErrors
    } = useForm<EditGoal>({
        mode: 'onBlur',
        reValidateMode: 'onBlur',
        resolver: zodResolver(EditGoalSchema),
        defaultValues: {
            [GOAL_MODEL.EndDate]: goal?.end_date,
            [GOAL_MODEL.Name]: goal?.name,
            [GOAL_MODEL.Details]: goal?.details
        }
    })
    const newEndDate = watch(GOAL_MODEL.EndDate)

    const onSubmit: SubmitHandler<EditGoal> = data => {
        mutate(data)
    }

    useHandleError({isError, error, setError})

    return (
        <Modal
            onClose={onClose}
            minHeight={'557px'}
            title={
                isStatusSuspended ? t('set_new_goal_time_popup:title_suspended') : t('set_new_goal_time_popup:title')
            }
        >
            {(isLoading || isPending || isNotifyPending || isSupportersLoading) && <Spinner />}
            <StyledForm onSubmit={handleSubmit(onSubmit)}>
                <StyledText>
                    <div>
                        <Label text={t('create_goal:end_date_label')} />
                        <DatePicker
                            minDate={new Date(minDate)}
                            maxDate={new Date(maxDate)}
                            mode={'single'}
                            selectedDates={[new Date(newEndDate)]}
                            onDatesChange={dates => {
                                setValue(GOAL_MODEL.EndDate, dayjs(dates[0]).format(), {shouldDirty: true})
                            }}
                            name={GOAL_MODEL.EndDate}
                            formatDateFn={date => dayjs(date).format('DD/MM/YYYY')}
                        />
                    </div>
                    {goal?.status === GoalStatusesE.SUSPENDED && (
                        <>
                            <InputText
                                type={'text'}
                                label={`${t('create_goal:name_label')}*`}
                                errorMessage={`${t(errors[GOAL_MODEL.Name]?.message || '')}`}
                                placeholder={t('create_goal:goal_name_placeholder')}
                                {...register(GOAL_MODEL.Name)}
                            />

                            <StyledTextareaWithBannedElementsTrap
                                defaultValue={goal?.details}
                                label={`${t('create_goal:details_label')}*`}
                                placeholder={t('create_goal:goal_details_placeholder')}
                                onChange={value =>
                                    setValue(GOAL_MODEL.Details, value, {
                                        shouldValidate: true
                                    })
                                }
                                ref={register(GOAL_MODEL.Details).ref}
                                maxChars={120}
                                errorMessage={`${t(errors[GOAL_MODEL.Details]?.message || '')}`}
                                setError={() =>
                                    setError(GOAL_MODEL.Details, {
                                        type: 'custom',
                                        message: `${t('validation:required_goal_details')}`
                                    })
                                }
                                clearErrors={() => clearErrors(GOAL_MODEL.Details)}
                                getHaveBannedElements={setHaveBannedContent}
                            />
                        </>
                    )}
                </StyledText>
                <Button type={'submit'} disabled={!isValid || haveBannedContent?.isBanned}>
                    <Trans t={t} i18nKey={'edit_goal_time_popup:button_text'} values={{context: status}} />
                </Button>
            </StyledForm>
        </Modal>
    )
}
