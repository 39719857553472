import styled, {DefaultTheme, css} from 'styled-components'

export const StyledThankYousSectionRoot = styled.div`
    ${({theme: {spacing}, inHostProfile}: {theme: DefaultTheme; inHostProfile: boolean}) => css`
        ${!inHostProfile
            ? css`
                  padding: ${spacing * 1.5}px;
              `
            : css`
                  padding: 0px;
              `}
    `}
`

export const StyledTitleWrapper = styled.div`
    ${({theme: {spacing, palette}, inHostProfile}: {theme: DefaultTheme; inHostProfile: boolean}) => css`
        margin-bottom: ${spacing * 1.5}px;
        color: ${palette.primary['900']};
        display: flex;
        align-items: center;
        line-height: 20px;
        & svg {
            width: 20;
            height: 21;
            margin-right: ${spacing}px;
            fill: ${palette.neutral.white};
        }

        ${inHostProfile &&
        css`
            padding: 0;
        `}
    `}
`

export const StyledItemWrapper = styled.div`
    ${({theme: {spacing}}) => css`
        margin: ${spacing * 2}px 0;
        display: flex;
        flex-direction: column;
        gap: ${spacing * 2.25}px;
    `}
`
