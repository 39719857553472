import {AccessIcon, Chat, CrownSmallIcon, EventsIcon} from '@/assets/icons/icons'
import {useTranslation} from 'react-i18next'
import {UltimateStatusContent} from '../content/UltimateStatusContent'
import {StyledContentBox, StyledContentTitle} from './style'

export const UltimateStatusContentList = () => {
    const {t} = useTranslation()
    const contentList = [
        {
            id: 1,
            icon: <CrownSmallIcon />,
            title: t('ultimate_status_modal:content:title_1'),
            text: t('ultimate_status_modal:content:text_1')
        },
        {
            id: 2,
            icon: <EventsIcon />,
            title: t('ultimate_status_modal:content:title_2'),
            text: t('ultimate_status_modal:content:text_2')
        },
        {
            id: 3,
            icon: <Chat />,
            title: t('ultimate_status_modal:content:title_3'),
            text: t('ultimate_status_modal:content:text_3')
        },
        {
            id: 4,
            icon: <AccessIcon />,
            title: t('ultimate_status_modal:content:title_4'),
            text: t('ultimate_status_modal:content:text_4')
        }
    ]

    return (
        <StyledContentBox>
            <StyledContentTitle>{t('ultimate_status_modal:content_title')}</StyledContentTitle>
            {contentList.map(contentItem => (
                <UltimateStatusContent key={contentItem.id} content={contentItem} />
            ))}
        </StyledContentBox>
    )
}
