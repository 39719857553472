import {TranslateSwitch} from '@/features/translation/components/translate-switch/TranslateSwitch'
import {StyledTranslationSwitchWrapper} from './style'
import {StyledTabTitle} from '../style'
import {useTranslation} from 'react-i18next'

export const TranslationSwitchSection = () => {
    const {t} = useTranslation()

    return (
        <StyledTranslationSwitchWrapper>
            <StyledTabTitle>{t('path_filter:translation_title')}</StyledTabTitle>
            <TranslateSwitch isUseMainAppLanguage />
        </StyledTranslationSwitchWrapper>
    )
}
