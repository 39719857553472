import {StyledDivider} from '@components/ui/divider/style.ts'

interface ContainerProps {
    width?: number
    height?: number
    topSpacing?: number
    bottomSpacing?: number
    background?: string
    className?: string
}

export const Divider = ({
    width,
    height = 1,
    topSpacing = 20,
    bottomSpacing = 20,
    background,
    className
}: ContainerProps) => (
    <StyledDivider
        className={className}
        width={width}
        height={height}
        background={background}
        topSpacing={topSpacing}
        bottomSpacing={bottomSpacing}
    />
)

Divider.displayName = 'Divider'
