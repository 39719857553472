import {typography} from '@/theme/typography'
import styled, {DefaultTheme, FlattenSimpleInterpolation, css, keyframes} from 'styled-components'

export const StyledProgressRoot = styled.div`
    ${({
        circleProgressStyles
    }: {
        theme: DefaultTheme
        circleProgressStyles?: (theme: DefaultTheme) => FlattenSimpleInterpolation
    }) => css`
        position: relative;
        display: flex;
        align-items: center;
        ${({theme}) => circleProgressStyles && circleProgressStyles(theme)};

        & span {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            & svg {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
            }
        }
    `}
`

export const StyledSvg = styled.svg`
    display: block;
    transform: rotate(-90deg);
`

export const animateProgress = (circumference: number, offset: number) => keyframes`
  from {
    stroke-dashoffset: ${circumference};
  }
  to {
    stroke-dashoffset: ${offset};
  }
`

export const StyledCircleBackground = styled.circle<{
    strokeWidth: number
    circleColor?: string
}>`
    fill: none;
    stroke: ${({circleColor}) => circleColor};
    stroke-width: ${({strokeWidth}) => strokeWidth};
`

export const StyledCircleProgress = styled.circle<{
    strokeWidth: number
    progressColor?: string
    offset: number
    circumference: number
    animated?: boolean
}>`
    fill: none;
    stroke: ${({progressColor}) => progressColor};
    stroke-width: ${({strokeWidth}) => strokeWidth};
    stroke-linecap: round;
    stroke-dasharray: ${({circumference}) => circumference};
    stroke-dashoffset: ${({offset}) => offset};
    animation: ${({circumference, offset, animated}) => animated && animateProgress(circumference, offset)} 1s ease-out
        forwards;
`

export const StyledText = styled.text<{
    percentageColor?: string
}>`
    font-size: ${typography.textSm};
    font-weight: 700;
    fill: ${({percentageColor}) => percentageColor};
`
