import {useTranslation} from 'react-i18next'
import {GoalStatusesE} from '../../types'
import {Tag} from '@/components/ui/tag/Tag'
import {useMemo} from 'react'
import {ExclamationMark, GoalSuccessIcon} from '@/assets/icons/icons'
import {tagStyles} from './style'
import {generatePath, useNavigate} from 'react-router-dom'
import {ROUTE_GOAL_PROOF} from '@/utilities/constants/routeNames'
import {useTheme} from 'styled-components'

type StatusLabelProps = {
    status?: GoalStatusesE
    goalId: number
}

export const StatusLabel = ({status, goalId}: StatusLabelProps) => {
    const {t} = useTranslation()
    const navigate = useNavigate()
    const {palette} = useTheme()
    const data = {
        [GoalStatusesE.PENDING]: {
            icon: <ExclamationMark height={16} width={16} />,
            text: t('goal_card:proof_label', {context: status}),
            action: undefined
        },
        [GoalStatusesE.AWAITING_PROOF]: {
            icon: <ExclamationMark height={16} width={16} />,
            text: t('goal_card:proof_label', {context: status}),
            action: () => navigate(generatePath(ROUTE_GOAL_PROOF, {goalId: `${goalId}`}))
        },
        [GoalStatusesE.PROOF_PENDING_APPROVAL]: {
            icon: <ExclamationMark height={16} width={16} />,
            text: t('goal_card:proof_label', {context: status}),
            action: undefined
        },
        [GoalStatusesE.SUSPENDED]: {
            icon: <ExclamationMark height={16} width={16} />,
            text: t('goal_card:proof_label', {context: status}),
            action: undefined
        },
        [GoalStatusesE.PROOF_STATUS_DECLINED]: {
            icon: <ExclamationMark height={16} width={16} />,
            text: t('goal_card:proof_label', {context: status}),
            action: () => navigate(generatePath(ROUTE_GOAL_PROOF, {goalId: `${goalId}`}))
        },
        [GoalStatusesE.SUCCESSFUL]: {
            icon: <GoalSuccessIcon height={16} width={16} fill={palette.success[600]} />,
            text: t('goal_card:proof_label', {context: status}),
            action: undefined
        },
        [GoalStatusesE.REVIEW]: {
            icon: <GoalSuccessIcon />,
            text: t('goal_card:proof_label', {context: status}),
            action: undefined
        }
    }

    const labelData = useMemo(() => {
        switch (status) {
            case GoalStatusesE.PENDING:
                return data[GoalStatusesE.PENDING]
            case GoalStatusesE.AWAITING_PROOF:
                return data[GoalStatusesE.AWAITING_PROOF]
            case GoalStatusesE.PROOF_PENDING_APPROVAL:
                return data[GoalStatusesE.PROOF_PENDING_APPROVAL]
            case GoalStatusesE.SUSPENDED:
                return data[GoalStatusesE.SUSPENDED]
            case GoalStatusesE.PROOF_STATUS_DECLINED:
                return data[GoalStatusesE.PROOF_STATUS_DECLINED]
            case GoalStatusesE.SUCCESSFUL:
                return data[GoalStatusesE.SUCCESSFUL]
            case GoalStatusesE.REVIEW:
                return data[GoalStatusesE.REVIEW]
            default:
                return null
        }
    }, [status])

    return labelData ? (
        <Tag
            icon={labelData.icon}
            title={`${labelData.text}`}
            onClick={labelData.action}
            tagStyles={theme => tagStyles(theme, status)}
        />
    ) : null
}
