import {Lens} from '@dhmk/zustand-lens'

type ErrorModalState = {
    isOpen: boolean
    message: string | null
    isBlankPage?: boolean
}

type RecurringGiftModalActions = {
    openErrorModal: (params: Omit<ErrorModalState, 'isOpen'>) => void
    closeErrorModal: () => void
}

export type ErrorModal = ErrorModalState & RecurringGiftModalActions

const initialModal: ErrorModalState = {isOpen: false, message: null}

const createErrorModalSlice: Lens<ErrorModal> = set => ({
    ...initialModal,
    openErrorModal: params => set(() => ({...params, isOpen: true})),
    closeErrorModal: () => set(() => ({...initialModal}))
})

export default createErrorModalSlice
