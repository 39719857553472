import {GoalStatusesE, GoalType} from '@/features/goal/types'
import {useTranslation} from 'react-i18next'
import {useMemo} from 'react'
import {StyledCanceled, StyledCountdown} from './style'
import GoalCountdown from '../../goal-countdown/GoalCountdown'

type HostStatusSectionProps = {
    goal: Partial<GoalType>
}

export const GuestDetailsStatusSection = ({goal}: HostStatusSectionProps) => {
    const {t} = useTranslation()

    const content = useMemo(() => {
        switch (goal?.status) {
            case GoalStatusesE.ACTIVE:
                return (
                    <StyledCountdown>
                        <GoalCountdown endDate={goal?.end_date ?? ''} />
                    </StyledCountdown>
                )

            case GoalStatusesE.REVIEW:
            case GoalStatusesE.SUSPENDED:
                return <StyledCanceled>{t('goal_page:goal_details:status_suspended')}</StyledCanceled>
            case GoalStatusesE.CANCELED:
                return <StyledCanceled>{t('goal_page:goal_details:status_canceled')}</StyledCanceled>
            default:
                return null
        }
    }, [goal?.status])

    return <>{content}</>
}

// !!!IMPORTANT - this rules only for GUEST side!!!

// 1. If status "active" - show countdown
// 2. If status "suspended", "review" - show red text "Goal suspended"
// 3. If status "canceled" - show red text "Canceled"
// 3. If status "awaiting_proof", "proof_pending_approval", "successful" - don't show anything
