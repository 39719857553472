import {ExclamationMark, GoalSuccessIcon, UploadPhotoIcon, UploadVideoIcon} from '@/assets/icons/icons'
import {GoalStatusesE, UploadAreaTypeE} from '../types'
import {palette} from '@/theme/palette'

export const proofUploadAreaData = {
    [UploadAreaTypeE.PROOF_PHOTO]: {
        icon: <UploadPhotoIcon />,
        title: 'goals_proof_page:photo_title',
        hint: 'goals_proof_page:photo_hint'
    },
    [UploadAreaTypeE.PROOF_VIDEO]: {
        icon: <UploadVideoIcon />,
        title: 'goals_proof_page:video_title',
        hint: 'goals_proof_page:video_hint'
    }
}

export const hostProofData = {
    [GoalStatusesE.PENDING]: {
        icon: <ExclamationMark fill={palette.light.tertiary['200']} />,
        title: 'goal_page:proof:title_pending',
        text: 'goal_page:proof:subtitle_pending',
        backgroundColor: palette.light.warning['150']
    },
    [GoalStatusesE.SUSPENDED]: {
        icon: <ExclamationMark fill={palette.light.danger['50']} />,
        title: 'goal_page:proof:title_suspended',
        text: '',
        backgroundColor: palette.light.danger['75']
    },
    [GoalStatusesE.REVIEW]: {
        icon: <ExclamationMark fill={palette.light.tertiary['200']} />,
        title: 'goal_page:proof:title_review',
        text: '',
        backgroundColor: palette.light.warning['150']
    },
    [GoalStatusesE.AWAITING_PROOF]: {
        icon: <ExclamationMark fill={palette.light.danger['50']} />,
        title: 'goal_page:proof:title_awaiting_proof',
        text: 'goal_page:proof:description_defoult_fail',
        backgroundColor: palette.light.danger['75']
    },
    [GoalStatusesE.PROOF_PENDING_APPROVAL]: {
        icon: <ExclamationMark fill={palette.light.tertiary['200']} />,
        title: 'goal_page:proof:title_proof_pending_approval',
        text: 'goal_page:proof:message',
        backgroundColor: palette.light.warning['150']
    },
    [GoalStatusesE.PROOF_STATUS_DECLINED]: {
        icon: <ExclamationMark fill={palette.light.danger['50']} />,
        title: 'goal_page:proof:title_proof_status_declined',
        text: 'goal_page:proof:description_defoult_fail',
        backgroundColor: palette.light.danger['75']
    },
    [GoalStatusesE.SUCCESSFUL]: {
        icon: <GoalSuccessIcon fill={palette.light.success['600']} />,
        title: 'goal_page:proof:title_successful',
        text: 'goal_page:proof:message',
        backgroundColor: palette.light.success['250']
    }
}

export const guestProofData = {
    [GoalStatusesE.ACTIVE]: {
        icon: <ExclamationMark fill={palette.light.tertiary['200']} />,
        title: 'goal_page:proof_for_leader:title_pending',
        text: 'goal_page:proof_for_leader:goal_not_reached_image_and_video',
        backgroundColor: palette.light.warning['150']
    },
    [GoalStatusesE.AWAITING_PROOF]: {
        image: {
            icon: <ExclamationMark fill={palette.light.tertiary['200']} />,
            title: 'goal_page:proof_for_leader:title_pending',
            text: 'goal_page:proof_for_leader:pending_image',
            backgroundColor: palette.light.warning['150']
        },
        video: {
            icon: <ExclamationMark fill={palette.light.tertiary['200']} />,
            title: 'goal_page:proof_for_leader:title_pending',
            text: 'goal_page:proof_for_leader:pending_video',
            backgroundColor: palette.light.warning['150']
        },
        both: {
            icon: <ExclamationMark fill={palette.light.tertiary['200']} />,
            title: 'goal_page:proof_for_leader:title_pending',
            text: 'goal_page:proof_for_leader:pending_image_and_video',
            backgroundColor: palette.light.warning['150']
        }
    },
    [GoalStatusesE.SUCCESSFUL]: {
        icon: <GoalSuccessIcon fill={palette.light.success['600']} />,
        title: 'goal_page:proof_for_leader:title_success',
        text: 'goal_page:proof:message',
        backgroundColor: palette.light.success['250']
    }
}
