import styled, {css} from 'styled-components'

export const StyledMedia = styled.div`
    ${({theme: {spacing, palette, mediaQueries}}) => css`
        height: 138px;
        width: 100%;
        max-width: 102px;
        position: relative;
        object-fit: contain;
        border-radius: 10px;
        overflow: hidden;
        cursor: pointer;

        & div {
            box-shadow: rgb(0 0 0 / 40%) 0px -24px 28px 8px inset;
        }

        & svg {
            position: absolute;
            top: ${spacing}px;
            right: ${spacing}px;

            & path {
                fill: ${palette.neutral.white};
            }
        }

        ${mediaQueries.l} {
            max-width: 150px;
            height: 200px;
        }
    `}
`
