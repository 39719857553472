import {useMutation, useQueryClient} from '@tanstack/react-query'
import {GOAL_API} from '../services/goal.http'
import * as types from '../types'
import {QUERY_KEYS} from './keys'

export const useEditGoal = (goalId: string | number, onSuccess: () => void, isStatusSuspended?: boolean) => {
    const queryClient = useQueryClient()

    return useMutation({
        mutationFn: (data: types.EditGoal | types.EditMedia) => GOAL_API.editGoal(`${goalId}`, data),
        onSuccess: () => {
            onSuccess()
            !isStatusSuspended && queryClient.invalidateQueries({queryKey: [QUERY_KEYS.selected_goal]})
        }
    })
}
