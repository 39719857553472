import styled, {css, DefaultTheme, FlattenSimpleInterpolation} from 'styled-components'
import {Splide, SplideSlide, SplideTrack} from '@splidejs/react-splide'

export const StyledWrapper = styled.div`
    ${({theme: {mediaQueries}}) => css`
        display: grid;
        column-gap: 60px;
        padding-bottom: 50px;
        grid-template-columns: 1fr;
        ${mediaQueries.m} {
            padding-bottom: 0px;
            grid-template-columns: 1fr 1fr;
        }
    `}
`

export const StyledMainHostInfo = styled.div`
    ${({theme: {spacing, mediaQueries}}) => css`
        display: grid;
        grid-row-gap: ${spacing * 3}px;
        align-items: center;
        width: 100%;
        max-width: 480px;
        ${mediaQueries.m} {
            margin-bottom: ${spacing * 2}px;
        }
    `}
`

export const StyledHostTitle = styled.div`
    ${({theme: {spacing, typography}}) => css`
        display: flex;
        ${typography.displayMd}
        padding: ${spacing * 1.2}px 0px;
        font-weight: 500;
    `}
`

export const StyledOnlineCircle = styled.span(
    ({theme: {spacing, palette, mediaQueries}}) => css`
        display: inline-block;
        width: 10px;
        height: 10px;
        background-color: ${palette.success[300]};
        border-radius: 50%;
        flex-shrink: 0;
        margin-right: ${spacing / 2}px;
        margin-bottom: ${spacing / 2}px;
        min-height: 10px !important;
        ${mediaQueries.m} {
            margin-bottom: ${spacing}px;
        }
    `
)

export const StyledHostFullName = styled.div`
    ${({
        isModal,
        resizeForModal,
        theme: {typography, mediaQueries}
    }: {
        isModal?: boolean
        resizeForModal?: boolean
        theme: DefaultTheme
    }) => css`
        ${typography.textXXl}
        font-weight: 400;
        line-height: 1.2;
        letter-spacing: -0.05em;
        max-width: 300px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        position: ${isModal && 'absolute'};
        top: ${isModal && '10px'};
        ${mediaQueries.m} {
            ${typography.displayMd}
        }
        ${mediaQueries.l} {
            max-width: 400px;
            max-width: ${resizeForModal && '300px'};
        }
    `}
`

export const StyledHostDescription = styled.p`
    ${({theme: {palette}}) => css`
        color: ${palette.primary[900]};
        opacity: 0.5;
        word-break: break-all;
    `}
`
export const topicsListStyles = () => css`
    display: flex;
    flex-wrap: wrap;
    & > span {
        white-space: normal;
    }
`

export const StyledSplide = styled(Splide)`
    ${({
        $carouselStyles,
        theme
    }: {
        $carouselStyles?: (theme: DefaultTheme) => FlattenSimpleInterpolation
        theme: DefaultTheme
    }) => css`
        height: 100%;
        position: relative;
        ${$carouselStyles && $carouselStyles(theme)}
    `}
`

export const StyledSplideSlide = styled(SplideSlide)(
    ({
        theme: {palette, flexUtils},
        $slideStyles
    }: {
        $slideStyles?: (theme: DefaultTheme) => FlattenSimpleInterpolation
        theme: DefaultTheme
    }) => css`
        background-color: ${palette.neutral.transparent};
        ${flexUtils.centered};
        & > * {
            max-height: 100vh;
        }
        ${$slideStyles};
    `
)

export const StyledSplideTrack = styled(SplideTrack)`
    height: 100%;
`

export const carouselStyles = ({theme: {spacing, palette}}: {theme: DefaultTheme}) => css`
    .splide__slide {
        padding: ${spacing * 1.25}px 3px;
        & div {
            flex: 1 0 48%;
        }
    }
    .splide__pagination {
        display: none;
        top: ${spacing * 3}px;
        bottom: initial;
        width: 100%;
        display: flex;
        align-items: center;
        flex-wrap: nowrap;
        padding: 6px;
        & li {
            display: flex;
            width: 100%;
        }
    }
    /* pagination buttons */
    & button {
        display: none;
        height: 5px;
        width: 100%;
        margin: ${spacing / 2}px;
        position: relative;
        border-radius: 100px;
        margin: 4px 1px;
        &.splide__pagination__page {
            background: ${palette.neutral.white};
            opacity: 0.2;
        }

        &.splide__pagination__page.is-active {
            background: ${palette.neutral.white};
            opacity: 1;
            transform: none;
        }
    }
`
export const sectionStyles = () => css`
    box-shadow: none;
    padding: 0;
`
export const StyledPagination = styled.div`
    ${({theme: {spacing}}) => css`
        position: absolute;
        top: ${spacing * 2}px;
        height: 5px;
        width: 100%;
        display: flex;
        gap: 3px;
        padding: 0px ${spacing}px;
    `}
`
export const StyledPaginationItem = styled.div`
    ${({theme: {palette}, isActive}: {theme: DefaultTheme; isActive?: boolean}) => css`
        background: ${palette.neutral.white};
        border-radius: 100px;
        width: 100%;
        opacity: ${isActive ? 1 : 0.2};
    `}
`
