import styled, {css} from 'styled-components'
import {Splide, SplideSlide} from '@splidejs/react-splide'

export const StyledSplide = styled(Splide)`
    ${({$shouldBeCentered}: {$shouldBeCentered: boolean}) => css`
        & ul {
            ${$shouldBeCentered &&
            css`
                display: flex;
                justify-content: center;
            `}
        }
    `}
`

export const StyledSplideSlide = styled(SplideSlide)(
    ({theme}) => css`
        ${theme.flexUtils.centered};
    `
)
