import {Lens} from '@dhmk/zustand-lens'

type GoalTeaserModalState = {
    isOpen: boolean
    guestID?: number | null
}

type GoalTeaserModalActions = {
    openModal: (params: Omit<GoalTeaserModalState, 'isOpen'>) => void
    closeModal: () => void
}

export type GoalTeaserModal = GoalTeaserModalState & GoalTeaserModalActions

const initialModal: GoalTeaserModalState = {isOpen: false, guestID: null}

const createGoalTeaserModalSlice: Lens<GoalTeaserModal> = set => ({
    ...initialModal,
    openModal: params => set(() => ({...params, isOpen: true})),
    closeModal: () => set(() => ({...initialModal}))
})

export default createGoalTeaserModalSlice
