import styled, {DefaultTheme, css} from 'styled-components'

export const StyledStatusBox = styled.div`
    ${({theme: {spacing}}) => css`
        display: flex;
        align-items: center;
        gap: ${spacing}px;
        cursor: pointer;
    `}
`

export const StyledBalanceBox = styled.div`
    ${({isLight, theme: {mediaQueries, spacing, typography, palette}}: {isLight: boolean; theme: DefaultTheme}) => css`
        display: flex;
        align-items: center;
        font-weight: 600;
        line-height: 17px;
        letter-spacing: -0.05em;
        gap: ${spacing / 4}px;
        color: ${isLight ? palette.primary['900'] : palette.neutral.white};
        svg {
            width: 13px;
            height: 13px;
        }
        ${mediaQueries.l} {
            ${typography.textLg};
            font-weight: 600;
            line-height: 17px;
            letter-spacing: -0.05em;
            svg {
                width: 19px;
                height: 19px;
            }
        }
    `}
`

export const StyledStatus = styled.div`
    ${({
        backgroundColor,
        theme: {mediaQueries, typography, spacing, palette}
    }: {
        backgroundColor: string
        theme: DefaultTheme
    }) => css`
        background: ${backgroundColor};
        padding: 1px ${spacing / 0.75}px;
        ${typography.textXs};
        font-weight: 600;
        line-height: 15px;
        color: ${palette.primary['900']};
        border-radius: 10px;
        ${mediaQueries.l} {
            padding: ${spacing / 4}px ${spacing * 1.25}px;
            ${typography.textMd};
        }
    `}
`

export const popoverStyles = ({theme}: {theme: DefaultTheme}) => css`
    margin-left: ${theme.spacing * 2}px;
    overflow: hidden;
    box-shadow: ${theme.shadows['8xl']};
    }
`

export const StyledTooltipContent = styled.div`
    ${({theme: {spacing, palette, typography}}) => css`
        max-width: 230px;
        text-align: center;
        ${typography.textXs};
        line-height: 16px;
        padding: ${spacing * 1.25}px ${spacing}px;
        letter-spacing: 0;
        color: ${palette.primary['275']};
    `}
`

export const StyledLink = styled.p`
    ${({theme: {palette}}) => css`
        color: ${palette.primary['200']};
        text-decoration: underline;
        cursor: pointer;
    `}
`
