import styled, {css} from 'styled-components'

export const StyledNotFoundContainer = styled.div<{height: number}>`
    ${({height, theme: {spacing, typography}}) => css`
        height: ${height}px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: ${spacing * 2.5}px;
        & div {
            & span {
                ${typography.text2Xl};
            }
        }
    `}
`

export const StyledButtonBox = styled.div`
    ${({theme: {mediaQueries}}) => css`
        width: 90%;
        ${mediaQueries.m} {
            width: 32%;
        }
    `}
`

export const StyledDescription = styled.span`
    color: rgba(0, 6, 61, 0.5);
    font-size: 14px;
    white-space: pre-wrap;
    text-align: center;
`
