import {StyledSignupButton} from './style.ts'
import {
    ROUTE_LINK_ACCOUNTS,
    ROUTE_LOGIN_GUEST,
    ROUTE_SIGNUP_GUEST,
    ROUTE_SIGNUP_GUEST_SOCIAL,
    ROUTE_SIGNUP_HOST,
    ROUTE_SIGNUP_SUCCESS
} from '@/utilities/constants/routeNames'
import {useLocation, useNavigate} from 'react-router-dom'
import {Button} from '@/components/ui/button/Button'
import {useTranslation} from 'react-i18next'
import {RouteVisibilityFragment} from '@/components/ui/routeVisibilityFragment/RouteVisibilityFragment'

export const SignupButton = () => {
    const {t} = useTranslation()
    const navigate = useNavigate()
    const location = useLocation()

    return (
        <RouteVisibilityFragment
            forbiddenRoutes={[
                ROUTE_SIGNUP_SUCCESS,
                ROUTE_SIGNUP_HOST,
                ROUTE_SIGNUP_GUEST,
                ROUTE_LINK_ACCOUNTS,
                ROUTE_SIGNUP_GUEST_SOCIAL
            ]}
        >
            <StyledSignupButton>
                <Button
                    variant="secondary"
                    size="sm"
                    onClick={() =>
                        navigate(
                            location?.pathname === ROUTE_LOGIN_GUEST || location?.pathname === ROUTE_SIGNUP_GUEST_SOCIAL
                                ? ROUTE_SIGNUP_GUEST_SOCIAL
                                : ROUTE_SIGNUP_HOST
                        )
                    }
                >
                    {t('menu:signup')}
                </Button>
            </StyledSignupButton>
        </RouteVisibilityFragment>
    )
}
