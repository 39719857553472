import {Modal} from '@/components/ui/modal/Modal'
import {StyledBody, StyledMediaWrapper} from './style'
import {useTranslation} from 'react-i18next'
import {Button} from '@/components/ui/button/Button'
import {Spinner} from '@/components/ui/spinner/Spinner'
import {GoalMediaType, GoalType} from '../../types'
import {MouseEvent, useState} from 'react'
import {MediaTypeE} from '@/types'
import {useEditGoal} from '../../queries/useEditGoal'
import {GOAL_MODEL} from '../../services/goal.schema'
import {FileUploader} from '@/components/ui/file-uploader/FileUploader'
import {EditMediaUploadArea} from './edit-media-upload-area/EditMediaUploadArea'
import {convertFileToFormData, getFileType} from '@/utilities/helpers'
import {AxiosRequestConfig} from 'axios'
import {useSendGoalMedia} from '../../queries/useSendGoalMedia'
import {ACCEPT_IMAGE_FILE_TYPES, ACCEPT_VIDEO_FILE_TYPES} from '@/utilities/constants/fileUploader'
import {PreviewMedia} from './preview-media/PreviewMedia'
import {useHandleError} from '@/hooks/useHandleError'

type EditMediaModalProps = {
    onClose: () => void
    goal?: Partial<GoalType>
}

export const EditMediaModal = ({onClose, goal}: EditMediaModalProps) => {
    const {t} = useTranslation()
    const defaultMediaList = goal?.media ?? []
    const [mediaList, setMediaList] = useState<GoalMediaType[]>(defaultMediaList)
    const [file, setFile] = useState<File | undefined>()
    const disabled = !mediaList.length

    const onSuccess = () => {
        onClose()
    }

    const {mutate, isPending, isError, error} = useEditGoal(`${goal?.id}`, onSuccess)

    useHandleError({isError, error, isBlankPage: true})

    const onRemove = (e: MouseEvent<Element, globalThis.MouseEvent>, id: number) => {
        e.stopPropagation()
        setMediaList(mediaList?.filter(item => item.id !== id))
    }

    const handleSubmit = () => {
        const pathsLocations = mediaList?.map(media => media.path_location)

        const data = {
            [GOAL_MODEL.EndDate]: goal?.end_date || '',
            [GOAL_MODEL.PathsLocations]: pathsLocations || []
        }
        mutate(data)
    }

    const onUploadSuccess = (path_location: string) => {
        const fileType = file && getFileType(file.type)
        if (file && fileType) {
            setMediaList([
                ...mediaList,
                {
                    id: Date.now(),
                    url: URL.createObjectURL(file),
                    type: fileType,
                    path_location,
                    goal_id: +`${goal?.id}`,
                    is_main: false
                }
            ])
        }
    }

    const {mutate: uploadMedia, isError: isUploadError, error: uploadMediaError} = useSendGoalMedia(onUploadSuccess)

    useHandleError({isError: isUploadError, error: uploadMediaError})

    const sendFile = ({file, options}: {file: File; options: AxiosRequestConfig}) => {
        setFile(file)
        const fileType = getFileType(file.type) ?? ''
        const formData = convertFileToFormData({key: 'file', file})
        const data = {data: formData, options, fileType}

        uploadMedia(data)
    }

    return (
        <Modal onClose={onClose} minHeight="527px" title={t('edit_goal_media:title')}>
            {isPending && <Spinner />}
            <StyledBody>
                <StyledMediaWrapper>
                    {[...mediaList, {id: Date.now(), type: null}]?.map((media, index) => {
                        const isImage = media?.type === MediaTypeE.IMAGE
                        return media?.type ? (
                            <PreviewMedia
                                key={media.id}
                                onRemove={(e: MouseEvent<Element, globalThis.MouseEvent>) => onRemove(e, media.id)}
                                url={media.url}
                                isImage={isImage}
                            />
                        ) : (
                            <FileUploader
                                key={index}
                                UploadArea={EditMediaUploadArea}
                                uploadFunction={sendFile}
                                accept={{...ACCEPT_VIDEO_FILE_TYPES, ...ACCEPT_IMAGE_FILE_TYPES}}
                                onRemove={() => setFile(undefined)}
                                isError={!!isUploadError}
                                isSuccessfulUpload={!isUploadError && !!file}
                            />
                        )
                    })}
                </StyledMediaWrapper>
                <Button onClick={handleSubmit} disabled={disabled}>
                    {t('common:save')}
                </Button>
            </StyledBody>
        </Modal>
    )
}
