import {FC, forwardRef, Ref} from 'react'
import {StyledBaseContent, StyledCardBox} from './style'
import {FlattenSimpleInterpolation} from 'styled-components'

interface CardProps {
    children?: JSX.Element
    rootCustomClass?: () => FlattenSimpleInterpolation
    bodyCustomClass?: () => FlattenSimpleInterpolation
    onClick?: () => void
    disabled?: boolean
    ref?: Ref<HTMLDivElement>
}

export const Card: FC<CardProps> = forwardRef<HTMLDivElement, CardProps>(
    ({children, rootCustomClass, bodyCustomClass, onClick, disabled = false, ...rest}, ref) => {
        return (
            <StyledCardBox rootClassName={rootCustomClass} onClick={onClick} disabled={disabled} {...rest} ref={ref}>
                <StyledBaseContent bodyClassName={bodyCustomClass}>{children}</StyledBaseContent>
            </StyledCardBox>
        ) as React.ReactElement
    }
)

Card.displayName = 'Card'
