import {Avatar} from '@/components/ui/avatar/Avatar'
import {Modal} from '@/components/ui/modal/Modal'
import {useMe} from '@/features/authentication/queries/useMe'
import {HaveBannedElementObj} from '@/features/generated-content-security/types'
import {useGetGuest} from '@/features/profile/queries/useGetGuest'
import useWindowDimensions from '@/hooks/useWindowDimensions'
import {useRootStore} from '@/store'
import {selectApproachFirstModal} from '@/store/selectors'
import {debounce} from 'lodash'
import {FC, useEffect, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {useTheme} from 'styled-components'
import {useSendApproachFirst} from '../queries/useSendApproachFirst'
import {
    StyledButton,
    StyledChatFill,
    StyledSendFirstMessageToGuest,
    StyledSendFirstMessageToGuestAvatarContainer,
    StyledSendFirstMessageToGuestModalContainer,
    StyledSendFirstMessageToGuestModalDescription,
    StyledTextArea,
    StyledTextAreaWrapper,
    StyledTextGuest
} from './style'
import {CHAT_API} from '@/features/chat/services/chat.http'

export const SendFirstMessageToGuestModal: FC = () => {
    const {breakpoints} = useTheme()
    const {t} = useTranslation()
    const {guestID, closeModal: onCloseApproachFirstModal} = useRootStore(selectApproachFirstModal)
    const {data: guest} = useGetGuest(+String(guestID), !!guestID)
    const [message, setMessage] = useState<string>('')
    const [haveBannedContent, setHaveBannedContent] = useState<HaveBannedElementObj | null>(null)
    const {width} = useWindowDimensions()
    const [isMobile, setIsMobile] = useState(width <= breakpoints.width.m)
    const {data: user} = useMe()
    const {mutateAsync: sendApproachFirst, error} = useSendApproachFirst({
        guestID: String(guestID),
        body: {user_id: Number(user?.id)},
        onSuccess: onCloseApproachFirstModal
    })
    useEffect(() => {
        setIsMobile(width <= breakpoints.width.m)
    }, [width])

    const onSubmit = async () => {
        const {language} = await CHAT_API.getMessageLanguage(message)
        await sendApproachFirst({message, user_id: Number(user?.id), language})
    }

    if (!guest) return <></>

    return (
        <Modal
            title={t('start_connecting_modal:title_rookie', {USER: guest.full_name})}
            onClose={onCloseApproachFirstModal}
        >
            <StyledSendFirstMessageToGuestModalContainer>
                <StyledSendFirstMessageToGuestModalDescription>
                    {t('start_connecting_modal:description', {USER: guest.full_name})}
                </StyledSendFirstMessageToGuestModalDescription>

                <StyledSendFirstMessageToGuest>
                    <StyledSendFirstMessageToGuestAvatarContainer>
                        <Avatar
                            imageUrl={guest.avatar?.url}
                            name={guest?.full_name}
                            height={isMobile ? 94 : 160}
                            width={isMobile ? 94 : 160}
                            radius={4}
                        />
                        <StyledChatFill height={isMobile ? 27 : 37} width={isMobile ? 27 : 37} />
                    </StyledSendFirstMessageToGuestAvatarContainer>
                    <StyledTextGuest>{t('start_connecting_modal:text_leader')}</StyledTextGuest>
                    <StyledTextAreaWrapper>
                        <StyledTextArea
                            placeholder={t('start_connecting_modal:label_rookie', {USER: guest.full_name})}
                            onChange={debounce(setMessage, 300)}
                            getHaveBannedElements={setHaveBannedContent}
                            errorMessage={error?.response?.data?.message || ''}
                        />
                    </StyledTextAreaWrapper>
                </StyledSendFirstMessageToGuest>
                <StyledButton disabled={!message || haveBannedContent?.isBanned} onClick={onSubmit}>
                    {t('start_connecting_modal:button_text')}
                </StyledButton>
            </StyledSendFirstMessageToGuestModalContainer>
        </Modal>
    )
}
