import styled, {css} from 'styled-components'

export const StyledWrapper = styled.div<{$bottomPadding?: string}>`
    ${({$bottomPadding, theme: {scrollbar, mediaQueries, spacing}}) => css`
            display: flex;
            overflow-x: auto;
            touch-action: auto;
            scroll-behavior: smooth;
            padding-bottom: ${$bottomPadding || `${spacing * 2.5}px`};
            padding-left: ${spacing * 1.5}px;
            ${mediaQueries.m} {
                width: 80%;
                ${scrollbar}
            },
    `}
`

export const StyledItem = styled.span`
    ${({theme: {spacing, palette, typography}}) => css`
        display: flex;
        align-items: center;
        gap: ${spacing / 2}px;
        padding: 0 ${spacing / 4}px 0 ${spacing / 2}px;
        margin: ${spacing / 4}px;
        color: ${palette.primary[900]};
        white-space: nowrap;
        font-weight: 700;
        ${typography.textSm}
        line-height: 120%;
        letter-spacing: -0.3px;
    `}
`
