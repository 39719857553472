import {useMe} from '@/features/authentication/queries/useMe'
import {useSendMessageToChannel} from '../queries/useSendMessageToChannel'
import {ChannelID, ChannelMessage, PubnubMessage} from '../types'
import usePubnubSendMessage from './usePubnubSendMessage'
import {UserRoleE} from '@/types'
import {RepresentativeType} from '@/features/authentication/types'
import {useGetChannelDetails} from '../queries/useGetChannelDetails'
import {MessageTypeE} from '@/utilities/constants/chat'
import {useHandleError} from '@/hooks/useHandleError'

type ChatMessageParams = {
    type: MessageTypeE
    userId: number
    attachmentId?: number
    language?: string
    text?: string
    micromorgiAmount?: number
    animationId?: number
    gifUrl?: string
    goalId?: number
    needSendToBackend?: boolean
    needReInitChatBeforeSendToPubNub?: boolean
    paidMessageId?: string | number
    isStarred?: boolean
}

const useSendChatMessage = (channel: ChannelID) => {
    const {sendMessage: sendMessageToPubnub} = usePubnubSendMessage(channel)
    const {mutateAsync: sendMessageToBackend, isError, error, data, isPending} = useSendMessageToChannel()
    const {data: user} = useMe()
    const {data: channelDetails} = useGetChannelDetails(channel)

    const sendTargetMessageToPubNub = async ({
        micromorgiAmount,
        animationId,
        type,
        text,
        userId,
        gifUrl,
        goalId,
        attachmentId,
        language,
        isStarred
    }: ChatMessageParams) => {
        const message: PubnubMessage = {
            type,
            text,
            user_id: userId,
            language,
            meta: {
                is_starred: isStarred,
                attachmentId,
                micromorgiAmount,
                animation_id: animationId,
                gif_url: gifUrl,
                goal_id: goalId
            }
        }
        if (user?.type === UserRoleE.Representative) {
            const representative = user as RepresentativeType
            message.meta = {
                ...message.meta,
                representative_id: representative.id,
                representative_first_name: representative.type_attributes.first_name
            }
        }
        await sendMessageToPubnub(message)
    }

    const sendTargetMessageToBackend = async ({
        type,
        text,
        userId,
        language,
        attachmentId,
        gifUrl,
        goalId,
        isStarred
    }: ChatMessageParams) => {
        if (channelDetails) {
            const messageToBackend: ChannelMessage = {
                is_starred: isStarred,
                channelId: channelDetails.channel_id.toString(),
                message: {
                    type,
                    text,
                    user_id: userId,
                    language,
                    meta: {
                        is_starred: isStarred,
                        attachmentId,
                        gif_url: gifUrl,
                        goal_id: goalId
                    }
                }
            }

            if (!!user && user.type === UserRoleE.Representative) {
                const representative = user as RepresentativeType
                if (messageToBackend.message) {
                    messageToBackend.message.meta = {
                        ...messageToBackend.message.meta,
                        representative_id: representative.id,
                        representative_first_name: representative.type_attributes.first_name
                    }
                }
            }

            await sendMessageToBackend(messageToBackend)
        }
    }

    useHandleError({isError, error})

    const sendChatMessage = async ({
        type,
        attachmentId,
        text,
        language,
        micromorgiAmount,
        userId,
        animationId,
        gifUrl,
        goalId,
        paidMessageId,
        needSendToBackend = true,
        isStarred
    }: ChatMessageParams) => {
        if (user) {
            await sendTargetMessageToPubNub({
                micromorgiAmount,
                animationId,
                type,
                text,
                userId,
                gifUrl,
                goalId,
                attachmentId,
                paidMessageId,
                isStarred,
                language
            })
            needSendToBackend &&
                (await sendTargetMessageToBackend({
                    type,
                    text,
                    userId,
                    language,
                    attachmentId,
                    gifUrl,
                    goalId,
                    isStarred
                }))
        }
    }

    return {sendChatMessage, sendTargetMessageToPubNub, sendTargetMessageToBackend, data, isPending}
}

export default useSendChatMessage
