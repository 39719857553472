import {useState, useEffect, useRef, FC} from 'react'
import lottie, {AnimationItem, AnimationSegment, RendererType} from 'lottie-web'
import {DIRECTION_FORWARD, DIRECTION_REVERSE, PAUSE, PLAY, STOP} from '@utilities/constants/animation'
import {DefaultTheme, FlattenSimpleInterpolation} from 'styled-components'
import {StyledRoot} from './style'

type AnimationProps = {
    animationData: object
    title?: string
    name?: string
    autoplay?: boolean
    loop?: boolean
    renderer?: RendererType | undefined
    rendererExtraSettings?: object
    onAnimationClick?: () => void
    animationState?: string
    direction?: number
    speed?: number
    segmentPlay?: AnimationSegment | AnimationSegment[]
    animationStyles?: (theme: DefaultTheme) => FlattenSimpleInterpolation
    onCompleteCallback?: (() => void) | null
}

interface ExtendedAnimationItem extends AnimationItem {
    onComplete?: (() => void) | null
}

export const Animation: FC<AnimationProps> = ({
    animationData,
    title = '',
    name = 'animation',
    autoplay = true,
    loop = false,
    renderer = 'svg',
    rendererExtraSettings,
    onAnimationClick,
    animationState = STOP,
    direction = DIRECTION_FORWARD,
    speed = 1,
    segmentPlay = [],
    onCompleteCallback = null,
    animationStyles
}) => {
    const [instance, setInstance] = useState<ExtendedAnimationItem | null>(null)
    const [playingState, setPlayingState] = useState<string>(autoplay ? PLAY : STOP)
    const animationContainer = useRef<HTMLDivElement | null>(null)

    useEffect(() => {
        const animationOpts = {
            name,
            container: animationContainer.current!,
            renderer,
            loop,
            autoplay,
            animationData,
            rendererSettings: {
                preserveAspectRatio: 'xMidYMid slice',
                ...rendererExtraSettings
            }
        }
        //timeout for avoid double render due to react strict mode
        const timeout = setTimeout(() => setInstance(lottie.loadAnimation(animationOpts)), 100)
        return () => {
            clearTimeout(timeout)
            if (instance) {
                instance.destroy()
            }
        }
    }, [])

    /* Segments control */
    useEffect(() => {
        if (instance && segmentPlay.length > 0) {
            instance.playSegments(segmentPlay, true)
        }
    }, [segmentPlay, instance])

    /* Direction control */
    useEffect(() => {
        if (instance && direction && (direction === DIRECTION_FORWARD || direction === DIRECTION_REVERSE)) {
            instance.setDirection(direction)
        }
    }, [direction, instance])

    /* Speed control */
    useEffect(() => {
        if (instance && direction && speed >= 0) {
            instance.setSpeed(speed)
        }
    }, [speed, instance])

    useEffect(() => {
        setPlayingState(playingState)
    }, [animationState, instance])

    /* Playing state control */
    useEffect(() => {
        if (instance) {
            if (playingState === PLAY) {
                instance.play()
            } else if (playingState === PAUSE) {
                instance.pause()
            } else if (playingState === STOP) {
                instance.stop()
            }
        }
    }, [playingState])

    useEffect(() => {
        if (onCompleteCallback && instance) {
            instance.onComplete = onCompleteCallback
        }

        return () => {
            if (instance) {
                instance.onComplete = null // Clean up the callback when the component unmounts
            }
        }
    }, [instance, onCompleteCallback])

    const onClickHandler = () => {
        if (onAnimationClick) onAnimationClick()
    }

    return (
        <StyledRoot ref={animationContainer} title={title} onClick={onClickHandler} animationStyles={animationStyles} />
    )
}
Animation.displayName = 'Animation'
