import {useMe} from '@/features/authentication/queries/useMe'
import {HostType} from '@/features/authentication/types'
import {useGetHost} from '@/features/guest/queries/useGetHost'
import {USER_ROLE_HOST} from '@/utilities/constants/user'
import megaGiftSmall from '@assets/images/megaGiftSmall.png'
import {FC} from 'react'
import {Trans, useTranslation} from 'react-i18next'
import {ProfileBanner} from '../profile-banner/ProfileBanner'
import {MEGA_GIFT} from '@/featuresConfig'

type MegaGiftBannerProps = {
    userName?: string
}

export const MegaGiftBanner: FC<MegaGiftBannerProps> = ({userName}) => {
    const {t} = useTranslation()
    
    const {data} = useMe()
    const me = data?.type === USER_ROLE_HOST ? (data as HostType) : null
    const isMe = me ? String(userName) === me.username : false
    const {data: host, isLoading: isLoadingHost} = useGetHost({filter: isMe ? undefined : userName, isUsername: true})

    const groupIdForRedirect = (isMe ? me : host)?.type_attributes?.top_fans_infos?.group_id

    const hostTopFansInfos = (isMe ? me : host)?.type_attributes
    const count = hostTopFansInfos ? hostTopFansInfos.mega_gifts_count : 0

    const isRedirectToChat: boolean =
        Boolean(isMe ? me?.type === USER_ROLE_HOST : host?.type === USER_ROLE_HOST) && !!groupIdForRedirect && isMe

    return (
        <ProfileBanner
            isRedirectToChat={isRedirectToChat}
            groupId={groupIdForRedirect}
            cost={MEGA_GIFT.cost}
            description={
                <>
                    <Trans
                        t={t}
                        i18nKey={'top_fan_card:received'}
                        values={{COUNT: count}}
                        components={{
                            span: <span />
                        }}
                    />
                    {isMe && (
                        <>
                            <span>{` | `}</span>
                            <Trans
                                t={t}
                                i18nKey={'top_fan_card:progress'}
                                values={{COUNT: 0}}
                                components={{
                                    span: <span />
                                }}
                            />
                        </>
                    )}
                </>
            }
            image={megaGiftSmall}
            title={t('top_fan_card:title_mega_gift').toUpperCase()}
            isLoading={isLoadingHost}
        />
    )
}
