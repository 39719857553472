import {InputCheckIcon, InputWarningIcon} from '@/assets/icons/icons'
import {AlertStatus, AlertTypeE} from '@/features/authentication/types'
import {AppStatusesE} from '@/utilities/constants/appStatus'
import {
    ROUTE_ACTIVE_GIFTS,
    ROUTE_CHECK_PAYMENT,
    ROUTE_EDIT_PROFILE,
    ROUTE_HOME,
    ROUTE_TRANSACTION_HISTORY,
    ROUTE_UPDATE_RECURRING_TRANSACTION,
    ROUTE_WELCOME_BACK_PAGE
} from '@/utilities/constants/routeNames'
import {DefaultTheme} from 'styled-components'

export const PROFILE_ALERTS = {
    [AlertTypeE.GUEST_ENTER_NEW_PAYMENT_METHOD]: {
        isDismissible: true,
        variant: AppStatusesE.FAILURE,
        title: null,
        description: `profile_alerts:${AlertTypeE.GUEST_ENTER_NEW_PAYMENT_METHOD}:message`,
        admittedLocations: null,
        blockedLocations: [ROUTE_ACTIVE_GIFTS, ROUTE_UPDATE_RECURRING_TRANSACTION, ROUTE_WELCOME_BACK_PAGE],
        action: {
            to: {
                pathname: ROUTE_ACTIVE_GIFTS,
                state: {fromProfileError: true}
            },
            name: `profile_alerts:${AlertTypeE.GUEST_ENTER_NEW_PAYMENT_METHOD}:action_name`
        }
    },
    [AlertTypeE.GUEST_ENTER_NEW_PAYMENT_METHOD_AMG]: {
        isDismissible: false,
        variant: AppStatusesE.FAILURE,
        description: `profile_alerts:${AlertTypeE.GUEST_ENTER_NEW_PAYMENT_METHOD_AMG}:message`,
        admittedLocations: null,
        blockedLocations: [ROUTE_HOME, ROUTE_UPDATE_RECURRING_TRANSACTION, ROUTE_WELCOME_BACK_PAGE],
        title: null,
        action: {
            to: '',
            name: `profile_alerts:${AlertTypeE.GUEST_ENTER_NEW_PAYMENT_METHOD_AMG}:action_name`
        }
    },
    [AlertTypeE.GUEST_CREDIT_CARD_PROBLEMS]: {
        isDismissible: false,
        variant: AppStatusesE.FAILURE,
        description: `profile_alerts:${AlertTypeE.GUEST_CREDIT_CARD_PROBLEMS}:message`,
        admittedLocations: null,
        blockedLocations: [ROUTE_HOME, ROUTE_CHECK_PAYMENT, ROUTE_WELCOME_BACK_PAGE],
        title: null,
        action: {
            to: {
                pathname: ROUTE_UPDATE_RECURRING_TRANSACTION
            },
            name: `profile_alerts:${AlertTypeE.GUEST_CREDIT_CARD_PROBLEMS}:action_name`
        }
    },
    [AlertTypeE.HOST_ACCOUNT_PENDING]: {
        isDismissible: true,
        variant: AppStatusesE.DEFAULT,
        title: `profile_alerts:${AlertTypeE.HOST_ACCOUNT_PENDING}:title`,
        description: null,
        admittedLocations: null,
        blockedLocations: null,
        action: null
    },
    [AlertTypeE.HOST_ACCOUNT_APPROVED]: {
        isDismissible: true,
        variant: AppStatusesE.SUCCESS,
        title: `profile_alerts:${AlertTypeE.HOST_ACCOUNT_APPROVED}:title`,
        description: null,
        admittedLocations: null,
        blockedLocations: null,
        action: null
    },
    [AlertTypeE.HOST_ACCOUNT_ID_VERIFICATION]: {
        isDismissible: false,
        variant: AppStatusesE.FAILURE,
        title: `profile_alerts:${AlertTypeE.HOST_ACCOUNT_ID_VERIFICATION}:title`,
        description: `profile_alerts:${AlertTypeE.HOST_ACCOUNT_ID_VERIFICATION}:message`,
        admittedLocations: [ROUTE_TRANSACTION_HISTORY],
        blockedLocations: null,
        action: {
            to: {
                pathname: ROUTE_EDIT_PROFILE,
                state: {fromProfileError: true}
            },
            name: `profile_alerts:${AlertTypeE.HOST_ACCOUNT_ID_VERIFICATION}:action_name`
        }
    }
}

export const getVariantStyle = (variant: AlertStatus, theme: DefaultTheme) => {
    switch (variant) {
        case AppStatusesE.SUCCESS:
            return {
                color: theme.palette.success[600],
                backgroundColor: theme.palette.success[25],
                closeIconColor: theme.palette.success[50],
                icon: <InputCheckIcon />
            }
        case AppStatusesE.FAILURE:
            return {
                color: theme.palette.danger[200],
                backgroundColor: theme.palette.warning[300],
                closeIconColor: theme.palette.danger[50],
                icon: <InputWarningIcon />
            }
        case AppStatusesE.DEFAULT:
        default:
            return {
                color: theme.palette.primary[200],
                backgroundColor: theme.palette.primary[500],
                closeIconColor: theme.palette.primary[600]
            }
    }
}
