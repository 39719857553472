import {Spinner} from '@/components/ui/spinner/Spinner'
import {useGetExpertises} from '@/features/chat-fav-topics/queries/useGetExpertises'
import {useHandleError} from '@/hooks/useHandleError'
import {StyledSpecialHostRoot, StyledTitle} from './style'
import {useTranslation} from 'react-i18next'
import Select from '@/components/commons/select/Select'
import {OptionType} from '@/components/commons/singleSelect/types'
import {Dispatch, SetStateAction} from 'react'
import {remapApiOptions} from '@/utilities/helpers'
import i18n from '@/translations/i18n'

type SpecialHostCategoryFilterProps = {
    specialHostCategory: OptionType[]
    setSpecialHostCategory: Dispatch<SetStateAction<OptionType[]>>
}

export const SpecialHostCategoryFilter = ({
    specialHostCategory,
    setSpecialHostCategory
}: SpecialHostCategoryFilterProps) => {
    const {t} = useTranslation()
    const {data: expertises, isLoading, isError, error} = useGetExpertises({appLang: i18n.language})
    useHandleError({isError, error})

    const remappedOptions = expertises ? remapApiOptions({options: expertises as {id: number; name: string}[]}) : []

    const handleChange = (value: OptionType[]) => {
        setSpecialHostCategory(value)
    }

    return (
        <StyledSpecialHostRoot>
            {isLoading && <Spinner />}
            <StyledTitle>{t('path_filter:filter_special_host')}</StyledTitle>
            <Select
                maxItems={expertises?.length}
                placeholder={t('path_filter:filter_special_host')}
                touched={true}
                value={specialHostCategory}
                defaultValue={specialHostCategory}
                maxItemsErrorMessage=""
                onChange={value => handleChange(value)}
                options={remappedOptions}
                isMulti={true}
                showSelectedOptions={false}
                maxMenuHeight={200}
            />
        </StyledSpecialHostRoot>
    )
}
