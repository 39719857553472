import {UnitsE, getDifference} from '@/dayjs'
import {useEffect, useState} from 'react'

type CountdownProps = {
    date: string
}

export const useCountdown = ({date}: CountdownProps) => {
    const [days, setDays] = useState(0)
    const [hours, setHours] = useState(0)
    const [minutes, setMinutes] = useState(0)
    const [seconds, setSeconds] = useState(0)

    const getTimeUntil = (date: string) => {
        const difference = getDifference(date, UnitsE.MILLISECOND)

        if (!date || difference < 0) {
            setDays(0)
            setHours(0)
            setMinutes(0)
            setSeconds(0)
        } else {
            setDays(Math.floor(difference / (1000 * 60 * 60 * 24)))
            setHours(Math.floor((difference / (1000 * 60 * 60)) % 24))
            setMinutes(Math.floor((difference / 1000 / 60) % 60))
            setSeconds(Math.floor((difference / 1000) % 60))
        }
    }

    useEffect(() => {
        const interval = setInterval(() => getTimeUntil(date), 1000)

        return () => clearInterval(interval)
    }, [date])

    return {
        days,
        hours,
        minutes,
        seconds
    }
}
