import {Spinner} from '@/components/ui/spinner/Spinner'
import {useTranslation} from 'react-i18next'
import {useGetThankYousByHostId} from '../../queries/useGetThankYousByHostId'
import {ThankYousTypesE} from '../../services/thankYous.schema'
import {StyledItemWrapper, StyledThankYousSectionRoot, StyledTitleWrapper} from './style'
import {ThankYousTipsItem} from './thank-yous-item.tsx/ThankYousTipsItem'
import {HostType} from '@/features/authentication/types'
import {Dispatch, SetStateAction, useEffect} from 'react'

type ThanksYousHostSectionProps = {
    inHostProfile?: boolean
    hostData?: HostType
    showGoalPageModalVariant?: boolean
    setIsShowThankYous?: Dispatch<SetStateAction<boolean>>
}

export const ThanksYousHostSection = ({
    inHostProfile = false,
    hostData,
    setIsShowThankYous
}: ThanksYousHostSectionProps) => {
    const {t} = useTranslation()
    const {data: hostThankYous, isLoading} = useGetThankYousByHostId(`${hostData?.id}`)
    const hostName = hostData?.type_attributes?.first_name

    const countInChatTipsAndGifts = hostThankYous?.in_chat_tips_and_gifts?.length
    const countUncoverAttachment = hostThankYous?.uncover_attachment?.length

    const hasHostThankYou = !!countInChatTipsAndGifts || !!countUncoverAttachment

    useEffect(() => {
        if (setIsShowThankYous) {
            setIsShowThankYous(hasHostThankYou)
        }
    }, [hasHostThankYou, setIsShowThankYous])

    if (inHostProfile && !hasHostThankYou) {
        return null
    }

    return isLoading ? (
        <Spinner />
    ) : (
        <StyledThankYousSectionRoot inHostProfile={inHostProfile}>
            <StyledTitleWrapper inHostProfile={inHostProfile}>
                {t('thanks_yous_label:tippy_title', {
                    ROOKIE_NAME: hostName
                })}
                {!inHostProfile && ':'}
            </StyledTitleWrapper>
            <StyledItemWrapper>
                {!!countInChatTipsAndGifts && (
                    <ThankYousTipsItem
                        data={hostThankYous?.in_chat_tips_and_gifts}
                        inHostProfile={inHostProfile}
                        count={countInChatTipsAndGifts}
                        type={ThankYousTypesE.inChatTipsAndGifts}
                    />
                )}
                {!!countUncoverAttachment && (
                    <ThankYousTipsItem
                        data={hostThankYous?.uncover_attachment}
                        inHostProfile={inHostProfile}
                        count={countUncoverAttachment}
                        type={ThankYousTypesE.uncoverAttachment}
                    />
                )}
            </StyledItemWrapper>
        </StyledThankYousSectionRoot>
    )
}
