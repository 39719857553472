import {StyledOverlay, StyledSvg, StyledSvgBox} from './style'

interface SpinnerProps {
    color?: string
    size?: number
    zIndex?: number
    overlayFullscreen?: boolean
    strokeLineCap?: 'butt' | 'round' | 'square' | 'inherit' | undefined
    overlay?: boolean
    inline?: boolean
    center?: boolean
}

export const Spinner = ({
    color = '#333',
    size = 50,
    overlayFullscreen = false,
    strokeLineCap = 'square',
    overlay = true,
    inline = false,
    center = true,
    zIndex = 1034
}: SpinnerProps) => (
    <>
        {overlay && <StyledOverlay overlayFullscreen={!inline || overlayFullscreen} />}
        <StyledSvgBox inline={inline} $size={size} center={center} zIndex={zIndex}>
            <StyledSvg $size={size} viewBox="0 0 50 50" zIndex={zIndex}>
                <circle
                    cx="25"
                    cy="25"
                    r="20"
                    fill="none"
                    stroke={color}
                    strokeWidth="3"
                    strokeLinecap={strokeLineCap}
                />
            </StyledSvg>
        </StyledSvgBox>
    </>
)

Spinner.displayName = 'Spinner'
