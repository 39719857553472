export const MUTATION_KEYS = {
    delete_my_moments: 'delete-my-moments',
    delete_host_moment: 'delete-host-moments'
}

export const QUERY_KEYS = {
    my_moments: 'my-moments',
    my_moments_filtered_categories: 'my-moments-filtered-categories',
    my_moments_filtered_hosts: 'my-moments-filtered-hosts'
}
