import {z} from 'zod'
import * as schemas from './services/guest.schema'

export type Subscriptions = z.infer<typeof schemas.SubscriptionsSchema>
export type SubscriptionsToRenewCreditCard = z.infer<typeof schemas.SubscriptionsSchemaToRenewCard>
export type Subscription = z.infer<typeof schemas.SubscriptionSchema>
export type SubscriptionsUpdate = z.infer<typeof schemas.SubscriptionsUpdateSchema>
export type AgesType = z.infer<typeof schemas.AgesSchema>

export enum SubscriptionsStatusE {
    Active = 'active',
    Inactive = 'inactive',
    Cancel = 'cancel'
}

export enum SubscriptionsKeysE {
    Subscriptions = 'subscriptions',
    Errors = 'errors'
}

export enum OnBoardStepsE {
    Step_one = 'step_one',
    Step_two = 'step_two',
    Step_three = 'step_three'
}

export enum OnBoardDataTypesE {
    Personal = 'personal',
    Generic = 'generic'
}

export enum HostFiltersTabIdsE {
    CHAT_TOPICS = 'chatTopics',
    ACTIVITIES = 'activities',
    COUNTRY_LANGUAGE = 'countryLanguage',
    SPECIAL_HOST_CATEGORY = 'specialHostCategory',
    GENDER = 'gender'
}

export type StepComponentProp = {
    images?: (string | undefined | null)[]
    avatar?: string
    hostname?: string | string[]
    type?: OnBoardDataTypesE
}
