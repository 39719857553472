import styled, {css} from 'styled-components'

export const StyledDivider = styled.div`
    ${({theme: {palette, spacing}}) => css`
        border-top: 1px solid ${palette.neutral[75]};
        margin: ${spacing * 4}px 0px;
    `}
`

export const StyledButtonWrapper = styled.section`
    ${({theme: {mediaQueries, spacing}}) => css`
        display: flex;
        flex-direction: column;
        gap: ${spacing * 2}px;
        margin-bottom: ${spacing * 2}px;
        ${mediaQueries.m} {
            flex-direction: row;
        }
    `}
`

export const StyledDescription = styled.div`
    ${({theme: {mediaQueries, typography, spacing}}) => css`
        & p {
            margin-top: ${spacing * 4}px;
            text-align: center;
            ${typography.textSm}
            ${mediaQueries.m} {
                ${typography.textMd}
            }
        }
    `}
`
