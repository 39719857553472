import {envVars} from '@/envVars'
import {HostType} from '@/features/authentication/types'
import * as routes from '@/utilities/constants/routeNames'
import {USER_ROLE_REPRESENTATIVE} from '@/utilities/constants/user'

import {Navigate, Outlet, useLocation} from 'react-router-dom'

const availablePagesWithoutTelegramBotConnection = [
    routes.ROUTE_TELEGRAM_BOT,
    routes.ROUTE_HOST_MY_PROFILE,
    routes.ROUTE_HOST_SCORE,
    routes.ROUTE_WELCOME_HOST,
    routes.ROUTE_CONNECT_TELEGRAM_PAGE,
    routes.ROUTE_HOME
]

export const HostRouteRedirect = ({user}: {user: HostType}) => {
    const location = useLocation()
    const isHostTelegramBotConnectionRequired =
        !availablePagesWithoutTelegramBotConnection.includes(location.pathname) &&
        !user.joined_telegram_bot_at &&
        !envVars.VITE_APP_ROOKIE_TELEGRAM_BLOCK_DISABLED &&
        import.meta.env.MODE !== 'development'

    const isUserProfileCompleted =
        !!user.chat_topics &&
        !!user.chat_topics.length &&
        !!user.favorite_activities &&
        !!user.favorite_activities.length &&
        !!user.spoken_languages &&
        !!user.spoken_languages.length

    switch (true) {
        case isHostTelegramBotConnectionRequired:
            return <Navigate to={routes.ROUTE_CONNECT_TELEGRAM_PAGE} />
        case !isUserProfileCompleted &&
            ![routes.ROUTE_DELETE_PROFILE, routes.ROUTE_ADD_NEW_INFO_PAGE, routes.ROUTE_COMPLETE_PROFILE].includes(
                location.pathname
            ) &&
            user &&
            user.type !== USER_ROLE_REPRESENTATIVE:
            return <Navigate to={routes.ROUTE_WELCOME_BACK_PAGE} />
        default:
            return <Outlet />
    }
}
