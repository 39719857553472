import styled, {DefaultTheme, css} from 'styled-components'

export const StyledCardWrapper = styled.div`
    ${({theme: {spacing, palette, mediaQueries}}) => css`
        cursor: pointer;
        width: 100%;
        border-radius: 10px;
        position: relative;
        margin: 0 auto;
        margin-top: ${spacing * 2.25}px;
        background-color: ${palette.neutral.white};

        ${mediaQueries.m} {
            margin-top: 28px;
        }
    `}
`

export const StyledPromoLabel = styled.div`
    ${({
        theme: {spacing, typography, palette},
        promoType,
        promoLabelGradients
    }: {
        theme: DefaultTheme
        promoType?: string
        promoLabelGradients?: string
    }) => css`
        position: absolute;
        top: -21px;
        right: 15px;
        padding: ${spacing}px;
        border-radius: 8px;
        box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
        color: ${promoType === 'cashback' ? palette.neutral.black : palette.neutral.white};
        background: ${promoLabelGradients};
        text-align: center;
        ${typography.textMd}
        font-weight: 700;
        letter-spacing: -0.3px;
        z-index: 1;
    `}
`

export const StyledSavedButtonContainer = styled.div(
    ({theme: {spacing, mediaQueries}}) => css`
        right: ${spacing * 1.625}px;
        top: ${spacing * 3}px;
        position: absolute;
        cursor: pointer;
        ${mediaQueries.l} {
            right: ${spacing * 2.5}px;
        }

        & > img {
            width: ${spacing * 3.25}px;
            height: ${spacing * 3}px;

            ${mediaQueries.l} {
                height: ${spacing * 4.75}px;
                width: ${spacing * 5.125}px;
            }
        }
    `
)

export const StyledCardContent = styled.div(
    ({theme: {spacing, mediaQueries, truncateText}}) => css`
        margin-left: ${spacing}px;
        margin-top: ${spacing * 2}px;
        width: 100%;
        ${truncateText};
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        ${mediaQueries.l} {
            margin-left: ${spacing * 2}px;
        }
    `
)

export const StyledTitleWrapper = styled.div(
    ({theme: {mediaQueries, truncateText, typography, sizing, spacing}}) => css`
        width: calc(100% - ${sizing.saveHostIconWidth});
        display: flex;
        flex-wrap: nowrap;
        ${typography.textLg};
        align-items: center;
        font-weight: 600;
        line-height: ${spacing * 2}px;

        & > h4 {
            ${truncateText};
        }

        ${mediaQueries.l} {
            ${typography.text3Xl};
            line-height: 34px;
        }

        & > h4 {
            ${truncateText};
        }
    `
)
