import {z} from 'zod'

export const CountrySchema = z.object({
    id: z.number(),
    name: z.string(),
    has_childs: z.number(),
    alpha_3_code: z.string(),
    alpha_2_code: z.string(),
    timezone: z.string(),
    currency: z.string(),
    dial: z.number(),
    language_id: z.number().nullable().optional()
})

export const LanguageSchema = z.object({
    id: z.number(),
    name: z.string(),
    code: z.string(),
    is_translatable: z.boolean().optional()
})

export const CountryWithLanguageSchema = CountrySchema.extend({
    language: LanguageSchema.nullable()
})

export const CitySchema = z.object({
    id: z.number(),
    name: z.string(),
    country_id: z.number(),
    country: CountrySchema
})

export const RegionSchema = CitySchema.extend({alpha_2_code: z.string()})

export const CountriesResponseSchema = z.array(CountrySchema)
export const CountriesWithLanguageResponseSchema = z.array(CountryWithLanguageSchema)
export const RegionsResponseSchema = z.array(RegionSchema)
export const CitiesResponseSchema = z.array(CitySchema)
export const LanguagesResponseSchema = z.array(LanguageSchema)
