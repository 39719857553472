import styled, {DefaultTheme, css} from 'styled-components'

export const StyledRoot = styled.div`
    ${({theme: {mediaQueries, spacing, zIndex, palette}}: {theme: DefaultTheme}) => css`
        color: ${palette.neutral.white};
        position: absolute;
        top: 5%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: ${zIndex.modal};
        background-color: ${palette.primary[900]};
        padding: ${spacing * 2}px;
        width: 345px;
        border-radius: 10px;
        cursor: pointer;

        & svg {
            align-self: self-start;
            cursor: pointer;
            & path {
                fill: ${palette.neutral.white};
            }
        }
        ${mediaQueries.m} {
            padding: ${spacing * 2}px ${spacing * 2}px;
        }
    `}
`

export const StyledContainer = styled.div`
    ${({theme: {spacing}}) => css`
        display: grid;
        grid-template-columns: 1fr auto;
        gap: ${spacing * 2}px;
        align-items: center;
    `}
`
export const StyledContent = styled.div`
    ${({theme: {typography}}) => css`
        display: flex;
        flex-direction: column;
        justify-content: center;
        & h3 {
            font-weight: 700;
            margin: 0;
            ${typography.textSm}
        }
        & div {
            word-break: break-word;
        }
    `}
`
