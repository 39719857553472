import footballIcon from '@assets/images/expertise/football.png'
import basketballIcon from '@assets/images/expertise/basketball.png'
import tennisIcon from '@assets/images/expertise/tennis.png'
import baseballIcon from '@assets/images/expertise/baseball.png'
import golfIcon from '@assets/images/expertise/golf.png'
import americanFootballIcon from '@assets/images/expertise/american_football.png'
import athleticsIcon from '@assets/images/expertise/athletics.png'
import swimmingIcon from '@assets/images/expertise/swimming.png'
import volleyballIcon from '@assets/images/expertise/volleyball.png'
import handballIcon from '@assets/images/expertise/handball.png'

import popIcon from '@assets/images/expertise/pop.png'
import rockIcon from '@assets/images/expertise/rock.png'
import rapIcon from '@assets/images/expertise/rap.png'
import electronicIcon from '@assets/images/expertise/electronic.png'
import countryIcon from '@assets/images/expertise/country.png'
import jazzIcon from '@assets/images/expertise/jazz.png'
import bluesIcon from '@assets/images/expertise/blues.png'
import classicalIcon from '@assets/images/expertise/classical.png'
import reggaeIcon from '@assets/images/expertise/reggae.png'
import rnbIcon from '@assets/images/expertise/rhythm_and_blues.png'
import folkIcon from '@assets/images/expertise/folk.png'
import metalIcon from '@assets/images/expertise/metal.png'

export const FOOTBALL_EXPERTISE: string = 'soccer_player'
export const BASKETBALL_EXPERTISE: string = 'basketball_player'
export const TENNIS_EXPERTISE: string = 'tennis'
export const GOLF_EXPERTISE: string = 'golf'
export const BASEBALL_EXPERTISE: string = 'baseball'
export const AMERICAN_FOOTBALL_EXPERTISE: string = 'american_football'
export const ATHLETICS_EXPERTISE: string = 'athletics'
export const SWIMMING_EXPERTISE: string = 'swimming'
export const VOLLEYBALL_EXPERTISE: string = 'volleyball'
export const HANDBALL_EXPERTISE: string = 'handball'

export const POP_EXPERTISE: string = 'pop'
export const ROCK_EXPERTISE: string = 'rock'
export const HIPHOP_RAP_EXPERTISE: string = 'hip_hop'
export const ELECTRONIC_EXPERTISE: string = 'electronic'
export const COUNTRY_EXPERTISE: string = 'country'
export const JAZZ_EXPERTISE: string = 'jazz'
export const BLUES_EXPERTISE: string = 'blues'
export const CLASSICAL_EXPERTISE: string = 'classical'
export const REGGAE_EXPERTISE: string = 'reggae'
export const RNB_EXPERTISE: string = 'r_&_b'
export const FOLK_EXPERTISE: string = 'folk'
export const METAL_EXPERTISE: string = 'metal'

export const CATEGORY_ART_EXPERTISE: string = 'art'
export const CATEGORY_SPORT_EXPERTISE: string = 'sport'

type ExpertiseIcons = {
    [key: string]: string
}

export const expertiseIcons: ExpertiseIcons = {
    [FOOTBALL_EXPERTISE]: footballIcon,
    [BASKETBALL_EXPERTISE]: basketballIcon,
    [TENNIS_EXPERTISE]: tennisIcon,
    [GOLF_EXPERTISE]: golfIcon,
    [BASEBALL_EXPERTISE]: baseballIcon,
    [AMERICAN_FOOTBALL_EXPERTISE]: americanFootballIcon,
    [ATHLETICS_EXPERTISE]: athleticsIcon,
    [SWIMMING_EXPERTISE]: swimmingIcon,
    [VOLLEYBALL_EXPERTISE]: volleyballIcon,
    [HANDBALL_EXPERTISE]: handballIcon,

    [POP_EXPERTISE]: popIcon,
    [ROCK_EXPERTISE]: rockIcon,
    [HIPHOP_RAP_EXPERTISE]: rapIcon,
    [ELECTRONIC_EXPERTISE]: electronicIcon,
    [COUNTRY_EXPERTISE]: countryIcon,
    [JAZZ_EXPERTISE]: jazzIcon,
    [BLUES_EXPERTISE]: bluesIcon,
    [CLASSICAL_EXPERTISE]: classicalIcon,
    [REGGAE_EXPERTISE]: reggaeIcon,
    [RNB_EXPERTISE]: rnbIcon,
    [FOLK_EXPERTISE]: folkIcon,
    [METAL_EXPERTISE]: metalIcon
}

export const expertiseTypes = {
    sport: 'sport',
    art: 'art'
}
