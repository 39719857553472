import styled, { css } from 'styled-components'

export const StyledGoalTeaserContainer = styled.div`
    ${({ theme: { spacing, mediaQueries } }) => css`
        position: relative;
        min-height: ${spacing * 20}px;
        margin: 0 auto;

        ${mediaQueries.l} {
            min-height: 250px;
        }
    `}
`
export const StyledGoalWrapper = styled.div`
    ${({ theme: { spacing, mediaQueries } }) => css`
        left: ${spacing / 2}px;
        bottom: ${spacing}px;
        position: absolute;

        ${mediaQueries.l} {
            left: ${spacing * 3}px;
            bottom: 19px;
        }
    `}
`
type StyledGoalImageProps = {
    url: string
}
export const StyledGoalImage = styled.div<StyledGoalImageProps>`
    ${({ theme: { spacing, mediaQueries }, url }) => css`
        background-repeat: no-repeat;
        background-position: center center;
        background-image: url(${url});
        background-size: cover;
        overflow: visible;
        width: 135px;
        bottom: ${spacing * 2}px;
        height: 135px;
        z-index: 3;
        position: absolute;

        ${mediaQueries.l} {
            left: -${spacing}px;
            width: 210px;
            height: 210px;
        }
    `}
`
export const StyledGoalName = styled.div`
    ${({ theme: { spacing, mediaQueries, palette, typography } }) => css`
        gap: ${spacing / 2}px;
        display: grid;
        padding: ${spacing / 2}px ${spacing}px ${spacing / 2}px ${spacing / 2}px;
        z-index: 3;
        position: relative;
        background: #a27606;
        box-shadow: 0px 2px 18px rgba(0, 6, 61, 0.15);
        align-items: center;
        border-radius: ${spacing * 4}px;
        justify-content: center;
        grid-template-columns: 0fr 1fr;

        ${mediaQueries.l} {
            gap: ${spacing * 2}px;
        }

        & > img {
            max-width: fit-content;
            width: 36px;
            height: 36px;
            border-radius: 100%;

            ${mediaQueries.l} {
                width: 55px;
                height: 55px;
            }
        }

        & p {
            color: ${palette.neutral.white};
            margin: 0;
            ${typography.textSm}
            max-width: 86px;
            line-height: ${spacing * 2}px;
            letter-spacing: -0.02em;

            & strong {
                font-weight: 700;
            }

            ${mediaQueries.l} {
                ${typography.textLg};
                max-width: 105px;
                line-height: ${spacing * 2}px;
            }
        }
    `}
`
export const StyledGoalBanner = styled.div`
    ${({ theme: { spacing, palette, mediaQueries } }) => css`
        width: 100%;
        bottom: 0;
        display: flex;
        overflow: hidden;
        position: absolute;
        background: ${palette.tertiary[25]};
        box-shadow: 0px 2px 18px rgba(0, 6, 61, 0.15);
        border-radius: 8px;
        justify-content: end;
        padding: ${spacing * 1.5}px;

        ${mediaQueries.l} {
            margin: ${spacing * 2.5}px auto ${spacing * 1.25}px;
        }

        & > svg {
            top: -${spacing * 10.625}px;
            left: -${spacing * 4}px;
            position: absolute;

            ${mediaQueries.l} {
                top: -179px;
                left: -68px;
                width: 390px;
                height: 440px;
            }
        }
    `}
`

export const StyledBannerText = styled.div`
    ${({ theme: { mediaQueries, spacing, typography } }) => css`
        margin: ${spacing * 1.25}px 0;
        position: relative;
        max-width: 160px;
        display: flex;
        flex-direction: column;

        ${mediaQueries.l} {
            max-width: 220px;
            margin-top: 0;
        }

        & > p {
            margin: 0;
            ${typography.textMd};
            font-weight: 500;
            line-height: 19px;
            margin-bottom: ${spacing}px;
            letter-spacing: -0.02em;

            ${mediaQueries.l} {
                ${typography.textXl}
                line-height: ${spacing * 3}px;
                margin-bottom: ${spacing * 2}px;
            }
        }

        & > button {
            padding: ${spacing / 2}px;
            ${typography.textSm};
            max-width: fit-content;
            background-color: rgb(134, 73, 209);
            font-weight: 600;
            line-height: 1.2;

            ${mediaQueries.l} {
                padding: ${spacing * 1.5}px;
                ${typography.textLg};
            }
        }
    `}
`
