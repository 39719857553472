import {useMutation} from '@tanstack/react-query'
import {MUTATION_KEYS} from './keys'
import {PROFILE_API} from '../services/profile.http'

export const useBlockUser = (onSuccess: () => void) => {
    return useMutation({
        mutationKey: [MUTATION_KEYS.block_user],
        mutationFn: (userId: number) => PROFILE_API.blockUser(userId),
        onSuccess
    })
}
