import {useTranslation} from 'react-i18next'
import {StyledArrowDownIcon, StyledListItems, StyledTitle, StyledWrapper} from './style'
import {useEffect, useMemo, useRef, useState} from 'react'
import {MenuItem} from '../menu-item/MenuItem'
import {matchRoutes, useLocation} from 'react-router-dom'
import {ArrowDownIcon} from '@/assets/icons/icons'
import {ItemMenu} from '../menuItemsList'

export const SubMenu = ({item, isMenuOpen, onClose}: {item: ItemMenu; isMenuOpen: boolean; onClose: () => void}) => {
    const {t} = useTranslation()
    const [open, setOpen] = useState(false)
    const {icon, title, subMenu, withGradientMenuItem, secondaryIcon} = item
    const location = useLocation()
    const listRef = useRef<HTMLDivElement | null>(null)
    const listHeight = (listRef?.current && listRef?.current?.scrollHeight) || 0

    useEffect(() => {
        setOpen(false)
    }, [isMenuOpen])

    useEffect(() => {
        const routeWithStaticNav =
            subMenu?.map(item => {
                return {path: item.to}
            }) ?? []

        const matches = matchRoutes(routeWithStaticNav, location)

        if (matches?.length) {
            setOpen(true)
        }
    }, [subMenu, location?.pathname])

    const activeIcon = useMemo(() => {
        if (withGradientMenuItem && open) {
            return secondaryIcon
        }
        return icon
    }, [icon, withGradientMenuItem, secondaryIcon, open])

    return (
        <div>
            <StyledWrapper onClick={() => setOpen(!open)} withGradientMenuItem={withGradientMenuItem} isOpen={open}>
                {!!icon && activeIcon}
                <StyledTitle>{t(title)}</StyledTitle>
                <StyledArrowDownIcon isOpen={open}>
                    <ArrowDownIcon />
                </StyledArrowDownIcon>
            </StyledWrapper>
            <StyledListItems listHeight={listHeight} isOpen={open} ref={listRef}>
                {!!subMenu &&
                    subMenu.map(item => <MenuItem key={item.id} item={item} forSubMenu={true} onClose={onClose} />)}
            </StyledListItems>
        </div>
    )
}

SubMenu.displayName = 'SubMenu'
