import {useCallback, useEffect, useState} from 'react'
import {useMe} from '@/features/authentication/queries/useMe'
import {CookieChangeEvent} from '@/utilities/constants/cookie'
import Cookies from 'js-cookie'
import mixpanel from 'mixpanel-browser'
import {envVars} from '@/envVars'
import {mixpanelConfig} from '@/integrations/mixpanel/services/mixpanel.config'
import {LocalStorageManager} from '@/utilities/localStorage'

const useIubenda = () => {
    const {loggedIn} = useMe()
    const [isMixpanelActive, setIsMixpanelActive] = useState(false)
    const register_once = mixpanel.register_once.bind(mixpanel)

    const activateMixpanel = () => {
        const token = `${envVars.VITE_APP_MIXPANEL_PROJECT_TOKEN}`
        mixpanel.init(token, {
            ...mixpanelConfig,
            loaded: () => {
                setIsMixpanelActive(true)
                const utmParams = LocalStorageManager.utmParams.get()
                if (Object.keys(utmParams).length) {
                    register_once(utmParams)
                    LocalStorageManager.utmParams.remove()
                }
            }
        })
    }

    // Iubenda last minute
    const cookieListener = useCallback(
        (event: CookieChangeEvent) => {
            event.changed.forEach(cookie => {
                if (cookie?.name.startsWith('_iub_cs')) {
                    try {
                        const iubendaCookieValue = JSON.parse(Cookies.get(cookie.name) || '')
                        if (iubendaCookieValue?.consent && !loggedIn) {
                            activateMixpanel()
                        }
                    } catch (e) {
                        console.log('cannot parse cookie')
                    }
                }
            })
        },
        [loggedIn]
    )

    useEffect(() => {
        // Listen for change in cookies
        const cookieStore = window?.cookieStore
        const eventListener: EventListenerOrEventListenerObject = {
            handleEvent: cookieListener
        }

        cookieStore?.addEventListener('change', eventListener)

        // Add if already active
        if (Cookies.get('_iub_cs') && !loggedIn) {
            activateMixpanel()
        }

        return () => {
            cookieStore?.removeEventListener('change', eventListener)

            if (isMixpanelActive) {
                mixpanel.reset()
            }
        }
    }, [])

    useEffect(() => {
        if (loggedIn) {
            // 1) activate mixpanel
            if (!isMixpanelActive) {
                activateMixpanel()
            }

            // 2) delete cookie banner
            setTimeout(() => {
                const iubendaConfirmBTN = document.getElementsByClassName(
                    'iubenda-cs-accept-btn'
                ) as HTMLCollectionOf<HTMLElement>

                if (iubendaConfirmBTN.length > 0) {
                    const firstButton = iubendaConfirmBTN[0]
                    firstButton.click()
                }
            }, 2000)
        }
    }, [loggedIn])

    return {isMixpanelActive}
}

export default useIubenda
