import styled, {DefaultTheme, css} from 'styled-components'

export const StyledWrapper = styled.div`
    ${({theme: {spacing, flexUtils}}) => css`
        ${flexUtils.centered}
        height: 100%;
        padding: 0px ${spacing * 2}px ${spacing * 2}px;
    `}
`
export const StyledAnimation = styled.div`
    height: 100%;
    width: 100%;
    max-width: 271px;
    max-height: 196px;
`

export const StyledSurprise = styled.div`
    ${({theme: {spacing}, containerHeight}: {theme: DefaultTheme; containerHeight?: number}) => css`
        height: 100%;
        width: 100%;
        border-radius: 10px;
        position: relative;
        max-height: calc(${containerHeight}px - ${spacing * 2}px);
        height: 100%;

        .plyr {
            height: 100%;
        }
    `}
`
export const StyledCloseButton = styled.div`
    ${({theme: {flexUtils, spacing, palette}}) => css`
        height: 32px;
        width: 32px;
        background-color: ${palette.neutral[625]};
        border-radius: 10px;
        position: absolute;
        top: ${spacing * 2}px;
        right: ${spacing * 2}px;
        ${flexUtils.centered}
        z-index: 1;
        cursor: pointer;
    `}
`
