import {Lens} from '@dhmk/zustand-lens'

type SuccessMegaGiftOverlayState = {
    isOpen: boolean
}

type SuccessMegaGiftOverlayActions = {
    openModal: (params: Omit<SuccessMegaGiftOverlayState, 'isOpen'>) => void
    closeModal: () => void
}

export type SuccessMegaGiftOverlay = SuccessMegaGiftOverlayState & SuccessMegaGiftOverlayActions

const initialModal: SuccessMegaGiftOverlayState = {isOpen: false}

const createSuccessMegaGiftOverlaySlice: Lens<SuccessMegaGiftOverlay> = set => ({
    ...initialModal,
    openModal: params => set(() => ({...params, isOpen: true})),
    closeModal: () => set(() => ({...initialModal}))
})

export default createSuccessMegaGiftOverlaySlice
